import ModalScheduleCallForm from './modals/ModalScheduleCallForm';
import ModalSuccessMsg from './modals/ModalSuccessMsg';
import React, { useCallback, useMemo, useState } from 'react';

const ContactYourRepresentativeModal = ({ show, setShow, owner }) => {
  const [successAlert, setSuccessAlert] = useState(false);
  return (
    <>
      <ModalScheduleCallForm
        show={show}
        data={owner}
        onModalSuccessMsg={() => setSuccessAlert(true)}
        onHide={() => setShow(false)}
      />
      <ModalSuccessMsg
        closeModal={() => {
          setShow(false);
          setSuccessAlert(false);
        }}
        show={successAlert}
        onHide={() => setSuccessAlert(false)}
      />
    </>
  );
};

const useContactModal = (owner) => {
  const [showModal, setShowModal] = useState(false);
  const ContactYourRepresentativeModalCallback = useCallback(() => {
    return (
      <ContactYourRepresentativeModal
        show={showModal}
        setShow={setShowModal}
        owner={owner}
      />
    );
  }, [showModal, setShowModal]);

  return useMemo(
    () => ({
      setShowModal,
      ContactYourRepresentative: showModal
        ? ContactYourRepresentativeModalCallback
        : () => <div />,
    }),
    [setShowModal, ContactYourRepresentativeModalCallback]
  );
};
export default useContactModal;
