import React from 'react';
import { Card, CardBody } from 'reactstrap';

const GlossaryWidget = ({ widgetConfig, whenPrinting }) => {
  const items = [];
  for (const key in widgetConfig.glossary) {
    items.push({
      id: key,
      text: widgetConfig.glossary[key],
    });
  }
  return (
    <Card className="glossary-widget report-widget">
      <CardBody className="justify-content-center align-items-center d-flex pt-3">
        <div className="flex-fill text-left glossary-items text-rpt-heading">
          {items.map((glos, index) => (
            <div
              key={index}
              className={`py-2 ${
                whenPrinting ? 'fs-10' : ''
              } glossary-item border-bottom`}
            >
              <p
                className={`font-weight-semi-bold font-size-sm2 mb-0 ${
                  whenPrinting ? 'pb-0 fs-10 mb-0' : ''
                }`}
              >
                {glos.id}
              </p>
              <p
                className={
                  whenPrinting ? 'pb-0 fs-10 mb-0' : 'mb-0 font-size-sm2'
                }
              >
                {glos.text}
              </p>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default GlossaryWidget;
