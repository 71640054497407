import TooltipComponent from '../../../../../components/lesson/Tooltip';
import MaterialIcon from '../../../../../components/commons/MaterialIcon';
import Loading from '../../../../../components/Loading';

const OverviewSelfDirectedTable = ({
  data,
  title,
  width,
  selfDirectedLoading,
}) => {
  return (
    <>
      {selfDirectedLoading ? (
        <Loading />
      ) : (
        <div className={width}>
          <div className={`card `}>
            <div className="card-body p-0">
              <h4
                className={`card-title p-3 text-hover-primary font-weight-medium font-size-md`}
              >
                {title}
              </h4>
              <table className="table table-xs table-hover table-borderless w-100 table-thead-bordered table-align-middle card-table dataTable no-footer">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>
                      <div className="d-flex align-items-center gap-1">
                        <span>Completed</span>
                        <TooltipComponent title="Total number of unique lessons completed.">
                          <MaterialIcon icon={'info'} clazz="font-size-sm" />
                        </TooltipComponent>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, index) => {
                    const name = `${
                      item?.first_name !== null
                        ? `${item?.first_name} ${item?.last_name}`
                        : '--'
                    }`;
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex gap-1 font-weight-semi-bold font-weight-medium align-items-center">
                            <span>{index + 1}.</span>
                            <span className={`text-capitalize d-inline-block`}>
                              {name}
                            </span>
                          </div>
                        </td>
                        <td>{item?.uniqueCompleted}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OverviewSelfDirectedTable;
