import { useEffect, useState } from 'react';
import { useProfileContext } from '../contexts/profileContext';
import { OPENED } from '../views/Deals/pipelines/Pipeline.constants';

const useDefaultFilter = (type) => {
  const { profileInfo } = useProfileContext();
  const [defaultMyFilter, setDefaultMyFilter] = useState({});
  useEffect(() => {
    const defaultFilter =
      type === 'deal'
        ? { assigned_user_id: profileInfo?.id, status: OPENED }
        : { assigned_user_id: profileInfo?.id };
    if (profileInfo?.id) {
      setDefaultMyFilter({
        filter: defaultFilter,
      });
    }
  }, [profileInfo]);
  return [defaultMyFilter, setDefaultMyFilter];
};

export default useDefaultFilter;
