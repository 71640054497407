import React, { useState, useEffect } from 'react';

import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import fieldService from '../../../services/field.service';
import InlineOrganizationForm from '../../organizations/InlineOrganizationForm';
import { useForm } from 'react-hook-form';
import { groupBy } from 'lodash';

const Organization = ({
  children,
  data,
  getProfileInfo,
  setProfileInfo,
  updateLabel,
  labelType,
  me,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFieldObj, setIsFieldObj] = useState({});
  const [customDataFields, setCustomDataFields] = useState([]);
  const [organizationFields, setOrganizationFields] = useState([]);
  const groupBySection = (fieldsList) => {
    setOrganizationFields(groupBy(fieldsList, 'section'));
  };
  const getFields = async () => {
    const fieldsData = await fieldService.getFields('organization', {
      usedField: true,
    });
    groupBySection(fieldsData?.data);
  };

  useEffect(() => {
    getFields();
  }, []);

  return (
    <>
      {children}
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <InlineOrganizationForm
        fields={organizationFields}
        data={data}
        editMode={editMode}
        setOrganizationFields={setOrganizationFields}
        setEditMode={setEditMode}
        isFieldsObj={isFieldObj}
        register={register}
        handleSubmit={handleSubmit}
        reset={reset}
        loading={loading}
        setLoading={setLoading}
        setValue={setValue}
        customDataFields={customDataFields}
        setCustomDataFields={setCustomDataFields}
        getFieldState={getFieldState}
        control={control}
        errors={errors}
        labelType={labelType}
        setIsFieldsObj={setIsFieldObj}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
        getProfileInfo={getProfileInfo}
        setProfileInfo={setProfileInfo}
        fromNavBar
        updateLabel={updateLabel}
      />
    </>
  );
};

export default Organization;
