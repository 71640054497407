import React, { useState, useEffect } from 'react';
import { Slide } from '@mui/material';
import MaterialIcon from '../commons/MaterialIcon';

const alertTypes = {
  success: {
    color: 'success',
    icon: 'check_circle',
    iconBg: 'bg-soft-alert-green text-success',
    bg: 'alert-green',
  },
  info: {
    color: 'info',
    icon: 'info',
    iconBg: 'bg-soft-alert-blue text-info',
    bg: 'alert-blue',
  },
  warning: {
    color: 'warning',
    icon: 'warning',
    iconBg: 'bg-soft-alert-yellow text-warning',
    bg: 'alert-yellow',
  },
  danger: {
    color: 'error',
    icon: 'cancel',
    iconBg: 'bg-soft-danger text-danger',
    bg: 'alert-red',
  },
};

const Alert = ({
  message,
  setMessage,
  color = alertTypes.success.color, // default
  time,
  alertWidth = 420,
  alertContentWidth = 300,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
      if ((time && time !== -1) || !time) {
        setTimeout(() => {
          setOpen(false);
        }, time || 4000);
      }
    }
  }, [message, time]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleExited = () => {
    setMessage('');
  };

  const styleConfig = alertTypes[color] || {};

  return (
    <Slide
      direction="down"
      in={open}
      onExited={handleExited}
      mountOnEnter
      unmountOnExit
    >
      <div
        className="d-flex mx-auto mb-3 justify-content-center"
        style={{ maxWidth: alertWidth }}
      >
        <div
          className={`d-flex gap-2 border rounded shadow-soft justify-content-between pr-2 ${styleConfig.bg} text-center align-items-center`}
        >
          <div className="d-flex align-items-center gap-2">
            <div
              className={`p-2_2 d-flex rounded-left align-items-center ${styleConfig.iconBg} justify-content-center`}
            >
              <MaterialIcon
                rounded
                filled
                icon={styleConfig.icon}
                clazz="font-size-2xl alert-icon"
              />
            </div>
            <span
              style={{ maxWidth: alertContentWidth }}
              className="text-black text-left font-weight-medium fs-7"
            >
              {message}
            </span>
          </div>
          <a
            className="icon-hover-bg cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            <MaterialIcon icon="close" rounded filled clazz="fs-5" />
          </a>
        </div>
      </div>
    </Slide>
  );
};

export default Alert;
