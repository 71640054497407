import React, { useEffect, useReducer, useState } from 'react';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import KnowledgeQuestions from './knowledgeAssessmentQuestions.json';
import KnowledgeAssessmentBanner from './KnowledgeAssessmentBanner';
import { useProfileContext } from '../../../contexts/profileContext';
import moment from 'moment/moment';
import { ReportTypes } from '../../../components/reports/reports.constants';
import { DATE_FORMAT_TIME_WO_SEC } from '../../../utils/Utils';
import ReportService from '../../../services/report.service';
export default function KnowledgeAssessment() {
  const { profileInfo } = useProfileContext();
  const assessmentQuestions = Object.values(KnowledgeQuestions);
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [, setAssessments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);
  const [assessment, updateAssessment] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      start: false,
      completed: false,
      showResults: false,
      finalResult: {},
      progress: 0,
      scored: 0,
    }
  );

  const calculateCorrectAnswers = (answers) => {
    return answers.filter((ans) => {
      return (
        ans.answer.userSelected === ans.answer.correctAnswer ||
        ans.answer.correctAnswer.includes(ans.answer.userSelected)
      );
    })?.length;
  };

  const hasPassed30Days = (inputDate) => {
    const inputMoment = moment(inputDate, 'YYYY-MM-DD');
    const currentMoment = moment();
    const daysDifference = currentMoment.diff(inputMoment, 'days');
    return daysDifference >= 30;
  };
  const getAssessments = async () => {
    try {
      setLoader(true);
      const { data } = await ReportService.getReports({
        type: ReportTypes.KnowledgeAssessment,
        limit: 100,
        page: 1,
      });
      const pastAssessments = data;
      setAssessments(pastAssessments);
      if (pastAssessments?.length) {
        pastAssessments.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        const userCreated = pastAssessments.find(
          (assessment) => assessment.createdById === profileInfo?.id
        );

        const are30DaysPassed = hasPassed30Days(userCreated?.date);
        // if no assessments or if past assessments created by current logged in user
        const hasCompleted =
          pastAssessments.length === 0 ||
          userCreated === undefined ||
          are30DaysPassed;

        setIsAllowed(hasCompleted);
        updateAssessment({
          completed: userCreated !== undefined,
          showResults: false,
          progress: userCreated !== undefined ? 100 : 0,
          start: userCreated !== undefined,
          scored:
            userCreated !== undefined
              ? userCreated?.manualInput?.scored ||
                calculateCorrectAnswers(
                  userCreated?.manualInput?.answers ||
                    userCreated?.manualInput?.finalResult
                )
              : 0,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const submitQuestionnaire = async (submissionRequest) => {
    try {
      setLoader(true);
      const now = moment();
      const name =
        profileInfo?.name ||
        `${profileInfo?.first_name} ${profileInfo?.last_name || ''}`;
      // correct answers count to show percentage at the end
      const correctAnswers = calculateCorrectAnswers(
        submissionRequest.finalResult
      );
      const reportObject = {
        name: `${name?.trim()} Knowledge Assessment - ${now.format(
          DATE_FORMAT_TIME_WO_SEC
        )}`,
        date: now.toISOString(),
        createdById: profileInfo.id,
        manualInput: {
          answers: submissionRequest.finalResult,
          scored: correctAnswers,
        },
        type: ReportTypes.KnowledgeAssessment,
      };
      await ReportService.createReport(reportObject);
      // once save user cannot take another
      setIsAllowed(false);
      // reset once submitted
      updateAssessment({
        start: true,
        completed: true,
        showResults: false,
        finalResult: {},
        progress: 100,
        scored: correctAnswers,
      });
    } catch (e) {
      console.log(e);
      setErrorMessage('Error in submitting knowledge assessment.');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    profileInfo?.id && getAssessments();
  }, [profileInfo]);
  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert color="info" message={infoMessage} setMessage={setInfoMessage} />
      </AlertWrapper>
      <div
        className="mx-xs-2 m-md-auto m-lg-auto mb-2"
        style={{ maxWidth: 900 }}
      >
        <KnowledgeAssessmentBanner
          loader={loader}
          isAllowed={isAllowed}
          assessment={assessment}
          setInfoMessage={setInfoMessage}
          updateAssessment={updateAssessment}
          assessmentQuestions={assessmentQuestions}
          submitQuestionnaire={submitQuestionnaire}
        />
      </div>
    </>
  );
}
