import { CardBody } from 'reactstrap';
import React from 'react';

const VendorsCountWidget = ({ widgetConfig, whenPrinting }) => {
  const formatNumber = (num) => {
    return num.toLocaleString('en-US');
  };

  return (
    <div
      className={`d-flex mt-2 py-2 align-items-center justify-content-around widget-border-2`}
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      {widgetConfig?.items?.map((item, index) => (
        <React.Fragment key={index}>
          <div>
            <CardBody className={`py-2 text-center`}>
              <p
                className={`font-weight-medium mb-0 sub-text fs-9`}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <h2
                className={`mb-0 font-weight-bolder text-rpt-heading fs-2 font-weight-semi-bold`}
              >
                {item.showCurrencySymbol
                  ? `$${formatNumber(widgetConfig?.data[item.key] || 0)}`
                  : formatNumber(widgetConfig?.data[item.key] || 0)}
              </h2>
            </CardBody>
          </div>
          {index < widgetConfig?.items?.length - 1 && (
            <div
              style={{
                width: '1px',
                backgroundColor: 'var(--rpt-widget-border-color-dark)',
                margin: '0 10px',
                height: '50px',
              }}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default VendorsCountWidget;
