import React, { useEffect, useState } from 'react';
import prospectService from '../services/prospect.service';
import { ProspectTypes } from '../components/prospecting/v2/constants';
import RRCompanyDetails from '../components/prospecting/v2/RRCompanyDetails';
import IconTextLoader from '../components/loaders/IconText';
import { Card } from 'react-bootstrap';
import { CardBody, CardHeader } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const ProspectCompanyDetails = ({
  companyDetailPage,
  setCompanyDetailPage,
}) => {
  const [company, setCompany] = useState({});
  const [socialLinks, setSocialLinks] = useState({});
  const [loader, setLoader] = useState(false);

  const swot = companyDetailPage?.swot;
  const id = companyDetailPage?.id;
  const name = companyDetailPage?.name;

  const fetchCompanyDetails = async (id, name, swot) => {
    setLoader(swot !== true);
    try {
      const idIncluded = id ? { id: [id] } : {};
      const { data } = await prospectService.query(
        { ...idIncluded },
        {
          page: 1,
          limit: 1,
          type: ProspectTypes.company,
        }
      );
      const companyDetails = data?.data[0];
      setCompany(companyDetails);
      setSocialLinks(companyDetails?.links || {});
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchCompanyDetails(id, name, swot);
  }, [id, name, swot]);

  const refreshCompany = (company) => {
    if (company) {
      fetchCompanyDetails(company?.id, company?.name, company?.swot);
    }
  };

  return (
    <>
      {loader ? (
        <LoaderSkeleton />
      ) : (
        <RRCompanyDetails
          company={company}
          socialLinks={socialLinks}
          allowBack={true}
          companyDetailPage={companyDetailPage}
          setCompanyDetailPage={setCompanyDetailPage}
          refreshCompany={refreshCompany}
        />
      )}
    </>
  );
};

export default ProspectCompanyDetails;

const LoaderSkeleton = () => {
  return (
    <>
      <div className="custom-layout-content p-0 bg-white position-relative">
        <Card className="w-full card-0">
          <CardHeader className="justify-content-center">
            <div className="d-flex align-items-center py-2">
              <span
                style={{ width: 70, height: 70 }}
                className="avatar-initials avatar-icon-font-size p-2 mr-2 rounded-circle text-primary"
              >
                <Skeleton height={70} width={70} circle />
              </span>
              <h1>
                <Skeleton height={15} width={300} />
                <br />
                <div className="d-flex align-items-center gap-2">
                  <Skeleton height={30} width={30} circle className="mr-2" />
                  <Skeleton height={30} width={30} circle className="mr-2" />
                  <Skeleton height={30} width={30} circle />
                </div>
              </h1>
            </div>
          </CardHeader>
          <CardBody className="justify-content-center text-center">
            <Skeleton height={10} width={600} />
            <br />
            <br />
            <Skeleton height={10} width={300} />
            <br />
            <br />
            <IconTextLoader count={8} />
          </CardBody>
        </Card>
      </div>
    </>
  );
};
