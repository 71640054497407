import ButtonIcon from '../commons/ButtonIcon';
import React from 'react';

const AddNewReportButton = ({
  readOnly,
  addView,
  onClick,
  label = 'Create New Report',
  icon = 'add',
}) => {
  return (
    <>
      {!readOnly && !addView ? (
        <>
          <ButtonIcon
            icon={icon}
            label={label}
            onclick={onClick}
            classnames="btn-sm"
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddNewReportButton;
