import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import ReportHoursChart from '../../../reports/ReportHoursChart';
import CutoutWidget from './CutoutWidget';

const HoursSpentWeeklyReconcilingPaymentsWidget = ({
  widgetConfig,
  whenPrinting,
}) => {
  return (
    <Card className="report-widget w-100">
      <CardBody className="justify-content-center align-items-center d-flex">
        <div className="flex-fill w-100">
          <h5 className="text-left d-flex justify-content-between mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-2">
            <p
              className={`mb-0 ${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } text-left`}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: widgetConfig.description,
                }}
              ></span>
            </p>
            <CutoutWidget
              widgetConfig={widgetConfig}
              whenPrinting={whenPrinting}
              cutoutConfig={{
                icon: 'schedule',
                text: `Time Breakdown of <br /> Survey Results`,
                bg: 'report-widget-inner-section-soft bg-white',
                iconConfig: {
                  fg: 'text-primary',
                  bg: 'bg-primary-soft',
                  icon: 'schedule',
                },
              }}
            >
              <div className="flex-fill position-relative w-100">
                <div className="w-100">
                  <ReportHoursChart
                    whenPrinting={whenPrinting}
                    barData={[
                      {
                        key: '< 1 hr',
                        value: 5,
                        percentage: '34%',
                        color: '65%',
                        multiply: 17,
                      },
                      {
                        key: '1 - 3 hrs',
                        value: 5.2,
                        percentage: '36%',
                        color: '50%',
                        multiply: 17,
                      },
                      {
                        key: '4 - 6 hrs',
                        value: 3,
                        percentage: '14%',
                        color: '85%',
                        multiply: 17,
                      },
                      {
                        key: '> 6 hrs',
                        value: 3.3,
                        percentage: '16%',
                        color: '75%',
                        multiply: 17,
                      },
                    ]}
                    chartStyle={{ maxWidth: 450 }}
                    barStyle={{ width: 80 }}
                    linesCount={whenPrinting ? 3 : 4}
                  />
                </div>
              </div>
            </CutoutWidget>
          </div>
          {widgetConfig?.source && (
            <div className={whenPrinting ? 'mt-2' : 'mt-4'}>
              <WidgetSourceBlock text={widgetConfig.source} />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default HoursSpentWeeklyReconcilingPaymentsWidget;
