import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import ReportBarChart from '../../../reports/ReportBarChart';
import { formatNumberV2, parseCurrency } from '../../../../utils/Utils';
import { LOCData } from '../../constants/widgetsConstants';

const splitRangeToObject = (minValue, maxValue, numIntervals = 5) => {
  const intervalSize = (maxValue - minValue) / numIntervals;
  const splitPoints = Array.from(
    { length: numIntervals - 1 },
    (_, i) => minValue + (i + 1) * intervalSize
  );

  const resultObject = { 0: formatNumberV2(maxValue) };
  splitPoints.reverse().forEach((splitPoint, index) => {
    resultObject[index + 1] = formatNumberV2(Math.round(splitPoint));
  });

  resultObject[numIntervals] = formatNumberV2(minValue); // Set the last key to minValue (0)

  return resultObject;
};

const YearChart = ({
  data,
  hideLineLimit,
  whenPrinting,
  chartConfig = { xAxisLabel: 'Average Monthly Usage' },
}) => {
  const lineLimit = parseCurrency(data?.loanOverview?.lineLimit || '1000000');
  const monthData = data?.loanOverview?.monthData || [];
  const intervals = splitRangeToObject(0, lineLimit, 5);
  // based on lineLimit we need to create dynamic y-axis values
  return (
    <div
      className={`position-relative ${
        whenPrinting ? 'pl-6 pt-4 pb-5' : 'pl-4 pb-6 pt-3'
      }`}
    >
      <ReportBarChart
        barData={monthData}
        lineLimit={lineLimit}
        chartStyle={{ maxWidth: whenPrinting ? 520 : 'calc(100% - 100px)' }}
        barStyle={{ width: 50 }}
        hideLineLimit={hideLineLimit}
        linesCount={6}
        lineHeight={25}
        withIntervals={intervals}
      />
      <span
        style={{ transform: 'rotate(270deg)', left: whenPrinting ? -60 : -50 }}
        className="fs-12 font-weight-semi-bold text-uppercase position-absolute abs-center-y"
      >
        {chartConfig.xAxisLabel}
      </span>
      <span
        style={{ bottom: whenPrinting ? -10 : 0 }}
        className="fs-12 font-weight-semi-bold text-uppercase position-absolute abs-center"
      >
        Month
      </span>
    </div>
  );
};

const LineOfCreditWidget = ({ widgetConfig, whenPrinting, chartConfig }) => {
  return (
    <Card className="report-widget">
      <CardBody className="d-flex flex-column w-100">
        {!widgetConfig?.hideHeading && (
          <h5 className="text-left d-flex mb-1">
            {widgetConfig.heading || LOCData.heading}
          </h5>
        )}
        <div className="flex-fill px-2">
          <div className="widget-extra-padding">
            {!widgetConfig?.hideHeading && (
              <p
                className={`mb-0 ${
                  whenPrinting ? 'fs-9' : 'font-size-sm2'
                } text-left`}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: widgetConfig.description || LOCData.description,
                  }}
                />
              </p>
            )}
            <YearChart
              data={widgetConfig?.data}
              hideLineLimit={widgetConfig.hideLineLimit}
              whenPrinting={whenPrinting}
              chartConfig={chartConfig}
            />
            {widgetConfig.source && (
              <WidgetSourceBlock text={widgetConfig.source} />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default LineOfCreditWidget;
