import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: {},
};

const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export default userReducer.reducer;
