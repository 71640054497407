import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import TextRoundBlock from '../../blocks/TextRoundBlock';
import MaterialIcon from '../../../commons/MaterialIcon';

const CheckPaymentsMoreRiskWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="bg-primary-soft justify-content-center align-items-center d-flex">
        <div>
          <h5 className="text-left d-flex mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div
            style={{ gap: 10 }}
            className={`d-flex align-items-center justify-content-center py-2`}
          >
            <TextRoundBlock
              big="$2.00"
              small="Cost Per Check"
              color="text-danger"
              bg="#ffffff"
            />
            <MaterialIcon
              icon="arrow_right_alt"
              symbols
              clazz="font-size-2em"
            />
            <TextRoundBlock
              big="$0.30"
              color="rpt-green-box-heading"
              small="Per Card Transaction"
              bg="#ffffff"
            />
            <MaterialIcon
              icon="arrow_right_alt"
              symbols
              clazz="font-size-2em"
            />
            <TextRoundBlock
              big="$0.25"
              color="rpt-green-box-heading"
              small="Per ACH Transaction"
              bg="#ffffff"
            />
          </div>
          <p
            className={`mb-0 ${
              whenPrinting ? 'fs-9' : 'font-size-sm2'
            } text-left`}
          >
            <span
              dangerouslySetInnerHTML={{ __html: widgetConfig.description }}
            ></span>
          </p>
          {widgetConfig.source && (
            <WidgetSourceBlock text={widgetConfig.source} />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default CheckPaymentsMoreRiskWidget;
