import React, { useState, useEffect } from 'react';
import AssignmentsTable from './AssignmentsTable';
import { Card } from 'react-bootstrap';
import ModalCreateAssignment from './ModalCreateAssignment';
import SimpleModalCreation from '../modal/SimpleModalCreation';
import LayoutHead from '../commons/LayoutHead';
import { DataFilters } from '../DataFilters';
import { ADD_ASSIGNMENTS, SEARCH_ASSIGNMENTS } from '../../utils/constants';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import assignmentService from '../../services/assignment.service';
import TableSkeleton from '../commons/TableSkeleton';
import DeleteConfirmationModal from '../../components/modal/DeleteConfirmationModal';
import { sortingTable } from '../../utils/sortingTable';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import ModalConfirmDefault from '../modal/ModalConfirmDefault';
import { formatText, isModuleAllowed } from '../../utils/Utils';
import { useProfileContext } from '../../contexts/profileContext';
import { useTenantContext } from '../../contexts/TenantContext';
import NoDataFound from '../commons/NoDataFound';
import PillFilters from '../commons/PillFilters';

const FilterStatuses = [
  {
    id: 'all',
    label: 'All',
    status: 'all',
  },
  {
    id: 'active',
    label: 'Active',
    status: 'active',
  },
  {
    id: 'inactive',
    label: 'Inactive',
    status: 'inactive',
  },
];

const Assignments = () => {
  const { profileInfo } = useProfileContext();
  const { tenant } = useTenantContext();
  if (
    !isModuleAllowed(tenant.modules, 'learns_lesson_catalog') &&
    !isModuleAllowed(tenant.modules, 'learns_assignments') &&
    isModuleAllowed(tenant.modules, 'settings_learn') &&
    !profileInfo.role.admin_access
  ) {
    return (
      <>
        <NoDataFound
          icon="manage_search"
          containerStyle="text-gray-search my-6 py-6"
          title={'No learn lesson catalog & assignments permissions'}
        />
      </>
    );
  }
  const defaultPagination = { page: 1, limit: 20 };
  const [filterOptionSelected, setFilterOptionSelected] = useState({
    id: 1,
    key: '',
    name: 'All',
  });
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [assignmentToDelete, setAssignmentToDelete] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [assignmentToEdit, setAssignmentToEdit] = useState([]);
  const [allAssignments, setAllAssignments] = useState([]);
  const [filterSelected, setFilterSelected] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [order, setOrder] = useState([['createdAt', 'DESC']]);
  const [errorMessage, setErrorMessage] = useState('');
  const [dataInDB, setDataInDB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [assignFilter, setAssignFilter] = useState(FilterStatuses[0]);

  const onClose = () => {
    setOpenAssignModal(false);
    setIsEdit(false);
  };

  const AssignFilterOptions = [
    { id: 1, key: '', name: 'All' },
    { id: 2, key: 'active', name: 'Active' },
    { id: 3, key: 'inactive', name: 'Inactive' },
  ];

  const onHandleFilterAssignment = (e, option) => {
    e.preventDefault();
    setFilterOptionSelected(option);
    setPagination((prev) => ({ ...prev, page: 1 }));
    if (option.key === 'createdAt') {
      setFilterSelected({
        order: [['createdAt', 'DESC']],
      });
    } else {
      if (option.key !== '') {
        setFilterSelected({
          status: option.key,
        });
      } else {
        setFilterSelected({});
      }
    }
  };

  const fetchAllAssignments = async (count) => {
    setLoading(true);

    const response = await assignmentService.getAssignments({
      page: pagination.page,
      limit: pagination.limit,
      order,
      ...filterSelected,
      retrieveAssigned: false,
    });

    setAllAssignments(response?.data);
    setPagination(response?.pagination);
    if (count) setDataInDB(Boolean(response?.pagination?.totalPages));
    setLoading(false);
  };

  const onHandleChangePage = (page) => {
    setPagination((prevState) => ({ ...prevState, page }));
  };

  const handleDelete = (assignment) => {
    setAssignmentToDelete([assignment]);
    setShowDeleteModal(true);
  };

  const handleEditFullAssignment = async (assignment) => {
    setAssignmentToEdit(assignment);
    setIsEdit(true);
    setOpenAssignModal(true);
  };

  const handleUpdate = async (modAssign) => {
    setSuccessMessage(modAssign);
    fetchAllAssignments(true);
  };

  const handleDeleteAssignment = async () => {
    const singleAssignment = assignmentToDelete[0];
    try {
      await assignmentService.deleteAssignment([
        singleAssignment?.assignmentId,
      ]);
      setSuccessMessage('Assignment Deleted');
    } catch (e) {
      if (e.response.status === 409) {
        setErrorMessage(formatText(e.response.data.errors[0].message));
      } else {
        setErrorMessage(formatText(e.response.data.errors[0].message));
      }
    } finally {
      setAssignmentToDelete([]);
      setShowDeleteModal(false);
      fetchAllAssignments(true);
    }
  };

  useEffect(() => {
    fetchAllAssignments(true);
  }, [filterSelected, pagination?.page, order, assignFilter]);

  const showAssignmentModalEdit = () => {
    setOpenAssignModal(true);
  };

  const handleUpdateStatus = async (assignment) => {
    setAssignmentToEdit(assignment);
    setShowUpdateStatusModal(true);
  };

  const confirmUpdateStatus = async () => {
    setDisableBtn(true);
    const statueToSave =
      assignmentToEdit.status === 'active' ? 'inactive' : 'active';
    const dataToEdit = {
      title: assignmentToEdit.title,
      status: statueToSave,
      dueAt: assignmentToEdit.dueAt,
    };
    try {
      await assignmentService.updateAssignmentById(
        assignmentToEdit.assignmentId,
        dataToEdit
      );
      setSuccessMessage(
        `Status successfully changed for ${assignmentToEdit.title}`
      );
    } catch (error) {
      console.error('Error updating assignment:', error);
    } finally {
      setShowUpdateStatusModal(false);
      fetchAllAssignments(true);
      setDisableBtn(false);
    }
  };

  const sortTable = ({ name }) => {
    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);
  };
  return (
    <>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <DeleteConfirmationModal
        showModal={showDeleteModal}
        description={'You want to delete this assignment!'}
        setShowModal={setShowDeleteModal}
        setSelectedCategories={setAssignmentToDelete}
        event={handleDeleteAssignment}
        itemsReport={[]}
        itemsConfirmation={assignmentToDelete}
      />

      <ModalConfirmDefault
        open={showUpdateStatusModal}
        onHandleConfirm={confirmUpdateStatus}
        onHandleClose={() => {
          setShowUpdateStatusModal(false);
        }}
        textBody={`Do you want to update the status for assignment ${assignmentToEdit.title}?`}
        labelButtonConfirm="Yes, Update"
        iconButtonConfirm="edit"
        loading={disableBtn}
        colorButtonConfirm="outline-danger"
      />
      <div className="row justify-content-center">
        <div className="col-lg-12 pt-0">
          <Card className="mb-5 rounded-0 card-borderinline shadow-none border-bottom-0 border-0">
            <Card.Header className="border-0  py-2 w-100">
              <div className="d-flex align-items-center w-100 justify-content-between">
                <div>
                  <PillFilters
                    items={FilterStatuses}
                    currentFilter={assignFilter}
                    onFilterChange={(newFilter) => {
                      if (newFilter.status === 'all') {
                        setFilterSelected({});
                      } else {
                        setFilterSelected({
                          status: newFilter.status,
                        });
                      }
                      setAssignFilter(newFilter);
                    }}
                  />
                </div>
                <LayoutHead
                  onHandleCreate={showAssignmentModalEdit}
                  buttonLabel={ADD_ASSIGNMENTS}
                  dataInDB={dataInDB}
                >
                  <DataFilters
                    searchClasses="col-md-12"
                    searchPlaceholder={SEARCH_ASSIGNMENTS}
                    filterSelected={filterSelected}
                    setFilterSelected={setFilterSelected}
                    paginationPage={pagination}
                    setPaginationPage={setPagination}
                  />
                  <div className="d-none">
                    <ButtonFilterDropdown
                      options={AssignFilterOptions}
                      filterOptionSelected={filterOptionSelected}
                      handleFilterSelect={onHandleFilterAssignment}
                    />
                  </div>
                </LayoutHead>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              <SimpleModalCreation
                open={openAssignModal}
                setOpenAssignModal={setOpenAssignModal}
                size={'xxl'}
                modalTitle={isEdit ? 'Edit Assignment' : ADD_ASSIGNMENTS}
                onHandleCloseModal={onClose}
                customModal="modal-assigncontent"
                bankTeam={true}
                noFooter={true}
                bodyClassName="pt-0 pb-0"
              >
                <ModalCreateAssignment
                  onUpdate={handleUpdate}
                  setOpenAssignModal={setOpenAssignModal}
                  dataToEdit={assignmentToEdit}
                  isEdit={isEdit}
                />
              </SimpleModalCreation>
              {loading && <TableSkeleton cols={5} rows={10} />}

              {!loading && (
                <AssignmentsTable
                  data={allAssignments}
                  paginationInfo={pagination}
                  onPageChange={onHandleChangePage}
                  dataInDB={dataInDB}
                  sortingTable={sortTable}
                  sortingOrder={order}
                  handleDelete={handleDelete}
                  handleUpdateStatus={handleUpdateStatus}
                  handleEdit={showAssignmentModalEdit}
                  handleEditFullAssignment={handleEditFullAssignment}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Assignments;
