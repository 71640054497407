import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { overflowing } from '../../../utils/Utils';
import SimpleModalCreation from '../../modal/SimpleModalCreation';
import InputValidation from '../../commons/InputValidation';
import { Col, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import roleService from '../../../services/role.service';

const InviteFormGroup = ({
  label,
  component,
  alignLabel = 'align-items-center',
}) => {
  return (
    <FormGroup className={`mb-0 ${alignLabel} mt-0`} row>
      <Label md={3} className="text-right py-0 font-size-sm">
        {label}
      </Label>
      <Col md={9} className="pl-0 py-0">
        {component}
      </Col>
    </FormGroup>
  );
};

const RoleNameDescriptionEditModal = ({ show, setShow, data, modalConfig }) => {
  const [saving, setSaving] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const [roleData, setRoleData] = useState({ ...data });
  const { setSuccessMessage, refresh, refreshTable } = modalConfig;
  useEffect(() => {
    if (show) {
      setValue('name', data.name);
      setValue('description', data.description);
    }
  }, [show]);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setRoleData({
      ...roleData,
      [name]: value,
    });
    setValue(name, value);
  };
  const updateProfile = async () => {
    try {
      setSaving(true);
      await roleService.updateRole(roleData);
      setSuccessMessage('Profile updated.');
      // update parent modal heading
      refresh(roleData);
      // update parent-parent table :\
      refreshTable();
      setShow(false);
    } catch (err) {
      console.log(err);
    } finally {
      setSaving(false);
    }
  };
  return (
    <>
      <SimpleModalCreation
        modalHeaderClasses="flex-fill"
        modalTitle="Edit Profile"
        saveButtonStyle="btn btn-primary btn-sm d-flex align-items-center"
        open={show}
        isLoading={saving}
        bankTeam={false}
        handleSubmit={handleSubmit(updateProfile)}
        onHandleCloseModal={() => {
          overflowing();
          setShow(!show);
        }}
      >
        <div className="d-flex flex-column gap-3">
          <InviteFormGroup
            label="Name"
            component={
              <InputValidation
                name="name"
                type="input"
                placeholder=""
                value={roleData.name || ''}
                errorDisplay="mb-0"
                classNames="mr-2"
                validationConfig={{
                  required: 'Name cannot be empty.',
                  inline: false,
                  borderLeft: true,
                  onChange: handleOnChange,
                }}
                errors={errors}
                register={register}
              />
            }
          />

          <InviteFormGroup
            label="Description"
            component={
              <InputValidation
                name="description"
                type="textarea"
                placeholder=""
                value={roleData.description || ''}
                errorDisplay="mb-0"
                classNames="mr-2"
                validationConfig={{
                  onChange: handleOnChange,
                }}
                errors={errors}
                register={register}
              />
            }
          />
        </div>
      </SimpleModalCreation>
    </>
  );
};

const useRoleNameDescriptionEditModal = (modalConfig) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const RoleNameDescriptionEditModalCallback = useCallback(() => {
    return (
      <RoleNameDescriptionEditModal
        show={showModal}
        setShow={setShowModal}
        data={data}
        modalConfig={modalConfig}
      />
    );
  }, [showModal, setShowModal, data, setData]);

  return useMemo(
    () => ({
      setShowModal,
      setData,
      RoleNameDescriptionEditModal: showModal
        ? RoleNameDescriptionEditModalCallback
        : () => {},
    }),
    [setShowModal, RoleNameDescriptionEditModalCallback]
  );
};

export default useRoleNameDescriptionEditModal;
