import { Card, CardBody } from 'reactstrap';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import React from 'react';
import { ICFData } from '../../constants/widgetsConstants';
import { isToFixedNoRound, roundOrShowAll } from '../../../../utils/Utils';

const ProgressBarLabel = ({ data, whenPrinting }) => {
  return (
    <div className="py-2">
      <p className={`fs-7 mb-1 font-weight-semi-bold`}>
        {isNaN(data.value) ? '$0' : isToFixedNoRound(Math.abs(data.value), 0)}
      </p>
      <div className="d-flex align-items-center gap-2 my-1 justify-content-center w-100">
        <div
          className="rpt-bg-dark-gray flex-grow-1"
          style={{ borderRadius: 'var(--borderRadius)' }}
        >
          <ProgressBar
            style={{
              height: 12,
              borderRadius: 'var(--rpt-widget-border-radius)',
            }}
            isChild={true}
            now={parseInt(data.percentage || '0')}
            max={100}
            key={1}
          />
        </div>
      </div>
      <p
        className={`mb-0 ${
          whenPrinting ? 'fs-10 sub-text' : 'fs-9'
        } mb-0 text-truncate`}
      >
        {data.text}
      </p>
    </div>
  );
};

const calculatePercentage = (type, current, total) => {
  const perc = (Math.abs(current) / Math.abs(total)) * 100;
  return perc > 100 ? 100 : perc;
};

const ImproveYourCashFlowWidget = ({
  widgetConfig,
  whenPrinting,
  selectedTenant,
}) => {
  const total = Math.abs(widgetConfig?.data?.totalImprovementToIndustryAvg);
  const value6 = ICFData.moreDescription[0].replace(
    '$X',
    isToFixedNoRound(total, 0)
  );
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center align-items-center d-flex">
        <div className="flex-fill">
          <h5 className="text-left d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-1 widget-extra-padding">
            <Row className={`align-items-center position-relative`}>
              <Col md={5} className="position-relative">
                <div
                  style={{
                    height: 130,
                    width: '100%',
                    borderRadius: 'var(--rpt-widget-border-radius) !important',
                  }}
                  className="rpt-green-box border-2"
                >
                  <div
                    className="position-absolute abs-center-xy px-3"
                    style={{ width: 200 }}
                  >
                    <div className={`mb-1 fw-bolder text-black`}>
                      <div className="d-flex gap-1 justify-content-center align-items-center">
                        <h1 className="mb-0 text-black font-weight-bold">
                          {roundOrShowAll(total, 999999999)}
                        </h1>
                      </div>
                    </div>
                    <p className="fs-9 mb-0 d-flex flex-column text-center sub-text">
                      <span>Cash unlocked by improving </span>
                      <span>your DPO and DSO to the</span>
                      <span>industry average</span>
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={7} className="text-left font-size-sm2 ml-0 pl-1">
                <ProgressBarLabel
                  data={{
                    text: 'Cash unlocked by improving your DPO to the industry average',
                    percentage: calculatePercentage(
                      'DPO',
                      widgetConfig?.data?.dpoImprovmentToIndustryAvg,
                      widgetConfig?.data?.totalImprovementToIndustryAvg
                    ),
                    value: widgetConfig?.data?.dpoImprovmentToIndustryAvg,
                  }}
                  whenPrinting={whenPrinting}
                />
                <ProgressBarLabel
                  data={{
                    text: 'Cash unlocked by improving your DSO to the industry average',
                    percentage: calculatePercentage(
                      'DSO',
                      widgetConfig?.data?.dsoImprovmentToIndustryAvg,
                      widgetConfig?.data?.totalImprovementToIndustryAvg
                    ),
                    value: widgetConfig?.data?.dsoImprovmentToIndustryAvg,
                  }}
                  whenPrinting={whenPrinting}
                />
              </Col>
            </Row>
            <p className={whenPrinting ? 'mb-1 fs-9' : 'mt-3 font-size-sm2'}>
              {widgetConfig.description}
            </p>
            <p className={`${whenPrinting ? 'fs-9' : 'font-size-sm2'} mb-0`}>
              {widgetConfig?.data?.improved
                ? ICFData.moreDescription[1]
                : value6}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ImproveYourCashFlowWidget;
