import axios from 'axios';
import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

const API_URL = process.env.REACT_APP_API_URL + '/api';

class CheckListService extends BaseRequestService {
  async getCheckLists({ page = 1, limit = 10, search, ...rest }) {
    const params = {
      page,
      limit,
      search,
      ...rest,
    };

    return await axios.get(`${API_URL}/checklists`, {
      params,
      headers: authHeader(),
    });
  }

  async getOrgCheckLists({ page = 1, limit = 10, ...rest }) {
    const params = {
      page,
      limit,
      ...rest,
    };

    return await axios.get(`${API_URL}/organizationChecklists`, {
      params,
      headers: authHeader(),
    });
  }

  async getCheckListItemsById(id, { page = 1, limit = 20, type }) {
    const params = {
      page,
      limit,
      type,
    };
    const response = await axios.get(
      `${API_URL}/checklists/${id}/items`,
      {
        params,
        headers: authHeader(),
      },
      {}
    );

    return response;
  }

  async getCheckListsById(id) {
    return await axios.get(`${API_URL}/checklists/${id}`, {
      headers: authHeader(),
    });
  }

  async getOrgCheckList(id, { page = 1, limit = 10, ...rest }) {
    const params = {
      organizationId: id,
      page,
      limit,
      ...rest,
    };
    const response = await axios.get(
      `${API_URL}/organizationChecklists`,
      {
        params,
        headers: authHeader(),
      },
      {}
    );

    return response;
  }

  async getOrgCheckListsById(id) {
    return await axios.get(`${API_URL}/organizationChecklists/${id}`, {
      headers: authHeader(),
    });
  }

  async createCheckLists(data) {
    return await axios.post(`${API_URL}/checklists`, data, {
      headers: authHeader(),
    });
  }

  async updateCheckLists(data, id) {
    return await axios.put(`${API_URL}/checklists/${id}`, data, {
      headers: authHeader(),
    });
  }

  async getCheckListsItemId(id) {
    return await axios.get(`${API_URL}/checklistItems/${id}`, {
      headers: authHeader(),
    });
  }

  async getChecklistByContact(id, { page = 1, limit = 10 }) {
    const params = {
      contactId: id,
      page,
      limit,
    };
    return await axios.get(`${API_URL}/organizationChecklistContacts`, {
      params,
      headers: authHeader(),
    });
  }

  async createCheckListItem(data) {
    return await axios.post(`${API_URL}/checklistItems`, data, {
      headers: authHeader(),
    });
  }

  async addOrgChecklist(data) {
    return await axios.post(`${API_URL}/organizationChecklists`, data, {
      headers: authHeader(),
    });
  }

  async checklistOrgDelete(id) {
    return await axios.delete(`${API_URL}/organizationChecklists/${id}`, {
      headers: authHeader(),
    });
  }

  async addContactChecklist(data) {
    return await axios.post(`${API_URL}/organizationChecklistContacts`, data, {
      headers: authHeader(),
    });
  }

  async updateOrgChecklist(data, id) {
    return await axios.put(`${API_URL}/checklists/${id}/organizations`, data, {
      headers: authHeader(),
    });
  }

  async updateCheckListItem(id, data) {
    return await axios.put(`${API_URL}/checklistItems/${id}`, data, {
      headers: authHeader(),
    });
  }

  async deleteCheckList(id) {
    return axios.delete(`${API_URL}/checklists/${id}`, {
      headers: authHeader(),
    });
  }

  async deleteCheckListContact(id) {
    return axios.delete(`${API_URL}/organizationChecklistContacts/${id}`, {
      headers: authHeader(),
    });
  }

  async deleteCheckListItem(id) {
    return axios.delete(`${API_URL}/checklistItems/${id}`, {
      headers: authHeader(),
    });
  }

  async updateCheckList(id, data) {
    return await axios.put(
      `${API_URL}/${id}`,
      data,
      { headers: authHeader() },
      { fullResponse: true }
    );
  }

  async getOrgCheckListProgress({
    limit = 10,
    page = 1,
    organizationChecklistId,
  }) {
    const params = {
      limit,
      page,
      organizationChecklistId,
    };
    return await axios.get(`${API_URL}/organizationChecklistProgress`, {
      params,
      headers: authHeader(),
    });
  }

  getChecklistAggregateStatus(data) {
    return axios.post(
      `${API_URL}/organizationChecklistProgress/aggregate/status/byDueDate`,
      data,
      {
        headers: authHeader(),
      }
    );
  }

  getChecklistAggregateCountByStatus(data) {
    return axios.post(
      `${API_URL}/organizationChecklists/aggregate/count/byStatus`,
      data,
      {
        headers: authHeader(),
      }
    );
  }

  async createOrgCheckListItemProgress(id, data) {
    return await axios.put(
      `${API_URL}/organizationChecklistItemProgress/${id}`,
      data,
      {
        headers: authHeader(),
      }
    );
  }

  async ownerAttachWithChecklist(id, user_id, data) {
    return await axios.post(
      `${API_URL}/organizationChecklists/${id}/owners/${user_id}`,
      data,
      {
        headers: authHeader(),
      }
    );
  }

  async createOrgCheckListFile(id, data) {
    return await axios.post(
      `${API_URL}/organizationChecklists/${id}/files`,
      data,
      {
        headers: authHeader(),
      }
    );
  }

  async getChecklistOwners({ organizationChecklistId, limit, page }) {
    const params = {
      limit,
      page,
      organizationChecklistId,
    };
    return await axios.get(
      `${API_URL}/organizationChecklists/${organizationChecklistId}/owners`,
      {
        params,
        headers: authHeader(),
      }
    );
  }

  async deleteCheckListOrgFile(id) {
    return axios.delete(`${API_URL}/organizationChecklistFiles/${id}`, {
      headers: authHeader(),
    });
  }

  async getCheckListFiles(id, { page = 1, limit = 20, type }) {
    const params = {
      page,
      limit,
      type,
      organizationChecklistId: id,
    };
    return await axios.get(`${API_URL}/organizationChecklistFile`, {
      params,
      headers: authHeader(),
    });
  }

  async getOrgCheckListItemProgress({
    limit = 10,
    page = 1,
    organizationChecklistProgressId,
  }) {
    const params = {
      limit,
      page,
      organizationChecklistProgressId,
    };
    return await axios.get(`${API_URL}/organizationChecklistItemProgress`, {
      params,
      headers: authHeader(),
    });
  }

  async getChecklistFiles({ limit = 10, page = 1, organizationChecklistId }) {
    const params = {
      limit,
      page,
      organizationChecklistId,
    };
    return await axios.get(`${API_URL}/organizationChecklistFile`, {
      params,
      headers: authHeader(),
    });
  }

  async getOrganizationChecklistContacts({
    limit = 10,
    page = 1,
    organizationChecklistId,
  }) {
    const params = {
      limit,
      page,
      organizationChecklistId,
    };
    return await axios.get(`${API_URL}/organizationChecklistContacts`, {
      params,
      headers: authHeader(),
    });
  }
}

export default new CheckListService();
