import { CardBody } from 'reactstrap';
import React from 'react';

const TextRoundBlock = ({
  big,
  small,
  fill = 'shadow-lg border',
  centered = 'py-2 text-center',
  color,
  bg = 'rgba(255, 255, 255, 0.50)',
  smallStyle = 'fs-9',
}) => {
  return (
    <div
      className={fill}
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: bg,
      }}
    >
      <CardBody className={centered}>
        <h1 className={`mb-0 font-weight-bold ${color}`}>{big || 0}</h1>
        <p
          className={`mb-0 sub-text ${smallStyle}`}
          dangerouslySetInnerHTML={{ __html: small }}
        />
      </CardBody>
    </div>
  );
};

export default TextRoundBlock;
