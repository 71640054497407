import { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { overflowing } from '../../../utils/Utils';
import WriteAIPanel from './WriteAIPanel';

const WriteAI = ({ profileInfo }) => {
  const [showWriteAIPanel, setShowWriteAIPanel] = useState(false);

  const onNewsClick = () => {
    setShowWriteAIPanel(true);
  };

  useEffect(() => {
    return () => {
      setShowWriteAIPanel(false);
      overflowing();
    };
  }, []);
  return (
    <>
      <OverlayTrigger
        key="newsStand"
        placement="bottom"
        overlay={
          <Tooltip
            id={`tooltip-niacs}`}
            className={`tooltip-profile font-weight-bold`}
          >
            <p>Write</p>
          </Tooltip>
        }
      >
        <div className="nav-item" onClick={onNewsClick}>
          <div className="nav-icon cursor-pointer">
            <span className="material-symbols-rounded fs-5 text-white bg-orange rounded-circle p-1">
              draw
            </span>
          </div>
        </div>
      </OverlayTrigger>
      {showWriteAIPanel && (
        <WriteAIPanel
          newsstandModal={showWriteAIPanel}
          setNewsstandModal={setShowWriteAIPanel}
          profileInfo={profileInfo}
        />
      )}
    </>
  );
};

export default WriteAI;
