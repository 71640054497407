import userService from '../../services/user.service';
import { Dropdown, Tab, Tabs } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import MaterialIcon from './MaterialIcon';
import ButtonFilterDropdown from './ButtonFilterDropdown';
import { useProfileContext } from '../../contexts/profileContext';
import Avatar from '../Avatar';
import { STATUS_ACTIVE } from '../../utils/constants';
import IconTextLoader from '../loaders/IconText';
import { Input } from 'reactstrap';
import SelectDefault from './SelectDefault';
import ButtonIcon from './ButtonIcon';

const FilterTabsButtonDropdown = ({
  buttonText,
  options,
  filterTabs,
  filterOptionSelected,
  setFilterOptionSelected,
  handleFilterSelect,
  filterSelected,
  setFilterSelected,
  setFilterTabs,
  openFilter,
  setOpenFilter,
  onHandleFilterOwner,
  defaultSelection,
  extraClasses,
  setModified,
  selectedOwner,
  setSelectedOwner,
  valueFilterOptions,
  amountRangeSelected = {},
  setAmountRangeSelected,
  probabilityRangeSelected = {},
  setProbabilityRangeSelected,
  handleSelectedValueFilter,
  handleRemoveValueFilter,
  module,
}) => {
  const filterKeys = options.map((k) => k.key);
  const { profileInfo } = useProfileContext();
  const [filterSearch, setFilterSearch] = useState({
    name: !filterKeys.includes(filterOptionSelected?.key)
      ? filterOptionSelected?.name || ''
      : '',
  });
  const [ownersData, setOwnersData] = useState([]);
  const [searchOwner, setSearchOwner] = useState();
  const [loading, setLoading] = useState(false);
  const getOwners = async () => {
    setLoading(true);
    const searchResults = await userService
      .getUsers(
        { ...{ search: searchOwner?.search, status: STATUS_ACTIVE } }, // only get active users
        {
          page: 1,
          limit: 3,
        }
      )
      .catch((err) => console.log(err));
    const { data } = searchResults || {};

    const filteredUsersData = data?.users?.map((item) => ({
      ...item,
      name: `${item.first_name} ${item.last_name}`,
    }));
    setOwnersData(filteredUsersData);
    setLoading(false);
  };

  const onInputSearch = (e) => {
    const { value } = e.target || {};
    setSearchOwner({
      search: value,
    });
  };

  const handleOwnerClick = (e, item) => {
    setOpenFilter(false);
    setSelectedOwner(item);
    onHandleFilterOwner(item);
    setFilterSearch(item);
  };

  const renderOwners = () => {
    if (loading) {
      return (
        <div className="px-3">
          <IconTextLoader count={3} />
        </div>
      );
    }
    if (ownersData?.length > 0) {
      return ownersData.map((item) => (
        <div
          key={item.id}
          onClick={(e) => handleOwnerClick(e, item)}
          className={`py-2 d-flex align-items-center gap-1 fs-7 bg-hover-gray text-black btn-outline-primary px-3 ${
            item.id === selectedOwner?.id ? 'bg-primary-soft' : ''
          }`}
        >
          <Avatar user={item} defaultSize="xs" />
          <span>{item.name}</span>
        </div>
      ));
    }
    return (
      <p className="mb-0 text-center w-100 p-2 text-muted">No data found.</p>
    );
  };

  useEffect(() => {
    if (filterTabs === 'owners') {
      getOwners();
    }
  }, [searchOwner?.search, filterTabs]);

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setProbabilityRangeSelected((prevState) => ({
      ...prevState,
      all: isChecked,
      min: isChecked ? 0 : prevState.min,
      max: isChecked ? 100 : prevState.max,
    }));
  };

  const handleMinChange = (e) => {
    const value = e.target.value;
    // if (value < 100 && value >= 0 && value < probabilityRangeSelected.max) {
    setProbabilityRangeSelected((prevState) => ({
      ...prevState,
      min: value,
    }));
  };

  const handleMaxChange = (e) => {
    const value = e.target.value;
    // if (value <= 100 && value > probabilityRangeSelected.min) {
    setProbabilityRangeSelected((prevState) => ({
      ...prevState,
      max: value,
    }));
  };

  return (
    <ButtonFilterDropdown
      filterOptionSelected={filterOptionSelected}
      options={options}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      handleFilterSelect={handleFilterSelect}
      buttonText={buttonText}
    >
      <Dropdown.Menu
        className={`p-0 ${extraClasses} z-index-250`}
        style={{ minWidth: 320 }}
      >
        <Tabs
          fill
          justify
          id="controlled-tab-example"
          activeKey={filterTabs}
          onSelect={(k) => {
            setFilterTabs(k);
          }}
          className="mb-1 w-100 idf-tabs"
        >
          <Tab
            eventKey="filters"
            title={
              <span className="d-flex flex-column justify-content-center gap-1 align-items-center">
                <MaterialIcon icon="filter_list" />
                <span> Filters </span>
              </span>
            }
          >
            <div className="py-1 idf-dropdown-item-list">
              {options.map((option) => (
                <Dropdown.Item
                  key={option.id}
                  href="#"
                  onClick={(e) => handleFilterSelect(e, option)}
                  className="px-3"
                >
                  <div className="d-flex align-items-center justify-content-between py-1">
                    <span
                      className={
                        filterOptionSelected?.key === option.key
                          ? 'fw-bold'
                          : ''
                      }
                    >
                      {option.name}
                    </span>
                    {filterOptionSelected?.key === option.key && (
                      <MaterialIcon icon="check" clazz="fw-bold" />
                    )}
                  </div>
                </Dropdown.Item>
              ))}
            </div>
          </Tab>

          {module === 'deal' && (
            <Tab
              eventKey="value"
              title={
                <span className="d-flex flex-column justify-content-center gap-1 align-items-center">
                  <MaterialIcon icon="paid" />
                  <span> Value </span>
                </span>
              }
            >
              <div className="pt-1 pb-3 px-3 d-flex flex-column gap-3">
                <div className="d-flex flex-column gap-1 position-relative font-size-sm2">
                  <label
                    htmlFor="value-filter-dropdown"
                    className="mb-0 d-block text-nowrap flex-1"
                  >
                    Value
                  </label>
                  <SelectDefault
                    id="value-filter-dropdown"
                    value={amountRangeSelected}
                    onChange={(e) => setAmountRangeSelected(e.target.value)}
                    items={valueFilterOptions}
                    placeholderDisabled={false}
                    placeholder="Select value bracket"
                  />
                </div>

                <div className="d-flex flex-column gap-1">
                  <label className="mb-0 d-block text-nowrap flex-1 font-size-sm2">
                    Probability Range
                  </label>

                  <div className="d-flex align-items-center pl-20">
                    <input
                      type="checkbox"
                      id="select-all-ranges"
                      className="form-check-input mt-0"
                      checked={probabilityRangeSelected.all}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      htmlFor="select-all-ranges"
                      className="mb-0 ms-2 fs-7"
                    >
                      Select All Ranges
                    </label>
                  </div>

                  <div className="d-flex align-items-center gap-2">
                    <input
                      type="number"
                      className="form-control"
                      value={probabilityRangeSelected.min}
                      min="0"
                      max="100"
                      onChange={handleMinChange}
                      disabled={probabilityRangeSelected.all}
                    />
                    <span>-</span>
                    <input
                      type="number"
                      className="form-control"
                      value={probabilityRangeSelected.max}
                      min="0"
                      max="100"
                      onChange={handleMaxChange}
                      disabled={probabilityRangeSelected.all}
                    />
                    <span>%</span>
                  </div>
                </div>

                <div className="d-flex gap-2 align-items-center w-100">
                  <ButtonIcon
                    color="primary"
                    classnames="btn-sm w-100"
                    icon="search"
                    onClick={handleSelectedValueFilter}
                    label="Apply Filter"
                  />
                  {filterSelected?.filter?.amountRange ||
                  filterSelected?.filter?.probabilityRange ? (
                    <ButtonIcon
                      color="white"
                      classnames="btn-sm w-100"
                      icon="close"
                      onClick={handleRemoveValueFilter}
                      label="Clear Filter"
                    />
                  ) : null}
                </div>
              </div>
            </Tab>
          )}

          <Tab
            eventKey="owners"
            title={
              <span className="d-flex flex-column justify-content-center gap-1 align-items-center">
                <MaterialIcon icon="group" /> <span> Owners </span>
              </span>
            }
          >
            <div className="d-flex flex-column">
              <a
                onClick={(e) => {
                  onHandleFilterOwner({});
                  setFilterSearch({ name: '' });
                  setOpenFilter(false);
                  setFilterSelected({
                    ...filterSelected,
                    filter: { assigned_user_id: null },
                  });
                }}
                className="d-flex cursor-default bg-hover-gray btn-outline-primary py-2 px-3 gap-1 justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center gap-1">
                  <MaterialIcon icon="people" clazz="pl-1" />
                  <span className="fs-7">Everyone</span>
                </div>
                {filterSearch?.name === '' && (
                  <MaterialIcon icon="check" clazz="text-primary" />
                )}
              </a>
              <a
                onClick={(e) => {
                  onHandleFilterOwner(profileInfo);
                  setFilterSearch(profileInfo);
                }}
                className="d-flex cursor-default bg-hover-gray btn-outline-primary py-2 px-3 gap-1 justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center gap-1">
                  <Avatar user={profileInfo} defaultSize="xs" />
                  <span className="fs-7">
                    {profileInfo?.first_name} {profileInfo?.last_name} (you)
                  </span>
                </div>
                {filterSearch?.id === profileInfo?.id && (
                  <MaterialIcon icon="check" clazz="text-primary" />
                )}
              </a>
              <div className="pt-3 px-3 border-top position-relative">
                <Input
                  placeholder="Search owners"
                  value={searchOwner?.search}
                  onChange={onInputSearch}
                  onClick={(e) => e.stopPropagation()}
                />
                <MaterialIcon
                  icon="search"
                  clazz="position-absolute"
                  style={{ right: 25, top: 27 }}
                />
              </div>
              <div
                className="my-2 d-flex flex-column overflow-y-auto gap-1"
                style={{ maxHeight: 200 }}
              >
                {renderOwners()}
              </div>
            </div>
          </Tab>
        </Tabs>
      </Dropdown.Menu>
    </ButtonFilterDropdown>
  );
};

export default FilterTabsButtonDropdown;
