const useJumpToNextInputOnEnter = (inputsRefs) => {
  return (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const nextIndex = index + 1;

      if (nextIndex < inputsRefs.current.length) {
        inputsRefs.current[nextIndex].focus();
      }
    }
  };
};

export default useJumpToNextInputOnEnter;
