import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ButtonIcon from '../../../commons/ButtonIcon';
import { overflowing } from '../../../../utils/Utils';
const ConfirmationModal = ({ show, setShow, modalConfig, data }) => {
  const [loading, setLoading] = useState(false);
  const handleHideModal = () => {
    setShow(false);
    overflowing('modal-open');
  };
  const handleConfirm = () => {
    try {
      setLoading(true);
      modalConfig.confirmCallback(data);
      handleHideModal();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      style={{ zIndex: 99999 }}
      animation={false}
      className="confirmation-modal"
      show={show}
      onHide={handleHideModal}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h3">{modalConfig.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p
          className="mb-0"
          dangerouslySetInnerHTML={{
            __html: modalConfig.description,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="white" size="sm" onClick={handleHideModal}>
          Cancel
        </Button>
        <ButtonIcon
          icon=""
          loading={loading}
          type="button"
          color="primary"
          classnames="btn-sm"
          label="Yes, Confirm"
          onclick={handleConfirm}
        />
      </Modal.Footer>
    </Modal>
  );
};

const useConfirmationModal = (modalConfig) => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({});
  const ConfirmationModalCallback = useCallback(() => {
    return (
      <ConfirmationModal
        show={showModal}
        setShow={setShowModal}
        data={data}
        modalConfig={modalConfig}
      />
    );
  }, [showModal, setShowModal, data, setData]);

  return useMemo(
    () => ({
      setShowModal,
      setData,
      ConfirmationModal: ConfirmationModalCallback,
    }),
    [setShowModal, ConfirmationModalCallback]
  );
};

export default useConfirmationModal;
