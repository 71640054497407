import React, { useEffect } from 'react';
import { Col, FormGroup, Form, Spinner } from 'reactstrap';
import IdfSearchDirections from '../idfComponents/idfSearch/IdfSearchDirections';
import IdfSelectLabel from '../idfComponents/idfDropdown/IdfSelectLabel';
import { renderComponent } from '../peoples/constantsPeople';
import stringConstants from '../../utils/stringConstants.json';
import organizationService from '../../services/organization.service';
import ControllerValidation from '../commons/ControllerValidation';
import {
  DATE_FORMAT,
  DATE_FORMAT_EJS,
  DATE_FORMAT_EJS_UPDATED,
  formatHHMM,
  formatHHMMSS,
  getPattern,
  getPatternErrorMessage,
  isPermissionAllowed,
  isToFixedNoRound,
  numbersWithComma,
  setDateFormat,
  splitAddress,
} from '../../utils/Utils';
import moment from 'moment';
import { PricingField } from '../PricingFieldComponent';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import ReactDatepicker from '../inputs/ReactDatpicker';
import IdfFormInput from '../idfComponents/idfFormInput/IdfFormInput';
import { CheckboxInput } from '../layouts/CardLayout';
import { removeCustomFieldsFromActivityForm } from '../../views/Deals/contacts/utils';
import DropdownSelect from '../DropdownSelect';
import AddPicklistOptions from '../peopleProfile/contentFeed/AddPicklistOptions';
import { useModuleContext } from '../../contexts/moduleContext';
import MaterialIcon from '../commons/MaterialIcon';
import LastModifiedOn from '../commons/LastModifiedOn';
import LabelComponent from '../commons/LabelComponent';
const constants = stringConstants.deals.organizations.profile;
const labelCache = {};
const excludedColumns = [
  'label_id',
  'address_street',
  'naics_code',
  'industry',
];

const excludedFieldTypes = [
  'CHECKBOX',
  'PHONE',
  'CURRENCY',
  'DATE',
  'TIME',
  'PICKLIST',
  'PICKLIST_MULTI',
];

const InlineOrganizationForm = ({
  fields,
  data = {},
  setEditMode,
  getProfileInfo,
  setProfileInfo,
  setSuccessMessage,
  editMode,
  labelType,
  handleSubmit,
  me,
  fromNavBar,
  control,
  setErrorMessage,
  getFieldState,
  onClose,
  loading,
  customDataFields,
  setCustomDataFields,
  customFields,
  isFieldsObj,
  updateLabel,
  setOrganizationFields,
  refresh,
  register,
  setValue,
  errors,
  setLoading,
  isLoading,
  setIsFieldsObj,
  organizationObj = {},
  picklistDefault = [],
  initialLoad = {},
  ...props
}) => {
  const { moduleMap } = useModuleContext();
  const picklistInitialValue = [];
  const onChange = (e) => {
    const target = e.target;

    if (target.name === 'employees') {
      const employees = e.target.value <= 0 ? 0 : parseInt(e.target.value);
      const dataSet = {
        ...isFieldsObj,
        employees,
      };
      setIsFieldsObj(dataSet);
    } else {
      const dataSet = {
        ...isFieldsObj,
        [target.name]: target?.value,
      };
      setIsFieldsObj(dataSet);
    }
    setValue(target.name, target.value);
  };
  const onAddressChange = (value) => {
    const name = 'address_street';
    const dataSet = {
      ...isFieldsObj,
      [name]: value,
    };
    setIsFieldsObj(dataSet);
    setValue(name, value);
  };

  const handleSetData = (data) => {
    const dataSet = {
      ...isFieldsObj,
      ...data,
    };
    setIsFieldsObj(dataSet);
  };
  useEffect(() => {
    if (Object.keys(data)?.length > 0) {
      handleSetData(data);
    }
  }, [data]);

  const onHandleSubmit = async () => {
    setLoading(true);
    const updateFields = removeCustomFieldsFromActivityForm(
      isFieldsObj,
      customDataFields
    );
    delete updateFields?.fields;
    try {
      await organizationService.updateOrganization(data.id, updateFields);
      setSuccessMessage(
        constants.profileForm.updated.replace(
          /Company/g,
          moduleMap.organization.singular
        )
      );
      await Promise.all(
        customDataFields?.map(async (item) => {
          if (item.value === '$' || item.value === '') {
            await organizationService.deleteCustomField(data.id, item.field_id);
          } else {
            await new Promise((resolve) => {
              organizationService
                .updateCustomField(data.id, item)
                .then(resolve);
            });
          }
        })
      );
      setEditMode(false);
      getProfileInfo();
      setLoading(false);
    } catch (error) {
      if (error.response.status === 403) {
        setEditMode(false);
        getProfileInfo();
      }
    } finally {
      setCustomDataFields([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    const groups = Object.keys(fields);
    if (groups?.length) {
      for (const grp of groups) {
        const field = fields[grp];
        field?.forEach((item) => {
          const { columnName, key } = item;
          const fieldName = columnName
            ? columnName.toLowerCase()
            : key?.toLowerCase().replace(/\s+/g, '');
          setValue(fieldName, isFieldsObj[fieldName]);
        });
      }
    }
  }, [fields]);
  const clearState = (name) => {
    setIsFieldsObj({ ...isFieldsObj, [name]: '' });
    setValue(name, '');
  };

  const onHandleCustomField = (e, id, value_type, field_type) => {
    const target = e.target;
    let value = '';
    if (value_type === 'string' && target.value !== '') {
      value = target.value;
    }
    if (value_type === 'number' && target.value !== '') {
      value = parseInt(target.value);
    }
    if (field_type === 'CURRENCY') {
      value = `$${target.value}`;
    } else if (field_type === 'TIME') {
      value = moment(value).format(formatHHMMSS);
    }
    let updated = false;
    setIsFieldsObj({ ...isFieldsObj, [target.name]: target.value });
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return {
          field_id: id,
          value,
        };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      setCustomDataFields([...fieldData, { field_id: id, value }]);
    }
  };
  const onHandleCustomCheckBox = (e, id) => {
    const target = e.target;
    setIsFieldsObj({ ...isFieldsObj, [target.name]: target.checked });

    const isDuplicate = customDataFields.some((field) => field.field_id === id);

    if (!isDuplicate) {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: target.checked },
      ]);
    }
  };
  const onHandleCustomDate = (date, id, fieldName) => {
    if (date === '') {
      setCustomDataFields([...customDataFields, { field_id: id, value: '' }]);
    } else {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: new Date(date) },
      ]);
    }
    setValue(fieldName, new Date(date));
    setIsFieldsObj({ ...isFieldsObj, [fieldName]: new Date(date) });
  };

  const onHandlePicklistSingle = (item, id, fieldName) => {
    let picked;
    if (item.name === '-None-') {
      picked = '';
    } else {
      picked = [{ value: item.name }];
    }
    let updated = false;
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: picked, key: fieldName },
      ]);
    }
    setIsFieldsObj({
      ...isFieldsObj,
      [fieldName]: picked,
    });
    setValue(fieldName, picked);
  };

  const selectPicklistValue = (fieldName, value_option, id) => {
    if (isFieldsObj[fieldName] === '') {
      return '-None-';
    }
    if (
      isFieldsObj[fieldName] &&
      isFieldsObj[fieldName][0] &&
      typeof isFieldsObj[fieldName][0].value === 'string'
    ) {
      return isFieldsObj[fieldName][0].value;
    }

    const defaultItem = value_option.find(
      (item) => item.default === true && item.value !== '-None-'
    );

    if (
      defaultItem &&
      defaultItem.value &&
      !editMode &&
      !(fieldName in initialLoad)
    ) {
      initialLoad[fieldName] = 'yes';
      handlePicklistDefault(
        {
          field_id: id,
          value: [{ value: defaultItem.value }],
          key: fieldName,
        },
        fieldName
      );
      return defaultItem.value;
    }

    return '-None-';
  };

  const onHandlePicklistMulti = (val, id, fieldName) => {
    let picked;
    if (val.length === 0) {
      picked = '';
    } else {
      picked = val;
    }
    let updated = false;
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      const tempCustom = [
        ...customDataFields,
        { field_id: id, value: picked, key: fieldName },
      ];
      setCustomDataFields(tempCustom);
    }
    setIsFieldsObj({
      ...isFieldsObj,
      [fieldName]: picked,
    });
    setValue(fieldName, picked);
  };
  const selectPicklistMultiValue = (fieldName, value_option, id) => {
    if (isFieldsObj[fieldName] === '') {
      return [];
    }
    if (isFieldsObj[fieldName]) {
      return isFieldsObj[fieldName];
    }

    const defaultItem = value_option.find(
      (item) => item.default === true && item.value !== '-None-'
    );

    if (
      defaultItem &&
      defaultItem.value &&
      !editMode &&
      !(fieldName in initialLoad)
    ) {
      initialLoad[fieldName] = 'yes';
      handlePicklistDefault(
        {
          field_id: id,
          value: [{ value: defaultItem.value }],
          key: fieldName,
        },
        fieldName
      );
      return [{ value: defaultItem.value }];
    }

    return [];
  };

  const handlePicklistDefault = (dataField, fieldName) => {
    organizationObj = {
      ...organizationObj,
      ...isFieldsObj,
      [fieldName]: dataField.value,
    };
    setIsFieldsObj(organizationObj);
    picklistInitialValue.push({ key: dataField.key, value: dataField.value });
    picklistDefault.push(dataField);
    setCustomDataFields([...customDataFields, ...picklistDefault]);
  };
  useEffect(() => {
    if (picklistInitialValue.length !== 0) {
      handleInitialValue(picklistInitialValue);
    }
  }, [picklistInitialValue]);

  const handleInitialValue = (items) => {
    if (Array.isArray(items) && items.length !== 0) {
      for (const item of items) {
        setValue(item.key, item.value);
      }
    }
  };

  const onHandleSelectAddress = (item) => {
    const { address, city, state, country } = splitAddress(item);
    const addressData = {
      ...isFieldsObj,
      address_state: state?.name || '',
      address_country: country,
      address_city: city || '',
      address_street: address || '',
    };
    setIsFieldsObj(addressData);
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onHandleSubmit)}>
        <div className="card-body bg-light pt-0 pb-2 px-3">
          {Object.keys(fields).map((fieldType, index) => {
            return (
              <div key={`fields-${index}`}>
                <h5 className={`pb-0 px-2 ${index !== 0 && 'mt-2'}`}>
                  {moduleMap.organization &&
                    fieldType
                      .replace(/Company/g, moduleMap.organization.singular)
                      .replace(/Companies/g, moduleMap.organization.plural)}
                </h5>
                {fields[fieldType]?.length > 0 &&
                  fields[fieldType]?.map((item) => {
                    const {
                      field_type,
                      columnName,
                      key,
                      mandatory,
                      isCustom,
                      id,
                      value_type,
                      value_option,
                    } = item;
                    const fieldName =
                      columnName || key?.toLowerCase().replace(/\s+/g, '');

                    const shouldRenderComponent =
                      !excludedColumns.includes(columnName) &&
                      !excludedFieldTypes.includes(field_type);

                    return (
                      <>
                        {shouldRenderComponent && (
                          <div key={item?.id}>
                            <EditableRow
                              field={item}
                              editMode={editMode}
                              loading={loading}
                              setEditMode={setEditMode}
                              formValue={isFieldsObj}
                            >
                              {renderComponent(field_type, {
                                id: fieldName,
                                value: isFieldsObj,
                                name: fieldName,
                                placeholder: key,
                                className: 'text-capitalize',
                                containerClasses: 'flex-column',
                                inputClass: mandatory
                                  ? 'border-left-4 border-left-danger'
                                  : '',
                                validationConfig: {
                                  required: mandatory,
                                  inline: false,
                                  onChange: (e) =>
                                    isCustom
                                      ? onHandleCustomField(e, id, value_type)
                                      : onChange(e),
                                  pattern: getPattern(field_type),
                                  validateSpaces: mandatory,
                                },
                                errorMessage:
                                  getPatternErrorMessage(field_type),

                                errors,
                                register,
                                errorDisplay: 'mb-0',
                                fieldType: field_type.toLowerCase(),
                                type:
                                  item?.field_type === 'TEXT'
                                    ? 'textarea'
                                    : 'input',
                              })}
                            </EditableRow>
                          </div>
                        )}
                        {field_type === 'CHECKBOX' && (
                          <EditableRow
                            field={item}
                            editMode={editMode}
                            loading={loading}
                            setEditMode={setEditMode}
                            formValue={isFieldsObj}
                          >
                            <FormGroup row className="py-1">
                              <Col md={9} className="pl-0">
                                <CheckboxInput
                                  id={fieldName}
                                  onChange={(e) => {
                                    if (isCustom) {
                                      onHandleCustomCheckBox(e, id);
                                    } else {
                                      onChange(e);
                                    }
                                  }}
                                  label={item?.key}
                                  name={fieldName}
                                  checked={isFieldsObj[fieldName]}
                                />
                              </Col>
                            </FormGroup>
                          </EditableRow>
                        )}
                        {field_type === 'PHONE' && (
                          <EditableRow
                            field={item}
                            editMode={editMode}
                            loading={loading}
                            setEditMode={setEditMode}
                            formValue={isFieldsObj}
                          >
                            <FormGroup row className="py-1">
                              <Col md={9} className="pl-0">
                                <IdfFormInput
                                  className="mb-0 w-100"
                                  placeholder={item?.key}
                                  value={isFieldsObj}
                                  name={item?.columnName}
                                  maxLength={14}
                                  onChange={(e) => onChange(e)}
                                  autocomplete="off"
                                />
                              </Col>
                            </FormGroup>
                          </EditableRow>
                        )}
                        {field_type === 'DATE' && (
                          <EditableRow
                            field={item}
                            editMode={editMode}
                            loading={loading}
                            setEditMode={setEditMode}
                            formValue={isFieldsObj}
                          >
                            <FormGroup row className="py-1 align-items-center">
                              <Col md={9} className="pl-0">
                                <div className="date-picker input-time w-100">
                                  <ControllerValidation
                                    name={fieldName}
                                    errors={errors}
                                    form={isFieldsObj}
                                    errorDisplay="mb-0"
                                    control={control}
                                    validationConfig={{
                                      required: mandatory
                                        ? `${key} is required.`
                                        : '',
                                    }}
                                    renderer={({ field }) => (
                                      <ReactDatepicker
                                        id={fieldName}
                                        name={fieldName}
                                        format={DATE_FORMAT_EJS}
                                        minDate={new Date()}
                                        autoComplete="off"
                                        todayButton="Today"
                                        validationConfig={{
                                          required: mandatory
                                            ? `${key} is required.`
                                            : '',
                                        }}
                                        fieldState={getFieldState(fieldName)}
                                        value={isFieldsObj[fieldName]}
                                        className="form-control"
                                        placeholder={DATE_FORMAT_EJS_UPDATED}
                                        onChange={(date) => {
                                          if (isCustom) {
                                            onHandleCustomDate(
                                              date,
                                              id,
                                              fieldName
                                            );
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                </div>
                              </Col>
                            </FormGroup>
                          </EditableRow>
                        )}
                        {field_type === 'TIME' && (
                          <EditableRow
                            field={item}
                            editMode={editMode}
                            loading={loading}
                            setEditMode={setEditMode}
                            formValue={isFieldsObj}
                          >
                            <FormGroup row className="py-1 align-items-center">
                              <Col md={9} className="pl-0">
                                <div className="date-picker input-time w-100">
                                  <TimePickerComponent
                                    id={`start-time`}
                                    cssClass="e-custom-style"
                                    openOnFocus={true}
                                    value={
                                      isFieldsObj?.start_time || '12:00 PM'
                                    }
                                    format="hh:mm a"
                                    placeholder="Start Time"
                                    onChange={(e) =>
                                      onHandleCustomField(
                                        e,
                                        id,
                                        value_type,
                                        field_type
                                      )
                                    }
                                  />
                                </div>
                              </Col>
                            </FormGroup>
                          </EditableRow>
                        )}
                        {field_type === 'CURRENCY' && (
                          <EditableRow
                            field={item}
                            editMode={editMode}
                            loading={loading}
                            setEditMode={setEditMode}
                            formValue={isFieldsObj}
                          >
                            <PricingField
                              name={fieldName}
                              placeholder={key}
                              errors={errors}
                              register={register}
                              onChange={(e) =>
                                isCustom
                                  ? onHandleCustomField(
                                      e,
                                      id,
                                      value_type,
                                      field_type
                                    )
                                  : onChange(e)
                              }
                              validationConfig={{
                                required: mandatory,
                                inline: false,
                              }}
                              value={isFieldsObj}
                            />
                          </EditableRow>
                        )}

                        {item?.columnName === 'address_street' && (
                          <EditableRow
                            field={item}
                            editMode={editMode}
                            loading={loading}
                            setEditMode={setEditMode}
                            formValue={isFieldsObj}
                          >
                            <FormGroup row className="py-1">
                              <Col md={9} className="pl-0">
                                <IdfSearchDirections
                                  fromNavBar
                                  selected={isFieldsObj?.address_street}
                                  onChange={onAddressChange}
                                  isFieldsObj={isFieldsObj}
                                  onHandleSelect={(item) => {
                                    onHandleSelectAddress(item);
                                  }}
                                  setIsFieldsObj={setIsFieldsObj}
                                  placeholder={item?.key}
                                  clearState={(e) => clearState(e)}
                                  name={fieldName}
                                  validationConfig={{
                                    required: item?.mandatory
                                      ? `${item?.key} is required.`
                                      : '',
                                  }}
                                  fieldState={getFieldState(fieldName)}
                                  {...props}
                                />
                              </Col>
                            </FormGroup>
                          </EditableRow>
                        )}
                        {field_type === 'PICKLIST' && (
                          <EditableRow
                            field={item}
                            editMode={editMode}
                            loading={loading}
                            setEditMode={setEditMode}
                            formValue={isFieldsObj}
                          >
                            <FormGroup row className="py-1 align-items-center">
                              <Col md={9} className="pl-0">
                                <div className="date-picker input-time w-100">
                                  <ControllerValidation
                                    name={fieldName}
                                    errors={errors}
                                    form={isFieldsObj}
                                    errorDisplay="mb-0"
                                    control={control}
                                    validationConfig={{
                                      required: mandatory
                                        ? `${key} is required.`
                                        : '',
                                    }}
                                    renderer={({ field }) => (
                                      <DropdownSelect
                                        data={value_option.map((item, i) => {
                                          return {
                                            id: i,
                                            name: item.value,
                                          };
                                        })}
                                        onHandleSelect={(item) => {
                                          onHandlePicklistSingle(
                                            item,
                                            id,
                                            fieldName
                                          );
                                        }}
                                        select={selectPicklistValue(
                                          fieldName,
                                          value_option,
                                          id
                                        )}
                                        placeholder="Select Option"
                                        customClasses={
                                          'w-100 overflow-y-auto max-h-300'
                                        }
                                        validationConfig={{
                                          required: mandatory,
                                        }}
                                        fieldState={getFieldState(fieldName)}
                                      />
                                    )}
                                  />
                                </div>
                              </Col>
                            </FormGroup>
                          </EditableRow>
                        )}
                        {field_type === 'PICKLIST_MULTI' && (
                          <EditableRow
                            field={item}
                            editMode={editMode}
                            loading={loading}
                            setEditMode={setEditMode}
                            formValue={isFieldsObj}
                          >
                            <FormGroup row className="py-1 align-items-center">
                              <Col md={9} className="pl-0">
                                <div className="date-picker input-time w-100">
                                  <ControllerValidation
                                    name={fieldName}
                                    errors={errors}
                                    form={isFieldsObj}
                                    errorDisplay="mb-0"
                                    control={control}
                                    validationConfig={{
                                      required: mandatory
                                        ? `${key} is required.`
                                        : '',
                                    }}
                                    renderer={({ field }) => (
                                      <AddPicklistOptions
                                        dropdownList={value_option.filter(
                                          (item) => item.value !== '-None-'
                                        )}
                                        validationConfig={{
                                          required: mandatory,
                                        }}
                                        fieldState={getFieldState(fieldName)}
                                        placeholder={'Add Options'}
                                        value={selectPicklistMultiValue(
                                          fieldName,
                                          value_option,
                                          id,
                                          fieldName
                                        )}
                                        setValue={(e) =>
                                          onHandlePicklistMulti(
                                            e,
                                            id,
                                            fieldName
                                          )
                                        }
                                        tooltip={constants.tooltipTagInput}
                                        labelSize="full"
                                        onChange={(e) => {}}
                                      />
                                    )}
                                  />
                                </div>
                              </Col>
                            </FormGroup>
                          </EditableRow>
                        )}
                        {item.columnName === 'label_id' && (
                          <EditableRow
                            field={item}
                            editMode={editMode}
                            loading={loading}
                            setEditMode={setEditMode}
                            formValue={isFieldsObj}
                            labelType={labelType}
                          >
                            <FormGroup row className="py-1">
                              <Col md={9} className="pl-0">
                                <ControllerValidation
                                  name={fieldName}
                                  validationConfig={{
                                    required: item?.mandatory
                                      ? `${item?.key} is required.`
                                      : '',
                                  }}
                                  errors={errors}
                                  form={isFieldsObj}
                                  errorDisplay="mb-0"
                                  control={control}
                                  renderer={({ field }) => (
                                    <IdfSelectLabel
                                      fromNavBar
                                      value={isFieldsObj?.label_id}
                                      onChange={onChange}
                                      name={fieldName}
                                      type={labelType}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key} is required.`
                                          : '',
                                      }}
                                      fieldState={getFieldState(fieldName)}
                                      placeholder={item?.key}
                                      {...props}
                                      required={field?.mandatory}
                                    />
                                  )}
                                />
                              </Col>
                            </FormGroup>
                          </EditableRow>
                        )}
                      </>
                    );
                  })}
              </div>
            );
          })}

          <div className="pt-4">
            <LastModifiedOn data={data} />
          </div>
        </div>
      </Form>
    </>
  );
};

export default InlineOrganizationForm;

const EditableRow = ({
  field,
  editMode,
  loading,
  setEditMode,
  formValue,
  children,
  labelType,
}) => {
  const { columnName, key, field_type } = field;
  const fieldName = columnName || key?.toLowerCase().replace(/\s+/g, '');

  const isAnyOtherEditModeActive = (currentKey) => {
    return Object.keys(editMode).some((k) => k !== currentKey && editMode[k]);
  };

  const editableClass = !isAnyOtherEditModeActive(key) ? 'editable-field' : '';
  const noHoverBgClass = editMode[key] && 'no-hover';
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  return (
    <div className={`pipeline-overview-row ${noHoverBgClass} ${editableClass}`}>
      <p>{key}</p>
      {editMode[key] ? (
        <div className={`relative ${fieldName}`}>
          {children}
          {editMode[key] && (
            <div
              className="text-right position-absolute"
              style={{ top: 4, right: '-16px' }}
            >
              <div className="d-flex align-items-center bg-white px-1 z-index-99 py-1">
                {!loading ? (
                  <>
                    <a className="cursor-pointer icon-hover-bg">
                      <button type="submit">
                        <MaterialIcon
                          icon="check_circle"
                          clazz="text-green fs-4 text-success"
                          filled
                        />
                      </button>
                    </a>
                    <a
                      className="cursor-pointer icon-hover-bg"
                      onClick={() => setEditMode({ ...editMode, [key]: false })}
                    >
                      <MaterialIcon
                        icon="cancel"
                        clazz="text-gray-400 fs-4"
                        filled
                      />{' '}
                    </a>
                  </>
                ) : (
                  <Spinner className="spinner-grow-xs" />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <p>
            {field_type !== 'DATE' &&
              field_type !== 'PICKLIST' &&
              field_type !== 'PICKLIST_MULTI' &&
              field_type !== 'CHECKBOX' &&
              field_type !== 'TIME' &&
              key !== 'Company' &&
              key !== 'Website' &&
              columnName !== 'label_id' &&
              (formValue[fieldName]
                ? columnName === 'amount' || columnName === 'annual_revenue'
                  ? isToFixedNoRound(formValue[fieldName], 2)
                  : columnName === 'employees'
                  ? numbersWithComma(formValue[fieldName] || 0)
                  : formValue[fieldName]
                : '--')}

            {key === 'Company' && <span>{formValue?.organization?.name}</span>}

            {field_type === 'DATE' && (
              <span
                className={
                  key === 'Closing Date' &&
                  (formValue[fieldName] &&
                  new Date(formValue[fieldName]) >= currentDate
                    ? ''
                    : 'text-red fw-bold')
                }
              >
                {setDateFormat(formValue[fieldName], DATE_FORMAT)}
              </span>
            )}

            {(field_type === 'PICKLIST' || field_type === 'PICKLIST_MULTI') &&
              formValue[fieldName]?.length &&
              formValue[fieldName].map((item) => item.value).join(',')}

            {field_type === 'CHECKBOX' &&
              (formValue[fieldName] ? 'True' : 'False')}

            {field_type === 'TIME' &&
              setDateFormat(formValue[fieldName], formatHHMM)}

            {columnName === 'label_id' && (
              <LabelComponent
                fieldName={fieldName}
                formValue={formValue}
                labelType={labelType}
                labelCache={labelCache}
              />
            )}

            {key === 'Website' && (
              <div className="d-flex gap-1 align-items-center">
                {formValue[fieldName] ? (
                  <>
                    {formValue[fieldName]}
                    <a
                      href={
                        formValue[fieldName]?.includes('http')
                          ? formValue[fieldName]
                          : 'https://' + formValue[fieldName]
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center text-black"
                    >
                      <MaterialIcon icon="open_in_new" size="fs-6" rounded />
                    </a>
                  </>
                ) : (
                  '--'
                )}
              </div>
            )}
          </p>
          {isPermissionAllowed('organizations', 'edit') && (
            <div className="position-absolute right-0 edit-div">
              <button
                className="btn btn-icon btn-sm hover-bg-none"
                onClick={() => setEditMode({ ...editMode, [key]: true })}
              >
                <MaterialIcon icon="edit" size="fs-5" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};
