import React, { useEffect, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import {
  NO_REPORT_SELECTED,
  NO_REPORTS_AVAILABLE,
} from '../../utils/constants';
import Alert from '../../components/Alert/Alert';
import AlertWrapper from '../../components/Alert/AlertWrapper';
import NoDataFound from '../../components/commons/NoDataFound';
import {
  DATE_FORMAT,
  scrollToTop,
  isMatchInCommaSeperated,
} from '../../utils/Utils';
import MaterialIcon from '../../components/commons/MaterialIcon';
import DatePicker from '../../components/dealsBreakdown/DatePicker';
import moment from 'moment';
import { useTenantContext } from '../../contexts/TenantContext';
import { useHistory } from 'react-router';
import { ReportSkeletonLoader } from './ReportSkeletonLoader';
import TextOverflowTooltip from '../../components/commons/TextOverflowTooltip';
import ChecklistsOverview from './reportRenders/checklist/ChecklistsOverview';
import ChecklistsReport from './reportRenders/checklist/ChecklistsReport';
import TrainingOverview from './reportRenders/training/overview/TrainingOverview';
import TrainingLeaderboard from './reportRenders/training/leaderboard/TrainingLeaderboard';
import { TrainingAssignmentTeamSnapshot } from './reportRenders/training/TrainingAssignmentTeamSnapshot';
import { TrainingAssignmentStatistics } from './reportRenders/training/TrainingAssignmentStatistics';
import { TrainingAssignmentDetails } from './reportRenders/training/TrainingAssignmentDetails';
import TrainingLessonDetails from './reportRenders/training/lessonDetails/TrainingLessonDetails';
import TrainingLessonStatistics from './reportRenders/training/TrainingLessonStatistics';
import TrainingUserTranscript from './reportRenders/training/userTranscript/TrainingUserTranscript';

const format = DATE_FORMAT;

const TrainingDashboardComponents = {
  Training_LessonDetails: 'Training_LessonDetails',

  Training_Overview: 'Training_Overview',
  Training_Leaderboard: 'Training_Leaderboard',
  Training_AssignmentTeamSnapshot: 'Training_AssignmentTeamSnapshot',
  Training_AssignmentStatistics: 'Training_AssignmentStatistics',
  Training_AssignmentDetails: 'Training_AssignmentDetails',
  Training_LessonStatistics: 'Training_LessonStatistics',
  Training_UserTranscript: 'Training_UserTranscript',
};
const TrainingDashboardComponentsSort = {
  Training_LessonDetails: 6,

  Training_Overview: 1,
  Training_Leaderboard: 2,
  Training_AssignmentTeamSnapshot: 3,
  Training_AssignmentStatistics: 4,
  Training_AssignmentDetails: 5,
  Training_LessonStatistics: 7,
  Training_UserTranscript: 8,
};
const TrainingComponentsName = {
  Training_LessonDetails: 'Lesson Details',

  Training_Overview: 'Learn Overview',
  Training_Leaderboard: 'Leaderboard',
  Training_AssignmentTeamSnapshot: 'Assignments Team Snapshot',
  Training_AssignmentStatistics: 'Assignment Statistics',
  Training_AssignmentDetails: 'Assignment Details',
  Training_LessonStatistics: 'Lesson Statistics',
  Training_UserTranscript: 'User Transcript',
};
const TrainingComponentsIcons = {
  Training_LessonDetails: 'text_snippet',

  Training_Overview: 'dashboard',
  Training_Leaderboard: 'trophy',
  Training_AssignmentTeamSnapshot: 'people',
  Training_AssignmentStatistics: 'pie_chart',
  Training_AssignmentDetails: 'description',
  Training_LessonStatistics: 'text_snippet',
  Training_UserTranscript: 'text_snippet',
};

const ChecklistComponentsName = {
  Checklists_ChecklistsOverview: 'Checklists Overview',
  Checklists_ChecklistsReport: 'Checklists Report',
};
const ChecklistsDashboardComponents = {
  Checklists_ChecklistsOverview: 'Checklists_ChecklistsOverview',
  Checklists_ChecklistsReport: 'Checklists_ChecklistsReport',
};
const ChecklistsOrder = [
  'Checklists_ChecklistsOverview',
  'Checklists_ChecklistsReport',
];

const DashboardIcons = {
  Training: 'school',
  Checklists: 'checklist',
};
const InsightReports = () => {
  const [dashboardList, setDashboardList] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState({});
  const { tenant } = useTenantContext();
  const [successMessage, setSuccessMessage] = useState('');
  const [active, setActive] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [dashboardComponents, setDashboardComponents] = useState([]);
  const [dashboardWithComponents, setDashboardWithComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState({});

  const [isUserDashboard, setIsUserDashboard] = useState(false);
  const [, setQuery] = useState({});
  const [dateRange, setDateRange] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (selectedComponent?.component) {
      let updatedTimeDimensions = [
        ...selectedComponent?.component?.analytic?.timeDimensions,
      ];
      if (updatedTimeDimensions && updatedTimeDimensions.length) {
        delete updatedTimeDimensions[0]?.compareDateRange;

        if (dateRange.startDate && dateRange.endDate) {
          updatedTimeDimensions[0].dateRange = [
            moment(dateRange.startDate).format(format),
            moment(dateRange.endDate).format(format),
          ];
        } else {
          updatedTimeDimensions = undefined;
        }
      }
      const queryBackup = {
        ...selectedComponent,
        component: {
          ...selectedComponent.component,
          analytic: {
            ...selectedComponent.component.analytic,
            timeDimensions: updatedTimeDimensions,
          },
        },
      };

      setSelectedComponent({});
      setQuery({});
      scrollToTop();

      setDashboardComponents(
        [...dashboardComponents].map((comp) => ({
          ...comp,
          component:
            comp.componentId === queryBackup.componentId
              ? queryBackup.component
              : comp.component,
        }))
      );

      setTimeout(() => {
        setQuery(queryBackup.component.analytic);
        setSelectedComponent(queryBackup);
      });
    }
  }, [dateRange]);

  const getDashboardComponents = (dashboard) => {
    return (
      dashboardWithComponents.find((d) => d?.dashboard?.id === dashboard?.id)
        ?.components || []
    );
  };

  const getDashboardFromList = (list, dashId) => {
    return list.find(({ dashboard }) => dashboard.id === dashId) || {};
  };

  const getDashboards = async () => {
    setLoading(true);

    const urlParams = new URLSearchParams(history.location.search);
    const dashId = urlParams.get('dashboard');

    const data = [
      { id: 'Training', name: 'Training' },
      {
        id: 'Training_Overview',
        name: 'Training_Overview',
      },
      {
        id: 'Training_LessonDetails',
        name: 'Training_LessonDetails',
      },
      {
        id: 'Training_Leaderboard',
        name: 'Training_Leaderboard',
      },
      {
        id: 'Training_AssignmentTeamSnapshot',
        name: 'Training_AssignmentTeamSnapshot',
      },
      {
        id: 'Training_AssignmentStatistics',
        name: 'Training_AssignmentStatistics',
      },
      {
        id: 'Training_AssignmentDetails',
        name: 'Training_AssignmentDetails',
      },
      {
        id: 'Training_LessonStatistics',
        name: 'Training_LessonStatistics',
      },
      {
        id: 'Training_UserTranscript',
        name: 'Training_UserTranscript',
      },

      { id: 'Checklists', name: 'Checklists' },
      {
        id: 'Checklists_ChecklistsOverview',
        name: 'Checklists_ChecklistsOverview',
      },
      {
        id: 'Checklists_ChecklistsReport',
        name: 'Checklists_ChecklistsReport',
      },
    ];

    const TrainingComponents = data?.filter((item) => {
      return TrainingDashboardComponents[item?.name];
    });

    const ChecklistsComponents = data?.filter((item) => {
      return ChecklistsDashboardComponents[item?.name];
    });

    const filteredData = data.filter(
      (dashboard) =>
        dashboard?.name !== TrainingDashboardComponents[dashboard?.name] &&
        dashboard?.name !== ChecklistsDashboardComponents[dashboard?.name]
    );
    const dashboards = filteredData?.map((d) => ({
      ...d,
      key: d.id,
    }));
    const desiredOrder = ['Checklists', 'Training'];
    const sortedData = dashboards.sort((a, b) => {
      return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
    });
    const uniqueDashboards = sortedData.filter((dashboard) => {
      const settingsInput = `reporting_${dashboard.name}`;
      return (
        tenant.modules === '*' ||
        isMatchInCommaSeperated(tenant.modules, settingsInput)
      );
    });

    const UpdatedTrainingComponents = TrainingComponents?.map((component) => ({
      ...component,
      insightName: component?.name,
      name: TrainingComponentsName[component?.name],
      id: component?.id,
      componentId: 1298,
      rptType: 'legacy',
      sort: TrainingDashboardComponentsSort[component?.name],
      component: {
        id: component?.id,
        insightName: component?.name,
        name: TrainingComponentsName[component?.name],
        analytic: {
          icon: TrainingComponentsIcons[component?.name] || 'people',
          timeDimensions: [],
        },
      },
      dashboardId: 'Training',
    }));
    UpdatedTrainingComponents.sort((a, b) => a.sort - b.sort);

    const sortedDashboardComponents = ChecklistsComponents?.sort((a, b) => {
      const nameA = a?.name || '';
      const nameB = b?.name || '';
      const indexA = ChecklistsOrder.indexOf(nameA);
      const indexB = ChecklistsOrder.indexOf(nameB);

      return indexA - indexB;
    });

    const UpdatedChecklistComponents =
      sortedDashboardComponents?.length > 0
        ? sortedDashboardComponents?.map((component) => ({
            ...component,
            insightName: component?.name,
            name: ChecklistComponentsName[component?.name],
            id: component?.id,
            componentId: 1298,
            rptType: 'legacy',
            sort: TrainingDashboardComponentsSort[component?.name],
            component: {
              id: component?.id,
              insightName: component?.name,
              name: ChecklistComponentsName[component?.name],
              analytic: {
                icon: TrainingComponentsIcons[component?.name] || 'people',
              },
            },
            dashboardId: 'Checklists',
          }))
        : [];

    const dashboardWithComponents = [
      {
        dashboard: uniqueDashboards.find((ud) => ud.name === 'Checklists'),
        components: UpdatedChecklistComponents,
      },
      {
        dashboard: uniqueDashboards.find((ud) => ud.name === 'Training'),
        components: UpdatedTrainingComponents,
      },
    ];

    setLoading(false);
    const first = dashId
      ? getDashboardFromList(dashboardWithComponents, dashId)
      : uniqueDashboards[0];

    setDashboardList(uniqueDashboards);
    setActive(uniqueDashboards[0]?.id);
    setSelectedDashboard(first?.dashboard || first);
    setDashboardWithComponents(dashboardWithComponents);
    setDashboardComponents(
      first?.components || dashboardWithComponents[0]?.components
    );
  };

  useEffect(() => {
    if (tenant) {
      getDashboards();
    }
  }, [tenant]);

  useEffect(() => {
    if (selectedDashboard) {
      setDashboardComponents(getDashboardComponents(selectedDashboard));
    }
  }, [selectedDashboard]);

  const Title = () => {
    return <div className="text-muted">{NO_REPORT_SELECTED}</div>;
  };

  const TitleNoReports = () => {
    return (
      <div className="text-muted font-size-md">{NO_REPORTS_AVAILABLE}</div>
    );
  };

  const handleCallUserComponent = (item) => {
    setIsUserDashboard(true);
    setSelectedComponent(item);
    scrollToTop();
  };

  const renderCollapseOfDashboard = (dashboard) => {
    const components = getDashboardComponents(dashboard);
    const subComponents = components.filter(
      (i) => i.dashboardId === dashboard.id
    );

    if (subComponents) {
      return (
        <>
          {subComponents.map((component) => (
            <Row
              key={component.id}
              onClick={() => {
                handleCallUserComponent(component);
              }}
              className={`cursor-pointer report-sub-menu align-items-center px-4_1 py-2 nav-link item-filter ${
                selectedComponent?.component?.id === component?.component?.id
                  ? 'text-primary active bg-primary-soft fw-600'
                  : ''
              }`}
            >
              <Col>
                <p className="d-flex align-items-center py-0 px-3_2 my-0 gap-2">
                  <span className="font-weight-medium font-size-sm2 mb-0">
                    <TextOverflowTooltip
                      text={
                        component?.component?.name === 'Assignment Progress'
                          ? 'Assignment Statistics'
                          : component?.component?.name?.split('-')[0]?.trim() ||
                            component?.name?.split('-')[0]?.trim()
                      }
                    />
                  </span>
                </p>
              </Col>
            </Row>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  const shouldShowDatePicker = () => {
    const keys = Object.keys(selectedComponent).length;
    return keys && selectedComponent.rptType !== 'legacy';
  };

  const handleToggleAccordion = (key) => {
    setActive(active === key ? null : key);
  };

  return (
    <div className="overflow-x-hidden">
      <AlertWrapper>
        <Alert message={successMessage} setMessage={setSuccessMessage} />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <div className="mt-0 custom-layout overflow-x-hidden">
        <div className="custom-layout-sidebar overflow-x-hidden">
          <div className="d-flex justify-content-between align-items-center pt-3 pb-3 px-3">
            <h3 className="mb-0">Reporting</h3>
          </div>
          <div className="d-flex overflow-y-auto overflow-x-hidden flex-column px-3">
            {loading ? (
              <ReportSkeletonLoader rows={5} height={30} />
            ) : (
              <ul className="list-unstyled mb-0 transparent-scroll-track">
                {dashboardList?.map((i, index) => {
                  return (
                    <li key={index} className="reporting-filter-wrapper">
                      <Accordion
                        defaultActiveKey={dashboardList[0]?.id || String(index)}
                        activeKey={active}
                      >
                        <Accordion.Toggle
                          onClick={() => handleToggleAccordion(i.id)}
                          eventKey={i.id}
                          className={`${
                            active === i.id
                              ? 'bg-accordion-active rounded-top rounded-left-0 rounded-right-0 rounded-bottom-0'
                              : 'bg-white'
                          } reporting-filter-toggle nav-link px-3 py-2 border-bottom cursor-pointer font-size-sm2 font-weight-medium text-black text-capitalize`}
                        >
                          <div className="d-flex align-items-center">
                            <span className="material-symbols-rounded fs-20">
                              {DashboardIcons[i.name]}
                            </span>
                            <span className="ml-2 text-capitalize py-0">
                              {i.name === 'Training' ? 'Learn' : i.name}
                            </span>

                            <MaterialIcon
                              clazz="ml-auto"
                              icon={
                                active === i.id
                                  ? 'keyboard_arrow_up'
                                  : 'keyboard_arrow_down'
                              }
                            />
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse
                          eventKey={i.id}
                          className="prospect-wrapper-content"
                        >
                          <div className="py-0 overflow-x-hidden">
                            {
                              <>
                                {loading ? (
                                  <ReportSkeletonLoader rows={15} />
                                ) : (
                                  <>
                                    {dashboardComponents?.length > 0 ? (
                                      <> {renderCollapseOfDashboard(i)} </>
                                    ) : (
                                      <NoDataFound
                                        title={<TitleNoReports />}
                                        icon="analytics"
                                        containerStyle="w-100 text-muted h-100"
                                        iconStyle="font-size-2xl"
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            }
                          </div>
                        </Accordion.Collapse>
                      </Accordion>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
        <div className="custom-layout-content p-0 pl-0 bg-white flex-fill position-relative right-panel-setting">
          {!isUserDashboard && (
            <div
              className="position-absolute z-index-5"
              style={{
                top: 15,
                right: 35,
                pointerEvents: shouldShowDatePicker() ? '' : 'none',
                opacity: shouldShowDatePicker() ? 1 : 0,
              }}
            >
              <DatePicker
                range={dateRange}
                setRange={setDateRange}
                selectedDate={!selectedComponent?.component}
                extraClass="p-0"
              />
            </div>
          )}
          {isUserDashboard &&
          selectedComponent?.insightName === 'Training_Overview' ? (
            <TrainingOverview
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Training_Leaderboard' ? (
            <TrainingLeaderboard
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName ===
              'Training_AssignmentTeamSnapshot' ? (
            <TrainingAssignmentTeamSnapshot
              insightName={
                TrainingComponentsName[selectedComponent.insightName]
              }
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName ===
              'Training_AssignmentStatistics' ? (
            <TrainingAssignmentStatistics
              insightName={
                TrainingComponentsName[selectedComponent.insightName]
              }
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Training_AssignmentDetails' ? (
            <TrainingAssignmentDetails
              insightName={
                TrainingComponentsName[selectedComponent.insightName]
              }
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Training_LessonDetails' ? (
            <TrainingLessonDetails
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Training_LessonStatistics' ? (
            <TrainingLessonStatistics
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Training_UserTranscript' ? (
            <TrainingUserTranscript
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName ===
              'Checklists_ChecklistsOverview' ? (
            <ChecklistsOverview
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Checklists_ChecklistsReport' ? (
            <ChecklistsReport
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : (
            <NoDataFound
              title={<Title />}
              icon="analytics"
              containerStyle="w-100 height-300 text-muted"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InsightReports;
