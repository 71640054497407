import React, { useEffect, useRef } from 'react';
import ButtonIcon from '../commons/ButtonIcon';

// load more button component to work like pagination, this is being used in training UIs
const LoadMoreButton = ({
  list,
  btnText = 'Load more',
  pagination,
  onClick,
  loading,
  btnStyle,
  btnContainerStyle = 'mt-2 mb-4',
  type,
  containerRef,
}) => {
  const prevListLengthRef = useRef(0);
  useEffect(() => {
    const shouldScroll =
      list.length > prevListLengthRef.current && list.length > 10;

    if (shouldScroll && containerRef?.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
    prevListLengthRef.current = list.length;
  }, [list]);
  return (
    <>
      {list?.length > 0 &&
        list?.length < pagination?.count &&
        pagination?.totalPages > 1 && (
          <div className={`${btnContainerStyle} text-center`}>
            <ButtonIcon
              classnames={`border-0 min-w-250 ${btnStyle}`}
              loading={loading}
              label={btnText}
              onclick={onClick}
              type={type}
            />
          </div>
        )}
    </>
  );
};

export default LoadMoreButton;
