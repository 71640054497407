export const ChecklistFieldsTabs = {
  Internal: 'internal',
  Client: 'client',
};

export const ActionIcons = {
  upload: 'description',
  signature: 'draw',
  acknowledge: 'visibility',
  video: 'play_circle',
  watch: 'play_circle',
};

export const CHECKLIST_ACTIONS = [
  {
    name: 'acknowledge',
    title: 'Acknowledge',
    value: 'acknowledge',
    icon: ActionIcons.acknowledge,
  },
  {
    name: 'upload',
    title: 'Upload',
    value: 'upload',
    icon: ActionIcons.upload,
  },
  {
    name: 'signature',
    title: 'Signature',
    value: 'signature',
    icon: ActionIcons.signature,
  },
  { name: 'watch', title: 'Video', value: 'watch', icon: ActionIcons.video },
];

export const ChecklistStatuses = {
  Pending: {
    value: 'pending',
    text: 'Pending',
    color: 'status-gray',
  },
  NotStarted: {
    value: 'not-started',
    text: 'Not Started',
    color: 'status-gray',
  },
  InProgress: {
    value: 'in-progress',
    text: 'In Progress',
    color: 'status-yellow',
  },
  NotViewed: {
    value: 'not-viewed',
    text: 'Not Viewed',
    color: 'status-gray',
  },
  Completed: {
    value: 'completed',
    text: 'Completed',
    color: 'status-green',
  },
  Deleted: {
    value: 'delete',
    text: 'Deleted',
    color: 'status-red',
  },
};
