import React from 'react';
import NotificationForm from '../components/notification/NotificationForm';

const Notification = () => {
  return (
    <>
      <NotificationForm border0 />
    </>
  );
};

export default Notification;
