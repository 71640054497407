import GenericTable from '../../../components/GenericTable';
import { DATE_FORMAT, checkDueDate, setDateFormat } from '../../../utils/Utils';
import OwnerAvatar from '../../../components/ActivitiesTable/OwnerAvatar';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import React from 'react';
import { ChecklistStatuses } from '../../../utils/checklist.constants';
import moment from 'moment';
import ChecklistStatus from '../../../components/checklist/ChecklistStatus';

const ChecklistReport = (results, handleSortByData) => {
  const { data } = results[0];
  const getName = (ownerName) => {
    try {
      const name = ownerName?.trim()?.split(' ');
      if (!name || !name.length || name[0] === '') {
        return null;
      }
      return {
        first_name: name[0],
        last_name: name?.length > 1 ? name[1] : '',
      };
    } catch (e) {
      return null;
    }
  };
  const rows = data.map((result, index) => {
    return {
      id: index,
      dataRow: [
        {
          key: 'Company Name',
          component: (
            <span className="fw-bold">
              {result['ChecklistProgress.organizationTitle']}
            </span>
          ),
        },
        {
          key: 'Checklist Name',
          component: (
            <div className="d-flex text-wrap align-items-center">
              {result['ChecklistProgress.title']}
            </div>
          ),
        },
        {
          key: 'OrganizationChecklistOwner.ownerName',
          component: (
            <>
              {getName(result['OrganizationChecklistOwner.ownerName']) ? (
                <div className="d-flex text-wrap align-items-center">
                  <OwnerAvatar
                    isMultiple={true}
                    item={{
                      external: false,
                      ...getName(
                        result['OrganizationChecklistOwner.ownerName']
                      ),
                    }}
                  />
                </div>
              ) : (
                '--'
              )}
            </>
          ),
        },
        {
          key: 'Status',
          component: (
            <ChecklistStatus
              item={{
                status:
                  result['ChecklistProgress.progress'] ===
                  ChecklistStatuses.Completed.text
                    ? ChecklistStatuses.Completed.text
                    : result['ChecklistProgress.progress'] ===
                      ChecklistStatuses.InProgress.text
                    ? ChecklistStatuses.InProgress.text
                    : result['ChecklistProgress.progress'] === 'Pending'
                    ? ChecklistStatuses.NotStarted.text
                    : ChecklistStatuses.Pending.text,
                statusText:
                  result['ChecklistProgress.progress'] === 'Pending'
                    ? 'Not Started'
                    : result['ChecklistProgress.progress'],
              }}
            />
          ),
        },
        {
          key: 'OrganizationChecklistProgress.maxOfLastAttempt',
          component: moment(
            result['OrganizationChecklistProgress.maxOfLastAttempt']
          ).format(DATE_FORMAT),
        },
        {
          key: 'Due Date',
          bgColor:
            result['ChecklistProgress.progress'] !== 'Completed' &&
            checkDueDate(result['ChecklistProgress.initialDueDate']) ===
              'text-red'
              ? 'bg-red-soft'
              : '',
          component: (
            <span
              className={`${
                result['ChecklistProgress.progress'] !== 'Completed' &&
                result['ChecklistProgress.initialDueDate'] &&
                checkDueDate(result['ChecklistProgress.initialDueDate']) ===
                  'text-red'
                  ? 'text-red'
                  : ''
              }`}
            >
              {result['ChecklistProgress.progress'] !== 'Completed' &&
              result['ChecklistProgress.initialDueDate'] &&
              checkDueDate(result['ChecklistProgress.initialDueDate']) ===
                'text-red' ? (
                <span className="position-relative" style={{ top: 5 }}>
                  <MaterialIcon icon="flag" rounded filled clazz="text-red" />
                </span>
              ) : null}
              {result['ChecklistProgress.initialDueDate']
                ? setDateFormat(
                    result['ChecklistProgress.initialDueDate'],
                    'MM/DD/YYYY'
                  )
                : '--'}
            </span>
          ),
        },
        {
          key: 'Completed At',
          component: result['ChecklistProgress.checklistCompletedAt']
            ? setDateFormat(
                result['ChecklistProgress.checklistCompletedAt'],
                'MM/DD/YYYY'
              )
            : '--',
        },
      ],
    };
  });

  return (
    <div>
      <div>
        <GenericTable
          checkbox={false}
          data={rows}
          exportToCSV={true}
          tableData={data}
          columnsToExport={[
            {
              key: 'ChecklistProgress.organizationTitle',
              component: 'Company Name',
            },
            {
              key: 'ChecklistProgress.title',
              component: 'Checklist Name',
            },
            {
              key: 'OrganizationChecklistOwner.ownerName',
              component: 'Owner',
            },
            {
              key: 'ChecklistProgress.progress',
              component: 'Status',
            },
            {
              key: 'ChecklistProgress.initialDueDate',
              component: 'Due Date',
            },
            {
              key: 'ChecklistProgress.checklistCompletedAt',
              component: 'Completed At',
            },
          ]}
          fileName="Checklist Report"
          columns={[
            {
              key: 'ChecklistProgress.organizationTitle',
              component: 'Company Name',
              orderBy: 'ChecklistProgress.organizationTitle',
            },
            {
              key: 'ChecklistProgress.title',
              component: 'Checklist Name',
              orderBy: 'ChecklistProgress.title',
            },
            {
              key: 'OrganizationChecklistOwner.ownerName',
              component: 'Owner',
              orderBy: 'OrganizationChecklistOwner.ownerName',
            },
            {
              key: 'ChecklistProgress.progress',
              component: 'Status',
              orderBy: 'ChecklistProgress.progress',
            },
            {
              key: 'OrganizationChecklistProgress.maxOfLastAttempt',
              component: 'Last Update',
              orderBy: 'OrganizationChecklistProgress.maxOfLastAttempt',
            },
            {
              key: 'ChecklistProgress.initialDueDate',
              component: 'Due Date',
              orderBy: 'ChecklistProgress.initialDueDate',
            },
            {
              key: 'ChecklistProgress.checklistCompletedAt',
              component: 'Completed At',
              orderBy: 'ChecklistProgress.checklistCompletedAt',
            },
          ]}
          usePagination={false}
          noDataInDbValidation={true}
          sortingTable={handleSortByData}
        />
      </div>
    </div>
  );
};

export default ChecklistReport;
