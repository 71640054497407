import React, { useState, useEffect } from 'react';
import AddActivity from './peopleProfile/contentFeed/AddActivity';
import fieldService from '../services/field.service';
import Loading from './Loading';
import RightPanelModal from './modal/RightPanelModal';
import { Card, CardBody, CardHeader } from 'reactstrap';
import ButtonIcon from './commons/ButtonIcon';
import {
  RIGHT_PANEL_WIDTH,
  isPermissionAllowed,
  overflowing,
} from '../utils/Utils';
import { groupBy } from 'lodash';
import AlertWrapper from './Alert/AlertWrapper';
import Alert from './Alert/Alert';
import { useProfileContext } from '../contexts/profileContext';
import AllActivitiesTable from './ActivitiesTable/AllActivitiesTable';
import activityService from '../services/activity.service';
import Pagination from './Pagination';
import PillFilters from './commons/PillFilters';

const activityFiltersList = [
  { id: 'all', label: 'All Activities', status: 'all' },
  { id: 'my', label: 'My Activities', status: 'my' },
];

export const AddActivityButtonsGroup = ({
  moduleMap,
  componentId,
  contactId,
  organizationId,
  dealId,
  profileRefresh,
  contactIs,
  contactInfo,
  setProfileInfo,
  dataType,
  deal,
  organization,
  setRefreshRecentFiles,
  activityIdOpen,
  me,
  step,
  isDeal,
  isContact,
  refresh,
  setRefresh,
}) => {
  const [isShow, setShowModal] = useState(false);
  const [btnType, setIsBtnType] = useState('');
  const { profileInfo } = useProfileContext();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [fieldsBySection, setFieldsBySection] = useState([]);
  const [activityFilters, setActivityFilters] = useState(activityFiltersList);
  const [activityFilter, setActivityFilter] = useState(activityFilters[0]);
  const [filterBy, setFilterBy] = useState({ type: ['task', 'event', 'call'] });
  const [allData, setAllData] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [dataInDB, setDataInDB] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [order, setOrder] = useState([]);
  const [cloneData, setCloneData] = useState(false);
  const [getActivityId, setGetActivityId] = useState({});
  const [activityData, setActivityData] = useState({});
  const [contacts, setContacts] = useState(contactInfo);
  const [deals, setDeals] = useState(deal);
  const [organizations, setOrganizations] = useState(organization);
  const moduleId = {
    dealId,
    contactId,
    organizationId,
  };
  const groupBySection = (fieldsList) => {
    setFieldsBySection(groupBy(fieldsList, 'section'));
  };
  const getFields = async (type) => {
    setLoading(true);
    const { data } = await fieldService.getFields(type, {
      preferred: true,
    });
    if (data?.length > 0) {
      groupBySection(data);
      setLoading(false);
    } else {
      const { data } = await fieldService.createDefaultFields(type);
      groupBySection(data);
      setLoading(false);
    }
  };
  const handleShow = (item) => {
    getFields(item);
    setShowModal(true);
    setIsBtnType(item);
  };
  const closeModal = () => {
    setShowModal(false);
    setGetActivityId();
    setIsBtnType('');
  };
  const loader = () => {
    if (loading) return <Loading />;
  };

  const updateFilterLabel = (id, count) => {
    setActivityFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.id === id ? { ...filter, count } : filter
      )
    );
  };

  const getCountsForTabs = async () => {
    setShowLoading(true);
    const paginationData = {
      page: 1,
      limit: 1,
    };
    try {
      const [allActivitiesData, myActivitiesData] = await Promise.all([
        activityService.getActivity(
          { ...filterBy, [`${dataType}Id`]: moduleId[`${dataType}Id`] },
          paginationData
        ),
        activityService.getActivity(
          {
            ...filterBy,
            [`${dataType}Id`]: moduleId[`${dataType}Id`],
            self: true,
          },
          paginationData
        ),
      ]);
      const allActivitiesCount = allActivitiesData?.pagination?.count;
      const myActivitiesCount = myActivitiesData?.pagination?.count;
      updateFilterLabel('all', allActivitiesCount);
      updateFilterLabel('my', myActivitiesCount);
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoading(false);
    }
  };

  const getData = async () => {
    setShowLoading(true);
    try {
      const data = await activityService.getActivity(
        { ...filterBy, [`${dataType}Id`]: moduleId[`${dataType}Id`] },
        {
          page: pagination.page,
          limit: pagination.limit,
          order,
        }
      );
      setAllData(data?.data);
      setPagination(data?.pagination);
      setDataInDB(Boolean(data?.pagination?.totalPages));
    } catch (err) {
      console.error(err);
      setErrorMessage('Error fetching activities');
    } finally {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filterBy, pagination.page, pagination.limit, order]);

  useEffect(() => {
    getCountsForTabs();
  }, []);

  const handleFilterSelect = (item) => {
    setActivityFilter(item);
    const { status } = item;
    if (status === 'all') {
      setFilterBy({ type: ['task', 'event', 'call'] });
    } else {
      setFilterBy({
        type: ['task', 'event', 'call'],
        self: true,
      });
    }
  };

  const onPaginationChange = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleEditActivity = async (singleItem) => {
    setGetActivityId(singleItem);
    setLoading(true);
    try {
      const singleData = await activityService.getSingleActivity(
        singleItem?.id
      );

      setContacts(singleData?.data?.contact);
      setDeals(singleData?.data?.deal);
      setOrganizations(singleData?.data?.organization);
      setIsBtnType(singleData?.data?.type);
      setShowModal(true);

      const { data } = await fieldService.getFields(singleItem.type, {
        usedField: true,
      });
      const {
        data: { data: customFields },
      } = await activityService.getCustomField(singleItem?.id, {
        page: 1,
        limit: 50,
      });
      let customValues = {};
      data.forEach((field) => {
        if (field.isCustom) {
          customFields.forEach((item) => {
            if (field.key === item.field.key && field.field_type !== 'DATE') {
              customValues = {
                ...customValues,
                [field.key?.toLowerCase().replace(/\s+/g, '')]:
                  field.field_type === 'CURRENCY'
                    ? item.value.substring(1)
                    : item.value,
              };
            } else if (
              field.key === item.field.key &&
              field.field_type === 'DATE'
            ) {
              customValues = {
                ...customValues,
                [field.key?.toLowerCase().replace(/\s+/g, '')]: new Date(
                  item.value
                ),
              };
            }
          });
        }
      });
      customValues = { ...singleData?.data, ...customValues };

      setActivityData(customValues);
      groupBySection(data);
    } catch {
      setErrorMessage('Server Error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <Card className="p-0 border-0 rounded-0 shadow-0">
        <CardHeader className="justify-content-between px-3 pt-0 btn-add-activity">
          <PillFilters
            items={activityFilters}
            currentFilter={activityFilter}
            onFilterChange={handleFilterSelect}
          />
          <div className="d-flex align-items-center gap-2">
            {isPermissionAllowed('activities', 'create')
              ? ['Task', 'Event', 'Call'].map((btn) => (
                  <ButtonIcon
                    key={btn}
                    color="outline-primary"
                    classnames="btn-sm rounded-pill pr-3 font-size-sm font-weight-medium"
                    icon="add"
                    onclick={() => handleShow(btn.toLowerCase())}
                    label={btn
                      .replace(/Task/g, moduleMap.task.singular)
                      .replace(/Call/g, moduleMap.call.singular)
                      .replace(/Event/g, moduleMap.event.singular)}
                  />
                ))
              : ''}
          </div>
        </CardHeader>
        {!step && (
          <CardBody className={`p-0 ${step ? 'shadow-none' : ''}`}>
            <AllActivitiesTable
              allData={allData}
              setData={setAllData}
              setShowLoading={setShowLoading}
              setDataInDB={setDataInDB}
              dataInDB={dataInDB}
              showLoading={showLoading}
              getData={getData}
              cloneData={cloneData}
              setCloneData={setCloneData}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              order={order}
              setOrder={setOrder}
              searchTitle={false}
              paginationInfo={{}}
              setPagination={setPagination}
              isFilterCheck={filterBy}
              isInTab
              handleEditActivity={handleEditActivity}
              checkboxHidden={true}
            />
            <div className="px-3 pb-3">
              <Pagination
                paginationInfo={pagination}
                onPageChange={onPaginationChange}
              />
            </div>
          </CardBody>
        )}
      </Card>
      {isShow && (
        <RightPanelModal
          showModal={isShow}
          setShowModal={() => closeModal()}
          showOverlay={true}
          containerBgColor={'pb-0'}
          containerWidth={RIGHT_PANEL_WIDTH}
          containerPosition={'position-fixed'}
          headerBgColor="bg-gray-5"
          Title={
            <div className="d-flex py-2 align-items-center text-capitalize">
              {cloneData || Object.keys(activityData).length === 0 ? (
                <h3 className="mb-0">
                  Add{' '}
                  {btnType === 'task'
                    ? moduleMap.task.singular
                    : btnType === 'call'
                    ? moduleMap.call.singular
                    : moduleMap.event.singular}
                </h3>
              ) : (
                <h3 className="mb-0">
                  Edit{' '}
                  {btnType === 'task'
                    ? moduleMap.task.singular
                    : btnType === 'call'
                    ? moduleMap.call.singular
                    : moduleMap.event.singular}
                </h3>
              )}
            </div>
          }
        >
          {loading ? (
            loader()
          ) : (
            <>
              {moduleMap.task && (
                <AddActivity
                  moduleMap={moduleMap}
                  dataType={dataType}
                  btnType={btnType}
                  task={moduleMap.task.singular}
                  componentId={componentId}
                  contactId={contactId}
                  organizationId={organizationId}
                  dealId={dealId}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  successMessage={successMessage}
                  setSuccessMessage={setSuccessMessage}
                  getProfileInfo={profileInfo}
                  profileRefresh={profileRefresh}
                  contactIs={contactIs}
                  isModal={isShow}
                  contactInfo={contacts}
                  profileInfo={setProfileInfo}
                  deal={deals}
                  allFields={fieldsBySection}
                  closeModal={() => {
                    overflowing();
                    closeModal();
                    getData();
                  }}
                  organization={organizations}
                  getActivityId={getActivityId}
                  feedId={getActivityId?.id}
                  activityData={activityData}
                  feedInfo={activityData}
                  setCloneData={setCloneData}
                  cloneData={cloneData}
                />
              )}{' '}
            </>
          )}
        </RightPanelModal>
      )}
    </>
  );
};
