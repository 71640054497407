import React, { useState, useEffect } from 'react';

import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import fieldService from '../../../services/field.service';
import { useForm } from 'react-hook-form';
import { groupBy } from 'lodash';
import RightPanelModal from '../../modal/RightPanelModal';
import { RIGHT_PANEL_WIDTH, overflowing } from '../../../utils/Utils';
import OrganizationForm from '../../organizations/OrganizationForm';
import organizationService from '../../../services/organization.service';

const EditOrganization = ({
  children,
  moduleMap,
  data,
  getProfileInfo,
  setProfileInfo,
  updateLabel,
  labelType,
  me,
  setEditModal,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: data,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editMode, setEditMode] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFieldObj, setIsFieldObj] = useState({});
  const [customDataFields, setCustomDataFields] = useState([]);
  const [organizationFields, setOrganizationFields] = useState([]);
  const groupBySection = (fieldsList) => {
    setOrganizationFields(groupBy(fieldsList, 'section'));
  };

  const getFields = async () => {
    setIsLoading(true);
    try {
      const { data } = await fieldService.getFields('organization', {
        usedField: true,
      });
      groupBySection(data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFields();
  }, []);

  const onClose = () => {
    setEditModal(false);
    reset(data);
    overflowing();
  };

  return (
    <>
      {children}
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <RightPanelModal
        showModal={true}
        setShowModal={() => onClose()}
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={RIGHT_PANEL_WIDTH}
        containerPosition={'possetMeition-fixed'}
        headerBgColor="bg-gray-5"
        Title={
          <div className="d-flex py-2 align-items-center">
            <h3 className="mb-0">Edit {moduleMap}</h3>
          </div>
        }
      >
        <OrganizationForm
          fields={organizationFields}
          data={data}
          onClose={onClose}
          editMode={editMode}
          setOrganizationFields={setOrganizationFields}
          setEditMode={setEditMode}
          isFieldsObj={isFieldObj}
          setIsFieldsObj={setIsFieldObj}
          service={organizationService}
          register={register}
          handleSubmit={handleSubmit}
          reset={reset}
          loading={loading}
          setLoading={setLoading}
          isLoading={isLoading}
          setValue={setValue}
          customDataFields={customDataFields}
          setCustomDataFields={setCustomDataFields}
          getFieldState={getFieldState}
          control={control}
          errors={errors}
          labelType={labelType}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          getProfileInfo={getProfileInfo}
          setProfileInfo={setProfileInfo}
          fromNavBar
          updateLabel={updateLabel}
          me={me}
        />
      </RightPanelModal>
    </>
  );
};

export default EditOrganization;
