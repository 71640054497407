import React from 'react';
import { Form, Dropdown } from 'react-bootstrap';

import stringConstants from '../../utils/stringConstants.json';
import MaterialIcon from '../commons/MaterialIcon';
import TooltipComponent from './Tooltip';
import { Badge } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

const constants = stringConstants.settings.resources.courses;

const List = ({ children, className, id, style }) => {
  return (
    <div className={`px-0 mt-3 ${className}`} id={id} style={style}>
      {children}
    </div>
  );
};

const DropdownLesson = ({
  id,
  onChange,
  value,
  name,
  placeholder,
  results,
  error,
  hasMore,
  selection,
  setSelection,
  onDeleteLesson,
  getLessons,
}) => {
  const handleCollapse = () => {
    const dropdownMenu = document.getElementById(id);
    dropdownMenu.classList.remove('show');
  };
  return (
    <Dropdown drop="down" className="rounded " id={'dropdownMenu'}>
      <Dropdown.Toggle
        className="w-100 section-owners-header px-0"
        variant="outline-link"
        id="dropdown"
      >
        <Form.Control
          type="text"
          onChange={onChange}
          id={name}
          name={name}
          placeholder={placeholder}
          results={results}
          maxLength={100}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100 pt-0" id={id}>
        {error.error && (
          <p className="alert-danger px-3 py-1 mb-1 rounded">{error.msg}</p>
        )}
        <List
          className="dropdown-results mt-0"
          id="scrollableDiv"
          style={{ height: 300, overflow: 'auto' }}
        >
          <InfiniteScroll
            dataLength={results.length}
            next={getLessons}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            {value.length > 1 && (
              <p className="alert-light mb-1 px-1 py-0 text-center rounded">
                {constants.labelAddLesson}
              </p>
            )}
            {results?.length === 0 ? (
              <div>No Match Found</div>
            ) : (
              results?.map((lesson, index) => {
                const lessonToAdd = {
                  title: lesson.title,
                  category: lesson?.category?.title,
                  category_id: lesson?.category_id,
                  id: lesson.id,
                };

                const checkUserAdded = selection.some(
                  (item) => item.id === lesson.id
                );

                const handleCheckUncheckLesson = () => {
                  if (checkUserAdded) {
                    onDeleteLesson();
                  } else {
                    selection
                      ? setSelection((selection) => [...selection, lessonToAdd])
                      : setSelection(lessonToAdd);
                  }
                  handleCollapse();
                };

                return (
                  <Dropdown.Item
                    id={`lesson-${index}`}
                    key={index}
                    eventKey={index}
                    onClick={handleCheckUncheckLesson}
                    className={`${
                      checkUserAdded ? 'd-none' : 'd-flex'
                    } rounded py-2 bg-hover-gray-dark cursor-pointer align-items-center justify-content-between px-0 `}
                  >
                    <div className="d-flex align-items-center justify-content-between gap-3 w-50">
                      <h5 className="mb-0 pl-3">{lesson.title || ''}</h5>
                      <Badge
                        color="bg-primary-soft"
                        className="h-100 fs-8 p-2 bg-primary-soft"
                      >
                        {lesson?.category?.title}
                      </Badge>
                    </div>
                    <div>
                      {!checkUserAdded && (
                        <p
                          className="btn btn-xs text-center mb-0 rounded"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (!checkUserAdded) {
                              selection
                                ? setSelection((selection) => [
                                    ...selection,
                                    lessonToAdd,
                                  ])
                                : setSelection(lessonToAdd);
                              handleCollapse();
                            }
                          }}
                        >
                          <TooltipComponent title="Add">
                            <MaterialIcon icon="add" clazz="text-primary" />
                          </TooltipComponent>
                        </p>
                      )}
                      {checkUserAdded && (
                        <p
                          className="btn btn-xs btn-icon btn-icon-sm icon-hover-bg text-center mb-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteLesson();
                            handleCollapse();
                          }}
                        >
                          <TooltipComponent title="Delete">
                            <MaterialIcon icon="delete" />
                          </TooltipComponent>
                        </p>
                      )}
                    </div>
                  </Dropdown.Item>
                );
              })
            )}
          </InfiniteScroll>
        </List>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownLesson;
