import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { CardBody, CardFooter, Form, FormGroup, Label } from 'reactstrap';
import organizationService from '../../services/organization.service';
import contactService from '../../services/contact.service';
import {
  SEARCH_FOR_CONTACT,
  SEARCH_FOR_COMPANY,
  OWNER,
  SEARCH_FOR_INSIGHT,
  PIPELINE,
} from '../../utils/constants';
import {
  onInputChangeAmount,
  onInputSearch,
} from '../../views/Deals/contacts/utils';
import DropdownSelect from '../DropdownSelect';
import {
  DATE_FORMAT,
  DATE_FORMAT_EJS,
  RIGHT_PANEL_WIDTH,
  checkValidDate,
  emailRegex,
  formatHHMMSS,
  urlRegex,
  valueNumberValidator,
  extractNameParts,
  isPermissionAllowed,
} from '../../utils/Utils';
import IdfOwnersHeader from '../idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatepicker from '../inputs/ReactDatpicker';
import AutoComplete from '../AutoComplete';
import ButtonIcon from '../commons/ButtonIcon';
import { renderComponent } from '../peoples/constantsPeople';
import { CheckboxInput } from '../layouts/CardLayout';
import Loading from '../Loading';
import ControllerValidation from '../commons/ControllerValidation';
import stageService from '../../services/stage.service';

import DealProductsV2 from '../../views/Deals/pipelines/DealProductsV2';
import moment from 'moment';
import IdfFormInput from '../idfComponents/idfFormInput/IdfFormInput';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { PricingField } from '../PricingFieldComponent';
import useIsTenant from '../../hooks/useIsTenant';
import AddPicklistOptions from '../peopleProfile/contentFeed/AddPicklistOptions';
import stringConstants from '../../utils/stringConstants.json';
import { useProfileContext } from '../../contexts/profileContext';
const constants = stringConstants.deals.organizations.profile;

const maxPrice = 99999999.0;

const DealForm = ({
  moduleData,
  moduleMap,
  setDealFormData,
  dealFormData,
  searchValue,
  toggleModalSize,
  customDataFields,
  setCustomDataFields,
  initialDeals = {},
  selectedStage,
  handleSubmit,
  setValue,
  errors,
  loading,
  getFieldState,
  isLoading,
  register,
  control,
  onClose,
  selectOrganization,
  setSelectOrganization,
  selectContactPerson,
  setSelectContactPerson,
  fields,
  pipelines,
  pipeline,
  selectedPipeline,
  setSearchOrg,
  setSearchContact,
  setSelectedPipeline,
  pipelineStages,
  setPipelineStages,
  openDeal,
  searchOrg,
  searchContact,
  addProductsClicked,
  setAddProductsClicked,
  setSelectedPipelineStage,
  fromNavbar,
  contactProfile,
  setContactId,
  setContainerWidth,
  organization = {},
  picklistDefault = [],
  initialLoad = {},
  dealData = {},
  allOrganizations,
  isEdited,
  ...props
}) => {
  const picklistInitialValue = [];
  const [allContacts, setAllContact] = useState([]);
  const [charactersRequire, setCharactersRequire] = useState('');
  const [charactersContact, setCharactersContact] = useState('');
  const [dealProducts, setDealProducts] = useState([]);
  const [organizationSelect, setOrganizationSelect] = useState({});
  const [getDealProducts, setGetDealProducts] = useState([]);
  const [productsTotalAmount, setProductsTotalAmount] = useState(0);
  const [loadingContact] = useState(false);
  const [getAmount, setGetAmount] = useState(0);
  const [dateClosed, setDateClosed] = useState({
    date_closed: isEdited ? new Date(dealData?.date_closed) : new Date(),
  });
  const { profileInfo } = useProfileContext();
  const getPipelineStages = async () => {
    const pipelineId = selectedPipeline?.id;
    if (pipelineId || pipeline) {
      const stages = await stageService.getPipelineStages(
        pipelineId || pipeline?.id
      );
      const newStages = stages.map((stage) => {
        return {
          id: stage.id,
          name: stage.name,
          title: stage.name,
          rank: stage.rank,
          stagePosition: stage.position,
        };
      });
      newStages.sort((a, b) => a.rank.localeCompare(b.rank));
      const newStage = selectedStage?.name ? selectedStage : newStages[0];
      setSelectedPipelineStage(newStage);
      setDealFormData({
        ...dealFormData,
        tenant_deal_stage_id: newStage?.id,
        assigned_user_id: profileInfo?.id,
        ...dateClosed,
        lead_source: PIPELINE.toLowerCase(),
      });
      setValue('date_closed', dateClosed.date_closed);
      setValue('tenant_deal_stage_id', newStage?.id);
      setPipelineStages(newStages);
      setSelectedStageOrFirst(newStage);
      if (contactProfile?.first_name) {
        setSelectContactPerson(contactProfile);
      }
      if (organization?.id) {
        setDealFormData({
          ...dealFormData,
          assigned_user_id: profileInfo?.id,
          contact_organization_id: organization?.id,
          tenant_deal_stage_id: newStage?.id,
          ...dateClosed,
          lead_source: PIPELINE.toLowerCase(),
        });
        setValue('contact_organization_id', organization?.id);
      }
      if (contactProfile?.id) {
        setDealFormData({
          ...dealFormData,
          assigned_user_id: profileInfo?.id,
          contact_organization_id: contactProfile?.organization?.id,
          contact_person_id: contactProfile?.id,
          tenant_deal_stage_id: newStage?.id,
          lead_source: PIPELINE.toLowerCase(),
          ...dateClosed,
        });
        setValue('contact_organization_id', contactProfile?.organization?.id);
        setValue('contact_person_id', contactProfile?.id);
      }
      const propertiesToRemove = [
        'id',
        'tenant_id',
        'assigned_user_id',
        'created_by',
        'external_id',
      ];
      if (Object.keys(dealData)?.length > 0) {
        const cleanedContactData = Object.keys(dealData)
          .filter((key) => !propertiesToRemove.includes(key))
          .reduce((acc, key) => {
            acc[key] = dealData[key];
            return acc;
          }, {});
        Object.keys(cleanedContactData).forEach((key) => {
          let updatedValue = cleanedContactData[key];

          if (
            key === 'date_closed' &&
            checkValidDate(cleanedContactData[key])
          ) {
            const existingDate = new Date(cleanedContactData[key]);
            const currentDate = new Date();

            updatedValue =
              existingDate < currentDate
                ? currentDate.toISOString()
                : cleanedContactData[key];
          }

          setDealFormData((prevFormData) => ({
            ...prevFormData,
            [key]: updatedValue,
            products: dealData?.deal_products?.map((item) => {
              return {
                product_id: item?.product_id,
                price: item?.price,
                quantity: item?.quantity,
              };
            }),
          }));

          setAddProductsClicked(cleanedContactData?.deal_products?.length > 0);
          setDealProducts(cleanedContactData?.deal_products);
          setValue(key, updatedValue);
        });
      }
    }
  };
  useEffect(() => {
    setDealProducts([]);
  }, []);

  const getTotal = (items) => {
    return [...items].reduce((total, b) => total + b.quantity * b.price, 0);
  };

  useEffect(() => {
    if (getDealProducts?.length > 0) {
      setDealFormData({
        ...dealFormData,
        products: getDealProducts.map((p) => ({
          product_id: p?.product?.id,
          quantity: parseFloat(p.quantity),
          price: parseFloat(p.price),
        })),
        amount: valueNumberValidator(
          getTotal(getDealProducts).toString(),
          2,
          maxPrice
        ),
      });
      isEdited && setContainerWidth(840);
    }
  }, [getDealProducts]);

  useEffect(() => {
    if (dealProducts?.length > 0) {
      const newDealProducts = dealProducts?.map((product) => ({
        id: product.id,
        product_id: product?.description?.id,
        quantity: parseFloat(product.quantity),
        price: parseFloat(product.price),
      }));

      setDealFormData({
        ...dealFormData,
        products: newDealProducts,
        amount: valueNumberValidator(
          toString(productsTotalAmount),
          2,
          maxPrice
        ),
      });
    }
  }, [productsTotalAmount]);

  useEffect(() => {
    if (getDealProducts?.length === 0 && dealProducts?.length === 0)
      setDealFormData({
        ...dealFormData,
        amount: parseInt(getAmount),
      });
  }, [getDealProducts, dealProducts]);

  const setSelectedStageOrFirst = (firstStage) => {
    let initialLabel = '';

    // setting pre-selected stage if the component gets it from some other component
    if (firstStage && Object.hasOwn(firstStage, 'title')) {
      initialLabel = {
        id: firstStage.id,
        title: firstStage.title,
      };
    } else {
      initialLabel = find(initialDeals) || firstStage;
    }
    return initialLabel;
  };

  useEffect(() => {
    getPipelineStages();
  }, [pipeline, selectedPipeline, openDeal]);
  const amountHandler = (e) => {
    let value = e.target.value <= 0 ? '' : e.target.value;

    value = valueNumberValidator(value, 2, maxPrice);
    setGetAmount(value);
    e.target.value = value;
    onInputChangeAmount(e, setDealFormData, dealFormData);
  };

  const onChangeClosingDate = (date) => {
    if (!date) {
      setDealFormData({
        ...dealFormData,
        date_closed: null,
      });
      setValue('date_closed', null);
      setDateClosed({ date_closed: null });
    } else {
      setDealFormData({
        ...dealFormData,
        date_closed: new Date(date),
      });
      setValue('date_closed', new Date(date));
      setDateClosed({ date_closed: new Date(date) });
    }
  };

  const toggleAddProducts = (e) => {
    e?.preventDefault();
    if (addProductsClicked) {
      setProductsTotalAmount(0);
      setDealProducts([]);
    }
    setContainerWidth(addProductsClicked ? RIGHT_PANEL_WIDTH : 840);
    setAddProductsClicked(!addProductsClicked);
  };

  const handlePipelineStageSelect = (item) => {
    setSelectedPipelineStage(item);
    setDealFormData({
      ...dealFormData,
      tenant_deal_stage_id: item?.id,
    });
    setValue('tenant_deal_stage_id', item?.id);
  };

  const onHandleCustomField = (e, id, value_type, field_type) => {
    const target = e.target;
    let value = '';
    if (value_type === 'string' && target.value !== '') {
      value = target.value;
    }
    if (value_type === 'number' && target.value !== '') {
      value = parseInt(target.value);
    }
    if (field_type === 'CURRENCY') {
      value = `$${target.value}`;
    } else if (field_type === 'TIME') {
      value = moment(value).format(formatHHMMSS);
    }
    let updated = false;
    setDealFormData({ ...dealFormData, [target.name]: target.value });
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return {
          field_id: id,
          value,
        };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      setCustomDataFields([...fieldData, { field_id: id, value }]);
    }
  };

  const onHandleCustomCheckBox = (e, id) => {
    const target = e.target;
    setDealFormData({ ...dealFormData, [target.name]: target.checked });

    const isDuplicate = customDataFields.some((field) => field.field_id === id);

    if (!isDuplicate) {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: target.checked },
      ]);
    }
    setValue(target.name, target.checked);
  };

  const onHandleCustomDate = (date, id, fieldName) => {
    if (!date) {
      const fieldExisted = customDataFields.find((f) => f.field_id === id);
      if (fieldExisted) {
        const updatedFields = customDataFields.map((f) =>
          f.field_id === id ? { ...f, value: null } : f
        );
        setCustomDataFields(updatedFields);
      } else {
        setCustomDataFields([
          ...customDataFields,
          { field_id: id, value: null },
        ]);
      }
      setDealFormData({ ...dealFormData, [fieldName]: null });
      setValue(fieldName, null);
    } else {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: new Date(date) },
      ]);
      setValue(fieldName, new Date(date));
      setDealFormData({ ...dealFormData, [fieldName]: new Date(date) });
    }
  };

  const handlePipelineSelect = (item) => {
    setSelectedPipeline(item);
    setSelectedPipelineStage({});
  };

  const onHandlePicklistSingle = (item, id, fieldName) => {
    let picked;
    if (item.name === '-None-') {
      picked = '';
    } else {
      picked = [{ value: item.name }];
    }
    let updated = false;
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: picked, key: fieldName },
      ]);
    }
    setDealFormData({ ...dealFormData, [fieldName]: picked });
    setValue(fieldName, picked);
  };

  const selectPicklistValue = (fieldName, value_option, id) => {
    if (dealFormData[fieldName] === '') {
      return '-None-';
    }
    if (
      dealFormData[fieldName] &&
      dealFormData[fieldName][0] &&
      typeof dealFormData[fieldName][0].value === 'string'
    ) {
      return dealFormData[fieldName][0].value;
    }

    const defaultItem = value_option.find(
      (item) => item.default === true && item.value !== '-None-'
    );

    if (defaultItem && defaultItem.value && !(fieldName in initialLoad)) {
      initialLoad[fieldName] = 'yes';
      handlePicklistDefault(
        {
          field_id: id,
          value: [{ value: defaultItem.value }],
          key: fieldName,
        },
        fieldName
      );
      return defaultItem.value;
    }

    return '-None-';
  };

  const onHandlePicklistMulti = (val, id, fieldName) => {
    let picked;
    if (val.length === 0) {
      picked = '';
    } else {
      picked = val;
    }
    let updated = false;
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      const tempCustom = [
        ...customDataFields,
        { field_id: id, value: picked, key: fieldName },
      ];
      setCustomDataFields(tempCustom);
    }
    setDealFormData({ ...dealFormData, [fieldName]: picked });
    setValue(fieldName, picked);
  };

  const selectPicklistMultiValue = (fieldName, value_option, id) => {
    if (dealFormData[fieldName] === '') {
      return [];
    }
    if (dealFormData[fieldName]) {
      return dealFormData[fieldName];
    }

    const defaultItem = value_option.find(
      (item) => item.default === true && item.value !== '-None-'
    );

    if (defaultItem && defaultItem.value && !(fieldName in initialLoad)) {
      initialLoad[fieldName] = 'yes';
      handlePicklistDefault(
        {
          field_id: id,
          value: [{ value: defaultItem.value }],
          key: fieldName,
        },
        fieldName
      );
      return [{ value: defaultItem.value }];
    }

    return [];
  };

  const handlePicklistDefault = (dataField, fieldName) => {
    setDealFormData({ ...dealFormData, [fieldName]: dataField.value });
    picklistInitialValue.push({ key: dataField.key, value: dataField.value });
    picklistDefault.push(dataField);
    setCustomDataFields([...customDataFields, ...picklistDefault]);
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setDealFormData({
      ...dealFormData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (picklistInitialValue.length !== 0) {
      handleInitialValue(picklistInitialValue);
    }
  }, [picklistInitialValue]);

  const handleInitialValue = (items) => {
    if (Array.isArray(items) && items.length !== 0) {
      for (const item of items) {
        setValue(item.key, item.value);
      }
    }
  };

  const loader = () => {
    if (isLoading) return <Loading />;
  };
  const stateChange = (e) => {
    setValue('contact_organization_id', '');
    setDealFormData({
      ...dealFormData,
      contact_organization_id: '',
    });
    setSelectContactPerson({});
    const match = e.target.value.match(/([A-Za-z])/g);
    if (match && match.length >= 2) {
      setCharactersRequire('');
      onInputSearch(e, searchOrg, setSearchOrg);
    } else {
      setCharactersRequire(match?.length);
    }

    if (e.target.value === '') clearState('contact_organization_id');
  };

  const handleContactChange = (e) => {
    const match = e.target.value.match(/([A-Za-z])/g);
    if (match && match.length >= 2) {
      setCharactersContact('');
      onInputSearch(e, searchContact, setSearchContact);
    } else {
      setCharactersContact(match?.length);
    }
    if (e.target.value === '') clearState('contact_person_id');
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleContactSelect = async (item) => {
    setDealFormData({
      ...dealFormData,
      contact_person_id: item?.id,
    });
    setSelectContactPerson(item);
    setValue('contact_person_id', item?.id);
  };

  const handleCompanySelect = (item) => {
    setDealFormData({
      ...dealFormData,
      contact_organization_id: item?.id,
    });
    setValue('contact_organization_id', item?.id);
    if (!item?.customOption) {
      getOrganizationContacts(item?.id);
    }
  };
  useEffect(() => {
    if (
      Object.keys(organizationSelect)?.length > 0 ||
      Object.keys(organization)?.length > 0
    ) {
      getOrganizationContacts(organizationSelect?.id || organization?.id);
    }
  }, [organizationSelect?.id, organization?.id]);

  const createOrganization = async (name) => {
    const organization = {
      name,
    };
    const { data } = await organizationService.createOrganization(organization);
    setValue('contact_organization_id', data?.id);
    setDealFormData({
      ...dealFormData,
      contact_organization_id: data?.id,
    });
    if (data?.id) {
      getOrganizationContacts(data?.id);
    }
    setOrganizationSelect(data);
  };

  const createNewUser = async (name) => {
    const nameParts = extractNameParts(name);
    const user = {
      ...nameParts,
      organization_id: dealFormData?.contact_organization_id,
    };
    const { data } = await contactService.createContact(user);
    setDealFormData({
      ...dealFormData,
      contact_person_id: data?.id,
    });
  };

  const getOrganizationContacts = async (id) => {
    const organizationContacts = await contactService
      .getContactsByOrganizationId(
        {
          organizationId: id,
          ...searchContact,
        },
        {
          page: 1,
          limit: 10,
        }
      )
      .catch((err) => {
        console.log(err);
      });

    const { contacts } = organizationContacts || {};
    setAllContact(
      contacts?.map((c) => ({ ...c, name: `${c.first_name} ${c.last_name}` }))
    );
  };
  const clearState = (name) => {
    setValue(name, '');
    const updatedFormData = { ...dealFormData };
    delete updatedFormData[name];
    setDealFormData(updatedFormData);
  };

  useEffect(() => {
    if (
      getDealProducts?.length === 0 ||
      !getDealProducts?.every((item) => item?.product)
    ) {
      delete dealFormData?.products;
    }
  }, [getDealProducts, dealProducts, addProductsClicked, dealFormData]);

  return (
    <>
      {isLoading ? (
        loader()
      ) : (
        <>
          <CardBody className="right-bar-vh h-100 overflow-y-auto">
            <Form onSubmit={handleSubmit}>
              {Object.keys(fields).map((key, index) => {
                return (
                  <div key={`fields-${index}`}>
                    <h5 className="pb-0 pt-2">
                      {key.replace(/Deal/g, capitalizeFirstLetter(moduleMap))}
                    </h5>
                    <div>
                      {fields[key]?.length > 0 &&
                        fields[key]?.map((item) => {
                          const {
                            field_type,
                            columnName,
                            key,
                            mandatory,
                            isCustom,
                            id,
                            value_type,
                            value_option,
                          } = item;
                          const fieldName =
                            columnName ||
                            key?.toLowerCase().replace(/\s+/g, '');
                          const contactName = selectContactPerson?.first_name
                            ? `${selectContactPerson.first_name} ${selectContactPerson.last_name}`
                            : '';
                          return (
                            <>
                              {key !== 'Company' &&
                              key !== 'Pipeline & Stage' &&
                              key !== 'Contact Person' &&
                              field_type !== 'CHECKBOX' &&
                              field_type !== 'PHONE' &&
                              field_type !== 'CURRENCY' &&
                              field_type !== 'DATE' &&
                              field_type !== 'PICKLIST' &&
                              field_type !== 'PICKLIST_MULTI' &&
                              field_type !== 'TIME' ? (
                                <div key={item?.id}>
                                  {renderComponent(field_type, {
                                    value: dealFormData,
                                    className: 'text-capitalize',
                                    label: key.replace(
                                      /Deal/,
                                      moduleData.deal.singular
                                    ),
                                    containerClasses:
                                      'flex-column align-items-start',
                                    inputClass: mandatory
                                      ? 'border-left-4 border-left-danger'
                                      : '',
                                    validationConfig: {
                                      required: mandatory,
                                      inline: false,
                                      validateSpaces: mandatory,
                                      onChange: (e) =>
                                        isCustom
                                          ? onHandleCustomField(
                                              e,
                                              id,
                                              value_type
                                            )
                                          : fieldName === 'amount'
                                          ? amountHandler(e)
                                          : onChange(e),
                                      pattern:
                                        field_type === 'EMAIL'
                                          ? {
                                              value: emailRegex,
                                              message:
                                                'Please enter a valid email.',
                                            }
                                          : field_type === 'URL'
                                          ? {
                                              value: urlRegex,
                                              message:
                                                'Please enter a valid URL',
                                            }
                                          : '',
                                    },
                                    errors,
                                    register,
                                    errorDisplay: 'mb-0 w-100',
                                    fieldType:
                                      field_type.toLowerCase() === 'url'
                                        ? 'text'
                                        : field_type.toLowerCase(),
                                    type:
                                      field_type === 'TEXT'
                                        ? 'textarea'
                                        : 'input',
                                    disabled:
                                      fieldName === 'amount'
                                        ? addProductsClicked === true
                                        : '',
                                    name: fieldName,
                                    placeholder:
                                      field_type === 'NUMBER'
                                        ? '0'
                                        : key.replace(
                                            /Deal/,
                                            moduleData.deal.singular
                                          ),
                                  })}
                                </div>
                              ) : (
                                ''
                              )}
                              {field_type === 'PHONE' && (
                                <FormGroup row className="py-1">
                                  <Label
                                    md={3}
                                    className="text-right font-size-sm fw-normal"
                                  >
                                    {key}
                                  </Label>
                                  <Col md={9} className="pl-0">
                                    <IdfFormInput
                                      className="mb-0 w-100"
                                      placeholder={key}
                                      value={dealFormData}
                                      name={item?.columnName}
                                      maxLength={14}
                                      onChange={(e) => onChange(e)}
                                      autocomplete="off"
                                    />
                                  </Col>
                                </FormGroup>
                              )}
                              {field_type === 'TIME' && (
                                <FormGroup
                                  row
                                  className="py-1 align-items-center"
                                >
                                  <Label
                                    md={3}
                                    className="text-right font-size-sm"
                                  >
                                    {key}
                                  </Label>
                                  <Col md={9} className="pl-0">
                                    <div className="date-picker input-time w-100">
                                      <TimePickerComponent
                                        id={`start-time`}
                                        cssClass="e-custom-style"
                                        openOnFocus={true}
                                        value={
                                          dealFormData[fieldName] || '12:00 PM'
                                        }
                                        format="hh:mm a"
                                        placeholder="Start Time"
                                        onChange={(e) =>
                                          onHandleCustomField(
                                            e,
                                            id,
                                            value_type,
                                            field_type
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                </FormGroup>
                              )}
                              {field_type === 'CURRENCY' && (
                                <PricingField
                                  label={key}
                                  name={fieldName}
                                  placeholder={key}
                                  errors={errors}
                                  register={register}
                                  disabled={dealFormData?.products?.length > 0}
                                  onChange={(e) =>
                                    isCustom
                                      ? onHandleCustomField(
                                          e,
                                          id,
                                          value_type,
                                          field_type
                                        )
                                      : onChange(e)
                                  }
                                  validationConfig={{
                                    required: mandatory,
                                    inline: false,
                                  }}
                                  value={dealFormData}
                                />
                              )}
                              {field_type === 'PICKLIST' && (
                                <FormGroup row className="py-1">
                                  <Label
                                    md={3}
                                    className="text-right font-size-sm"
                                  >
                                    {key}
                                  </Label>
                                  <Col md={9} className="pl-0">
                                    <ControllerValidation
                                      name={fieldName}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key} is required.`
                                          : '',
                                      }}
                                      errors={errors}
                                      form={dealFormData}
                                      errorDisplay="mb-0"
                                      control={control}
                                      renderer={({ field }) => (
                                        <DropdownSelect
                                          data={value_option.map((item, i) => {
                                            return {
                                              id: i,
                                              name: item.value,
                                            };
                                          })}
                                          onHandleSelect={(item) => {
                                            onHandlePicklistSingle(
                                              item,
                                              id,
                                              fieldName
                                            );
                                          }}
                                          select={selectPicklistValue(
                                            fieldName,
                                            value_option,
                                            id
                                          )}
                                          placeholder="Select Option"
                                          customClasses={
                                            'w-100 overflow-y-auto max-h-300'
                                          }
                                          validationConfig={{
                                            required: mandatory,
                                          }}
                                          fieldState={getFieldState(fieldName)}
                                        />
                                      )}
                                    />
                                  </Col>
                                </FormGroup>
                              )}
                              {field_type === 'PICKLIST_MULTI' && (
                                <FormGroup row className="py-1">
                                  <Label
                                    md={3}
                                    className="text-right font-size-sm"
                                  >
                                    {key}
                                  </Label>
                                  <Col md={9} className="pl-0">
                                    <ControllerValidation
                                      name={fieldName}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key} is required.`
                                          : '',
                                      }}
                                      errors={errors}
                                      form={dealFormData}
                                      errorDisplay="mb-0"
                                      control={control}
                                      renderer={({ field }) => (
                                        <AddPicklistOptions
                                          dropdownList={value_option.filter(
                                            (item) => item.value !== '-None-'
                                          )}
                                          validationConfig={{
                                            required: mandatory,
                                          }}
                                          fieldState={getFieldState(fieldName)}
                                          placeholder={'Add Options'}
                                          value={selectPicklistMultiValue(
                                            fieldName,
                                            value_option,
                                            id,
                                            fieldName
                                          )}
                                          setValue={(e) =>
                                            onHandlePicklistMulti(
                                              e,
                                              id,
                                              fieldName
                                            )
                                          }
                                          tooltip={constants.tooltipTagInput}
                                          labelSize="full"
                                          onChange={(e) => {}}
                                        />
                                      )}
                                    />
                                  </Col>
                                </FormGroup>
                              )}
                              {field_type === 'CHECKBOX' && (
                                <FormGroup row className="py-1">
                                  <Label
                                    md={3}
                                    className="text-right font-size-sm"
                                  ></Label>
                                  <Col md={9} className="pl-0">
                                    <ControllerValidation
                                      name={fieldName}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key} is required.`
                                          : '',
                                      }}
                                      errors={errors}
                                      form={dealFormData}
                                      errorDisplay="mb-0"
                                      control={control}
                                      renderer={({ field }) => (
                                        <CheckboxInput
                                          id={fieldName}
                                          onChange={(e) =>
                                            isCustom
                                              ? onHandleCustomCheckBox(e, id)
                                              : onChange(e)
                                          }
                                          label={key}
                                          name={fieldName}
                                          validationConfig={{
                                            required: mandatory
                                              ? `${key} is required.`
                                              : '',
                                          }}
                                          fieldState={getFieldState(fieldName)}
                                          checked={dealFormData[fieldName]}
                                        />
                                      )}
                                    />
                                  </Col>
                                </FormGroup>
                              )}
                              {key === 'Company' && (
                                <FormGroup row className="py-1">
                                  <Label
                                    md={3}
                                    className="text-right font-size-sm"
                                  >
                                    {key.replace(
                                      /Company/,
                                      moduleData.organization.singular
                                    )}
                                  </Label>
                                  <Col md={9} className="pl-0">
                                    <ControllerValidation
                                      name={fieldName}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key.replace(
                                              /Company/,
                                              moduleData.organization.singular
                                            )} is required.`
                                          : '',
                                      }}
                                      errors={errors}
                                      form={dealFormData}
                                      errorDisplay="mb-0"
                                      control={control}
                                      renderer={({ field }) => (
                                        <AutoComplete
                                          id="contact_organization_id"
                                          placeholder={
                                            useIsTenant().isSynovusBank
                                              ? SEARCH_FOR_INSIGHT
                                              : SEARCH_FOR_COMPANY.replace(
                                                  /Company/,
                                                  moduleData.organization
                                                    .singular
                                                )
                                          }
                                          name="contact_organization_id"
                                          onChange={(name) => stateChange(name)}
                                          charactersRequire={charactersRequire}
                                          data={allOrganizations}
                                          type={
                                            moduleData.organization.singular
                                          }
                                          onHandleSelect={(item) =>
                                            handleCompanySelect(item)
                                          }
                                          validationConfig={{
                                            required: mandatory
                                              ? `${key} is required.`
                                              : '',
                                          }}
                                          clearState={(e) => clearState(e)}
                                          fieldState={getFieldState(fieldName)}
                                          customKey="name"
                                          selected={
                                            organization?.name ||
                                            organizationSelect?.name
                                          }
                                          search={searchOrg.search}
                                          createItem={
                                            isPermissionAllowed(
                                              'organizations',
                                              'create'
                                            )
                                              ? (data) => {
                                                  createOrganization(data);
                                                }
                                              : null
                                          }
                                        />
                                      )}
                                    />
                                  </Col>
                                </FormGroup>
                              )}
                              {key === 'Pipeline & Stage' && (
                                <FormGroup row className="py-1">
                                  <Label
                                    md={3}
                                    className="text-right font-size-sm"
                                  >
                                    {key.replace(
                                      /Pipeline/g,
                                      moduleData.deal.singular
                                    )}
                                  </Label>
                                  <Col
                                    md={9}
                                    className="pl-0 d-flex align-items-center"
                                  >
                                    <ControllerValidation
                                      name={fieldName}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key.replace(
                                              /Pipeline/g,
                                              moduleData.deal.singular
                                            )} is required.`
                                          : '',
                                      }}
                                      errors={errors}
                                      form={dealFormData}
                                      errorDisplay="mb-0"
                                      control={control}
                                      renderer={({ field }) => (
                                        <Row>
                                          <FormGroup className="col w-50 mb-0">
                                            <DropdownSelect
                                              data={pipelines}
                                              customTitle="name"
                                              name={fieldName}
                                              hideIcon={true}
                                              allOption={false}
                                              validationConfig={{
                                                required: mandatory
                                                  ? `${key.replace(
                                                      /Pipeline/g,
                                                      moduleData.deal.singular
                                                    )} is required.`
                                                  : '',
                                              }}
                                              fieldState={getFieldState(
                                                fieldName
                                              )}
                                              customClasses="w-100"
                                              placeholder={`Select ${moduleData.deal.singular}`}
                                              toggleButtonClasses="rounded-right-0"
                                              onHandleSelect={(item) =>
                                                handlePipelineSelect(item)
                                              }
                                              select={
                                                selectedPipeline?.name ||
                                                dealData?.tenant_deal_stage
                                                  ?.pipeline?.name ||
                                                pipeline?.name
                                              }
                                            />
                                          </FormGroup>
                                          <FormGroup className="col w-50 pl-0 mb-0">
                                            <DropdownSelect
                                              data={pipelineStages}
                                              customTitle="title"
                                              hideIcon={true}
                                              validationConfig={{
                                                required: mandatory
                                                  ? `${key} is required.`
                                                  : '',
                                              }}
                                              fieldState={getFieldState(
                                                'stage'
                                              )}
                                              allOption={false}
                                              customClasses="w-100"
                                              toggleButtonClasses="rounded-left-0"
                                              placeholder={`Select ${moduleData.deal.singular}  Stage`}
                                              onHandleSelect={(item) =>
                                                handlePipelineStageSelect(item)
                                              }
                                              select={
                                                selectedStage?.name ||
                                                dealData?.tenant_deal_stage
                                                  ?.name
                                              }
                                            />
                                          </FormGroup>
                                        </Row>
                                      )}
                                    />
                                  </Col>
                                </FormGroup>
                              )}
                              {key === 'Contact Person' && (
                                <FormGroup row className="py-1">
                                  <Label
                                    md={3}
                                    className="text-right font-size-sm"
                                  >
                                    {key.replace(
                                      /Contact/,
                                      moduleData.contact.singular
                                    )}
                                  </Label>
                                  <Col md={9} className="pl-0">
                                    <ControllerValidation
                                      name={fieldName}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key.replace(
                                              /Contact/,
                                              moduleData.contact.singular
                                            )} is required.`
                                          : '',
                                      }}
                                      errors={errors}
                                      form={dealFormData}
                                      errorDisplay="mb-0"
                                      control={control}
                                      renderer={({ field }) => (
                                        <AutoComplete
                                          id="contact_person_id"
                                          placeholder={SEARCH_FOR_CONTACT.replace(
                                            /contact/,
                                            moduleData.contact.singular
                                          )}
                                          loading={loadingContact}
                                          name="contact_person_id"
                                          type={moduleData.contact.singular?.toLowerCase()}
                                          charactersRequire={charactersContact}
                                          showAvatar={true}
                                          disabled={
                                            !dealFormData?.contact_organization_id
                                          }
                                          customKey="name"
                                          clearState={(name) =>
                                            clearState(name)
                                          }
                                          onChange={(e) =>
                                            handleContactChange(e)
                                          }
                                          validationConfig={{
                                            required: mandatory
                                              ? `${key.replace(
                                                  /Contact/,
                                                  moduleData.contact.singular
                                                )} is required.`
                                              : '',
                                          }}
                                          fieldState={getFieldState(fieldName)}
                                          data={allContacts}
                                          onHandleSelect={(item) =>
                                            handleContactSelect(item)
                                          }
                                          search={searchContact.search}
                                          selected={contactName}
                                          createItem={(data) => {
                                            createNewUser(data);
                                          }}
                                        />
                                      )}
                                    />
                                  </Col>
                                </FormGroup>
                              )}
                              {field_type === 'DATE' && (
                                <FormGroup row className="py-1">
                                  <Label
                                    md={3}
                                    className="text-right font-size-sm"
                                  >
                                    {key}
                                  </Label>
                                  <Col md={9} className="pl-0">
                                    <ControllerValidation
                                      name={fieldName}
                                      validationConfig={{
                                        required: mandatory
                                          ? `${key} is required.`
                                          : '',
                                      }}
                                      errors={errors}
                                      form={dealFormData}
                                      errorDisplay="mb-0"
                                      control={control}
                                      renderer={({ field }) => (
                                        <ReactDatepicker
                                          id={fieldName}
                                          {...field}
                                          name={fieldName}
                                          format={DATE_FORMAT_EJS}
                                          todayButton="Today"
                                          autoComplete="off"
                                          validationConfig={{
                                            required: mandatory
                                              ? `${key} is required.`
                                              : '',
                                          }}
                                          fieldState={getFieldState(fieldName)}
                                          value={
                                            isEdited && dealFormData[fieldName]
                                              ? dateClosed.date_closed
                                              : dealFormData[fieldName]
                                              ? new Date(
                                                  dealFormData[fieldName]
                                                )
                                              : ''
                                          }
                                          className="form-control mx-0 mb-0"
                                          placeholder={DATE_FORMAT}
                                          onChange={(date) =>
                                            isCustom
                                              ? onHandleCustomDate(
                                                  date,
                                                  id,
                                                  fieldName
                                                )
                                              : onChangeClosingDate(date)
                                          }
                                        />
                                      )}
                                    />
                                  </Col>
                                </FormGroup>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
              {isPermissionAllowed('products', 'create') &&
                !addProductsClicked && (
                  <div className="py-2">
                    <ButtonIcon
                      color="outline"
                      icon="add"
                      type="button"
                      onclick={(e) => toggleAddProducts(e)}
                      label={moduleData.product.singular}
                      classnames={`border-0 px-2 btn-block bg-hover-gray font-weight-semi-bold text-left bg-gray-5 rounded text-primary`}
                    />
                  </div>
                )}
              {addProductsClicked && (
                <div className="mt-3">
                  <DealProductsV2
                    moduleData={moduleData}
                    heading={`Associated ${moduleData.product.singular}`}
                    productData={dealData?.deal_products}
                    setDeal={setDealFormData}
                    deal={
                      Object?.keys(dealData)?.length > 0 ? dealFormData : ''
                    }
                    dealMode={2}
                    toggle={toggleAddProducts}
                    setGetDealProducts={setGetDealProducts}
                  />
                </div>
              )}
              <FormGroup className="mb-2">
                <Label>{OWNER}</Label>
                <IdfOwnersHeader
                  id="assigned_user_id"
                  name="assigned_user_id"
                  showAvatar={true}
                  isClickable={false}
                  mainOwner={profileInfo}
                  activity={true}
                  preowners={props?.ownerData}
                  addBtnStyles={'bg-gray-5 add-icon'}
                  ownerData={props?.ownerData}
                  setOwnerData={props?.setOwnerData}
                  allowDelete
                  {...props}
                />
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter className="bg-gray-5">
            <div className="d-flex gap-2 justify-content-end align-items-center">
              <button
                type="button"
                className="btn btn-sm btn-white"
                data-dismiss="modal"
                onClick={onClose}
              >
                Cancel
              </button>
              <ButtonIcon
                type="button"
                onclick={handleSubmit}
                classnames="btn-sm"
                label={'Save'}
                loading={loading}
                color="primary"
              />
            </div>
          </CardFooter>
        </>
      )}
    </>
  );
};

export default DealForm;
