import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import {
  checkIfNaicsOrReportDateEmpty,
  cleanDataWidgets,
  formatCurrencyField,
  NAICS_STORAGE_KEY,
  overflowing,
} from '../../utils/Utils';
import SimpleModalCreation from '../modal/SimpleModalCreation';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  ListGroup,
  Row,
} from 'reactstrap';
import SicNaicsAutoComplete from '../prospecting/v2/common/SicNaicsAutoComplete';
import ReactDatepicker from '../inputs/ReactDatpicker';
import { Accordion, FormCheck, InputGroup } from 'react-bootstrap';
import _ from 'lodash';
import OrganizationService from '../../services/organization.service';
import {
  ActionTypes,
  ReportFormErrorFields,
  ReportTypes,
  WorkingCapitalReportSections,
} from './reports.constants';
import { useProfileContext } from '../../contexts/profileContext';
import ReportService from '../../services/report.service';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import useSicNaicsChangeDetect from '../../hooks/useSicNaicsChangeDetect';
import {
  APOData,
  AROData,
  DPODSOFactors,
  DPOFTIData,
  DPOTPData,
  DSOFTIData,
  DSOTPData,
  DisclaimerData,
  FraudMitigationData,
  LOCData,
  RTMData,
  TODDData,
  UIEData,
  UWCData,
  WidgetTypes,
} from '../reportbuilder/constants/widgetsConstants';
import MaterialIcon from '../commons/MaterialIcon';
import TooltipComponent from '../lesson/Tooltip';
import {
  calculateMonthDataUsage,
  PdfFileNames,
  updateWidgetBySection,
  workingCapitalMapping,
} from './reports.helper.functions';
import ReportAccordionToggle from './ReportAccordionToggle';
import ReportSectionIncludedLabel from './ReportSectionIncludedLabel';
import IconHeadingBlock from '../reportbuilder/blocks/IconHeadingBlock';
import WidgetWithActionButtons from '../reportbuilder/widgets/WidgetWithActionButtons';
import useWidgetsLibrary from '../reportbuilder/widgets/useWidgetsLibrary';
import NoDataFound from '../commons/NoDataFound';
import moment from 'moment';
import useIsTenant from '../../hooks/useIsTenant';
import usePermission from '../../hooks/usePermission';
import { PermissionsConstants } from '../../utils/permissions.constants';
import ValidationErrorText from '../commons/ValidationErrorText';

import useJumpToNextInputOnEnter from '../../hooks/useJumpToNextInputOnEnter';
import { WCInputField, WorkingCapitalFields } from './WCInputField';

const FactorsMergedDPODSO = ({
  widget,
  handleFactorChange,
  isExcelBank,
  stacked,
}) => {
  const factorsText = DPOFTIData.factors;
  // first check if widget has preloaded factors
  const dpoFactors = widget?.DPO?.widgetConfig?.factors || DPODSOFactors.DPO;
  const dsoFactors = widget?.DSO?.widgetConfig?.factors || DPODSOFactors.DSO;

  return (
    <div>
      <Card className={`mb-3 ${stacked ? 'mr-0' : 'mr-3'}`}>
        <CardBody>
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="flex-fill">Factors That Impact</h5>
            <div className="d-flex align-items-center gap-2">
              <h5 className="pl-4 pr-5">
                <TooltipComponent title="Days Payable Outstanding">
                  <span>DPO</span>
                </TooltipComponent>
              </h5>
              <h5 className="pr-1">
                <TooltipComponent title="Days Sales Outstanding">
                  <span>DSO</span>
                </TooltipComponent>
              </h5>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex flex-column gap-2 flex-fill">
              {factorsText.map((factor) => (
                <div
                  key={factor.heading}
                  className="d-flex align-items-center gap-1"
                >
                  <span className="p-1 rounded-circle rpt-icon-lite d-flex align-items-center justify-content-center">
                    <MaterialIcon
                      icon={factor.icon}
                      symbols
                      clazz="font-size-lg"
                    />
                  </span>
                  <h6 className="mb-0">{factor.heading}</h6>
                </div>
              ))}
            </div>
            <div
              className="d-flex align-items-center pr-5 flex-column"
              style={{ gap: '1rem' }}
            >
              {dpoFactors.map((factor) => (
                <FormCheck
                  type="switch"
                  key={factor.heading}
                  custom={true}
                  id={`${factor.heading}-toggle-dpo`}
                  name={`${factor.heading}-toggle-dpo`}
                  checked={factor.isChecked}
                  onChange={() =>
                    handleFactorChange(factor, DPOFTIData.extraType)
                  }
                  label=""
                  className="fs-7"
                />
              ))}
            </div>
            <div
              className="d-flex align-items-center pl-1 flex-column"
              style={{ gap: '1rem' }}
            >
              {dsoFactors.map((factor) => (
                <FormCheck
                  type="switch"
                  key={factor.heading}
                  custom={true}
                  id={`${factor.heading}-toggle-dso`}
                  name={`${factor.heading}-toggle-dso`}
                  checked={factor.isChecked}
                  onChange={() =>
                    handleFactorChange(factor, DSOFTIData.extraType)
                  }
                  label=""
                  className="fs-7"
                />
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const WidgetsBySection = ({
  icon,
  widgets,
  section,
  onAddWidget,
  isMultiple,
  selectedTenant,
  onDeleteWidget,
  setSelectedWidget,
  setShowWidgetsLibrary,
}) => {
  const OpportunityHeading = {
    [WorkingCapitalReportSections.Overview]: 'Working Capital Analysis',
  };
  const newWidgets = isMultiple || widgets[section];

  const heading =
    section === 'Disclaimer' && widgets[section]
      ? widgets[section][0]?.widgetConfig?.data?.disclaimerHeading
      : OpportunityHeading[section] || _.startCase(section);

  return (
    <>
      <div className="text-left">
        <br />
        <IconHeadingBlock
          heading={heading}
          icon={icon}
          showIcon={false}
          containerStyle="gap-1 justify-content-between px-3 mb-0"
          reportType={ReportTypes.WorkingCapital}
        />
      </div>
      <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
        {newWidgets?.map((widget, index) => (
          <WidgetWithActionButtons
            key={index}
            widget={widget}
            section={section}
            onAddWidget={onAddWidget}
            onDeleteWidget={onDeleteWidget}
            setSelectedWidget={setSelectedWidget}
            setShowWidgetsLibrary={setShowWidgetsLibrary}
            selectedTenant={selectedTenant}
            actionButtons={{
              remove: { text: 'Remove', icon: 'delete' },
              add: { text: 'Replace', icon: 'refresh' },
            }}
          />
        ))}
      </ListGroup>
    </>
  );
};

const GenerateWorkingCapitalReportModal = ({
  report,
  widgets,
  isEdited,
  setReport,
  reportPages,
  organization,
  selectedReport,
  selectedTenant,
  insightsData,
  openGenerateReport,
  handleGenerateReport,
  setOpenGenerateReport,
}) => {
  const [modalWidgets, setModalWidgets] = useState({});
  const [reportBackup, setReportBackup] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [generating, setGenerating] = useState(false);
  const [deleteBtnConfig, setDeleteBtnConfig] = useState(null);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [reportsToDelete, setReportsToDelete] = useState([]);
  const { profileInfo } = useProfileContext();
  const { setShowModal, setCompany, NaicsModal } = useSicNaicsChangeDetect(
    report,
    organization
  );
  const [errorFields, setErrorFields] = useState(ReportFormErrorFields);
  const [currentAccordionKey, setCurrentAccordionKey] = useState(
    WorkingCapitalReportSections.Overview
  );
  const [modalReportPages, setModalReportPages] = useState({});
  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const { isAllowed: isLoanOverViewAllowed } = usePermission(
    PermissionsConstants.Reports.WorkingCapitalLoanOverview,
    'view',
    true
  );
  const inputRefs = useRef([]);
  const handleKeyPress = useJumpToNextInputOnEnter(inputRefs);

  const handleRef = (index) => (ref) => {
    inputRefs.current[index] = ref;
  };
  const { isExcelBank } = useIsTenant();
  const [selectedMonth, setSelectedMonth] = useState('');
  const [monthInputs, setMonthInputs] = useState([]);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  // saving keys to match later when update report via input
  const expensesKeys = UIEData.expenses.map((ex) => ex.key);
  useLayoutEffect(() => {
    generateMonthInputs(selectedMonth);
  }, [selectedMonth]);
  const generateMonthsByMonthName = (monthName) => {
    const selectedMonthIndex = months.indexOf(monthName);
    const inputs = [];

    for (let i = 0; i < 12; i++) {
      const currentMonthIndex = (selectedMonthIndex + i) % 12;
      const currentMonth = months[currentMonthIndex];
      inputs.push({
        currentMonth,
        fullMonth: currentMonth,
        currentMonthIndex: crypto.randomUUID(),
        value: 0,
        color: '65%',
      });
    }

    return inputs;
  };
  const generateMonthInputs = (monthName) => {
    if (!monthName) {
      return null;
    }

    const inputs = generateMonthsByMonthName(monthName);
    const reportMonthData = report?.loanOverview?.monthData || [];
    const newReport = {
      ...report,
      loanOverview: {
        ...report.loanOverview,
        selectedMonth: monthName,
      },
    };

    let oldMonthDataIndex = reportMonthData.findIndex(
      (f) => f.fullMonth === monthName
    );
    const oldMonthArray = reportMonthData.slice(
      oldMonthDataIndex,
      reportMonthData.length
    );

    // when changing select month only update name not values.
    if (reportMonthData.length === inputs.length) {
      for (let i = 0; i < inputs.length; i++) {
        const newData =
          oldMonthDataIndex !== -1
            ? reportMonthData[oldMonthDataIndex]
            : oldMonthArray[i] || inputs[i];

        inputs[i].value = newData.value || 0;
        reportMonthData[i].value = newData.value || 0;
        reportMonthData[i].key = (
          newData.currentMonth || newData.fullMonth
        )?.substring(0, 3);
        reportMonthData[i].index = newData.index || newData.currentMonthIndex;
        reportMonthData[i].fullMonth =
          newData.currentMonth || newData.fullMonth;

        if (oldMonthDataIndex !== -1) {
          oldMonthDataIndex = -1;
        }
      }

      newReport.loanOverview.monthData = reportMonthData;
    } else {
      newReport.loanOverview.monthData = inputs.map((mp) => ({
        key: mp.currentMonth.substring(0, 3),
        fullMonth: mp.currentMonth,
        index: mp.currentMonthIndex,
        value: 0,
        color: '65%',
      }));
    }
    setMonthInputs(inputs);
    const updatedReport = calculateLoanOverviewValues(newReport);
    setReport(updatedReport);
    handleUpdateWidgetBySectionType(
      WorkingCapitalReportSections.LoanOverview,
      [WidgetTypes.LOC, WidgetTypes.RTM, WidgetTypes.UIE],
      updatedReport
    );
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const APOHeadings = {
    [APOData.extraType]: APOData.heading,
    [AROData.extraType]: AROData.heading,
  };

  const FTIData = {
    DPO: DPOFTIData,
    DSO: DSOFTIData,
  };

  const PowerPotentialData = {
    DPO: DPOTPData,
    DSO: DSOTPData,
  };

  const getHeading = (wg, config) => {
    return wg.type === WidgetTypes.APO
      ? APOHeadings[config.extraType]
      : config.heading;
  };

  const getDescription = (wg, config) => {
    const description = config.description;
    if (wg.type === WidgetTypes.QRCode) {
      return wg?.extraType === 'Fraud'
        ? FraudMitigationData.description
        : UWCData.description;
    }
    if (wg.type === WidgetTypes.TODD) {
      return TODDData.description;
    }
    return description;
  };

  const getValueText = (wg, config) => {
    return config.extraType === 'DPO' ? APOData.valueText : AROData.valueText;
  };

  const getFactors = (wg, config) => {
    const defaultFactors = FTIData[config.extraType]?.factors;
    return config.factors.map((f) => {
      const oldFactor = defaultFactors.find(
        (of) => of.heading.toLowerCase() === f.heading.toLowerCase()
      );
      return {
        ...f,
        heading: oldFactor.heading,
        icon: oldFactor?.icon || f.icon,
        width: oldFactor?.width,
      };
    });
  };

  const getPowerAndPotential = (wg, config) => {
    return PowerPotentialData[config.extraType]?.powerAndPotential;
  };
  const updateDefaultWidgetsWithMapping = (
    defaultOrSavedWidgets,
    reportObjectWithMapping
  ) => {
    const updatedWidgets = {};
    for (const key in defaultOrSavedWidgets) {
      updatedWidgets[key] = defaultOrSavedWidgets[key]?.map((wg) => ({
        ...wg,
        widgetConfig: {
          ...wg.widgetConfig,
          heading: getHeading(wg, wg.widgetConfig),
          description: getDescription(wg, wg.widgetConfig),
          data: wg.type === WidgetTypes.QRCode ? null : reportObjectWithMapping,
          extraPadding: 'py-0', // QRCode widget reads so...
          factors:
            wg.type === WidgetTypes.FTI
              ? getFactors(wg, wg.widgetConfig)
              : null,
          powerAndPotential:
            wg.type === WidgetTypes.OTP
              ? getPowerAndPotential(wg, wg.widgetConfig)
              : null,
          valueText:
            wg.type === WidgetTypes.APO
              ? getValueText(wg, wg.widgetConfig)
              : null,
          videoDuration:
            wg.type === WidgetTypes.QRCode
              ? wg?.extraType === 'Fraud'
                ? FraudMitigationData.videoDuration
                : UWCData.videoDuration
              : null,
          videoId:
            wg.type === WidgetTypes.QRCode
              ? wg?.extraType === 'Fraud'
                ? FraudMitigationData.videoId
                : UWCData.videoId
              : wg.widgetConfig.videoId,
        },
      }));
    }

    return {
      ...updatedWidgets,
    };
  };

  const handleChange = (e, key) => {
    const { value } = e.target;
    const newReport = {
      ...report,
      [key]: value,
    };
    const mapping = workingCapitalMapping(newReport, insightsData);
    const newWidgets = updateDefaultWidgetsWithMapping(modalWidgets, mapping);
    setReport(newReport);
    setModalWidgets(newWidgets);
  };

  const createOrUpdateReport = async () => {
    try {
      setGenerating(true);
      let reportObject = null;
      if (checkIfNaicsOrReportDateEmpty(report, errorFields, setErrorFields)) {
        return;
      }
      // update case
      if (report?.reportId) {
        reportObject = await ReportService.updateReport(report?.reportId, {
          name: report.companyName,
          date: report.reportDate,
          type: ReportTypes.WorkingCapital,
          manualInput: {
            ...report,
            widgets: cleanDataWidgets(modalWidgets),
            reportPages: modalReportPages,
          },
        });
        setSuccessMessage('Report Saved');
      } else {
        reportObject = await OrganizationService.createManualReport(
          organization.id,
          {
            name: report.companyName,
            date: report.reportDate,
            type: ReportTypes.WorkingCapital,
            manualInput: {
              ...report,
              widgets: cleanDataWidgets(modalWidgets),
              reportPages: modalReportPages,
            },
          }
        );
        setSuccessMessage('Report Created');
      }
      handleGenerateReport(
        report,
        report?.reportId ? ActionTypes.UPDATE : ActionTypes.ADD,
        reportObject,
        cleanDataWidgets(modalWidgets),
        modalReportPages
      );
    } catch (e) {
      console.log(e);
    } finally {
      setGenerating(false);
    }
  };

  const handleConfirmDeleteReport = async () => {
    try {
      // call delete api
      await ReportService.deleteReport(selectedReport.key);
      overflowing();
      setSuccessMessage('Report Deleted');
      setShowDeleteReportModal(false);
      handleGenerateReport(selectedReport, ActionTypes.REMOVE, selectedReport);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // if the owner viewing give him a delete option only
    if (
      isEdited &&
      (report?.id || report?.reportId) &&
      profileInfo.id === selectedReport.createdById
    ) {
      setDeleteBtnConfig({
        label: 'Delete Report',
        show: true,
        loading: false,
        onClick: () => {
          setReportsToDelete([selectedReport]);
          setShowDeleteReportModal(true);
        },
      });
    } else {
      setDeleteBtnConfig(null);
    }
    let clonedWidgets = _.cloneDeep(widgets);
    const nowMonth = moment().format('MMMM');

    if (
      !Object.hasOwn(
        clonedWidgets,
        WorkingCapitalReportSections.LoanOverview
      ) ||
      !report?.loanOverview
    ) {
      const monthArray = generateMonthsByMonthName(nowMonth);
      const monthData = monthArray.map((mp) => ({
        key: mp.currentMonth.substring(0, 3),
        fullMonth: mp.currentMonth,
        value: 0,
        color: '65%',
        index: mp.currentMonthIndex,
      }));
      const loanOverview = {
        lineLimit: 0,
        monthData,
        expenses: UIEData.expenses,
        selectedMonth,
      };

      const newReport = {
        ...report,
        loanOverview,
        disclaimer: report.disclaimer || DisclaimerData.disclaimer,
        disclaimerHeading:
          report.disclaimerHeading || DisclaimerData.disclaimerHeading,
      };
      setReport(newReport);
      clonedWidgets = {
        ...clonedWidgets,
        [WorkingCapitalReportSections.LoanOverview]: [
          {
            id: 1,
            widgetConfig: { ...LOCData, data: newReport },
            type: WidgetTypes.LOC,
          },
          {
            id: 2,
            widgetConfig: { ...UIEData, data: newReport },
            type: WidgetTypes.UIE,
          },
          {
            id: 3,
            widgetConfig: {
              ...RTMData,
              data: newReport,
            },
            type: WidgetTypes.RTM,
          },
        ],
      };
      setSelectedMonth(nowMonth);
      setReportBackup(_.cloneDeep(newReport));
    } else {
      setSelectedMonth(report?.loanOverview?.selectedMonth || nowMonth);
      setMonthInputs(report?.loanOverview?.monthData);
      setReportBackup(_.cloneDeep(report));
    }
    setModalWidgets(clonedWidgets);
    setModalReportPages(_.cloneDeep(reportPages));
    setErrorFields(ReportFormErrorFields);
  }, [isEdited, openGenerateReport]);

  const handleFactorChange = (factor, extraType) => {
    const dsoDpoMappingKeys = {
      DPO: WorkingCapitalReportSections.Payables,
      DSO: WorkingCapitalReportSections.Receivables,
    };

    const type = dsoDpoMappingKeys[extraType];
    // we got widgets
    const sectionWidgets = modalWidgets[type];

    // get the factors and update isChecked value
    const newFactors = sectionWidgets
      .find((wg) => wg.type === WidgetTypes.FTI)
      ?.widgetConfig?.factors?.map((fc) => ({
        ...fc,
        isChecked: fc.heading === factor.heading ? !fc.isChecked : fc.isChecked,
      }));
    // now, we need FTI widget and update its widgetConfig.factors array to reflect toggle change
    const updatedModalWidgets = sectionWidgets.map((wg) =>
      wg.type === WidgetTypes.FTI
        ? { ...wg, widgetConfig: { ...wg.widgetConfig, factors: newFactors } }
        : { ...wg }
    );
    setModalWidgets({
      ...modalWidgets,
      [type]: updatedModalWidgets,
    });
  };

  const getFTIWidget = (section) => {
    return modalWidgets[section]?.find((wg) => wg.type === WidgetTypes.FTI);
  };

  const handleAccordionClick = (eventKey) => {
    setCurrentAccordionKey(eventKey);
  };

  const onAddWidget = (e, newWidget, oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: crypto.randomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: crypto.randomUUID() }
          : { ...wg }
      );
    }
    setModalWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
    setShowWidgetsLibrary(false);
  };

  const onDeleteWidget = (oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    setModalWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
  };

  const handleReportPageToggle = (section) => {
    const updatedReportPages = {
      ...modalReportPages,
      [section]: {
        ...modalReportPages[section],
        enabled: !Object.hasOwn(modalReportPages, section)
          ? true
          : !modalReportPages[section].enabled,
      },
    };

    // we need to make sure at-least 1 page is active, so if user toggles all off and trying to toggle last off make sure to avoid it.
    const remainingEnabledSections = Object.entries(updatedReportPages).filter(
      ([key, value]) => value.enabled && key !== section
    ).length;

    if (!remainingEnabledSections) {
      setErrorMessage('At least one section must be enabled.');
      return;
    }
    setModalReportPages(updatedReportPages);
  };

  // eslint-disable-next-line no-unused-vars
  const handleUpdateWidgetBySectionType = (section, widgetType, newReport) => {
    const newWidgets = updateWidgetBySection(
      modalWidgets,
      section,
      widgetType,
      newReport
    );
    setModalWidgets(newWidgets);
  };

  const handleChangeInputDisclaimer = (e) => {
    const { value } = e.target;
    const newReport = {
      ...report,
      disclaimer: value,
    };
    setReport(newReport);
    handleUpdateWidgetBySectionType(
      WorkingCapitalReportSections.Disclaimer,
      WidgetTypes.DISC,
      newReport
    );
  };

  const handleChangeInputDisclaimerHeading = (e) => {
    const { value } = e.target;
    const newReport = {
      ...report,
      disclaimerHeading: value,
    };
    setReport(newReport);
    handleUpdateWidgetBySectionType(
      WorkingCapitalReportSections.Disclaimer,
      WidgetTypes.DISC,
      newReport
    );
  };

  const setReportValue = (key, value, section, widgetType) => {
    const newReport = {
      ...report,
      loanOverview: {
        ...report.loanOverview,
        [key]: value,
      },
    };

    if (expensesKeys.includes(key)) {
      // update expenses array when type
      newReport.loanOverview.expenses = [
        ...newReport.loanOverview.expenses,
      ].map((ex) => ({
        ...ex,
        value: ex.key === key ? value : ex.value,
      }));
    }
    const updatedReport = calculateLoanOverviewValues(newReport);
    setReport(updatedReport);
    handleUpdateWidgetBySectionType(section, widgetType, updatedReport);
  };

  const handleCurrencyUpdate = (value, key, section, widgetType) => {
    setReportValue(key, value, section, widgetType);
  };

  const calculateLoanOverviewValues = (newReport) => {
    const usage = calculateMonthDataUsage(newReport);

    for (const key in usage) {
      if (expensesKeys.includes(key) && newReport?.loanOverview?.expenses) {
        // update expenses array when type
        newReport.loanOverview.expenses = [
          ...newReport.loanOverview.expenses,
        ].map((ex) => ({
          ...ex,
          value: ex.key === key ? usage[key] : ex.value,
        }));
      }
    }

    // (Sum of 12 months avg monthly credit utilization) * 0.1 * Average Annual Rate %
    newReport.loanOverview.calculatedTotalExpense =
      usage.sum *
      0.1 *
      (parseFloat(newReport.loanOverview.avgAnnualRate || '0.0') / 100);

    return newReport;
  };

  const handleChangeMonthInput = (value, name) => {
    // get all the months values and then divide them by 12 for avg
    const newMonthData = [...report.loanOverview.monthData].map((mp) => ({
      ...mp,
      value: mp.index === name ? value : mp.value,
    }));
    const newReport = {
      ...report,
      loanOverview: {
        ...report.loanOverview,
        monthData: newMonthData,
      },
    };
    const updatedReport = calculateLoanOverviewValues(newReport);
    setReport(updatedReport);
    setMonthInputs(newMonthData);
    handleUpdateWidgetBySectionType(
      WorkingCapitalReportSections.LoanOverview,
      [WidgetTypes.LOC, WidgetTypes.RTM],
      updatedReport
    );
  };

  const getGeneralOverviewWidgets = () => {
    const overviewWidgets = modalWidgets[WorkingCapitalReportSections.Overview];
    const receivablesWidgets =
      modalWidgets[WorkingCapitalReportSections.Receivables];
    const payableWidgets = modalWidgets[WorkingCapitalReportSections.Payables];
    if (overviewWidgets && receivablesWidgets && payableWidgets)
      return [
        ...overviewWidgets,
        ...receivablesWidgets.filter((fg) => fg.type === WidgetTypes.FTI),
        ...payableWidgets.filter((fg) => fg.type === WidgetTypes.FTI),
      ];
  };

  return (
    <>
      <NaicsModal />
      <WidgetsLibraryModal />
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      <DeleteConfirmationModal
        showModal={showDeleteReportModal}
        setShowModal={setShowDeleteReportModal}
        setSelectedCategories={setReportsToDelete}
        event={handleConfirmDeleteReport}
        itemsConfirmation={reportsToDelete}
        description="Are you sure you want to delete this Report?"
        itemsReport={[]}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        positiveBtnText="Yes, Delete"
      />
      {openGenerateReport && (
        <SimpleModalCreation
          modalTitle={`${PdfFileNames[ReportTypes.WorkingCapital]} Report`}
          open={openGenerateReport}
          bankTeam={false}
          saveButton="Save Report"
          bodyClassName="p-0 overflow-x-hidden"
          isLoading={generating}
          deleteButton={deleteBtnConfig}
          handleSubmit={() => {
            overflowing();
            createOrUpdateReport();
          }}
          size={isExcelBank || isLoanOverViewAllowed ? 'xxl' : 'lg'}
          onHandleCloseModal={() => {
            overflowing();
            setOpenGenerateReport(!openGenerateReport);
            // on cancel set backup report object
            setReport(reportBackup);
            setSelectedMonth('');
          }}
        >
          <Row>
            <Col md={5}>
              <div className="pl-3 py-3">
                <FormGroup>
                  <Label for="title">Company Name</Label>
                  <input
                    name="name"
                    type="text"
                    value={report.companyName}
                    placeholder="Enter Company Name"
                    onChange={(e) => {
                      setReport({
                        ...report,
                        companyName: e.target.value,
                      });
                      setErrorFields({
                        ...errorFields,
                        name: {
                          ...errorFields.name,
                          isShow: !e.target.value,
                        },
                      });
                    }}
                    className={`form-control border-left-4 border-left-danger ${
                      errorFields.name.isShow ? 'border-danger' : ''
                    }`}
                  />
                  {errorFields.name.isShow && (
                    <ValidationErrorText text={errorFields.name.message} />
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="title">NAICS</Label>
                  <SicNaicsAutoComplete
                    data={report}
                    setData={setReport}
                    placeholder="Enter a NAICS code"
                    customKey="valueN"
                    setErrorMessage={setErrorMessage}
                    showFieldError={errorFields.naics?.isShow}
                    callKey={NAICS_STORAGE_KEY}
                    callType="getNaicsCodes"
                    onSelect={(item, naicsSicOnly, naicsTitle) => {
                      setReport({
                        ...report,
                        valueN: item ? [item] : [],
                        valueNaicsSic: naicsSicOnly,
                        industry: naicsTitle,
                      });
                      setErrorFields({
                        ...errorFields,
                        naics: {
                          ...errorFields.naics,
                          isShow: !naicsSicOnly,
                        },
                      });
                      if (naicsSicOnly) {
                        if (naicsSicOnly !== organization.naics_code) {
                          setShowModal(true);
                          setCompany(organization);
                        }
                      }
                    }}
                  />
                  {errorFields.naics?.isShow && (
                    <ValidationErrorText text={errorFields.naics.message} />
                  )}
                </FormGroup>
                <FormGroup className="date-wrapper">
                  <Label for="title">Report Date</Label>
                  <ReactDatepicker
                    id={'rptDate'}
                    name={'reportDate'}
                    todayButton="Today"
                    value={report.reportDate}
                    readonly
                    autoComplete="off"
                    className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                      errorFields.reportDate?.isShow ? 'border-danger' : ''
                    }`}
                    placeholder="Select Report Date"
                    format="MMMM yyyy"
                    onChange={(date) => {
                      setReport({
                        ...report,
                        reportDate: date,
                      });
                      setErrorFields({
                        ...errorFields,
                        reportDate: {
                          ...errorFields.reportDate,
                          isShow: !date,
                        },
                      });
                    }}
                    showMonthYearPicker
                    showFullMonthYearPicker
                  />
                  {errorFields.reportDate?.isShow && (
                    <ValidationErrorText
                      text={errorFields.reportDate.message}
                    />
                  )}
                </FormGroup>
                {(isExcelBank || isLoanOverViewAllowed) && (
                  <FormGroup>
                    <Label for="title">Adjust Values by Page</Label>
                    <Accordion activeKey={currentAccordionKey}>
                      <Card>
                        <ReportAccordionToggle
                          eventKey={WorkingCapitalReportSections.LoanOverview}
                          callback={handleAccordionClick}
                          setCurrentAccordionKey={setCurrentAccordionKey}
                        >
                          {WorkingCapitalReportSections.LoanOverview}
                          <ReportSectionIncludedLabel
                            section={WorkingCapitalReportSections.LoanOverview}
                            reportPages={modalReportPages}
                            handleReportPageToggle={handleReportPageToggle}
                          />
                        </ReportAccordionToggle>
                        <Accordion.Collapse
                          eventKey={WorkingCapitalReportSections.LoanOverview}
                        >
                          <CardBody>
                            <FormGroup>
                              <Label>Line Limit</Label>
                              <InputGroup className="align-items-center">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <CurrencyInput
                                  name="lineLimit"
                                  placeholder="0"
                                  ref={handleRef(0)}
                                  value={
                                    formatCurrencyField(
                                      report?.loanOverview?.lineLimit
                                    ) || ''
                                  }
                                  onKeyDown={(e) => handleKeyPress(e, 0)}
                                  className="form-control"
                                  onValueChange={(value, name, values) => {
                                    handleCurrencyUpdate(
                                      values.float,
                                      name,
                                      WorkingCapitalReportSections.LoanOverview,
                                      WidgetTypes.LOC
                                    );
                                  }}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label for="title">Average Annual Rate</Label>
                              <InputGroup className="align-items-center">
                                <CurrencyInput
                                  name="avgAnnualRate"
                                  placeholder="0.00"
                                  decimalsLimit={2}
                                  decimalScale={2}
                                  ref={handleRef(1)}
                                  value={report?.loanOverview?.avgAnnualRate}
                                  onKeyDown={(e) => handleKeyPress(e, 1)}
                                  className="form-control"
                                  onValueChange={(value, name, values) => {
                                    setReportValue(
                                      name,
                                      values?.formatted || '',
                                      WorkingCapitalReportSections.LoanOverview,
                                      [WidgetTypes.UIE, WidgetTypes.RTM]
                                    );
                                  }}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>%</InputGroup.Text>
                                </InputGroup.Append>
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label>Total Interest Expense</Label>
                              <InputGroup className="align-items-center">
                                <InputGroup.Prepend>
                                  <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <CurrencyInput
                                  name="totalInterestExpense"
                                  placeholder="0"
                                  ref={handleRef(2)}
                                  value={
                                    formatCurrencyField(
                                      report?.loanOverview?.totalInterestExpense
                                    ) || ''
                                  }
                                  onKeyDown={(e) => handleKeyPress(e, 2)}
                                  className="form-control"
                                  onValueChange={(value, name, values) => {
                                    handleCurrencyUpdate(
                                      values.float,
                                      name,
                                      WorkingCapitalReportSections.LoanOverview,
                                      WidgetTypes.UIE
                                    );
                                  }}
                                />
                              </InputGroup>
                            </FormGroup>
                            <FormGroup>
                              <Label>Select Start Month</Label>
                              <InputGroup className="align-items-center">
                                <select
                                  value={selectedMonth}
                                  onChange={handleMonthChange}
                                  ref={handleRef(3)}
                                  onKeyDown={(e) => handleKeyPress(e, 3)}
                                  className="form-control custom-select"
                                >
                                  <option value="">Select Start Month</option>
                                  {months.map((month) => (
                                    <option key={month} value={month}>
                                      {month}
                                    </option>
                                  ))}
                                </select>
                              </InputGroup>
                            </FormGroup>
                            {monthInputs.length ? (
                              <>
                                <FormGroup className="d-flex mb-0 py-1 justify-content-between align-items-center">
                                  <Label
                                    style={{ minWidth: 100 }}
                                    className="mb-0"
                                  >
                                    &nbsp;
                                  </Label>
                                  <Label className="flex-fill mb-0">
                                    Average Monthly Credit Utilization
                                  </Label>
                                </FormGroup>
                                {monthInputs.map((mi, index) => (
                                  <FormGroup
                                    key={index}
                                    className="d-flex mb-0 py-1 justify-content-between align-items-center"
                                  >
                                    <Label
                                      style={{ minWidth: 100 }}
                                      className="mb-0 font-weight-normal"
                                    >
                                      {mi.fullMonth}
                                    </Label>
                                    <InputGroup className="align-items-center flex-fill">
                                      <InputGroup.Prepend>
                                        <InputGroup.Text>$</InputGroup.Text>
                                      </InputGroup.Prepend>
                                      <CurrencyInput
                                        name={mi.currentMonthIndex || mi.index}
                                        placeholder="0"
                                        value={
                                          formatCurrencyField(mi.value) || ''
                                        }
                                        ref={handleRef(index + 4)}
                                        onKeyDown={(e) =>
                                          handleKeyPress(e, index + 4)
                                        }
                                        className="form-control"
                                        onValueChange={(
                                          value,
                                          name,
                                          values
                                        ) => {
                                          handleChangeMonthInput(
                                            values.float,
                                            name,
                                            WorkingCapitalReportSections.LoanOverview,
                                            WidgetTypes.LOC
                                          );
                                        }}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                ))}
                              </>
                            ) : null}
                          </CardBody>
                        </Accordion.Collapse>
                      </Card>

                      <Card>
                        <ReportAccordionToggle
                          eventKey={WorkingCapitalReportSections.Overview}
                          callback={handleAccordionClick}
                          setCurrentAccordionKey={setCurrentAccordionKey}
                        >
                          Working Capital Analysis
                          <ReportSectionIncludedLabel
                            section={WorkingCapitalReportSections.Overview}
                            reportPages={modalReportPages}
                            handleReportPageToggle={handleReportPageToggle}
                          />
                        </ReportAccordionToggle>
                        {isExcelBank || isLoanOverViewAllowed ? (
                          <Accordion.Collapse
                            eventKey={WorkingCapitalReportSections.Overview}
                          >
                            <CardBody>
                              {WorkingCapitalFields.map((field, index) => (
                                <WCInputField
                                  key={field}
                                  report={report}
                                  label={field}
                                  index={index + 17}
                                  stacked={true}
                                  handleKeyPress={handleKeyPress}
                                  handleRef={handleRef}
                                  labelKey={_.camelCase(field)}
                                  handleChange={handleChange}
                                />
                              ))}

                              <div className="py-3">
                                <FactorsMergedDPODSO
                                  isExcelBank={isExcelBank}
                                  stacked={true}
                                  widget={{
                                    DPO: getFTIWidget(
                                      WorkingCapitalReportSections.Payables
                                    ),
                                    DSO: getFTIWidget(
                                      WorkingCapitalReportSections.Receivables
                                    ),
                                  }}
                                  handleFactorChange={handleFactorChange}
                                />
                              </div>
                            </CardBody>
                          </Accordion.Collapse>
                        ) : null}
                      </Card>

                      <Card>
                        <ReportAccordionToggle
                          eventKey={WorkingCapitalReportSections.Glossary}
                          callback={handleAccordionClick}
                          dontExpand={true}
                          setCurrentAccordionKey={setCurrentAccordionKey}
                        >
                          {WorkingCapitalReportSections.Glossary}
                          <ReportSectionIncludedLabel
                            section={WorkingCapitalReportSections.Glossary}
                            reportPages={modalReportPages}
                            handleReportPageToggle={handleReportPageToggle}
                          />
                        </ReportAccordionToggle>
                      </Card>

                      <Card>
                        <ReportAccordionToggle
                          eventKey={WorkingCapitalReportSections.Disclaimer}
                          callback={handleAccordionClick}
                          setCurrentAccordionKey={setCurrentAccordionKey}
                        >
                          {report.disclaimerHeading}
                          <ReportSectionIncludedLabel
                            section={WorkingCapitalReportSections.Disclaimer}
                            reportPages={modalReportPages}
                            handleReportPageToggle={handleReportPageToggle}
                          />
                        </ReportAccordionToggle>
                        <Accordion.Collapse
                          eventKey={WorkingCapitalReportSections.Disclaimer}
                        >
                          <CardBody>
                            <FormGroup>
                              <Label for="title">
                                Enter disclaimer heading
                              </Label>
                              <input
                                name="heading"
                                value={report.disclaimerHeading}
                                onChange={handleChangeInputDisclaimerHeading}
                                className="form-control"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="title">Enter disclaimer text</Label>
                              <textarea
                                name="disclaimer"
                                rows={6}
                                value={report.disclaimer}
                                onChange={handleChangeInputDisclaimer}
                                className="form-control"
                              />
                            </FormGroup>
                          </CardBody>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </FormGroup>
                )}
              </div>
            </Col>
            <Col
              md={7}
              className={`bg-white treasury-modal position-relative overflow-x-hidden pb-3 pl-0 ${
                currentAccordionKey === null
                  ? 'd-flex align-items-center justify-content-center'
                  : ''
              }`}
            >
              {currentAccordionKey === null && (
                <NoDataFound
                  title="No page selected"
                  description="Please expand a page menu from left to edit values"
                  icon="analytics"
                  containerStyle="text-gray-900"
                />
              )}
              {currentAccordionKey === WorkingCapitalReportSections.Overview &&
                !isExcelBank &&
                !isLoanOverViewAllowed && (
                  <div className="py-2 px-3">
                    <div>
                      <h3 className="py-2 mt-2 mb-0">
                        Working Capital Analysis
                      </h3>
                      <Card
                        className={`mr-3 mb-3 ${
                          isExcelBank ? 'report-widget' : ''
                        } shadow-none`}
                      >
                        <CardBody>
                          <div className="d-flex align-items-center py-1 justify-content-between">
                            <h6 style={{ minWidth: 160 }}>&nbsp;</h6>
                            <div
                              style={{
                                fontSize: '0.765625rem',
                                fontWeight: 600,
                              }}
                              className="d-flex flex-fill text-center justify-content-center align-items-center gap-1"
                            >
                              Full Year Financial Data (Actual Dollars)
                            </div>
                          </div>
                          {WorkingCapitalFields.map((field, index) => (
                            <WCInputField
                              key={field}
                              report={report}
                              label={field}
                              index={index}
                              handleKeyPress={handleKeyPress}
                              handleRef={handleRef}
                              labelKey={_.camelCase(field)}
                              handleChange={handleChange}
                            />
                          ))}
                        </CardBody>
                      </Card>
                    </div>
                    <div className="pb-3">
                      <FactorsMergedDPODSO
                        isExcelBank={isExcelBank}
                        widget={{
                          DPO: getFTIWidget(
                            WorkingCapitalReportSections.Payables
                          ),
                          DSO: getFTIWidget(
                            WorkingCapitalReportSections.Receivables
                          ),
                        }}
                        handleFactorChange={handleFactorChange}
                      />
                    </div>
                  </div>
                )}
              {currentAccordionKey ===
                WorkingCapitalReportSections.LoanOverview && (
                <WidgetsBySection
                  icon="price_check"
                  widgets={modalWidgets}
                  section={WorkingCapitalReportSections.LoanOverview}
                  onAddWidget={onAddWidget}
                  reportPages={modalReportPages}
                  onDeleteWidget={onDeleteWidget}
                  selectedTenant={selectedTenant}
                  setSelectedWidget={setSelectedWidget}
                  setShowWidgetsLibrary={setShowWidgetsLibrary}
                  handleReportPageToggle={handleReportPageToggle}
                />
              )}
              {currentAccordionKey === WorkingCapitalReportSections.Overview &&
                (isExcelBank || isLoanOverViewAllowed) && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={modalWidgets}
                    section={WorkingCapitalReportSections.Overview}
                    onAddWidget={onAddWidget}
                    isMultiple={getGeneralOverviewWidgets()}
                    reportPages={modalReportPages}
                    onDeleteWidget={onDeleteWidget}
                    selectedTenant={selectedTenant}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
              {currentAccordionKey === WorkingCapitalReportSections.Glossary &&
                (isExcelBank || isLoanOverViewAllowed) && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={modalWidgets}
                    section={WorkingCapitalReportSections.Glossary}
                    onAddWidget={onAddWidget}
                    reportPages={modalReportPages}
                    onDeleteWidget={onDeleteWidget}
                    selectedTenant={selectedTenant}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
              {currentAccordionKey ===
                WorkingCapitalReportSections.Disclaimer && (
                <WidgetsBySection
                  icon="menu_book"
                  widgets={modalWidgets}
                  section={WorkingCapitalReportSections.Disclaimer}
                  onAddWidget={onAddWidget}
                  reportPages={modalReportPages}
                  onDeleteWidget={onDeleteWidget}
                  selectedTenant={selectedTenant}
                  setSelectedWidget={setSelectedWidget}
                  setShowWidgetsLibrary={setShowWidgetsLibrary}
                  handleReportPageToggle={handleReportPageToggle}
                />
              )}
            </Col>
          </Row>
        </SimpleModalCreation>
      )}
    </>
  );
};

export default GenerateWorkingCapitalReportModal;
