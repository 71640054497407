import React, { useEffect, useReducer, useState } from 'react';
import FilterItemMenu from './common/FilterItemMenu';
import './style.css';
import { filters, initialState, isEmptyFilter, reducer } from './constants';
import { useFilterProspectContext } from '../../../contexts/filterProspectContext';
import { getKeysWithData } from '../../../utils/Utils';
import { useLocation } from 'react-router';
import MaterialIcon from '../../commons/MaterialIcon';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MenuPeople = ({
  chargeFilter,
  saveFilter,
  loadFilter,
  selctedFilter,
}) => {
  const query = useQuery();
  const flag = query.get('back');
  const { globalFilters, setGlobalFilters } = useFilterProspectContext();
  const [data, dispatch] = useReducer(reducer, initialState);
  const [showClear, setShowClear] = useState(false);
  const [firstRender, setFirstRender] = useState(false);
  const [active, setActive] = useState([1]); // people filters will be open byDefault

  const onSubmit = async () => {
    const filters = getKeysWithData(data);
    if (Object.keys(filters).length) {
      setShowClear(true);
      setFirstRender(false);
      chargeFilter(filters);
      setGlobalFilters(filters);
    } else {
      chargeFilter({}, 'LOAD');
      setShowClear(false);
      setFirstRender(true);
      dispatch({ type: 'set', payload: initialState });
      setGlobalFilters({});
    }
  };

  const clearFilter = () => {
    setShowClear(false);
    dispatch({ type: 'set', payload: initialState });
    chargeFilter({}, 'CLEAR');
    setGlobalFilters(initialState);
  };

  const onEnter = () => {
    if (firstRender || showClear) {
      onSubmit();
    } else {
      setFirstRender(true);
    }

    if (firstRender) setFirstRender(false);
  };

  useEffect(() => {
    if (flag === 'true') {
      dispatch({ type: 'set', payload: globalFilters });
    } else {
      setGlobalFilters(initialState);
    }
  }, []);

  useEffect(() => {
    setGlobalFilters(data);
    setFirstRender(true);
  }, [data]);

  useEffect(() => {
    // if we get load filter from parent component then dispatch updated filters list to show in filter menu
    dispatch({ type: 'set', payload: globalFilters });
    const filters = getKeysWithData(globalFilters);
    setShowClear(!isEmptyFilter(filters));
    setFirstRender(true);
  }, [loadFilter]);

  return (
    <div>
      <div className="d-flex py-2 justify-content-between">
        <h5 className="font-weight-medium mb-0">Search Filters</h5>
        {showClear && (
          <div
            className="d-flex align-items-center justify-content-end cursor-pointer fs-7"
            onClick={clearFilter}
          >
            <MaterialIcon icon="close" size="fs-5" clazz="d-flex" />
            <span className="date-clear-btn">Clear all</span>
          </div>
        )}
      </div>
      <div className="flex-column">
        {filters.map((name) => (
          <FilterItemMenu
            key={name}
            title={name}
            data={data}
            setData={dispatch}
            onEnter={onEnter}
            active={active}
            setActive={setActive}
            selctedFilter={selctedFilter}
          />
        ))}
      </div>
    </div>
  );
};

export default MenuPeople;
