import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import { ProgressBar } from 'react-bootstrap';

const IconProgressBar = ({ item, whenPrinting }) => {
  return (
    <>
      <div className="d-flex align-items-start gap-3">
        <MaterialIcon
          icon={item.icon}
          rounded
          clazz={`${whenPrinting ? 'font-size-2p5em' : 'font-size-3em'} ${
            item.style
          }`}
        />
        <div className="flex-fill">
          <ProgressBar
            now={item.value}
            variant={item.variant}
            style={{ height: whenPrinting ? 35 : 40 }}
          />
          <p
            className={`fs-8 sub-text text-left mt-1 mb-0`}
            dangerouslySetInnerHTML={{ __html: item.text }}
          />
        </div>
      </div>
    </>
  );
};

const PaymentFraudImpactsWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center d-flex">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-2">
            <div
              className={`d-flex flex-column px-6 ${
                whenPrinting ? 'py-4' : 'py-6'
              }`}
              style={{ gap: whenPrinting ? 20 : 30 }}
            >
              {widgetConfig.items.map((item) => (
                <IconProgressBar
                  item={item}
                  key={item.id}
                  whenPrinting={whenPrinting}
                />
              ))}
            </div>
          </div>
          <div className="mt-2 text-left">
            {widgetConfig?.source && (
              <WidgetSourceBlock text={widgetConfig.source} />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PaymentFraudImpactsWidget;
