import MaterialIcon from '../../../commons/MaterialIcon';
import TenantThemeWrapperWidget from './TenantThemeWrapperWidget';
import { formatNumber } from '../../../../utils/Utils';

const OverviewWidget = ({ widgetConfig, whenPrinting }) => {
  const replacePlaceholders = (itemText) => {
    const isAggregateView = widgetConfig?.aggregate === true;
    const aggregateOrIndividualObject = isAggregateView
      ? widgetConfig?.data?.aggregate
      : widgetConfig?.data;

    const customerName = isAggregateView
      ? `On average, ${aggregateOrIndividualObject?.Customer_Name || 'You'}`
      : 'You';

    const placeholders = {
      $$CustomerName$$: customerName,
      $$TotalGrossVolume$$:
        formatNumber(aggregateOrIndividualObject?.TotalGrossVolume) || '0',
      $$TotalTransactions$$: Math.round(
        aggregateOrIndividualObject?.TotalTransactions || '0'
      ),
      $$EffectiveRate$$:
        `${aggregateOrIndividualObject?.EffectiveRate?.toFixed(2)}%` || '0%',
    };

    Object.keys(placeholders).forEach((key) => {
      if (itemText.includes(key)) {
        itemText = itemText.replace(key, placeholders[key]);
      }
    });

    return itemText;
  };
  return (
    <TenantThemeWrapperWidget bodyStyles="d-flex flex-column position-relative border-0 justify-content-start">
      <span className="fs-6 font-weight-bold text-align-left mb-2">
        {widgetConfig.header}
      </span>
      <div className="my-2">
        {widgetConfig?.items.map((item, index) => {
          return (
            <div key={index} className="">
              <div className="d-flex align-items-center text-left">
                <MaterialIcon
                  icon={'check_circle'}
                  filled
                  size="fs-3"
                  clazz={'text-primary'}
                />
                <span className="font-weight-medium fs-8 mx-2">
                  {replacePlaceholders(item)}
                </span>
              </div>
              {index < widgetConfig?.items?.length - 1 && (
                <div
                  className="bg-primary-lighter my-3"
                  style={{
                    height: 'var(--rpt-widget-border-thickness)',
                    borderRadius: 'var(--rpt-widget-border-radius)',
                  }}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    </TenantThemeWrapperWidget>
  );
};

export default OverviewWidget;
