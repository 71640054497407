import React, { useState } from 'react';
import OrganizationService from '../../services/organization.service';
import ReportDropdownItem from '../../components/reports/ReportDropdownItem';
import { getReportName } from '../../components/reports/reports.helper.functions';
import moment from 'moment/moment';
import { ReportTypes } from '../../components/reports/reports.constants';

const useFetchEngagementReports = (
  organization,
  type,
  limit = 100,
  page = 1
) => {
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);

  const fetchReports = async () => {
    try {
      setLoading(true);
      const { data } = await OrganizationService.getReports(organization.id, {
        limit,
        page,
        type,
      });

      const updatedReports = data.data
        .map((rpt) => {
          const rptObject = rpt.manualInput;
          const datePart =
            type === ReportTypes.AccountStructure
              ? rpt.manualInput.ReportInfo
              : rpt.manualInput;
          return {
            ...rptObject,
            key: rpt.reportId,
            reportId: rpt.reportId,
            customElement: (
              <ReportDropdownItem
                item={{
                  ...datePart,
                  key: rpt.reportId,
                  reportId: rpt.reportId,
                }}
              />
            ),
            name: getReportName(rptObject),
            isManual: true,
            createdById: rpt.createdById,
            createdAt: rpt.createdAt,
            updatedAt: rpt.updatedAt,
            prettyDate: moment(datePart.reportDate).format('MMMM YYYY'),
          };
        })
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setReports(updatedReports);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, reports, setReports, fetchReports };
};

export default useFetchEngagementReports;
