import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';

const FactorsThatImpactDSODPOWidget = ({ widgetConfig, whenPrinting }) => {
  const factors = widgetConfig.factors;
  return (
    <>
      {factors.length > 0 ? (
        <Card className="report-widget w-100">
          <CardBody className="align-items-center d-flex w-100">
            <div className="flex-fill">
              <h5 className="text-left d-flex mb-1">{widgetConfig.heading}</h5>
              <div className="widget-extra-padding px-2">
                <p
                  className={`mb-0 ${
                    whenPrinting ? 'fs-9' : 'font-size-sm2'
                  } text-left`}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: widgetConfig.description,
                    }}
                  ></span>
                </p>
                <div
                  style={{ gap: 10 }}
                  className={`d-flex mt-2 align-items-center justify-content-center`}
                >
                  {factors.map((pp) => (
                    <div
                      key={pp.heading}
                      style={{
                        width: 150,
                      }}
                      className={`flex-fill ${
                        pp.isChecked ? 'text-white' : 'text-primary'
                      } gap-1 h-100 position-relative align-items-center fti-item`}
                    >
                      <div
                        className={`d-flex align-items-center py-2 ${
                          whenPrinting ? 'px-2' : 'px-3'
                        } gap-2 justify-content-center`}
                      >
                        <span
                          className={`rounded-circle p-2 d-flex align-items-center justify-content-center ${
                            pp.isChecked
                              ? 'rpt-green-box'
                              : 'opacity-lg bg-gray-200'
                          } border-0`}
                        >
                          <MaterialIcon
                            rounded
                            icon={pp.icon}
                            clazz={`font-size-2xl ${
                              pp.isChecked
                                ? 'rpt-green-box-heading'
                                : 'text-gray-700'
                            }`}
                          />
                        </span>
                        <p
                          style={{ maxWidth: pp.width }}
                          className={`mb-0 ${
                            whenPrinting ? 'sub-text' : 'fs-9'
                          } ${
                            pp.isChecked
                              ? 'font-weight-medium'
                              : 'text-gray-500'
                          }`}
                        >
                          {pp.heading}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                {widgetConfig.source && (
                  <WidgetSourceBlock text={widgetConfig.source} />
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      ) : null}
    </>
  );
};

export default FactorsThatImpactDSODPOWidget;
