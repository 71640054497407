import React, { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import { Steps, Step } from 'react-step-builder';
import { useParams, useHistory } from 'react-router-dom';
import Heading from '../../components/heading';
import Nav from '../../components/lesson/nav';
import Page from '../../components/lesson/page';
import Hero from '../../components/lesson/hero';
import { API } from '../../services/api';
import lessonService from '../../services/lesson.service';
import { COMPLETED, CONGRATULATIONS, QUIZ } from '../../utils/constants';
import PageTitle from '../../components/commons/PageTitle';
import LearnFilter from '../../components/commons/LearnFilter';
import { LessonLayout } from '../../components/lessonLayout';
import courseService from '../../services/course.service';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import LessonSkeleton from '../../components/commons/LessonSkeleton';
import Card from '../../components/lesson/card';
import categoryService from '../../services/category.service';

export default function CourseDetail(props) {
  const api = new API();
  const { id } = useParams();
  const searchParams = useUrlSearchParams();
  const viewType = searchParams.get('viewType');
  const category_id = searchParams.get('categoryId');

  const path = searchParams.get('path');
  const history = useHistory();
  const [currentLesson, setCurrentLesson] = useState({
    state: 'in_progress',
  });
  const [isLoading, setIsLoading] = useState(false);

  const [refresh, setRefresh] = useState(0);
  const [relatedLessons, setRltLessons] = useState([]);
  const [nextLessons, setNextLessons] = useState([]);
  const [getLessonId, setGetLessonId] = useState('');
  const [getCategory, setGetCategory] = useState({});

  const [isTitle] = useState('');
  const getData = async (id) => {
    try {
      const [
        fullCourse,
        course,
        progressCourse = { status: 'pending', progress: 0 },
      ] = await Promise.all([
        courseService.getCourseById(id, { favorites: 'include' }),
        courseService.getCourseLessonsById(id),
        courseService.getCourseProgress(id, { self: true }).catch(() => {}),
      ]);

      if (progressCourse && course) {
        const { is_learning_path, lessons, quiz_id, ...restProps } = course;

        const lessonsProgress = await Promise.all(
          lessons.map(async (lesson) => {
            try {
              const resp = await lessonService.GetLessonTrackByLessonId(
                lesson.id,
                { self: true }
              );
              lesson.progress = resp?.progress || 0; // Assuming progress is retrieved from the response
            } catch (error) {
              // Handle the error here, you can log it or take other actions
              console.error(
                `Error fetching progress for lesson with ID ${lesson.id}:`,
                error
              );
              lesson.progress = 0; // Set a default value of 0 in case of an error
            }
            return lesson;
          })
        );
        const lessonsOrder = lessonsProgress.sort((next, prox) => {
          if (
            next.CourseLessonModel.position > prox.CourseLessonModel.position
          ) {
            return 1;
          }
          return -1;
        });
        const filteredLessons = lessonsOrder.filter(
          (lesson) => lesson.progress !== 100
        );
        if (!getLessonId && filteredLessons?.length === 0) {
          const { status, progress } = progressCourse;

          setCurrentLesson({
            state: status,
            progress,
            is_learning_path,
            quiz_id,
            category: !is_learning_path ? lessons[0]?.category : null,
            ...restProps,
            contents: fullCourse?.contents,
          });
        } else {
          setNextLessons(filteredLessons);
          setGetLessonId(getLessonId || filteredLessons[0]?.id);
        }

        if (lessonsOrder.length > 0) {
          setRltLessons(lessonsOrder);
        }
      } else {
        history.push('/');
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (id) {
      // Using regular expression to match the UUID
      const match = id.match(
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/
      );

      // Extracting the matched UUID
      const courseId = match ? match[0] : null;
      getData(courseId);
    }
  }, [id]);

  const getLesson = async (id) => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    const apiLesson = await api
      .GetLessonById(id)
      .catch((err) => console.log(err));

    if (!apiLesson) {
      return;
    }

    try {
      const resp = await lessonService.GetLessonTrackByLessonId(id, {
        self: true,
      });

      const { completed_at, status } = resp || {};

      let newPages = apiLesson.pages.slice();

      if (completed_at && status === COMPLETED) {
        newPages = apiLesson.pages.filter((page) => !page.type.includes(QUIZ));
      }

      setCurrentLesson({
        ...apiLesson,
        pages: newPages,
        lessonTrack: resp || {},
      });
    } catch (e) {
      // first time lesson progress comes null, so default/first page load
      const newPages = apiLesson.pages.slice();
      setCurrentLesson({
        ...apiLesson,
        pages: newPages,
        lessonTrack: {},
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (getLessonId) {
      getLesson(getLessonId);
    }
  }, [getLessonId]);
  useEffect(() => {
    if (refresh > 0) {
      getData(id);
    }
  }, [refresh]);
  const config = {
    navigation: {
      component: Nav, // a React component with special props provided automatically
      location: 'after', // or after
    },
  };
  const FirstStep = (props) => {
    return (
      <Hero
        points={currentLesson.max_points}
        {...props}
        setRefresh={setRefresh}
        setNextLessons={setNextLessons}
        handleRetakeCourse={handleRetakeCourse}
        course={id}
        getLessonId={getLessonId}
        setGetLessonId={setGetLessonId}
        nextLessons={nextLessons}
      />
    );
  };
  const GetCategoryById = async () => {
    const data = await categoryService.GetCategoryById(category_id);
    setGetCategory(data);
  };

  useEffect(() => {
    GetCategoryById();
  }, [currentLesson]);
  const loader = () => {
    if (isLoading) return <LessonSkeleton />;
  };
  const handleRetakeCourse = async () => {
    const pl = {
      pageId: null,
    };
    await Promise.all(
      relatedLessons?.map(async (item) => {
        try {
          await api.TrackLesson(item?.id, pl);
          setGetLessonId('');
        } catch (err) {
          console.log(err);
        }
      })
    );
    setRefresh((prevState) => prevState + 1);
  };
  return (
    <div>
      {isLoading ? (
        loader()
      ) : (
        <>
          <PageTitle page={currentLesson?.title} pageModule="Learn" />
          <LearnFilter viewType={viewType} path={getCategory?.title} />
          <LessonLayout
            lesson={currentLesson}
            relatedLessons={relatedLessons}
            path={path}
            setGetLessonId={setGetLessonId}
            type={viewType}
            course={id}
          >
            {getLessonId || nextLessons?.length > 0 ? (
              <>
                <div
                  className={
                    isTitle || getCategory?.title ? 'my-3' : 'mt-5 pt-4'
                  }
                >
                  <Heading
                    title={isTitle || getCategory?.title}
                    pageHeaderDivider="pb-0 mb-0 text-capitalize"
                  />
                </div>
                <div className="card rounded-lg card-lesson-hero">
                  <CardBody>
                    <Steps config={config}>
                      <Step
                        title={currentLesson?.title}
                        lesson={currentLesson}
                        component={(props) => <FirstStep {...props} />}
                      />
                      {currentLesson.pages?.map((p, indx) => (
                        <Step
                          key={indx}
                          lessonId={currentLesson?.id}
                          firstPage={currentLesson?.pages[0]}
                          title={p?.title}
                          component={(props) => (
                            <Page {...props} lesson={currentLesson} page={p} />
                          )}
                        />
                      ))}
                      <Step
                        title={CONGRATULATIONS}
                        lesson={currentLesson}
                        component={(props) => (
                          <Hero
                            {...props}
                            course={id}
                            category_id={currentLesson?.category_id}
                            nextLessons={nextLessons}
                            setGetLessonId={setGetLessonId}
                            setNextLessons={setNextLessons}
                            handleRetakeCourse={handleRetakeCourse}
                            getLessonId={getLessonId}
                            setRefresh={setRefresh}
                            type={viewType}
                          />
                        )}
                      />
                    </Steps>
                  </CardBody>
                </div>
              </>
            ) : (
              <>
                <div className={isTitle ? 'my-3' : 'mt-5 pt-4'}>
                  <Heading
                    title={isTitle}
                    pageHeaderDivider="pb-0 mb-0 text-capitalize"
                  />
                </div>
                <Card
                  item={currentLesson}
                  category={isTitle}
                  layoutCourse={true}
                  progressDone={relatedLessons?.every(
                    (item) => item?.progress === 100
                  )}
                  handleRetakeCourse={handleRetakeCourse}
                  classnames={
                    'd-flex flex-row-reverse flex-wrap justify-content-between align-items-center'
                  }
                />
              </>
            )}
          </LessonLayout>
        </>
      )}
    </div>
  );
}
