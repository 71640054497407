const WhiteLabelName = ({ wrap, children }) => {
  return (
    <>
      {wrap ? (
        <div className="d-flex flex-wrap gap-1">
          <div className="py-1 px-2 font-weight-medium border bg-white shadow text-center rounded-pill">
            {children}
          </div>
        </div>
      ) : (
        <div className="py-1 px-2 font-weight-medium border bg-white shadow text-center rounded-pill">
          {children}
        </div>
      )}
    </>
  );
};

export default WhiteLabelName;
