import React from 'react';
import { Card } from 'react-bootstrap';
import MaterialIcon from '../../components/commons/MaterialIcon';
export const ChangelogList = () => {
  return (
    <>
      <div>
        <input className="form-control" placeholder="Search here..." />
      </div>
      <Card className="mt-3">
        <Card.Body>
          <div>
            <h4 className="fw-bold font-size-xxl mb-0">New Feature</h4>
            <span className="text-muted font-size-sm">Jan 29, 2024</span>
            <p className="mb-0 font-size-md text-wrap">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel,
              beatae voluptates placeat tenetur ut suscipit amet earum nam
              voluptatem excepturi minus qui necessitatibus velit quaerat itaque
              ullam quas dolores dolore.
            </p>
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="badge badge-lg text-white label text-black rounded-pill bg-primary text-capitalize">
                New
              </div>
              <div className="badge badge-lg text-white label text-black rounded-pill bg-primary text-capitalize">
                Published
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end mt-3 gap-2">
              <div>
                <MaterialIcon
                  icon={'edit'}
                  clazz="font-size-xl bg-primary-soft p-2 cursor-pointer rounded-circle text-primary"
                />
                <MaterialIcon
                  icon={'delete'}
                  clazz="font-size-xl bg-primary-soft p-2 ml-2 cursor-pointer rounded-circle text-danger"
                />
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
