import { Card, CardBody } from 'reactstrap';

// this specific widget is for wrapping report widget in a soft light color plus slight dark border based on tenant theme
const TenantThemeWrapperWidget = ({
  cardStyles,
  bodyStyles = 'bg-primary-soft border-0',
  children,
}) => {
  return (
    <Card
      style={cardStyles}
      className="report-widget no-border bg-primary-soft bg-primary-soft-forced"
    >
      <CardBody className={bodyStyles}>{children}</CardBody>
    </Card>
  );
};

export default TenantThemeWrapperWidget;
