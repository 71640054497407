import React, { useState } from 'react';

import { usePagesContext } from '../../../../contexts/pagesContext';
import NoDataFoundTitle from '../../../fields/NoDataFoundTitle';
import NoDataFound from '../../../commons/NoDataFound';
import FlowChart from '../FlowChart';
import FlowChartLegends from '../FlowChartLegends';

const FundsFlow = () => {
  const { pageContext } = usePagesContext();
  const [accounts] = useState(
    pageContext?.AccountStructureReportModal?.ReportDetails?.accounts || []
  );
  return (
    <div className="position-relative">
      {accounts?.length === 0 ? (
        <NoDataFound
          icon="account_tree"
          iconRounded={true}
          iconStyle="font-size-2em"
          containerStyle="text-gray-search mt-2 pt-2 mb-1"
          title={
            <NoDataFoundTitle
              clazz="fs-7 mb-0"
              str={`No funds flow available`}
            />
          }
          description="Please go to Report Details tab and add accounts."
        />
      ) : (
        <div className="position-relative">
          <div className="py-5" style={{ height: 500 }}>
            <FlowChart accounts={accounts} />
          </div>
          <FlowChartLegends />
        </div>
      )}
    </div>
  );
};
export default FundsFlow;
