import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import MaterialIcon from '../../commons/MaterialIcon';

const ExpandCollapseCard = ({
  label,
  heading,
  expand,
  setExpand,
  children,
}) => {
  return (
    <Card className="w-100">
      <CardHeader
        className={`bg-hover-gray p-3 cursor-pointer`}
        onClick={() =>
          setExpand({
            ...expand,
            [heading]: !expand[heading],
          })
        }
      >
        <div className="d-flex w-100 align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-1">
            {label && (
              <span
                style={{ height: 22, width: 22 }}
                className={`tab-number fs-8 bg-gray-300 text-gray-700 font-weight-medium border text-center d-flex align-items-center justify-content-center rounded-circle`}
              >
                {label}
              </span>
            )}
            <h5 className="mb-0">{heading}</h5>
          </div>
          <MaterialIcon
            icon={expand[heading] ? 'expand_less' : 'expand_more'}
          />
        </div>
      </CardHeader>
      {expand[heading] && <CardBody className="p-3">{children}</CardBody>}
    </Card>
  );
};
export default ExpandCollapseCard;
