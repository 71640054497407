import { Card, CardBody } from 'reactstrap';
import ButtonIcon from '../../../commons/ButtonIcon';

const BlankWidget = ({ widget }) => {
  return (
    <Card
      className="report-widget border-dashed-gray border-4"
      style={{ height: 270 }}
    >
      <CardBody className="d-flex align-items-center justify-content-center">
        <ButtonIcon
          color="primary"
          icon="add"
          classnames="btn-sm"
          onclick={widget?.action?.onAdd}
          label="Add Widget"
        />
      </CardBody>
    </Card>
  );
};

export default BlankWidget;
