import React, { Fragment, useEffect, useReducer, useState } from 'react';

import './style.css';
import {
  orgFilters,
  initialStateCompany,
  reducer,
  isEmptyFilter,
} from './constants';
import { useFilterProspectContext } from '../../../contexts/filterProspectContext';
import FilterItemMenuCompany from './common/FilterItemMenuCompany';
import { getKeysWithData } from '../../../utils/Utils';

import { useLocation } from 'react-router';
import MaterialIcon from '../../commons/MaterialIcon';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const MenuCompany = ({
  chargeFilter,
  saveFilter,
  loadFilter,
  selctedFilter,
}) => {
  const query = useQuery();
  const flag = query.get('back');
  const { globalFiltersCompany, setGlobalFiltersCompany } =
    useFilterProspectContext();
  const [data, dispatch] = useReducer(reducer, initialStateCompany);
  const [showClear, setShowClear] = useState(false);
  const [active, setActive] = useState([1]);
  const [firstRender, setFirstRender] = useState(false);

  const onSubmit = async () => {
    const filters = getKeysWithData(data);
    if (Object.keys(filters).length) {
      setShowClear(true);
      setFirstRender(true);
      chargeFilter(filters);
      setGlobalFiltersCompany(data);
    } else {
      chargeFilter({}, 'LOAD');
      setShowClear(false);
      setFirstRender(true);
      dispatch({ type: 'set', payload: initialStateCompany });
      setGlobalFiltersCompany({});
    }
  };

  const clearFilter = () => {
    setShowClear(false);
    dispatch({ type: 'set', payload: initialStateCompany });
    chargeFilter({}, 'CLEAR');
    setGlobalFiltersCompany(initialStateCompany);
  };

  const onEnter = () => {
    if (firstRender || showClear) {
      onSubmit();
    } else {
      setFirstRender(true);
    }

    if (firstRender) setFirstRender(false);
  };

  useEffect(() => {
    if (flag === 'true') {
      dispatch({ type: 'set', payload: globalFiltersCompany });
    } else {
      setGlobalFiltersCompany(initialStateCompany);
    }
  }, []);

  useEffect(() => {
    setGlobalFiltersCompany(data);
    setFirstRender(true);
  }, [data]);

  useEffect(() => {
    // if we get load filter from parent component then dispatch updated filters list to show in filter menu
    dispatch({ type: 'set', payload: globalFiltersCompany });
    const filters = getKeysWithData(globalFiltersCompany);
    setShowClear(!isEmptyFilter(filters));
    setFirstRender(true);
  }, [loadFilter]);

  return (
    <Fragment>
      <div className="d-flex py-2 justify-content-between">
        <h5 className="font-weight-medium mb-0">Search Filters</h5>
        {showClear && (
          <div
            className="d-flex align-items-center justify-content-end cursor-pointer fs-7"
            onClick={clearFilter}
          >
            <MaterialIcon icon="close" size="fs-5" clazz="d-flex" />
            <span className="date-clear-btn">Clear all</span>
          </div>
        )}
      </div>
      <div className="flex-column">
        {orgFilters.map((name) => (
          <FilterItemMenuCompany
            title={name}
            key={name}
            data={data}
            setData={dispatch}
            onEnter={onEnter}
            active={active}
            setActive={setActive}
            selctedFilter={selctedFilter}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default MenuCompany;
