import React, { useContext, useEffect, useState } from 'react';

import AddNote from './contentFeed/AddNote';
import AddFile from './contentFeed/AddFile';
import stringConstants from '../../utils/stringConstants.json';
import AddDataReport from './contentFeed/AddDataReport';
import {
  DetailTabs,
  getAccountsChildPermissions,
  isModuleAllowed,
  isPermissionAllowed,
} from '../../utils/Utils';
import { AddActivityButtonsGroup } from '../addActivityButtonsGroup';
import Steps from '../steps/Steps';
import DealProductsV2 from '../../views/Deals/pipelines/DealProductsV2';
import { TenantContext } from '../../contexts/TenantContext';
import AnimatedTabs from '../commons/AnimatedTabs';
import feedService from '../../services/feed.service';
import { PermissionsConstants } from '../../utils/permissions.constants';
import NoDataFound from '../commons/NoDataFound';
import AuditActivityTimeline from '../ActivityTimeline/AuditActivityTimeline';
import { useParams } from 'react-router-dom';
import { ChecklistsOrganization } from '../ChecklistOrganizationTab';
import InfiniteScroll from 'react-infinite-scroll-component';
import Contacts from '../organizationProfile/contacts/Contacts';
import Deals from './deals/Deals';
import StageHistory from './deals/StageHistory';

const constants = stringConstants.deals.contacts.profile;
const TABS = DetailTabs;

const organizationTabsSequence = [
  'Data',
  'Timeline',
  'Contacts',
  'Notes',
  'Activities',
  'Checklist',
  'Opportunities',
  'Files',
];

const contactsTabsSequence = [
  'Timeline',
  'Notes',
  'Activities',
  'Opportunities',
  'Files',
];

const opportunitiesTabsSequence = [
  'Timeline',
  'Notes',
  'Activities',
  'Files',
  'StageHistory',
  'Products',
  'Contacts',
];

const sortTabsBySequence = (enabledTabs, dataType) => {
  const sequence =
    dataType === 'organization'
      ? organizationTabsSequence
      : dataType === 'contact'
      ? contactsTabsSequence
      : opportunitiesTabsSequence;

  if (!sequence?.length) return enabledTabs;
  const sequenceIds = sequence.map((tabName) => DetailTabs[tabName]);

  // Sort the enabledTabs array based on the sequenceIds array
  return enabledTabs.sort((a, b) => {
    const indexA = sequenceIds.indexOf(a.tabId);
    const indexB = sequenceIds.indexOf(b.tabId);

    // If a tabId is not found in the sequence, it will appear after the ones that are found
    return indexA === -1 ? 1 : indexB === -1 ? -1 : indexA - indexB;
  });
};

const AddContent = ({
  moduleMap,
  contactId,
  getProfileInfo,
  organizationId,
  dealId,
  getDeal,
  setDeal,
  dataSection,
  setProfileInfo,
  contactIs,
  refreshRecentFiles,
  setRefreshDeal,
  setRefreshRecentFiles,
  contactInfo,
  dataType,
  refreshPipelineStage,
  isPrincipalOwner,
  me,
  type,
  activityType,
  deal,
  organization,
  activityIdOpen,
  isDeal,
  isContact,
  fromOrganization,
  profileInfo,
  tabCounts,
  setRefreshTabCounts,
}) => {
  const { tenant } = useContext(TenantContext);
  const params = useParams();
  const [allNotes, setAllNotes] = useState([]);

  const [showContactsModal, setShowContactsModal] = useState(false);

  const EngagementReportsPermissions = Object.values(
    PermissionsConstants.Reports
  );
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [refresh, setRefresh] = useState(0);
  let updatedType = type;
  if (type !== 'pipelines') {
    updatedType = getAccountsChildPermissions(tenant.modules, type);
  }

  const organizationTabs = [
    {
      tabId: TABS.Data,
      icon: 'text_snippet',
      title: 'Engagement',
      module: Object.values(PermissionsConstants.Reports), // :\ any of the engagement reports strings found enable the tab please
      count: 0,
    },
    {
      tabId: TABS.Timeline,
      icon: 'text_snippet',
      title: 'Timeline',
      module: `${updatedType}_timeline`,
      count: 0,
    },
    {
      tabId: TABS.Notes,
      icon: 'text_snippet',
      title: constants.notesLabel,
      module: `${updatedType}_notes`,
      count: 0,
    },
    {
      tabId: TABS.Checklist,
      icon: 'text_snippet',
      title: 'Checklists',
      module: PermissionsConstants.Checklist.checklist,
      count: 0,
    },
    {
      tabId: TABS.Activities,
      icon: 'event',
      title: 'Activities',
      module: `activities_${activityType}_activities`,
      count: 0,
    },
    {
      tabId: TABS.Files,
      icon: 'attachment',
      title: constants.filesLabel,
      module: `${updatedType}_files`,
      count: 0,
    },
  ];
  const dealTabs = [
    {
      tabId: TABS.StageHistory,
      icon: 'history',
      title: 'Stage History',
      module: 'pipelines_stage_history',
      count: 0,
    },
    {
      tabId: TABS.Products,
      icon: 'text_snippet',
      title: moduleMap?.product?.plural,
      module: 'pipelines_products',
      count: 0,
    },
  ];
  const contactTabs = [
    {
      tabId: TABS.Timeline,
      icon: 'text_snippet',
      title: 'Timeline',
      module: `${updatedType}_timeline`,
      count: 0,
    },
    {
      tabId: TABS.Notes,
      icon: 'text_snippet',
      title: constants.notesLabel,
      module: `${updatedType}_notes`,
      count: 0,
    },
    {
      tabId: TABS.Activities,
      icon: 'event',
      title: 'Activities',
      module: `activities_${activityType}_activities`,
      count: 0,
    },
    {
      tabId: TABS.Files,
      icon: 'attachment',
      title: constants.filesLabel,
      module: `${updatedType}_files`,
      count: 0,
    },
  ];
  const contactsTab = isModuleAllowed(tenant.modules, 'contacts')
    ? [
        {
          tabId: TABS.Contacts,
          icon: 'text_snippet',
          title: moduleMap?.contact?.plural,
          module: 'contacts',
          count: 0,
        },
      ]
    : [];
  const opportunityTab = isModuleAllowed(tenant.modules, 'pipelines')
    ? [
        {
          tabId: TABS.Opportunities,
          icon: 'attachment',
          title: constants.opportunitiesLabel,
          module: 'pipelines',
          count: 0,
        },
      ]
    : [];
  const allowedTabs = contactTabs?.filter((item) => {
    return isModuleAllowed(tenant?.modules, item?.module);
  });
  const allowedOrgTabs = organizationTabs?.filter((item) => {
    return isModuleAllowed(tenant?.modules, item?.module);
  });
  const allowedDealTabs = dealTabs?.filter((item) => {
    return isModuleAllowed(tenant?.modules, item?.module);
  });
  const tabsByType = {
    organization: [...allowedOrgTabs, ...opportunityTab, ...contactsTab],
    contact: [...allowedTabs, ...opportunityTab],
    deal: isModuleAllowed(tenant.modules, 'pipelines_products')
      ? [...allowedTabs, ...allowedDealTabs, ...contactsTab]
      : [...allowedTabs, ...contactsTab],
  };
  const enabledTabs = tabsByType[dataType];
  const [activeTab, setActiveTab] = useState(enabledTabs[0].tabId);
  const [tabs, setTabs] = useState(sortTabsBySequence(enabledTabs, dataType));
  const [hasMore, setHasMore] = useState(false);
  const [limit, setLimit] = useState(5);
  const notePlaceholder = (
    <div
      className="cursor-pointer text-muted"
      style={{ backgroundColor: 'white' }}
    >
      {showPlaceholder ? 'Start writing a note...' : ''}
    </div>
  );
  const handleRefreshFeed = () => {
    setRefresh((prevState) => prevState + 1);
  };
  const getNotes = async () => {
    try {
      const pagination = {
        page: 1,
        limit,
      };
      const queryParam = isContact
        ? { contactId }
        : isDeal
        ? { dealId }
        : { organizationId };
      const { data: notes, pagination: countData } = await feedService.getNote(
        queryParam,
        pagination
      );
      setAllNotes([...notes]);
      if (limit <= countData?.count) {
        setHasMore(notes.length > 0);
        setLimit((prevLimit) => prevLimit + 10);
      } else {
        setHasMore(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    activeTab === TABS.Notes && getNotes();
  }, [refresh, params, activeTab]);

  useEffect(() => {
    const sortedTabs = sortTabsBySequence(tabsByType[dataType], dataType);
    setTabs(sortedTabs);
  }, [moduleMap]);
  const renderContent = () => {
    if (activeTab === TABS.Data) {
      return (
        <>
          {isModuleAllowed(tenant.modules, EngagementReportsPermissions) ? (
            <AddDataReport
              organization={organization}
              getProfileInfo={getProfileInfo}
              organizationId={organizationId}
              profileInfo={setProfileInfo}
              isPrincipalOwner={isPrincipalOwner}
              refreshCounts={() => {
                setRefreshTabCounts((prevState) => prevState + 1);
              }}
            />
          ) : null}
        </>
      );
    } else if (activeTab === TABS.Notes) {
      return (
        <div className="px-3 tab-item">
          <h4 className="mt-0 mb-0">Notes</h4>
          {isPermissionAllowed('notes', 'create') ? (
            <div>
              <AddNote
                setShowPlaceholder={setShowPlaceholder}
                contactId={contactId}
                organizationId={organizationId}
                getProfileInfo={() => {
                  setRefreshTabCounts((prevState) => prevState + 1);
                  handleRefreshFeed();
                }}
                dealId={dealId}
                getDeal={getDeal}
                placeholder={notePlaceholder}
                onCancel={() => setShowPlaceholder(true)}
              />
            </div>
          ) : (
            <NoDataFound title="You don't have access this tab" />
          )}
          <div>
            <InfiniteScroll
              dataLength={allNotes.length}
              next={getNotes}
              hasMore={hasMore}
            >
              <Steps
                organizationId={organizationId}
                getProfileInfo={getProfileInfo}
                openActivityId={activityIdOpen}
                organization={organization}
                dataType={dataType}
                contactInfo={contactInfo}
                allNotes={allNotes}
                setRefreshRecentFiles={setRefreshRecentFiles}
                me={me}
                filteredBy={{ type: ['note'] }}
                layout="new"
                layoutType="note"
                isDeal={isDeal}
                deal={deal}
                setDeal={setDeal}
                dealId={deal?.id}
                isContact={isContact}
                contactId={contactId}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </InfiniteScroll>
          </div>
        </div>
      );
    } else if (activeTab === TABS.Checklist) {
      return (
        <>
          {isPermissionAllowed('checklists', 'view') ? (
            <div className="p-0 tab-item activity-detail-tab">
              <h4 className="mt-0 px-3">Checklists</h4>
              <ChecklistsOrganization
                moduleMap={moduleMap}
                componentId="new-activity"
                contactId={contactId}
                dataType={dataType}
                organizationId={organizationId}
                dealId={dealId}
                profileRefresh={() => {
                  getProfileInfo();
                  handleRefreshFeed();
                }}
                contactIs={contactIs}
                contactInfo={contactInfo}
                profileInfo={setProfileInfo}
                deal={deal}
                organization={organization}
                activityIdOpen={activityIdOpen}
                me={me}
                activeTab={activeTab}
                isDeal={isDeal}
                setRefreshRecentFiles={setRefreshRecentFiles}
                isContact={isContact}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </div>
          ) : (
            <NoDataFound title="You don't have access this tab" />
          )}
        </>
      );
    } else if (activeTab === TABS.Activities) {
      return (
        <>
          {isPermissionAllowed('activities', 'view') ? (
            <div className="p-0 tab-item activity-detail-tab">
              <h4 className="mt-0 px-3">Activities</h4>
              <AddActivityButtonsGroup
                moduleMap={moduleMap}
                componentId="new-activity"
                contactId={contactId}
                dataType={dataType}
                organizationId={organizationId}
                dealId={dealId}
                profileRefresh={() => {
                  getProfileInfo();
                  handleRefreshFeed();
                }}
                contactIs={contactIs}
                contactInfo={contactInfo}
                profileInfo={setProfileInfo}
                deal={deal}
                organization={organization}
                activityIdOpen={activityIdOpen}
                me={me}
                isDeal={isDeal}
                setRefreshRecentFiles={setRefreshRecentFiles}
                isContact={isContact}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </div>
          ) : (
            <NoDataFound title="You don't have access this Tab" />
          )}
        </>
      );
    } else if (activeTab === TABS.Files) {
      return (
        <div className="px-3 tab-item">
          <AddFile
            contactId={contactId}
            organizationId={organizationId}
            getProfileInfo={getProfileInfo}
            dealId={dealId}
            getDeal={getDeal}
            refreshRecentFiles={refreshRecentFiles}
            setRefreshRecentFiles={setRefreshRecentFiles}
            me={me}
            setRefreshTabCounts={setRefreshTabCounts}
            noFilesMessage={
              <NoDataFound
                icon="note_stack"
                iconSymbol={true}
                title={
                  <div className="font-normal font-size-sm2 text-gray-search">
                    {`This record doesn’t have any files`}
                  </div>
                }
                containerStyle="text-gray-search py-6"
              />
            }
            fromOrganization={fromOrganization}
          />
        </div>
      );
    } else if (activeTab === TABS.Products) {
      return (
        <DealProductsV2
          deal={deal}
          setDeal={setDeal}
          mode={1}
          setRefreshDeal={setRefreshDeal}
          refreshPipelineStage={refreshPipelineStage}
          getDeal={getDeal}
        />
      );
    } else if (activeTab === TABS.Timeline) {
      return (
        // hehehe :P passing id like that
        <AuditActivityTimeline
          parentResourceId={
            dataType === 'deal'
              ? dealId
              : dataType === 'contact'
              ? contactId
              : organizationId
          }
          resourceId={
            dataType === 'deal'
              ? dealId
              : dataType === 'contact'
              ? contactId
              : organizationId
          }
          closeSidePanel={null}
          type={dataType}
          refresh={refresh}
        />
      );
    } else if (activeTab === TABS.Contacts) {
      return (
        <>
          {dataType === 'organization' ? (
            <Contacts
              companyName={moduleMap.organization.singular}
              moduleMap={moduleMap.contact.singular}
              organizationId={organizationId}
              showContactsModal={showContactsModal}
              setShowContactsModal={setShowContactsModal}
              profileInfo={profileInfo}
              organization={organization}
              getProfileInfo={getProfileInfo}
              isPrincipalOwner={isPrincipalOwner}
              mainOwner={profileInfo?.assigned_user}
              mainModuleMap={moduleMap}
              viewType="table"
              className="companydetail-tab-contact"
            />
          ) : (
            <Contacts
              moduleMap={moduleMap.contact.plural}
              organizationId={
                Object.keys(deal)?.length > 0 ? deal?.organization?.id : ''
              }
              profileInfo={profileInfo}
              viewType="table"
              showContactsModal={showContactsModal}
              setShowContactsModal={setShowContactsModal}
              getProfileInfo={getProfileInfo}
              isPrincipalOwner={isPrincipalOwner}
              mainOwner={profileInfo?.assigned_user}
              mainModuleMap={moduleMap}
            />
          )}
        </>
      );
    } else if (activeTab === TABS.Opportunities) {
      return (
        <div className="tab-item activity-detail-tab">
          {dataType === 'organization' ? (
            <Deals
              moduleMap={moduleMap.deal}
              organizationId={organizationId}
              organization={organization}
            />
          ) : (
            <Deals
              moduleMap={moduleMap.deal}
              contactProfile={contactInfo}
              contactId={contactId}
            />
          )}
        </div>
      );
    } else if (activeTab === TABS.StageHistory) {
      return (
        <div className="p-0 tab-item activity-detail-tab">
          <h4 className="mt-0 px-3">Stage History</h4>
          <StageHistory
            deal={deal}
            refreshTabCounts={() => {
              setRefreshTabCounts((prevState) => prevState + 1);
            }}
          />
        </div>
      );
    } else {
      return <span>Invalid tab</span>;
    }
  };

  return (
    <div>
      <div className="card border-0 shadow-none">
        <div className="card-header p-0 border-bottom-0">
          <div className="border-bottom w-100">
            <AnimatedTabs
              showCount={true}
              tabClasses={'link-active-wrapper nav-sm-down-break'}
              tabsData={tabs}
              activeTab={activeTab}
              tabCounts={tabCounts}
              toggle={(tab) => setActiveTab(tab.tabId)}
            />
          </div>
        </div>
        <div className="p-0 tab-content custom-scrollbar">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default AddContent;
