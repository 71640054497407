import { usePagesContext } from '../../../../contexts/pagesContext';
import { AccountStructureTabMap } from '../account.structure.constants';
import React from 'react';
import AccountStructureWidgetWrapper from './AccountStructureWidgetWrapper';
import FlowChartLegends from '../FlowChartLegends';
import FlowChart from '../FlowChart';

const FundsFlow = ({ report }) => {
  const { pageContext } = usePagesContext();
  const whenPrinting = !!report;
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  const accountStructure =
    report?.AccountStructureReport || pageContext.AccountStructureReport;
  const accounts = accountStructure[jsonKey]?.accounts || [];
  return (
    <>
      {accounts.length > 0 ? (
        <div className="mt-2">
          <AccountStructureWidgetWrapper>
            {!whenPrinting && <h5 className="text-left mb-1">Funds Flow</h5>}
            <div className="position-relative">
              <div className="py-5">
                <FlowChart accounts={accounts} />
              </div>
              <FlowChartLegends />
            </div>
          </AccountStructureWidgetWrapper>
        </div>
      ) : null}
    </>
  );
};

export default FundsFlow;
