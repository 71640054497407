import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Spinner } from 'reactstrap';

export const DeactivateTeamModal = ({
  handleShowModal,
  handleHideModal,
  handleUpdateTeam,
  isActivate,
  isTeamName = '',
  isLoading,
}) => {
  return (
    <>
      <Modal
        animation={false}
        show={handleShowModal || isActivate}
        onHide={handleHideModal}
      >
        <Modal.Header closeButton>
          {!isActivate ? (
            <Modal.Title as="h3">Confirm Deactivating Team</Modal.Title>
          ) : (
            <Modal.Title as="h3">Confirm Activating Team</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: !isActivate
                ? `This action will deactivate <b>${isTeamName.name}</b>. You can reactivate anytime.`
                : `This action will activate <b>${isTeamName.name}</b>.`,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="white" size="sm" onClick={handleHideModal}>
            Cancel
          </Button>
          {isActivate ? (
            <Button variant="primary" size="sm" onClick={handleUpdateTeam}>
              {isLoading ? (
                <Spinner className="spinner-grow-xs" />
              ) : (
                <span>Activate</span>
              )}
            </Button>
          ) : (
            <Button variant="danger" size="sm" onClick={handleUpdateTeam}>
              {isLoading ? (
                <Spinner className="spinner-grow-xs" />
              ) : (
                <span>Deactivate</span>
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
