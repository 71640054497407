import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Home from '../components/ClientPortal/Home';
import authService from '../services/auth.service';
import Loading from '../components/Loading';
import organizationService from '../services/organization.service';
import envService from '../services/env.service';
import useTenantTheme from '../hooks/useTenantTheme';
import ClientDashboardLayout from '../layouts/ClientDashboardLayout';
import { usePagesContext } from '../contexts/pagesContext';
import {
  getClientPortalOrganization,
  getClientPortalToken,
  getIdfPublicToken,
} from '../layouts/constants';

import { useTenantContext } from '../contexts/TenantContext';

const ClientDashboard = () => {
  const history = useHistory();
  const [, setLoader] = useState(false);
  const [organization, setOrganization] = useState({});
  const [contactId, setContactId] = useState('');
  const { pageContext } = usePagesContext();
  const [, setOwner] = useState({});
  const [organizationId, setOrganizationId] = useState('');
  const { setTenant } = useTenantContext();
  const [selectedMenu, setSelectedMenu] = useState({});

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const hasUrlToken = urlParams.get('token');
      let contact_id = null;
      let shared_by = null;
      let resource_access = null;
      if (hasUrlToken) {
        if (!pageContext?.ClientPortalData) {
          setLoader(true);
          try {
            const query = qs.parse(location.search, {
              ignoreQueryPrefix: true,
            });
            const hasQueryKeys = Object.keys(query).length > 0;
            const storageToken =
              getIdfPublicToken()?.access_token || query.token;

            // no query keys, redirect to login
            if (!hasQueryKeys || !query.token) {
              history.push('/clientportal/login');
            }

            const token = {
              access_token: storageToken,
            };
            const tokenPayload = await authService.introspect(storageToken);

            sessionStorage.setItem('idftoken-public', JSON.stringify(token));

            const data = await envService.getEnv();
            setTenant(data);

            contact_id = tokenPayload.contact_id;
            shared_by = tokenPayload.shared_by;
            tokenPayload.exp = tokenPayload.exp * 1000;
            resource_access = tokenPayload.resource_access.organization[0].id;
            sessionStorage.setItem(
              'tokenPayload',
              JSON.stringify(tokenPayload)
            );
            const organizationObj =
              await organizationService.getOrganizationById(resource_access);
            setOrganization(organizationObj);
            setLoader(false);
          } catch (error) {
            history.push('/clientportal/login');
          }
        }
      } else {
        const clientPortalOrganization = getClientPortalOrganization();
        const clientPortalToken = getClientPortalToken();

        if (clientPortalOrganization) {
          try {
            setOrganization(clientPortalOrganization);
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }

        if (clientPortalToken) {
          try {
            resource_access =
              clientPortalToken?.resource_access?.organization[0]?.id;
            shared_by = clientPortalToken?.shared_by;
            contact_id = clientPortalToken?.contact_id;
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }
      }

      setContactId(contact_id);
      setOwner(shared_by);
      setOrganizationId(resource_access);
    })();
  }, []);

  useTenantTheme();

  if (!contactId || !organizationId) {
    return <Loading />;
  }

  return (
    <ClientDashboardLayout
      selectedMenu={selectedMenu}
      setSelectedMenu={setSelectedMenu}
    >
      <Home
        contactId={contactId}
        organization={organization}
        organizationId={organizationId}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />
    </ClientDashboardLayout>
  );
};

export default ClientDashboard;
