const AllRightsReservedWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <div
      className={`d-flex flex-column text-left w-100 px-2 report-widget ${
        whenPrinting ? 'p-2' : 'p-4'
      } gap-3`}
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      <span className="text-left font-size-sm2">{widgetConfig.stamp}</span>
      <span className="text-left font-size-sm2">{widgetConfig.statement}</span>
      <span className="text-left font-size-sm2">
        {widgetConfig.enrollmentStatement}
      </span>
    </div>
  );
};

export default AllRightsReservedWidget;
