import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, Row, Col, Spinner } from 'reactstrap';

import { ADD_TO_LESSON, REMOVE_FROM_FAVORITES } from '../../utils/constants';
import LessonService from '../../services/lesson.service';
import TooltipComponent from './Tooltip';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import MaterialIcon from '../commons/MaterialIcon';
import LessonLabel from '../commons/LessonLabel';
import { isModuleAllowed } from '../../utils/Utils';
import { useTenantContext } from '../../contexts/TenantContext';

export default function LayoutCard(props) {
  const { item, lesson, setGetLessonId, classnames } = props;
  const { id } = item;

  const parsedTags = item?.tags?.length && JSON.parse(item.tags);
  const [errorMessage, setErrorMessage] = useState('');
  const [favorite, setFavorite] = useState(false);
  const [favoriteInProgress, setFavoriteInProgress] = useState(false);
  const { tenant } = useTenantContext();

  const setFavoriteLessonAndCourse = (item) => {
    try {
      const { progress } = item;
      const { preferences } = item;
      setFavorite(preferences?.[0]?.isFavorite || progress?.[0]?.is_favorite);
    } catch (e) {
      console.log('parsing-error-preferences', e);
    }
  };
  useEffect(() => {
    const setTrainingInfo = async () => {
      setFavoriteLessonAndCourse(item);
    };

    setTrainingInfo();
  }, []);
  async function onHandleFavorite(e) {
    e.preventDefault();
    setFavoriteInProgress(true);
    const favorite = await LessonService.PutFavoriteByLessonId({ id });
    setFavoriteInProgress(false);
    if (favorite) setFavorite((prevState) => !prevState);
  }

  async function onDownload(e) {
    e.preventDefault();

    try {
      const file = await LessonService.PdfLinkByLesson(item.documents);

      if (!file) {
        setErrorMessage('File not found');
        return;
      }

      const data = new Blob([file], { type: 'application/pdf' });
      const fileUrl = window.URL.createObjectURL(data);
      window.open(fileUrl);
    } catch (error) {
      setErrorMessage('File not found');
    }
  }
  return (
    <div className={`${classnames} position-relative`}>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
      </AlertWrapper>
      <div className="mt-3" onClick={() => setGetLessonId(item?.id)}>
        <div className="mt-3 cursor-pointer">
          <Card
            className={`setting-item lesson-card-box rounded-lg ${
              lesson?.id === item?.id ? 'lesson-active' : ''
            }`}
          >
            <CardBody className="p-2_1 px-3 overflow-y-auto h-100">
              <div className="d-flex flex-column h-100">
                <div className="d-flex align-items-center flex-fill">
                  <div className={`flex-fill mb-2`}>
                    <div className="text-right">
                      <LessonLabel labels={parsedTags} />
                    </div>
                    <span className="text-primary pt-4 font-size-md font-weight-500 d-block">
                      {item?.category_id ? 'Lesson' : 'Course'}
                    </span>
                    <h4 className="card-title mb-0 text-hover-primary text-wrap">
                      {item.title || item.name}
                    </h4>
                  </div>
                </div>
              </div>
            </CardBody>
            <CardFooter className="p-2_1 px-3">
              <Row className="justify-content-between align-items-center">
                <Col className="col-auto d-flex flex-row align-items-center">
                  {isModuleAllowed(tenant.modules, 'learns_lesson_catalog') ? (
                    !favoriteInProgress ? (
                      <TooltipComponent
                        title={favorite ? REMOVE_FROM_FAVORITES : ADD_TO_LESSON}
                      >
                        <button
                          className="btn btn-icon btn-icon-sm icon-ignore btn-soft-primary btn-sm rounded-circle cursor-pointer"
                          onClick={(e) => onHandleFavorite(e)}
                        >
                          <MaterialIcon
                            clazz={`mt-1`}
                            icon={favorite ? 'favorite' : 'favorite_border'}
                          />
                        </button>
                      </TooltipComponent>
                    ) : (
                      <Spinner
                        className="mr-1"
                        style={{ width: 26, height: 26 }}
                      />
                    )
                  ) : (
                    ''
                  )}

                  {item.documents && (
                    <TooltipComponent title="Download lessons">
                      <button
                        className="cursor-pointer ml-1 btn btn-icon icon-ignore btn-icon-sm btn-soft-success btn-sm rounded-circle"
                        onClick={(e) => onDownload(e)}
                      >
                        <MaterialIcon icon="download_for_offline" />
                      </button>
                    </TooltipComponent>
                  )}

                  {(item?.progress === 100 ||
                    item?.progress[0]?.progress === 100) && (
                    <TooltipComponent title="Completed">
                      <button
                        className="btn btn-icon ml-1 btn-icon-sm btn-soft-success icon-ignore no-hover p-2 btn-sm rounded-circle cursor-default"
                        onClick={(e) => onDownload(e)}
                      >
                        <MaterialIcon
                          filled
                          icon="check_circle"
                          clazz="text-success font-size-xl"
                        />
                      </button>
                    </TooltipComponent>
                  )}
                </Col>
                <div className="col-auto text-muted fs-6">
                  {item.duration > 0 && `${item.duration} mins`}
                </div>
              </Row>
            </CardFooter>
          </Card>
        </div>
      </div>
    </div>
  );
}
