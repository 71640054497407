import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import TextRoundBlock from '../../blocks/TextRoundBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import { numbersWithComma, roundOrShowAll } from '../../../../utils/Utils';
import Naics55Block from '../../Naics55Block';

const DPODSOpportunityToImproveWidget = ({ widgetConfig, whenPrinting }) => {
  const yourDPODSOValue = Math.abs(
    widgetConfig?.data[`your${widgetConfig?.extraType}`]
  );
  const industryAvgValue = Math.abs(
    widgetConfig?.data[`avg${widgetConfig?.extraType}`]
  );
  const bestInClassValue = Math.abs(
    widgetConfig?.data[`bestInClass${widgetConfig?.extraType}`]
  );
  const bestInClassValue_ = Math.abs(
    widgetConfig?.data[`bestInClass${widgetConfig?.extraType}_`]
  );
  const oneDayDiffValue = Math.abs(
    widgetConfig?.data[`oneDay${widgetConfig.extraType}Diff`]
  );
  const totalBestInClassDPODiff = Math.abs(
    widgetConfig?.data[`totalBestInClass${widgetConfig.extraType}Diff`]
  );

  // not liking these things at all
  const naics55DPO =
    widgetConfig?.extraType === 'DPO' &&
    widgetConfig?.data?.valueNaicsSic === '55';

  const naics55DSO =
    widgetConfig?.extraType === 'DSO' &&
    widgetConfig?.data?.valueNaicsSic === '55';

  const congratsDSO =
    widgetConfig?.extraType === 'DSO'
      ? widgetConfig?.data?.yourDSO <
        (widgetConfig?.data?.bestInClassDSO || widgetConfig?.data?.avgDSO)
      : '';
  const congratsDPO =
    widgetConfig?.extraType === 'DPO'
      ? widgetConfig?.data?.yourDPO > widgetConfig?.data?.bestInClassDPO
      : '';

  return (
    <Card className="report-widget h-100">
      <CardBody className="align-items-center d-flex w-100">
        <div className="flex-fill">
          <h5 className="text-left d-flex mb-1">{widgetConfig.heading}</h5>
          <div className="widget-extra-padding px-2">
            <p
              className={`mb-0 ${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } text-left`}
            >
              <span
                dangerouslySetInnerHTML={{ __html: widgetConfig.description }}
              ></span>
            </p>
            <div
              style={{ gap: 10 }}
              className={`d-flex mt-2 py-2 align-items-center justify-content-center`}
            >
              <TextRoundBlock
                big={numbersWithComma(yourDPODSOValue)}
                fill="flex-fill border-2 report-widget-inner-section w-100px py-2"
                small={`Your Current ${widgetConfig.extraType}`}
                color="text-rpt-heading"
                bg="#ffffff"
              />
              <TextRoundBlock
                fill="flex-fill border-2 report-widget-inner-section w-100px py-2"
                big={numbersWithComma(industryAvgValue)}
                color="text-rpt-heading"
                small={`Industry Average ${widgetConfig.extraType}`}
                bg="#ffffff"
              />
              <TextRoundBlock
                fill="flex-fill w-100px border-2 report-widget-inner-section-soft border-2 bg-primary-soft py-2"
                big={
                  naics55DSO
                    ? numbersWithComma(industryAvgValue)
                    : numbersWithComma(bestInClassValue)
                }
                color="text-rpt-heading"
                small={`Best-In-Class ${widgetConfig.extraType} ${
                  naics55DPO || naics55DSO ? '*' : ''
                }`}
                bg=""
              />
            </div>
            {naics55DPO || naics55DSO ? (
              <Naics55Block
                naics={widgetConfig?.data?.valueNaicsSic}
                style="mb-0"
              />
            ) : null}
            <div
              style={{ gap: 10 }}
              className={`d-flex align-items-center justify-content-center pt-6`}
            >
              {widgetConfig.powerAndPotential.map((pp) => {
                // TODO: hocus pocus logic
                const newP = {
                  ...pp,
                  description: pp.description.includes(
                    `bestInClass${widgetConfig.extraType}`
                  )
                    ? pp.description
                        .replace(
                          `bestInClass${widgetConfig.extraType}`,
                          roundOrShowAll(bestInClassValue_)?.replace(/$/g, '')
                        )
                        .replace(/[$()]/g, '')
                    : pp.description.replace(
                        `oneDay${widgetConfig.extraType}Diff`,
                        roundOrShowAll(oneDayDiffValue)
                      ),
                  xValue: pp.xValue.includes(
                    `oneDay${widgetConfig.extraType}Diff`
                  )
                    ? pp.xValue.replace(
                        `oneDay${widgetConfig.extraType}Diff`,
                        roundOrShowAll(oneDayDiffValue, 999999999)
                      )
                    : pp.xValue.replace(
                        `bestInClass${widgetConfig.extraType}`,
                        roundOrShowAll(totalBestInClassDPODiff, 999999999)
                      ),
                };
                return (
                  <div
                    key={newP.heading}
                    className="flex-fill pt-4 pb-3 border-2 report-widget-inner-section-soft position-relative gap-1 h-100 text-primary rounded-lg"
                  >
                    <div
                      className="d-flex ml-3 px-2 align-items-center gap-2 bg-rpt-icon bg-white justify-content-center position-absolute left-0"
                      style={{ top: -24 }}
                    >
                      <span className="rounded-circle p-2 d-flex align-items-center justify-content-center bg-primary-soft">
                        <MaterialIcon
                          icon={newP.icon}
                          symbols
                          rounded={false}
                          clazz="font-size-2xl text-primary"
                        />
                      </span>
                      <p
                        className="fs-8 mb-0 font-weight-semi-bold"
                        style={{ maxWidth: 100 }}
                        dangerouslySetInnerHTML={{ __html: newP.heading }}
                      />
                    </div>
                    <div className="px-4 pt-3">
                      <>
                        {newP.heading.includes('Best-In-Class') &&
                        (congratsDPO || congratsDSO) ? (
                          <>
                            <p
                              className={`mb-1 max-w-200 ${
                                whenPrinting ? 'sub-text' : 'fs-9'
                              }`}
                            >
                              You&apos;re Best-In-Class, consider the power of
                              improving one day:
                              <h1 className="rpt-green-box-heading font-weight-bold mb-0">
                                Congratulations!
                              </h1>
                            </p>
                          </>
                        ) : (
                          <>
                            <p
                              className={`mb-1 max-w-200 ${
                                whenPrinting ? 'sub-text' : 'fs-9'
                              }`}
                            >
                              {newP.description}
                            </p>
                            <h1 className="rpt-green-box-heading font-weight-bold mb-0">
                              {newP.xValue}
                            </h1>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                );
              })}
            </div>
            {widgetConfig.source && (
              <WidgetSourceBlock text={widgetConfig.source} />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DPODSOpportunityToImproveWidget;
