import IdfFormInput from '../idfComponents/idfFormInput/IdfFormInput';
import IdfSearchDirections from '../idfComponents/idfSearch/IdfSearchDirections';
import IdfSelectLabel from '../idfComponents/idfDropdown/IdfSelectLabel';
import { PricingField } from '../PricingFieldComponent';
import ReactDatepicker from '../inputs/ReactDatpicker';

export const orgDynamicFields = [
  {
    field_type: 'CHAR',
    component: <IdfFormInput />,
    colSize: 12,
  },
  {
    field_type: 'TEXT',
    component: <IdfFormInput />,
    colSize: 12,
  },
  {
    field_type: 'ADDRESS',
    component: <IdfSearchDirections fromNavBar />,
    colSize: 12,
  },
  {
    field_type: 'TEXT',
    component: <IdfSelectLabel />,
    colSize: 12,
  },
  {
    field_type: 'NUMBER',
    component: <PricingField />,
    colSize: 12,
  },
  {
    field_type: 'DATE',
    component: <ReactDatepicker />,
    colSize: 12,
  },
  {
    field_type: 'TIME',
    component: <IdfFormInput />,
    colSize: 12,
  },
];
