import React from 'react';
import { Col, Row } from 'react-bootstrap';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import TenantThemeWrapperWidget from './TenantThemeWrapperWidget';

const IconSet = ({ icons }) => {
  // 2: means 3 icons and 1 means: 1 middle icon and light color around it
  const iconSetType = icons.length > 1 ? 2 : 1;
  return (
    <div
      className="position-absolute h-100 w-100"
      style={{ right: 20, top: 0 }}
    >
      {iconSetType === 2 ? (
        <div className="position-relative h-100 w-100">
          <div
            className="p-2 rounded-lg shadow-xl abs-center position-absolute"
            style={{
              background: 'var(--secondaryColor)',
            }}
          >
            <div className="z-index-5 d-flex">
              <MaterialIcon
                icon={icons[0]}
                clazz="text-white d-flex font-size-2xl"
              />
            </div>
            <div
              className="p-2 rounded-lg shadow-sm border abs-center position-absolute"
              style={{
                background: '#FFFFFF',
                top: -55,
              }}
            >
              <MaterialIcon
                icon={icons[1]}
                clazz="text-gray-700 d-flex font-size-2xl"
              />
            </div>
            <div
              className="p-2 rounded-lg shadow-sm border position-absolute"
              style={{
                background: '#FFFFFF',
                top: -35,
                right: -55,
              }}
            >
              <MaterialIcon
                icon={icons[2]}
                clazz="text-gray-700 d-flex font-size-2xl"
              />
            </div>

            <div
              className="p-1 rounded position-absolute rpt-blue-box-lite"
              style={{
                width: 22,
                height: 22,
                right: -32,
                top: 22,
              }}
            >
              &nbsp;
            </div>
            <div
              className="p-1 rounded position-absolute rpt-blue-box-lite"
              style={{
                width: 22,
                height: 22,
                right: -32,
                top: -65,
              }}
            >
              &nbsp;
            </div>
            <div
              className="p-1 rounded position-absolute rpt-blue-box-lite"
              style={{
                width: 22,
                height: 22,
                left: -30,
                top: -15,
              }}
            >
              &nbsp;
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="position-relative h-100">
            <div
              className="p-2 rounded-lg abs-center-xy shadow-xl position-absolute"
              style={{
                background: 'var(--secondaryColor)',
              }}
            >
              <div
                className="p-1 z-index-2 rounded position-absolute rpt-blue-box-lite"
                style={{
                  bottom: -22,
                  left: -15,
                  width: 22,
                  height: 22,
                }}
              >
                &nbsp;
              </div>
              <div className="z-index-5 d-flex">
                <MaterialIcon
                  icon={icons[0]}
                  symbols
                  clazz="text-white d-flex font-size-3xl"
                />
              </div>

              <div
                className="p-1 rounded position-absolute rpt-blue-box-lite"
                style={{
                  top: -30,
                  right: -20,
                  width: 22,
                  height: 22,
                }}
              >
                &nbsp;
              </div>
              <div
                className="p-1 rounded position-absolute rpt-blue-box-lite"
                style={{
                  right: -10,
                  bottom: -30,
                  width: 22,
                  height: 22,
                }}
              >
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const IconSetWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <TenantThemeWrapperWidget
      bodyStyles="bg-primary-soft border-0 justify-content-center align-items-center d-flex p-0"
      cardStyles={{ minHeight: 200 }}
    >
      <Row
        className={`align-items-center mb-0 ${
          !whenPrinting ? 'p-3' : 'icon-set-widget'
        }`}
      >
        <Col
          className={`justify-content-center h-100 ${
            whenPrinting ? 'p-0' : 'px-3'
          }`}
        >
          <h5 className="text-left d-flex justify-content-between mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <p
            className={`mb-0 ${
              whenPrinting ? 'fs-9' : 'font-size-sm2'
            } text-left`}
          >
            <span
              dangerouslySetInnerHTML={{ __html: widgetConfig.description }}
            ></span>
          </p>
          {widgetConfig.source && (
            <WidgetSourceBlock text={widgetConfig.source} />
          )}
        </Col>
        <Col md={3}>
          <IconSet icons={widgetConfig.icons} />
        </Col>
      </Row>
    </TenantThemeWrapperWidget>
  );
};

export default IconSetWidget;
