import React, { useCallback, useContext, useMemo, useState } from 'react';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { AlertMessageContext } from '../../../contexts/AlertMessageContext';
import ReportService from '../../../services/report.service';
import { overflowing } from '../../../utils/Utils';
import { ActionTypes } from '../../../components/reports/reports.constants';
import { getReportName } from '../../../components/reports/reports.helper.functions';
const DeleteFraudReportModal = ({
  show,
  setShow,
  report,
  handleGenerateReport,
  setParentModalShow,
}) => {
  const { setSuccessMessage, setErrorMessage } =
    useContext(AlertMessageContext);

  const handleConfirmDeleteReport = async () => {
    try {
      // call delete api
      await ReportService.deleteReport(report.key || report.reportId);
      overflowing();
      setSuccessMessage('Report Deleted');
      setParentModalShow(false);
      setShow(false);
      handleGenerateReport(report, ActionTypes.REMOVE, report);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <DeleteConfirmationModal
      showModal={show}
      setShowModal={setShow}
      setSelectedCategories={[{ ...report, title: getReportName(report) }]}
      event={handleConfirmDeleteReport}
      itemsConfirmation={[{ ...report, title: getReportName(report) }]}
      description="Are you sure you want to delete this Report?"
      itemsReport={[]}
      setErrorMessage={setErrorMessage}
      setSuccessMessage={setSuccessMessage}
      positiveBtnText="Yes, Delete"
    />
  );
};

const useDeleteFraudReportModal = (
  handleGenerateReport,
  setParentModalShow
) => {
  const [showModal, setShowModal] = useState(false);
  const [report, setReport] = useState('');
  const DeleteFraudReportModalCallback = useCallback(() => {
    return (
      <DeleteFraudReportModal
        show={showModal}
        setShow={setShowModal}
        report={report}
        handleGenerateReport={handleGenerateReport}
        setParentModalShow={setParentModalShow}
      />
    );
  }, [showModal, setShowModal, report, setReport]);

  return useMemo(
    () => ({
      setShowModal,
      setReport,
      DeleteFraudReportModal: DeleteFraudReportModalCallback,
    }),
    [setShowModal, DeleteFraudReportModalCallback]
  );
};

export default useDeleteFraudReportModal;
