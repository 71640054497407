import RightPanelModal from '../../../components/modal/RightPanelModal';
import MaterialIcon from '../../commons/MaterialIcon';
import Write from '../../../pages/Write';
import { PROSPECT_RIGHT_PANEL_WIDTH } from '../../../utils/Utils';

const WriteAIPanel = ({ newsstandModal, setNewsstandModal, profileInfo }) => {
  return (
    <RightPanelModal
      showModal={newsstandModal}
      setShowModal={setNewsstandModal}
      profileInfo={profileInfo}
      containerWidth={PROSPECT_RIGHT_PANEL_WIDTH}
      Title={
        <div className="d-flex py-2 align-items-center">
          <MaterialIcon
            icon="draw"
            clazz="font-size-xl text-white bg-orange p-1 icon-circle mr-2"
          />
          <h4 className="mb-0">Write</h4>
        </div>
      }
    >
      <Write layout="vertical" customProspect={profileInfo} />
    </RightPanelModal>
  );
};

export default WriteAIPanel;
