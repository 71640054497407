import React from 'react';
import _ from 'lodash';
import MaterialIcon from '../../commons/MaterialIcon';

const ListItems = ({ items, deleteItem, onClear, customKey, options }) => {
  const findKeyByValue = (value) => {
    if (options) {
      return _.find(options, { value })?.key;
    }
    return value;
  };
  return (
    <div className="mt-3 w-100">
      <div>
        {items?.map((item, index) => {
          return (
            <div
              className="tag-item rounded align-items-center h-auto p-1 pl-2"
              key={index}
            >
              <span className="fw-normal fs-8 font-weight-semi-bold text-wrap">
                {customKey ? findKeyByValue(item) : item}
              </span>

              <button
                type="button"
                className="button ml-0"
                onClick={() => deleteItem(item)}
              >
                <MaterialIcon icon="close" />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListItems;
