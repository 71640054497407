import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';

const SolutionsData = [
  {
    text: 'Check Positive Pay',
    description: 'Block unauthorized checks from clearing your account.',
    icon: 'checkbook',
  },
  {
    text: 'ACH Fraud Filter',
    description: 'Block unauthorized ACH debits from clearing your account.',
    icon: 'account_balance',
  },
  {
    text: 'Dual Control',
    description:
      'Workflow solution that ensures two people are always approving.',
    icon: 'partner_exchange',
  },
];

const SolutionDataItem = ({ data }) => {
  return (
    <div className="d-flex flex-column flex-fill w-100 align-items-center">
      <div className="d-flex align-items-center bg-primary-soft rounded-circle justify-content-center p-2">
        <MaterialIcon
          icon={data.icon}
          symbols
          rounded={false}
          clazz="font-size-em text-primary"
        />
      </div>
      <div className="my-1 text-rpt-heading text-center w-100 font-weight-semi-bold fs-7">
        {data.text}
      </div>
      <p className="mb-0 fs-8 text-center">{data.description}</p>
    </div>
  );
};
const FraudSolutionsEmpowerWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center align-items-center d-flex">
        <div className="flex-fill w-100">
          <h5 className="text-left d-flex justify-content-between mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-2">
            <p
              className={`mb-0 ${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } text-left`}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: widgetConfig.description,
                }}
              ></span>
            </p>
            <div className="d-flex justify-content-center gap-2 align-items-center py-3">
              {SolutionsData.map((dd) => (
                <SolutionDataItem data={dd} key={dd.key} />
              ))}
            </div>
            {widgetConfig?.source && (
              <div className="mt-2">
                <WidgetSourceBlock text={widgetConfig.source} />
              </div>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default FraudSolutionsEmpowerWidget;
