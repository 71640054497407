import React, { useState, useEffect } from 'react';
import { newsList, newsTimeRangeList } from '../constants';
import SelectDefault from '../../../commons/SelectDefault';

const NewsTypeFilter = ({
  data = [],
  setData,
  keyType,
  keyFilter,
  typeKeyType,
  typeKeyFilter,
  onEnter,
}) => {
  const [refresh, setRefresh] = useState(0);
  const [selected, setSelected] = useState();
  const [selectedMethods, setSelectedMethods] = useState([]);

  const updateNewsTimeRange = (e) => {
    const type = e.target.value;
    if (selected !== type) {
      const payload = {
        ...data,
        [typeKeyType]: {
          ...data[typeKeyType],
          [typeKeyFilter]: type,
        },
      };
      setSelected(type);
      setData({ type: 'set', payload });
      setRefresh((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    onEnter();
  }, [refresh]);

  const handleCheckboxChange = (method) => {
    setSelectedMethods((prevSelected) => {
      const updatedSelected = prevSelected.includes(method)
        ? prevSelected.filter((item) => item !== method)
        : [...prevSelected, method];

      // Call updateNewsCategory with the updated list of selected methods
      updateNewsCategory(updatedSelected);

      return updatedSelected;
    });
  };

  const updateNewsCategory = (filters) => {
    const payload = {
      ...data,
      [keyType]: {
        [keyFilter]: filters,
      },
    };
    setData({ type: 'set', payload });
    setRefresh((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (data[keyType] && data[keyType][keyFilter]) {
      setSelectedMethods(data[keyType][keyFilter]);
    }
  }, [data, keyType, keyFilter]);

  useEffect(() => {
    const filterData = data[typeKeyType][typeKeyFilter];
    if (data[typeKeyType] && filterData) {
      setSelected(filterData);
    } else {
      setSelected();
    }
  }, [data, typeKeyType, typeKeyFilter]);

  return (
    <>
      <div className="mt-2 mb-2 text-capitalize font-weight-semi-bold">
        Category
      </div>
      <div className="d-flex flex-column gap-1 pl-4">
        {newsList?.map((item) => (
          <div
            className="d-flex align-items-center position-relative"
            key={item.key}
          >
            <input
              type="checkbox"
              id={item.key}
              checked={selectedMethods?.includes(item.value)}
              onChange={() => handleCheckboxChange(item.value)}
              className="form-check-input cursor-pointer my-0"
            />
            <label htmlFor={item.key} className="my-0">
              {item.value}
            </label>
          </div>
        ))}
      </div>

      <div className="mt-3 mb-2 text-capitalize font-weight-semi-bold">
        Time Range
      </div>

      <SelectDefault
        id={typeKeyFilter}
        value={selected}
        onChange={updateNewsTimeRange}
        items={newsTimeRangeList?.map(({ key, value }) => ({
          title: key,
          value,
        }))}
      />
    </>
  );
};

export default NewsTypeFilter;
