import React from 'react';
import TenantTable from '../../components/Tenant/TenantTable';

const AllTenant = () => {
  return (
    <>
      <TenantTable />
    </>
  );
};
export default AllTenant;
