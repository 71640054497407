import { numbersWithComma, parseCurrency } from '../../utils/Utils';

export const calculateTotalFees = (summaryPageData) => {
  const totalAnalyzedFeeBasedCharge = Object.hasOwn(
    summaryPageData,
    'totalAnalyzedFeeBasedCharge'
  )
    ? summaryPageData.totalAnalyzedFeeBasedCharge
    : '$0';
  const totalAnalyzedCharge = Object.hasOwn(
    summaryPageData,
    'totalAnalyzedCharge'
  )
    ? summaryPageData.totalAnalyzedCharge
    : '$0';

  const totalFees =
    parseCurrency(totalAnalyzedFeeBasedCharge) +
    parseCurrency(totalAnalyzedCharge);
  return totalFees === 0 ? '$0' : numbersWithComma(totalFees);
};
