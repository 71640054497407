import AddNewButton from './AddNewButton';
import SectionWrapper from './SectionWrapper';
import { Col, Row } from 'reactstrap';
import ActionButtons from './tabs/ActionButtons';
import React, { useEffect, useState } from 'react';
import NoDataFoundTitle from '../../fields/NoDataFoundTitle';
import NoDataFound from '../../commons/NoDataFound';
import useDeleteModal from './modals/useDeleteModal';
import useAddNewAuthorizedUserModal from './modals/AddNewAuthorizedUser';
import { usePagesContext } from '../../../contexts/pagesContext';
import { AccountStructureTabMap } from './account.structure.constants';
import WhiteLabelName from './WhiteLabelName';

const AuthorizedUsers = ({ report, setReport }) => {
  const { pageContext, setPageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  const [authUsers, setAuthUsers] = useState(
    pageContext?.AccountStructureReportModal[jsonKey]?.authorizedUsers || []
  );
  const [selectedAuthUser, setSelectedAuthUser] = useState({});
  const {
    DeleteModal,
    setData,
    setShowModal: setShowDeleteModal,
  } = useDeleteModal({
    description: 'Are you sure you want to delete this Authorized user?',
    successMsg: 'Authorized user deleted.',
    deleteCallback: async (authUser) => {
      const newAuthUsers = [...authUsers].filter((f) => f.id !== authUser.id);
      setAuthUsers(newAuthUsers);
    },
  });
  const handleEditAuthUser = (authUser) => {
    setSelectedAuthUser(authUser);
    setShowModal(true);
  };
  const handleDeleteAuthUser = (authUser) => {
    setData({
      ...authUser,
      title: authUser.name,
    });
    setShowDeleteModal(true);
  };
  const { setShowModal, AddNewAuthorizedUserModal } =
    useAddNewAuthorizedUserModal(selectedAuthUser, authUsers, setAuthUsers);

  useEffect(() => {
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: {
        ...pageContext.AccountStructureReportModal,
        [jsonKey]: {
          ...pageContext.AccountStructureReportModal[jsonKey],
          authorizedUsers: authUsers,
        },
      },
    });
  }, [authUsers]);
  return (
    <div className="w-100">
      <DeleteModal />
      <AddNewAuthorizedUserModal />
      <div className="d-flex mb-2 justify-content-end align-items-center">
        <AddNewButton
          label="Add User"
          onClick={() => {
            setSelectedAuthUser({});
            setShowModal(true);
          }}
        />
      </div>
      <SectionWrapper columns={['Names', 'Roles']}>
        {authUsers.length ? (
          <>
            {authUsers.map((authUser) => (
              <Row
                key={authUser.id}
                className="fs-7 bg-hover-gray mx-0 py-2 px-0 cursor-pointer border-bottom border-white"
              >
                <Col md={6} className="pl-2 align-self-center">
                  <WhiteLabelName wrap={true}>{authUser.name}</WhiteLabelName>
                </Col>
                <Col md={6} className="align-self-center">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>{authUser.role?.name}</span>
                    <ActionButtons
                      onEdit={() => handleEditAuthUser(authUser)}
                      onDelete={() => handleDeleteAuthUser(authUser)}
                    />
                  </div>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <NoDataFound
            icon="people"
            iconRounded={true}
            iconStyle="font-size-2em"
            containerStyle="text-gray-search mt-2 pt-2 mb-1"
            title={
              <NoDataFoundTitle clazz="fs-7 mb-0" str={`No authorized users`} />
            }
          />
        )}
      </SectionWrapper>
    </div>
  );
};
export default AuthorizedUsers;
