import React from 'react';

const RocketReachLocation = ({ prospect, fontSize = 'font-size-sm2' }) => {
  return (
    <>
      {prospect.location ? (
        <p
          className={`prospect-typography-h6 text-wrap p-0 m-0 ${fontSize}`}
          style={{ maxWidth: 150 }}
        >
          <span>
            {prospect.location ? (
              <span>{prospect.location}</span>
            ) : (
              <span>
                {prospect.city && <span>{prospect.city}</span>}
                {prospect.state && (
                  <span>
                    {prospect.city && ','} {prospect.state}
                  </span>
                )}
                {prospect.country && <span>{prospect.country}</span>}
              </span>
            )}
          </span>
        </p>
      ) : (
        ''
      )}
    </>
  );
};
export default RocketReachLocation;
