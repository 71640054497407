import React, { useEffect, useState } from 'react';
import { overflowing } from '../../utils/Utils';
import SimpleModalCreation from '../../components/modal/SimpleModalCreation';
import { FormGroup, Label } from 'reactstrap';
import SicNaicsAutoComplete from '../prospecting/v2/common/SicNaicsAutoComplete';
import organizationService from '../../services/organization.service';
import ValidationErrorText from '../commons/ValidationErrorText';

const EditNaicsModal = ({
  show,
  setShow,
  profileInfo,
  getProfileInfo,
  setSuccessMessage = () => {},
  isRequired = true,
}) => {
  const [data, setData] = useState(profileInfo);
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsError(false);
    if (profileInfo.naics_code && profileInfo.industry) {
      setData({
        ...profileInfo,
        valueN: [`(${profileInfo.naics_code}) ${profileInfo.industry}`],
      });
    } else {
      setData({
        ...profileInfo,
        valueN: [],
      });
    }
  }, [profileInfo, show]);

  const onHandleCloseModal = () => {
    if (!loading) {
      overflowing();
      setShow(!show);
    }
  };

  const handleUpdate = async () => {
    const { valueN, naics_code, industry, ...rest } = data;
    const updateFields = {
      ...rest,
      naics_code: isRequired
        ? naics_code || profileInfo.naics_code
        : naics_code,
      industry: isRequired ? industry || profileInfo.industry : industry,
    };

    if (isRequired && (!naics_code || !industry)) {
      setIsError(true);
    } else {
      setLoading(true);
      try {
        await organizationService.updateOrganization(
          profileInfo.id,
          updateFields
        );
        getProfileInfo();
        setTimeout(() => {
          overflowing();
          setShow(false);
          setSuccessMessage('NAICS updated.');
        }, 1000);
      } catch (error) {
        if (error.response.status === 403) {
          getProfileInfo();
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <SimpleModalCreation
        modalHeaderClasses="flex-fill"
        modalTitle="Edit NAICS Information"
        saveButtonStyle="btn btn-primary btn-sm d-flex align-items-center"
        open={show}
        editFields={true}
        isLoading={loading}
        footerStyle="border-0"
        handleSubmit={handleUpdate}
        onHandleCloseModal={onHandleCloseModal}
      >
        <div className="d-flex flex-column gap-3-">
          <FormGroup>
            <Label for="title">NAICS</Label>
            <SicNaicsAutoComplete
              data={data}
              setData={setData}
              isRequired={isRequired}
              placeholder="Enter a NAICS code"
              customKey="valueN"
              callKey="naicsCodesList"
              callType="getNaicsCodes"
              isDisabled={loading}
              showFieldError={isError}
              onSelect={(item, naicsSicOnly, naicsTitle) => {
                setData({
                  ...data,
                  valueN: [item],
                  naics_code: naicsSicOnly,
                  industry: naicsTitle,
                });
                setIsError(false);
              }}
            />
            {isError && <ValidationErrorText text="NAICS is required." />}
          </FormGroup>
        </div>
      </SimpleModalCreation>
    </>
  );
};

export default EditNaicsModal;
