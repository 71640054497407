import React from 'react';
import { getYearFromMonth } from '../../utils/Utils';

// Function to calculate bar height
function calculateBarHeight(value, maxRange, chartHeight) {
  const scaledValue = value / maxRange;
  const barHeight = scaledValue * chartHeight;

  // Ensure that the bar height does not exceed the maximum chart height
  return Math.min(barHeight, chartHeight);
}
const HorizontalLines = ({
  count,
  lineHeight = 20,
  hideLineLimit,
  withIntervals,
}) => {
  const rowCount = Array(count).fill(0);
  return (
    <div>
      {rowCount.map((r, idx) => (
        <div
          key={idx}
          className={`border-bottom position-relative ${
            idx === 0 && !hideLineLimit ? 'border-gray-dark border-width-2' : ''
          }`}
          style={{ height: lineHeight }}
        >
          {withIntervals && (
            <>
              <span
                className="position-absolute d-block align-items-center justify-content-center text-center fs-12"
                style={{ left: -35, bottom: -5 }}
              >
                {withIntervals[idx]}
              </span>
              {idx === 0 && !hideLineLimit && (
                <span
                  className="position-absolute text-gray-dark fs-11 font-weight-bold"
                  style={{ top: 0, right: 0 }}
                >
                  Line Limit
                </span>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};
const VerticalBars = ({ barData, lineLimit, barStyle }) => {
  const maxHeight = 125;
  const maxValue = lineLimit;
  return (
    <div className="position-absolute w-100 bottom-0">
      <div className="d-flex align-items-end gap-2 justify-content-between">
        {barData.map((d, index) => (
          <div
            key={index}
            className={`position-relative bg-purple transition rounded-top ${d.clazz}`}
            style={{
              ...barStyle,
              height: `${Math.round(
                calculateBarHeight(d.value, maxValue, maxHeight)
              )}px`,
            }}
          >
            <div
              className="position-absolute text-nowrap abs-center fs-12 font-weight-semi-bold"
              style={{
                bottom: `-15px`,
              }}
            >
              {d.key || d.fullMonth.substring(0, 3)} {getYearFromMonth(d)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ReportBarChart = ({
  linesCount,
  barData,
  lineLimit,
  lineHeight = 20,
  chartStyle,
  barStyle,
  withIntervals,
  hideLineLimit,
}) => {
  return (
    <div className="position-relative w-100 m-auto" style={chartStyle}>
      <HorizontalLines
        count={linesCount}
        lineHeight={lineHeight}
        withIntervals={withIntervals}
        hideLineLimit={hideLineLimit}
      />
      <VerticalBars
        lineLimit={lineLimit}
        barData={barData}
        barStyle={barStyle}
      />
    </div>
  );
};

export default ReportBarChart;
