import React, { useContext, useState } from 'react';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import { TabsContext } from '../contexts/tabsContext';
import ModuleFields from './ModuleFields';
import { FieldTypeEnum } from '../components/fields/fields.constants';
import { useModuleContext } from '../contexts/moduleContext';
import { isModuleAllowed } from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';

const FieldsTab = {
  ModuleFields: 1,
  PipelineFields: 2,
};

const Fields = () => {
  const { moduleMap } = useModuleContext();
  const { tenant } = useTenantContext();

  const [activeTabId, setActiveTabId] = useState(
    isModuleAllowed(tenant?.modules, 'settings_fields_module_fields')
      ? FieldsTab.ModuleFields
      : isModuleAllowed(tenant?.modules, 'settings_fields_pipeline_fields')
      ? FieldsTab.PipelineFields
      : ''
  );
  const { setActivatedTab } = useContext(TabsContext);
  const toggle = (tabId) => {
    if (activeTabId !== tabId) {
      setActiveTabId(tabId);
      setActivatedTab({
        [location.pathname]: tabId,
      });
    }
  };

  const tabsData = [
    {
      title: 'Module Fields',
      tabId: FieldsTab.ModuleFields,
      byModule: 'settings_fields_module_fields',
    },
    {
      title: `${
        moduleMap.deal === undefined ? '' : moduleMap.deal.singular
      } Fields`,
      tabId: FieldsTab.PipelineFields,
      byModule: 'settings_fields_pipeline_fields',
    },
  ];

  return (
    <div className="bg-setting-menu">
      <div className="mb-2 bg-white">
        <AnimatedTabs
          tabsData={tabsData}
          activeTab={activeTabId}
          tabClasses="px-4 bg-white pt-1"
          borderClasses="border-bottom"
          toggle={(tab) => toggle(tab.tabId)}
        />
      </div>
      {activeTabId === FieldsTab.ModuleFields &&
        isModuleAllowed(tenant?.modules, 'settings_fields_module_fields') && (
          <ModuleFields filter={(f) => f.type !== FieldTypeEnum.deal} />
        )}
      {activeTabId === FieldsTab.PipelineFields &&
        isModuleAllowed(tenant?.modules, 'settings_fields_pipeline_fields') && (
          <ModuleFields filter={(f) => f.type === FieldTypeEnum.deal} />
        )}
    </div>
  );
};

export default Fields;
