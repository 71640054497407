import React from 'react';
import HoursSpentWeeklyWidget from '../../../reportbuilder/widgets/horizontal/HoursSpentWeeklyWidget';
import {
  HoursSpentWeeklyData,
  HSWRData,
} from '../../../reportbuilder/constants/widgetsConstants';
import HoursSpentWeeklyReconcilingPaymentsWidget from '../../../reportbuilder/widgets/horizontal/HoursSpentWeeklyReconcilingPaymentsWidget';
import { usePagesContext } from '../../../../contexts/pagesContext';
import {
  AccountSchematicReportSections,
  AccountStructureTabMap,
} from '../account.structure.constants';

const Widget = () => {
  return (
    <div className="mt-0">
      <HoursSpentWeeklyReconcilingPaymentsWidget widgetConfig={HSWRData} />
    </div>
  );
};

const AccountsReceivables = ({ report }) => {
  const { pageContext } = usePagesContext();
  const accountStructure =
    report?.AccountStructureReport || pageContext.AccountStructureReport;
  const showWidget =
    accountStructure[AccountStructureTabMap.Widgets.key][
      AccountSchematicReportSections.AccountsReceivable
    ]?.isActive;
  const showAutomatingWidget =
    accountStructure[AccountStructureTabMap.Widgets.key][
      AccountSchematicReportSections.AccountsReceivableAutomating
    ]?.isActive;
  return (
    <>
      {showAutomatingWidget || showWidget ? (
        <>
          {showAutomatingWidget && (
            <div className={`${showWidget ? 'mb-3' : 'mb-0'}`}>
              <HoursSpentWeeklyWidget widgetConfig={HoursSpentWeeklyData} />
            </div>
          )}
          {showWidget && <Widget />}
        </>
      ) : null}
    </>
  );
};

export default AccountsReceivables;
