import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import BrandLogoIcon from './BrandLogoIcon';
import { TenantContext } from '../../contexts/TenantContext';

function SidebarIcon() {
  const { tenant } = useContext(TenantContext);
  const history = useHistory();

  return (
    <div className="pl-2 pr-4">
      <a
        className="text-center cursor-pointer"
        onClick={() => history.push('/')}
        aria-label="Identifee"
      >
        <BrandLogoIcon tenant={tenant} width="100%" />
      </a>
    </div>
  );
}

export default SidebarIcon;
