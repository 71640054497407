import axios from 'axios';
import authHeader from './auth-header';
const API_URL = process.env.REACT_APP_API_URL;

class AssignmentService {
  async createAssignment(data) {
    return axios
      .post(`${API_URL}/api/assignments`, data, {
        headers: authHeader(),
      })
      .then((response) => response.data);
  }

  async getAssignments(params = {}) {
    const response = await axios.get(`${API_URL}/api/assignments`, {
      headers: authHeader(),
      params,
    });

    return response.data;
  }

  async getUserAssignments(assignmentId, params = {}) {
    const response = await axios.get(
      `${API_URL}/api/assignments/${assignmentId}/users`,
      {
        headers: authHeader(),
        params,
      }
    );

    return response.data;
  }

  async createAssignmentContent(assignmentId, data) {
    const response = await axios.put(
      `${API_URL}/api/assignments/${assignmentId}/contents`,
      data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  }

  async getAssignmentById(assignmentId, params = {}) {
    const response = await axios.get(
      `${API_URL}/api/assignments/${assignmentId}`,
      {
        headers: authHeader(),
        params,
      }
    );

    return response.data;
  }

  async updateAssignmentById(assignmentId, data) {
    const response = await axios.put(
      `${API_URL}/api/assignments/${assignmentId}`,
      data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  }

  deleteAssignment(assignmentId) {
    return axios
      .delete(`${API_URL}/api/assignments/${assignmentId}`, {
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((e) => console.log(e));
  }

  async upsertAssignedEntries(assignmentId, data) {
    const response = await axios.put(
      `${API_URL}/api/assignments/${assignmentId}/assign`,
      data,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  }

  async getTeamAssignment(assignmentId, params = {}) {
    const response = await axios.get(
      `${API_URL}/api/assignments/${assignmentId}/teams`,
      {
        headers: authHeader(),
        params,
      }
    );

    return response.data;
  }
}

export default new AssignmentService();
