import React from 'react';

import SiteSettingsForm from '../components/siteSettings/SiteSettingsForm';

const SiteSettings = () => {
  return (
    <>
      <SiteSettingsForm />
    </>
  );
};

export default SiteSettings;
