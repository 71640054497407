import { Col, Row } from 'reactstrap';
import React from 'react';
import SectionWrapper from '../SectionWrapper';
import AccountStructureWidgetWrapper from './AccountStructureWidgetWrapper';
import { usePagesContext } from '../../../../contexts/pagesContext';
import { AccountStructureTabMap } from '../account.structure.constants';
import WhiteLabelName from '../WhiteLabelName';
import BankContact from '../BankContact';

const Widget = ({ contacts, whenPrinting }) => {
  return (
    <>
      {contacts.length ? (
        <AccountStructureWidgetWrapper className="mt-3">
          <h5 className="mb-1">Bank Contacts</h5>
          <SectionWrapper
            columns={['Contact Name', 'Contact Details']}
            containerClasses="card card-body rounded-lg report-widget"
          >
            <div className="px-3">
              <>
                {contacts?.map((acnt) => (
                  <BankContact key={acnt.id} acnt={acnt} />
                ))}
              </>
            </div>
          </SectionWrapper>
        </AccountStructureWidgetWrapper>
      ) : null}
    </>
  );
};

const AccountNumbers = ({ accounts }) => {
  const splitted = accounts.length > 0 ? accounts.split(', ') : [];
  return (
    <>
      {splitted.length > 0 ? (
        <>
          {splitted.map((nme) => (
            <WhiteLabelName key={nme}>{nme}</WhiteLabelName>
          ))}
        </>
      ) : null}
    </>
  );
};

const SignersAndBankContacts = ({ report }) => {
  const { pageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  const whenPrinting = !!report;
  const accountStructure =
    report?.AccountStructureReport || pageContext.AccountStructureReport;
  const { authorizedSigners, authorizedUsers, bankContacts } =
    accountStructure[jsonKey];
  return (
    <>
      {authorizedSigners.length || authorizedUsers.length ? (
        <AccountStructureWidgetWrapper>
          {authorizedSigners.length ? (
            <>
              <h5 className="mb-1">Authorized Signers</h5>
              <div className="px-2 pb-3">
                <SectionWrapper
                  columns={['Names', 'Account Numbers']}
                  containerClasses="card card-body rounded-lg report-widget"
                >
                  {authorizedSigners.map((signer) => (
                    <Row
                      key={signer.id}
                      className={`${
                        whenPrinting ? 'fs-9' : 'fs-7'
                      } bg-hover-gray mx-0 py-2 px-0 border-bottom border-white`}
                    >
                      <Col md={6} className="pl-2 align-self-center">
                        <div className="d-flex flex-wrap gap-1">
                          {signer.names.map((nme) => (
                            <WhiteLabelName key={nme.id}>
                              {nme.value}
                            </WhiteLabelName>
                          ))}
                        </div>
                      </Col>
                      <Col md={6} className="align-self-center">
                        <div className="d-flex flex-wrap gap-1">
                          <AccountNumbers accounts={signer.accountNumber} />
                        </div>
                      </Col>
                    </Row>
                  ))}
                </SectionWrapper>
              </div>
            </>
          ) : null}
          {authorizedUsers.length ? (
            <>
              <h5 className="mb-1">Authorized Users</h5>
              <div className="px-2">
                <SectionWrapper
                  columns={['Names', 'Roles']}
                  containerClasses="card card-body rounded-lg report-widget"
                >
                  {authorizedUsers.map((authUser) => (
                    <Row
                      key={authUser.id}
                      className={`${
                        whenPrinting ? 'fs-9' : 'fs-7'
                      } bg-hover-gray mx-0 py-2 px-0 border-bottom border-white`}
                    >
                      <Col md={6} className="pl-2 align-self-center">
                        <WhiteLabelName wrap={true}>
                          {authUser.name}
                        </WhiteLabelName>
                      </Col>
                      <Col md={6} className="align-self-center">
                        <div className="d-flex align-items-center justify-content-between">
                          <span>{authUser.role?.name}</span>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </SectionWrapper>
              </div>
            </>
          ) : null}
        </AccountStructureWidgetWrapper>
      ) : null}
      <Widget contacts={bankContacts} whenPrinting={whenPrinting} />
    </>
  );
};

export default SignersAndBankContacts;
