import { useEffect, useState } from 'react';
import { cubeService } from '../../../../../services';
import NoDataFound from '../../../../../components/commons/NoDataFound';
import Loading from '../../../../../components/Loading';
import InsightStatItem from '../../../InsightStatItem';

const AssignmentStats = {
  completed: 'Lessons - Total Complete',
  uniqueCompleted: 'Lessons - Unique Complete',
  taken: 'Lessons - Self Study',
  taken_assigned: 'Lessons - Assigned',
};

const TooltipTitlesGet = {
  'Lessons - Total Complete': 'Lessons completed including repeats',
  'Lessons - Unique Complete': 'Unique lessons completed',
  'Lessons - Assigned':
    '# of lessons lessons completed that were not part of a required assignment',
  'Lessons - Self Study':
    '# of lessons completed as part of a required assignment',
};

const UserTranscriptStats = ({ userFilters = [] }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const filterData = userFilters?.length > 0 && userFilters[0]?.values;
  const filters = userFilters.filter((item) => item?.values);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await cubeService.loadCustomAnalytics({
        type: 'UserTranscriptTickers',
        users: filters[0]?.values,
      });
      setData(results);
      setLoading(false);
    })();
  }, [filterData]);

  const updatedData =
    data?.length > 0 &&
    Object.entries(data[0])?.reduce((acc, [key, value]) => {
      if (key === 'lessonsCompleted') {
        acc.uniqueCompleted = value;
      } else if (key === 'taken') {
        acc.completed = value;
        acc.taken = value;
      } else if (key === 'taken_assigned') {
        acc.taken_assigned = value;
      }
      return acc;
    }, {});

  const sortedUpdatedData =
    data?.length > 0 &&
    Object.keys(AssignmentStats)?.reduce((obj, key) => {
      obj[key] = updatedData[key] || '';
      return obj;
    }, {});

  return (
    <div className="w-100 px-3 mb-2">
      <div className="row pt-3 px-2">
        {loading ? (
          <Loading />
        ) : data?.length > 0 ? (
          Object.entries(sortedUpdatedData).map(([key, value]) => {
            return (
              <>
                <InsightStatItem
                  heading={AssignmentStats[key]}
                  tooltipData={TooltipTitlesGet}
                  stat={
                    key === 'taken'
                      ? value - sortedUpdatedData?.taken_assigned
                      : value
                  }
                  hideAverage
                  customClass="col-md-3 px-2"
                />
              </>
            );
          })
        ) : (
          <NoDataFound title="Data no found" />
        )}
      </div>
    </div>
  );
};

export default UserTranscriptStats;
