import React, { useState, useEffect } from 'react';

import SimpleModal from '../../modal/SimpleModal';
import AutoComplete from '../../AutoComplete';
import userService from '../../../services/user.service';

const UpdateContact = ({
  moduleMap,
  getContacts,
  showAddContactModal,
  setShowAddContactModal,
  children,
  selectedItem,
  setSelectedItem,
  handleSubmit,
}) => {
  const [filter, setFilter] = useState('');
  const [data, setData] = useState([]);
  const [isNewContact, setIsNewContact] = useState(false);

  const searchContacts = async () => {
    try {
      const { users } = await userService.getUser({
        limit: 100,
        page: 1,
        status: 'active',
        search: filter,
      });

      setData(users);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (item) => {
    setSelectedItem(item.id);
  };

  const handleCloseAddContactModal = () => {
    setShowAddContactModal(false);
  };

  useEffect(() => {
    if (showAddContactModal) {
      searchContacts();
    } else {
      setSelectedItem(null);
      getContacts();
      setFilter('');
      setIsNewContact(false);
    }
  }, [showAddContactModal]);

  useEffect(() => {
    if (filter) {
      searchContacts();
    }
  }, [filter]);

  return (
    <>
      <SimpleModal
        onHandleCloseModal={handleCloseAddContactModal}
        open={showAddContactModal}
        modalTitle={`Update Owner`}
        buttonLabel={`Update Owner`}
        buttonsDisabled={!isNewContact && !selectedItem}
        handleSubmit={handleSubmit}
      >
        <AutoComplete
          id="assigned_user_id"
          placeholder={`Search for User`}
          name="assigned_user_id"
          showAvatar={true}
          loading={false}
          onChange={(e) => {
            setFilter(e?.target?.value);
          }}
          data={data}
          showIcon={false}
          onHandleSelect={(item) => {
            handleSelect(item);
          }}
          customKey="name"
          showEmail={true}
        />
      </SimpleModal>
      {children}
    </>
  );
};

export default UpdateContact;
