export const sortingTable = ({ name, order, setOrder }, lesson) => {
  const variantName = {
    badge: {
      field: 'name',
    },
    category: {
      field: 'title',
    },
    label: {
      field: 'name',
    },
    parent: {
      field: 'name',
    },
    organization: {
      field: 'name',
    },
    contact: {
      field: 'first_name',
    },
  };

  const orderByKey = typeof name === 'string' ? name : name.orderBy;
  const orderByClicked = typeof name === 'string' ? name : name.clicked;
  if (!orderByKey || orderByKey === 'owner') return false;

  if (variantName[orderByKey]) {
    const [key, field] = order;

    if (key === orderByKey) {
      return setOrder([
        orderByKey,
        field || variantName[orderByKey].field,
        orderByClicked,
      ]);
    }

    return setOrder([
      orderByKey,
      variantName[orderByKey].field,
      orderByClicked,
    ]);
  }

  const [key] = lesson ? order[0] : order;

  if (key === orderByKey) {
    if (orderByKey === 'role' || orderByKey === 'group') {
      const [key] = order;
      return setOrder([key, orderByClicked]);
    }

    return setOrder([key, orderByClicked]);
  }

  if (orderByKey === 'role' || orderByKey === 'group') {
    return setOrder([orderByKey, orderByClicked]);
  }
  setOrder([orderByKey, orderByClicked]);
};
