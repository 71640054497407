import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import Avatar from '../../Avatar';
import IdfAllAdditionalOwnersList from './IdfAllAdditionalOwnersList';
import TooltipComponent from '../../lesson/Tooltip';

const IdfAdditionalOwnersListDropdown = ({
  service,
  serviceId,
  maxOwners,
  maxCount = 9,
  refreshOwners,
  defaultSize = 'sm',
  className = '',
  setRefresOwners,
  prevalueCount,
  mainOwner,
  prevalue,
  preOwners,
  filteredIcons = false,
  ...props
}) => {
  const [openAllOwners, setOpenAllOwners] = useState(false);
  const [owners, setOwners] = useState([]);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (prevalue && prevalueCount) {
      setCount(prevalueCount);
      setOwners(preOwners);
    }
  }, [prevalueCount]);

  useEffect(() => {
    if (serviceId) onGetOwners();
  }, [serviceId, refreshOwners]);

  const ownersService = async (pagination) => {
    return await service
      .getOwners(serviceId, pagination)
      .catch((err) => console.log(err));
  };

  const onGetOwners = async () => {
    const resp = await ownersService({ page: 1, limit: 10 });

    const { data } = resp || {};
    const filteredData = data?.filter((item) => {
      return item?.user?.status !== 'deactivated';
    });
    setOwners(filteredData);
    setCount(filteredData?.length);
  };

  const getListOfOwnerNames = () => {
    const ownerNames = owners?.map((owner, i) =>
      owner.user.first_name
        ? `${owner.user.first_name} ${owner.user.last_name}`
        : `${owner.user.email}`
    );
    return ownerNames;
  };

  return (
    <Dropdown
      show={show}
      drop="up"
      onToggle={(isOpen, event, metadata) => {
        if (metadata.source !== 'select') {
          setShow(isOpen);
        }
      }}
    >
      <TooltipComponent list={getListOfOwnerNames()}>
        <Dropdown.Toggle
          className={`btn btn-icon btn-${defaultSize} btn-ghost-primary section-owners-header icon-ignore rounded-circle ${className}`}
        >
          <div className="d-flex gap-0">
            <span className={`fw-bold border-0`}>+</span>
            <span className={`fw-bold border-0`}>{count - maxOwners}</span>
          </div>
        </Dropdown.Toggle>
      </TooltipComponent>
      <Dropdown.Menu className="border z-index-100 position-fixed border-1 py-1">
        {owners?.slice(maxOwners, owners.length).map((owner, i) => (
          <div key={owner.user_id}>
            <Dropdown.Item
              data-testid={owner.user_id}
              className="d-flex align-items-center gap-1 z-index-250 px-2"
            >
              <div className="avatar avatar-sm d-flex align-items-center justify-content-center avatar-circle">
                <Avatar
                  defaultSize="xs"
                  user={owner.user}
                  style={
                    filteredIcons
                      ? {
                          border: `${
                            props.clicked &&
                            props.clicked.includes(i + maxOwners)
                              ? '2px solid blue'
                              : ''
                          }`,
                          padding: '2px',
                        }
                      : {}
                  }
                />
              </div>
              <h6 className="mb-0">
                {owner.user.first_name
                  ? `${owner.user.first_name} ${owner.user.last_name}`
                  : `${owner.user.email}`}
              </h6>
            </Dropdown.Item>
          </div>
        ))}
        {count > maxCount && (
          <IdfAllAdditionalOwnersList
            openAllOwners={openAllOwners}
            setOpenAllOwners={setOpenAllOwners}
            service={service}
            serviceId={serviceId}
            count={count}
            refreshOwners={refreshOwners}
            setRefresOwners={setRefresOwners}
            {...props}
          >
            {filteredIcons || (
              <Dropdown.Item>
                <div onClick={() => setOpenAllOwners(true)}>
                  View all
                  <span className="material-symbols-rounded fs-5">
                    chevron_right
                  </span>
                </div>
              </Dropdown.Item>
            )}
          </IdfAllAdditionalOwnersList>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default IdfAdditionalOwnersListDropdown;
