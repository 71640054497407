import React from 'react';
import ReportBlockWrapper from '../reports/ReportBlockWrapper';
import IconHeadingBlock from './blocks/IconHeadingBlock';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { ReportTypes } from '../reports/reports.constants';
import Accounts from '../reports/account-structure/pages/Accounts';
import { AccountSchematicReportSections } from '../reports/account-structure/account.structure.constants';
import BalanceTrends from '../reports/account-structure/pages/BalanceTrends';
import AccountsPayables from '../reports/account-structure/pages/AccountsPayables';
import AccountsReceivables from '../reports/account-structure/pages/AccountsReceivables';
import SignersAndBankContacts from '../reports/account-structure/pages/SignersAndBankContacts';
import WorkingCapitalDPO from '../reports/account-structure/pages/WorkingCapitalDPO';
import WorkingCapitalDSO from '../reports/account-structure/pages/WorkingCapitalDSO';
import FundsFlow from '../reports/account-structure/pages/FundsFlow';
import { chunkArray } from '../../utils/Utils';

const Page = ({ report, pageNumber, heading, selectedTenant, children }) => {
  return (
    <ReportBlockWrapper
      showLogo={true}
      current={pageNumber}
      report={report.ReportInfo}
      reportType={ReportTypes.AccountStructure}
      selectedTenant={selectedTenant}
    >
      <div className="text-left">
        <br />
        <IconHeadingBlock
          heading={heading}
          showIcon={false}
          containerStyle="gap-1 justify-content-between px-5 mb-0"
          pageNumber={pageNumber}
          report={report}
          reportType={ReportTypes.AccountStructure}
        />
      </div>
      <ListGroup
        className={`list-group-no-gutters my-0 list-group-flush ${
          ReportTypes.AccountStructure === ReportTypes.WorkingCapital
            ? 'working-capital-report'
            : ''
        }`}
      >
        <ListGroupItem className="border-0 px-5 position-relative pb-0">
          {children}
        </ListGroupItem>
      </ListGroup>
    </ReportBlockWrapper>
  );
};

const getDynamicAccountStructurePages = (reportWithAccountStructure) => {
  const accounts =
    reportWithAccountStructure.AccountStructureReport.ReportDetails?.accounts ||
    [];
  const chunkSize = 4;
  if (accounts.length <= chunkSize) {
    return [
      {
        id: AccountSchematicReportSections.AccountStructure,
        heading: AccountSchematicReportSections.AccountStructure,
        isAvailable: true,
        component: <Accounts report={reportWithAccountStructure} />,
      },
    ];
  }

  const accountsChunks = chunkArray(accounts, chunkSize);
  return accountsChunks.map((chunk, index) => ({
    id: `${AccountSchematicReportSections.AccountStructure}-${index + 1}`,
    heading: AccountSchematicReportSections.AccountStructure,
    isAvailable: true,
    component: <Accounts report={reportWithAccountStructure} chunked={chunk} />,
  }));
};

// this wrap each section into pdf page
const ReportPagesAccountStructure = ({ report, selectedTenant }) => {
  const reportWithAccountStructure = { AccountStructureReport: report };
  const isWorkingCapitalActive = report?.WorkingCapital?.isActive;
  const isAccountReceivablesActive =
    report?.Widgets[AccountSchematicReportSections.AccountsReceivable]
      ?.isActive;
  const isAccountReceivablesActiveAutomating =
    report?.Widgets[AccountSchematicReportSections.AccountsReceivableAutomating]
      ?.isActive;

  let accountStructureReportPages = [
    {
      id: AccountSchematicReportSections.FundsFlow,
      heading: AccountSchematicReportSections.FundsFlow,
      isAvailable: true,
      component: <FundsFlow report={reportWithAccountStructure} />,
    },
    ...getDynamicAccountStructurePages(reportWithAccountStructure),
    {
      id: AccountSchematicReportSections.BalanceTrends,
      heading: AccountSchematicReportSections.BalanceTrends,
      isAvailable: true,
      component: (
        <BalanceTrends report={reportWithAccountStructure} hideHeading={true} />
      ),
    },
    {
      id: AccountSchematicReportSections.AccountsPayable,
      heading: AccountSchematicReportSections.AccountsPayable,
      isAvailable: true,
      component: <AccountsPayables report={reportWithAccountStructure} />,
    },
    {
      id: AccountSchematicReportSections.AccountsReceivable,
      heading: AccountSchematicReportSections.AccountsReceivable,
      isAvailable:
        isAccountReceivablesActive || isAccountReceivablesActiveAutomating,
      component: <AccountsReceivables report={reportWithAccountStructure} />,
    },
    {
      id: AccountSchematicReportSections.SignersUsersContacts,
      heading: AccountSchematicReportSections.SignersUsersContacts,
      component: <SignersAndBankContacts report={reportWithAccountStructure} />,
      isAvailable: true,
    },
  ];

  if (isWorkingCapitalActive) {
    accountStructureReportPages = [
      {
        id: AccountSchematicReportSections.FundsFlow,
        heading: AccountSchematicReportSections.FundsFlow,
        isAvailable: true,
        component: <FundsFlow report={reportWithAccountStructure} />,
      },
      ...getDynamicAccountStructurePages(reportWithAccountStructure),
      {
        id: AccountSchematicReportSections.BalanceTrends,
        heading: AccountSchematicReportSections.BalanceTrends,
        isAvailable: true,
        component: (
          <BalanceTrends
            report={reportWithAccountStructure}
            hideHeading={true}
          />
        ),
      },
      {
        id: AccountSchematicReportSections.WCDPO,
        heading: AccountSchematicReportSections.WCDPO,
        isAvailable: true,
        component: <WorkingCapitalDPO report={reportWithAccountStructure} />,
      },
      {
        id: AccountSchematicReportSections.AccountsPayable,
        heading: AccountSchematicReportSections.AccountsPayable,
        isAvailable: true,
        component: <AccountsPayables report={reportWithAccountStructure} />,
      },
      {
        id: AccountSchematicReportSections.WCDSO,
        heading: AccountSchematicReportSections.WCDSO,
        isAvailable: true,
        component: <WorkingCapitalDSO report={reportWithAccountStructure} />,
      },
      {
        id: AccountSchematicReportSections.AccountsReceivable,
        heading: AccountSchematicReportSections.AccountsReceivable,
        isAvailable:
          isAccountReceivablesActive || isAccountReceivablesActiveAutomating,
        component: <AccountsReceivables report={reportWithAccountStructure} />,
      },
      {
        id: AccountSchematicReportSections.SignersUsersContacts,
        heading: AccountSchematicReportSections.SignersUsersContacts,
        isAvailable: true,
        component: (
          <SignersAndBankContacts report={reportWithAccountStructure} />
        ),
      },
    ];
  }

  return (
    <>
      {accountStructureReportPages
        .filter((page) => page.isAvailable)
        .map((page, index) => (
          <Page
            key={page.id}
            report={report}
            heading={page.heading}
            selectedTenant={selectedTenant}
            pageNumber={index + 2}
          >
            {page.component}
          </Page>
        ))}
    </>
  );
};

export default ReportPagesAccountStructure;
