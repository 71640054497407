import { ChecklistStatuses } from '../../utils/checklist.constants';
import React from 'react';
import MaterialIcon from '../commons/MaterialIcon';

const ChecklistStatus = ({ item, fontSize }) => {
  const GreenItems = ['Completed', 'Included'];
  const RedItems = ['Overdue', 'Excluded'];
  return (
    <span
      className={`badge status-chk pr-2 label d-inline-flex align-items-center justify-content-center rounded-pill text-capitalize ${fontSize} ${
        GreenItems.includes(item?.status)
          ? ChecklistStatuses.Completed.color
          : item?.status === 'Not Started'
          ? ChecklistStatuses.Pending.color
          : RedItems.includes(item?.status)
          ? ChecklistStatuses.Deleted.color
          : ChecklistStatuses.InProgress.color
      } `}
    >
      <MaterialIcon
        filled
        size="fs-7"
        icon="fiber_manual_record"
        style={{
          color: GreenItems.includes(item?.status)
            ? 'var(--status-green-main)'
            : item?.status === 'Not Started'
            ? 'var(--status-gray-main)'
            : item?.status === 'In Progress'
            ? 'var(--status-yellow-main)'
            : RedItems.includes(item?.status)
            ? 'var(--status-red-main)'
            : 'var(--status-blue-main)',
        }}
      />{' '}
      <span>{item?.statusText || item?.status}</span>
    </span>
  );
};
export default ChecklistStatus;
