import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import TextRoundBlock from '../../blocks/TextRoundBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import { roundOrShowAll } from '../../../../utils/Utils';
import CutoutWidget from './CutoutWidget';

const TheOneDayDifferenceWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="align-items-center d-flex">
        <div className="w-100">
          <h5 className="text-left d-flex mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-1 widget-extra-padding">
            <p
              className={`mb-0 ${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } text-left`}
            >
              <span
                dangerouslySetInnerHTML={{ __html: widgetConfig.description }}
              ></span>
            </p>
            <CutoutWidget
              whenPrinting={whenPrinting}
              widgetConfig={widgetConfig}
              cutoutConfig={{
                text: `Just <br /> One Day`,
                icon: 'calendar_clock',
                bg: 'report-widget-inner-section-soft bg-white',
                iconConfig: {
                  fg: 'text-primary',
                  bg: 'bg-primary-soft',
                },
              }}
            >
              <TextRoundBlock
                big={roundOrShowAll(
                  Math.abs(widgetConfig?.data?.oneDayDPODiff)
                )}
                fill="border-none shadow-none border-0 flex-grow-1 w-50"
                small="Cash unlocked by improving your DPO by just one day"
                color="text-rpt-heading"
                centered="text-left px-2"
                bg="#fff"
              />
              <MaterialIcon
                icon="arrow_right_alt"
                rounded
                filled
                clazz="font-size-2em text-rpt-heading arrow-align-relative"
              />
              <TextRoundBlock
                big={roundOrShowAll(
                  Math.abs(widgetConfig?.data?.oneDayDSODiff)
                )}
                fill="border-none shadow-none flex-grow-1 border-0 w-50"
                centered="text-left px-2"
                color="text-rpt-heading"
                small="Cash unlocked by improving your DSO by just one day"
                bg="#fff"
              />
              <MaterialIcon
                icon="equal"
                rounded
                filled
                clazz="font-size-2em text-rpt-heading arrow-align-relative"
              />
              <TextRoundBlock
                big={roundOrShowAll(
                  Math.abs(widgetConfig?.data?.totalOneDayDiff)
                )}
                fill="border-none shadow-none flex-grow-1 border-0 w-50"
                color="text-rpt-heading"
                centered="text-left px-2"
                small="Cash unlocked by improving your DSO/DPO by just one day"
                bg="#fff"
              />
            </CutoutWidget>
            {widgetConfig.source && (
              <WidgetSourceBlock text={widgetConfig.source} />
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default TheOneDayDifferenceWidget;
