const ReportPDFWrapper = ({ classes, selector, children }) => {
  return (
    <div
      className="position-absolute opacity-0 h-100"
      style={{ left: '-999', width: 682 }}
    >
      <div className={`${classes} ${selector}`} id="rptPdf">
        {children}
      </div>
    </div>
  );
};

export default ReportPDFWrapper;
