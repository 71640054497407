import {
  checkAndAllowFileUpload,
  VALID_FILE_FORMATS,
} from '../../../utils/constants';
import MaterialIcon from '../../commons/MaterialIcon';
import { FILE_SIZE_LIMIT, getFileSize } from '../../../utils/Utils';

const IdfNoteUploadFiles = ({ setErrorMessage, setFileInput }) => {
  const onFileChange = (event) => {
    const file = event?.target?.files[0];

    const fileSize = file.size;
    const errors = [];

    // if file exceeds limit
    if (fileSize > FILE_SIZE_LIMIT) {
      errors.push(
        `File size exceeds ${getFileSize(FILE_SIZE_LIMIT, true)} limit.`
      );
    }
    // if file type not allowed
    if (!checkAndAllowFileUpload(file)) {
      errors.push(
        `Invalid file format. Upload file in these ${VALID_FILE_FORMATS.join(
          ','
        )} format.`
      );
    }

    if (errors.length) {
      return setErrorMessage(errors.join('\n'));
    }
    const files = [...event.target.files];
    return setFileInput((prevData) => prevData.concat(files));
  };

  return (
    <div className="position-relative">
      <>
        <h5 className="mb-0 font-sm d-flex gap-1 align-items-center add-title">
          <MaterialIcon icon="attachment" /> Attach File
        </h5>
        <input
          className="file-input-drag"
          type="file"
          name="file"
          accept={VALID_FILE_FORMATS.join(',')}
          multiple
          onChange={onFileChange}
          id="file"
        />
      </>
    </div>
  );
};

export default IdfNoteUploadFiles;
