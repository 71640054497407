import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import { PFData } from '../../constants/widgetsConstants';
import DigitalDonutChart from '../../../../views/Overview/dashboard/components/DigitalDonutChart';

const DONUT_CHART_DATA = [
  {
    text: 'Checks',
    percentage: '63%',
    key: 'checks',
    chart: {
      data: [63, 37],
      colors: ['#e60808', '#eee'],
    },
  },
  {
    text: 'Wire',
    percentage: '31%',
    key: 'wire',
    chart: {
      data: [31, 69],
      colors: ['#258e13', '#eee'],
    },
  },
  {
    text: 'ACH Credits / Debits',
    percentage: '30%',
    key: 'ach',
    chart: {
      data: [30, 70],
      colors: ['#258e13', '#eee'],
    },
  },
  {
    text: 'Virtual Card',
    percentage: '9%',
    key: 'cards',
    chart: {
      data: [9, 91],
      colors: ['#258e13', '#eee'],
    },
  },
];
const ReportDonutChart = ({ data, whenPrinting }) => {
  return (
    <div className="d-flex flex-column flex-fill gap-2 align-items-center">
      <div
        className={`donut-chart ${data.key}`}
        style={{
          width: whenPrinting ? 100 : 120,
          height: whenPrinting ? 100 : 120,
        }}
      >
        <div className="center-text">
          <h2
            className={`text-rpt-heading font-weight-bold mb-0 ${
              whenPrinting ? 'fs-6' : ''
            }`}
          >
            {data.percentage}
          </h2>
        </div>
        {data.key === 'cards' && <div className="after-card" />}
      </div>
      <h2
        className={`mb-0 text-rpt-heading font-weight-semi-bold ${
          whenPrinting ? 'fs-8' : 'fs-7'
        }`}
      >
        {data.text}
      </h2>
    </div>
  );
};

const PaymentFraudContinuesWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center align-items-center d-flex">
        <div className="flex-fill w-100">
          <h5 className="text-left d-flex justify-content-between mb-1 d-flex align-items-center gap-1">
            {PFData.heading}
          </h5>
          <div className="px-2">
            <p
              className={`mb-0 ${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } text-left`}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: PFData.description,
                }}
              ></span>
            </p>
            {whenPrinting ? (
              <div
                className={`d-flex align-items-center w-100 gap-2 ${
                  whenPrinting ? 'py-1' : 'py-3'
                }`}
              >
                <>
                  {DONUT_CHART_DATA.map((dd) => (
                    <ReportDonutChart
                      data={dd}
                      key={dd.key}
                      whenPrinting={whenPrinting}
                    />
                  ))}
                </>
              </div>
            ) : (
              <div
                className={`d-flex align-items-center w-100 justify-content-between px-5 gap-2 ${
                  whenPrinting ? 'py-1' : 'py-3'
                }`}
              >
                {DONUT_CHART_DATA.map((dd) => (
                  <DigitalDonutChart item={dd} key={dd.id} />
                ))}
              </div>
            )}
          </div>
          {widgetConfig?.source && (
            <div className="mt-2">
              <WidgetSourceBlock text={widgetConfig.source} />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default PaymentFraudContinuesWidget;
