import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import MaterialIcon from '../../components/commons/MaterialIcon';
import routes from '../../utils/routes.json';
import {
  isModuleAllowed,
  isPermissionAllowed,
  LearnViewTypes,
} from '../../utils/Utils';
import { TenantContext } from '../../contexts/TenantContext';
import PageTitle from '../../components/commons/PageTitle';
import { useProfileContext } from '../../contexts/profileContext';
import Avatar from '../../components/Avatar';
import useIsTenant from '../../hooks/useIsTenant';
import TopicIcon from '../../components/commons/TopicIcon';
import Skeleton from 'react-loading-skeleton';
import { CardBody } from 'reactstrap';
import { PermissionsConstants } from '../../utils/permissions.constants';
import ReviewMyAssignmentsBanner from '../../components/Assignments/ReviewMyAssignmentsBanner';

const HomeItemNewLoader = () => {
  return (
    <div
      className={`card home-card-item-new card-hover-shadow cursor-pointer hover-actions rounded shadow-sm position-relative h-100`}
      style={{ maxWidth: 370, maxHeight: 162, minHeight: 162 }}
    >
      <CardBody className="p-4">
        <Skeleton height={42} width={42} circle className="rounded-circle" />
        <div className="mt-2">
          <Skeleton height="10" width="80" className="mb-1" />
          <Skeleton height="10" width="150" />
        </div>
      </CardBody>
    </div>
  );
};

const HomeItemNew = ({ item }) => {
  return (
    <div
      className={`card home-card-item-new card-hover-shadow cursor-pointer hover-actions rounded shadow-sm position-relative h-100`}
      style={{ maxWidth: 370, maxHeight: 162, minHeight: 162 }}
    >
      <Link to={item.url} className={`card-body p-4`}>
        <TopicIcon
          icon={item.icon}
          iconBg="bg-primary-soft"
          iconStyle={{ width: 42, height: 42 }}
          iconClasses="font-size-em text-primary"
        />
        <div className="mt-2">
          <h4 className="font-weight-semi-bold mb-1">{item.name}</h4>
          <p className="text-muted font-size-sm2 mb-0 font-weight-normal">
            {item.description}
          </p>
        </div>
      </Link>
      <div
        className="abs-center-y position-absolute action-items"
        style={{ right: 10 }}
      >
        <MaterialIcon icon="east" clazz="font-size-em text-primary" />
      </div>
    </div>
  );
};
const Home = () => {
  const { tenant } = useContext(TenantContext);
  const { profileInfo } = useProfileContext();
  const [loader, setLoader] = useState(true);

  const { isSynovusBank } = useIsTenant();

  // the damned permissions :\
  const isActivitiesAllowed =
    isModuleAllowed(tenant.modules, 'activities') &&
    isPermissionAllowed('activities', 'view');

  const isCompaniesAllowed =
    isModuleAllowed(tenant.modules, 'companies') &&
    isPermissionAllowed('organizations', 'view');
  const isDealsAllowed =
    isModuleAllowed(tenant.modules, 'pipelines') &&
    isPermissionAllowed('deals', 'view');
  const isProspectsAllowed =
    isModuleAllowed(tenant.modules, 'prospecting') &&
    isPermissionAllowed('prospects', 'view');

  const isLearnAllowed =
    isModuleAllowed(tenant.modules, 'learns') &&
    isPermissionAllowed('lessons', 'view');

  const isContentAIAllowed = isModuleAllowed(
    tenant.modules,
    PermissionsConstants.AIAssist.Assist
  );

  // eslint-disable-next-line no-unused-vars
  const isMyLearnAssignmentsAllowed =
    isModuleAllowed(tenant.modules, 'learns_assignments') &&
    isPermissionAllowed('lessons', 'view');

  const NewHome = () => {
    useEffect(() => {
      if (tenant?.id && profileInfo?.id) {
        setLoader(false);
      }
    }, [tenant, profileInfo]);

    return (
      <>
        <div className="m-auto text-center mt-4 w-100">
          <div>
            <Avatar
              user={profileInfo}
              defaultSize="md"
              sizeIcon="avatar-dark"
            />
            <p className="mt-2 mb-4">
              Welcome, {profileInfo?.first_name?.trim()}!
            </p>
          </div>
          <h3 className="mt-4">What would you like to do today?</h3>
          <div
            className="row row-cols-1 m-auto justify-content-between font-weight-medium"
            style={{ gap: 10, maxWidth: 750 }}
          >
            {isMyLearnAssignmentsAllowed && (
              <ReviewMyAssignmentsBanner extraClasses={'w-100 mt-3 mb-0'} />
            )}
          </div>
        </div>
        {loader ? (
          <div className="p-3 mt-2">
            <div
              className="row row-cols-2 m-auto justify-content-between font-weight-medium"
              style={{ gap: 10, maxWidth: 750 }}
            >
              {Array(6)
                .fill(0)
                .map((s, index) => (
                  <HomeItemNewLoader key={index} />
                ))}
            </div>
          </div>
        ) : (
          <div className="p-2 mt-2 w-100">
            <div
              className="row row-cols-md-2 row-cols-sm-1 m-auto font-weight-medium"
              style={{ gap: 10, maxWidth: 750 }}
            >
              {isCompaniesAllowed && (
                <HomeItemNew
                  item={{
                    name: 'Companies',
                    description: 'View your most recent opportunities.',
                    icon: 'trending_up',
                    url: isSynovusBank
                      ? routes.insightsCompanies
                      : `${routes.caccounts}#Companies`,
                  }}
                />
              )}
              {isDealsAllowed && (
                <HomeItemNew
                  item={{
                    name: 'Opportunities',
                    description: 'View your most recent opportunities.',
                    icon: 'trending_up',
                    url: routes.dealsPipeline,
                  }}
                />
              )}
              {isActivitiesAllowed && (
                <HomeItemNew
                  item={{
                    name: 'Activities',
                    description: 'Create, assign, and manage activities.',
                    icon: 'event_available',
                    url: routes.Activities,
                  }}
                />
              )}
              {isCompaniesAllowed && (
                <HomeItemNew
                  item={{
                    name: 'Create an Insight',
                    description:
                      'Generate a customer specific, benchmark reports.',
                    icon: 'corporate_fare',
                    url: isSynovusBank
                      ? `${routes.insightsCompanies}?filter=MyOrganization`
                      : `${routes.caccounts}?filter=MyOrganization&id=${profileInfo?.id}`,
                  }}
                />
              )}
              {isProspectsAllowed && (
                <HomeItemNew
                  item={{
                    name: 'Prospecting',
                    description: 'Search 720M professionals, worldwide.',
                    icon: 'person_search',
                    url: routes.prospecting,
                  }}
                />
              )}
              {isContentAIAllowed && (
                <HomeItemNew
                  item={{
                    name: 'Content AI',
                    description: 'Generate content with the power of AI.',
                    icon: 'auto_awesome',
                    url: routes.aiAssist,
                  }}
                />
              )}
              {isLearnAllowed && (
                <HomeItemNew
                  item={{
                    name: 'Learn',
                    description: 'On-demand micro lessons.',
                    icon: 'school',
                    url: `${routes.learnMain}?viewType=${LearnViewTypes.Overview}`,
                  }}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <PageTitle page="Home" />
      <NewHome />
    </>
  );
};

export default Home;
