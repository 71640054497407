import { Card, CardBody, CardHeader } from 'reactstrap';
import ButtonIcon from '../../../components/commons/ButtonIcon';
import { Image, ProgressBar } from 'react-bootstrap';
import AssessmentBanner from '../../../assets/png/knowledge-assessment-banner.png';
import React from 'react';

import KnowledgeAssessmentQuestionnaire from './KnowledgeAssessmentQuestionnaire';
import Skeleton from 'react-loading-skeleton';

const calculatePercentage = (score, total) => {
  return Math.round((score / total) * 100);
};
const KnowledgeAssessmentBanner = ({
  loader,
  isAllowed,
  assessment,
  setInfoMessage,
  updateAssessment,
  assessmentQuestions,
  submitQuestionnaire,
}) => {
  return (
    <Card className="mx-sm-2">
      {!assessment.start ? (
        <>
          <CardHeader>
            <h3 className="mb-0">
              Please complete the Knowledge Assessment - Due February 29, 2024
            </h3>
          </CardHeader>
          <CardBody className="p-5">
            <div className="text-center mb-3">
              <Image src={AssessmentBanner} style={{ width: 600 }} />
            </div>
            <p>
              The knowledge assessment is designed to provide a clear snapshot
              of the team&apos;s current understanding and to identify areas
              where we may need to deepen our knowledge in the coming year.
              These insights will assist us in developing and delivering
              training where it is most needed. We encourage you to see this as
              an opportunity to highlight your strengths and uncover areas for
              growth, aiding in our collective advancement.
            </p>
            <div className="text-center">
              <ButtonIcon
                label="Start Knowledge Assessment"
                color="primary"
                loading={loader}
                disabled={loader === true}
                classnames="mt-2"
                onclick={() => {
                  if (isAllowed) {
                    updateAssessment({ start: true });
                  } else {
                    setInfoMessage(
                      "You've already taken the product assessment. Please wait for next 30 days. Thanks!"
                    );
                  }
                }}
              />
            </div>
          </CardBody>
        </>
      ) : (
        <>
          <CardBody className="text-center position-relative">
            {!assessment.showResults && !assessment.completed && (
              <ProgressBar
                now={assessment.progress}
                className={`w-100 position-absolute flat-progress top-0 left-0`}
              />
            )}
            {!assessment.completed && (
              <KnowledgeAssessmentQuestionnaire
                assessmentQuestions={assessmentQuestions}
                finishQuestionnaire={(submissionRequest) => {
                  updateAssessment({ completed: true });
                  submitQuestionnaire(submissionRequest);
                }}
                setProgress={(newProgress) => {
                  updateAssessment({ progress: newProgress });
                }}
              />
            )}
            {assessment.completed && !assessment.showResults && (
              <div className="text-center mb-2">
                <div>
                  <span className="font-size-5em">&#127881;</span>
                </div>
                <h1 className="text-black my-2">Congratulations!</h1>
                <p className="my-2">
                  You have successfully completed product knowledge assessment.
                </p>
                {loader ? (
                  <Skeleton width={100} height={10} />
                ) : (
                  <p>
                    You scored:{' '}
                    <b>
                      {calculatePercentage(
                        assessment.scored,
                        assessmentQuestions.length
                      )}
                      %
                    </b>
                  </p>
                )}
              </div>
            )}
          </CardBody>
        </>
      )}
    </Card>
  );
};

export default KnowledgeAssessmentBanner;
