import { Col, Row } from 'reactstrap';
import { roundNumbers } from '../../../utils/Utils';

const RocketReactCompanyDetails = ({ prospect }) => {
  return (
    <Row className="justify-content-center font-size-sm2">
      <Col
        md={6}
        className="d-flex flex-column align-items-center text-center pl-0"
      >
        <>
          {prospect?.revenue ? (
            <>
              <p className="mb-0">${roundNumbers(prospect?.revenue)}</p>
              <p className="text-nowrap mb-0">Revenue</p>
            </>
          ) : null}
        </>
      </Col>
      <Col md={6} className="d-flex flex-column align-items-center text-center">
        <p className="mb-0 text-nowrap">{roundNumbers(prospect?.employees)}</p>
        <p className="text-nowrap mb-0">Employees</p>
      </Col>
    </Row>
  );
};

export default RocketReactCompanyDetails;
