import React, { useEffect, useState } from 'react';
import { overflowing } from '../../utils/Utils';
import SimpleModalCreation from '../../components/modal/SimpleModalCreation';
import InputValidation from '../commons/InputValidation';
import { useForm } from 'react-hook-form';
import organizationService from '../../services/organization.service';
import { onGetOwners } from '../../views/Deals/contacts/utils';
import InlineFormGroup from '../commons/InlineFormGroup';
import NewKindOfSearchDropdown from '../commons/NewKindOfSearchDropdown';

const EditOrganizationInfo = ({
  show,
  setShow,
  profileInfo,
  getProfileInfo,
  mainOwner,
  setRefresOwners,
  setSuccessMessage = () => {},
  setErrorMessage = () => {},
}) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [data, setData] = useState({ ...profileInfo });
  const [loading, setLoading] = useState(false);

  const [owners, setOwners] = useState();
  const [selectedUser, setSelectedUser] = useState({});
  const [searchUser, setSearchUser] = useState({
    search: '',
  });

  const onHandleCloseModal = () => {
    if (!loading) {
      overflowing();
      setShow(!show);
    }
  };

  useEffect(() => {
    if (show) {
      setValue('name', profileInfo.name);
      setValue('assigned_user_id', profileInfo.assigned_user_id);
    }
  }, [show]);

  const handleSelectUser = (item) => {
    setSelectedUser(item);
    handleOnChange({
      target: {
        name: 'assigned_user_id',
        value: item.id,
      },
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setValue(name, value);
  };

  const ownersService = async (pagination) => {
    return await organizationService
      .getOwners(profileInfo?.id, pagination)
      .catch((err) => console.log(err));
  };

  const getCurrentOwners = async () => {
    const resp = await ownersService({ page: 1, limit: 20 });
    const { data } = resp || {};
    const filteredData = data?.filter((item) => {
      return item?.user?.status !== 'deactivated';
    });
    setUsersList(filteredData);
  };

  const setUsersList = (filteredData) => {
    const users = filteredData?.map((item) => item.user) || [];
    const existingUsers = mainOwner ? [mainOwner, ...users] : [...users];
    setOwners(existingUsers);
  };

  useEffect(() => {
    if (profileInfo?.id) {
      getCurrentOwners();
    }
    setSelectedUser(mainOwner);
  }, [profileInfo?.id, mainOwner]);

  useEffect(() => {
    if (searchUser.search) {
      onGetOwners(searchUser, setOwners);
    }
  }, [searchUser.search]);

  const onChangeUser = (e) => {
    const match = e.target.value.match(/([A-Za-z])/g);
    if (match && match.length >= 2) {
      setSearchUser({
        ...searchUser,
        search: e.target.value,
      });
    }
  };

  const onSubmit = async () => {
    const { name, assigned_user_id, ...rest } = data;

    const updateFields = {
      ...rest,
      name,
      assigned_user_id,
    };
    if (name && assigned_user_id) {
      setLoading(true);

      // remove this owner from existing owners list
      removeOwner(assigned_user_id);

      try {
        await organizationService.updateOrganization(
          profileInfo.id,
          updateFields
        );
        getProfileInfo();
        setTimeout(() => {
          overflowing();
          setShow(false);
          setSuccessMessage('Company Updated.');
        }, 1000);
      } catch (error) {
        if (error.response.status === 403) {
          getProfileInfo();
        }
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage('All fields are required.');
    }
  };

  const removeOwner = async (ownerId) => {
    try {
      await organizationService.removeOwner(profileInfo.id, ownerId);
      setRefresOwners(true);
    } catch (error) {
      console.log('remove owner error', error);
    }
  };

  const uniqueOwners = owners?.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i
  );

  return (
    <>
      <SimpleModalCreation
        modalHeaderClasses="flex-fill"
        modalTitle="Edit Company Information"
        saveButtonStyle="btn btn-primary btn-sm d-flex align-items-center"
        open={show}
        editFields={true}
        isLoading={loading}
        footerStyle="border-0"
        handleSubmit={handleSubmit(onSubmit)}
        onHandleCloseModal={onHandleCloseModal}
      >
        <div className="d-flex flex-column gap-3">
          <InlineFormGroup
            label="Name"
            component={
              <InputValidation
                name="name"
                type="input"
                placeholder="Company Name"
                value={data.name || ''}
                errorDisplay="mb-0"
                classNames="mr-2"
                validationConfig={{
                  required: 'Name is required.',
                  inline: false,
                  borderLeft: true,
                  onChange: handleOnChange,
                }}
                errors={errors}
                register={register}
              />
            }
          />
          <InlineFormGroup
            label="Primary Owner"
            component={
              <NewKindOfSearchDropdown
                readOnly={true}
                preData={uniqueOwners}
                placeholder="Search for owner"
                onItemSelect={handleSelectUser}
                onInputSearch={onChangeUser}
                currentSelected={selectedUser}
                validationConfig={{
                  required: 'Primary owner is required.',
                  inline: false,
                  borderLeft: true,
                }}
              />
            }
          />
        </div>
      </SimpleModalCreation>
    </>
  );
};

export default EditOrganizationInfo;
