import React from 'react';

const WidgetSourceBlock = ({ text }) => {
  return (
    <p className="fs-10 text-muted source-block mb-0 text-left">
      Source:&nbsp;
      <span dangerouslySetInnerHTML={{ __html: text }}></span>
    </p>
  );
};

export default WidgetSourceBlock;
