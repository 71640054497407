import { Card, CardBody, Col, Row } from 'reactstrap';
import React from 'react';
import MaterialIcon from '../../../commons/MaterialIcon';
import WidgetSourceBlock from '../../WidgetSourceBlock';

const IconTextItem = ({ icon, text }) => {
  return (
    <Card className="rounded-lg" style={{ height: 150 }}>
      <CardBody
        className="d-flex flex-column align-items-center justify-content-center"
        style={{
          background:
            'linear-gradient(327.32deg, #F2F3FF -21.69%, rgba(255, 255, 255, 0) 113.02%)',
        }}
      >
        <MaterialIcon symbols icon={icon} clazz="text-primary font-size-2xl" />
        <p className="mb-0 font-weight-semi-bold">{text}</p>
      </CardBody>
    </Card>
  );
};

const AutomatingAccountsReceivables = ({ widgetConfig, whenPrinting }) => {
  const items = [
    { id: 1, text: 'Better Visibility', icon: 'search' },
    { id: 2, text: 'Happy Vendors', icon: 'supervised_user_circle' },
    { id: 3, text: 'Cost Savings', icon: 'savings' },
    { id: 4, text: 'Improved Accuracy', icon: 'rocket' },
    { id: 5, text: 'Decreased Fraud', icon: 'verified_user' },
  ];
  return (
    <Card className="report-widget">
      <CardBody className="d-flex align-items-center justify-content-center">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <Row className="px-2">
            {items.slice(0, 3).map((item) => (
              <Col key={item.id} className="p-2">
                <IconTextItem text={item.text} icon={item.icon} />
              </Col>
            ))}
          </Row>
          <Row className="mt-1 px-2">
            {items.slice(3).map((item) => (
              <Col key={item.id} className="p-2">
                <IconTextItem text={item.text} icon={item.icon} />
              </Col>
            ))}
          </Row>

          <p
            className={`${
              whenPrinting ? 'fs-9' : 'font-size-sm2'
            } mb-1 text-left`}
          >
            {widgetConfig.description}
          </p>
          {widgetConfig?.source && (
            <WidgetSourceBlock text={widgetConfig.source} />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default AutomatingAccountsReceivables;
