import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

import stringConstants from '../../utils/stringConstants.json';
import ButtonIcon from '../commons/ButtonIcon';

const constants = stringConstants.deleteConfirmationModal;

const DocumentConfirmationModal = ({
  showModal,
  setShowModal,
  event,
  item,
  clearSelection = () => {},
  showModalFooter = true,
  description,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onHandleCloseModal = () => {
    setShowModal(false);
    clearSelection();
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    await event();
    setIsLoading(false);
  };

  const RenderConfirmationText = () => (
    <div>
      <h4 className="fw-bold">Confirmation Required</h4>
      {description}
    </div>
  );

  return (
    <Modal
      isOpen={showModal}
      fade={false}
      zIndex={1202}
      className={`delete-role-modal`}
    >
      <ModalBody className="p-3">
        <RenderConfirmationText />
        {showModalFooter && (
          <div className="flex-nowrap gap-2 d-flex align-items-center mt-4">
            {item?.title && (
              <>
                <ButtonIcon
                  label={constants.cancelButton}
                  onclick={onHandleCloseModal}
                  loading={isLoading}
                  color="white"
                  classnames="w-50 btn-sm btn-white"
                />
                <ButtonIcon
                  label="Confirm"
                  onclick={handleSubmit}
                  loading={isLoading}
                  classnames="w-50 btn-sm"
                />
              </>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DocumentConfirmationModal;
