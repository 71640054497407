import React from 'react';
import InsightStatItem from '../../../InsightStatItem';

export const OverviewWidget = (results) => {
  const { data } = results[0];
  return (
    <InsightStatItem
      tooltipData={{
        'Unique Lessons Completed for all Users':
          'Total number of unique lessons taken by all users combined',
      }}
      heading="Unique Lessons Completed for all Users"
      headingClass="font-size-md"
      countClass={'font-size-4em text-left'}
      subHeadingClass="font-size-xl"
      customClass="col-md-12 pb-3 px-0"
      stat={data[0]?.['LessonProgress.uniqueCountOfCompleted']}
      hideAverage
    />
  );
};
