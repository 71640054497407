import React, { useCallback, useMemo, useState } from 'react';
import { overflowing } from '../../../utils/Utils';
import Search from '../../manageUsers/Search';
import { ListGroup } from 'react-bootstrap';
import { ListGroupItem } from 'reactstrap';
import NoDataFound from '../../commons/NoDataFound';
import SimpleModalCreation from '../../modal/SimpleModalCreation';
import { HorizontalWidgets } from '../constants/widgetsConstants';
import WidgetWrapper from './WidgetWrapper';

const WidgetsLibraryModal = ({ show, setShow, selectedWidget }) => {
  const [search, setSearch] = useState('');
  const filterWidgets = (widget) => {
    return search
      ? JSON.stringify(widget.widgetConfig)
          .toLowerCase()
          .includes(search.toLowerCase())
      : true;
  };

  const handleAddWidget = (newWidget) => {
    selectedWidget.onAdd(this, newWidget, selectedWidget);
  };
  return (
    <SimpleModalCreation
      modalTitle="Widgets Library"
      open={show}
      onHandleCloseModal={() => {
        overflowing();
        setShow(false);
      }}
      onClick={() => document.dispatchEvent(new MouseEvent('click'))}
      size={'lg'}
    >
      <div className="d-flex align-items-center justify-content-between p-0">
        <div className="search-fixed">
          <Search
            onHandleChange={(e) => setSearch(e.target.value)}
            searchPlaceholder="Search"
            classnames="px-0"
          />
        </div>
      </div>
      <ListGroup className="list-group-no-gutters mt-3 list-group-flush">
        <h4 className="text-black font-weight-semi-bold mb-0">
          All Widgets ({HorizontalWidgets.filter(filterWidgets).length})
        </h4>
        {HorizontalWidgets.filter(filterWidgets).map((widget, index) => (
          <ListGroupItem
            key={widget.id}
            className="border-0 position-relative pb-0"
          >
            <WidgetWrapper
              widget={{
                ...widget,
                action: { onAdd: () => handleAddWidget(widget) },
              }}
              actionButtons={{
                remove: { text: 'Remove', icon: 'delete' },
                add: { text: 'Add Widget', icon: 'add' },
              }}
              fromWidgetsLibrary={true}
            />
          </ListGroupItem>
        ))}
        {!HorizontalWidgets?.filter(filterWidgets).length && search && (
          <NoDataFound
            title="No widgets found"
            description="Please update your search query"
            icon="dashboard"
            containerStyle="text-gray-900 my-6 py-6"
          />
        )}
      </ListGroup>
    </SimpleModalCreation>
  );
};

const useWidgetsLibrary = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState({});
  const WidgetsLibraryModalCallback = useCallback(() => {
    return (
      <WidgetsLibraryModal
        show={showModal}
        setShow={setShowModal}
        selectedWidget={selectedWidget}
      />
    );
  }, [showModal, setShowModal, selectedWidget, setSelectedWidget]);

  return useMemo(
    () => ({
      setShowModal,
      setSelectedWidget,
      WidgetsLibraryModal: WidgetsLibraryModalCallback,
    }),
    [setShowModal, WidgetsLibraryModalCallback]
  );
};

export default useWidgetsLibrary;
