import React, { useEffect, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { isModuleChecked, replaceSpaceWithCharacter } from '../utils/Utils';
import { ModulesList } from '../utils/modules';
import _ from 'lodash';
import { ImageList, ImageListItem } from '@mui/material';
// Create an array of unique group names

const groupedData = ModulesList.map((item) => {
  const newItem = {
    id: item.id,
    label: item.label,
    group: item.group,
    name: item.name.replace(/ /g, '_').toLowerCase(),
  };

  if (item.children) {
    newItem.children = item.children.map((child) => {
      const newChild = {
        id: child.id,
        label: child.label,
        name: child.name.replace(/ /g, '_').toLowerCase(),
      };

      if (child.grandchildren) {
        newChild.grandchildren = child.grandchildren.map((grandchild) => {
          const newGrandchild = {
            id: grandchild.id,
            label: grandchild.label,
            name: grandchild.name.replace(/ /g, '_').toLowerCase(),
          };

          if (grandchild.great_grandchildren) {
            newGrandchild.great_grandchildren =
              grandchild.great_grandchildren.map((greatGrandchild) => ({
                id: greatGrandchild.id,
                label: greatGrandchild.label,
                name: greatGrandchild.name.replace(/ /g, '_').toLowerCase(),
              }));
          }

          return newGrandchild;
        });
      }

      return newChild;
    });
  }

  return newItem;
});

const groupNames = [...new Set(groupedData.map((module) => module.group))];

// Combine the data and groups
const combinedData = groupNames.map((groupName) => ({
  name: groupName,
  modules: groupedData.filter((module) => module.group === groupName),
}));

const TenantCheckBox = ({
  modulesData,
  selectedCheckboxes,
  setSelectedCheckboxes,
}) => {
  const [allCheck, setAllCheck] = useState(false);
  const [enableAll, setEnableAll] = useState({});

  const [flattenArray, setFlattenArray] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});

  // Function to check or uncheck all checkboxes
  const handleSelectAllChange = (isChecked) => {
    const updatedCheckedItems = { ...checkedItems };
    ModulesList.forEach((item) => {
      const itemName = replaceSpaceWithCharacter(item.name, '_').toLowerCase();
      updatedCheckedItems[itemName] = isChecked;

      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
          const childName = `${
            itemName !== child.name
              ? `${itemName}_${replaceSpaceWithCharacter(
                  child.name,
                  '_'
                ).toLowerCase()}`
              : replaceSpaceWithCharacter(child.name, '_').toLowerCase()
          }`;
          updatedCheckedItems[childName] = isChecked;

          if (child.grandchildren && child.grandchildren.length > 0) {
            child.grandchildren.forEach((grandchild) => {
              const grandchildName = `${childName}_${replaceSpaceWithCharacter(
                grandchild.name,
                '_'
              ).toLowerCase()}`;
              updatedCheckedItems[grandchildName] = isChecked;

              if (
                grandchild.great_grandchildren &&
                grandchild.great_grandchildren.length > 0
              ) {
                grandchild.great_grandchildren.forEach((greatGrandchild) => {
                  const greatGrandchildName = `${grandchildName}_${replaceSpaceWithCharacter(
                    greatGrandchild.name,
                    '_'
                  ).toLowerCase()}`;
                  updatedCheckedItems[greatGrandchildName] = isChecked;
                });
              }
            });
          }
        });
      }
    });
    setCheckedItems(updatedCheckedItems);
  };

  const renderCheckboxes = (items, parentName = '') => {
    return items?.map((item, index) => {
      const checkboxName = parentName
        ? `${parentName}_${replaceSpaceWithCharacter(
            item.name,
            '_'
          ).toLowerCase()}`
        : replaceSpaceWithCharacter(item.name, '_').toLowerCase();

      return (
        <div key={index} className="p-1 fs-7">
          <FormCheck
            id={checkboxName}
            name={checkboxName}
            type="checkbox"
            custom={true}
            label={item?.label}
            checked={checkedItems[checkboxName] || false}
            onChange={() =>
              handleCheckboxChange(
                checkboxName,
                item.children,
                item.grandchildren,
                parentName
              )
            }
          />
          {item.grandchildren && item.grandchildren.length > 0 && (
            <div className="pl-3">
              {renderCheckboxes(item.grandchildren, checkboxName)}
            </div>
          )}
          {item.great_grandchildren && item.great_grandchildren.length > 0 && (
            <div className="pl-3">
              {renderCheckboxes(item.great_grandchildren, checkboxName)}
            </div>
          )}
        </div>
      );
    });
  };

  const isAnyModuleChecked = (moduleName, checkedItems) => {
    const keys = _.keys(checkedItems).filter((it) => it.includes(moduleName));
    return keys.some((s) => checkedItems[s] === true);
  };
  const isAllUnChecked = (moduleName, checkedItems) => {
    const keys = Object.keys(checkedItems).filter(
      (key) => key.includes(moduleName) && key.includes('_')
    );
    const allUnchecked = keys.every((key) => {
      const isUnChecked = checkedItems[key] === false;
      return isUnChecked;
    });
    checkedItems[moduleName] = !allUnchecked;
  };

  const handleCheckboxChange = (
    itemName,
    children,
    grandchildren,
    great_grandchildren,
    parentName
  ) => {
    const updatedCheckedItems = { ...checkedItems };
    updatedCheckedItems[itemName] = !updatedCheckedItems[itemName];

    if (children && children.length > 0) {
      children.forEach((child) => {
        const childName = `${
          itemName !== child.name
            ? `${itemName}_${replaceSpaceWithCharacter(
                child.name,
                '_'
              ).toLowerCase()}`
            : replaceSpaceWithCharacter(child.name, '_').toLowerCase()
        }`;
        updatedCheckedItems[childName] = updatedCheckedItems[itemName];
        if (child.grandchildren && child.grandchildren.length > 0) {
          child.grandchildren.forEach((grandchild) => {
            const grandchildName = `${childName}_${replaceSpaceWithCharacter(
              grandchild.name,
              '_'
            ).toLowerCase()}`;
            updatedCheckedItems[grandchildName] = updatedCheckedItems[itemName];
            if (
              grandchild.great_grandchildren &&
              Array.isArray(grandchild.great_grandchildren)
            ) {
              grandchild.great_grandchildren.forEach((great_grandchild) => {
                const greatGrandchildName = `${grandchildName}_${replaceSpaceWithCharacter(
                  great_grandchild.name,
                  '_'
                ).toLowerCase()}`;
                updatedCheckedItems[greatGrandchildName] =
                  updatedCheckedItems[itemName];
              });
            }
          });
        }
      });
    }
    if (grandchildren && grandchildren?.length > 0) {
      grandchildren?.forEach((grandchild) => {
        const grandchildName = `${itemName}_${replaceSpaceWithCharacter(
          grandchild.name,
          '_'
        ).toLowerCase()}`;
        updatedCheckedItems[grandchildName] = updatedCheckedItems[itemName];
        if (
          grandchild.great_grandchildren &&
          Array.isArray(grandchild.great_grandchildren)
        ) {
          grandchild.great_grandchildren.forEach((great_grandchild) => {
            const greatGrandchildName = `${grandchildName}_${replaceSpaceWithCharacter(
              great_grandchild.name,
              '_'
            ).toLowerCase()}`;
            updatedCheckedItems[greatGrandchildName] =
              updatedCheckedItems[itemName];
          });
        }
      });
    }

    if (great_grandchildren && Array.isArray(great_grandchildren)) {
      great_grandchildren?.forEach((great_grandchild) => {
        const greatGrandchildName = `${itemName}_${replaceSpaceWithCharacter(
          great_grandchild.name,
          '_'
        ).toLowerCase()}`;
        updatedCheckedItems[greatGrandchildName] =
          updatedCheckedItems[itemName];
      });
    }

    if (parentName !== 'ai_assist' && parentName) {
      const splitParent = parentName.split('_');
      if (splitParent?.length) {
        updatedCheckedItems[splitParent[0]] =
          isAllUnChecked(parentName, updatedCheckedItems) ||
          isAnyModuleChecked(parentName, updatedCheckedItems);
        if (splitParent.length > 1) {
          updatedCheckedItems[splitParent.join('_')] =
            isAllUnChecked(parentName, updatedCheckedItems) ||
            isAnyModuleChecked(parentName, updatedCheckedItems);
        }
      }
    } else {
      updatedCheckedItems[parentName] =
        isAllUnChecked(parentName, updatedCheckedItems) ||
        isAnyModuleChecked(parentName, updatedCheckedItems);
    }
    moduleChecked(parentName, updatedCheckedItems);
    setCheckedItems(updatedCheckedItems);
  };

  const handleParentCheckboxChange = (
    itemName,
    children,
    grandchildren,
    great_grandchildren,
    parentName
  ) => {
    const updatedCheckedItems = { ...checkedItems };
    updatedCheckedItems[itemName] = !updatedCheckedItems[itemName];

    if (children && children.length > 0) {
      children.forEach((child) => {
        const childName = `${
          itemName !== child.name
            ? `${itemName}_${replaceSpaceWithCharacter(
                child.name,
                '_'
              ).toLowerCase()}`
            : replaceSpaceWithCharacter(child.name, '_').toLowerCase()
        }`;
        updatedCheckedItems[childName] = updatedCheckedItems[itemName];
        if (child.grandchildren && child.grandchildren.length > 0) {
          child.grandchildren.forEach((grandchild) => {
            const grandchildName = `${childName}_${replaceSpaceWithCharacter(
              grandchild.name,
              '_'
            ).toLowerCase()}`;
            updatedCheckedItems[grandchildName] = updatedCheckedItems[itemName];
            if (
              grandchild.great_grandchildren &&
              Array.isArray(grandchild.great_grandchildren)
            ) {
              grandchild.great_grandchildren.forEach((great_grandchild) => {
                const greatGrandchildName = `${grandchildName}_${replaceSpaceWithCharacter(
                  great_grandchild.name,
                  '_'
                ).toLowerCase()}`;
                updatedCheckedItems[greatGrandchildName] =
                  updatedCheckedItems[itemName];
              });
            }
          });
        }
      });
    }
    if (grandchildren && grandchildren?.length > 0) {
      grandchildren?.forEach((grandchild) => {
        const grandchildName = `${itemName}_${replaceSpaceWithCharacter(
          grandchild.name,
          '_'
        ).toLowerCase()}`;
        updatedCheckedItems[grandchildName] = updatedCheckedItems[itemName];
        if (
          grandchild.great_grandchildren &&
          Array.isArray(grandchild.great_grandchildren)
        ) {
          grandchild.great_grandchildren.forEach((great_grandchild) => {
            const greatGrandchildName = `${grandchildName}_${replaceSpaceWithCharacter(
              great_grandchild.name,
              '_'
            ).toLowerCase()}`;
            updatedCheckedItems[greatGrandchildName] =
              updatedCheckedItems[itemName];
          });
        }
      });
    }

    if (great_grandchildren && Array.isArray(great_grandchildren)) {
      great_grandchildren?.forEach((great_grandchild) => {
        const greatGrandchildName = `${itemName}_${replaceSpaceWithCharacter(
          great_grandchild.name,
          '_'
        ).toLowerCase()}`;
        updatedCheckedItems[greatGrandchildName] =
          updatedCheckedItems[itemName];
      });
    }

    updatedCheckedItems[parentName] =
      isAllUnChecked(parentName, updatedCheckedItems) ||
      isAnyModuleChecked(parentName, updatedCheckedItems);
    moduleChecked(parentName, updatedCheckedItems);
    setCheckedItems(updatedCheckedItems);
  };
  useEffect(() => {
    if (modulesData?.modules === '*') {
      handleSelectAllChange(true);
    }
    if (modulesData?.modules !== '*' && modulesData?.modules?.length > 0) {
      const modules = modulesData?.modules.split(',');
      const modulesObject = {};
      modules.forEach((item) => {
        modulesObject[item] = true;
      });
      setCheckedItems(modulesObject);
    }
  }, [modulesData]);

  const flatten = (module) => {
    return _.flatMapDeep(module, (item) => {
      const children = _.get(item, 'children', []);

      const flattenedChildren = children.map((child) => ({
        name: `${item.name} ${child.name}`,
        group: child.group,
        id: child.id,
      }));

      const flattenedGrandchildren = children.map((child) =>
        child?.grandchildren?.map((grand) => ({
          name: `${item.name} ${child?.name || ''} ${grand.name}`,
          group: grand.group,
          id: grand.id,
        }))
      );

      const flattenedGreatGrandchildren = children.map((child) =>
        child?.grandchildren?.map((grandchild) =>
          grandchild?.great_grandchildren?.map((greatGrandchild) => ({
            name: `${item.name} ${child.name} ${grandchild.name} ${greatGrandchild.name}`,
            group: greatGrandchild.group,
            id: greatGrandchild.id,
          }))
        )
      );

      return [
        {
          name: item.name,
          group: item.group,
          id: item.id,
        },
        ...flattenedChildren,
        ..._.compact(_.flattenDeep(flattenedGrandchildren)),
        ..._.compact(_.flattenDeep(flattenedGreatGrandchildren)),
      ];
    });
  };

  useEffect(() => {
    const result = flatten(ModulesList);
    setFlattenArray(result);
  }, []);
  const moduleChecked = (name, updatedCheckedItems) => {
    let keys = [];
    const findName = name?.includes(' ')
      ? name?.toLowerCase().replace(/ /g, '_')
      : name?.toLowerCase();
    if (Object.keys(updatedCheckedItems).length > 0) {
      keys = flattenArray.filter((item) =>
        item?.name?.toLowerCase().replace(/ /g, '_').includes(findName)
      );
      if (keys?.length > 0) {
        const allUnchecked = keys.every((key) => {
          const moduleName = key?.name?.toLowerCase().replace(/ /g, '_');
          return updatedCheckedItems[moduleName] === true;
        });
        const moduleName = name?.toLowerCase().replace(/ /g, '_');

        setEnableAll((prevEnableAll) => ({
          ...prevEnableAll,
          [moduleName]: allUnchecked,
        }));
      }
    }
  };

  useEffect(() => {
    if (Object.keys(checkedItems)?.length > 0) {
      ModulesList.forEach((item) => {
        moduleChecked(item?.name, checkedItems);
      });
    }
  }, [checkedItems, modulesData?.modules, allCheck]);

  useEffect(() => {
    if (Object.keys(checkedItems)?.length) {
      const isAllModulesChecked = flatten(ModulesList)?.every((item) => {
        return isModuleChecked(item, checkedItems);
      });

      if (isAllModulesChecked) {
        setAllCheck(true);
        setSelectedCheckboxes('*');
      } else {
        const selectedCheckboxNames = Object.keys(checkedItems).filter(
          (key) => checkedItems[key]
        );
        setSelectedCheckboxes(selectedCheckboxNames.join(','));
        setAllCheck(false);
      }
    } else {
      const selectedCheckboxNames = Object.keys(checkedItems).filter(
        (key) => checkedItems[key]
      );
      setSelectedCheckboxes(selectedCheckboxNames.join(','));
      setAllCheck(false);
    }
  }, [checkedItems, allCheck]);

  return (
    <>
      <div>
        <FormCheck
          id="selectAll"
          name="selectAll"
          type="switch"
          custom={true}
          label="Select All"
          checked={allCheck}
          onChange={(e) => handleSelectAllChange(e.target.checked)}
        />
      </div>
      <div className="mt-3">
        <ImageList variant="masonry" cols={2} gap={10}>
          {combinedData.map((group, index) => (
            <ImageListItem
              key={index}
              className={`bg-gray-200 rounded border p-0 overflow-auto ${group?.name}`}
            >
              {group?.modules?.map((item) => (
                <div key={item} className="p-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 text-capitalize d-flex align-items-center">
                      <span>{item.group}</span>
                      <FormCheck
                        id={item.name.toLowerCase()}
                        name={item.name.toLowerCase()}
                        type="switch"
                        custom={true}
                        className="hide"
                        checked={
                          checkedItems[
                            item.name.toLowerCase().replace(/ /g, '_')
                          ] || false
                        }
                        onChange={() =>
                          handleCheckboxChange(
                            item.name.toLowerCase(),
                            item.children,
                            item.grandchildren
                          )
                        }
                      />
                    </h5>
                    {item?.children?.length > 0 ? (
                      <FormCheck
                        type="switch"
                        id={`${item.name.toLowerCase()}_1`}
                        name={item.name.toLowerCase()}
                        label="Enable All"
                        custom={true}
                        className="fs-8"
                        checked={enableAll[item?.name] === true}
                        onChange={() =>
                          handleParentCheckboxChange(
                            item.name.toLowerCase(),
                            item.children,
                            item.grandchildren,
                            item.name
                          )
                        }
                      />
                    ) : (
                      <FormCheck
                        id={item.name.toLowerCase()}
                        name={item.name.toLowerCase()}
                        type="switch"
                        label="Enable"
                        custom={true}
                        className="fs-8"
                        checked={
                          checkedItems[
                            item.name.toLowerCase().replace(/ /g, '_')
                          ] || false
                        }
                        onChange={() =>
                          handleCheckboxChange(
                            item.name.toLowerCase(),
                            item.children,
                            item.grandchildren
                          )
                        }
                      />
                    )}
                  </div>
                  {item.children?.length > 0 ? (
                    <div className="pl-2 pt-2">
                      {renderCheckboxes(item.children, item.name)}
                      {item.children.map((child) =>
                        child.great_grandchildren ? (
                          <div className="pl-2 pt-2" key={child.id}>
                            {renderCheckboxes(
                              child.great_grandchildren,
                              child.name
                            )}
                          </div>
                        ) : null
                      )}
                    </div>
                  ) : null}
                </div>
              ))}
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </>
  );
};

export default TenantCheckBox;
