import React, { createContext, useContext, useEffect, useState } from 'react';
import authHeader from '../services/auth-header';
import { useAppContext } from './appContext';
import _ from 'lodash';

const FLAG_NAMES = {
  REPORT_DISABLE_FILE_PARSING: 'REPORT_DISABLE_FILE_PARSING',
};
const TenantFlagsContext = createContext({
  flags: {},
  setFlags: () => {},
  FLAG_NAMES,
});
export const TenantFlagsContextProvider = (props) => {
  const { isAuthenticated } = useAppContext();
  const [flags, setFlags] = useState({});
  const getTenantFlags = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth/context/tenant/flags?page=1&limit=2`,
        {
          headers: authHeader(),
        }
      );
      const { data } = await response.json();
      if (data.length) {
        setFlags(_.groupBy(data, 'name'));
      }
    } catch (error) {
      console.error('Error fetching tenant flags:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getTenantFlags();
    }
  }, [isAuthenticated]);

  return (
    <TenantFlagsContext.Provider value={{ flags, setFlags, FLAG_NAMES }}>
      {props.children}
    </TenantFlagsContext.Provider>
  );
};

export const useTenantFlags = () => {
  return useContext(TenantFlagsContext);
};
