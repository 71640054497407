import React, { useEffect, useState } from 'react';

import DropdownWrapper from './DropdownWrapper';
import { getKeysWithData } from '../../../../utils/Utils';
import prospectService from '../../../../services/prospect.service';
import _ from 'lodash';

const cityCountryCombine = (item) => {
  if (item?.isoCountrySubdivisionCode) {
    return `${item.city}, ${item?.isoCountrySubdivisionCode}, ${item?.isoCountryCode}`;
  }
  return `${item.city}, ${item?.isoCountryCode}`;
};

const defaultUSStatesList = [
  {
    city: 'New York',
    isoCountrySubdivisionCode: 'NY',
    isoCountryCode: 'US',
  },
  {
    city: 'Los Angeles',
    isoCountrySubdivisionCode: 'CA',
    isoCountryCode: 'US',
  },
  {
    city: 'Chicago',
    isoCountrySubdivisionCode: 'IL',
    isoCountryCode: 'US',
  },
  {
    city: 'San Francisco',
    isoCountrySubdivisionCode: 'CA',
    isoCountryCode: 'US',
  },
  {
    city: 'Dallas',
    isoCountrySubdivisionCode: 'TX',
    isoCountryCode: 'US',
  },
  {
    city: 'Boston',
    isoCountrySubdivisionCode: 'MA',
    isoCountryCode: 'US',
  },
  {
    city: 'Houston',
    isoCountrySubdivisionCode: 'TX',
    isoCountryCode: 'US',
  },
  {
    city: 'Seattle',
    isoCountrySubdivisionCode: 'WA',
    isoCountryCode: 'US',
  },
  {
    city: 'Miami',
    isoCountrySubdivisionCode: 'FL',
    isoCountryCode: 'US',
  },
  {
    city: 'Atlanta',
    isoCountrySubdivisionCode: 'GA',
    isoCountryCode: 'US',
  },
];
const LocationSearch = ({
  name,
  data = [],
  setData,
  keyType,
  keyFilter,
  placeholder,
  onEnter,
  showLabelColon = true,
}) => {
  const limit = 10;
  const [list, setList] = useState(defaultUSStatesList.map(cityCountryCombine));
  const [options, setOptions] = useState([]);
  const [selects, setSelects] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [search, setSearch] = useState('');
  const [loader, setLoader] = useState(false);

  const getLocations = async () => {
    try {
      search && setLoader(true);
      const { data } = await prospectService.getLocations(search);
      const concatData = data?.data?.map(cityCountryCombine);
      const uniqData = _.uniq(concatData);
      setOptions(uniqData.slice(0, limit));
      setList(uniqData);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    search && getLocations();
  }, [search]);

  const updateData = (newData = []) => {
    const payload = {
      ...data,
      [keyType]: {
        ...data[keyType],
        [keyFilter]: newData,
      },
    };

    setSelects(newData);
    setData({ type: 'set', payload });
    setRefresh((prevState) => prevState + 1);
  };

  const onHandleChange = (value) => {
    setSearch(value);
    let found = list.filter((item) => {
      return (
        item?.toLowerCase().includes(value.toLowerCase()) &&
        !selects.includes(value)
      );
    });

    // remove the already selected items from the list to avoid duplicates selection
    if (!value && selects.length) {
      const selectsSet = new Set(selects);
      found = list.filter((element) => {
        return !selectsSet.has(element);
      });
    }
    setOptions(found.slice(0, limit));
  };

  const onHandleSelect = (item) => {
    updateData(_.uniqBy([...selects, item]));
  };

  const onHandleRemoveSelect = (value) => {
    const items = selects.slice();
    const index = items.findIndex((item) => {
      return item.toLowerCase() === value.toLowerCase();
    });

    items.splice(index, 1);
    updateData(items);
  };

  const onHandleClear = () => {
    updateData([]);
  };

  useEffect(() => {
    onEnter();
  }, [refresh]);

  useEffect(() => {
    try {
      setSelects(data[keyType][keyFilter] || []);
    } catch (e) {}
  }, [data]);

  useEffect(() => {
    if (!Object.keys(getKeysWithData(data)).length) {
      setSelects([]);
    }
  }, [data]);

  return (
    <>
      {name && (
        <div className="mt-2 mb-2 text-capitalize font-weight-semi-bold fs-7">
          {name}
          {showLabelColon && <span>:</span>}
        </div>
      )}
      <DropdownWrapper
        placeholder={placeholder}
        onChange={onHandleChange}
        onSelect={onHandleSelect}
        options={options}
        selects={selects}
        onRemoveSelect={onHandleRemoveSelect}
        onClear={onHandleClear}
        loader={loader}
      />
    </>
  );
};

export default LocationSearch;
