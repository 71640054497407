import React, { useState } from 'react';
import Alert from '../Alert/Alert';
import AlertWrapper from '../Alert/AlertWrapper';
import HelpCenterModal from './HelpCenterModal';
import MaterialIcon from '../commons/MaterialIcon';

export default function HelpCenter() {
  const [modal, setModal] = useState(false);
  const [sentMessage, setSentMessage] = useState('');

  const toggle = () => setModal(!modal);

  return (
    <>
      <section className="ml-auto mr-2" onClick={toggle}>
        <MaterialIcon
          icon="help_outline"
          rounded
          clazz="text-gray-700 icon-hover-bg font-size-xl2 cursor-pointer"
        />
      </section>
      <HelpCenterModal
        modal={modal}
        toggle={toggle}
        setSentMessage={setSentMessage}
      />
      <AlertWrapper>
        <Alert
          color="success"
          message={sentMessage}
          setMessage={setSentMessage}
        />
      </AlertWrapper>
    </>
  );
}
