export const columnsTableCompany = [
  {
    key: 'Name',
    component: 'Company',
  },
  {
    key: 'Website',
    component: '',
  },
  {
    key: 'Location',
    component: 'Location',
  },
  {
    key: 'Details',
    component: 'Details',
  },
  {
    key: '',
    component: '',
  },
];

export const columnsTablePeople = [
  {
    key: 'Name',
    component: 'Name',
  },
  {
    key: 'Company',
    component: 'Company',
  },
  {
    key: 'Details',
    component: 'Details',
  },
  {
    key: '',
    component: '',
  },
  {
    key: '',
    component: '',
  },
];
