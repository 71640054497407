import moment from 'moment';
import routes from './routes.json';
import usaStates from '../components/organizations/Constants.states.json';
import _ from 'lodash';
import { useNewPermissionContext } from '../contexts/newPermissionContext';
import { useContext } from 'react';
import { TenantContext } from '../contexts/TenantContext';
import useIsTenant from '../hooks/useIsTenant';
import { anyRegex, phoneRegex, timeRegex } from './constants';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const formatHHMM = 'hh:mm A';
export const formatHHMMSS = 'hh:mm:ss A';
export const DATE_FORMAT_REPEAT = 'YYYY-MM-DD';
export const DATE_FORMAT_EJS = 'MM/dd/yyyy';
export const DATE_FORMAT_EJS_UPDATED = 'MM/DD/YYYY';
export const DATE_FORMAT_TIME = 'MM/DD/YYYY hh:mm:ss A';
export const DATE_FORMAT_TIME_WO_SEC = 'MM/DD/YYYY h:mm A';
export const DATE_FORMAT_MONTH = 'MMM DD, YYYY h:mm A';

export const DATE_FORMAT_Z = 'MM/DD/YYYYTHH:mm:ssZ';
export const DATE_FORMAT_DASHED_TZ = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const PROSPECT_RIGHT_PANEL_WIDTH = 468;
export const RIGHT_PANEL_WIDTH = 625;
export const RIGHT_PANEL_WIDTH_Large = 750;

export const FILE_SIZE_LIMIT = 25 * 1024 * 1024; // max 25mb allowed to upload

export const IntervalMap = {
  6: 'semi-annually',
  3: 'quarterly',
};

export const FrequencyMap = {
  YEARLY: 'annually',
  MONTHLY: 'monthly',
};

const today = new Date();

const startOfWeek = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - today.getDay()
);

const endOfWeek = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() + (6 - today.getDay())
);
// Calculate the start of last week (Monday)
const startOfLastWeek = new Date(
  today.getTime() - (today.getDay() + 6) * 24 * 60 * 60 * 1000
);

// Calculate the end of last week (Sunday)
const endOfLastWeek = new Date(
  today.getTime() - today.getDay() * 24 * 60 * 60 * 1000
);

// Format the dates as strings
export const startOfLastWeekString = startOfLastWeek
  .toISOString()
  .substring(0, 10);
export const endOfLastWeekString = endOfLastWeek.toISOString().substring(0, 10);
export const startOfWeekString = startOfWeek.toISOString().substring(0, 10);
export const endOfWeekString = endOfWeek.toISOString().substring(0, 10);
export const createBlobObject = (file) => {
  return new Blob([file], { type: file.type });
};

export const base64ToBlob = (base64) => {
  return fetch(base64).then((res) => res.blob());
};

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (mail = '') => {
  return !(mail === '' || !emailRegex.test(mail));
};
export const youTubeWistiaRegex =
  /^(?:https?:\/\/)?(?:www\.)?(?:wistia\.com\/medias\/|identifee\.wistia\.com\/medias\/|youtu\.be\/)([a-zA-Z0-9_-]+)/;

export const urlRegex =
  /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

export const isAlphanumeric = (value) => {
  if (/[^0-9a-zA-Z\s]/.test(value)) {
    return false;
  }
  return true;
};

export const isMatchInCommaSeperated = (input, value) => {
  if (
    input
      ?.toLowerCase()
      ?.match(new RegExp('(?:^|,)' + value?.toLowerCase() + '(?:,|$)'))
  ) {
    return true;
  }
  return false;
};

export const checkStringsInInput = (inputString, searchStrings) => {
  const inputList = inputString.split(',');
  for (let i = 0; i < searchStrings.length; i++) {
    const searchString = searchStrings[i];
    if (inputList.indexOf(searchString.toLowerCase()) !== -1) {
      return true;
    }
  }
  return false;
};

export const generatePath = (item, path) => {
  const viewType = item?.isPublic ? 'explore' : 'custom';
  const type = item?.isPublic ? 'explore' : 'custom';
  return `${routes.learnMain}?id=${item?.id}&viewType=${viewType}&path=${path}&title=${item?.title}&type=${type}`;
};

export const isDisplayWelcomeScreen = (modules) => {
  return (
    modules &&
    modules !== '*' &&
    isMatchInCommaSeperated(modules, 'welcome_screen')
  );
};

export const getAccountsChildPermissions = (modules, moduleName) => {
  if (modules?.includes('accounts')) {
    return `accounts_${moduleName}`;
  }
  return moduleName;
};

export const isModuleAllowed = (modules, value) => {
  if (!modules || modules === '*') {
    return true;
  } else {
    // if value is any array meaning [moduleConstant1, moduleConstant2]
    // for ref check Sidebar.constants.js
    // the prospecting sidemenu item needs to be only shown if either of those tab permissions allowed
    if (Array.isArray(value)) {
      return value.some((md) => isMatchInCommaSeperated(modules, md));
    }
    let currentValue = value;
    if (value === 'companies' || value === 'contacts') {
      currentValue = getAccountsChildPermissions(modules, value);
    }
    return isMatchInCommaSeperated(modules, currentValue);
  }
};

export const isPermissionAllowed = (
  collection,
  action,
  tenantName = '',
  permissionContext = undefined
) => {
  try {
    const { tenant } = useContext(TenantContext);
    const context = permissionContext || useNewPermissionContext();
    const { permissionChanges } = context;
    const permissionsCheck = permissionChanges.filter((item) => {
      return item.collection === collection;
    });
    const permissionAction = permissionsCheck?.find((child) => {
      return child.action === action;
    });
    if (tenantName) {
      return (
        permissionAction.collection === collection &&
        permissionAction.action === action &&
        isModuleAllowed(tenant.modules, tenantName)
      );
    } else if (permissionAction) {
      return (
        permissionAction.collection === collection &&
        permissionAction.action === action
      );
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const getIdfToken = (parsed = false) => {
  const host = window.location.host.split('.');
  const hostTokenKey = `${host[0]}-idftoken`;
  return parsed
    ? JSON.parse(localStorage.getItem(hostTokenKey) || '{}')
    : localStorage.getItem(hostTokenKey);
};

export const setIdfToken = (tokenData) => {
  const host = window.location.host.split('.');
  localStorage.setItem(host[0] + '-idftoken', tokenData);
};

export const removeIdfToken = (tokenData) => {
  const host = window.location.host.split('.');
  localStorage.removeItem(host[0] + '-idftoken');
};

export const getRootComponentName = (tenant) => {
  const today = moment().format('ddd MMMM DD');
  let moduleis = [today];
  if (
    checkStringsInInput(tenant.modules, [
      'Dashboards',
      'home',
      'new_home',
      'dashboard',
      '*',
    ])
  ) {
    moduleis = [today];
    return moduleis;
  }
  if (isModuleAllowed(tenant.modules, 'companies')) {
    return (moduleis = useIsTenant().isSynovusBank
      ? ['Insights']
      : ['Companies']);
  }
  if (isModuleAllowed(tenant.modules, 'contacts')) {
    return (moduleis = ['Contacts']);
  }
  if (isModuleAllowed(tenant.modules, 'pipelines')) {
    return (moduleis = ['Deals']);
  }
  if (isModuleAllowed(tenant.modules, 'activities')) {
    return (moduleis = ['Activities']);
  }
  if (isModuleAllowed(tenant.modules, 'reporting')) {
    return (moduleis = ['Resources']);
  }
  if (isModuleAllowed(tenant.modules, 'reporting')) {
    return (moduleis = ['Insights']);
  }
  if (isModuleAllowed(tenant.modules, 'learns')) {
    return (moduleis = ['Training']);
  }
  if (isModuleAllowed(tenant.modules, 'prospecting')) {
    return (moduleis = ['Prospecting']);
  }
  return moduleis;
};

export const isEmpty = (value) => {
  if (!value || !value.trim()) return true;

  return false;
};

export const isDefined = (value) => {
  if (!value || value === 'undefined') return false;

  return true;
};

export const checkDate = (data) => {
  const TODAY = moment.utc().startOf('day');
  const TOMORROW = moment.utc().add(1, 'day').startOf('day');
  const NOON_TODAY = TODAY.clone().add(12, 'hours');

  // IDF-6128 - avoid text red if its due today
  if (!data?.done) {
    if (
      moment.utc(data?.start_date).isSame(TODAY, 'day') &&
      moment.utc(data?.start_date).isAfter(NOON_TODAY)
    ) {
      return 'text-danger';
    }
  }

  if (moment.utc(data.start_date).isSame(TODAY, 'day')) {
    return 'text-success';
  }

  if (moment.utc(data.start_date).isSame(TOMORROW, 'day')) {
    return 'text-primary';
  }

  return '';
};

export const getTomorrowDate = () => {
  const today = new Date();
  today.setDate(today?.getDate() + 1);
  return today;
};

export const checkDueDate = (date) => {
  const currentDate = new Date();
  currentDate?.setHours(0, 0, 0, 0);
  const dateClosed = date ? new Date(date) : '';
  if (dateClosed >= currentDate) {
    return '';
  } else {
    return 'text-red';
  }
};

export const setDateFormat = (date, format = 'MM/DD/YYYY LT') => {
  return moment(date).format(format);
};

export const floorFigure = (figure, decimals = 2) => {
  const d = 10 ** decimals;
  return (parseInt(figure * d, 10) / d).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimals,
  });
};

export const valueNumberValidator = (value, decimals = 0, max, min = 0) => {
  const inputNumber = Number(value);
  if (Number.isNaN(inputNumber)) {
    value = parseFloat(value) || '';
  }

  if (max && max > min) {
    if (value > max) {
      value = parseInt(
        Math.max(Number(min), Math.min(Number(max), Number(value) / 10))
      );
    }
  }

  if (Number(value) - parseInt(value) > 0) {
    const textValue = value.toString();
    const index = textValue.indexOf('.');

    if (textValue.length - index - 1 > decimals) {
      value = Number(value).toFixed(decimals);
    }
  }

  return value;
};

export const isToFixedNoRound = (num = 0, decimals = 2) => {
  const newNum = parseFloat(num);
  if (Number.isNaN(newNum)) {
    return '$0';
  }
  return floorFigure(num, decimals);
};

export const roundOrShowAll = (value, threshold = 999999, decimals = 0) => {
  return value < threshold
    ? isToFixedNoRound(value, decimals)
    : formatNumberV2(value);
};

export const decimalToNumber = (num = 0) => {
  const newNum = parseInt(num);
  if (Number.isNaN(newNum)) {
    return '0';
  }
  return newNum;
};

export const formatNumber = (
  num,
  decimals = 2,
  mandatoryDecimals = 0,
  parseMillions = true
) => {
  const newNum = parseFloat(num);
  if (Number.isNaN(newNum)) {
    return '0';
  }
  if (parseMillions && newNum >= 1e6) {
    return `${isToFixedNoRound(newNum / 1e6, decimals)}M`;
  }

  if (mandatoryDecimals > 0) {
    return isToFixedNoRound(newNum.toFixed(mandatoryDecimals), decimals);
  }
  return isToFixedNoRound(newNum, decimals);
};

export const formatDecimalFixed = (num, decimals = 2) => {
  const newNum = parseFloat(num);
  if (Number.isNaN(newNum)) {
    return '0';
  }

  return newNum.toFixed(decimals);
};

export const formatNumberWithDecimalPoints = (number, decimals) => {
  if (!number || number === '') {
    return '$0.00';
  }

  if (number < 1000) {
    return `$${number.toFixed(decimals)}`;
  }

  const suffixes = ['', 'K', 'M', 'B', 'T', 'Q'];
  const magnitude = Math.floor((Math.log10(number) + 1) / 3);

  if (magnitude >= suffixes.length) {
    return number.toExponential(decimals);
  }

  const scaled = number / Math.pow(10, magnitude * 3);
  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  const formatted = formatter.format(scaled);

  const suffixMagnitude = magnitude < 0 ? '' : suffixes[magnitude];
  return `$${formatted}${suffixMagnitude}`;
};

// converts 2100000 to $210k etc
export const formatNumberV2 = (number, decimals) => {
  if (!number || number === '') {
    return '$0';
  }

  if (number < 1000) {
    return `$${number}`;
  }

  const suffixes = ['', 'K', 'M', 'B', 'T', 'Q'];
  const magnitude = Math.floor((Math.log10(number) + 1) / 3);

  if (magnitude >= suffixes.length) {
    return number.toExponential(2);
  }

  const scaled = number / Math.pow(10, magnitude * 3);
  const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: scaled < 10 ? 2 : scaled < 100 ? 1 : 0,
  });
  const formatted = formatter.format(scaled);

  // special handling for 100M (to ensure it displays as $100M, not $0.1B)
  if (number >= 100000000 && number < 1000000000) {
    const scaled = number / 1000000;
    return `$${scaled.toFixed(decimals)}M`;
  }
  const suffixMagnitude = magnitude < 0 ? '' : suffixes[magnitude];
  return `$${formatted}${suffixMagnitude}`;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const getFileSize = (size, rounded = false) => {
  const byteUnits = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const fileSize = Math.floor(Math.log(size) / Math.log(1024));
  const fSize = (size / Math.pow(1024, fileSize)).toFixed(2);
  return `${!isNaN(fSize) ? (rounded ? Math.round(fSize) : fSize) : 0} ${
    byteUnits[fileSize] || 'B'
  }`;
};

export const capitalizeEachWord = (inputString = '') => {
  if (!inputString) {
    return '';
  }
  const words = inputString.toLowerCase().split(' ');
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  return capitalizedWords.join(' ');
};
export const capitalize = (str = '') => {
  if (!str) {
    return '';
  }
  try {
    const sentences = str?.split('. ');

    const capitalizedSentences = sentences?.map((sentence) => {
      return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
    });

    // Join the sentences back together with a period and a space
    return capitalizedSentences.join('. ');
  } catch (e) {
    return str;
  }
};

// old method
export const numberWithCommas = (x = 0) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
/**
 * Allows us to attach a TTL to a memoized function.
 */
export const ttlMemoize = (fn) => {
  const cache = {};

  // drop keys from cache based on the ttl
  setInterval(() => {
    const keysToDrop = Object.entries(cache)
      .filter(([key, item]) => {
        if (!item.ttl) {
          return false;
        }

        const now = new Date().valueOf();
        const ttl = new Date(item.ttl).valueOf();
        return now > ttl;
      })
      .map(([key]) => key);

    keysToDrop.forEach((key) => delete cache[key]);
  }, 5000);

  return {
    memo: (...args) => {
      const argsKey = JSON.stringify(args);
      if (!cache[argsKey]) {
        cache[argsKey] = {
          fn: fn(...args),
        };
      }

      return cache[argsKey].fn;
    },
    setTTL: (ttl, ...args) => {
      const argsKey = JSON.stringify(args);
      if (!cache[argsKey]) {
        return;
      }
      cache[argsKey].ttl = ttl;
    },
  };
};

export const searchParams = (params, search) => {
  return new URLSearchParams(params).get(search);
};

export const errorsRedirectHandler = (error) => {
  if (
    error?.response?.status === 401 ||
    error?.response?.status === 400 ||
    error?.response?.status === 404 ||
    error?.response?.status === 409 ||
    error?.response?.status === 500 ||
    error?.response?.status === 403
  ) {
    throw error; // instead of returning error, just throw it here so that we can catch it from where it is being called
  } else {
    console.log(error);
  }
};

export const getProductsTotalAmount = (products) => {
  let totalAmountAcum = 0;
  products.forEach((dealProduct) => {
    const price = dealProduct.price;
    const quantity = dealProduct.quantity;
    const amount = price * quantity;
    totalAmountAcum = amount + totalAmountAcum || 0;
  });
  return totalAmountAcum;
};

// this method will split google sent address back to address/city/state separately
export const splitAddress = (item) => {
  const termsLength = item?.terms?.length;
  const stateIndex = termsLength - 2;
  const cityIndex = termsLength - 3;
  const addressIndex = item?.terms[cityIndex]?.offset - 2;
  const countryIndex = termsLength - 1;

  const state = usaStates.find(
    (state) =>
      state.abbreviation === item?.terms[stateIndex]?.value ||
      state.name === item?.terms[stateIndex]?.value
  );
  const city = item?.terms[cityIndex]?.value;
  const address = item.description.slice(0, addressIndex);
  const country = item?.terms[countryIndex]?.value;

  return { address, city, state, country };
};

export const pageTitleBeautify = (titles, separator = ' - ') => {
  return titles.join(separator);
};

export const sortByCompleted = (list, lessonOrCourse) => {
  if (lessonOrCourse === 'courseTracking') {
    return list;
  }
  return _.orderBy(list, [`${lessonOrCourse}.status`], ['desc']);
};

function getFaviconEl() {
  return document.getElementById('favicon');
}
export const changeFavIcon = (favIc) => {
  if (favIc) {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = favIc;
  }
};

export const TrainingViewTypes = {
  Lesson: 1,
  Course: 2,
};
export const TECHNOLOGIES_STORAGE_KEY = 'technologiesList';
export const INDUSTRIES_STORAGE_KEY = 'industriesList';
export const SIC_STORAGE_KEY = 'sicCodesList';
export const NAICS_STORAGE_KEY = 'naicsCodesList';
export const RESOURCES_PEOPLE_FILTER = 'resourcesPeopleFilter';
export const RESOURCES_COMPANY_FILTER = 'resourcesCompanyFilter';
export const SUMMARIZE_RECENT_SEARCHES = 'summarizeRecentSearches';

export const replaceSpaceWithCharacter = (value, ch = '-') => {
  return value?.replace(/\s+/g, ch);
};

export const TrainingFilterOptions = [
  { id: 1, key: 'updated_at', name: 'Last Modified' },
  { id: 2, key: "eq 'draft'", name: 'Draft' },
  { id: 3, key: "eq 'published'", name: 'Published' },
];

// took this from here: https://stackoverflow.com/a/43467144/2633871
export const isValidUrl = (str) => {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

// moving the scroll to top of window
export const scrollToTop = () => {
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
};

// converting values 12641 to 12.6K or 24578000000 to 24.6B etc.
export const roundNumbers = (value, display = 'short', decimals = 1) => {
  return new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: display,
    maximumFractionDigits: decimals,
  }).format(value);
};

export const numbersWithComma = (value, formatConfig) => {
  return new Intl.NumberFormat('en', formatConfig).format(value);
};

export const formatCurrencyField = (value) => {
  return typeof value === 'string' ? parseCurrency(value) : value;
};

export const parseCurrencyOrNormal = (value) => {
  if (value) {
    if (typeof value === 'string') {
      if (value.includes('$')) {
        const parsedCurrency = parseCurrency(value);
        return numbersWithComma(parsedCurrency);
      } else if (value.includes('%')) {
        return value.replace(/%/g, '');
      }
    } else {
      return numbersWithComma(value + '');
    }
  }
  return value;
};

// only get keys with data
export const getKeysWithData = (obj) => {
  const filters = {};
  for (const key in obj) {
    const item = obj[key];
    for (const sub in item) {
      if (Array.isArray(item[sub])) {
        if (item[sub].length) {
          filters[sub] = item[sub];
        }
      } else if (item[sub]?.trim()) {
        filters[sub] = [item[sub]];
      }
    }
  }
  return filters;
};

// took the following from here: https://vijayendren-r.medium.com/javascript-react-js-function-to-convert-array-json-into-a-csv-file-8315ea8f6ab2
// Function to convert the JSON(Array of objects) to CSV.
export const arrayToCsv = (headers, data) => {
  const csvRows = [];
  // getting headers.
  const headerValues = headers.map((header) => header.label);
  csvRows.push(headerValues.join(',')); // Push into array as comma separated values
  // Getting rows.
  for (const row of data) {
    const rowValues = headers.map((header) => {
      const value = row[header.key] || '';
      const escaped = ('' + value).replace(/"/g, '\\"'); // To replace the unwanted quotes.
      return `"${escaped}"`; // To escape the comma in a address like string.
    });
    csvRows.push(rowValues.join(',')); // Push into array as comma separated values.
  }
  return csvRows.join('\n'); // To enter the next rows in the new line '\n'
};

// Function to download the generated CSV as a .csv file.
const download = (data, fileName) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName + '.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateCSV = (header, data, filename) => {
  const csvData = arrayToCsv(header, data);
  download(csvData, filename);
};

export const overflowing = (extraClass) => {
  document.body.classList.remove('overflow-hidden');
  document.body.classList.add('overflow-auto');
  if (extraClass) {
    // two bootstrap modals and mui drawer getting conflict and removing modal-open class from body
    // so adding here forcefully to keep the vertical scroll when top one gets hidden
    setTimeout(() => {
      document.body.classList.add(extraClass);
    }, 50);
  }
};

// this method will clean widgets inside data so that it doesnt save and
// create more payload which is throwing error from api payload too large
export const cleanDataWidgets = (widgetsData) => {
  const newWidgets = { ...widgetsData };
  for (const key in newWidgets) {
    newWidgets[key].forEach((widget) => {
      delete widget?.widgetConfig?.data?.widgets;
    });
  }
  return newWidgets;
};
export const removeBodyScroll = () => {
  document.body.classList.remove('overflow-auto');
  document.body.classList.add('overflow-hidden');
};

export const addressify = (comp, type = 'people') => {
  if (!comp) {
    return '';
  }
  if (type === 'people') {
    const address = [comp.address_street, comp.address_city]
      .filter((a) => !!a)
      .join(', ');
    return `${address || ''} ${comp.address_state || ''} ${
      comp.address_country || ''
    } ${comp.address_postalcode || ''}`;
  } else {
    const address = [comp.address, comp.city].filter((a) => !!a).join(', ');
    return `${address || ''} ${comp.state || ''} ${comp.country || ''} ${
      comp.postal_code || ''
    }`;
  }
};

export const secondsToMinutes = (seconds) => {
  // currently support to minutes not hours
  return `${
    Math.floor(seconds / 60) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2)
  }`;
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
};

export const formatPhone = (phoneNumber) => {
  const cleaned = phoneNumber?.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
  const match = cleaned?.match(/\d{3}-\d{3}-\d{4}/g);
  if (match) {
    return match[0]; // Use index 0 to access the entire matched string
  }
  return phoneNumber;
};

export const formatUSPhoneNumber = (phoneNumber) => {
  // Remove any non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, '');

  // Check if the number starts with the US country code (1)
  if (digitsOnly.startsWith('1')) {
    // Remove the country code (1) from the number
    const formattedNumber = digitsOnly.slice(1);

    // Format the remaining number as (XXX) XXX-XXXX
    return `(${formattedNumber.slice(0, 3)}) ${formattedNumber.slice(
      3,
      6
    )}-${formattedNumber.slice(6)}`;
  } else {
    // Format the number as (XXX) XXX-XXXX
    return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(
      3,
      6
    )}-${digitsOnly.slice(6)}`;
  }
};

export const parseNaics = (str) => {
  if (!str) {
    return '';
  }

  if (!str.includes(',')) {
    return str.trim();
  }

  const splitted = str.split(', ');

  if (splitted.length === 1) {
    return splitted[0]; // return first one only in case we found one, thats a generic one
  }

  return splitted[1]; // the specific one if we found
};

export const parseCurrency = (value) => {
  if (value) {
    // Remove all non-numeric characters from the value using a regular expression
    const numericValue =
      typeof value === 'string' ? value.replace(/[^\d.-]/g, '') || '0' : value;

    // Parse the resulting string as a float and return the value
    return parseFloat(numericValue);
  }
  return 0;
};

// helper function to detect whether its browser refresh or not
export const getBrowserNavigationType = () => {
  let result;
  let p;
  if (window.performance && window.performance.navigation) {
    result = window.performance.navigation;
    if (result === 255) {
      result = 4;
    }
  }
  if (
    window.performance &&
    window.performance.getEntriesByType &&
    window.performance.getEntriesByType('navigation')
  ) {
    if (window.performance.getEntriesByType('navigation').length) {
      p = window.performance.getEntriesByType('navigation')[0].type;
      if (p === 'navigate') {
        result = 0;
      }
      if (p === 'reload') {
        result = 1;
      }
      if (p === 'back_forward') {
        result = 2;
      }
      if (p === 'prerender') {
        result = 3;
      } // 3 is my invention!
    } else {
      result = window.performance.navigation.type;
    }
  }
  return result;
};

export const parsePercentage = (value, isDecimal = true) => {
  const val = typeof value === 'string' ? Number(value) : value;
  const parsed = val.toFixed(val % 1 === 0 ? 0 : 2);

  return `${isDecimal ? parsed * 100 : parsed} %`;
};

export const hexToHSL = (H) => {
  // Convert hex to RGB first
  let r = 0;
  let g = 0;
  let b = 0;
  if (H.length === 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length === 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return {
    colorCode: h,
    hsl: 'hsl(' + h + ',' + s + '%,' + l + '%)',
  };
};

export const hslToHex = (h, s, l) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // Convert to hex and pad if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const LearnViewTypes = {
  Overview: 'overview',
  MyFavorites: 'my-learning',
  SelfAssessment: 'self-assessment',
  KnowledgeAssessment: 'knowledge-assessment',
  MyOrganization: 'organization',
  WorkingCapital: 'working-capital',
  Topics: 'explore',
  Custom: 'custom',
  AIAdvisor: 'ai-advisor',
};

export const clearMenuSelection = (itemId) => {
  const activeLinks = document.querySelectorAll(
    '.navbar.navbar-vertical-aside .active'
  );
  if (activeLinks && activeLinks.length) {
    activeLinks.forEach((item) => {
      item.classList.remove('active');
    });
  }
  document.getElementById(itemId)?.classList.add('active');
};

export const sortByPinnedTopics = (secondList) => {
  const pinnedTopics = [
    { title: 'My Organization', order: 1 },
    { title: 'Core Product & Services', order: 2 },
    { title: 'Sales Strategy & Skills', order: 3 },
    { title: 'Agile Mindset', order: 4 },
    { title: 'Faster Payments', order: 5 },
    { title: 'Working Capital', order: 6 },
    { title: 'Fraud & Risk', order: 7 },
    { title: 'Merchant Services', order: 8 },
    { title: 'Commercial Card', order: 9 },
    { title: 'Industry Insights', order: 10 },
  ];
  const filteredList = secondList.filter((topic) => {
    return !pinnedTopics.some(
      (pinnedTopic) =>
        pinnedTopic.title.toLowerCase() === topic.title.trim().toLowerCase()
    );
  });

  const pinnedTopicsApi = [];
  const pinnedTopicsTitle = pinnedTopics.map((s) => s.title.toLowerCase());
  secondList.forEach((s) => {
    if (pinnedTopicsTitle.includes(s.title.trim().toLowerCase())) {
      pinnedTopicsApi.push({
        ...s,
        order: pinnedTopics.find(
          (s2) => s2.title.toLowerCase() === s.title.trim().toLowerCase()
        )?.order,
      });
    }
  });
  pinnedTopicsApi.sort((a, b) => {
    return a.order - b.order;
  });
  return pinnedTopicsApi.concat(filteredList);
};

export const formatCurrency = (inputValue) => {
  const numericValue = inputValue.replace(/[^0-9.]/g, '');

  const [integerPart, decimalPart] = numericValue.split('.');

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );

  const formattedValue =
    decimalPart !== undefined
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart;

  return formattedValue;
};

export const dateWithoutTZ = (dateWithTZ, format = DATE_FORMAT) => {
  return moment(dateWithTZ?.split('T')[0]).format(format);
};

export const isModuleChecked = (module, checkedItems) => {
  const moduleName = module?.name?.toLowerCase().replace(/ /g, '_');
  return checkedItems[moduleName] === true;
};

export const CompanyRelated = {
  Parent: { name: 'Parent', label: 'Parent' },
  Daughter: { name: 'Daughter', label: 'Child' },
  Child: { name: 'Daughter', label: 'Child' },
  Sibling: { name: 'Sibling', label: 'Sister' },
  Related: { name: 'Related', label: 'Affiliated' },
};

export const CompanyRelations = [
  { id: 0, ...CompanyRelated.Parent },
  { id: 1, ...CompanyRelated.Daughter },
  { id: 2, ...CompanyRelated.Related },
];

export const queryStringToObject = (queryString) => {
  const params = new URLSearchParams(queryString);
  const result = {};
  params.forEach((value, key) => {
    result[key] = value;
  });
  return result;
};

export const checkValidDate = (value) => {
  const date = new Date(value);
  return !isNaN(date.getTime());
};

export const formatText = (text) => {
  // Remove underscores and split the text into an array of words
  const words = text.split('_');

  // Capitalize each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back into a single string with spaces
  const formattedText = capitalizedWords.join(' ');

  return formattedText;
};
export const getPattern = (field_type) => {
  if (field_type === 'EMAIL') {
    return emailRegex;
  } else if (field_type === 'PHONE') {
    return phoneRegex;
  } else if (field_type === 'TIME') {
    return timeRegex;
  } else if (field_type === 'URL') {
    return urlRegex;
  } else {
    return anyRegex;
  }
};

export const getPatternErrorMessage = (field_type) => {
  if (field_type === 'EMAIL') {
    return 'Invalid email address.';
  } else if (field_type === 'PHONE') {
    return 'Phone number must be (123)-123-1234.';
  } else if (field_type === 'TIME') {
    return 'Time must be HH:MM:SS AM/PM.';
  } else if (field_type === 'URL') {
    return 'Invalid URL.';
  } else {
    return 'Invalid Input.';
  }
};

export const detectAndExtractNaicsOrSic = (str) => {
  if (str) {
    const regex = /\((.*?)\)/;
    const match = str?.match(regex);
    if (match !== null) {
      return match[1];
    }
    return str;
  }
  return '';
};
export const extractNaicsTitle = (str) => {
  return str ? str.replace(/[^a-zA-Z\s]/g, '') : '';
};

export const getNaicsWithTitle = (reportObject, company, onlyNaics) => {
  if (!reportObject?.valueN?.length && !company.naics_code) {
    return '';
  }
  const naics = reportObject?.valueN?.length
    ? reportObject?.valueN[0]
    : `(${company.naics_code})${company.industry}`;
  let naicsOnly = detectAndExtractNaicsOrSic(naics);
  let naicsTitle = extractNaicsTitle(naics) || reportObject?.industry;
  if (!naicsTitle) {
    naicsTitle = company.industry;
  }
  if (!naicsOnly) {
    naicsOnly = company.naics_code;
  }
  return onlyNaics ? naicsOnly : [`(${naicsOnly})${naicsTitle}`];
};

export const handleEmptySpaceAtStart = (e) => {
  if (e.target.value === '') {
    if (e.which === 32) {
      e.preventDefault();
    }
  }
};
export const parseRRuleString = (rRuleString = '') => {
  const rRuleObj = {};
  const rRuleWithoutPrefix = rRuleString?.replace('RRULE:', '');
  const parts = rRuleWithoutPrefix?.split(';');

  parts?.forEach((part) => {
    const [key, value] = part?.split('=');
    rRuleObj[key] = value;
  });

  return rRuleObj;
};

// this is a temp solution, until i will do this proper via react-hook-form
export const checkIfNaicsOrReportDateEmpty = (
  report,
  errorFields,
  setErrorFields,
  fieldKeys = ['companyName', 'reportDate']
) => {
  const newErrorFields = {
    name: { ...errorFields.name, isShow: !report[fieldKeys[0]] },
    naics: { ...errorFields.naics, isShow: !report.valueNaicsSic },
    reportDate: { ...errorFields.reportDate, isShow: !report[fieldKeys[1]] },
  };
  setErrorFields(newErrorFields);
  return Object.values(newErrorFields).some((field) => field.isShow);
};

const LessonLabel = {
  Foundational: 'Foundational',
  Essentials: 'Essentials',
  Advanced: 'Advanced',
  Elective: 'Elective',
};
export const LessonLabels = Object.keys(LessonLabel).map((key) => ({
  key,
  name: key,
}));

export const getNAICSWithIndustry = (organization) => {
  return {
    valueN: organization?.naics_code
      ? [`(${organization.naics_code}) ${organization?.industry?.trim()}`]
      : [],
    valueNaicsSic: organization.naics_code,
  };
};

export const chunkArray = (array, chunkSize) => {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }
  return results;
};

export const getYearFromMonth = (d) => {
  try {
    if (!d) {
      return '';
    }
    if (d.year) {
      return d.year.slice(-2);
    }
    return d.month.slice(-2);
  } catch (e) {
    return '';
  }
};

export const getYesterdayDate = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today;
};

export const sortObjectKeys = (obj) => {
  const sortedKeys = Object.keys(obj).sort((a, b) => a.localeCompare(b));

  const normalEntries = [];
  const editedEntries = [];

  sortedKeys.forEach((key) => {
    if (key === 'Cash Vault') {
      return;
    }
    if (obj[key].isEdited) {
      editedEntries.push([key, obj[key]]);
    } else {
      normalEntries.push([key, obj[key]]);
    }
  });

  const combinedEntries = [...normalEntries];

  // comerica request to place Cash Vault at the end.
  if (Object.hasOwn(obj, 'Cash Vault')) {
    combinedEntries.push(['Cash Vault', obj['Cash Vault']]);
  }

  combinedEntries.push(...editedEntries);

  const result = combinedEntries.reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  return result;
};

export const DetailTabs = {
  Data: 1,
  Timeline: 2,
  Notes: 3,
  Checklist: 4,
  Activities: 5,
  Files: 6,
  Products: 7,
  Contacts: 8,
  Opportunities: 9,
  StageHistory: 10,
};

export const MultipleSources = 'Multiple Sources';

export const extractNameParts = (fullName) => {
  let nameParts = { first_name: fullName };
  if (fullName?.includes(' ')) {
    const [first_name, last_name] = fullName.split(' ');
    nameParts = { first_name, last_name };
  }
  return nameParts;
};

export const getDifferenceWidgetVisibility = (widgetConfig) => {
  if (!Object.hasOwn(widgetConfig?.data, 'enableDifferenceWidget')) {
    return true;
  }
  return widgetConfig?.data?.enableDifferenceWidget;
};

export const transformTo2D = (arr, columns = 2) => {
  const result = [];
  for (let i = 0; i < arr.length; i += columns) {
    result.push(arr.slice(i, i + columns));
  }
  return result;
};
