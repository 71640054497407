import authService from '../services/auth.service';
import { Spinner } from 'reactstrap';
import AlertWrapper from '../components/Alert/AlertWrapper';
import Alert from '../components/Alert/Alert';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import ButtonIcon from '../components/commons/ButtonIcon';
import { validateEmail } from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';
import BrandLogoIcon from '../components/sidebar/BrandLogoIcon';
import PageTitle from '../components/commons/PageTitle';
import useUrlSearchParams from '../hooks/useUrlSearchParams';
import useTenantTheme from '../hooks/useTenantTheme';

const ClientLogin = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const { tenant } = useTenantContext();
  const params = useUrlSearchParams();

  const sendEmail = async (email, organizationId) => {
    try {
      // validate email
      if (validateEmail(email)) {
        setEmail(email);
        setLoading(true);
        await authService.guestToken(
          email,
          organizationId || params.get('organizationId'),
          tenant.clientPortalUrl
        );
        setEmailSent(true);
      } else {
        setErrorMessage('Your email is not valid');
      }
    } catch (error) {
      setErrorMessage('Your email is not valid');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const emailContact = params.get('email');
      const organizationId = params.get('organizationId');
      if (emailContact) {
        tenant?.clientPortalUrl && sendEmail(emailContact, organizationId);
      }
      sessionStorage.removeItem('idftoken-public');
      sessionStorage.removeItem('tokenPayload');
      sessionStorage.removeItem('organizationObj');
    })();
  }, [history.location, tenant]);

  async function handleSubmit(e) {
    e.preventDefault();
    sendEmail(email);
  }
  useTenantTheme();
  return (
    <>
      <PageTitle page={'Client Login'} pageModule="" />
      <main id="content" role="main" className="main public-layout">
        <div className="container py-5 py-sm-7">
          <div className="d-flex justify-content-center mt-5 mb-5">
            <BrandLogoIcon tenant={tenant} width={160} />
          </div>

          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <h3 className="text-center mb-3">
                {emailSent ? 'Check Your Email' : 'Welcome Back!'}
              </h3>
              <div className="card mb-5">
                <div className="card-body">
                  {!emailSent ? (
                    <form className="js-validate" onSubmit={handleSubmit}>
                      <p>
                        Please enter your email address to receive activation
                        link.
                      </p>
                      <label className="input-label font-weight-medium font-size-sm2">
                        Email Address
                      </label>
                      <div className="js-form-message form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="signinEmail"
                          tabIndex="0"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>

                      <button
                        type="submit"
                        className="btn btn-md btn-block btn-primary"
                      >
                        {loading ? (
                          <Spinner className="spinner-grow-xs" />
                        ) : (
                          <span>Continue</span>
                        )}
                      </button>
                    </form>
                  ) : (
                    <>
                      <div className="font-size-sm2">
                        <p>
                          We&apos;ve sent you an email with an activation link.
                          Link will expire in 24 hours.
                        </p>
                        <p>
                          Can&apos;t find the email? Try checking your spam
                          folder.
                        </p>
                        <p>
                          If you still can&apos;t log in, have us{' '}
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              setEmailSent(false);
                            }}
                            className="font-weight-bold cursor-pointer text-primary"
                          >
                            resend the email.
                          </a>
                        </p>
                      </div>
                      <ButtonIcon
                        color="primary"
                        classnames="mt-3 btn-md btn-block"
                        icon=""
                        onclick={() => {
                          setEmailSent(false);
                          setEmail('');
                        }}
                        label="Return to Login"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
    </>
  );
};

export default ClientLogin;
