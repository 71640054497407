import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { numbersWithComma } from '../utils/Utils';

const Counter = ({ max }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 500) {
      setCount(max); // skip the increment and set to max directly if max is greater than 500
      return;
    }
    const interval = setInterval(() => {
      if (count < max) {
        setCount(count + 1);
      }
    }, 2);

    return () => clearInterval(interval);
  }, [count, max]);

  return <span>{numbersWithComma(count)}</span>;
};

const TableFooterStats = ({ stats, loading = false }) => {
  return (
    <>
      {loading ? (
        <div className="d-flex p-2 justify-content-center align-items-center ">
          <Skeleton width={120} height={10} />
        </div>
      ) : (
        <div className="stats">
          <ul>
            {stats
              ?.sort((a, b) => a.order - b.order)
              ?.map((item) => (
                <li
                  key={item.id}
                  className="d-flex fs-7 align-items-center justify-content-center gap-1"
                >
                  <label className="mb-0 fs-7 font-weight-normal cursor-pointer">
                    {item.label}
                  </label>
                  <span className="text-gray-400">•</span>
                  <label className="mb-0 font-weight-bold cursor-pointer text-black">
                    <Counter max={item.count} />
                  </label>
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default TableFooterStats;
