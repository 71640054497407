import React, { useState, useEffect } from 'react';
import { Card, FormCheck } from 'react-bootstrap';

import {
  paginationDefault,
  SEARCH_COURSES,
  SERVER_ERROR,
} from '../../../utils/constants';
import stringConstants from '../../../utils/stringConstants.json';
import CoursesTable from '../../../components/courses/CoursesTable';
import courseService from '../../../services/course.service';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import LayoutHead from '../../../components/commons/LayoutHead';
import { sortingTable } from '../../../utils/sortingTable';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import TableSkeleton from '../../../components/commons/TableSkeleton';
import { DataFilters } from '../../../components/DataFilters';
import { formatText, TrainingFilterOptions } from '../../../utils/Utils';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import ModalCreateCourse from '../../../components/modal/ModalCreateCourse';
import { useProfileContext } from '../../../contexts/profileContext';
const constants = stringConstants.settings.resources.courses;
const ListCourses = ({ setCreate, setId }) => {
  const [courses, setCourses] = useState([]);
  const [coursesSelect, setCoursesSelect] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [filterSelected, setFilterSelected] = useState({});
  const [dataInDB, setDataInDB] = useState(false);
  const [order, setOrder] = useState([['updated_at', 'DESC']]);
  const [selectAll, setSelectAll] = useState(false);
  const [coursesToDelete, setCoursesToDelete] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paginationPage, setPaginationPage] = useState(paginationDefault);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [orgCheck, setOrgCheck] = useState(true);

  const [filterOptionSelected, setFilterOptionSelected] = useState({
    id: 1,
    key: 'updated_at',
    name: 'Last Modified',
  });
  const { profileInfo } = useProfileContext();
  const roleInfo = profileInfo?.role;
  const isAdmin = roleInfo?.admin_access;
  const restrictByCondition =
    !isAdmin && orgCheck ? 'private' : isAdmin ? 'externalPublic' : '';

  const findCourses = async (count) => {
    setLoading(true);
    const data = await courseService
      .getCourses({
        page: paginationPage.page,
        limit: 10,
        ...(restrictByCondition && { restrictBy: restrictByCondition }),
        deleted: false,
        order,
        ...filterSelected,
      })
      .catch((e) => setErrorMessage(SERVER_ERROR));

    const { data: courses, pagination } = data;

    setPaginationData(pagination);
    setCourses(courses);
    if (count) setDataInDB(Boolean(pagination?.totalPages));
    setLoading(false);
  };

  const onHandleChangePage = (page) => {
    setPaginationPage((prevState) => ({ ...prevState, page }));
  };

  const onHandleFilterCourse = (e, option) => {
    e.preventDefault();
    setFilterOptionSelected(option);
    setPaginationPage((prev) => ({ ...prev, page: 1 }));
    if (option.key === 'updated_at') {
      setFilterSelected({
        order: [['updated_at', 'DESC']],
      });
    } else {
      setFilterSelected({
        status: option.key,
      });
    }
  };

  const onHandleSetSelectedCourses = (items) => {
    console.log(items);
    setCoursesSelect(items);
  };

  const onHandleRedirectCourses = (item) => {
    if (isAdmin || (!isAdmin && !item.isPublic)) {
      setId(item.id);
      setCreate(true);
    }
  };

  useEffect(() => {
    if (!orgCheck || orgCheck) {
      findCourses(true);
    }
  }, [filterSelected, paginationPage, order, orgCheck]);

  const sortTable = ({ name }) =>
    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);

  const clearSelection = () => {
    setSelectAll(false);
    setCoursesSelect([]);
  };

  const handleDelete = (course) => {
    setCoursesToDelete([course]);
    setShowDeleteModal(true);
  };

  const handleDeleteCourse = async () => {
    const course = coursesToDelete[0];
    try {
      await courseService.deleteCourses([course?.id]);
      findCourses(true);
      setSuccessMessage('Course Deleted');
    } catch (error) {
      if (error.response.status === 409) {
        setErrorMessage(formatText(error.response.data.errors[0].message));
      }
    } finally {
      setCoursesToDelete([]);
      setShowDeleteModal(false);
    }
  };
  const permissions = {
    collection: 'courses',
    action: 'create',
  };

  return (
    <div>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <DeleteConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        setSelectedCategories={setCoursesToDelete}
        event={handleDeleteCourse}
        itemsConfirmation={coursesToDelete}
        description={`Are you sure you want to delete this course: ${coursesToDelete[0]?.title}`}
        itemsReport={[]}
      />
      <ModalCreateCourse
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        onCreate={onHandleRedirectCourses}
        setSuccessMessage={setSuccessMessage}
        successMessage={successMessage}
      />
      <Card className="mb-5 rounded-0 card-borderinline shadow-none border-0">
        <Card.Header className="border-0 py-2 w-100">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <div>
              {!isAdmin && (
                <div>
                  <FormCheck
                    id={'orgCheck'}
                    name={'orgCheck'}
                    type="switch"
                    custom={true}
                    label="My Organization"
                    checked={orgCheck}
                    onChange={() => setOrgCheck(!orgCheck)}
                  />
                </div>
              )}
            </div>
            <LayoutHead
              onHandleCreate={() => {
                setShowCreateModal(true);
              }}
              buttonLabel={constants.addCourse}
              selectedData={coursesSelect}
              onClear={clearSelection}
              dataInDB={dataInDB}
              permission={permissions}
            >
              <DataFilters
                filterSelected={filterSelected}
                searchClasses="col-md-12"
                setFilterSelected={setFilterSelected}
                searchPlaceholder={SEARCH_COURSES}
                paginationPage={paginationData}
                setPaginationPage={setPaginationData}
              />
              <ButtonFilterDropdown
                options={TrainingFilterOptions}
                filterOptionSelected={filterOptionSelected}
                handleFilterSelect={onHandleFilterCourse}
              />
            </LayoutHead>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          {loading && <TableSkeleton cols={5} rows={10} />}
          {!loading && (
            <CoursesTable
              data={courses}
              paginationInfo={paginationData}
              onPageChange={onHandleChangePage}
              selectedCourses={coursesSelect}
              setSelectedCourses={onHandleSetSelectedCourses}
              dataInDB={dataInDB}
              sortingTable={sortTable}
              sortingOrder={order}
              orgCheck={orgCheck}
              setCreate={() => {
                setShowCreateModal(true);
              }}
              permission={permissions}
              onClickRow={(item) => {
                onHandleRedirectCourses(item);
              }}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              handleDelete={handleDelete}
            />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ListCourses;
