import { AccountTypes } from './account.structure.constants';
import React from 'react';

const FlowChartLegends = () => {
  const legends = Object.entries(AccountTypes).map((obj) => ({
    ...obj[1],
  }));
  return (
    <div className="legends position-absolute w-100 bottom-0">
      <div className="d-flex align-items-center justify-content-center gap-3">
        {legends.map((leg) => (
          <div key={leg.id} className="d-flex align-items-center gap-1">
            <span
              style={{ height: 8, width: 8 }}
              className={`rounded-circle ${leg.color}`}
            />
            <span className="fs-11 text-black">{leg.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlowChartLegends;
