import ButtonIcon from '../../commons/ButtonIcon';
import MaterialIcon from '../../commons/MaterialIcon';

const IconLabel = ({ label, reverse }) => {
  return (
    <div
      className={`d-flex align-items-center gap-1 ${
        reverse ? 'flex-row-reverse' : ''
      }`}
    >
      <span>{label}</span>
      <MaterialIcon icon="add_circle" size="fs-6" rounded filled />
    </div>
  );
};
const AddNewButton = ({ label, onClick, extra = 'btn-xs', reverse }) => {
  return (
    <ButtonIcon
      label={<IconLabel label={label} reverse={reverse} />}
      onclick={onClick}
      type="button"
      classnames={`bg-primary-soft bg-primary-soft-hover-dark text-primary rounded ${extra}`}
      color=""
    />
  );
};

export default AddNewButton;
