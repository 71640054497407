import React, { useEffect, useState } from 'react';
import { Card, CardHeader } from 'reactstrap';
import dashboardService from '../../../../../services/dashboard.service';
import { ReportSkeletonLoader } from '../../../ReportSkeletonLoader';
import UserTranscriptDashboard from '../../../components/InsightsQueryUserTranscript';
import { cubeService } from '../../../../../services';
import { useTenantContext } from '../../../../../contexts/TenantContext';
import { OverviewWidget } from './OverviewWidget';
import OverviewAssignedTable from './OverviewAssignedTable';
import OverviewActivityDetail from './OverviewActivityDetail';
import OverviewSelfDirectedTable from './OverviewSelfDirectedTable';

const TrainingOverview = ({ dashboard }) => {
  const renderComponents = [
    {
      name: 'Top 5 Lessons by Engagement Rate',
      render: OverviewAssignedTable,
    },
    {
      name: 'Total Lessons Completed',
      render: OverviewWidget,
    },
  ];
  const [loading, setLoading] = useState(false);
  const [csvBtnLoad, setCSVBtnLoad] = useState(false);
  const [data, setData] = useState([]);
  const [selfDirected, setSelfDirected] = useState([]);
  const [selfDirectedLoading, setSelfDirectedLoading] = useState(false);

  const [activityResults, setActivityResults] = useState([]);
  const [dashboardComponents, setDashboardComponents] = useState([]);
  const [query, setQuery] = useState({});
  const { tenant } = useTenantContext();
  const getDashboards = async () => {
    setLoading(true);

    const data = await dashboardService.getAnalytics();

    setLoading(false);

    const updatedData = data
      ?.filter((item) => {
        const matchedComponent = renderComponents?.find(
          (rc) => rc.name === item?.name
        );
        return !!matchedComponent;
      })
      .map((item) => {
        const matchedComponent = renderComponents?.find(
          (rc) => rc.name === item?.name
        );
        return { ...item, renderComponent: matchedComponent };
      });

    setDashboardComponents(updatedData);
  };

  useEffect(() => {
    getDashboards();
  }, []);

  const statsDetail = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'AssignmentStats',
    });
    setData(results);
    setLoading(false);
  };

  const getSelfDirectedDetails = async () => {
    setSelfDirectedLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'Leaderboard',
      limit: 10,
    });
    setSelfDirected(results);
    setSelfDirectedLoading(false);
  };

  const getActivityDetails = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'AssignmentTeamStats',
      limit: 10,
    });
    setActivityResults(results);
    setLoading(false);
  };

  useEffect(() => {
    statsDetail();
    getSelfDirectedDetails();
    getActivityDetails();
  }, []);

  const engagementComponent = dashboardComponents?.find(
    (component) =>
      component?.component?.name === 'Top 5 Lessons by Engagement Rate'
  );

  return (
    <Card className={`overflow-x-hidden overflow-y-auto h-auto`}>
      <CardHeader>
        <div
          className={`d-flex justify-content-between align items center w-100 ${
            csvBtnLoad ? 'mr-5' : ''
          }`}
        >
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            {dashboard?.name}
          </h4>
        </div>
      </CardHeader>
      <div className="p-0">
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={15} />
          </div>
        ) : (
          <div className="d-flex learn-dashboard mx-0 my-4 row">
            <UserTranscriptDashboard
              dashboardComponents={dashboardComponents}
              query={query}
              dashboard={dashboard}
              engagementComponent={engagementComponent}
              setCSVBtnLoad={setCSVBtnLoad}
              tenant={tenant}
              setQuery={setQuery}
              assignmentData={data}
              customClass="learn-report-card"
              width={`col-md-4 ml-0`}
            />
            <OverviewSelfDirectedTable
              width={`pl-0 pr-2 col-md-4 learn-report-card`}
              data={selfDirected}
              selfDirectedLoading={selfDirectedLoading}
              title={'Top 10 Users by Lessons Completed'}
            />
            <OverviewActivityDetail
              width={`px-2 col-md-4 learn-report-card`}
              data={activityResults}
              title="Assignments Activity By Team"
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default TrainingOverview;
