import SectionWrapper from './SectionWrapper';
import { Col, Row } from 'reactstrap';
import { formatCurrencyField } from '../../../utils/Utils';
import { InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import React, { useEffect, useState } from 'react';
import {
  AccountStructureTabMap,
  generateOneYearBalanceRecords,
} from './account.structure.constants';
import { usePagesContext } from '../../../contexts/pagesContext';

const Balances = ({ report, setReport }) => {
  const { pageContext, setPageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  const [balances, setBalances] = useState(
    pageContext?.AccountStructureReportModal[jsonKey]?.balances?.length
      ? pageContext?.AccountStructureReportModal[jsonKey]?.balances
      : generateOneYearBalanceRecords()
  );
  const handleInputChange = (id, value) => {
    const updatedBalances = [...balances].map((fs) =>
      fs.id === id ? { ...fs, value } : { ...fs }
    );
    setBalances(updatedBalances);
  };
  useEffect(() => {
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: {
        ...pageContext.AccountStructureReportModal,
        [jsonKey]: {
          ...pageContext.AccountStructureReportModal[jsonKey],
          balances,
        },
      },
    });
  }, [balances]);
  return (
    <div className="w-100">
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <p className="mb-0 fs-7">
          Balances for last <b>12 Months </b>
        </p>
      </div>
      <SectionWrapper columns={['Month', 'Average Balance']}>
        {balances?.map((acnt) => (
          <Row
            key={acnt.id}
            className="fs-7 bg-hover-gray mx-0 py-2 px-0 border-bottom border-white"
          >
            <Col md={6} className="pl-2 align-self-center">
              <span>{acnt.month}</span>
            </Col>
            <Col md={6}>
              <InputGroup className="align-items-center">
                <InputGroup.Prepend className="bg-white p-0 rounded">
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Prepend>
                <CurrencyInput
                  name={`num-${acnt.id}`}
                  placeholder="0"
                  value={formatCurrencyField(acnt.value) || ''}
                  className="form-control"
                  onValueChange={(value, name, values) =>
                    handleInputChange(acnt.id, value)
                  }
                />
              </InputGroup>
            </Col>
          </Row>
        ))}
      </SectionWrapper>
    </div>
  );
};

export default Balances;
