import React, { Component } from 'react';
import { secondsToMinutes } from '../utils/Utils';

class WistiaEmbed extends Component {
  constructor(props) {
    super(props);
    const { hashedId, onVideoLoaded, isChannel, ...embedOptions } = {
      ...this.props,
    };
    if (typeof window !== `undefined`) {
      if (isChannel) {
        window._wcq = window._wcq || [];
        window._wcq.push({
          id: hashedId,
          options: embedOptions,
          onHasData: (video) => {
            this.handle = video;
            const newVideo = {
              data: { media: { name: video._galleryData.series[0].title } },
            };
            onVideoLoaded && onVideoLoaded(newVideo);
          },
        });
      } else {
        window._wq = window._wq || [];
        window._wq.push({
          id: hashedId,
          options: embedOptions,
          onHasData: (video) => {
            this.handle = video;
          },
          onReady: (video) => {
            onVideoLoaded &&
              onVideoLoaded(video, secondsToMinutes(video.data.media.duration));
          },
        });
      }
    }
  }

  _renderCommon() {
    const { hashedId, isChannel } = this.props;
    const embedUrl = isChannel
      ? `https://fast.wistia.com/embed/channel/${hashedId}/swatch`
      : `https://fast.wistia.com/embed/medias/${hashedId}/swatch`;
    return (
      <div
        className="wistia_swatch"
        style={{
          height: '100%',
          left: 0,
          opacity: 0,
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          transition: 'opacity 200ms',
          width: '100%',
        }}
      >
        <img
          src={embedUrl}
          style={{
            filter: 'blur(5px)',
            height: '100%',
            objectFit: 'contain',
            width: '100%',
          }}
          alt=""
          aria-hidden="true"
        />
      </div>
    );
  }

  _renderResponsive() {
    const { hashedId, padding, isChannel } = this.props;
    const classNames = isChannel
      ? `wistia_channel wistia_async_${hashedId} mode=inline`
      : `wistia_embed wistia_async_${hashedId} videoFoam=true`;
    return (
      <div
        className="wistia_responsive_padding"
        style={{
          padding,
          position: 'relative',
        }}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{
            left: '0',
            position: 'absoulte',
            top: 0,
          }}
        >
          <div
            className={classNames}
            style={{ height: '100%', width: '100%', position: 'relative' }}
          >
            {this._renderCommon()}
          </div>
        </div>
      </div>
    );
  }

  _renderFixed() {
    const { width, height, hashedId } = this.props;
    const style = {
      height: height || '480px',
      width: width || '720px',
      position: 'relative',
    };
    return (
      <div className={`wistia_embed wistia_async_${hashedId}`} style={style}>
        {this._renderCommon()}
      </div>
    );
  }

  render() {
    const { isResponsive } = this.props;
    return isResponsive ? this._renderResponsive() : this._renderFixed();
  }

  componentDidMount() {
    const { isChannel } = this.props;

    if (!document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
      if (isChannel) {
        const wistiaChannelScript = document.createElement('script');
        wistiaChannelScript.id = 'wistia_channel_script';
        wistiaChannelScript.type = 'text/javascript';
        wistiaChannelScript.src =
          'https://fast.wistia.com/assets/external/channel.js';
        wistiaChannelScript.async = true;
        document.body.appendChild(wistiaChannelScript);
      }
    }
  }

  componentWillUnmount() {
    // this.handle && this.handle.remove(); // why?
  }
}

WistiaEmbed.defaultProps = {
  isResponsive: true,
  onVideoLoaded: () => {},
};

export default WistiaEmbed;
