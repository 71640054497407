import { useEffect, useState } from 'react';
import fieldService from '../../services/field.service';
import { useForm } from 'react-hook-form';
import Loading from '../Loading';
import RightPanelModal from '../modal/RightPanelModal';
import { groupBy } from 'lodash';
import { RIGHT_PANEL_WIDTH, overflowing } from '../../utils/Utils';
import OverviewForm from '../peopleProfile/overview/OverviewForm';

const EditPeople = ({
  data,
  getProfileInfo,
  isPrincipalOwner,
  labelType,
  moduleMap,
  editModal,
  setEditModal,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const { reset } = useForm({
    defaultValues: data,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [isFieldsData, setIsFieldsData] = useState([]);
  const [fieldData, setFieldsData] = useState([]);
  const currentView = 'contact';
  const groupBySection = (fieldsList) => {
    setIsFieldsData(groupBy(fieldsList, 'section'));
  };

  const getFields = async () => {
    setIsLoading(true);
    try {
      const { data } = await fieldService.getFields(currentView, {
        usedField: true,
      });
      setFieldsData(data);
      groupBySection(data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFields();
  }, [data]);

  const onClose = () => {
    setEditModal(false);
    reset(data);
    overflowing();
  };

  return (
    <>
      <RightPanelModal
        showModal={editModal}
        setShowModal={() => onClose()}
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={RIGHT_PANEL_WIDTH}
        containerPosition={'position-fixed'}
        headerBgColor="bg-gray-5"
        Title={
          <div className="d-flex py-2 align-items-center">
            <h3 className="mb-0">{`Edit ${moduleMap.contact.singular}`}</h3>
          </div>
        }
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {moduleMap.contact && (
              <OverviewForm
                onClose={onClose}
                moduleMap={moduleMap}
                labelType={labelType}
                overviewData={data}
                fieldData={fieldData}
                editMode={editMode}
                setEditMode={setEditMode}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
                getProfileInfo={getProfileInfo}
                isFieldsData={isFieldsData}
              />
            )}
          </>
        )}
      </RightPanelModal>
    </>
  );
};

export default EditPeople;
