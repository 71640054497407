import React, { useEffect, useState } from 'react';
import { Card, CardHeader } from 'reactstrap';
import UserTranscriptLessonProgress from './UserTranscriptLessonProgress';
import ButtonFilterDropdown from '../../../../../components/commons/ButtonFilterDropdown';
import { reportPages } from '../../../../../utils/constants';
import SingleSelectionDropdown from '../../../../../components/commons/SingleSelectionDropdown';
import userService from '../../../../../services/user.service';
import { NoUserSelected } from '../../../components/queries/NoUserSelected';
import { UserTranscriptProgress } from '../../UserTranscriptProgress';
import UserTranscriptStats from './UserTranscriptStats';

const TrainingUserTranscript = ({ dashboard, componentHeight = 'h-100' }) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    (async () => {
      const { data } = await userService.getUsers(
        { status: 'active', search: searchQuery },
        { page: 1, limit: 10 }
      );
      setUsers(data.users);
    })();
  }, [searchQuery]);

  const [queryFilter, setQueryFilter] = useState({
    filters: [],
  });

  const [reportPage, setReportPage] = useState(reportPages[0]);

  const handleOptionSelect = (item) => {
    if (item?.id) {
      setQueryFilter({
        filters: [
          { member: 'User.id', operator: 'equals', values: [item?.id] },
        ],
      });
    } else {
      setQueryFilter({
        filters: [],
      });
    }
  };

  return (
    <Card className={`overflow-x-hidden overflow-y-auto ${componentHeight}`}>
      <CardHeader>
        <div className="d-flex justify-content-between align items center w-100">
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            {dashboard?.name}
          </h4>
          <div className="d-flex align-items-center gap-2">
            <SingleSelectionDropdown
              selectedData={selectedItem}
              data={users}
              show={show}
              label={`User`}
              scrollable="pr-3 text-left"
              setShow={setShow}
              showCheckAll={false}
              setSelectedData={setSelectedItem}
              search={true}
              customKey={['name', 'id']}
              customStyle={{ width: '340px' }}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleOptionSelected={handleOptionSelect}
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              btnToggleStyle="btn-md"
              options={reportPages}
              filterOptionSelected={reportPage}
              handleFilterSelect={(e, item) => {
                setReportPage(item);
              }}
            />
          </div>
        </div>
      </CardHeader>
      <div className="py-3 px-0">
        <>
          {queryFilter?.filters?.length > 0 ? (
            <div className="p-0">
              <UserTranscriptProgress userFilters={queryFilter?.filters} />{' '}
              <UserTranscriptStats userFilters={queryFilter?.filters} />
              <UserTranscriptLessonProgress
                userFilters={queryFilter?.filters}
                limit={reportPage}
              />
            </div>
          ) : (
            <NoUserSelected label="user" />
          )}
        </>
      </div>
    </Card>
  );
};

export default TrainingUserTranscript;
