const NumberedTabs = ({ tabs, onSelect }) => {
  return (
    <nav className="d-flex gap-2 align-items-center">
      {tabs.map((tab, index) => (
        <a
          key={tab.id}
          className={`${
            tab.isActive ? 'bg-primary-soft tab-selected' : 'text-gray-700'
          } p-2 rounded-lg fw-semibold cursor-pointer fs-8 bg-primary-soft-hover`}
          onClick={() => onSelect(tab)}
        >
          <div className="d-flex align-items-center cursor-pointer gap-1">
            <span
              style={{ height: 20, width: 20 }}
              className={`${
                tab.isActive
                  ? 'bg-primary text-white'
                  : 'bg-gray-300 text-gray-700'
              } tab-number text-center d-flex align-items-center justify-content-center rounded-circle`}
            >
              {index + 1}
            </span>
            <label className="mb-0 tab-name cursor-pointer">{tab.name}</label>
          </div>
        </a>
      ))}
    </nav>
  );
};

export default NumberedTabs;
