import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import stringConstants from '../../utils/stringConstants.json';
import { sortingTable } from '../../utils/sortingTable';
import routes from '../../utils/routes.json';
import Table from '../GenericTable';
import TableSkeleton from '../commons/TableSkeleton';
import activityService from '../../services/activity.service';
import { dateWithoutTZ, isPermissionAllowed } from '../../utils/Utils';
import { Link } from 'react-router-dom';
import MaterialIcon from '../commons/MaterialIcon';
import MoreActions from '../MoreActions';
import TableActions from '../commons/TableActions';
import { ActivityDetail } from './activityDetail';
import OwnerAvatar from './OwnerAvatar';
import DeleteModal from '../modal/DeleteModal';
import ChecklistStatus from '../checklist/ChecklistStatus';
import TableRowHover from '../commons/TableRowHover';
import { useProfileContext } from '../../contexts/profileContext';
import TextOverflowTooltip from '../commons/TextOverflowTooltip';
const constants = stringConstants.allActivities;
const AllActivitiesTable = ({
  paginationPage,
  order,
  setActivatedTab,
  setOrder,
  pagination,
  showLoading,
  dataInDB,
  setPagination,
  selectedData = [],
  setSelectedData,
  setShowDeleteOrgModal,
  deleteResults,
  tabType,
  showDeleteOrgModal,
  setDeleteResults,
  handleClearSelection = () => {},
  selectAll,
  getData,
  cloneData,
  setCloneData,
  setErrorMessage,
  setSuccessMessage,
  allData,
  isFilterCheck,
  setSelectAll,
  handleEditActivity,
  getStats,
  isInTab = false,
  checkboxHidden = false,
}) => {
  const { profileInfo } = useProfileContext();
  const [isShow, setIsShow] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [activityObj, setActivityObj] = useState({});
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [modified, setModified] = useState(false);

  useEffect(() => {
    getData(tabType);
  }, [pagination?.page, pagination?.limit, order, isFilterCheck?.filter]);
  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  useEffect(() => {
    paginationPage?.page === 1 && changePaginationPage(1);
  }, [paginationPage]);
  const activityDetail = async (content) => {
    const { data } = await activityService.getSingleActivity(
      content.id ? content.id : content
    );
    setActivityObj(data);
    setIsShow(true);
  };
  const markAsDone = async (id) => {
    try {
      await activityService.markAsCompleted(id);
      getData('task');
      setActivatedTab({
        task: 1,
      });
      setSuccessMessage(constants.completed);
    } catch (error) {
      setErrorMessage(constants.errorUpdatedActivity);
    }
  };

  const GetOwnersAction = (task) => {
    if (task) {
      return (
        <>
          {task?.owners?.[0] ? (
            <div
              className="position-relative index-0-on-scroll"
              key={task.owners[0].id}
            >
              <OwnerAvatar
                hovering="index-0-on-scroll"
                item={task.owners[0]}
                isMultiple={true}
              />
            </div>
          ) : (
            <h5 className="mb-0 p-0">--</h5>
          )}
        </>
      );
    }
  };
  const GetCreateByOwners = (task) => {
    if (task) {
      return (
        <div className="position-relative index-0-on-scroll">
          <OwnerAvatar item={task} hovering="index-0-on-scroll" />
        </div>
      );
    }
  };
  const getAction = (content) => {
    if (content?.organization) {
      return (
        <Link
          to={`${routes.companies}/${content?.organization?.id}/organization/profile`}
          className="text-black fw-normal d-inline-flex align-items-center gap-1"
        >
          {content?.organization && (
            <>
              <MaterialIcon icon="domain" /> {content?.organization?.name}
            </>
          )}
        </Link>
      );
    } else if (content?.contact) {
      const name = `${content?.contact?.first_name} ${content?.contact?.last_name}`;
      return (
        <Link
          to={`${routes.contacts}/${content?.contact?.id}/profile`}
          className="text-black fw-normal d-inline-flex align-items-center gap-1"
        >
          {content && (
            <>
              <MaterialIcon icon="people" /> {name}
            </>
          )}
        </Link>
      );
    } else if (content?.deal) {
      return (
        <Link
          to={`${routes.dealsPipeline}/${content?.deal?.id}`}
          className="text-black fw-normal d-inline-flex align-items-center gap-1"
        >
          {content?.deal && (
            <>
              <MaterialIcon icon="monetization_on" /> {content?.deal?.name}
            </>
          )}
        </Link>
      );
    }
  };
  const tableActions = [
    {
      id: 1,
      title: 'Edit',
      icon: 'edit',
      permission: {
        collection: 'activities',
        action: 'edit',
      },
      onClick: (e) => {
        setIsShow(false);
        handleEditActivity(e);
      },
    },
    {
      id: 2,
      title: 'Clone',
      permission: {
        collection: 'activities',
        action: 'create',
      },
      icon: 'content_copy',
      onClick: (e) => {
        setIsShow(false);
        setCloneData(true);
        handleEditActivity(e);
      },
    },
  ];
  const columns = [
    {
      key: 'name',
      orderBy: 'name',
      component: 'Activity Name ',
      width: isInTab ? '220px' : '',
    },
    ...(isInTab
      ? []
      : [
          {
            key: 'description',
            orderBy: '',
            component: 'Description ',
            width: '195px',
            classes: 'pl-3',
          },
        ]),
    {
      key: 'created_by',
      orderBy: '',
      component: 'Created By',
      width: isInTab ? '160px' : '',
    },
    {
      key: '',
      orderBy: '',
      component: 'Owner',
      width: isInTab ? '160px' : '',
    },
    {
      key: 'end_date',
      orderBy: 'end_date',
      component: 'Due Date',
      width: isInTab ? '220px' : '',
    },
    {
      key: 'done',
      orderBy: 'done',
      component: 'Status',
      width: isInTab ? '220px' : '',
    },
    {
      key: 'priority',
      orderBy: 'priority',
      component: 'Priority',
      width: isInTab ? '220px' : '',
    },
    ...(isInTab
      ? []
      : [
          {
            key: 'related_to',
            orderBy: '',
            component: 'Related To',
          },
        ]),
    {
      key: 'action',
      orderBy: '',
      component: '',
      width: isInTab ? '100px' : '',
    },
  ];
  const data = allData?.map((activity) => ({
    ...activity,
    dataRow: [
      {
        key: 'name',
        component: (
          <div className="d-flex gap-1 align-items-center">
            <span className={`text-truncate text-wrap fw-bold`}>
              <TextOverflowTooltip
                maxLength={20}
                capitalizeText={false}
                text={activity?.name}
                textStyle="fw-bold mb-0"
              />
            </span>
          </div>
        ),
      },
      ...(isInTab
        ? []
        : [
            {
              key: 'description',
              component: (
                <div className="pl-2">
                  <TextOverflowTooltip
                    maxLength={30}
                    capitalizeText={false}
                    text={activity?.description}
                    textStyle="mb-0"
                  />
                </div>
              ),
            },
          ]),
      {
        key: 'created_by',
        component: GetCreateByOwners(activity),
      },
      {
        key: 'owner',
        component: GetOwnersAction(activity),
      },
      {
        key: 'end_date',
        component: <span>{dateWithoutTZ(activity?.start_date)}</span>,
      },
      {
        key: 'done',
        component: (
          <ChecklistStatus
            item={{ status: activity?.done ? 'Completed' : 'In Progress' }}
          />
        ),
      },
      {
        key: 'priority',
        component: (
          <span>
            {activity?.priority ? (
              <div className="d-flex text-red align-items-center gap-1">
                <MaterialIcon
                  filled={true}
                  icon="flag"
                  size="fs-flag-icon"
                  clazz="text-red ml-n1"
                />
                High
              </div>
            ) : (
              'Normal'
            )}
          </span>
        ),
      },
      ...(isInTab
        ? []
        : [
            {
              key: 'related_to',
              component: (
                <div className="position-relative">{getAction(activity)}</div>
              ),
            },
          ]),
      {
        key: 'action',
        component: (
          <>
            {isPermissionAllowed('activities', 'edit') ||
            isPermissionAllowed('activities', 'create') ||
            profileInfo?.role?.owner_access ? (
              <TableRowHover
                onClick={(e) => {
                  const rect = e.target.getBoundingClientRect();
                  const x = e.clientX - rect.left;
                  if (x <= 50) {
                    setSelectedData((prevSelected) => {
                      if (!prevSelected.includes(activity.id)) {
                        return [...prevSelected, activity.id];
                      } else {
                        return prevSelected.filter(
                          (item) => item !== activity.id
                        );
                      }
                    });
                  } else {
                    activityDetail(activity);
                  }
                }}
              >
                <TableActions
                  item={{ ...activity, title: name }}
                  actions={tableActions}
                />
                {isPermissionAllowed('activities', 'edit') && (
                  <a
                    className={`icon-hover-bg cursor-pointer position-relative`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <MoreActions
                      icon="more_vert"
                      menuPlacement="up"
                      items={[
                        {
                          permission: {
                            collection: 'activities',
                            action: 'edit',
                          },
                          id: 'edit',
                          icon: 'task_alt',
                          name: 'Mark as done',
                          className: activity.done ? 'd-none' : '',
                        },
                      ]}
                      onHandleEdit={() => markAsDone(activity.id)}
                      toggleClassName="w-auto p-0 h-auto"
                    />
                  </a>
                )}
              </TableRowHover>
            ) : (
              ''
            )}
          </>
        ),
      },
    ],
  }));

  const sortTable = ({ name }) => {
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder });
  };
  const loader = () => {
    if (showLoading) return <TableSkeleton cols={6} rows={10} />;
  };

  const deleteOrganizations = async () => {
    await Promise.all(
      selectedData?.map(async (item) => {
        try {
          await activityService.deleteActivity(
            selectedData.length > 0 ? item : deleteResults?.id
          );
          setSuccessMessage(`Activities has been deleted.`);
          setShowDeleteOrgModal(false);
          handleClearSelection();
          setSelectedData([]);
        } catch (err) {
          setErrorMessage(err.message);
        }
      })
    );
    getData('all');
    setActivatedTab({
      all: 1,
    });
    setShowDeleteOrgModal(false);
  };
  const openDeleteModal = async () => {
    setShowDeleteOrgModal(true);
    await deleteOrganizations();
  };

  useEffect(() => {
    if (!showDeleteOrgModal) {
      handleClearSelection();
    }
  }, [!showDeleteOrgModal]);
  return (
    <div>
      <Card className="mb-0 card-0">
        <Card.Body className="p-0">
          <div className="table-responsive-md datatable-custom">
            <div
              id="datatable_wrapper"
              className="dataTables_wrapper no-footer"
            >
              {showLoading ? (
                loader()
              ) : (
                <>
                  <Table
                    stickyColumn={`${
                      !checkboxHidden ? 'stickyColumn' : ''
                    } activity-table`}
                    columns={columns}
                    data={data}
                    checkbox={!checkboxHidden}
                    headClass={`bg-gray-table-head ${
                      !checkboxHidden ? 'border-top' : ''
                    }`}
                    setDeleteResults={setDeleteResults}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    paginationInfo={{}}
                    actionPadding="h-100 full-h"
                    onPageChange={changePaginationPage}
                    emptyDataIcon="event_available"
                    emptyDataText="This record doesn't have any activities"
                    title="Task"
                    dataInDB={dataInDB}
                    showTooltip={showTooltip}
                    setShowTooltip={setShowTooltip}
                    sortingTable={sortTable}
                    sortingOrder={order}
                    onClick={activityDetail}
                  />
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      {showDeleteOrgModal && (
        <DeleteModal
          type="task"
          showModal={showDeleteOrgModal}
          setShowModal={setShowDeleteOrgModal}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          event={openDeleteModal}
          data={allData}
          results={deleteResults}
          setResults={setDeleteResults}
          showReport={showDeleteReport}
          setShowReport={setShowDeleteReport}
          modified={modified}
          setSelectAll={setSelectAll}
          setModified={setModified}
          constants={constants?.delete}
        />
      )}
      {isShow && (
        <ActivityDetail
          activityDetail={activityDetail}
          isShow={isShow}
          setIsShow={setIsShow}
          data={activityObj}
          cloneData={cloneData}
          getActivityId={activityObj}
          markAsDone={markAsDone}
          tableActions={tableActions}
        />
      )}
    </div>
  );
};

export default AllActivitiesTable;
