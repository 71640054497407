import React, { useEffect, useState } from 'react';
import { overflowing } from '../../../utils/Utils';
import SimpleModalCreation from '../../../components/modal/SimpleModalCreation';
import InputValidation from '../../../components/commons/InputValidation';
import { useForm } from 'react-hook-form';
import InlineFormGroup from '../../../components/commons/InlineFormGroup';

const PipelineNamePriceEditModal = ({
  show,
  setShow,
  deal,
  onHandleSubmit,
  isLoading: updatingDeal,
}) => {
  //   const [saving, setSaving] = useState(false);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const [dealData, setDealData] = useState({ ...deal });

  useEffect(() => {
    if (show) {
      setValue('name', deal.name);
      setValue('amount', deal.amount);
    }
  }, [show]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setDealData({
      ...dealData,
      [name]: value,
    });
    setValue(name, value);
  };

  const onSubmit = (dealFormData) => {
    onHandleSubmit(dealFormData);
  };

  return (
    <>
      <SimpleModalCreation
        modalHeaderClasses="flex-fill"
        modalTitle="Edit Opportunity Information"
        saveButtonStyle="btn btn-primary btn-sm d-flex align-items-center"
        open={show}
        editFields={true}
        isLoading={updatingDeal}
        footerStyle="border-0"
        handleSubmit={handleSubmit(onSubmit)}
        onHandleCloseModal={() => {
          overflowing();
          setShow(!show);
        }}
      >
        <div className="d-flex flex-column gap-3">
          <InlineFormGroup
            label="Deal Name"
            component={
              <InputValidation
                name="name"
                type="input"
                placeholder="Deal Name"
                value={dealData.name || ''}
                errorDisplay="mb-0"
                classNames="mr-2"
                validationConfig={{
                  required: 'Deal Name is required.',
                  inline: false,
                  borderLeft: true,
                  onChange: handleOnChange,
                }}
                errors={errors}
                register={register}
              />
            }
          />

          <InlineFormGroup
            label="Amount"
            component={
              <InputValidation
                name="amount"
                type="input"
                placeholder="Amount"
                value={dealData.amount || ''}
                errorDisplay="mb-0"
                classNames="mr-2"
                validationConfig={{
                  onChange: handleOnChange,
                }}
                errors={errors}
                register={register}
              />
            }
          />
        </div>
      </SimpleModalCreation>
    </>
  );
};

export default PipelineNamePriceEditModal;
