import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import InputValidation from '../../components/commons/InputValidation';
import { Label } from 'reactstrap';
import ReactDatepicker from '../../components/inputs/ReactDatpicker';
import { useForm } from 'react-hook-form';
import { DATE_FORMAT_EJS, DATE_FORMAT_EJS_UPDATED } from '../../utils/Utils';
import ControllerValidation from '../../components/commons/ControllerValidation';
import ButtonIcon from '../../components/commons/ButtonIcon';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const CreateChangeLog = ({ type = 'Create' }) => {
  const defaultChangeLogForm = {
    title: '',
    content: null,
    dueDate: '',
    type: '',
    action: 'draft',
  };
  const {
    register,
    setValue,
    getFieldState,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: defaultChangeLogForm,
  });

  const [changeLogForm, setChangeLogForm] = useState(defaultChangeLogForm);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setChangeLogForm({
      [name]: value,
    });
    setValue(name, value);
  };

  const onSubmit = () => {};

  return (
    <>
      <Card>
        <Card.Body>
          <h4>{type} Changelog</h4>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-2">
              <Label className="w-100 d-block fw-600 mb-1">Tile</Label>
              <InputValidation
                name="title"
                placeholder="Title"
                fieldType="text"
                type="input"
                value={changeLogForm?.title}
                validationConfig={{
                  inline: false,
                  required: true,
                  onChange: handleChange,
                }}
                errors={errors}
                register={register}
              />
            </div>
            <div className="mt-2">
              <Label className="w-100 d-block fw-600 mb-1">Date</Label>
              <div className="date-picker changelog-date-picker w-100">
                <ControllerValidation
                  name={'dueDate'}
                  errors={errors}
                  form={changeLogForm}
                  errorDisplay="mb-0"
                  control={control}
                  validationConfig={{
                    required: `DueDate is required.`,
                  }}
                  renderer={({ field }) => (
                    <ReactDatepicker
                      id={'dueDate'}
                      name={'dueDate'}
                      format={DATE_FORMAT_EJS}
                      autoComplete="off"
                      todayButton="Today"
                      validationConfig={{
                        required: `DueDate is required.`,
                      }}
                      fieldState={getFieldState('dueDate')}
                      value={changeLogForm?.dueDate}
                      className="form-control w-100"
                      placeholder={DATE_FORMAT_EJS_UPDATED}
                      onChange={(date) => {
                        setChangeLogForm({
                          dueDate: date,
                        });
                        setValue('dueDate', date);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="mt-2">
              <Label className="w-100 d-block fw-600 mb-1">Description</Label>

              <CKEditor
                editor={ClassicEditor}
                data="<p>Write Here!</p>"
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log({ event, editor, data });
                  setChangeLogForm({ content: data });
                }}
              />
            </div>
            <div className="mt-2">
              <Label className="w-100 d-block fw-600 mb-1">Type</Label>
              <select className="form-control">
                <option value="">Select please...</option>
                <option value="new">New</option>
                <option value="improved">Improved</option>
                <option value="fixed">Fixed</option>
              </select>
            </div>{' '}
            <div className="mt-2">
              <Label className="w-100 d-block fw-600 mb-1">Action</Label>
              <select className="form-control">
                <option value="">Select please...</option>
                <option value="new">Draft</option>
                <option value="improved">Published</option>
              </select>
            </div>
            <div className="mt-3">
              <ButtonIcon
                label="Save"
                type="submit"
                classnames="btn-sm btn-primary"
              />
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};
