import AutoComplete from '../AutoComplete';
import React, { useEffect, useState } from 'react';
import userService from '../../services/user.service';
import axios from 'axios';
import { STATUS_ACTIVE } from '../../utils/constants';

const SearchUsersAutocomplete = ({
  name,
  onLoad,
  selected,
  clearOnSelected,
  clearField,
  onSelect,
}) => {
  const [charactersRequire, setCharactersRequire] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [cancelToken, setCancelToken] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [searchUser, setSearchUser] = useState({
    search: selected || '',
    active: STATUS_ACTIVE,
  });
  const [loadingUsers, setLoadingUsers] = useState(false);
  const stateChange = (e) => {
    const match = e.target.value.match(/([A-Za-z])/g);
    if (match && match.length >= 2) {
      setClicked(false);
      setCharactersRequire('');
      const newSearchQuery = {
        search: e.target.value,
        active: STATUS_ACTIVE,
      };
      setSearchUser(newSearchQuery);
    } else {
      return setCharactersRequire(match?.length);
    }
  };
  async function onGetUsers(newSearchQuery, limit = 10) {
    try {
      setLoadingUsers(true);
      const newToken = axios.CancelToken.source();
      setCancelToken(newToken);
      const response = await userService
        .getUsers(newSearchQuery || searchUser, {
          limit,
          cancelToken: newToken.token,
        })
        .catch((err) => err);

      setLoadingUsers(false);
      const { users } = response?.data;
      const updatedUsers = users
        ?.filter((o) => !!o.name)
        .map((u) => ({ ...u, title: `${u.first_name} ${u.last_name}` }));
      setAllUsers(updatedUsers);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.log('Error:', error);
      }
    } finally {
      setLoadingUsers(false);
    }
  }
  useEffect(() => {
    if (searchUser?.search && !clicked) {
      if (cancelToken) {
        cancelToken.cancel('Request cancelled.');
      }
      onGetUsers({ ...searchUser, active: STATUS_ACTIVE });
    }
  }, [searchUser?.search]);

  useEffect(() => {
    if (!clicked) {
      if (onLoad || clearField?.clear === 1) {
        onGetUsers('', 5);
      }
    }
  }, [onLoad, clearField]);

  useEffect(() => {
    if (clearField?.clear === 1) {
      setSearchUser({ search: '' });
      onSelect({ clear: 0 });
    }
  }, [clearField]);
  return (
    <AutoComplete
      id={name}
      placeholder={'Search for Users'}
      name={name}
      type="item"
      loading={loadingUsers}
      charactersRequire={charactersRequire}
      onChange={(e) => stateChange(e)}
      data={allUsers}
      customKey="title"
      showAvatar={true}
      showEmail={true}
      selected={searchUser.search}
      onHandleSelect={(item) => {
        if (clearOnSelected) {
          setSearchUser({ search: '' });
        }
        setClicked(true);
        setSearchUser({ search: item.title });
        onSelect(item);
      }}
    />
  );
};

export default SearchUsersAutocomplete;
