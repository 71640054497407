import React from 'react';
import PaymentFraudContinuesWidget from '../../../reportbuilder/widgets/horizontal/PaymentFraudContinuesWidget';
import { PFData } from '../../../reportbuilder/constants/widgetsConstants';

const AccountsWidget = ({ whenPrinting }) => {
  return (
    <PaymentFraudContinuesWidget
      widgetConfig={PFData}
      whenPrinting={whenPrinting}
    />
  );
};

export default AccountsWidget;
