import { Card, CardBody } from 'reactstrap';
import { Col, Row } from 'react-bootstrap';
import React from 'react';
import { numbersWithComma } from '../../../../utils/Utils';

const AccountsPayableReceivableWidget = ({
  widgetConfig,
  whenPrinting,
  selectedTenant,
}) => {
  const dpoDsoValue = Math.abs(
    widgetConfig?.data[`your${widgetConfig?.extraType}`]
  );
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center align-items-center d-flex">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <Row
            className={`align-items-center position-relative widget-extra-padding`}
          >
            <Col md={4} className="position-relative">
              <div
                style={{
                  height: 130,
                  width: '100%',
                  borderRadius: 'var(--rpt-widget-border-radius)',
                }}
                className="bg-primary-soft border-2 report-widget-inner-section-soft"
              >
                <div className="position-absolute abs-center-xy w-100">
                  <h1 className="mb-0 text-rpt-heading text-center font-weight-bold">
                    {numbersWithComma(dpoDsoValue)}
                  </h1>
                  <p className="fs-9 text-center sub-text mb-0">
                    {widgetConfig.valueText}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={8} className="text-left font-size-sm2 ml-0 pl-1">
              <p
                className={`mb-0 ${
                  whenPrinting ? 'fs-9' : 'font-size-sm2'
                } text-left`}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: widgetConfig?.description?.replace(
                      '$X',
                      numbersWithComma(dpoDsoValue)
                    ),
                  }}
                ></span>
              </p>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default AccountsPayableReceivableWidget;
