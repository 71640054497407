import Skeleton from 'react-loading-skeleton';
import React from 'react';
import { Spinner } from 'reactstrap';

export default function Loading({ bars }) {
  return (
    <>
      {bars ? (
        <div className="px-4 py-2">
          <Skeleton count={3} height={10} className={'mb-2'} />
        </div>
      ) : (
        <div className="d-flex justify-content-center align-items-center height-container-spinner">
          <Spinner color="primary" />
        </div>
      )}
    </>
  );
}
