import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';

const API_URL_ANALYTICS = process.env.REACT_APP_API_URL + '/api/analytics';

class DashboardService extends BaseRequestService {
  async getAnalytics() {
    const data = await this.get(`${API_URL_ANALYTICS}`, {
      headers: authHeader(),
    });

    return data.map((analytic) => {
      return {
        ...analytic,
        component: {
          name: analytic.name,
          analytic,
        },
      };
    });
  }
}

export default new DashboardService();
