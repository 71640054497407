import React, { useCallback, useMemo, useState } from 'react';
import { overflowing } from '../utils/Utils';
import SimpleModalCreation from '../components/modal/SimpleModalCreation';
import Skeleton from 'react-loading-skeleton';

const YoutubeEmbedModal = ({
  show,
  videoData,
  setShow,
  videoId,
  isChannel,
  loader,
  buttonText,
}) => {
  return (
    <SimpleModalCreation
      modalTitle={videoData?.title}
      open={show}
      size={isChannel ? 'xxl' : 'md'}
      bankTeam={false}
      cancelButtonText={'Close'}
      isLoading={false}
      saveButton={buttonText}
      handleSubmit={() => setShow(false) || ''}
      bodyClassName="mb-0 p-3 min-h-120"
      onHandleCloseModal={() => {
        overflowing();
        setShow(!show);
      }}
    >
      <div className="position-relative">
        {loader ? (
          <div className="position-absolute h-100 w-100">
            <Skeleton height={90} width="100%" className="d-block" />
          </div>
        ) : (
          <iframe
            width="100%"
            height="315"
            src={`https://www.youtube.com/embed/${videoId}/`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        )}
      </div>
    </SimpleModalCreation>
  );
};

const useYoutubeEmbedPlayer = (
  videoId,
  videoData = '',
  isChannel,
  buttonText
) => {
  const [videoModalShow, setVideoModalShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const YoutubeEmbedModalModalCallback = useCallback(() => {
    return (
      <YoutubeEmbedModal
        show={videoModalShow}
        setShow={setVideoModalShow}
        videoId={videoId}
        isChannel={isChannel}
        videoData={videoData}
        loader={loading}
        buttonText={buttonText}
      />
    );
  }, [videoModalShow, setLoading, setVideoModalShow]);

  return useMemo(
    () => ({
      setVideoModalShow,
      setLoading,
      YoutubeEmbedModal: videoModalShow
        ? YoutubeEmbedModalModalCallback
        : () => <div />,
    }),
    [setVideoModalShow, setLoading, YoutubeEmbedModalModalCallback]
  );
};

export default useYoutubeEmbedPlayer;
