import { Col, Row } from 'reactstrap';

const SectionWrapper = ({ columns, children, containerClasses }) => {
  return (
    <div className={containerClasses}>
      <Row className="fs-9">
        <Col md={6}>
          <span>{columns[0]}</span>
        </Col>
        <Col md={6}>
          <span>{columns[1]}</span>
        </Col>
      </Row>
      <div className="rounded-lg border bg-gray-200 mt-1">{children}</div>
    </div>
  );
};

export default SectionWrapper;
