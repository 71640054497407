import { Card, CardBody } from 'reactstrap';
import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';

const Dollars = {
  text: 'US Dollars',
  items: [
    { text: 'You', description: 'Initiate USD Wire Transfer' },
    { text: 'Your Bank', description: 'Debits Account, makes USD transfer' },
    {
      text: 'Correspondent Bank',
      description:
        'Executes Currency Exchange at an unknown rate charged to the payment',
    },
    {
      text: "Vendor's Bank",
      description:
        'Receives funds in foreign currency, posts to transaction less than fees',
    },
  ],
}; // blue
const ForeignCurrency = {
  text: 'Foreign Currency (FX)',
  items: [
    { text: 'You', description: 'Initiate USD Wire Transfer' },
    {
      text: 'Your Bank',
      description:
        'Initiate FX Wire Transfer & Executes Currency Exchange at an agreed upon rate',
    },
    {
      text: 'Correspondent Bank',
      description:
        'Receives funds in foreign currency (dependent upon vendors bank)',
    },
    {
      text: "Vendor's Bank",
      description:
        'Receives funds in foreign currency posts to vendors account for exact amount',
    },
  ],
}; // purple

const ExchangeItems = ({ section, itemStyle }) => {
  const { items, text } = section;
  return (
    <div
      style={{ background: itemStyle.mainBg, minHeight: 375 }}
      className="p-2 rounded h-100 d-flex justify-content-center w-50 flex-column align-items-center gap-2"
    >
      <h4 className="text-white my-1 text-center">{text}</h4>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            background: `hsla(${itemStyle.itemBg}, 100%, 90%, 0.3)`,
            color: itemStyle.fc,
            height: 90,
          }}
          className="rounded p-2 w-100 d-flex flex-column justify-content-center align-items-center fs-9 font-weight-semi-bold"
        >
          <p className="mb-1 fs-7 text-center">{item.text}</p>
          <p className="mb-0 text-center" style={{ filter: 'brightness(0.8)' }}>
            {item.description}
          </p>
        </div>
      ))}
    </div>
  );
};

const InternationalBankingPayingForeignSuppliersWidget = ({
  widgetConfig,
  whenPrinting,
}) => {
  return (
    <Card className="report-widget">
      <CardBody className="d-flex align-items-center justify-content-center">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="d-flex my-2 align-items-center gap-2 justify-content-between">
            <ExchangeItems
              section={Dollars}
              itemStyle={{ mainBg: '#1C2DD8', itemBg: '235', fc: '#fff' }}
            />
            <ExchangeItems
              section={ForeignCurrency}
              itemStyle={{ mainBg: '#6941C6', itemBg: '258', fc: '#fff' }}
            />
          </div>
          <p
            className={`mb-0 ${
              whenPrinting ? 'fs-9' : 'font-size-sm2'
            } text-left`}
          >
            <span
              dangerouslySetInnerHTML={{ __html: widgetConfig.description }}
            ></span>
          </p>
          {widgetConfig?.source && (
            <WidgetSourceBlock text={widgetConfig.source} />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default InternationalBankingPayingForeignSuppliersWidget;
