/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import { Steps, Step } from 'react-step-builder';
import { useParams, useHistory } from 'react-router-dom';
import Heading from '../../components/heading';
import Nav from '../../components/lesson/nav';
import Page from '../../components/lesson/page';
import Hero from '../../components/lesson/hero';
import { API } from '../../services/api';
import lessonService from '../../services/lesson.service';
import { COMPLETED, CONGRATULATIONS, QUIZ } from '../../utils/constants';
import PageTitle from '../../components/commons/PageTitle';
import LearnFilter from '../../components/commons/LearnFilter';
import { LessonLayout } from '../../components/lessonLayout';
import categoryService from '../../services/category.service';
import Card from '../../components/lesson/card';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import LessonSkeleton from '../../components/commons/LessonSkeleton';

export default function Lesson(props) {
  const api = new API();
  const { id, course_id } = useParams();
  const searchParams = useUrlSearchParams();
  const viewType = searchParams.get('viewType');
  const path = searchParams.get('path');
  const [getLessonId, setGetLessonId] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [currentLesson, setCurrentLesson] = useState({
    state: 'in_progress',
  });
  const [refresh, setRefresh] = useState(0);
  const [relatedLessons, setRltLessons] = useState([]);
  const [isTitle, setTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const pathName = location?.pathname.includes('lessons');

  const getRelatedLessons = async () => {
    const data = await categoryService.GetLessonsByCategory({
      id: currentLesson?.category_id,
      limit: 5,
      favorites: 'include',
    });
    setRltLessons(data?.data);
    setLoading(false);
  };
  useEffect(() => {
    if (currentLesson?.category_id) {
      getRelatedLessons(currentLesson?.category_id);
    }
  }, [currentLesson]);

  useEffect(() => {
    getLesson();
  }, [getLessonId, id]);
  const getLesson = async () => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    const apiLesson = await lessonService
      .getLessonById(getLessonId || id, { favorites: 'include' })
      .catch((err) => console.log(err));

    if (!apiLesson) {
      return;
    }

    try {
      const resp = await lessonService.GetLessonTrackByLessonId(id, {
        self: true,
      });

      const { completed_at, status } = resp || {};

      let newPages = apiLesson.pages.slice();

      if (completed_at && status === COMPLETED) {
        newPages = apiLesson.pages.filter((page) => !page.type.includes(QUIZ));
      }

      setCurrentLesson({
        ...apiLesson,
        pages: newPages,
        lessonTrack: resp || {},
      });
    } catch (e) {
      // first time lesson progress comes null, so default/first page load
      const newPages = apiLesson.pages.slice();
      setCurrentLesson({
        ...apiLesson,
        pages: newPages,
        lessonTrack: {},
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (refresh > 0) {
      // avoiding api calls on retake.
      getLesson();
      setRefresh(0);
    }
  }, [refresh]);
  const config = {
    navigation: {
      component: Nav, // a React component with special props provided automatically
      location: 'after', // or after
    },
  };
  const loader = () => {
    if (isLoading) return <LessonSkeleton />;
  };
  const FirstStep = (props) => {
    return (
      <Hero
        points={currentLesson.max_points}
        {...props}
        lesson={currentLesson}
        setRefresh={setRefresh}
        setGetLessonId={setGetLessonId}
        getLessonId={getLessonId}
        lessons={relatedLessons}
      />
    );
  };
  return (
    <div>
      {isLoading ? (
        loader()
      ) : (
        <>
          <PageTitle page={currentLesson?.title} pageModule="Learn" />
          <LearnFilter
            viewType={viewType}
            path={currentLesson?.category?.title || path}
          />
          <LessonLayout
            lesson={currentLesson}
            relatedLessons={relatedLessons}
            path={path}
            type={viewType}
            viewType="lesson"
            setGetLessonId={setGetLessonId}
            loading={loading}
            course={pathName === 'courses' ? id : course_id}
            lessonId={pathName === 'lessons' ? id : ''}
          >
            <>
              <div
                className={
                  isTitle || currentLesson?.category?.title
                    ? 'my-3'
                    : 'mt-5 pt-4'
                }
              >
                <Heading
                  title={isTitle || currentLesson?.category?.title}
                  pageHeaderDivider="pb-0 mb-0 text-capitalize"
                />
              </div>
              <div className="card rounded-lg card-lesson-hero">
                <CardBody>
                  <Steps config={config}>
                    <Step
                      title={currentLesson?.title}
                      lesson={currentLesson}
                      component={(props) => <FirstStep {...props} />}
                    />
                    {currentLesson.pages?.map((p, indx) => (
                      <Step
                        key={indx}
                        lessonId={currentLesson?.id}
                        firstPage={currentLesson?.pages[0]}
                        title={p?.title}
                        component={(props) => (
                          <Page {...props} lesson={currentLesson} page={p} />
                        )}
                      />
                    ))}
                    <Step
                      title={CONGRATULATIONS}
                      lesson={currentLesson}
                      component={(props) => (
                        <Hero
                          {...props}
                          category_id={currentLesson?.category_id}
                          setGetLessonId={setGetLessonId}
                          getLessonId={getLessonId}
                          setRefresh={setRefresh}
                          type={viewType}
                        />
                      )}
                    />
                  </Steps>
                </CardBody>
              </div>
            </>
          </LessonLayout>
        </>
      )}
    </div>
  );
}
