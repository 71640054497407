import { Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import GenericTable from '../../../../components/GenericTable';
import { cubeService } from '../../../../services';
import Loading from '../../../../components/Loading';
import assignmentService from '../../../../services/assignment.service';
import { AssignmentSnapshotWidget } from '../AssignmentSnapshotWidget';
import ButtonFilterDropdown from '../../../../components/commons/ButtonFilterDropdown';
import { reportPages } from '../../../../utils/constants';
import { ShortDescription } from '../../../../components/ShortDescription';
import SingleSelectionDropdown from '../../../../components/commons/SingleSelectionDropdown';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utils/Utils';
import { NoUserSelected } from '../../components/queries/NoUserSelected';

const AssignmentStatsNames = {
  assignmentCompleted: 'Completed',
  assignmentInProgress: 'In Progress',
  assignmentNotStarted: 'Not Started',
  assignmentOverDue: 'Overdue',
  peopleAssigned: 'Users Assigned',
  totalLessonCount: 'Number of Lessons in Assignment',
};

const assignmentStatsTooltip = {
  Completed: 'Users who have completed the assignment',
  'In Progress': 'Users who have completed some but not all of the assignment',
  'Not Started': 'Users who have not started the assignment',
  Overdue: 'Users who have not completed this assignment by the due date.',
  'Users Assigned': '# of users required to complete the assignment',
  'Number of Lessons in Assignment':
    'total # of lessons included in the assignment',
};

export const TrainingAssignmentDetails = ({ insightName }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItemAppear, setSelectedItemAppear] = useState({});
  const [showCSVBtn, setShowCSVBtn] = useState(false);
  const [show, setShow] = useState(false);
  const [selectItem, setSelectedItem] = useState({});
  const [queryFilter, setQueryFilter] = useState({ value: [] });
  const [allAssignments, setAllAssignments] = useState([]);
  const [reportPage, setReportPage] = useState(reportPages[0]);
  const [querySearch, setQuerySearch] = useState('');

  useEffect(() => {
    (async () => {
      const { data } = await assignmentService.getAssignments({
        page: 1,
        limit: 1000,
        search: querySearch,
      });
      const updatedData = data?.map((item) => ({
        ...item,
        name: item?.title,
      }));
      setAllAssignments(updatedData);
    })();
  }, [querySearch]);

  const handleOptionSelect = (item) => {
    if (Object.keys(item)?.length === 0) {
      setQueryFilter({ value: [] });
      setSelectedItem({});
      setSelectedItemAppear({});
    } else {
      setQueryFilter({ value: [item?.assignmentId] });
    }
  };

  const getData = async () => {
    setLoading(true);
    const results = await cubeService.loadCustomAnalytics({
      type: 'AssignmentDetails',
      ...(reportPage?.key !== 'all' ? { limit: parseInt(reportPage.key) } : {}),
      ...(queryFilter?.value?.length > 0
        ? { assignments: queryFilter?.value }
        : {}),
    });
    setShowCSVBtn(results?.length > 0);
    setData(results);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [reportPage, queryFilter]);

  const sortedData = data?.sort((a, b) => {
    if (a?.assignmentprogress === null && b?.assignmentprogress === null) {
      return 0;
    }
    if (a?.assignmentprogress === null) {
      return -1;
    }
    if (b?.assignmentprogress === null) {
      return 1;
    }
    return a.assignmentprogress - b.assignmentprogress;
  });

  const rows = sortedData?.map((result, index) => {
    const name = `${result?.first_name !== null ? result?.first_name : '--'} ${
      result?.last_name !== null ? result?.last_name : ''
    }`;
    return {
      id: index,
      dataRow: [
        {
          key: 'users',
          component: <span className="fw-bold">{name}</span>,
        },
        {
          key: 'progress',
          component:
            `${
              result?.assignmentprogress !== null
                ? `${result?.assignmentprogress}%`
                : '--'
            }` || '--',
        },
        {
          key: 'team',
          component:
            result?.team !== null ? (
              <ShortDescription limit={15} content={result?.team} />
            ) : (
              '--'
            ),
        },
        {
          key: 'last activity',
          component:
            `${
              result?.date_last_attempted !== null
                ? result?.date_last_attempted
                : '--'
            }` || '--',
        },
      ],
    };
  });

  const columns = [
    {
      key: 'users',
      component: 'Users',
      width: '25%',
    },
    {
      key: 'progress',
      component: 'Progress',
      tooltipTitle: '% of assignment complete ',
      width: '25%',
    },
    {
      key: 'team',
      component: 'Team',
      tooltipTitle: 'Team(s) a user belongs to',
      width: '25%',
    },
    {
      key: 'lastActivity',
      component: 'Last Activity',
      tooltipTitle: 'Users most recent activity on the assignment',
      width: '25%',
    },
  ];

  const updatedRows = sortedData?.map((item) => {
    return {
      users: `${item?.first_name !== null ? item?.first_name : '--'} ${
        item?.last_name !== null ? item?.last_name : ''
      }`,
      progress:
        item?.assignmentprogress !== null ? item?.assignmentprogress : '--',
      completed: `${
        item?.assignmentprogress !== null
          ? `${item?.assignmentprogress}%`
          : '--'
      }`,
      team: `${item?.team !== null ? item?.team : '--'}`,
      lastActivity: `${
        item?.date_last_attempted !== null ? item?.date_last_attempted : '--'
      }`,
    };
  });

  return (
    <>
      <Card>
        <Card.Header
          className={`justify-content-between ${showCSVBtn ? 'mr-5' : ''}`}
        >
          <h4 className="card-title text-hover-primary py-2 mb-0 pt-2 mt-1">
            {insightName}
          </h4>
          <div
            className={`w-75 text-right ${
              showCSVBtn ? 'mr-5 pr-5' : ''
            } d-flex align-items-center justify-content-end gap-2`}
          >
            <SingleSelectionDropdown
              data={allAssignments}
              show={show}
              label={`Assignment${queryFilter?.length > 0 ? 's' : ''}`}
              customClass={'w-50'}
              scrollable="pr-3 text-left"
              setShow={setShow}
              showCheckAll={false}
              search={true}
              customKey={['title', 'assignmentId']}
              handleOptionSelected={handleOptionSelect}
              selectedData={selectItem}
              searchQuery={querySearch}
              setSearchQuery={setQuerySearch}
              setSelectedData={setSelectedItem}
              selectedItemDetailsShow={true}
              setItemDetailsAppear={setSelectedItemAppear}
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              options={reportPages}
              btnToggleStyle="btn-md"
              filterOptionSelected={reportPage}
              handleFilterSelect={(e, item) => {
                setReportPage(item);
              }}
            />
          </div>
        </Card.Header>
        <div>
          {loading ? (
            <div className="py-5 text-center w-100">
              <Loading />
            </div>
          ) : (
            <>
              {queryFilter?.value?.length > 0 ? (
                <div>
                  {selectedItemAppear?.name && (
                    <div className="pt-3 pl-4">
                      <h4
                        className={`card-title d-flex align-items-center gap-1 text-hover-primary font-weight-medium font-size-sm2`}
                      >
                        {' '}
                        Assignment Name:{' '}
                        <span className="fw-bold font-size-sm">
                          {selectedItemAppear?.name}
                        </span>
                      </h4>
                      <h4
                        className={`card-title d-flex align-items-center gap-1 text-hover-primary font-weight-medium font-size-sm2`}
                      >
                        Due Date:
                        <span className="fw-bold font-size-sm">
                          {moment(selectedItemAppear?.dueAt).format(
                            DATE_FORMAT
                          )}
                        </span>
                      </h4>
                    </div>
                  )}
                  <div className="ml-n2">
                    <AssignmentSnapshotWidget
                      insightName=""
                      queryFilter={queryFilter?.value}
                      allSelectedData={allAssignments}
                      param="assignments"
                      type="AssignmentDetailStats"
                      assignmentStatsTooltip={assignmentStatsTooltip}
                      assignmentStatsNames={AssignmentStatsNames}
                    />
                  </div>
                  <div>
                    <GenericTable
                      checkbox={false}
                      data={rows}
                      exportToCSV={showCSVBtn}
                      tableData={updatedRows}
                      fileName="AssignmentDetails"
                      columns={columns}
                      usePagination={false}
                      noDataInDbValidation={true}
                      externalValues={
                        Object.keys(selectItem)?.length > 0
                          ? [
                              { 'Assignment Name': selectItem?.name },
                              {
                                'Due Date': moment(selectItem?.dueAt).format(
                                  DATE_FORMAT
                                ),
                              },
                            ]
                          : []
                      }
                    />
                  </div>
                </div>
              ) : (
                <NoUserSelected icon="school" label="Assignment" />
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
};
