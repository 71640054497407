import React from 'react';
import { Card } from 'react-bootstrap';
import MaterialIcon from '../../../components/commons/MaterialIcon';

const Stats = {
  'OrganizationChecklistProgress.count': '',
  'OrganizationChecklistProgress.countOfCompleted': 'Completed',
  'OrganizationChecklistProgress.countOfInProgress': 'In Progress',
  'OrganizationChecklistProgress.countOfPending': 'Not Started',
  'OrganizationChecklistProgress.countOfOverDue': 'Overdue',
};

const StatsIcon = {
  'OrganizationChecklistProgress.count': '',
  'OrganizationChecklistProgress.countOfCompleted': 'check',
  'OrganizationChecklistProgress.countOfInProgress': 'schedule',
  'OrganizationChecklistProgress.countOfPending': 'Info',
  'OrganizationChecklistProgress.countOfOverDue': 'calendar_today',
};

const StatsColor = {
  'OrganizationChecklistProgress.count': '',
  'OrganizationChecklistProgress.countOfCompleted': 'bg-rpt-green',
  'OrganizationChecklistProgress.countOfInProgress': 'bg-rpt-orange',
  'OrganizationChecklistProgress.countOfPending': 'bg-rpt-gray',
  'OrganizationChecklistProgress.countOfOverDue': 'bg-rpt-red',
};

const StatsIconColor = {
  'OrganizationChecklistProgress.count': '',
  'OrganizationChecklistProgress.countOfCompleted': 'text-rpt-green',
  'OrganizationChecklistProgress.countOfInProgress': 'rpt-orange-box-heading ',
  'OrganizationChecklistProgress.countOfPending': 'text-rpt-gray',
  'OrganizationChecklistProgress.countOfOverDue': 'rpt-red-box-heading',
};
const ChecklistOverviewReportStats = (results) => {
  const { data } = results[0];

  const dataArray =
    data?.length > 0 &&
    Object.entries(data[0])?.map(([key, value]) => ({
      key,
      name: Stats[key],
      icon: StatsIcon[key],
      color: StatsColor[key],
      iconColor: StatsIconColor[key],
      value,
    }));
  const totalCount = dataArray?.find((item) => {
    return item?.key === 'OrganizationChecklistProgress.count';
  });
  return (
    <div className="mb-3">
      {dataArray.map((item, index) => {
        const totalValue = isNaN(
          Math.round((item?.value * 100) / totalCount?.value)
        )
          ? 0
          : Math.round((item?.value * 100) / totalCount?.value);
        // const lastIndex = dataArray ? dataArray?.length - 1 : -1;
        return (
          <>
            {item?.name && (
              <div className="px-1" key={index}>
                <Card
                  className="position-relative shadow-none"
                  style={{ borderRadius: '0.75rem !important' }}
                >
                  <Card.Body style={{ borderRadius: '0.75rem' }}>
                    <div
                      className={`h-100 ${item?.color}`}
                      style={{
                        position: 'absolute',
                        left: '0px',
                        width: '5px',
                        top: 0,
                        borderTopLeftRadius: '0.75rem',
                        borderBottomLeftRadius: '0.75rem',
                      }}
                    ></div>
                    <div className="d-flex align-items-center justify-content-between">
                      <h5 className="text-gray-800">{item?.name}</h5>
                      <MaterialIcon
                        icon={item?.icon}
                        clazz={`${item?.iconColor} font-size-2em`}
                      />
                    </div>
                    <div>
                      <h2 className="font-size-2em">
                        {item?.value} {`(${totalValue}%)`}
                      </h2>
                      <span className="text-muted font-weight-normal font-size-sm2">
                        {item?.name === 'Completed'
                          ? 'Last 30 days'
                          : 'Current'}
                      </span>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};
export default ChecklistOverviewReportStats;
