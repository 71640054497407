import { APP_VERSION_KEY } from '../../utils/constants';

const AppVersion = ({
  styles = 'position-fixed bottom-0 right-0 mr-3',
  color = 'text-muted',
}) => {
  const appVersion = localStorage.getItem(APP_VERSION_KEY);
  return (
    <div className={styles}>
      <div className="fs-8">
        <p
          className={`d-flex justify-content-end cursor-default gap-1 align-items-center mb-0 ${color}`}
        >
          <b className={color}>Version: </b> {appVersion}
        </p>
      </div>
    </div>
  );
};

export default AppVersion;
