import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { overflowing, removeBodyScroll } from '../../../../utils/Utils';
import SimpleModalCreation from '../../../modal/SimpleModalCreation';
import { FormGroup, Label } from 'reactstrap';
import { FormControl } from 'react-bootstrap';
import Alert from '../../../Alert/Alert';
import AlertWrapper from '../../../Alert/AlertWrapper';

const AddNewBankContactModal = ({
  show,
  setShow,
  bankContacts,
  selectedBankContact,
  setBankContacts,
}) => {
  const [, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const defaultBankContactObject = {
    id: crypto.randomUUID(),
    name: '',
    title: '',
    email: '',
    phone: '',
  };
  const [bankContactObject, setBankContactObject] = useState(
    defaultBankContactObject
  );

  useEffect(() => {
    if (show) {
      removeBodyScroll();
      if (selectedBankContact?.id) {
        setBankContactObject(selectedBankContact);
      }
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
    overflowing();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBankContactObject({ ...bankContactObject, [name]: value });
  };

  const handleSave = () => {
    try {
      handleClose();
      const contactsExists = bankContacts.some(
        (cnt) => cnt.id === bankContactObject.id
      );
      let updatedBankContacts = [];
      if (!contactsExists) {
        updatedBankContacts = [...bankContacts, bankContactObject];
      } else {
        updatedBankContacts = bankContacts.map((cnt) =>
          cnt.id === bankContactObject.id ? bankContactObject : cnt
        );
      }
      setBankContacts(updatedBankContacts);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };
  return (
    <SimpleModalCreation
      modalTitle="Add Bank Contact"
      modalHeaderClasses="align-items-start"
      open={show}
      bankTeam={false}
      saveButton="Save"
      bodyClassName="p-3"
      buttonsDisabled={!bankContactObject.name || !bankContactObject.email}
      isLoading={false}
      handleSubmit={handleSave}
      onHandleCloseModal={() => {
        overflowing();
        setShow(!show);
      }}
    >
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
      </AlertWrapper>
      <form autoComplete="off">
        <FormGroup>
          <Label>Name</Label>
          <FormControl
            as="input"
            autoFocus={true}
            name="name"
            value={bankContactObject.name}
            placeholder="Enter name"
            className="mb-2 border-left-4 border-left-danger"
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Title</Label>
          <FormControl
            as="input"
            name="title"
            value={bankContactObject.title}
            placeholder="Enter title"
            className="mb-2"
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <FormControl
            as="input"
            name="email"
            type="email"
            value={bankContactObject.email}
            placeholder="Enter email"
            className="mb-2 border-left-4 border-left-danger"
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Phone</Label>
          <FormControl
            as="input"
            name="phone"
            value={bankContactObject.phone}
            placeholder="Enter phone"
            className="mb-2"
            onChange={handleInputChange}
          />
        </FormGroup>
      </form>
    </SimpleModalCreation>
  );
};

const useAddNewBankContactModal = (
  selectedBankContact,
  bankContacts,
  setBankContacts
) => {
  const [showModal, setShowModal] = useState(false);

  const AddNewBankContactModalCallback = useCallback(() => {
    return (
      <AddNewBankContactModal
        show={showModal}
        setShow={setShowModal}
        selectedBankContact={selectedBankContact}
        bankContacts={bankContacts}
        setBankContacts={setBankContacts}
      />
    );
  }, [
    showModal,
    setShowModal,
    selectedBankContact,
    bankContacts,
    setBankContacts,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      AddNewBankContactModal: AddNewBankContactModalCallback,
    }),
    [setShowModal, AddNewBankContactModalCallback]
  );
};

export default useAddNewBankContactModal;
