import React from 'react';
import { Col, Row } from 'react-bootstrap';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import TenantThemeWrapperWidget from './TenantThemeWrapperWidget';
import { isToFixedNoRound } from '../../../../utils/Utils';
import { RTMData } from '../../constants/widgetsConstants';

const RightTreasuryManagementWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <TenantThemeWrapperWidget bodyStyles="bg-primary-soft border-0 justify-content-center d-flex flex-column">
      <h5 className="text-left mb-1">
        {widgetConfig.heading || RTMData.heading}
      </h5>
      <Row
        className={`align-items-center mb-0 `}
        style={{
          minHeight: !whenPrinting ? 200 : 160,
        }}
      >
        <Col className="justify-content-center">
          <div className="widget-extra-padding">
            <p
              className={`mb-0 ${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } text-left`}
            >
              <p>
                The right treasury management solutions can help you improve
                your working capital position. Reallocating freed cash flow
                towards debt reduction can improve your company&apos;s overall
                financial efficiency and liquidity position.
              </p>{' '}
              <p className="mb-0 font-weight-semi-bold">
                Reducing line usage by 10% could save you{' '}
                {isToFixedNoRound(
                  widgetConfig?.data?.loanOverview?.calculatedTotalExpense || 0,
                  0
                )}{' '}
                in interest expense.
              </p>
            </p>
            {widgetConfig.source && (
              <WidgetSourceBlock text={widgetConfig.source} />
            )}
          </div>
        </Col>
        <Col
          md={3}
          className="align-items-center justify-content-center d-flex"
        >
          <div
            style={{ height: 96, width: 96 }}
            className="justify-content-center gap-2 d-flex align-items-center flex-column p-2 text-center"
          >
            <div className="bg-primary rounded-circle d-flex p-3">
              <MaterialIcon
                icon="lightbulb"
                clazz="text-white font-size-4em"
                rounded
              />
            </div>
          </div>
        </Col>
      </Row>
    </TenantThemeWrapperWidget>
  );
};

export default RightTreasuryManagementWidget;
