import React from 'react';
import MaterialIcon from '../../../components/commons/MaterialIcon';

const ChecklistLeaderStats = (results) => {
  const { data = [] } = results[0];
  return (
    <div>
      <div className="split-progress-bar">
        <div className="d-flex align-items-center gap-2 w-100">
          <MaterialIcon
            icon="person"
            clazz="font-size-2em rpt-orange-box-heading"
          />
          <div className="w-100">
            <h5 className="text-muted fon-weight-light font-size-sm mb-1">
              Checklists Leader (Last 30 days)
            </h5>
            <div>
              <h2>
                {!data[0]?.['OrganizationChecklistOwner.ownerName'] ? (
                  '--'
                ) : (
                  <>
                    <span>
                      {data[0]?.['OrganizationChecklistOwner.ownerName']}
                    </span>{' '}
                    (
                    {
                      data[0]?.[
                        'OrganizationChecklistProgress.countOfCompleted'
                      ]
                    }{' '}
                    {data[0]?.[
                      'OrganizationChecklistProgress.countOfCompleted'
                    ] !== null &&
                    data[0]?.[
                      'OrganizationChecklistProgress.countOfCompleted'
                    ] <= 1
                      ? 'checklist'
                      : 'checklists'}{' '}
                    completed)
                  </>
                )}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChecklistLeaderStats;
