export const TeamsColumns = [
  {
    key: 'team_name',
    orderBy: 'team_name',
    component: <span className="pl-0">Team Name</span>,
  },
  {
    key: 'team_manager',
    orderBy: '',
    component: <span>Team Manager</span>,
  },
  {
    key: 'team_members',
    orderBy: '',
    component: <span>Total Users</span>,
  },
  {
    key: 'last_updated',
    orderBy: 'status',
    component: <span>Last Updated</span>,
  },
  {
    key: 'status',
    orderBy: 'last_access',
    component: <span>Status</span>,
  },
];
