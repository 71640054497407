import { AnalyticsQuery } from '../../../components/analytics';
import React, { useCallback } from 'react';
import { UserTranscriptProgress } from '../reportRenders/UserTranscriptProgress';
import NoDataFound from '../../../components/commons/NoDataFound';
import { LearnDashboardProgress } from '../reportRenders/LearnDashboardProgress';
import { LEARN_OVERVIEW_CONSTANT_KEY } from '../../../components/reports/reports.constants';
const orderByFilterComponentNames = {
  'Active Checklists': 'Active Checklists',
  'Checklists Report': 'Checklists Report',
};
const InsightsQueryUserTranscript = React.memo(
  ({
    containerStyle,
    insight,
    tenant,
    spinnerClass,
    render,
    setQuery,
    setCSVBtnLoad,
    handleSortByData,
    queryFilter = {},
    checklistReportSorting,
    handleCallUserComponent,
    dateRange = {},
    order,
    reportPage = {},
    engagementComponent = {},
  }) => {
    if (reportPage?.key === 'all') {
      delete insight?.limit;
    }
    const queryData = {
      ...insight,
      componentId: insight?.id,
      insight,
      filters:
        queryFilter?.filters?.length > 0
          ? queryFilter?.filters
          : insight?.filters,
      ...(engagementComponent?.component?.name ===
      'Top 5 Lessons by Engagement Rate'
        ? { limit: 10 }
        : {}),
      ...(Object.keys(reportPage)?.length > 0
        ? reportPage?.key !== 'all'
          ? { limit: parseInt(reportPage.key) }
          : insight?.limit
          ? { limit: insight?.limit }
          : {}
        : ''),
      ...(order?.length > 0 && orderByFilterComponentNames[insight?.name]
        ? { order }
        : { order: insight?.order }),
      insightName: queryFilter?.name,
      ...(dateRange?.length > 0
        ? {
            timeDimensions: [
              {
                ...insight?.timeDimensions[0],
                dateRange,
              },
            ],
          }
        : { ...insight?.timeDimensions }),
    };
    delete queryData?.id;
    return (
      <>
        <div className={`overflow-x-auto ${containerStyle}`}>
          <AnalyticsQuery
            query={queryData}
            spinnerClass={spinnerClass}
            insight={insight}
            setQuery={setQuery}
            checklistReportSorting={checklistReportSorting}
            handleSortByData={handleSortByData}
            render={render}
            tenant={tenant}
            containerStyle={containerStyle}
            handleCallUserComponent={handleCallUserComponent}
            setGetCSVBtn={setCSVBtnLoad}
          />
        </div>
      </>
    );
  }
);

InsightsQueryUserTranscript.displayName = 'InsightsQueryUserTranscript';

const UserTranscriptDashboard = ({
  dashboardComponents = [],
  query,
  setQuery,
  queryFilter = {},
  dateRange = {},
  handleSortByData = () => {},
  setCSVBtnLoad = () => {},
  handleCallUserComponent,
  reportPage = {},
  tenant,
  dashboard,
  customClass,
  orderBy,
  checklistReportSorting = false,
  engagementComponent,
  assignmentData = {},
  width = 'w-25 p-0',
}) => {
  const order = [
    'Total Lesson Completed',
    'Lesson Completed Self Directed',
    'Lesson Completed Assigned',
    'assignmentsStats',
    'Activity Details',
  ];
  const selfDashboardAppend = [
    ...(dashboardComponents ?? []),
    ...(dashboard?.name === 'User Transcript'
      ? [{ name: 'assignmentsStats' }]
      : []),
    ...(dashboard?.name === LEARN_OVERVIEW_CONSTANT_KEY
      ? [
          {
            name: 'assignmentCount',
          },
        ]
      : []),
  ];
  let sortedComponents = checklistReportSorting
    ? [...dashboardComponents]
    : [...selfDashboardAppend].sort((a, b) => {
        if (
          a?.component?.analytic?.displayType === 'kpi_rankings' &&
          b?.component?.analytic?.displayType !== 'kpi_rankings'
        ) {
          return 1; // Move a to the end
        }
        if (
          a?.component?.analytic?.displayType !== 'kpi_rankings' &&
          b?.component?.analytic?.displayType === 'kpi_rankings'
        ) {
          return -1; // Move b to the end
        }
        return 0; // Keep original order for other cases
      });

  if (dashboard?.name === 'User Transcript' && sortedComponents?.length > 0) {
    sortedComponents = sortedComponents?.sort((a, b) => {
      return (
        order.indexOf(a?.renderComponent?.name || a?.name) -
        order.indexOf(b?.renderComponent?.name || b?.name)
      );
    });
  }
  return (
    <>
      {dashboardComponents?.length === 0 ? (
        <div className="w-100 text-center">
          <NoDataFound title="No report data found" />
        </div>
      ) : (
        sortedComponents?.map((component, index) => {
          const analytic = component?.component?.analytic;
          const isUserTranscript =
            component?.name === 'assignmentsStats' &&
            dashboard?.name === 'User Transcript';
          const isLearnOverviewAssignment =
            dashboard?.name === LEARN_OVERVIEW_CONSTANT_KEY &&
            component?.name === 'assignmentCount';
          const isTop5Lessons =
            analytic?.name === 'Top 5 Lessons by Engagement Rate';
          const isKpiStandard = analytic?.displayType === 'kpi_standard';

          const containerStyle = checklistReportSorting
            ? `${component?.width || width} h-auto`
            : isKpiStandard || isTop5Lessons
            ? `h-auto ${width} overflow-hidden kpi-widget ${
                isTop5Lessons ? customClass : ''
              }`
            : 'h-auto w-100 p-0';
          const setComponentQuery = useCallback(() => {
            setQuery(
              query?.filters?.length > 0
                ? {
                    filters: queryFilter?.filters,
                    ...(Object.keys(reportPage)?.length > 0
                      ? reportPage?.key !== 'all'
                        ? { limit: parseInt(reportPage.key) }
                        : analytic?.limit
                        ? { limit: analytic?.limit }
                        : {}
                      : ''),
                    ...(orderBy?.order?.length > 0
                      ? { order: orderBy?.order }
                      : { order: analytic?.order }),
                    ...analytic,
                    componentId: analytic?.id,
                    analytic,
                    ...(dateRange?.length > 0
                      ? {
                          timeDimensions: [
                            {
                              dimension: 'LessonProgress.updatedAt',
                              dateRange,
                            },
                          ],
                        }
                      : { timeDimensions: [] }),
                  }
                : analytic
            );
          }, [setQuery, query, analytic]);

          return (
            <React.Fragment key={index}>
              {isUserTranscript ? (
                <UserTranscriptProgress
                  insightName={component?.name}
                  userFilters={queryFilter?.filters}
                />
              ) : isLearnOverviewAssignment ? (
                <LearnDashboardProgress data={assignmentData} />
              ) : (
                <InsightsQueryUserTranscript
                  index={index}
                  containerStyle={containerStyle}
                  insight={analytic}
                  queryFilter={queryFilter}
                  reportPage={reportPage}
                  engagementComponent={engagementComponent}
                  handleSortByData={handleSortByData}
                  checklistReportSorting={checklistReportSorting}
                  dateRange={dateRange}
                  order={orderBy?.order}
                  tenant={tenant}
                  setCSVBtnLoad={setCSVBtnLoad}
                  handleCallUserComponent={handleCallUserComponent}
                  render={component?.renderComponent?.render}
                  setQuery={setComponentQuery}
                />
              )}
            </React.Fragment>
          );
        })
      )}
    </>
  );
};
export default UserTranscriptDashboard;
