import React, { useEffect, useState, useMemo } from 'react';
import labelsService from '../../services/labels.service';
import { Badge } from 'reactstrap';

const LabelComponent = ({ labelType, formValue, fieldName, labelCache }) => {
  const [label, setLabel] = useState([]);

  const getLabels = async () => {
    if (labelCache[labelType]) {
      return labelCache[labelType];
    }
    return await labelsService
      .getLabels(labelType)
      .catch((err) => console.log(err));
  };

  const fetchLabels = useMemo(() => {
    return async () => {
      const labels = await getLabels().catch((err) => console.log(err));
      if (labels) {
        labelCache[labelType] = labels;
      }
      setLabel(labels || []);
    };
  }, [labelType]);

  useEffect(() => {
    fetchLabels();
  }, [fetchLabels]);

  const labelSelected = label?.find((item) => item.id === formValue[fieldName]);

  return (
    <>
      {formValue[fieldName] && labelSelected ? (
        <Badge
          id={labelSelected?.id}
          style={{
            fontSize: '12px',
            backgroundColor: `${labelSelected?.color}`,
            whiteSpace: 'normal',
          }}
          className="text-uppercase px-2 w-auto"
        >
          {labelSelected?.name}
        </Badge>
      ) : (
        '--'
      )}
    </>
  );
};

export default LabelComponent;
