import PageTitle from '../../components/commons/PageTitle';
import ButtonFilterDropdown from '../../components/commons/ButtonFilterDropdown';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import SelfAssessment from '../Resources/selfAssessment/SelfAssessment';
import MyFavorites from '../Resources/MyLessons';
import categoryService from '../../services/category.service';
import Category from '../Resources/category/Category';
import { useHistory } from 'react-router-dom';
import {
  generatePath,
  isModuleAllowed,
  isPermissionAllowed,
  LearnViewTypes,
  sortByPinnedTopics,
} from '../../utils/Utils';
import { TenantContext } from '../../contexts/TenantContext';
import TopicIcon from '../../components/commons/TopicIcon';
import IdfTooltip from '../../components/idfComponents/idfTooltip';
import Overview from './Overview';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import OrganizationTopics from './OrganizationTopics';
import ReviewMyAssignmentsBanner from '../../components/Assignments/ReviewMyAssignmentsBanner';
import lessonService from '../../services/lesson.service';
import courseService from '../../services/course.service';
import { useProfileContext } from '../../contexts/profileContext';
import KnowledgeAssessment from '../Resources/knowledgeAssessment/KnowledgeAssessment';
import { PermissionsConstants } from '../../utils/permissions.constants';
import _ from 'lodash';
import MyOrganizationCover from '../../assets/png/learn/categories/MyOrganization-Category.png';
import AgileMindsetCover from '../../assets/png/learn/categories/AgileMindset-Category.png';
import CommercialCardCover from '../../assets/png/learn/categories/CommercialCard-Category.png';
import CoreProductAndServicesCover from '../../assets/png/learn/categories/CoreProductsAndServices-Category.png';
import FasterPaymentsCover from '../../assets/png/learn/categories/FasterPayments-Category.png';
import FraudAndRiskCover from '../../assets/png/learn/categories/FraudAndRisk-Category.png';
import HowToCover from '../../assets/png/learn/categories/HowTo-Category.png';
import IndustryInsightsCover from '../../assets/png/learn/categories/IndustryInsights-Category.png';
import MerchantServicesCover from '../../assets/png/learn/categories/MerchantServices-Category.png';
import SalesStrategyCover from '../../assets/png/learn/categories/SalesStrategyAndSkills-Category.png';
import WorkingCapitalandWorkflowsCover from '../../assets/png/learn/categories/WorkingCapitalAndWorkflows-Category.png';
import AIAdvisor from '../Resources/aiAdvisor/AIAdvisor';

const CategoryCovers = {
  AgileMindset: AgileMindsetCover,
  CommercialCard: CommercialCardCover,
  CoreProductsandServices: CoreProductAndServicesCover,
  FasterPayments: FasterPaymentsCover,
  FraudandRisk: FraudAndRiskCover,
  HowTo: HowToCover,
  IndustryInsights: IndustryInsightsCover,
  MerchantServices: MerchantServicesCover,
  MyOrganization: MyOrganizationCover,
  SalesStrategyandSkills: SalesStrategyCover,
  WorkingCapitalandWorkflows: WorkingCapitalandWorkflowsCover,
};
const convertToPascalCase = (str) => {
  return _.upperFirst(_.camelCase(str.replace(/\s+/g, '')));
};

const mapCategoryPath = (item) => {
  const path = item.title.toLocaleLowerCase().trim().replace(/ /g, '-');
  const cover = CategoryCovers[convertToPascalCase(item.title)];
  return {
    ...item,
    path: generatePath(item, path),
    cover,
  };
};

const CategoryList = ({ list, type, viewType, title, onClick }) => {
  const renameTitle = title?.replace(/-/g, ' ');
  return (
    <div className="overflow-y-auto" style={{ maxHeight: 400, minWidth: 260 }}>
      <ul className="list-group">
        {sortByPinnedTopics(list).map((item) => (
          <li key={item.id}>
            <a
              onClick={(e) => onClick(e, item, type)}
              className={`py-2 px-3 d-flex align-items-center gap-1 text-black bg-primary-soft-hover d-block w-100 border-bottom ${
                item?.title?.toLowerCase().includes(renameTitle) &&
                type === viewType
                  ? 'fw-bold text-primary bg-primary-soft'
                  : 'font-weight-medium '
              }`}
            >
              <TopicIcon
                icon={item.icon || 'category'}
                iconBg="bg-primary"
                iconStyle={{ width: 28, height: 28 }}
                iconClasses="font-size-md text-white"
              />
              {item.title.length > 25 ? (
                <IdfTooltip text={item.title}>
                  <p className="mb-0">{item.title}</p>
                </IdfTooltip>
              ) : (
                <p className="mb-0">{item.title}</p>
              )}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Learn = () => {
  const history = useHistory();
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState({});
  const [categories, setCategories] = useState({});
  const [stats, setStats] = useState({});
  const [ownerAccess, setOwnerAccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const searchParams = useUrlSearchParams();
  const title = searchParams.get('title');
  const id = searchParams.get('id');
  const path = searchParams.get('path');
  const viewType = searchParams.get('viewType');
  const ref = searchParams.get('ref');
  const { tenant } = useContext(TenantContext);
  const { profileInfo } = useProfileContext();
  const filterList = [
    {
      name: 'Overview',
      key: LearnViewTypes.Overview,
      component: null,
      moduleName: 'learns_lesson_catalog',
    },
    {
      name: 'My Learning',
      key: LearnViewTypes.MyFavorites,
      moduleName: 'learns_assignments',
      component: (
        <MyFavorites
          selectedFilter={{
            key: LearnViewTypes.MyFavorites,
            name: 'My Learning',
          }}
        />
      ),
    },
    {
      name: 'Categories',
      moduleName: 'learns_lesson_catalog',
      key: LearnViewTypes.Topics,
      component: null,
      submenu: null, // will list all public categories
    },
    {
      name: 'Self-Assessment',
      key: LearnViewTypes.SelfAssessment,
      moduleName: 'learns_self_assessment',
      component: (
        <div className="pt-3">
          <SelfAssessment />
        </div>
      ),
    },
    {
      name: 'Knowledge Assessment',
      key: LearnViewTypes.KnowledgeAssessment,
      moduleName: PermissionsConstants.Learn.KnowledgeAssessment,
      component: (
        <div className="pt-3">
          <KnowledgeAssessment />
        </div>
      ),
    },
    {
      name: 'Ask AI Advisor',
      key: LearnViewTypes.AIAdvisor,
      moduleName: PermissionsConstants.Learn.AIAdvisor,
      component: (
        <div className="pt-3">
          <AIAdvisor />
        </div>
      ),
    },
  ];
  const [updateFilterList, setUpdateFilterList] = useState(filterList);
  const [selectedFilter, setSelectedFilter] = useState(filterList[0]);

  const handleTopicCustomMenuClick = (e, item, type) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenFilter(false);
    if (item?.type === 'custom') {
      history.push(item?.url);
    } else {
      setSelectedTopic(item);
      const selectedCategory = {
        ...updateFilterList.find((f) => f.key === type),
      };
      const titlePath = item.title
        .toLocaleLowerCase()
        .trim()
        .replace(/ /g, '-');
      history.push(generatePath(item, titlePath));
      setSelectedFilter(selectedCategory);
    }
  };

  const getCategories = async () => {
    try {
      setLoader(true);
      const requests = [
        categoryService.GetCategories(null, {
          limit: 100,
          restrictBy: 'externalPublic',
          requireContent: false,
          status: 'published',
        }),
        categoryService.GetCategories(null, {
          limit: 100,
          restrictBy: 'private',
          requireContent: false,
          status: 'published',
        }),
      ];
      const myOrgData = [
        lessonService.getLessons({
          page: 1,
          limit: 100,
          restrictBy: 'private',
          requireContent: false,
          status: 'published',
        }),
        courseService.getCourses({
          page: 1,
          limit: 100,
          restrictBy: 'private',
          requireContent: false,
          status: 'published',
        }),
      ];
      const lessonsCourses = await Promise.all(myOrgData);
      const lessons = lessonsCourses[0].data;
      const courses = lessonsCourses[1].data;
      const data = {
        totalLessons: lessons?.length,
        totalCourses: courses?.length,
      };

      setStats(data);

      const responses = await Promise.all(requests);
      const exploreList = responses[0].data?.map((m) => ({
        ...m,
        isPublic: true,
      }));
      const customList = responses[1].data?.map((m) => ({
        ...m,
        isPublic: false,
      }));
      const updatedExploreList = exploreList.filter(
        (item) => item.title !== 'My Organization'
      );
      setCategories({
        exploreList: updatedExploreList?.map(mapCategoryPath),
        customList: customList.map(mapCategoryPath),
      });
      const updatedSubMenu = [...updatedExploreList];
      updatedSubMenu.unshift({
        title: 'My Organization',
        type: 'custom',
        id: '1',
        cover: CategoryCovers.MyOrganization,
        key: LearnViewTypes.Custom,
        component: <OrganizationTopics />,
        url: '?viewType=custom&ref=my-organization',
      });
      const newFilters = [...updateFilterList].map((s) => ({
        ...s,
        submenu:
          s.key === LearnViewTypes.Topics ? (
            <CategoryList
              list={updatedSubMenu?.map(mapCategoryPath)}
              onClick={handleTopicCustomMenuClick}
              type={LearnViewTypes.Topics}
              title={path}
              viewType={viewType}
              selected={selectedTopic}
            />
          ) : null,
        component:
          s.key === LearnViewTypes.MyFavorites ? (
            <MyFavorites
              selectedFilter={{
                key: LearnViewTypes.MyFavorites,
                name: 'My Learning',
              }}
              topics={exploreList?.concat(customList)}
            />
          ) : (
            s.component
          ),
        // permission check
        showHide:
          s.key === LearnViewTypes.SelfAssessment
            ? isModuleAllowed(tenant.modules, 'learns_self_assessment')
              ? ''
              : 'd-none'
            : '',
      }));
      setUpdateFilterList(newFilters);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getCategories();
  }, [viewType, path, id]);
  useEffect(() => {
    if (viewType) {
      let obj = filterList.find((f) => f.key === viewType);
      // i dont know why or how it was working before :\
      if (ref === 'my-organization') {
        obj = {
          name: 'My Organization',
          key: LearnViewTypes.Custom,
          component: <OrganizationTopics />,
          submenu: null, // will list all tenant specific categories
        };
      }
      setSelectedFilter(obj);
    } else {
      setSelectedFilter(
        filterList.find((f) => f.key === LearnViewTypes.Overview)
      );
    }
  }, [viewType]);
  useEffect(() => {
    if (profileInfo?.role) {
      setOwnerAccess(profileInfo.role.owner_access);
    }
  }, [profileInfo]);

  const isMyLearnAssignmentsAllowed =
    isModuleAllowed(tenant.modules, 'learns_assignments') &&
    (isPermissionAllowed('lessons', 'view') || ownerAccess);

  useLayoutEffect(() => {
    const filteredMenuItems = updateFilterList?.filter((item) => {
      if (item?.moduleName === 'learns_assignments') {
        return (
          isModuleAllowed(tenant?.modules, 'learns_assignments') ||
          isModuleAllowed(tenant?.modules, 'learns_lesson_catalog')
        );
      } else {
        return item?.moduleName
          ? isModuleAllowed(tenant?.modules, item?.moduleName)
          : item;
      }
    });
    setUpdateFilterList(filteredMenuItems);
    if (!isModuleAllowed(tenant.modules, 'learns_lesson_catalog')) {
      history.push(`/learn?viewType=${viewType}`);
    }
  }, [openFilter]);

  return (
    <div className="container">
      <PageTitle page={selectedFilter?.name || 'Learn'} pageModule="" />
      <ButtonFilterDropdown
        buttonText="Timeline"
        options={updateFilterList}
        filterOptionSelected={selectedFilter}
        ignoreChildHover="ignore-child"
        btnToggleStyle="rounded text-dark font-weight-500"
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilterSelect={(e, item) => {
          history.replace({
            search: `?viewType=${item.key}`,
          });
          setSelectedFilter(item);
        }}
      />

      {isMyLearnAssignmentsAllowed && (
        <ReviewMyAssignmentsBanner extraClasses={'w-100 mt-3 mb-3'} />
      )}
      <>
        {id && path ? (
          <Category
            category={{ id, title, path }}
            topics={categories?.exploreList?.concat(categories?.customList)}
          />
        ) : (
          <>
            {selectedFilter?.key === LearnViewTypes.Overview ? (
              <>
                <Overview
                  loading={loader}
                  setSelectedFilter={setSelectedFilter}
                  topics={categories}
                  stats={stats}
                  updateFilterList={updateFilterList}
                  selectedFilter={selectedFilter}
                />
              </>
            ) : (
              selectedFilter?.component
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Learn;
