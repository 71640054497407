import { Card } from 'react-bootstrap';
import Table from '../GenericTable';
import React, { useEffect, useState } from 'react';
import checklistService from '../../services/checklist.service';
import {
  DATE_FORMAT,
  checkDueDate,
  getYesterdayDate,
  isPermissionAllowed,
} from '../../utils/Utils';
import moment from 'moment';
import TableSkeleton from '../commons/TableSkeleton';
import ViewChecklist from '../fields/modals/ViewChecklist';
import { paginationDefault } from '../../utils/constants';
import AlertWrapper from '../Alert/AlertWrapper';
import Alert from '../Alert/Alert';
import { ChecklistStatuses } from '../../utils/checklist.constants';
import MaterialIcon from '../commons/MaterialIcon';
import Skeleton from 'react-loading-skeleton';
import OwnerAvatar from '../ActivitiesTable/OwnerAvatar';
import { sortingTable } from '../../utils/sortingTable';
import { useProfileContext } from '../../contexts/profileContext';
import ChecklistStatus from './ChecklistStatus';
import TableRowHover from '../commons/TableRowHover';
import Avatar from '../Avatar';
import Pagination from '../../components/Pagination';

const ChecklistOwner = ({ checklist }) => {
  const [loader, setLoader] = useState(false);
  const [checklistOwner, setChecklistOwner] = useState([]);
  const getChecklistOwner = async () => {
    try {
      setLoader(true);
      const { data } = await checklistService.getChecklistOwners({
        organizationChecklistId: checklist?.organizationChecklistId,
        limit: 1,
        page: 1,
      });
      setChecklistOwner(data?.data || []);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getChecklistOwner();
  }, []);

  return (
    <>
      {loader ? (
        <Skeleton width={100} height={10} />
      ) : (
        <div>
          {checklistOwner ? (
            checklistOwner.map((owner, index) => (
              <div key={index}>
                <OwnerAvatar key={owner} item={owner?.user} isMultiple={true} />
              </div>
            ))
          ) : (
            <h5 className="ml-1 mb-0"> -- </h5>
          )}
        </div>
      )}
    </>
  );
};

const ActivitiesChecklist = ({
  activeTabId,
  refresh,
  setCheckListCount,
  isChecklistFilterCheck,
  checklistFilter,
  setIsChecklistFilterCheck,
  selectedOwner,
  refreshCalendar,
  isFilterCheck,
  ownershipFilter,
  ChecklistStats,
}) => {
  const { profileInfo } = useProfileContext();
  const [openView, setOpenView] = useState(false);
  const [row, setRow] = useState({});
  const [pagination, setPagination] = useState(paginationDefault);
  const [dataInDB, setDataInDB] = useState(false);
  const [checkLists, setCheckLists] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [order, setOrder] = useState([['initialDueDate', 'DESC']]);
  const yesterdayDate = getYesterdayDate();

  const getOwnerFilter = () => {
    if (selectedOwner?.type) {
      if (selectedOwner.type === 'me') {
        return {
          ownerId: profileInfo?.id,
        };
      } else if (selectedOwner.type === 'other') {
        return {
          ownerId: selectedOwner?.data?.id,
        };
      } else {
        return {};
      }
    }
    return {};
  };

  const getDateFilters = () => {
    const filters = isChecklistFilterCheck?.filters || [];
    if (filters.length) {
      return isChecklistFilterCheck.filters.reduce((acc, item) => {
        acc[item.key] = item.value;
        return acc;
      }, {});
    }
    return {};
  };

  const getChecklistData = async () => {
    setShowLoading(true);
    const filters = isChecklistFilterCheck?.filters
      ? isChecklistFilterCheck.filters.reduce(
          (obj, item) => {
            obj[item.key] = item.value;
            return obj;
          },
          { ...getOwnerFilter() }
        )
      : { ...getOwnerFilter() };

    const dateFilters = getDateFilters();
    try {
      const { data } = await checklistService.getOrgCheckLists({
        page: pagination?.page,
        limit: pagination?.limit,
        ...dateFilters,
        ...filters,
        ...(ownershipFilter?.filter || {}),
        ...(activeTabId !== 'all' &&
        activeTabId !== '' &&
        activeTabId !== 'overdue'
          ? {
              'organizationChecklistProgress[status]': activeTabId,
            }
          : activeTabId === 'overdue'
          ? {
              'organizationChecklistProgress[status]': [
                'inProgress',
                'pending',
              ],
              'initialDueDateRange[end]': yesterdayDate,
            }
          : ''),
        ...(checklistFilter?.checklistId
          ? { checklistId: checklistFilter?.checklistId }
          : ''),
        order,
      });
      const checklistData = {
        data: data?.data,
        pagination: data?.pagination,
      };
      setPagination(checklistData?.pagination);
      setCheckListCount(checklistData?.pagination);
      setDataInDB(Boolean(data?.pagination?.totalPages));
      setCheckLists(checklistData?.data);
    } catch (error) {
      console.log('Error fetching checklists:', error);
    } finally {
      setShowLoading(false);
    }
  };
  useEffect(() => {
    if (activeTabId || refresh > 0) {
      profileInfo?.id && getChecklistData();
    }
  }, [
    activeTabId,
    refresh,
    order,
    pagination?.page,
    pagination?.limit,
    isChecklistFilterCheck?.filters,
    checklistFilter,
    selectedOwner,
    isFilterCheck,
    ownershipFilter,
    profileInfo,
  ]);

  const loader = () => {
    if (showLoading) return <TableSkeleton cols={6} rows={10} />;
  };
  const columns = [
    {
      key: 'company_name',
      orderBy: 'organization.name',
      component: 'Company Name',
      width: '300px',
    },
    {
      key: 'checklist_name',
      orderBy: 'checklist.title',
      component: 'Assigned Checklist',
      classes: 'pl-3',
      width: '245px',
    },
    {
      key: 'owner',
      orderBy: '',
      component: 'Owner',
      width: '170px',
    },
    {
      key: 'status',
      component: 'Status',
      orderBy: 'organizationChecklistProgresses.status',
      width: '170px',
    },
    {
      key: 'completed_at',
      component: 'Completed At',
      orderBy: 'organizationChecklistProgresses.completedAt',
      width: '170px',
    },
    {
      key: 'due_date',
      orderBy: 'initialDueDate',
      component: 'Due Date',
      width: '170px',
    },
  ];
  const data = checkLists?.map((item) => ({
    ...item,
    dataRow: [
      {
        key: 'company_name',
        component: (
          <div className="d-flex gap-1 align-items-center bg-white">
            <Avatar
              user={item?.organization}
              classModifiers="bg-primary-soft text-primary"
              sizeIcon="fs-6"
              defaultSize="xs"
              type={'organization'}
            />
            <span className="text-truncate fw-bold">
              {item?.organization?.name}
            </span>
          </div>
        ),
      },
      {
        key: 'checklist_name',
        component: (
          <span className="text-black pl-2">{item?.checklist?.title}</span>
        ),
      },
      {
        key: 'owner',
        component: (
          <div className="position-relative index-0-on-scroll">
            <ChecklistOwner checklist={item} />
          </div>
        ),
      },
      {
        key: 'status',
        component: (
          <ChecklistStatus
            item={{
              status: item?.organizationChecklistProgresses?.every(
                (progress) => progress?.progress === 100
              )
                ? ChecklistStatuses.Completed.text
                : item?.organizationChecklistProgresses?.every(
                    (progress) =>
                      progress?.progress > 0 &&
                      item?.organizationChecklistProgresses?.every(
                        (progress) => progress?.progress < 100
                      )
                  )
                ? ChecklistStatuses.InProgress.text
                : item?.organizationChecklistProgresses?.every(
                    (progress) => progress?.progress === 0
                  )
                ? ChecklistStatuses.NotStarted.text
                : '',
            }}
          />
        ),
      },
      {
        key: 'completed_at',
        component: (
          <span className="text-black">
            {item?.organizationChecklistProgresses?.every(
              (progress) => progress?.progress === 100
            )
              ? moment(
                  item?.organizationChecklistProgresses[0]?.completedAt
                ).format(DATE_FORMAT)
              : '--'}
          </span>
        ),
      },
      {
        key: 'due_date',
        bgColor:
          !item?.organizationChecklistProgresses[0]?.completedAt &&
          checkDueDate(item?.initialDueDate)
            ? 'bg-red-soft'
            : '',
        component: (
          <>
            <TableRowHover />
            <span
              className={`${
                !item?.organizationChecklistProgresses[0]?.completedAt &&
                checkDueDate(item?.initialDueDate) === 'text-red'
                  ? 'text-red'
                  : ''
              }`}
            >
              {!item?.organizationChecklistProgresses[0]?.completedAt &&
              checkDueDate(item?.initialDueDate) ? (
                <span className="position-relative" style={{ top: 5 }}>
                  <MaterialIcon
                    icon="flag"
                    rounded
                    filled
                    size="fs-flag-icon"
                    clazz="text-red"
                  />
                </span>
              ) : null}{' '}
              {moment(item?.initialDueDate).format(DATE_FORMAT)}
            </span>
          </>
        ),
      },
    ],
  }));

  const handleActivityRowClick = (item) => {
    setRow(item);
    setOpenView(true);
  };

  const changePaginationPage = (newPage) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const changePaginationLimit = (perPage) => {
    setPagination((prev) => ({ ...prev, limit: perPage }));
  };

  const sortTable = ({ name }) => {
    if (name === 'action') return null;
    sortingTable({ name, order, setOrder: (val) => setOrder([val]) }, true);
  };
  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <div className="flex-1">
        <Card className="mb-0 card-0">
          <Card.Body className="p-0">
            <div className="table-responsive-md datatable-custom">
              {showLoading ? (
                loader()
              ) : (
                <Table
                  stickyColumn="stickyColumn1"
                  columns={columns}
                  actionPadding="h-100"
                  data={data}
                  paginationInfo={{}}
                  emptyDataText="This record doesn't have any checklists"
                  emptyDataIcon="checklist"
                  title="Checklist"
                  headClass="bg-gray-table-head border-top"
                  onPageChange={changePaginationPage}
                  dataInDB={dataInDB}
                  onClick={handleActivityRowClick}
                  sortingTable={sortTable}
                  sortingOrder={order}
                  permission={{ collection: 'checklists' }}
                  usePagination={true}
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      <div className="align-items-center border-top row px-3 checklist-stats">
        <ChecklistStats />
        <div className="d-flex align-items-center ml-auto">
          <Pagination
            showPerPage
            paginationInfo={pagination}
            onPageChange={changePaginationPage}
            onLimitChange={changePaginationLimit}
          />
        </div>
      </div>
      {isPermissionAllowed('checklists', 'view') && (
        <ViewChecklist
          openModal={openView}
          setOpenModal={setOpenView}
          setIsChecklistFilterCheck={setIsChecklistFilterCheck}
          isChecklistFilterCheck={isChecklistFilterCheck}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
          checklist={row}
          activityChecklist={true}
          getChecklistData={getChecklistData}
          organization={{ name: row?.name }}
          readonly={true}
        />
      )}
    </>
  );
};
export default ActivitiesChecklist;
