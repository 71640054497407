import { Skeleton } from '@mui/material';
import React from 'react';

const Tab = () => {
  return (
    <Skeleton
      variant="rectangular"
      className="rounded"
      width={100}
      height={30}
    />
  );
};

export const LoadingDetailSkeleton = () => {
  return (
    <div className="w-100 h-100">
      <div className="d-flex align-items-center border-bottom justify-content-between">
        <div className="d-flex align-items-center gap-2 mb-3">
          <Skeleton variant="circular" width={60} height={60} />
          <div>
            <Skeleton variant="text" width={250} height={20} />
            <Skeleton variant="text" width={120} height={20} />
          </div>
          <div className="d-flex gap-2">
            <Skeleton variant="circular" width={32} height={32} />
            <Skeleton variant="circular" width={32} height={32} />
            <Skeleton variant="circular" width={32} height={32} />
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <Skeleton
            variant="rectangular"
            className="rounded"
            width={100}
            height={40}
          />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </div>
      </div>
      <div className="row mt-0" style={{ height: 'calc(100vh - 200px)' }}>
        <div className="col-md-4 d-flex flex-wrap gap-2 pr-2">
          <Skeleton
            variant="text"
            className="rounded"
            width={'40%'}
            height={30}
          />
          <Skeleton
            variant="rectangular"
            className="rounded"
            width={'100%'}
            height={'37%'}
          />
          <Skeleton
            variant="text"
            className="rounded"
            width={'40%'}
            height={30}
          />
          <Skeleton
            variant="rectangular"
            className="rounded"
            width={'100%'}
            height={'20%'}
          />
          <Skeleton
            variant="text"
            className="rounded"
            width={'40%'}
            height={30}
          />
          <Skeleton
            variant="rectangular"
            className="rounded"
            width={'100%'}
            height={'25%'}
          />
        </div>
        <div className="col-md-8 pl-2 border-left h-100">
          <div className="w-100">
            <div className="d-flex justify-content-between gap-2 my-3">
              <Tab />
              <Tab />
              <Tab />
              <Tab />
              <Tab />
              <Tab />
              <Tab />
              <Tab />
            </div>
          </div>
          <Skeleton
            variant="rectangular"
            className="rounded"
            width={'100%'}
            height={`95%`}
          />
        </div>
      </div>
    </div>
  );
};
