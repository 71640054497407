import React, { useEffect, useState } from 'react';
import { Card, CardHeader } from 'reactstrap';
import dashboardService from '../../../../services/dashboard.service';
import { ReportSkeletonLoader } from '../../ReportSkeletonLoader';
import UserTranscriptDashboard from '../../components/InsightsQueryUserTranscript';
import ButtonFilterDropdown from '../../../../components/commons/ButtonFilterDropdown';
import { reportPages } from '../../../../utils/constants';
import { DATE_FORMAT, getTomorrowDate } from '../../../../utils/Utils';
import moment from 'moment';
import DatePickerTraining from '../../../../components/dealsBreakdown/DatePickerTraining';
import ChecklistReport from '../ChecklistReport';
import checklistService from '../../../../services/checklist.service';
import ReportSearchOptionDropdown from '../../../../components/commons/ReportSearchOptionDropdown';
import ChecklistReportStats from '../ChecklistReportStats';
import { NoUserSelected } from '../../components/queries/NoUserSelected';

const ChecklistsReport = ({
  dashboard,
  selectedComponent = {},
  componentHeight = 'h-100',
  checklistId = '',
}) => {
  const renderComponents = [
    {
      name: 'Checklists Report',
      render: ChecklistReport,
    },
    {
      name: 'Checklist Report Tickers',
      render: ChecklistReportStats,
    },
  ];
  const tomorrowDate = getTomorrowDate();

  const [dateRange, setDateRange] = useState({
    startDate: new Date(1970, 0, 1),
    endDate: tomorrowDate,
  });
  const [loading, setLoading] = useState(false);
  const [dashboardComponents, setDashboardComponents] = useState([]);
  const [query, setQuery] = useState({});
  const [selectedItem, setSelectedItem] = useState([]);
  const [show, setShow] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [csvBtnLoad, setCSVBtnLoad] = useState(false);
  const [orderBy, setOrderBy] = useState([]);

  const [queryFilter, setQueryFilter] = useState({
    filters: [],
  });
  const [reportPage, setReportPage] = useState(reportPages[0]);
  const getDashboards = async () => {
    setLoading(true);

    const data = await dashboardService.getAnalytics();

    setLoading(false);

    const updatedData = data
      ?.filter((item) => {
        const matchedComponent = renderComponents?.find(
          (rc) => rc.name === item?.name
        );
        return !!matchedComponent;
      })
      .map((item) => {
        const matchedComponent = renderComponents?.find(
          (rc) => rc.name === item?.name
        );
        return { ...item, renderComponent: matchedComponent };
      });

    const { data: checklists } = await checklistService.getCheckLists({
      page: 1,
      limit: 100,
      status: 'active',
    });
    let allSelected = [];
    if (checklistId !== '') {
      const getId = checklists?.data?.find((checklist) => {
        return checklist?.checklistId === checklistId;
      });
      allSelected = [getId?.checklistId];
    } else {
      allSelected = checklists?.data?.map((rpt) => rpt?.checklistId);
    }
    setChecklists(checklists.data);
    const getDashboard = updatedData?.find(
      (item) => item?.component?.name === 'Checklist Report Tickers'
    );
    setQueryFilter({
      filters: [
        ...getDashboard?.component?.analytic?.filters,
        {
          member: 'Checklist.checklistId',
          operator: 'equals',
          values: [...allSelected],
        },
      ],
    });
    setSelectedItem(allSelected);
    setDashboardComponents(updatedData);
  };

  useEffect(() => {
    getDashboards();
  }, [selectedComponent]);
  const dateFormat = [
    moment(dateRange?.startDate).format(DATE_FORMAT),
    moment(dateRange?.endDate).format(DATE_FORMAT),
  ];

  const handleOptionSelect = (event) => {
    const { value } = event.target;
    const getDashboard = dashboardComponents?.find(
      (item) => item?.component?.name === 'Checklist Report Tickers'
    );
    if (selectedItem?.length > 0) {
      setQueryFilter({
        filters: [
          ...getDashboard?.component?.analytic?.filters,
          {
            member: 'Checklist.checklistId',
            operator: 'equals',
            values: [...selectedItem],
          },
        ],
      });
    } else {
      setQueryFilter((prevState = []) => {
        const isSelected = prevState.includes(value);
        const updatedSelectedData = isSelected
          ? prevState.filter((item) => item !== value)
          : [...prevState, value];
        return updatedSelectedData;
      });
    }
  };

  const handleAllSelect = () => {
    const allSelected = checklists?.map((rpt) => rpt?.checklistId);

    setQueryFilter({
      filters: [
        {
          operator: 'notSet',
          member: 'Checklist.deletedAt',
        },
        {
          operator: 'notSet',
          member: 'OrganizationChecklist.deletedAt',
        },
        {
          operator: 'notSet',
          member: 'OrganizationChecklistOwner.deletedAt',
        },
        {
          member: 'Checklist.checklistId',
          operator: 'equals',
          values: [...allSelected],
        },
      ],
    });
  };

  const handleSortByData = (key) => {
    setOrderBy({
      order: [[key?.name?.orderBy, key?.name?.clicked.toLowerCase()]],
    });
  };

  return (
    <Card className={`overflow-x-hidden overflow-y-auto ${componentHeight}`}>
      <CardHeader>
        <div className="d-flex justify-content-between align items center w-100">
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            {dashboard?.name}
          </h4>
          <div
            className={`d-flex align-items-center ${
              csvBtnLoad ? 'mr-5 pr-5' : ''
            }`}
          >
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              extraClass="mx-1"
            />
            <ReportSearchOptionDropdown
              selectedData={selectedItem}
              data={checklists}
              show={show}
              label={`Checklists`}
              scrollable="pr-3 text-left"
              setShow={setShow}
              showCheckAll={false}
              setSelectedData={setSelectedItem}
              setQueryFilters={setQueryFilter}
              customKey={['title', 'checklistId']}
              handleAllSelect={handleAllSelect}
              customStyle={{ width: '340px' }}
              handleOptionSelected={handleOptionSelect}
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              options={reportPages}
              filterOptionSelected={reportPage}
              handleFilterSelect={(e, item) => {
                setReportPage(item);
              }}
              btnToggleStyle={`btn-md ${csvBtnLoad ? 'mr-5 ml-2' : 'ml-2'}`}
            />
          </div>
        </div>
      </CardHeader>
      <div className="py-3 px-3">
        {loading ? (
          <div className="w-100 d-block p-1">
            <ReportSkeletonLoader rows={15} height={15} />
          </div>
        ) : (
          <>
            {queryFilter?.filters?.length > 0 ? (
              <div className="row mx-0 align-items-center flex-wrap">
                <UserTranscriptDashboard
                  dashboardComponents={dashboardComponents}
                  query={query}
                  queryFilter={queryFilter}
                  dashboard={dashboard}
                  dateRange={
                    dateRange?.key === 'Last 30 Days'
                      ? 'from 30 day ago to now'
                      : dateFormat
                  }
                  reportPage={reportPage}
                  orderBy={orderBy}
                  handleSortByData={handleSortByData}
                  checklistReportSorting={true}
                  setCSVBtnLoad={setCSVBtnLoad}
                  setQuery={setQuery}
                  width={'w-100'}
                />
              </div>
            ) : (
              <NoUserSelected label="checklist" />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default ChecklistsReport;
