import React from 'react';
import { Col, Row } from 'react-bootstrap';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import TenantThemeWrapperWidget from './TenantThemeWrapperWidget';

const IconWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <TenantThemeWrapperWidget bodyStyles="bg-primary-soft border-0 justify-content-center d-flex flex-column">
      <h5 className="text-left mb-1">{widgetConfig.heading}</h5>
      <Row
        className={`align-items-center mb-0 `}
        style={{
          minHeight: !whenPrinting ? 200 : 160,
        }}
      >
        <Col className="justify-content-center">
          <div className="widget-extra-padding">
            <p
              className={`mb-0 ${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } text-left`}
            >
              <span
                dangerouslySetInnerHTML={{ __html: widgetConfig.description }}
              />
            </p>
            {widgetConfig.source && (
              <WidgetSourceBlock text={widgetConfig.source} />
            )}
          </div>
        </Col>
        <Col
          md={3}
          className="align-items-center justify-content-center d-flex"
        >
          {widgetConfig?.star ? (
            <div
              style={{ height: 96, width: 96 }}
              className="justify-content-center gap-2 d-flex align-items-center flex-column p-2 text-center"
            >
              <div className="position-relative">
                <MaterialIcon
                  icon="brightness_empty"
                  clazz="text-primary font-size-6em"
                  symbols
                />
                <MaterialIcon
                  icon={widgetConfig.icon}
                  clazz="text-primary position-absolute shadow-lg abs-center-xy font-size-3em"
                  symbols
                />
              </div>
            </div>
          ) : (
            <div
              style={{ height: 96, width: 96 }}
              className={`justify-content-center gap-2 d-flex align-items-center flex-column p-2 text-center
              ${widgetConfig?.wrap ? '' : 'bg-white shadow-lg  rounded-lg '}`}
            >
              {widgetConfig?.shield ? (
                <div className="position-relative">
                  <MaterialIcon
                    icon="shield"
                    clazz="text-primary font-size-5em"
                    filled
                  />
                  <MaterialIcon
                    icon={widgetConfig.icon}
                    clazz="text-white position-absolute shadow-lg abs-center-xy font-size-2p5em"
                    symbols
                  />
                </div>
              ) : (
                <>
                  {widgetConfig?.wrap ? (
                    <div className="bg-primary rounded-circle d-flex p-3">
                      <MaterialIcon
                        icon={widgetConfig.icon}
                        clazz="text-white font-size-4em"
                        rounded
                      />
                    </div>
                  ) : (
                    <MaterialIcon
                      icon={widgetConfig.icon}
                      clazz="text-primary font-size-4em"
                      symbols
                    />
                  )}
                </>
              )}
            </div>
          )}
        </Col>
      </Row>
    </TenantThemeWrapperWidget>
  );
};

export default IconWidget;
