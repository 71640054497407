import React from 'react';
import MaterialIcon from './MaterialIcon';

const StatusReview = ({
  text = 'Review',
  icon = 'info',
  show,
  fontSize = 'fs-9',
  styleClass = 'bg-orange-5 text-orange',
}) => {
  return (
    <div
      className={`d-flex align-items-center py-1 px-2 gap-1 rounded-pill ${styleClass} ${show}`}
    >
      <MaterialIcon icon={icon} rounded filled clazz="font-size-md" />
      <span className={`font-weight-medium ${fontSize}`}>{text}</span>
    </div>
  );
};

export default StatusReview;
