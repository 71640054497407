import React from 'react';
import MaterialIcon from '../../../components/commons/MaterialIcon';

const ChecklistFastestStates = (results) => {
  const { data } = results[0];
  return (
    <div className="split-progress-bar">
      <div className="d-flex align-items-center gap-2 w-100">
        <MaterialIcon
          icon="electric_bolt"
          clazz="font-size-2em text-AInstituteSecondary"
        />
        <div className="w-100">
          <h5 className="text-muted fon-weight-light font-size-sm mb-1">
            Fastest Completion Time (All time)
          </h5>
          <div>
            {(data[0]?.[
              'OrganizationChecklistProgress.fastestCompletionTime'
            ] === 0 ||
              data[0]?.[
                'OrganizationChecklistProgress.fastestCompletionTime'
              ] === null) &&
            data[0]?.['OrganizationChecklistProgress.countOfCompleted'] ===
              '0' ? (
              '--'
            ) : (
              <h2>
                {(data[0]?.[
                  'OrganizationChecklistProgress.fastestCompletionTime'
                ] < 1 &&
                  data[0]?.[
                    'OrganizationChecklistProgress.fastestCompletionTime'
                  ] > 0) ||
                (data[0]?.['OrganizationChecklistProgress.countOfCompleted'] >
                  0 &&
                  data[0]?.[
                    'OrganizationChecklistProgress.fastestCompletionTime'
                  ] === 0)
                  ? 1
                  : Math.round(
                      data[0]?.[
                        'OrganizationChecklistProgress.fastestCompletionTime'
                      ]
                    )}{' '}
                {data[0]?.[
                  'OrganizationChecklistProgress.fastestCompletionTime'
                ] > 1
                  ? 'days'
                  : 'day'}
              </h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChecklistFastestStates;
