import Topics from './Topics';
import React from 'react';
const Overview = ({
  loading,
  topics,
  stats,
  updateFilterList,
  setSelectedFilter,
}) => {
  return (
    <>
      <Topics
        loading={loading}
        topics={topics}
        stats={stats}
        updateFilterList={updateFilterList}
        setSelectedFilter={setSelectedFilter}
      />
    </>
  );
};

export default Overview;
