/* eslint-disable prettier/prettier */
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Header from '../components/header';
import Sidebar from './sidebar/Sidebar';
import Breadcrumb, { getTitleBreadcrumb } from '../utils/Breadcrumb';
import { useTenantContext } from '../contexts/TenantContext';
import FullScreenSpinner from './FullScreeenSpinner';
import useTenantTheme from '../hooks/useTenantTheme';
import { useProfileContext } from '../contexts/profileContext';
import { isDisplayWelcomeScreen } from '../utils/Utils';
import useIsTenant from '../hooks/useIsTenant';
import { useModuleContext } from '../contexts/moduleContext';
import Alert from './Alert/Alert';
import AlertWrapper from './Alert/AlertWrapper';
import axios from 'axios';
import Settings from '../views/settings/Settings';
import useLoginAlert from '../hooks/useLoginAlert';

const routesForSettings = [
  '/settings/profile',
  '/settings/branding',
  '/settings/users',
  '/settings/notifications',
  '/settings/products',
  '/settings/learn',
  '/settings/stages',
  '/settings/fields',
  '/settings/checklist-fields',
  '/settings/integrations',
  '/settings/bulk-import',
  '/settings/users/:id',
  '/settings/users/roles/:id',
];

const isMatchingRoute = (route, pathname) => {
  const pattern = new RegExp(`^${route.replace(/:[^/]+/g, '([^/]+)')}$`);
  return pattern.test(pathname);
};

const Layout = ({ children, isSplitView }) => {
  const [mounted, setMounted] = useState(false);
  const [padding, setPadding] = useState('');
  const location = useLocation();
  const [showChangeLogAlert] = useState(false);
  const { LoginAlert } = useLoginAlert(showChangeLogAlert);
  const isSettingsRoute = routesForSettings.some((route) =>
    isMatchingRoute(route, location.pathname)
  );

  // const isSettingsRoute = routesForSettings.includes(location.pathname);
  const { profileInfo } = useProfileContext();
  const { tenant } = useTenantContext();
  const { isExcelBank } = useIsTenant();
  const { moduleMap } = useModuleContext();
  const isWelcomeScreen =
    location?.pathname?.includes('welcome') ||
    isDisplayWelcomeScreen(tenant?.modules);
  const [errorMessage, setErrorMessage] = useState('');
  useTenantTheme();

  useEffect(() => {
    tenant?.id && !mounted && setMounted(true);
  }, [tenant]);
  useEffect(() => {
    if (
      (isExcelBank &&
        location.pathname === '/' &&
        profileInfo?.role?.owner_access &&
        tenant?.modules === '*') ||
      (location.pathname === '/' && tenant?.modules.includes('Dashboards')) ||
      location.pathname.includes('prospecting') ||
      location.pathname.includes('reporting') ||
      location.pathname.includes('/organization/details') ||
      location.pathname === '/pipeline' ||
      location.pathname.startsWith('/pipeline/')
    ) {
      setPadding('p-0');
    } else {
      setPadding('');
    }
  }, [location, profileInfo, tenant]);

  // Axios interceptors to response
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err?.response?.status === 403) {
        setErrorMessage(
          'You don’t have permission to access or update this, please contact administrator.'
        );
      }
      throw err;
    }
  );
  if (mounted) {
    return (
      <>
        <AlertWrapper>
          <Alert
            color="danger"
            message={errorMessage}
            setMessage={setErrorMessage}
          />
        </AlertWrapper>
        <Helmet>
          <title>{getTitleBreadcrumb(location.pathname)}</title>
        </Helmet>
        <div className="has-navbar-vertical-aside navbar-vertical-aside-show-xl footer-offset">
          {!isWelcomeScreen ? (
            <>
              {showChangeLogAlert && <LoginAlert />}
              <Header />
              <Sidebar />
            </>
          ) : null}
          {isSplitView ? (
            <main
              id="content"
              role="main"
              className="main splitted-content-main"
            >
              {children}
            </main>
          ) : (
            <main className={`main ${isWelcomeScreen ? 'p-0' : ''}`}>
              {isSettingsRoute ? (
                <div className={`settings-layout ${padding}`}>
                  <Settings>{children}</Settings>
                </div>
              ) : (
                <div className={`content container-fluid ${padding}`}>
                  <div className="pl-0 breadcrumb-mobile">
                    {moduleMap.organization && (
                      <Breadcrumb moduleMap={moduleMap} />
                    )}
                  </div>
                  {children}
                </div>
              )}
            </main>
          )}
        </div>
      </>
    );
  } else {
    return <FullScreenSpinner />;
  }
};

export default Layout;
