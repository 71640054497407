import { isPermissionAllowed } from '../../utils/Utils';
import { Dropdown } from 'react-bootstrap';
import React from 'react';

const ContactCompanyDeleteMenu = ({
  isPrincipalOwner,
  permission = { collection: 'contacts', action: 'delete' },
  setOpenModal,
  setOpenCloneModal,
}) => {
  return (
    <>
      <div className="pr-0">
        {(isPermissionAllowed(permission.collection, permission.action) ||
          isPermissionAllowed(permission.collection, 'create')) && (
          <Dropdown drop="down" className="rounded idf-dropdown-item-list">
            <Dropdown.Toggle
              className={`dropdown-search btn-icon icon-hover-bg-soft-primary rounded-circle no-caret`}
              variant="link"
            >
              <i className="material-symbols-rounded fs-5">more_vert</i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-1">
              {isPermissionAllowed(permission.collection, 'create') && (
                <Dropdown.Item
                  className="pl-2 bg-hover-primary d-flex align-items-center text-gray-900"
                  onClick={() => setOpenCloneModal(true)}
                >
                  <i className="material-symbols-rounded fs-5 dropdown-item-icon">
                    content_copy
                  </i>
                  Clone
                </Dropdown.Item>
              )}
              {isPermissionAllowed(
                permission.collection,
                permission.action
              ) && (
                <Dropdown.Item
                  className="pl-2 bg-hover-danger d-flex align-items-center text-gray-900"
                  onClick={() => setOpenModal(true)}
                >
                  <i className="material-symbols-rounded fs-5 dropdown-item-icon">
                    delete
                  </i>
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </>
  );
};

export default ContactCompanyDeleteMenu;
