import ButtonIcon from './ButtonIcon';
import MaterialIcon from './MaterialIcon';
import React from 'react';

const NoDataFound = ({
  title,
  description,
  icon,
  iconStyle = 'font-size-4em',
  iconFilled,
  iconSymbol,
  showButton = false,
  buttonIcon,
  handleShow,
  titleStyle,
  label,
  iconRounded,
  containerStyle = 'py-6 my-6',
  descriptionStyle = 'font-weight-normal font-italic mb-0',
}) => {
  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center ${containerStyle}`}
    >
      {icon && (
        <MaterialIcon
          icon={icon}
          clazz={`${iconStyle} mb-1`}
          filled={iconFilled}
          symbols={iconSymbol}
          rounded={iconRounded}
        />
      )}
      <h4 className={`font-weight-medium ${titleStyle}`}>{title}</h4>
      <p className={descriptionStyle}>{description}</p>
      {showButton && (
        <ButtonIcon
          label={label}
          icon={buttonIcon}
          classnames="btn-sm text-capitalize mt-2"
          onclick={handleShow}
        />
      )}
    </div>
  );
};

export default NoDataFound;
