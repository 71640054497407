import { useEffect } from 'react';
import PageTitle from '../components/commons/PageTitle';
import { Card, CardBody, Col, Row } from 'reactstrap';
import BrandLogoIcon from '../components/sidebar/BrandLogoIcon';
import { useTenantContext } from '../contexts/TenantContext';
import DotDot from '../components/commons/DotDot';
import { getIdfToken, setIdfToken } from '../utils/Utils';
import { useHistory } from 'react-router-dom';
import { useKeycloak } from '../contexts/KeycloakProvider';
import { useAppContext } from '../contexts/appContext';
import UserService from '../services/user.service';
import useHash from '../hooks/useHash';

const KeycloakCallback = () => {
  const { tenant, count, setCount } = useTenantContext();
  const history = useHistory();
  const keycloak = useKeycloak();
  const { userHasAuthenticated } = useAppContext();
  const urlParams = new URLSearchParams(window.location.search);
  const { hash } = useHash();

  const checkAuthAndRedirect = async () => {
    const authenticated = keycloak?.authenticated;
    if (authenticated) {
      const { token: access_token, refreshToken: refresh_token } = keycloak;
      const expires = keycloak.tokenParsed.exp * 1000;
      setIdfToken(
        JSON.stringify({
          access_token,
          refresh_token,
          expires,
        })
      );
      // sync user before redirecting
      try {
        await UserService.sync();
      } catch (e) {
        console.log(e);
      }
      userHasAuthenticated(true);
      history.push('/');
    } else {
      console.log('User is not authenticated', keycloak);
      setCount(2);
      history.push('/login');
    }
  };

  useEffect(() => {
    if (
      !getIdfToken() &&
      !urlParams.get('session_state') &&
      !keycloak?.authenticated &&
      !hash
    ) {
      return history.push('/login');
    }
    count === 1 && checkAuthAndRedirect();
  }, [keycloak]);
  return (
    <>
      <PageTitle page={['Redirecting...']}></PageTitle>
      <Row className="w-100 mt-6 mx-0">
        <Col md={9} className="m-auto">
          <Card className="bg-transparent shadow-none border-none">
            <CardBody className="text-center p-sm-0 p-lg-6">
              <BrandLogoIcon tenant={tenant} />
              <h1 className="mt-4">
                Please wait. Redirecting <DotDot />
              </h1>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default KeycloakCallback;
