import React, { useCallback } from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Box, styled } from '@mui/material';
import moment from 'moment';
import { format } from 'date-fns';

const CustomPickersDay = styled(PickersDay)(({ theme, isToday }) => ({
  position: 'relative',
  width: 26,
  height: 26,
  backgroundColor: isToday ? 'var(--primaryColor)' : 'inherit',
  color: isToday ? theme.palette.common.white : 'inherit',
  '&:hover': {
    backgroundColor: isToday
      ? 'var(--primaryColor)'
      : theme.palette.action.hover,
  },
}));

const CustomBox = styled(PickersDay)(({ theme }) => ({
  '& .cal-dot-container': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: -2,
    left: '50%',
    transform: 'translateX(-54%)',
    width: '100%',
    flexDirection: 'row',
    gap: theme.spacing(0.5), // Adjust space between dots
  },
  '& .cal-dot': {
    width: 5,
    height: 5,
    borderRadius: '50%',
  },
}));

const DayWithDots = ({ day, datesWithDots = [], dotKey }) => {
  const { outsideCurrentMonth, ...other } = day;
  const currentDate = new Date(day.day);
  const dateStr = format(currentDate, 'yyyy-MM-dd'); // Format date in local time zone, to avoid date difference
  const dotEntries = datesWithDots[dateStr] || []; // Get the dots for the current date

  return (
    <CustomBox {...other}>
      <CustomPickersDay {...other} outsideCurrentMonth={outsideCurrentMonth}>
        {currentDate.getDate()}
      </CustomPickersDay>
      {dotEntries.length > 0 && (
        <Box className="cal-dot-container">
          {dotEntries.map((entry, index) => (
            <Box key={index} className={`cal-dot cal-dot-${entry[dotKey]}`} />
          ))}
        </Box>
      )}
    </CustomBox>
  );
};

const groupByDate = (datesWithDots, dateKey) => {
  return datesWithDots?.reduce((acc, curr) => {
    const dateStr = moment(curr[dateKey]).utc().format('yyyy-MM-DD');
    if (!acc[dateStr]) {
      acc[dateStr] = [];
    }
    acc[dateStr].push(curr);
    return acc;
  }, {});
};

const ChecklistCalendar = ({
  className,
  onChange,
  value,
  setSelectedDate,
  datesWithDots,
  getCalendarData,
  dateKey = 'aggregateDueDate',
  dotKey = 'status',
}) => {
  const groupedDatesWithDots = groupByDate(datesWithDots, dateKey);

  const handleMonthChange = useCallback(
    (newDate) => {
      const startDate = moment(newDate).startOf('month');
      const endDate = moment(newDate).endOf('month');
      setSelectedDate(newDate);
      getCalendarData(startDate, endDate);
    },
    [getCalendarData]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        className={className}
        value={value}
        onChange={onChange}
        onMonthChange={handleMonthChange}
        slots={{
          day: (day, _, pickersDayProps) => (
            <DayWithDots
              {...pickersDayProps}
              day={day}
              datesWithDots={groupedDatesWithDots}
              dotKey={dotKey}
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export default ChecklistCalendar;
