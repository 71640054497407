import TooltipComponent from '../../../../../components/lesson/Tooltip';
import MaterialIcon from '../../../../../components/commons/MaterialIcon';

const OverviewAssignedTable = (results, tenant) => {
  const { data } = results[0];
  return (
    <div className="card">
      <div className="card-body p-0">
        <h4
          className={`card-title p-3 text-hover-primary font-weight-medium font-size-md`}
        >
          Top 10 Lessons by Engagement Rate
        </h4>
        <table className="table table-xs table-hover w-100 table-borderless table-thead-bordered table-align-middle card-table dataTable no-footer">
          <thead>
            <tr>
              <th>Name</th>
              <th>
                <div className="d-flex align-items-center gap-1">
                  <span>Engagement</span>
                  <TooltipComponent title="Unique users who have completed the lesson">
                    <MaterialIcon icon={'info'} clazz="font-size-sm" />
                  </TooltipComponent>
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center gap-1">
                  <span>Assigned</span>
                  <TooltipComponent title="Lesson completed as part of a required assignment">
                    <MaterialIcon icon={'info'} clazz="font-size-sm" />
                  </TooltipComponent>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className="d-flex gap-1 font-weight-semi-bold align-items-baseline">
                    <span>{index + 1}.</span>
                    <span className={`text-capitalize d-inline-block`}>
                      {item['Lesson.title']}
                    </span>
                  </div>
                </td>
                <td>
                  <p className="mb-0">
                    {item['LessonProgress.uniqueCountOfUsers'] || '--'}
                  </p>
                </td>
                <td>
                  <p className="mb-0">
                    {tenant?.id === '45457ca0-3394-403f-9b95-a9c617514c18'
                      ? 'Y'
                      : item['AssignmentContent.count'] === '0'
                      ? 'N'
                      : 'Y' || '--'}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OverviewAssignedTable;
