import React from 'react';

const FlexColumn = ({ children }) => {
  return (
    <div className="d-flex align-items-start w-100 flex-column gap-2">
      {children}
    </div>
  );
};

export default FlexColumn;
