import React, { Fragment } from 'react';
import withOrganizationData from '../withOrganizationData';
import { useTenantContext } from '../../../contexts/TenantContext';
import PageTitle from '../../commons/PageTitle';

const HomeContent = ({ organization, selectedMenu = {} }) => {
  const { tenant } = useTenantContext();
  return (
    <>
      <PageTitle page={selectedMenu?.name} pageModule="" />
      <Fragment>
        {Object.entries(selectedMenu).length > 0 ? (
          <>
            <div className="page-title pl-3 pr-4 pt-3 d-flex justify-content-between align-items-center">
              <h3 className="mb-0">
                <span className="font-weight-bold">{selectedMenu?.name}</span>{' '}
                <span className="font-weight-normal">
                  {selectedMenu?.type || 'Report'}
                </span>
              </h3>
            </div>
            <div className="dasboard px-3 py-4 m-auto position-relative">
              {selectedMenu?.type === 'Checklist' ? (
                selectedMenu?.component
              ) : (
                <div className="m-auto" style={{ maxWidth: 825 }}>
                  {selectedMenu?.component(organization, tenant, true)}
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </Fragment>
    </>
  );
};

export default withOrganizationData(HomeContent);
