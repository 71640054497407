import { Row, Form } from 'react-bootstrap';

import './layout.css';

export const CardLabel = ({
  children,
  label,
  labelSize,
  id,
  formClassName,
  containerClassName,
}) => {
  return (
    <Form.Group as={Row} className={`${formClassName || `my-2`}`}>
      {label && (
        <Form.Label
          id={id}
          className={labelSize === `full` ? 'pb-2' : 'label-mw'}
          column
          xs={labelSize === `full` ? 12 : ''}
        >
          <span>{label}</span>
        </Form.Label>
      )}
      <div className={`${containerClassName || `pl-2 pr-3`} w-100`}>
        {children}
      </div>
    </Form.Group>
  );
};
