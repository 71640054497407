import { useState, useEffect } from 'react';

const useHash = () => {
  const [hash, setHash] = useState(window.location.hash.substring(1));
  useEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash.substring(1));
    };
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return {
    hash,
    updateHash: (newHash) => {
      window.location.hash = newHash;
    },
  };
};

export default useHash;
