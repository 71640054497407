import { Label } from 'reactstrap';
import { InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { formatCurrencyField } from '../../utils/Utils';
import React from 'react';

const WorkingCapitalFields = [
  'Accounts Payable',
  'Accounts Receivable',
  'Net Sales',
  'Cost of Goods Sold',
  'Inventory',
];

const WCInputField = ({
  report,
  label,
  index,
  labelKey,
  stacked,
  handleChange,
  handleRef,
  handleKeyPress,
}) => {
  const isCostOfCapital = label === 'Cost of Capital';
  return (
    <div
      className={`d-flex py-1 justify-content-between ${
        stacked ? 'flex-column' : 'align-items-center'
      }`}
    >
      {stacked ? (
        <Label className="mb-2 font-weight-normal">{label}</Label>
      ) : (
        <h6 className="mb-0" style={{ minWidth: 160 }}>
          {label}
        </h6>
      )}
      <InputGroup className="align-items-center">
        {!isCostOfCapital && (
          <InputGroup.Prepend>
            <InputGroup.Text style={{ width: 40 }}>$</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <CurrencyInput
          name={labelKey}
          placeholder="0"
          decimalsLimit={2}
          decimalScale={2}
          allowDecimals={isCostOfCapital}
          value={
            isCostOfCapital
              ? report[labelKey]
              : formatCurrencyField(report[labelKey]) || ''
          }
          ref={handleRef(index)}
          onKeyDown={(e) => handleKeyPress(e, index)}
          className="form-control"
          onValueChange={(value, name, values) => {
            const inputValue = isCostOfCapital
              ? values.formatted
              : values.float;
            handleChange({ target: { value: inputValue, name } }, name);
          }}
        />
        {isCostOfCapital && (
          <InputGroup.Append>
            <InputGroup.Text style={{ width: 40 }}>%</InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>
    </div>
  );
};

export { WCInputField, WorkingCapitalFields };
