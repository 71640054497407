import React from 'react';
import { Badge } from 'react-bootstrap';
import IdfTooltip from '../idfComponents/idfTooltip';

const Counter = ({ moduleName, active, count }) => {
  return (
    <>
      {count > 0 ? (
        <Badge
          style={{ width: 22, height: 22 }}
          className={`rounded-circle d-flex align-items-center justify-content-center ml-2 fs-9 p-1 ${
            active ? 'bg-white' : 'bg-primary-soft'
          }`}
        >
          {count > 10 ? (
            <IdfTooltip text={`Total ${moduleName}: ${count}`}>10+</IdfTooltip>
          ) : (
            count
          )}
        </Badge>
      ) : null}
    </>
  );
};
const PillFilters = ({ items, moduleName, currentFilter, onFilterChange }) => {
  return (
    <div className="d-flex p-1 align-items-center rounded-pill border">
      {items.map((fil) => (
        <a
          key={fil.id}
          style={{ minWidth: 100 }}
          onClick={(e) => {
            e.preventDefault();
            onFilterChange(fil);
          }}
          className={`py-1 px-2 ${
            currentFilter.id === fil.id ? 'bg-primary-soft fw-bold' : ''
          } text-center fs-7 d-flex align-items-center justify-content-center bg-hover-gray cursor-pointer rounded-pill`}
        >
          {fil.label}
          <Counter
            active={currentFilter.id === fil.id}
            moduleName={moduleName}
            count={fil.count}
          />
        </a>
      ))}
    </div>
  );
};

export default PillFilters;
