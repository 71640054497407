import { ReportTypes } from './reports.constants';
import React from 'react';

export const ReportCovers = {
  // 2.svg
  [ReportTypes.Merchant]: (
    <>
      <defs>
        <clipPath id="1aff7aac38">
          <path
            d="M 241.464844 0 L 594.675781 0 L 594.675781 335 L 241.464844 335 Z M 241.464844 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="4483e03326">
          <path
            d="M 380 51 L 594.675781 51 L 594.675781 841.5 L 380 841.5 Z M 380 51 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="892219727d">
          <path
            d="M 410 0 L 594.675781 0 L 594.675781 841.5 L 410 841.5 Z M 410 0 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#1aff7aac38)">
        <path
          fill="light"
          d="M 241.332031 -24.246094 L 625.652344 -24.246094 L 625.652344 334.867188 Z M 241.332031 -24.246094 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#4483e03326)">
        <path
          fill="dark"
          d="M 380.113281 865.386719 L 625.652344 51.667969 L 625.480469 865.386719 Z M 380.113281 865.386719 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#892219727d)">
        <path
          fill="medium"
          d="M 410.363281 133.8125 L 625.652344 865.386719 L 625.652344 -24.246094 Z M 410.363281 133.8125 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </>
  ),
  // 3.svg
  [ReportTypes.Treasury]: (
    <>
      <defs>
        <clipPath id="fb5d978d2a">
          <path
            d="M 447 282 L 594.675781 282 L 594.675781 841.5 L 447 841.5 Z M 447 282 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="610385febe">
          <path
            d="M 539 322 L 594.675781 322 L 594.675781 694 L 539 694 Z M 539 322 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="9961873fc5">
          <path
            d="M 19 603 L 594.675781 603 L 594.675781 841.5 L 19 841.5 Z M 19 603 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="49f9ae72c6">
          <path
            d="M 44 759 L 594.675781 759 L 594.675781 841.5 L 44 841.5 Z M 44 759 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="686dfbc0c1">
          <path
            d="M 9 598 L 594.675781 598 L 594.675781 841.5 L 9 841.5 Z M 9 598 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="5e75773b5a">
          <path
            d="M 0.246094 236.117188 L 594.675781 236.117188 L 594.675781 841.5 L 0.246094 841.5 Z M 0.246094 236.117188 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="c2e73e31fb">
          <path
            d="M 0.246094 0 L 384 0 L 384 235 L 0.246094 235 Z M 0.246094 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="fa0896822e">
          <path
            d="M 0.246094 0 L 359 0 L 359 80 L 0.246094 80 Z M 0.246094 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="1c82504a2f">
          <path
            d="M 0.246094 0 L 394 0 L 394 240 L 0.246094 240 Z M 0.246094 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="ff7c245d60">
          <path
            d="M 0.246094 0 L 440.5 0 L 440.5 602.804688 L 0.246094 602.804688 Z M 0.246094 0 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#fb5d978d2a)">
        <path
          fill="medium"
          d="M 447.699219 895.109375 L 632.804688 895.109375 L 632.804688 282.769531 L 522.742188 645.972656 L 520.742188 651.976562 L 519.742188 657.980469 L 518.738281 658.980469 L 515.738281 671.988281 L 478.71875 794.054688 Z M 447.699219 895.109375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#610385febe)">
        <path
          fill="dark"
          d="M 539.75 694 L 632.804688 651.976562 L 632.804688 322.792969 L 555.761719 630.964844 L 554.757812 630.964844 L 553.757812 636.96875 L 552.757812 641.96875 L 551.757812 642.96875 L 548.757812 655.976562 Z M 539.75 694 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#9961873fc5)">
        <path
          fill="medium"
          d="M 19.460938 895.109375 L 632.804688 895.109375 L 632.804688 603.949219 L 552.757812 641.96875 L 519.742188 657.980469 Z M 19.460938 895.109375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#49f9ae72c6)">
        <path
          fill="dark"
          d="M 44.476562 895.109375 L 632.804688 895.109375 L 632.804688 759.035156 L 478.71875 794.054688 L 45.476562 895.109375 Z M 44.476562 895.109375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#686dfbc0c1)">
        <path
          fill="light"
          d="M 9.457031 895.109375 L 45.476562 895.109375 L 515.738281 671.988281 L 548.757812 655.976562 L 632.804688 615.957031 L 632.804688 598.945312 L 553.757812 636.96875 L 520.742188 651.976562 Z M 9.457031 895.109375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#5e75773b5a)">
        <path
          fill="verylight"
          d="M -1.546875 895.109375 L 21.464844 895.109375 L 499.730469 667.984375 L 511.734375 661.980469 L 518.738281 658.980469 L 551.757812 642.96875 L 632.804688 604.949219 L 632.804688 593.941406 L 555.761719 630.964844 L 523.742188 645.972656 L 520.742188 647.972656 Z M 509.734375 634.964844 L 632.804688 236.746094 L 632.804688 270.765625 L 521.742188 629.964844 L 517.738281 642.96875 L -14.554688 896.109375 L -37.570312 896.109375 Z M 509.734375 634.964844 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#c2e73e31fb)">
        <path
          fill="medium"
          d="M 383.1875 -56.1875 L -230.152344 -56.1875 L -230.152344 234.972656 L -150.105469 196.953125 L -117.089844 180.945312 Z M 383.1875 -56.1875 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#fa0896822e)">
        <path
          fill="dark"
          d="M 358.175781 -56.1875 L -230.152344 -56.1875 L -230.152344 79.886719 L -76.066406 44.867188 L 357.175781 -56.1875 Z M 358.175781 -56.1875 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#1c82504a2f)">
        <path
          fill="light"
          d="M 393.195312 -56.1875 L 357.175781 -56.1875 L -113.085938 166.9375 L -146.105469 182.945312 L -230.152344 222.964844 L -230.152344 239.976562 L -151.105469 201.957031 L -118.089844 186.945312 Z M 393.195312 -56.1875 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#ff7c245d60)">
        <path
          fill="verylight"
          d="M 404.199219 -56.1875 L 381.1875 -56.1875 L -97.078125 170.9375 L -109.082031 176.941406 L -116.085938 179.941406 L -149.105469 195.953125 L -230.152344 233.972656 L -230.152344 244.980469 L -153.109375 207.957031 L -121.089844 192.949219 L -118.089844 190.949219 Z M -107.082031 203.957031 L -230.152344 602.175781 L -230.152344 568.15625 L -119.089844 208.960938 L -115.085938 195.953125 L 417.207031 -57.1875 L 440.222656 -57.1875 Z M -107.082031 203.957031 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </>
  ),
  // 4.svg
  [ReportTypes.WorkingCapital]: (
    <>
      <defs>
        <clipPath id="491d5754de">
          <path
            d="M 459 43 L 594.675781 43 L 594.675781 275 L 459 275 Z M 459 43 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="87c3252a84">
          <path
            d="M 174 0 L 594.675781 0 L 594.675781 275 L 174 275 Z M 174 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="66e988f2ab">
          <path
            d="M 532 0 L 594.675781 0 L 594.675781 408.382812 L 532 408.382812 Z M 532 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="bfe36838d9">
          <path
            d="M 59.71875 0 L 329 0 L 329 65 L 59.71875 65 Z M 59.71875 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="a4e2e9539b">
          <path
            d="M 0.246094 714 L 134 714 L 134 841.5 L 0.246094 841.5 Z M 0.246094 714 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="7a14e16208">
          <path
            d="M 0.246094 714 L 332 714 L 332 841.5 L 0.246094 841.5 Z M 0.246094 714 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="21090a553a">
          <path
            d="M 0.246094 621.070312 L 84 621.070312 L 84 841.5 L 0.246094 841.5 Z M 0.246094 621.070312 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <path
        fill="medium"
        d="M 189.699219 64.878906 L 513.457031 64.878906 L 276.238281 1.554688 L 174.460938 43.769531 Z M 189.699219 64.878906 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <g clipPath="url(#491d5754de)">
        <path
          fill="medium"
          d="M 532.152344 274.277344 L 459.785156 43.769531 L 669.855469 92.015625 L 722.453125 228.707031 Z M 532.152344 274.277344 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#87c3252a84)">
        <path
          fill="dark"
          d="M 807.554688 274.277344 L 174.460938 -26.074219 L 807.554688 -26.074219 Z M 807.554688 274.277344 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#66e988f2ab)">
        <path
          fill="light"
          d="M 807.554688 -20.554688 L 532.152344 274.277344 L 807.554688 408.289062 Z M 807.554688 -20.554688 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#bfe36838d9)">
        <path
          fill="light"
          d="M 328.722656 -26.074219 L 189.699219 64.878906 L 59.71875 -26.074219 Z M 328.722656 -26.074219 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#a4e2e9539b)">
        <path
          fill="medium"
          d="M 83.179688 714.050781 L 133.332031 873.789062 L -12.246094 840.355469 L -48.699219 745.628906 Z M 83.179688 714.050781 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#7a14e16208)">
        <path
          fill="#2668a6"
          d="M -107.671875 714.050781 L 331.058594 922.191406 L -107.671875 922.191406 Z M -107.671875 714.050781 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#21090a553a)">
        <path
          fill="light"
          d="M -107.671875 918.367188 L 83.179688 714.050781 L -107.671875 621.179688 Z M -107.671875 918.367188 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </>
  ),
  // 1.svg
  [ReportTypes.Fraud]: (
    <>
      <defs>
        <filter x="0%" y="0%" width="100%" height="100%" id="e92e8c782f">
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            colorInterpolationFilters="sRGB"
          />
        </filter>
        <clipPath id="3830f69072">
          <path
            d="M 462 0 L 594.675781 0 L 594.675781 841.5 L 462 841.5 Z M 462 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="48e008b98d">
          <path
            d="M 201 0 L 594.675781 0 L 594.675781 592 L 201 592 Z M 201 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="3bcc4fed89">
          <path
            d="M 201 0 L 594.675781 0 L 594.675781 323 L 201 323 Z M 201 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="0abd65d7fb">
          <path
            d="M 442 0 L 594.675781 0 L 594.675781 841.5 L 442 841.5 Z M 442 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <mask id="ae4daa1f72">
          <g filter="url(#e92e8c782f)">
            <rect
              x="-59.495996"
              width="713.951952"
              fill="#000000"
              y="-84.224997"
              height="1010.699964"
              fillOpacity="0.2"
            />
          </g>
        </mask>
        <clipPath id="38db7d564a">
          <path
            d="M 0.171875 0 L 152.675781 0 L 152.675781 841.5 L 0.171875 841.5 Z M 0.171875 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="cff44d86b1">
          <rect x="0" width="153" y="0" height="842" />
        </clipPath>
      </defs>
      <g clipPath="url(#3830f69072)">
        <path
          fill="medium"
          d="M 462.582031 909.824219 L 651.09375 909.824219 L 651.09375 -43.347656 L 623.242188 97.476562 L 552.890625 453.191406 L 552.0625 457.382812 Z M 462.582031 909.824219 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#48e008b98d)">
        <path
          fill="light"
          d="M 651.09375 591.785156 L 651.09375 106.765625 L 623.242188 97.476562 L 461.472656 43.515625 L 201.058594 -43.347656 L 550.363281 449.625 L 552.890625 453.191406 Z M 651.09375 591.785156 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#3bcc4fed89)">
        <path
          fill="dark"
          d="M 651.09375 -43.347656 L 651.09375 322.351562 L 201.058594 -43.347656 Z M 651.09375 -43.347656 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#0abd65d7fb)">
        <g mask="url(#ae4daa1f72)">
          <g transform="matrix(1, 0, 0, 1, 442, 0)">
            <g clipPath="url(#cff44d86b1)">
              <g clipPath="url(#38db7d564a)">
                <path
                  fill="medium"
                  d="M 209.09375 909.824219 L 209.09375 -43.347656 L 0.457031 -43.347656 L 19.472656 43.515625 L 108.363281 449.625 L 110.890625 453.191406 L 110.0625 457.382812 Z M 209.09375 909.824219 "
                  fillOpacity="1"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </>
  ),
  // 1.svg
  [ReportTypes.AccountStructure]: (
    <>
      <defs>
        <filter x="0%" y="0%" width="100%" height="100%" id="e92e8c782f">
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            colorInterpolationFilters="sRGB"
          />
        </filter>
        <clipPath id="3830f69072">
          <path
            d="M 462 0 L 594.675781 0 L 594.675781 841.5 L 462 841.5 Z M 462 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="48e008b98d">
          <path
            d="M 201 0 L 594.675781 0 L 594.675781 592 L 201 592 Z M 201 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="3bcc4fed89">
          <path
            d="M 201 0 L 594.675781 0 L 594.675781 323 L 201 323 Z M 201 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="0abd65d7fb">
          <path
            d="M 442 0 L 594.675781 0 L 594.675781 841.5 L 442 841.5 Z M 442 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <mask id="ae4daa1f72">
          <g filter="url(#e92e8c782f)">
            <rect
              x="-59.495996"
              width="713.951952"
              fill="#000000"
              y="-84.224997"
              height="1010.699964"
              fillOpacity="0.2"
            />
          </g>
        </mask>
        <clipPath id="38db7d564a">
          <path
            d="M 0.171875 0 L 152.675781 0 L 152.675781 841.5 L 0.171875 841.5 Z M 0.171875 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="cff44d86b1">
          <rect x="0" width="153" y="0" height="842" />
        </clipPath>
      </defs>
      <g clipPath="url(#3830f69072)">
        <path
          fill="medium"
          d="M 462.582031 909.824219 L 651.09375 909.824219 L 651.09375 -43.347656 L 623.242188 97.476562 L 552.890625 453.191406 L 552.0625 457.382812 Z M 462.582031 909.824219 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#48e008b98d)">
        <path
          fill="light"
          d="M 651.09375 591.785156 L 651.09375 106.765625 L 623.242188 97.476562 L 461.472656 43.515625 L 201.058594 -43.347656 L 550.363281 449.625 L 552.890625 453.191406 Z M 651.09375 591.785156 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#3bcc4fed89)">
        <path
          fill="dark"
          d="M 651.09375 -43.347656 L 651.09375 322.351562 L 201.058594 -43.347656 Z M 651.09375 -43.347656 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#0abd65d7fb)">
        <g mask="url(#ae4daa1f72)">
          <g transform="matrix(1, 0, 0, 1, 442, 0)">
            <g clipPath="url(#cff44d86b1)">
              <g clipPath="url(#38db7d564a)">
                <path
                  fill="medium"
                  d="M 209.09375 909.824219 L 209.09375 -43.347656 L 0.457031 -43.347656 L 19.472656 43.515625 L 108.363281 449.625 L 110.890625 453.191406 L 110.0625 457.382812 Z M 209.09375 909.824219 "
                  fillOpacity="1"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </>
  ),
  [ReportTypes.CommercialCard]: (
    <>
      <defs>
        <clipPath id="d020da3c5f">
          <path d="M0 .148h644V566H0zm0 0"></path>
        </clipPath>
        <clipPath id="06faee9d2f">
          <path d="M299 .148h392.691V273H299zm0 0"></path>
        </clipPath>
        <clipPath id="25ca131654">
          <path d="M0 .148h374V296H0zm0 0"></path>
        </clipPath>
        <clipPath>
          <path d="M0 272h300v506.672H0zm0 0"></path>
        </clipPath>
        <clipPath id="49c5c752b2">
          <path d="M0 .148h512V370H0zm0 0"></path>
        </clipPath>
        <clipPath id="0da81eb9f7">
          <path d="M167 .148h392.293V77H167zm0 0"></path>
        </clipPath>
        <clipPath id="dba8ab28f8">
          <path d="M0 .148h242V100H0zm0 0"></path>
        </clipPath>
        <clipPath id="851afea1bd">
          <path d="M0 76h168v506.668H0zm0 0"></path>
        </clipPath>
        <clipPath id="0132d65cc3">
          <path d="M479 1292h578v394.852H479zm0 0"></path>
        </clipPath>
        <clipPath id="89aa405607">
          <path d="M431.777 1585H824v101.852H431.777zm0 0"></path>
        </clipPath>
        <clipPath id="34797b4753">
          <path d="M749 1562h308v124.852H749zm0 0"></path>
        </clipPath>
        <clipPath id="caecf24a0e">
          <path d="M823 1079.496h234V1586H823zm0 0"></path>
        </clipPath>
        <clipPath id="fd3f312bd3">
          <path d="M176 .148h881V458H176zm0 0"></path>
        </clipPath>
        <clipPath id="2da3e878d6">
          <path d="M97 1298h924v388.852H97zm0 0"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#d020da3c5f)">
        <path
          fill="dark"
          fillRule="evenodd"
          d="M-41.848-119.23h685.215L-41.848 565.55zm0 0"
        ></path>
      </g>
      <g clipPath="url(#06faee9d2f)">
        <path
          fill="verylight"
          fillRule="evenodd"
          d="M527.258-119.23h164.457L299.859 272.367V108.04zm0 0"
        ></path>
      </g>
      <g clipPath="url(#25ca131654)">
        <path
          fill="medium"
          fillRule="evenodd"
          d="M-41.848-119.23h415.27L-41.848 295.78zm0 0"
        ></path>
      </g>
      <g clipPath="url(#382e878935)">
        <path
          fill="verylight"
          fillRule="evenodd"
          d="M-42.2 778.531l.352-164.683 341.707-341.48v164.331zm0 0"
        ></path>
      </g>
      <g clipPath="url(#49c5c752b2)">
        <path
          fill="dark"
          fillRule="evenodd"
          d="M-174.195-315.234h685.21l-685.21 684.777zm0 0"
        ></path>
      </g>
      <g clipPath="url(#0da81eb9f7)">
        <path
          fill="verylight"
          fillRule="evenodd"
          d="M394.91-315.234h164.457L167.507 76.363V-87.969zm0 0"
        ></path>
      </g>
      <g clipPath="url(#dba8ab28f8)">
        <path
          fill="medium"
          fillRule="evenodd"
          d="M-174.195-315.234h415.27l-415.27 415.011zm0 0"
        ></path>
      </g>
      <g clipPath="url(#851afea1bd)">
        <path
          fill="verylight"
          fillRule="evenodd"
          d="M-174.55 582.527l.355-164.683 341.703-341.48v164.331zm0 0"
        ></path>
      </g>
      <g clipPath="url(#0132d65cc3)">
        <path
          fill="dark"
          fillRule="evenodd"
          d="M1165.012 1977.398H479.8l685.21-684.78zm0 0"
        ></path>
      </g>
      <g clipPath="url(#89aa405607)">
        <path
          fill="verylight"
          fillRule="evenodd"
          d="M595.906 1977.398H431.45l391.856-391.597v164.328zm0 0"
        ></path>
      </g>
      <g clipPath="url(#34797b4753)">
        <path
          fill="medium"
          fillRule="evenodd"
          d="M1165.012 1977.398h-415.27l415.27-415.015zm0 0"
        ></path>
      </g>
      <g clipPath="url(#caecf24a0e)">
        <path
          fill="verylight"
          fillRule="evenodd"
          d="M1165.367 1079.637l-.355 164.683-341.707 341.48V1421.47zm0 0"
        ></path>
      </g>
      <path
        fill="dark"
        stroke="#015a7d"
        strokeWidth="2.999"
        d="M-564.684 1384.64l888.23-880.953"
      ></path>
      <g clipPath="url(#fd3f312bd3)">
        <path
          fill="dark"
          stroke="#015a7d"
          strokeWidth="2.999"
          d="M193.262 440.625l888.23-880.957"
        ></path>
      </g>
      <g clipPath="url(#2da3e878d6)">
        <path
          fill="dark"
          stroke="#015a7d"
          strokeWidth="2.999"
          d="M114.906 2196.234l888.23-880.953"
        ></path>
      </g>
    </>
  ),
  [ReportTypes.MerchantV2]: (
    <>
      <defs>
        <clipPath id="fb5d978d2a">
          <path
            d="M 447 282 L 594.675781 282 L 594.675781 841.5 L 447 841.5 Z M 447 282 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="610385febe">
          <path
            d="M 539 322 L 594.675781 322 L 594.675781 694 L 539 694 Z M 539 322 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="9961873fc5">
          <path
            d="M 19 603 L 594.675781 603 L 594.675781 841.5 L 19 841.5 Z M 19 603 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="49f9ae72c6">
          <path
            d="M 44 759 L 594.675781 759 L 594.675781 841.5 L 44 841.5 Z M 44 759 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="686dfbc0c1">
          <path
            d="M 9 598 L 594.675781 598 L 594.675781 841.5 L 9 841.5 Z M 9 598 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="5e75773b5a">
          <path
            d="M 0.246094 236.117188 L 594.675781 236.117188 L 594.675781 841.5 L 0.246094 841.5 Z M 0.246094 236.117188 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="c2e73e31fb">
          <path
            d="M 0.246094 0 L 384 0 L 384 235 L 0.246094 235 Z M 0.246094 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="fa0896822e">
          <path
            d="M 0.246094 0 L 359 0 L 359 80 L 0.246094 80 Z M 0.246094 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="1c82504a2f">
          <path
            d="M 0.246094 0 L 394 0 L 394 240 L 0.246094 240 Z M 0.246094 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="ff7c245d60">
          <path
            d="M 0.246094 0 L 440.5 0 L 440.5 602.804688 L 0.246094 602.804688 Z M 0.246094 0 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#fb5d978d2a)">
        <path
          fill="medium"
          d="M 447.699219 895.109375 L 632.804688 895.109375 L 632.804688 282.769531 L 522.742188 645.972656 L 520.742188 651.976562 L 519.742188 657.980469 L 518.738281 658.980469 L 515.738281 671.988281 L 478.71875 794.054688 Z M 447.699219 895.109375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#610385febe)">
        <path
          fill="dark"
          d="M 539.75 694 L 632.804688 651.976562 L 632.804688 322.792969 L 555.761719 630.964844 L 554.757812 630.964844 L 553.757812 636.96875 L 552.757812 641.96875 L 551.757812 642.96875 L 548.757812 655.976562 Z M 539.75 694 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#9961873fc5)">
        <path
          fill="medium"
          d="M 19.460938 895.109375 L 632.804688 895.109375 L 632.804688 603.949219 L 552.757812 641.96875 L 519.742188 657.980469 Z M 19.460938 895.109375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#49f9ae72c6)">
        <path
          fill="dark"
          d="M 44.476562 895.109375 L 632.804688 895.109375 L 632.804688 759.035156 L 478.71875 794.054688 L 45.476562 895.109375 Z M 44.476562 895.109375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#686dfbc0c1)">
        <path
          fill="light"
          d="M 9.457031 895.109375 L 45.476562 895.109375 L 515.738281 671.988281 L 548.757812 655.976562 L 632.804688 615.957031 L 632.804688 598.945312 L 553.757812 636.96875 L 520.742188 651.976562 Z M 9.457031 895.109375 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#5e75773b5a)">
        <path
          fill="verylight"
          d="M -1.546875 895.109375 L 21.464844 895.109375 L 499.730469 667.984375 L 511.734375 661.980469 L 518.738281 658.980469 L 551.757812 642.96875 L 632.804688 604.949219 L 632.804688 593.941406 L 555.761719 630.964844 L 523.742188 645.972656 L 520.742188 647.972656 Z M 509.734375 634.964844 L 632.804688 236.746094 L 632.804688 270.765625 L 521.742188 629.964844 L 517.738281 642.96875 L -14.554688 896.109375 L -37.570312 896.109375 Z M 509.734375 634.964844 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#c2e73e31fb)">
        <path
          fill="medium"
          d="M 383.1875 -56.1875 L -230.152344 -56.1875 L -230.152344 234.972656 L -150.105469 196.953125 L -117.089844 180.945312 Z M 383.1875 -56.1875 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#fa0896822e)">
        <path
          fill="dark"
          d="M 358.175781 -56.1875 L -230.152344 -56.1875 L -230.152344 79.886719 L -76.066406 44.867188 L 357.175781 -56.1875 Z M 358.175781 -56.1875 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#1c82504a2f)">
        <path
          fill="light"
          d="M 393.195312 -56.1875 L 357.175781 -56.1875 L -113.085938 166.9375 L -146.105469 182.945312 L -230.152344 222.964844 L -230.152344 239.976562 L -151.105469 201.957031 L -118.089844 186.945312 Z M 393.195312 -56.1875 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#ff7c245d60)">
        <path
          fill="verylight"
          d="M 404.199219 -56.1875 L 381.1875 -56.1875 L -97.078125 170.9375 L -109.082031 176.941406 L -116.085938 179.941406 L -149.105469 195.953125 L -230.152344 233.972656 L -230.152344 244.980469 L -153.109375 207.957031 L -121.089844 192.949219 L -118.089844 190.949219 Z M -107.082031 203.957031 L -230.152344 602.175781 L -230.152344 568.15625 L -119.089844 208.960938 L -115.085938 195.953125 L 417.207031 -57.1875 L 440.222656 -57.1875 Z M -107.082031 203.957031 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
    </>
  ),
};
