import React from 'react';
import GreenRedCheckItem from '../../blocks/GreenRedCheckItem';
import { TreasuryReportSections } from '../../../reports/reports.constants';
import { Card, CardBody, Col, Row } from 'reactstrap';

const Items = ({ items, isChecked, whenPrinting }) => {
  return (
    <div className="d-flex flex-column">
      {items.map((blk) => (
        <GreenRedCheckItem
          key={blk.text}
          iconBg={true}
          section={TreasuryReportSections.Fraud}
          textWrap="text-nowrap"
          bordered=""
          itemStyle="text-center w-100"
          containerStyle={whenPrinting ? 'pb-1' : 'py-2'}
          textStyle={whenPrinting ? 'fs-10' : 'fs-8'}
          item={[
            blk.text,
            {
              isChecked,
              key: blk.text,
              value: isChecked ? 1 : 0,
              itemValue: 1,
              icon: blk.icon,
            },
          ]}
        />
      ))}
    </div>
  );
};

const Dos = [
  {
    text: 'Verify using contact <br /> information on file',
    icon: 'contact_phone',
    rounded: false,
    filled: true,
  },
  {
    text: 'Validate urgent requests <br /> before acting',
    icon: 'paid',
    rounded: false,
    filled: true,
  },
  {
    text: 'Enable two-factor <br /> authentication',
    icon: 'deployed_code_account',
    rounded: true,
    filled: false,
  },
];

const Donts = [
  {
    text: 'Verify source before <br /> moving data/funds',
    icon: 'person_check',
    rounded: true,
    filled: false,
  },
  {
    text: 'Avoid clicking unknown <br /> links/attachments',
    icon: 'attach_email',
    rounded: false,
    filled: true,
  },
  {
    text: 'Verify emails with <br /> contact on file',
    icon: 'contacts',
    rounded: false,
    filled: true,
  },
];

const BusinessEmailCompromiseWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody>
        <div>
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-2">
            <p
              className={`${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } mb-1 text-left`}
            >
              {widgetConfig.description}
            </p>
            <Row className="px-4">
              <Col className="px-0 d-flex justify-content-center">
                <Items
                  items={Dos}
                  isChecked={true}
                  whenPrinting={whenPrinting}
                />
              </Col>
              <Col md={1}>
                <span className="line fti-item etpr ivr bec" />
              </Col>
              <Col className="px-0 d-flex justify-content-center">
                <Items
                  items={Donts}
                  isChecked={false}
                  whenPrinting={whenPrinting}
                />
              </Col>
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default BusinessEmailCompromiseWidget;
