import React from 'react';
import {
  ElectronicPaymentsSavingsData,
  ElectronicPaymentsToGrowthData,
} from '../../../reportbuilder/constants/widgetsConstants';
import ElectronicPaymentsSavingsWidget from '../../../reportbuilder/widgets/horizontal/ElectronicPaymentsSavingsWidget';
import ElectronicPaymentsForGrowthWidget from '../../../reportbuilder/widgets/horizontal/ElectronicPaymentsForGrowthWidget';
import {
  AccountSchematicReportSections,
  AccountStructureTabMap,
} from '../account.structure.constants';
import { usePagesContext } from '../../../../contexts/pagesContext';

const Widget = () => {
  return (
    <div className="mt-3">
      <ElectronicPaymentsForGrowthWidget
        widgetConfig={ElectronicPaymentsToGrowthData}
      />
    </div>
  );
};
const AccountsPayables = ({ report }) => {
  const { pageContext } = usePagesContext();
  const accountStructure =
    report?.AccountStructureReport || pageContext.AccountStructureReport;
  const showWidget =
    accountStructure[AccountStructureTabMap.Widgets.key][
      AccountSchematicReportSections.AccountsPayable
    ]?.isActive;
  return (
    <>
      <ElectronicPaymentsSavingsWidget
        widgetConfig={ElectronicPaymentsSavingsData}
      />
      {showWidget && <Widget />}
    </>
  );
};

export default AccountsPayables;
