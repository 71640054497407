import React, { useMemo } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS } from 'chart.js';
import { Chart } from 'react-chartjs-2';
ChartJS.register(ChartDataLabels);

const ChartDonutWidget = ({
  data,
  style = { height: 150, width: '100%' },
  clazz,
  dataLabelsConfig = { display: false },
  optionsConfig = {},
  name,
  tooltip,
}) => {
  const { type, datasets } = data; // chart type

  const modifiedData = useMemo(() => {
    if (
      !datasets ||
      datasets.length === 0 ||
      datasets[0].data.every((value) => value === 0)
    ) {
      return {
        ...data,
        datasets: [
          {
            data: [1],
            backgroundColor: ['#80808099'],
            borderWidth: 0,
          },
        ],
        labels: ['No Data'],
      };
    }
    return data;
  }, [data]);

  const config = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0, // Disable animation
      },
      plugins: {
        datalabels: dataLabelsConfig,
        legend: {
          display: optionsConfig.legendDisplay ?? true,
          position: data.legendPosition,
          labels: {
            usePointStyle: true,
            boxWidth: 7,
            boxHeight: 7,
            font: {
              size: 9,
            },
          },
        },
        tooltip: tooltip || true,
      },
      ...optionsConfig,
    }),
    [dataLabelsConfig, optionsConfig, data.legendPosition]
  );

  return (
    <div style={style} className={clazz}>
      <Chart key={name} type={type} options={config} data={modifiedData} />
    </div>
  );
};

export default ChartDonutWidget;
