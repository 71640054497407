import React, { useEffect, useState } from 'react';
import fieldService from '../../../services/field.service';
import AlertWrapper from '../../Alert/AlertWrapper';
import Alert from '../../Alert/Alert';
import { groupBy } from 'lodash';
import InlineOverviewForm from './InlineOverviewForm';

const Overview = ({
  data,
  getProfileInfo,
  isPrincipalOwner,
  labelType,
  moduleMap,
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [isFieldsData, setIsFieldsData] = useState([]);
  const [fieldData, setFieldsData] = useState([]);
  const currentView = 'contact';
  const groupBySection = (fieldsList) => {
    setIsFieldsData(groupBy(fieldsList, 'section'));
  };
  const getData = async () => {
    const { data } = await fieldService.getFields(currentView, {
      usedField: true,
    });
    setFieldsData(data);
    groupBySection(data);
  };

  useEffect(() => {
    if (fieldData?.length === 0) {
      getData();
    }
  }, [data]);

  return (
    <div className="card border-0 shadow-none">
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>

      <InlineOverviewForm
        moduleMap={moduleMap}
        labelType={labelType}
        overviewData={data}
        fieldData={fieldData}
        editMode={editMode}
        setEditMode={setEditMode}
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
        getProfileInfo={getProfileInfo}
        isFieldsData={isFieldsData}
      />
    </div>
  );
};

export default Overview;
