import React, { useEffect, useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const ShortDescription = ({
  content,
  limit,
  align = '',
  btnShow,
  classnames,
}) => {
  const descriptionRef = useRef(null);
  const contentLength = content?.length;
  const renderContent = () => {
    return content?.slice(0, limit) + '...';
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        descriptionRef?.current &&
        !descriptionRef?.current.contains(event.target)
      ) {
        event.stopPropagation();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  return (
    <div
      ref={descriptionRef}
      className={`d-flex align-items-center position-relative ${classnames}`}
    >
      {contentLength >= limit ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className={`tooltip-profile font-weight-bold`}>
              {content}
            </Tooltip>
          }
        >
          <p className="mb-0">
            {contentLength >= limit ? renderContent() : content}
          </p>
        </OverlayTrigger>
      ) : (
        <p className="mb-0">
          {contentLength >= limit ? renderContent() : content}
        </p>
      )}
    </div>
  );
};
