import useReportHeaderFooter from '../../hooks/useReportHeaderFooter';
import { ReportCoverPattern, ReportTitles } from './ReportCover';

const ReportCoverThankYou = ({
  name,
  date,
  type,
  selectedTenant,
  report,
  organization,
  allRightReserved = '©2024, Comerica Bank. All rights reserved.',
}) => {
  const { reportFooterImage } = useReportHeaderFooter(selectedTenant);
  const textColor = 'text-black text-left';
  return (
    <ReportCoverPattern type={type}>
      <img
        src={reportFooterImage}
        className="position-absolute"
        alt="Tenant Logo"
        crossOrigin="anonymous"
        style={{
          width: 150,
          objectFit: 'contain',
          left: '50%',
          top: '17%',
          transform: 'translate(-50%, -17%)',
        }}
      />
      <div
        className={`position-absolute ${textColor}`}
        style={{ left: '50%', top: '35%', transform: 'translate(-50%, -35%)' }}
      >
        <p
          className={`d-flex align-items-center justify-content-center text-center gap-1 ${textColor}`}
        >
          <span className="font-size-sm2">{ReportTitles[type]}</span>
        </p>
        <h1
          className={`font-size-2p5em text-center font-weight-bolder mb-2 ${textColor}`}
          style={{ maxWidth: 350, lineHeight: 1.2 }}
        >
          Thank You
        </h1>
      </div>
      <div
        className="position-absolute text-center"
        style={{ left: '50%', top: '75%', transform: 'translate(-50%, -75%)' }}
      >
        <span className="font-size-sm2">{allRightReserved}</span>
      </div>
    </ReportCoverPattern>
  );
};

export default ReportCoverThankYou;
