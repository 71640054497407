import { useState } from 'react';

import LessonsTable from '../components/manageLessons/LessonsTable';
import LessonAdminView from '../views/Resources/LessonAdminView';
import AlertWrapper from '../components/Alert/AlertWrapper';
import Alert from '../components/Alert/Alert';

const ManageLessons = () => {
  const [create, setCreate] = useState(false);
  const [id, setId] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      {create ? (
        <LessonAdminView
          lessonId={id}
          create={create}
          setLessonId={setId}
          setCreate={setCreate}
          setSuccessMessage={setSuccessMessage}
        />
      ) : (
        <LessonsTable setCreate={setCreate} setId={setId} />
      )}
    </>
  );
};

export default ManageLessons;
