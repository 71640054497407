import MaterialIcon from '../../components/commons/MaterialIcon';
import TooltipComponent from '../../components/lesson/Tooltip';
import { numberWithCommas } from '../../utils/Utils';

const InsightStatItem = ({
  heading,
  stat,
  subtext,
  customClass = 'px-0',
  skipRounding,
  hideAverage,
  headingClass = 'font-size-sm2',
  countClass = 'font-size-3em',
  subHeadingClass = 'font-size-sm2',
  tooltipData = {},
  headingsTitle = {},
  tooltipTitle,
}) => {
  const numberFormatted = subtext !== '%' ? numberWithCommas(stat) : stat;
  return (
    <div className={`${customClass} col`}>
      <div className={`card border-0 rounded mb-2`}>
        <div className="card-body rounded border-primary">
          <h4
            className={`card-title d-flex align-items-center gap-1 text-hover-primary font-weight-medium ${headingClass}`}
          >
            {Object.keys(headingsTitle)?.length > 0
              ? headingsTitle[heading]
              : heading}{' '}
            {(Object?.keys(tooltipData)?.length > 0 && tooltipData[heading]) ||
            tooltipTitle ? (
              <TooltipComponent title={tooltipData[heading] || tooltipTitle}>
                <MaterialIcon icon={'info'} clazz="font-size-sm" />
              </TooltipComponent>
            ) : (
              ''
            )}
          </h4>
          <h1 className={`text-primary mb-0 ${countClass}`}>
            {skipRounding
              ? numberFormatted
              : isNaN(stat)
              ? 0
              : subtext !== '%'
              ? numberFormatted
              : Math.round(stat)}
            {subtext && (
              <span
                className={`font-weight-normal text-black ml-1 ${subHeadingClass}`}
              >
                {subtext}
              </span>
            )}
            {!hideAverage && (
              <span className="font-weight-normal text-black ml-1 font-size-sm2">
                average
              </span>
            )}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default InsightStatItem;
