import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Card } from 'react-bootstrap';
import { CardButton } from '../layouts/CardLayout';
import useOrganizationContacts from './useOrganizationContacts';
import { useTenantContext } from '../../contexts/TenantContext';
import { AlertMessageContext } from '../../contexts/AlertMessageContext';
import MaterialIcon from '../commons/MaterialIcon';
import useDownloadReport from './useDownloadReport';
import { usePagesContext } from '../../contexts/pagesContext';
import contactService from '../../services/contact.service';
import ButtonIcon from '../commons/ButtonIcon';
import usePermission from '../../hooks/usePermission';
import { PermissionsConstants } from '../../utils/permissions.constants';

const ClientShareViewTypes = {
  Contacts: 'Contacts',
  Download: 'Download',
};

const DownloadReportLink = ({ onClick }) => {
  return (
    <a
      href=""
      onClick={(e) => onClick(e)}
      className="d-flex mt-2 text-gray-dark py-2 px-3 rounded bg-hover-gray justify-content-between align-items-center"
    >
      <div className="d-flex align-items-center gap-2">
        <MaterialIcon icon="download" clazz="fs-4" rounded />
        <span className="font-size-sm font-weight-medium">Download</span>
      </div>
      <MaterialIcon icon="chevron_right" clazz="fs-3 text-icon" rounded />
    </a>
  );
};
const ClientViewLink = ({ organization }) => {
  const { pageContext } = usePagesContext();
  const companyContacts = pageContext.CompanyContacts || [];
  const randomIndex = Math.floor(Math.random() * companyContacts.length);
  const randomContact = companyContacts[randomIndex] || {};
  const username = randomContact.id;
  const [loading, setLoading] = useState(false);
  const { tenant } = useTenantContext();
  const { clientPortalUrl } = tenant;
  const { setSuccessMessage } = useContext(AlertMessageContext);

  const getTokenByUser = async () => {
    const { access_token: token } = await contactService.impersonate(username);
    return `${clientPortalUrl}/clientportal/dashboard?token=${token}`;
  };
  const handleClientViewLink = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const clientPortalDashboardUrl = await getTokenByUser();
      navigator.clipboard.writeText(clientPortalDashboardUrl);
      setSuccessMessage('Client view link is copied to clipboard.');
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleClientViewAndOpenNewWindow = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const clientPortalDashboardUrl = await getTokenByUser();
      window.open(
        clientPortalDashboardUrl,
        'ClientPortal',
        'width=500,fullscreen=yes'
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {companyContacts?.length ? (
        <a className="d-flex mt-2 text-gray-dark py-2 px-3 rounded bg-hover-gray justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <MaterialIcon icon="link" clazz="fs-4" rounded />
            <span className="font-size-sm font-weight-medium">
              Client view link
            </span>
          </div>
          <div className="d-flex align-items-center gap-2">
            <ButtonIcon
              color="outline-primary"
              classnames="btn-xs"
              loading={loading}
              label="Copy"
              onclick={(e) => handleClientViewLink(e)}
            />
            <a
              className="icon-hover-bg cursor-pointer"
              onClick={(e) => handleClientViewAndOpenNewWindow(e)}
            >
              <MaterialIcon icon="open_in_new" clazz="fs-5 text-icon" rounded />
            </a>
          </div>
        </a>
      ) : null}
    </>
  );
};
const CopyClientPortalLoginLink = ({ organization }) => {
  const { tenant } = useTenantContext();
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const { clientPortalUrl, id } = tenant;
  const handleCopyLink = () => {
    const params = new URLSearchParams();
    params.set('tenant_id', id);
    params.set('organizationId', organization.id);
    navigator.clipboard.writeText(
      `${clientPortalUrl}/clientportal/login?${params.toString()}`
    );
    setSuccessMessage('Link copied!');
  };
  return (
    <div className="mt-3 px-3 gap-2">
      <CardButton
        type="button"
        className={'btn-sm btn-block font-weight-medium btn-outline-primary'}
        title="Copy Link"
        icon=""
        onClick={handleCopyLink}
      />
    </div>
  );
};

const ClientSharePopup = ({ organization, setShowContactsModal }) => {
  const { isAllowed: isClientPortalEnabled } = usePermission(
    PermissionsConstants.ClientPortal,
    'view',
    true
  );
  const { Contacts, setShow: setShowContacts } = useOrganizationContacts(
    organization,
    setShowContactsModal
  );
  const { DownloadReportForm, setShow: setShowDownload } = useDownloadReport(
    organization,
    setShowContactsModal
  );

  const ClientShareViews = {
    [ClientShareViewTypes.Contacts]: {
      type: ClientShareViewTypes.Contacts,
      heading: 'Share with Client',
      component: <Contacts />,
    },
    [ClientShareViewTypes.Download]: {
      type: ClientShareViewTypes.Download,
      heading: 'Download',
      component: <DownloadReportForm />,
    },
  };

  // if client portal is enabled then normal flow of load company contacts
  // else default view will be download report
  const [selectedView, setSelectedView] = useState(
    ClientShareViews[
      isClientPortalEnabled
        ? ClientShareViewTypes.Contacts
        : ClientShareViewTypes.Download
    ]
  );
  return (
    <Card>
      <Card.Header className="px-3 pb-1 fw-bold d-flex border-0 align-items-center justify-content-start">
        {selectedView.type !== ClientShareViewTypes.Contacts &&
          isClientPortalEnabled && (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setShowContacts(true);
                setSelectedView(
                  ClientShareViews[ClientShareViewTypes.Contacts]
                );
              }}
              className="icon-hover-bg position-relative"
              style={{ left: -4 }}
            >
              <MaterialIcon
                icon="chevron_left"
                rounded
                clazz="text-black font-size-2xl"
              />
            </a>
          )}
        <h4 className="mb-0">{selectedView.heading}</h4>
      </Card.Header>
      <Card.Body className="mb-0 px-0 py-2">
        <>
          {selectedView.type !== ClientShareViewTypes.Contacts ? (
            <>{selectedView.component}</>
          ) : (
            <>
              <div className="border-bottom pb-3">
                {selectedView.component}
                <CopyClientPortalLoginLink organization={organization} />
              </div>
              <DownloadReportLink
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedView(
                    ClientShareViews[ClientShareViewTypes.Download]
                  );
                  setShowDownload(true);
                }}
              />
              <ClientViewLink organization={organization} />
            </>
          )}
        </>
      </Card.Body>
    </Card>
  );
};
const useClientSharePopup = (
  organizationId,
  profileInfo,
  setShowContactsModal
) => {
  const [show, setShow] = useState(false);
  const [organization, setOrganization] = useState({
    ...profileInfo,
    id: organizationId,
  });
  // wait for organization full object gets loaded then update it locally
  useEffect(() => {
    if (profileInfo?.id) {
      setOrganization(profileInfo);
    }
  }, [profileInfo]);

  const ClientShareDropdownCallback = useCallback(() => {
    return (
      <ClientSharePopup
        organization={organization}
        setShowContactsModal={setShowContactsModal}
      />
    );
  }, [show, setShow]);

  return useMemo(
    () => ({
      show,
      setShow,
      ClientSharePopup: show ? ClientShareDropdownCallback : () => {},
    }),
    [setShow, ClientShareDropdownCallback]
  );
};

export default useClientSharePopup;
