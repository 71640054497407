import MaterialIcon from '../../../commons/MaterialIcon';

const ActionButtons = ({ onEdit, onDelete }) => {
  return (
    <div className="d-flex align-items-center">
      {onEdit && (
        <a className="icon-hover-bg cursor-pointer" onClick={onEdit}>
          <MaterialIcon
            icon="edit"
            rounded
            filled
            clazz="text-gray-600"
            size="fs-6"
          />
        </a>
      )}
      {onDelete && (
        <a className="icon-hover-bg cursor-pointer" onClick={onDelete}>
          <MaterialIcon
            icon="delete"
            rounded
            filled
            clazz="text-gray-600"
            size="fs-6"
          />
        </a>
      )}
    </div>
  );
};

export default ActionButtons;
