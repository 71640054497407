import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, TabContent, TabPane } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import routes from '../utils/routes.json';

import { TabsContext } from '../contexts/tabsContext';
import AnimatedTabs from '../components/commons/AnimatedTabs';
import Organizations from './Organizations';
import PageTitle from '../components/commons/PageTitle';
import { PermissionsConstants } from '../utils/permissions.constants';
import { isModuleAllowed, numbersWithComma } from '../utils/Utils';
import { useTenantContext } from '../contexts/TenantContext';
import Contacts from './Contacts';
import useIsTenant from '../hooks/useIsTenant';
import { Form } from 'react-bootstrap';
import MaterialIcon from '../components/commons/MaterialIcon';
import TopicIcon from '../components/commons/TopicIcon';
import organizationService from '../services/organization.service';
import { useProfileContext } from '../contexts/profileContext';
import contactService from '../services/contact.service';
import Skeleton from 'react-loading-skeleton';
import { usePagesContext } from '../contexts/pagesContext';
import checklistService from '../services/checklist.service';
import activityService from '../services/activity.service';

const companiesOwnershipOptions = [
  {
    id: 2,
    key: 'MyOrganization',
    value: 'My',
    name: `My ${useIsTenant().isSynovusBank ? 'Insights' : 'Companies'}`,
  },
  {
    id: 1,
    key: 'AllOrganizations',
    value: 'All',
    name: `All ${useIsTenant().isSynovusBank ? 'Insights' : 'Companies'}`,
  },
];

const contactsOwnershipOptions = [
  { id: 2, key: 'MyContacts', value: 'My', name: 'My Contacts' },
  { id: 1, key: 'AllContacts', value: 'All', name: 'All Contacts' },
];

const CAccountsTabs = {
  Companies: 1,
  Contacts: 2,
};

const Status = {
  pending: 'pending',
  overdue: 'overdue',
};
const statusLabels = {
  inProgress: 'In Progress',
  [Status.pending]: 'Not Started',
  [Status.overdue]: 'Overdue',
  completed: 'Completed',
  task: 'Tasks',
  event: 'Events',
  call: 'Calls',
};

const CAccounts = () => {
  const history = useHistory();
  const location = useLocation();
  const { tenant } = useTenantContext();
  const { profileInfo } = useProfileContext();
  const { pageContext, setPageContext } = usePagesContext();

  const tabsData = [
    {
      title: 'Companies',
      tabId: CAccountsTabs.Companies,
      byModule: PermissionsConstants.CAccounts.Companies,
      iconAdd: true,
      iconToolTip: 'Create Company',
      iconOnClick: () => createNew(tabsData[0]),
    },
    {
      title: 'Contacts',
      tabId: CAccountsTabs.Contacts,
      byModule: PermissionsConstants.CAccounts.Contacts,
      iconAdd: true,
      iconToolTip: 'Create Contact',
      iconOnClick: () => createNew(tabsData[1]),
    },
  ];

  const createNew = (tab) => {
    toggle(tab);

    // Trigger button click after tab switch
    setTimeout(() => {
      if (tab.tabId === CAccountsTabs.Companies) {
        const btn = document.getElementById('add-company-button');
        if (btn) btn.click();
      } else if (tab.tabId === CAccountsTabs.Contacts) {
        const btn = document.getElementById('add-contact-button');
        if (btn) btn.click();
      }
    }, 200); // Delay to ensure tab switch occurs before click
  };

  const { activatedTab, setActivatedTab } = useContext(TabsContext);
  const [activeTab, setActiveTab] = useState(tabsData[0]);

  const [ownershipOptions, setOwnershipOptions] = useState(
    companiesOwnershipOptions
  );
  const [selectedOwnershipCompanies, setSelectedOwnershipCompanies] = useState(
    pageContext?.CAccountsPage?.ownershipCompanies ?? 'My'
  );
  const [selectedOwnershipContacts, setSelectedOwnershipContacts] = useState(
    pageContext?.CAccountsPage?.ownershipContacts ?? 'My'
  );

  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState({
    MyOrganization: 0,
    AllOrganizations: 0,
    MyContacts: 0,
    AllContacts: 0,
    MyChecklists: { total: 0, options: [] },
    AllChecklists: { total: 0, options: [] },
    MyActivities: { total: 0, options: [] },
    AllActivities: { total: 0, options: [] },
  });

  const toggle = (tab) => {
    if (activeTab?.tabId !== tab.tabId) {
      setActiveTab(tab);
      history.replace({ search: '' });
      setActivatedTab({
        CAccountsPage: { tab },
      });

      // Update ownership options and reset selected ownership
      if (tab.tabId === CAccountsTabs.Companies) {
        setOwnershipOptions(companiesOwnershipOptions);
      } else if (tab.tabId === CAccountsTabs.Contacts) {
        setOwnershipOptions(contactsOwnershipOptions);
      }
    }
  };

  useEffect(() => {
    if (tenant?.id) {
      const isCompaniesAllowed = isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.CAccounts.Companies
      );
      const isContactsAllowed = isModuleAllowed(
        tenant?.modules,
        PermissionsConstants.CAccounts.Contacts
      );
      setActiveTab(
        isCompaniesAllowed
          ? tabsData[0]
          : isContactsAllowed
          ? tabsData[1]
          : null
      );

      setOwnershipOptions(
        isCompaniesAllowed
          ? companiesOwnershipOptions
          : isContactsAllowed
          ? contactsOwnershipOptions
          : []
      );
    }
  }, [tenant]);

  useEffect(() => {
    if (location.hash === '#Contacts') {
      setActiveTab(tabsData[1]);
      setOwnershipOptions(contactsOwnershipOptions);
      setSelectedOwnershipContacts('My');
    } else if (location.hash === '#Companies') {
      setActiveTab(tabsData[0]);
      setOwnershipOptions(companiesOwnershipOptions);
      setSelectedOwnershipCompanies('My');
    } else {
      const savedTab = activatedTab?.CAccountsPage?.tab;
      if (savedTab) {
        setActiveTab(savedTab);
        setOwnershipOptions(
          savedTab.tabId === CAccountsTabs.Companies
            ? companiesOwnershipOptions
            : savedTab.tabId === CAccountsTabs.Contacts
            ? contactsOwnershipOptions
            : []
        );
      }
    }
  }, [location]);

  const getCounts = async () => {
    setLoading(true);

    const filter = { filter: {} };
    const ownerFilter = {
      filter: { assigned_user_id: profileInfo?.id },
    };
    const pagination = { page: 1, limit: 1 };

    const countRequests = [
      {
        key: 'AllOrganizations',
        apiCall: () =>
          organizationService.getOrganizations({ ...filter }, pagination),
      },
      {
        key: 'MyOrganization',
        apiCall: () =>
          organizationService.getOrganizations({ ...ownerFilter }, pagination),
      },
      {
        key: 'AllContacts',
        apiCall: () => contactService.getContacts({ ...filter }, pagination),
      },
      {
        key: 'MyContacts',
        apiCall: () =>
          contactService.getContacts({ ...ownerFilter }, pagination),
      },
      {
        key: 'AllChecklists',
        apiCall: () => checklistService.getChecklistAggregateCountByStatus({}),
      },
      {
        key: 'MyChecklists',
        apiCall: () =>
          checklistService.getChecklistAggregateCountByStatus({ self: true }),
      },
    ];

    try {
      const responses = await Promise.allSettled(
        countRequests.map((request) => request.apiCall())
      );

      const updatedCounts = {};

      countRequests.forEach((request, index) => {
        if (responses[index].status === 'fulfilled') {
          const responseValue = responses[index].value?.data;
          if (
            request.key === 'AllChecklists' ||
            request.key === 'MyChecklists'
          ) {
            updatedCounts[request.key] = responseValue.reduce(
              (acc, item) => {
                acc.total += item.count;
                acc.options?.push(item);
                return acc;
              },
              { total: 0, options: [] }
            );
          } else {
            updatedCounts[request.key] = responseValue?.pagination?.count || 0;
          }
        } else {
          updatedCounts[request.key] = request.key.includes('Checklists')
            ? { total: 0, options: [] }
            : 0;
          console.error(
            `Error fetching ${request.key}:`,
            responses[index].reason
          );
        }
      });

      const activityRequests = [
        { key: 'MyActivities', self: true },
        { key: 'AllActivities', self: false },
      ];

      for (const { key, self } of activityRequests) {
        const [typeResponse, statusResponse] = await Promise.allSettled([
          activityService.getActivitiesAggregateCountByType({ self }),
          activityService.getActivitiesAggregateCountByStatus({ self }),
        ]);

        if (
          typeResponse.status === 'fulfilled' &&
          statusResponse.status === 'fulfilled'
        ) {
          updatedCounts[key] = {
            total: typeResponse.value?.data.reduce(
              (sum, item) => sum + item.count,
              0
            ),
            options: [
              ...typeResponse.value?.data.map(({ type, count }) => ({
                status: type,
                count,
              })),
              ...statusResponse.value?.data
                .filter((f) => f.status === Status.overdue) // only show overdue in activities
                .map(({ status, count }) => ({
                  status,
                  count,
                })),
            ],
          };
        } else {
          updatedCounts[key] = { total: 0, options: [] };
          console.error(`Error fetching ${key} data`);
        }
      }
      setCount(updatedCounts);
    } catch (error) {
      console.error('Error fetching counts:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (profileInfo?.id) {
      getCounts();
    }
  }, [profileInfo?.id]);

  const getStats = (type) => {
    let stats = [];
    switch (type) {
      case CAccountsTabs.Companies:
        stats = [
          {
            id: 1,
            label: `All ${
              useIsTenant().isSynovusBank ? 'Insights' : 'Companies'
            }`,
            count: count.AllOrganizations,
            order: 2,
          },
          {
            id: 2,
            label: `My ${
              useIsTenant().isSynovusBank ? 'Insights' : 'Companies'
            }`,
            count: count.MyOrganization,
            order: 1,
          },
        ];
        break;
      case CAccountsTabs.Contacts:
        stats = [
          {
            id: 1,
            label: 'All Contacts',
            count: count.AllContacts,
            order: 2,
          },
          {
            id: 2,
            label: 'My Contacts',
            count: count.MyContacts,
            order: 1,
          },
        ];
        break;
    }
    return stats;
  };

  useEffect(() => {
    if (activeTab.tabId === CAccountsTabs.Contacts) {
      if (location.hash !== '#contacts') {
        history.push(`${routes.caccounts}#contacts`);
      }
    } else if (location.hash === '#contacts') {
      history.push(`${routes.caccounts}`);
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab.tabId === CAccountsTabs.Companies) {
      setPageContext({
        ...pageContext,
        CAccountsPage: {
          ...pageContext.CAccountsPage,
          ownershipCompanies: selectedOwnershipCompanies,
        },
      });
    } else {
      setPageContext({
        ...pageContext,
        CAccountsPage: {
          ...pageContext.CAccountsPage,
          ownershipContacts: selectedOwnershipContacts,
        },
      });
    }
  }, [selectedOwnershipCompanies, selectedOwnershipContacts]);

  return (
    <>
      <PageTitle page={activeTab?.title} />

      <div className="custom-layout accounts-layout">
        <div className="custom-layout-sidebar overflow-x-hidden">
          <h3 className="pt-3 pb-2 px-3">Accounts</h3>

          <div className="d-flex flex-column gap-3 pb-2 px-3 transparent-scroll-track">
            <div className="d-flex flex-column gap-2">
              <h3 className="mb-0 fs-7_1">Ownership</h3>
              {ownershipOptions?.map((item) => (
                <Form.Check
                  key={item.key}
                  id={item.key}
                  inline
                  label={item.name}
                  name={item.key}
                  className={`large-radio fs-7`}
                  type="radio"
                  checked={
                    activeTab.tabId === CAccountsTabs.Companies
                      ? selectedOwnershipCompanies === item.value
                      : selectedOwnershipContacts === item.value
                  }
                  onChange={(e) => {
                    e.preventDefault();
                    if (activeTab.tabId === CAccountsTabs.Companies) {
                      setSelectedOwnershipCompanies(item.value);
                    } else {
                      setSelectedOwnershipContacts(item.value);
                    }
                  }}
                />
              ))}
            </div>

            <Fragment>
              <AccountsCard
                icon="corporate_fare"
                title="Total Companies"
                loading={loading}
                count={
                  activeTab.tabId === CAccountsTabs.Companies
                    ? selectedOwnershipCompanies === 'My'
                      ? count.MyOrganization
                      : count.AllOrganizations
                    : selectedOwnershipContacts === 'MY'
                    ? count.MyOrganization
                    : count.AllOrganizations
                }
              />

              <AccountsCard
                icon="group"
                title="Total Contacts"
                loading={loading}
                count={
                  activeTab.tabId === CAccountsTabs.Companies
                    ? selectedOwnershipCompanies === 'My'
                      ? count.MyContacts
                      : count.AllContacts
                    : selectedOwnershipContacts === 'MY'
                    ? count.MyContacts
                    : count.AllContacts
                }
              />

              <AccountsCard
                icon="event_available"
                title="Activities"
                loading={loading}
                count={
                  selectedOwnershipCompanies === 'My'
                    ? count.MyActivities.total
                    : count.AllActivities.total
                }
                options={
                  selectedOwnershipCompanies === 'My'
                    ? count.MyActivities.options
                    : count.AllActivities.options
                }
              />

              <AccountsCard
                icon="checklist"
                title="Checklists"
                loading={loading}
                count={
                  selectedOwnershipCompanies === 'My'
                    ? count.MyChecklists.total
                    : count.AllChecklists.total
                }
                options={
                  selectedOwnershipCompanies === 'My'
                    ? count.MyChecklists.options
                    : count.AllChecklists.options
                }
              />
            </Fragment>
          </div>
        </div>

        <div className="custom-layout-content overflow-hidden bg-white">
          <div className="mb-2">
            <AnimatedTabs
              tabsData={tabsData}
              activeTab={activeTab?.tabId}
              tabClasses="px-3"
              toggle={toggle}
              permissionCheck={true}
              borderClasses="border-bottom"
            />
          </div>
          <div className="accounts-tabs">
            <TabContent activeTab={activeTab?.tabId}>
              <TabPane tabId={CAccountsTabs.Companies}>
                <Organizations
                  selectedOwnership={selectedOwnershipCompanies}
                  setSelectedOwnership={setSelectedOwnershipCompanies}
                  stats={getStats(CAccountsTabs.Companies)}
                />
              </TabPane>
              <TabPane tabId={CAccountsTabs.Contacts}>
                <Contacts
                  selectedOwnership={selectedOwnershipContacts}
                  setSelectedOwnership={setSelectedOwnershipContacts}
                  stats={getStats(CAccountsTabs.Contacts)}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
};

export default CAccounts;

const AccountsCard = (props) => {
  const { icon, title, count, options, loading } = props;

  return (
    <Card className="p-2_2 border-none">
      <div className="d-flex gap-1 align-items-center mb-1">
        {icon && (
          <TopicIcon
            icon={icon}
            iconBg="bg-primary-soft"
            iconStyle={{ width: 24, height: 24 }}
            iconClasses="fs-6 text-primary"
          />
        )}
        <h3 className="fs-7 mb-0 font-weight-medium">{title}</h3>
      </div>
      <h1 className="mb-0 fs-3 font-weight-bolder">
        {loading ? (
          <Skeleton width={100} height={10} />
        ) : (
          numbersWithComma(count)
        )}
      </h1>

      {options && options?.length ? (
        <div className="mt-2 d-flex flex-column gap-1">
          {options
            .filter((item) => item.count > 0) // add options with count > 0
            .sort(
              (a, b) =>
                a.status === Status.overdue
                  ? 1
                  : b.status === Status.overdue
                  ? -1
                  : 0 // set overdue option at end
            )
            .map((item, index) => (
              <div
                key={index}
                className="width-full d-flex align-items-center justify-content-between"
              >
                {item.status === Status.overdue ? (
                  <div className="d-flex gap-1 align-items-center">
                    <MaterialIcon
                      icon="error"
                      size="fs-6"
                      clazz="text-danger"
                    />
                    <h1 className="mb-0 fs-7 text-danger font-weight-normal">
                      Overdue
                    </h1>
                  </div>
                ) : (
                  <h1 className="mb-0 fs-7 font-weight-normal">
                    {statusLabels[item.status] || item.status}
                  </h1>
                )}
                <h1
                  className={`${
                    item.status === Status.overdue ? 'text-danger' : ''
                  } mb-0 fs-7 font-weight-normal`}
                >
                  {numbersWithComma(item.count)}
                </h1>
              </div>
            ))}
        </div>
      ) : null}
    </Card>
  );
};
