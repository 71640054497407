import React from 'react';

import ChangePassword from '../components/security/ChangePassword';

function Security() {
  return (
    <>
      <ChangePassword />
    </>
  );
}

export default Security;
