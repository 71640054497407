import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PRINCIPAL_OWNER_LABEL } from '../../../utils/constants';
import Avatar from '../../Avatar';
import './IdfAdditionalOwners.css';
import { useContext, useEffect, useState } from 'react';
import TooltipComponent from '../../lesson/Tooltip';
import MaterialIcon from '../../commons/MaterialIcon';
import { useProfileContext } from '../../../contexts/profileContext';
import OrganizationService from '../../../services/organization.service';
import { AlertMessageContext } from '../../../contexts/AlertMessageContext';

const IdfPrincipalOwnerAvatar = ({
  sizeIcon,
  mainOwner,
  defaultSize,
  className,
  headerType,
  serviceId,
  isClickable = true,
}) => {
  const [owner, setOwner] = useState();
  const { profileInfo } = useProfileContext();
  const [, setLoading] = useState(false);
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const handlePrimaryOwner = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setOwner(profileInfo);
      setSuccessMessage('You are added as the primary owner of this company.');
      await OrganizationService.updateOrganization(serviceId, {
        assigned_user_id: profileInfo.id,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setOwner(mainOwner);
  }, [mainOwner]);
  return (
    <div className={`d-flex main-owner ${className}`}>
      {!owner && headerType === 'organization' ? (
        <TooltipComponent title="Click to Become Primary Owner">
          <a
            href=""
            onClick={(e) => handlePrimaryOwner(e)}
            className={`avatar d-flex align-items-center border bg-primary-soft-forced border-green rounded-circle justify-content-center avatar-${defaultSize}`}
          >
            <MaterialIcon
              icon="person_add"
              rounded
              clazz={defaultSize === 'xs' ? 'font-size-lg' : 'fs-5'}
            />
          </a>
        </TooltipComponent>
      ) : (
        <>
          <OverlayTrigger
            key={owner?.id}
            placement="bottom"
            overlay={
              <Tooltip
                id={`tooltip-${owner?.id}`}
                className={`tooltip-profile font-weight-bold`}
              >
                <p>{`${owner?.first_name} ${owner?.last_name}`}</p>
                <p>{PRINCIPAL_OWNER_LABEL}</p>
              </Tooltip>
            }
          >
            <div className="border-0">
              {' '}
              {isClickable ? (
                <span className="d-flex">
                  <Avatar
                    classModifiers="avatar-md"
                    user={owner}
                    defaultSize={defaultSize}
                    sizeIcon={sizeIcon}
                  />
                </span>
              ) : (
                <Link className="d-flex cursor-default">
                  <Avatar
                    classModifiers="avatar-md"
                    user={owner}
                    defaultSize={defaultSize}
                    sizeIcon={sizeIcon}
                  />
                </Link>
              )}
            </div>
          </OverlayTrigger>
        </>
      )}
    </div>
  );
};

export default IdfPrincipalOwnerAvatar;
