import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import TooltipComponent from '../../../components/lesson/Tooltip';

const ChecklistOverviewStates = (results) => {
  const { data } = results[0];
  const getExternalChecklists = isNaN(
    Math.round(
      (data[0]?.['ChecklistItem.countOfExternalItems'] * 100) /
        data[0]?.['ChecklistItem.count']
    )
  )
    ? 0
    : Math.round(
        (data[0]?.['ChecklistItem.countOfExternalItems'] * 100) /
          data[0]?.['ChecklistItem.count']
      );
  const getInternalChecklists = isNaN(
    Math.round(
      (data[0]?.['ChecklistItem.countOfInternalItems'] * 100) /
        data[0]?.['ChecklistItem.count']
    )
  )
    ? 0
    : Math.round(
        (data[0]?.['ChecklistItem.countOfInternalItems'] * 100) /
          data[0]?.['ChecklistItem.count']
      );

  const baseStyle = {
    height: 20,
    borderTopRightRadius: '0.75rem',
    borderBottomRightRadius: '0.75rem',
  };
  const baseExternalStyle = {
    height: 20,
    borderTopLeftRadius: '0.75rem',
    borderBottomLeftRadius: '0.75rem',
  };
  const conditionalStyle =
    getInternalChecklists === 0
      ? {
          borderTopLeftRadius: '0.75rem',
          borderBottomLeftRadius: '0.75rem',
        }
      : {};
  const conditionalExternalStyle =
    getExternalChecklists === 0
      ? {
          borderTopLeftRadius: '0.75rem',
          borderBottomLeftRadius: '0.75rem',
        }
      : {};
  const combinedStyle = { ...baseStyle, ...conditionalStyle };

  const externalStyle = { ...baseExternalStyle, ...conditionalExternalStyle };
  return (
    <div>
      <div className="split-progress-bar">
        <div className="d-flex align-items-center gap-2 w-100">
          <MaterialIcon icon="earthquake" clazz="font-size-2em text-primary" />
          <div className="w-100">
            <h5 className="text-muted fon-weight-light font-size-sm d-flex align-items-center gap-1">
              Checklists Split (All time){' '}
              <TooltipComponent title="% of Checklist Items">
                <MaterialIcon icon={'info'} />
              </TooltipComponent>
            </h5>
            <ProgressBar className={'progress-bar-2 w-100 rounded-lg mb-2'}>
              <ProgressBar
                style={externalStyle}
                now={getInternalChecklists}
                key={1}
                className="bg-gray-800"
              />
              <ProgressBar
                style={combinedStyle}
                className="bg-gray-400"
                now={getExternalChecklists}
                key={2}
              />
            </ProgressBar>
            <div className="d-flex align-items-center justify-content-between w-100 mt-3">
              <div className="font-size-md">
                <div
                  className="bg-gray-800 rounded-circle d-inline-block mr-1"
                  style={{ width: '10px', height: '10px' }}
                ></div>
                Internal: {getInternalChecklists}%
              </div>
              <div className="font-size-md">
                <div
                  className="bg-gray-400 rounded-circle d-inline-block mr-1"
                  style={{ width: '10px', height: '10px' }}
                ></div>
                External: {getExternalChecklists}%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChecklistOverviewStates;
