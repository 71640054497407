import { FormGroup } from 'reactstrap';
import DragDropUploadFile from '../commons/DragDropUploadFile';
import { CHOOSE_IMAGE_FILE } from '../../utils/constants';
import ButtonIcon from '../commons/ButtonIcon';
import React from 'react';
import { ReportTypes } from './reports.constants';
import MaterialIcon from '../commons/MaterialIcon';
import DragDropUploadFiles from '../commons/DragDropUploadFiles';

const ReportDragDrop = ({
  file,
  loader,
  setFile,
  fileUpload = true,
  uploadIcon = 'upload_file',
  onLoadFile,
  onRemoveFile,
  handleGenerate,
  lineBreak,
  reportType,
  isMultiple,
  scrape,
}) => {
  return (
    <div className="p-3">
      <FormGroup className="mb-0">
        {isMultiple ? (
          <DragDropUploadFiles
            files={file}
            setFiles={setFile}
            name="accountStructureFiles"
            onRemoveFile={onRemoveFile}
            uploadOnDrop={true}
            onLoadFile={onLoadFile}
            emptyContainerHeight={210}
            isLoading={loader}
            scrape={scrape}
            fileUpload={fileUpload}
            chooseFileText="Drag files here"
            showUploadIcon={uploadIcon}
          />
        ) : (
          <DragDropUploadFile
            file={file}
            setFile={setFile}
            name="accountStatementPdf"
            onLoadFile={onLoadFile}
            allowedFormat={['.pdf']}
            chooseFileText={CHOOSE_IMAGE_FILE}
            emptyContainerHeight={210}
            uploadOnDrop={true}
            isLoading={loader}
            fileUpload={fileUpload}
            onRemoveFile={onRemoveFile}
            showUploadIcon={uploadIcon}
            customBtn={
              !loader || !file ? (
                <>
                  {lineBreak && (
                    <div className="position-relative">
                      <hr style={{ width: 320 }} />
                      <span
                        className="bg-gray-200 position-absolute text-muted py-1 abs-center fs-7"
                        style={{ top: -15, paddingLeft: 10, paddingRight: 10 }}
                      >
                        OR
                      </span>
                    </div>
                  )}
                  <ButtonIcon
                    icon="edit_note"
                    label="Manually enter your data"
                    onclick={handleGenerate}
                    color="primary"
                    classnames="btn-sm mt-1"
                  />
                </>
              ) : null
            }
          />
        )}
        {reportType === ReportTypes.Treasury ? (
          <div className="d-flex gap-2 rounded p-2_1 bg-soft-yellow border border-yellow">
            <MaterialIcon icon="info" clazz="text-yellow" rounded filled />{' '}
            <p className="mb-0 text-left text-black font-size-sm2">
              <b>
                <p className="mb-0">Having trouble uploading your PDF files?</p>
              </b>
              PDF files can get damaged or corrupted for a variety of reasons.
              The file may have not downloaded properly, a hard-drive may have
              reached its storage capacity or there could have been a problem
              transferring from one device to another. If the issue is from a
              download, try downloading the file again to see if this fixes the
              issue.
            </p>
          </div>
        ) : null}
      </FormGroup>
    </div>
  );
};

export default ReportDragDrop;
