import { useEffect, useState } from 'react';

function useIsClientPortalHome() {
  const [isHome, setIsHome] = useState(false);
  useEffect(() => {
    const checkIsHome = () => {
      const urlParts = window.location.href.split('/');
      const lastPart = urlParts[urlParts.length - 1];
      setIsHome(
        lastPart === 'dashboard' ||
          window.location.href.includes('#') ||
          window.location.href.includes('token')
      );
    };
    checkIsHome();
    window.addEventListener('hashchange', checkIsHome);
    return () => {
      window.removeEventListener('hashchange', checkIsHome);
    };
  }, []);

  return isHome;
}

export default useIsClientPortalHome;
