import { transformTo2D } from '../../../../utils/Utils';
import { CardBody } from 'reactstrap';
import MaterialIcon from '../../../commons/MaterialIcon';

const ContactInfoRow = ({ icon, text }) => {
  return (
    <div className="d-flex align-items-center my-2">
      <div
        className="d-flex align-items-center justify-content-center bg-primary-soft rounded-circle"
        style={{ width: '38px', height: '38px' }}
      >
        <MaterialIcon icon={icon} filled size="fs-4" clazz="text-primary" />
      </div>
      <span className="font-size-sm font-weight-medium px-2">{text}</span>
    </div>
  );
};

const TeamContactsWidget = ({ widgetConfig, whenPrinting }) => {
  const contacts = Object.hasOwn(widgetConfig, 'contacts')
    ? widgetConfig.contacts
    : [];
  const data = transformTo2D(contacts);
  return (
    <div
      className={`d-flex flex-column`}
      style={{
        background: '#ffffff',
      }}
    >
      {data.map((row, rowIndex) => {
        return (
          <div key={rowIndex} className="d-flex gap-2">
            {row.map((contact, contactIndex) => {
              return (
                <div
                  key={contactIndex}
                  className="flex-1 mt-2 rpt-border-primary-soft rpt-rounded"
                >
                  <CardBody
                    className={`d-flex flex-column ${
                      whenPrinting ? 'px-3' : 'px-6'
                    } text-left`}
                  >
                    <span className="font-size-ml font-weight-bold">
                      {contact.name}
                    </span>
                    <span className="font-size-sm font-weight-medium">
                      {contact.title}
                    </span>
                    <ContactInfoRow icon={'mail'} text={contact.email} />
                    <div
                      className="bg-primary-soft"
                      style={{ height: '2px' }}
                    ></div>
                    <ContactInfoRow icon={'call'} text={contact.phone} />
                    <a
                      className={`btn btn-primary text-white font-size-md font-weight-semi-bold py-2 rounded-lg ${
                        whenPrinting ? 'mt-2' : 'mt-4'
                      } text-center`}
                    >
                      Contact me
                    </a>
                  </CardBody>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default TeamContactsWidget;
