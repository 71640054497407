import React from 'react';
import {
  FraudMitigationData,
  LOCData,
} from '../../../reportbuilder/constants/widgetsConstants';
import LineOfCreditWidget from '../../../reportbuilder/widgets/horizontal/LineOfCreditWidget';
import BalanceTrendsWidget from '../widgets/BalanceTrendsWidget';
import { usePagesContext } from '../../../../contexts/pagesContext';
import {
  AccountSchematicReportSections,
  AccountStructureTabMap,
  generateOneYearBalanceRecords,
} from '../account.structure.constants';
import QRCodeWidget from '../../../reportbuilder/widgets/horizontal/QRCodeWidget';

const BalanceTrends = ({ report, hideHeading }) => {
  const { pageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  const whenPrinting = !!report;
  const accountStructure =
    report?.AccountStructureReport || pageContext.AccountStructureReport;
  const { balances } = Object.entries(accountStructure[jsonKey]).length
    ? accountStructure[jsonKey]
    : { balances: generateOneYearBalanceRecords() };
  const lineLimit = Math.max(...balances.map((mb) => parseFloat(mb.value)));
  const loanOverview = {
    lineLimit,
    monthData: balances,
  };
  const showWidget =
    accountStructure[AccountStructureTabMap.Widgets.key][
      AccountSchematicReportSections.BalanceTrends
    ]?.isActive;
  const showFraudWidget =
    accountStructure[AccountStructureTabMap.Widgets.key][
      AccountSchematicReportSections.BalanceTrendsFraud
    ]?.isActive;
  return (
    <>
      <LineOfCreditWidget
        widgetConfig={{
          ...LOCData,
          heading: 'Balance Trends',
          description:
            'Let’s take a look at the monthly average balance for all your accounts over the past 12 months',
          hideHeading,
          hideLineLimit: true,
          data: { loanOverview },
        }}
        whenPrinting={whenPrinting}
        chartConfig={{
          xAxisLabel: 'Average Monthly Balance',
        }}
      />
      {showWidget && (
        <div className="mt-3">
          <BalanceTrendsWidget
            report={{ loanOverview }}
            whenPrinting={whenPrinting}
            accountStructure={accountStructure}
          />
        </div>
      )}
      {showFraudWidget && (
        <div className="mt-3">
          <QRCodeWidget widgetConfig={FraudMitigationData} />
        </div>
      )}
    </>
  );
};

export default BalanceTrends;
