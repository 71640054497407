import { Card, CardBody } from 'reactstrap';
import React, { useState } from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import MaterialIcon from '../../../commons/MaterialIcon';
import Skeleton from 'react-loading-skeleton';
import CutoutWidget from './CutoutWidget';
import Naics55Block from '../../Naics55Block';
import { MultipleSources } from '../../../../utils/Utils';

const DSOWidget = ({ widgetConfig, whenPrinting }) => {
  const insightsData = widgetConfig?.insightsData || {};
  const avgDSO = !widgetConfig?.data?.valueN?.length
    ? 0
    : insightsData?.sp?.days_sales_out;
  const bestInClassDSO = !widgetConfig?.data?.valueN?.length
    ? 0
    : insightsData?.sp?.aggregations?.find(
        (agr) => agr.aggregation_type === 'AVERAGE_BOTTOM_10_PERCENT'
      )?.days_sales_out || 0;
  const [loader] = useState(false);
  return (
    <Card className="report-widget">
      <CardBody className="d-flex align-items-center justify-content-center">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            Days Sales Outstanding
          </h5>
          <div className="px-2">
            <p
              className={`${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } mb-1 text-left`}
            >
              Automating reconciliation and accepting card and virtual payments
              can speed up funds receipt and processing, ultimately decreasing
              days sales outstanding.
            </p>

            <CutoutWidget
              widgetConfig={widgetConfig}
              whenPrinting={whenPrinting}
              cutoutConfig={{
                icon: 'monetization_on',
                text: `Benefits of <br /> Automating`,
                bg: 'report-widget-inner-section-soft bg-white',
                iconConfig: {
                  fg: 'text-primary',
                  bg: 'bg-primary-soft',
                  icon: 'paid',
                  filled: true,
                },
              }}
            >
              <div>
                <div
                  className="d-flex align-items-center justify-content-center mt-2 pt-2 pb-3 px-2"
                  style={{ gap: whenPrinting ? 25 : 50 }}
                >
                  <div>
                    <div className="d-flex align-items-center justify-content-center gap-1">
                      {loader ? (
                        <Skeleton width={50} height={20} />
                      ) : (
                        <>
                          <h1 className="mb-0 rpt-red-box-heading font-weight-bold">
                            {avgDSO}
                          </h1>
                          <h1 className="mb-0 rpt-red-box-heading font-weight-bold">
                            Days
                          </h1>
                        </>
                      )}
                    </div>
                    <p className="fs-10 mb-1 m-auto text-center font-weight-medium d-flex flex-column">
                      <span>Days Sales Outstanding </span>
                      <span>Industry Average</span>
                    </p>
                  </div>
                  <MaterialIcon
                    icon="double_arrow"
                    rounded
                    filled
                    clazz="font-size-2xl rpt-red-box-heading"
                  />
                  <div className="flex-grow-1">
                    <MaterialIcon
                      icon="credit_score"
                      rounded
                      clazz="font-size-3xl txt-rpt-heading"
                    />
                  </div>
                  <MaterialIcon
                    icon="double_arrow"
                    rounded
                    filled
                    clazz="font-size-2xl flex-fill text-green-rpt"
                  />
                  <div>
                    <div className="d-flex align-items-center justify-content-center gap-1">
                      {loader ? (
                        <Skeleton width={50} height={20} />
                      ) : (
                        <>
                          <h1 className="mb-0 rpt-green-box-heading font-weight-bold">
                            {widgetConfig?.data?.valueNaicsSic === '55'
                              ? avgDSO
                              : bestInClassDSO}
                          </h1>
                          <h1 className="mb-0 rpt-green-box-heading font-weight-bold">
                            Days
                          </h1>
                        </>
                      )}
                    </div>
                    <p className="fs-10 mb-1 font-weight-medium text-center m-auto d-flex flex-column">
                      <span>Days Sales Outstanding </span>
                      <span>
                        Industry Best-In-Class{' '}
                        {widgetConfig?.data?.valueNaicsSic === '55' ? '*' : ''}
                      </span>
                    </p>
                  </div>
                </div>
                <Naics55Block naics={widgetConfig?.data?.valueNaicsSic} />
              </div>
            </CutoutWidget>
            {widgetConfig?.source && (
              <div className="mt-2">
                <WidgetSourceBlock text={MultipleSources} />
              </div>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DSOWidget;
