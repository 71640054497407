import NoDataFound from '../../../../components/commons/NoDataFound';

export const NoUserSelected = ({ label = 'user', icon }) => {
  return (
    <>
      <NoDataFound
        iconStyle="font-size-3xl"
        icon={icon || 'manage_search'}
        containerStyle="text-gray-search my-6 py-6"
        title={
          <>
            <div className="text-gray-search">
              {`No ${label} selected, please select a ${label}`}
            </div>
          </>
        }
      />
    </>
  );
};
