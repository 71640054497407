import React, { useState, useEffect } from 'react';
import TooltipComponent from '../lesson/Tooltip';

const TextOverflowTooltip = ({
  text,
  textStyle = 'font-weight-medium text-truncate font-size-sm2 mb-0',
  maxLength = 22,
  capitalizeText,
  tooltip = '',
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    setIsOverflowing(text?.length > maxLength);
  }, [text]);

  const truncatedText =
    text?.length > maxLength ? text?.slice(0, maxLength) + '...' : text;

  return (
    <>
      {text?.length > maxLength ? (
        <TooltipComponent
          title={isOverflowing ? (tooltip !== '' ? tooltip : text) : ''}
          disableHoverListener={!isOverflowing}
          capitalizeText={capitalizeText}
        >
          <span className={textStyle}>{truncatedText}</span>
        </TooltipComponent>
      ) : (
        <span className={textStyle}>{text}</span>
      )}
    </>
  );
};

export default TextOverflowTooltip;
