import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Register the components to Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

// Custom plugin to add percentage text in the center
const centerTextPlugin = {
  id: 'centerText',
  afterDraw: (chart) => {
    const { ctx, width, height, data } = chart;
    const total = data.datasets[0].data.reduce((acc, value) => acc + value, 0);
    const value = data.datasets[0].data[0];
    const percentage = ((value / total) * 100).toFixed(0) + '%';

    ctx.restore();
    // eslint-disable-next-line no-unused-vars
    const fontSize = (height / 114).toFixed(2);
    ctx.font = 'bold 1.5em Inter, sans-serif'; // Use the specified font size and font family
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#404040'; // Set text color
    const textX = Math.round((width - ctx.measureText(percentage).width) / 2);
    const textY = height / 2;
    ctx.fillText(percentage, textX, textY);
    ctx.save();
  },
};

const DigitalDonutChart = ({ item }) => {
  const data = {
    labels: [],
    datasets: [
      {
        data: item.chart.data,
        backgroundColor: item.chart.colors,
        borderColor: item.chart.colors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    plugins: {
      datalabels: {
        display: false,
      },
      scales: {
        xAxes: [{ display: false }],
        yAxes: [{ display: false }],
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      centerText: {
        // Custom options for our plugin
      },
    },
    cutout: '75%', // Adjust the cutout percentage to make it more donut-like
  };

  return (
    <div className="flex-fill flex-column d-flex align-items-center">
      <div style={{ maxWidth: 120 }}>
        <Doughnut data={data} options={options} plugins={[centerTextPlugin]} />
      </div>
      <h2 className={`mb-0 fs-7 mt-2 text-rpt-heading font-weight-semi-bold`}>
        {item.text}
      </h2>
    </div>
  );
};

export default DigitalDonutChart;
