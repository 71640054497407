import React from 'react';

const Naics55Block = ({ naics, style }) => {
  return (
    <>
      {naics === '55' ? (
        <p className={`font-italic fs-11 ${style}`}>
          * Best-In-Class approximates Industry Average, reflecting the small
          population of the sector which has no outliers.
        </p>
      ) : null}
    </>
  );
};

export default Naics55Block;
