import { Skeleton } from '@mui/material';
import { Col, Row } from 'react-bootstrap';

const LessonSkeleton = () => {
  return (
    <Row className="mx-0">
      <Col md={8} className="mt-4">
        <Skeleton variant="rounded" height={25} width={200} />
        <div className="mt-3">
          <Skeleton variant="rounded" height={15} width={150} />
        </div>
        <div className="mt-3">
          <Skeleton variant="rounded" height={600} width={'100%'} />
        </div>
      </Col>
      <Col md={4} className="mt-5">
        <Skeleton variant="rounded" height={85} width={'100%'} />
        <div className="mt-4">
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </div>
        <div className="mt-3">
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </div>
        <div className="mt-3">
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </div>
      </Col>
    </Row>
  );
};

export default LessonSkeleton;
