import React, { createContext, useContext, useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import { TenantContext } from './TenantContext';
import useHash from '../hooks/useHash';

const KeycloakContext = createContext();

export const useKeycloak = () => useContext(KeycloakContext);

const isKeycloakEnabled = process.env.REACT_APP_IDP_ENABLED === 'true';

// we have to add these because at that time no style files are loaded yet
const KeyCloakInitStyles = {
  display: 'flex',
  alignItems: 'center',
  margin: 'auto',
  maxWidth: 400,
  textAlign: 'center',
};

export const KeycloakProvider = ({ children }) => {
  const { tenant, count } = useContext(TenantContext);
  const [keycloak, setKeycloak] = useState(null);
  const { hash } = useHash();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const initKeycloak = async () => {
      const keycloakConfig = {
        realm: tenant.realm || 'identifee',
        url: process.env.REACT_APP_IDP_URL,
        redirectUri: `${window.location.origin}/callback`,
        clientId: 'identifee-app',
      };
      const keycloakInstance = new Keycloak(keycloakConfig);
      try {
        /**
         * @param {Object} options
         * @param {string} options.onLoad - 'login-required' | 'check-sso'
         * 'login-required' - will redirect to Keycloak login page if not authenticated
         * 'check-sso' - will not redirect to keycloak login page if not authenticated. This will only check if user is authenticated or not
         *   and we can use the existing login page of our application
         */
        await keycloakInstance.init({
          onLoad: 'check-sso',
          redirectUri: `${window.location.origin}/callback`,
        });
        setKeycloak(keycloakInstance);
      } catch (error) {
        console.error('Keycloak initialization failed.', error);
      }
    };
    // if env has keycloak key enabled then do this else ignore
    // count indicates that tenant is loaded, so avoiding hook init again and again when
    // keycloack callback is initiated
    // to avoid trigger KC in case of normal login
    (urlParams.get('session_state') || hash !== '') &&
      count === 1 &&
      tenant?.realm &&
      initKeycloak();
  }, [count]);

  return (
    <KeycloakContext.Provider value={keycloak}>
      {keycloak || !isKeycloakEnabled ? (
        children
      ) : (
        <div style={KeyCloakInitStyles}>
          <h4>Please wait...</h4>
        </div>
      )}
    </KeycloakContext.Provider>
  );
};
