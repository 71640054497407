import React from 'react';
import './style.css';
import './responsive.css';
import ClientDashboardLayout from '../../layouts/ClientDashboardLayout';
import HomeContent from './home/HomeContent';

const Home = ({ wrapLayout, selectedMenu, setSelectedMenu }) => {
  return wrapLayout ? (
    <ClientDashboardLayout
      selectedMenu={selectedMenu}
      setSelectedMenu={setSelectedMenu}
    >
      <HomeContent
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />
    </ClientDashboardLayout>
  ) : (
    <HomeContent
      selectedMenu={selectedMenu}
      setSelectedMenu={setSelectedMenu}
    />
  );
};

export default Home;
