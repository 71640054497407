import React from 'react';
import { Row, Col, CardBody, Card } from 'reactstrap';
import Heading from './heading';
import NoDataFound from './commons/NoDataFound';
import { decimalToNumber } from '../utils/Utils';
import { ProgressBarDefault } from './commons/Progress';
import LayoutCard from './lesson/layoutCard';

export const LessonLayout = (props) => {
  const {
    children,
    lesson,
    setGetLessonId,
    type,
    viewType,
    relatedLessons,
    course,
  } = props;

  const completedLessons = relatedLessons?.filter(
    (lesson) => lesson.progress === 100
  );
  const progress = (100 / relatedLessons?.length) * completedLessons?.length;

  const filteredLessons =
    viewType === 'lesson'
      ? relatedLessons?.filter((item) => lesson?.id !== item?.id)
      : relatedLessons;

  return (
    <div>
      <Row className="mb-5">
        <Col md={8}>{children}</Col>
        <Col md={4} className={course ? 'mt-4' : 'mt-3'}>
          {course ? (
            <Card className="rounded-lg bg-custom-gradient">
              <CardBody>
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="fs-6">Course Progress</h4>
                  <p className="fs-7 text-muted">
                    {`${completedLessons?.length} / ${relatedLessons?.length}`}{' '}
                    Completed
                  </p>
                </div>
                <ProgressBarDefault
                  now={decimalToNumber(progress)}
                  label={'course'}
                  variant={
                    completedLessons?.length === relatedLessons?.length
                      ? 'success'
                      : null
                  }
                />
              </CardBody>
            </Card>
          ) : (
            <Heading
              title={' Recommended Lessons'}
              pageHeaderDivider="pb-0 mb-0"
            />
          )}

          {filteredLessons?.length === 0 ? (
            <NoDataFound title="No lessons found" />
          ) : (
            filteredLessons?.map((item, index) => {
              return (
                <LayoutCard
                  learnViewTypes={type}
                  key={item}
                  classnames={
                    course &&
                    item?.progress !== 100 &&
                    relatedLessons[index] &&
                    lesson?.id !== item?.id
                      ? 'disabled-lessons'
                      : ''
                  }
                  setGetLessonId={setGetLessonId}
                  lesson={lesson}
                  course={course}
                  item={item}
                />
              );
            })
          )}
        </Col>
      </Row>
    </div>
  );
};
