import React, { useEffect, useState } from 'react';
import PageTitle from '../components/commons/PageTitle';
import PortalNavigationMenu from '../components/ClientPortal/LeftSidebar';
import MaterialIcon from '../components/commons/MaterialIcon';
import Button from 'react-bootstrap/Button';
import { Toaster } from 'react-hot-toast';
import BrandLogoIcon from '../components/sidebar/BrandLogoIcon';
import { useTenantContext } from '../contexts/TenantContext';
import { getClientPortalOrganization, getClientPortalToken } from './constants';
import { usePagesContext } from '../contexts/pagesContext';
import ModalActivityLogs from '../components/ClientPortal/modals/ModalActivityLogs';
import useIsClientPortalHome from '../components/ClientPortal/useIsClientPortalHome';
import useSessionExpiryModal from '../components/modal/SessionExpiryModal';
import useCheckTokenValidityClientPortal from '../hooks/useCheckTokenValidityClientPortal';

const ClientDashboardLayout = ({ children, selectedMenu, setSelectedMenu }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [organization, setOrganization] = useState({});
  const [contactId, setContactId] = useState('');
  const [organizationId, setOrganizationId] = useState('');
  const [owner, setOwner] = useState({});
  const { tenant } = useTenantContext();
  const { pageContext } = usePagesContext();
  const [modalActivityLogs, setModalActivityLogs] = useState(false);
  const isHome = useIsClientPortalHome();
  const { SessionExpiryModal, setShowModal } = useSessionExpiryModal(true);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const showModalActivityLog = () => {
    setModalActivityLogs(true);
  };

  useEffect(() => {
    const clientPortalOrganization = getClientPortalOrganization();
    const clientPortalToken = getClientPortalToken();
    setOrganization(clientPortalOrganization);
    setOwner(clientPortalToken?.shared_by);
    setContactId(clientPortalToken?.contact_id);
    setOrganizationId(clientPortalToken?.resource_access?.organization[0]?.id);
  }, [pageContext]);

  useCheckTokenValidityClientPortal(setShowModal);

  return (
    <div
      className={`d-flex AppBackground overflow-x-hidden w-100 client-dashboard vh-100 ${
        showSidebar ? 'showsidebar' : ''
      }`}
    >
      <PageTitle page={organization?.name} pageModule="" />
      <SessionExpiryModal />
      <Button variant="light" className="mobile-menu justify-content-between">
        <div className="logo">
          <BrandLogoIcon tenant={tenant} width="100%" height={53} />
        </div>
        <ModalActivityLogs
          show={modalActivityLogs}
          onModalOpen={showModalActivityLog}
          onHide={() => setModalActivityLogs(false)}
        />
        <span onClick={toggleSidebar}>
          {showSidebar ? (
            <MaterialIcon
              icon="close"
              clazz="text-blue font-size-2xl"
              rounded
            />
          ) : (
            <MaterialIcon icon="menu" clazz="font-size-2xl" rounded />
          )}
        </span>
      </Button>
      <PortalNavigationMenu
        contactId={contactId}
        organizationId={organizationId}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        owner={owner}
      />
      <div
        className={`client-main-content w-100 vh-100 ${
          isHome ? 'at-home' : ''
        }`}
      >
        <div
          className={`bg-white p-3 border-bottom header-area ${
            isHome ? 'at-home' : ''
          }`}
        >
          <div className="d-flex gap-2 py-1 align-items-center w-100">
            <MaterialIcon icon="corporate_fare" rounded clazz="font-size-em" />
            <h4 className="mb-0"> {organization?.name}</h4>
          </div>
        </div>
        <div className="content-body position-relative vh-100">{children}</div>
        <Toaster position="bottom-right" reverseOrder={true} />
      </div>
    </div>
  );
};

export default ClientDashboardLayout;
