import React from 'react';

// generic component to show material/google icons wherever we want to use
const MaterialIcon = ({
  icon,
  clazz,
  filled,
  symbols,
  rounded = true,
  twoTone,
  size = 'fs-5',
  ...rest
}) => {
  return (
    <>
      {rounded ? (
        <i
          {...rest}
          className={`material-symbols-rounded ${size} ${
            filled === true ? 'filled' : ''
          } ${clazz}`}
        >
          {icon}
        </i>
      ) : (
        <i
          {...rest}
          className={`${
            filled
              ? `material-symbols-outlined ${size}`
              : symbols
              ? `material-symbols-outlined ${size}`
              : twoTone
              ? 'material-icons-two-tone'
              : `material-symbols-rounded ${size}`
          } ${clazz}`}
        >
          {icon}
        </i>
      )}
    </>
  );
};

export default MaterialIcon;
