import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import Peoples from '../views/Deals/contacts/Peoples';

const TabTitle = ({ icon, title }) => (
  <div className="d-flex align-items-center tab-title">
    <span className="material-symbols-rounded fs-5 m-1 ">{icon}</span>
    <span>{title}</span>
  </div>
);

const Contacts = ({ selectedOwnership, setSelectedOwnership, stats }) => {
  const tabsData = {
    title: <TabTitle title="People" />,
    component: (
      <Peoples
        selectedOwnership={selectedOwnership}
        setSelectedOwnership={setSelectedOwnership}
        stats={stats}
      />
    ),
    tabId: 2,
  };

  return (
    <>
      <TabContent>
        <TabPane className="position-relative">{tabsData.component}</TabPane>
      </TabContent>
    </>
  );
};

export default Contacts;
