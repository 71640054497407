import React, { useCallback, useMemo, useState } from 'react';
import {
  checkIfNaicsOrReportDateEmpty,
  formatCurrencyField,
  NAICS_STORAGE_KEY,
  overflowing,
} from '../../../utils/Utils';
import SimpleModalCreation from '../../../components/modal/SimpleModalCreation';
import {
  PdfFileNames,
  updateWidgetBySection,
} from '../../../components/reports/reports.helper.functions';
import {
  ActionTypes,
  FraudReportSections,
  ReportFormErrorFields,
  ReportTypes,
  TreasuryReportSections,
} from '../../../components/reports/reports.constants';
import _ from 'lodash';
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  ListGroup,
  Row,
} from 'reactstrap';
import SicNaicsAutoComplete from '../../../components/prospecting/v2/common/SicNaicsAutoComplete';
import ReactDatepicker from '../../../components/inputs/ReactDatpicker';
import useSicNaicsChangeDetect from '../../useSicNaicsChangeDetect';
import { Accordion, FormCheck, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import ReportService from '../../../services/report.service';
import OrganizationService from '../../../services/organization.service';
import useDeleteFraudReportModal from './useDeleteFraudReportModal';
import ReportAccordionToggle from '../../../components/reports/ReportAccordionToggle';
import NoDataFound from '../../../components/commons/NoDataFound';
import IconHeadingBlock from '../../../components/reportbuilder/blocks/IconHeadingBlock';
import WidgetWithActionButtons from '../../../components/reportbuilder/widgets/WidgetWithActionButtons';
import {
  DisclaimerData,
  FraudWhatYouCanDoHeading,
  WidgetTypes,
} from '../../../components/reportbuilder/constants/widgetsConstants';
import ReportSectionIncludedLabel from '../../../components/reports/ReportSectionIncludedLabel';
import useWidgetsLibrary from '../../../components/reportbuilder/widgets/useWidgetsLibrary';
import { SwitchInputWithEditableControls } from '../../../components/layouts/CardLayout';
import Alert from '../../../components/Alert/Alert';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import ValidationErrorText from '../../../components/commons/ValidationErrorText';

const WidgetsBySection = ({
  icon,
  widgets,
  section,
  onAddWidget,
  isMultiple,
  selectedTenant,
  onDeleteWidget,
  setSelectedWidget,
  setShowWidgetsLibrary,
}) => {
  const OpportunityHeading = {
    [FraudReportSections.FraudWhyItMatters]: 'Fraud - Why It Matters',
    [FraudReportSections.WhatCanYouDo]: FraudWhatYouCanDoHeading,
  };
  const newWidgets = isMultiple || widgets[section];

  const heading =
    section === 'Disclaimer' && widgets[section]
      ? widgets[section][0]?.widgetConfig?.data?.disclaimerHeading
      : OpportunityHeading[section] || _.startCase(section);

  return (
    <>
      <div className="text-left">
        <br />
        <IconHeadingBlock
          heading={heading}
          icon={icon}
          showIcon={false}
          containerStyle="gap-1 justify-content-between px-3 mb-0"
          reportType={ReportTypes.Fraud}
        />
      </div>
      <ListGroup className="list-group-no-gutters mt-0 list-group-flush">
        {newWidgets?.map((widget, index) => (
          <WidgetWithActionButtons
            key={index}
            widget={widget}
            section={section}
            onAddWidget={onAddWidget}
            onDeleteWidget={onDeleteWidget}
            setSelectedWidget={setSelectedWidget}
            setShowWidgetsLibrary={setShowWidgetsLibrary}
            selectedTenant={selectedTenant}
            actionButtons={{}}
          />
        ))}
      </ListGroup>
    </>
  );
};
const GenerateFraudReportModal = ({
  show,
  setShow,
  organization,
  modalWidgets,
  selectedReport,
  modalReportPages,
  handleGenerateReport,
  getOrganization,
}) => {
  const [errorFields, setErrorFields] = useState(ReportFormErrorFields);
  const [generating, setGenerating] = useState(false);
  const clonedReport = _.cloneDeep(selectedReport);
  const [reportForm, setReportForm] = useState(clonedReport);
  const { setShowModal, setCompany, NaicsModal } = useSicNaicsChangeDetect(
    selectedReport,
    organization,
    getOrganization
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [currentAccordionKey, setCurrentAccordionKey] = useState(
    FraudReportSections.FraudWhyItMatters
  );
  const [widgets, setWidgets] = useState(modalWidgets);
  const [reportPages, setReportPages] = useState(modalReportPages);

  const {
    DeleteFraudReportModal,
    setReport,
    setShowModal: setShowDeleteModal,
  } = useDeleteFraudReportModal(handleGenerateReport, setShow);
  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const handleAccordionClick = (eventKey) => {
    setCurrentAccordionKey(eventKey);
  };

  const updateWidgets = (newReportData) => {
    const newWidgets = updateWidgetBySection(
      widgets,
      FraudReportSections.FraudWhyItMatters,
      WidgetTypes.FBAR,
      newReportData
    );
    setWidgets(newWidgets);
  };

  const createOrUpdateReport = async () => {
    try {
      let reportObject = null;
      if (
        checkIfNaicsOrReportDateEmpty(reportForm, errorFields, setErrorFields)
      ) {
        return;
      }
      setGenerating(true);
      // update case
      if (selectedReport?.reportId) {
        reportObject = await ReportService.updateReport(
          selectedReport?.reportId,
          {
            name: selectedReport.companyName,
            date: selectedReport.reportDate,
            type: ReportTypes.Fraud,
            manualInput: {
              ...reportForm,
              widgets,
              reportPages,
            },
          }
        );
      } else {
        reportObject = await OrganizationService.createManualReport(
          organization.id,
          {
            name: selectedReport.companyName,
            date: selectedReport.reportDate,
            type: ReportTypes.Fraud,
            manualInput: {
              ...reportForm,
              widgets,
              reportPages,
            },
          }
        );
      }
      setShow(false);
      handleGenerateReport(
        selectedReport,
        selectedReport?.reportId ? ActionTypes.UPDATE : ActionTypes.ADD,
        reportObject,
        widgets,
        reportPages
      );
    } catch (e) {
      console.log(e);
    } finally {
      setGenerating(false);
    }
  };

  const handleChange = (e, key) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      [key]: value,
    };
    updateWidgets(newReport);
    setReportForm(newReport);
  };

  const handleToggleChange = (e) => {
    const { checked, name } = e.target;
    const newReport = {
      ...reportForm,
      [name]: checked,
    };
    updateWidgets(newReport);
    setReportForm(newReport);
  };

  const handleToggleFraudPreventionProductsChange = (e, entry) => {
    const { checked, name } = e.target;
    const newReport = {
      ...reportForm,
      [name]: checked,
      fraudPreventionProducts: {
        ...reportForm.fraudPreventionProducts,
        [entry[0]]: {
          key: entry[1]?.key,
          value: checked ? 1 : 0,
        },
      },
    };
    updateWidgets(newReport);
    setReportForm(newReport);
  };

  const handleToggleFraudPreventionProductsTextChange = (e, entry) => {
    const keyText = e.target.value;
    const newReport = {
      ...reportForm,
      fraudPreventionProducts: {
        ...reportForm.fraudPreventionProducts,
        [entry[0]]: {
          key: keyText,
          value: entry[1]?.value,
        },
      },
    };
    updateWidgets(newReport);
    setReportForm(newReport);
  };

  const updateDisclaimerWidgets = (newReportData) => {
    const newWidgets = updateWidgetBySection(
      widgets,
      FraudReportSections.Disclaimer,
      WidgetTypes.DISC,
      newReportData,
      DisclaimerData
    );
    setWidgets(newWidgets);
  };

  const handleChangeInputDisclaimer = (e) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      disclaimer: value,
    };
    setReportForm(newReport);
    updateDisclaimerWidgets(newReport);
  };

  const handleChangeInputDisclaimerHeading = (e) => {
    const { value } = e.target;
    const newReport = {
      ...reportForm,
      disclaimerHeading: value,
    };
    setReportForm(newReport);
    updateDisclaimerWidgets(newReport);
  };

  const detectNaicsChangeAndRefreshOrganizationProfile = () => {
    if (reportForm?.valueNaicsSic !== organization?.naics_code) {
      getOrganization && getOrganization();
    }
  };

  const handleReportPageToggle = (section) => {
    const updatedReportPages = {
      ...reportPages,
      [section]: {
        ...reportPages[section],
        enabled: !Object.hasOwn(reportPages, section)
          ? true
          : !reportPages[section].enabled,
      },
    };

    // we need to make sure at-least 1 page is active, so if user toggles all off and trying to toggle last off make sure to avoid it.
    const remainingEnabledSections = Object.entries(updatedReportPages).filter(
      ([key, value]) => value.enabled && key !== section
    ).length;

    if (!remainingEnabledSections) {
      setErrorMessage('At least one section must be enabled.');
      return;
    }
    setReportPages(updatedReportPages);
  };

  const onAddWidget = (e, newWidget, oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: crypto.randomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: crypto.randomUUID() }
          : { ...wg }
      );
    }
    setWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
    setShowWidgetsLibrary(false);
  };

  const onDeleteWidget = (oldWidget) => {
    const sectionWidgets = modalWidgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    setWidgets({ ...modalWidgets, [oldWidget.section]: updatedWidgets });
  };

  return (
    <>
      <AlertWrapper className="alert-position">
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <NaicsModal />
      <DeleteFraudReportModal />
      <WidgetsLibraryModal />
      <SimpleModalCreation
        modalTitle={`${PdfFileNames[ReportTypes.Fraud]} Report`}
        open={show}
        saveButton="Save Report"
        isLoading={generating}
        deleteButton={
          reportForm?.reportId || reportForm?.key
            ? {
                label: 'Delete Report',
                show: true,
                loading: false,
                onClick: () => {
                  setReport(reportForm);
                  setShowDeleteModal(true);
                },
              }
            : null
        }
        handleSubmit={() => {
          overflowing();
          createOrUpdateReport();
          detectNaicsChangeAndRefreshOrganizationProfile();
        }}
        bodyClassName="p-0 overflow-x-hidden"
        size="xxl"
        onHandleCloseModal={() => {
          overflowing();
          setShow(false);
        }}
        onClick={() => document.dispatchEvent(new MouseEvent('click'))}
      >
        <Row>
          <Col md={5}>
            <div className="pl-3 py-3">
              <FormGroup>
                <Label for="title">Company Name</Label>
                <input
                  name="name"
                  type="text"
                  value={reportForm.companyName}
                  placeholder="Enter Company Name"
                  onChange={(e) => {
                    setReportForm({
                      ...reportForm,
                      companyName: e.target.value,
                    });
                    setErrorFields({
                      ...errorFields,
                      name: {
                        ...errorFields.name,
                        isShow: !e.target.value,
                      },
                    });
                  }}
                  className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                    errorFields.name.isShow ? 'border-danger' : ''
                  }`}
                />
                {errorFields.name.isShow && (
                  <ValidationErrorText text={errorFields.name.message} />
                )}
              </FormGroup>
              <FormGroup>
                <Label for="title">NAICS</Label>
                <SicNaicsAutoComplete
                  data={reportForm}
                  setData={setReportForm}
                  placeholder="Enter a NAICS code"
                  customKey="valueN"
                  showFieldError={errorFields.naics?.isShow}
                  setErrorMessage={setErrorMessage}
                  callKey={NAICS_STORAGE_KEY}
                  callType="getNaicsCodes"
                  onSelect={(item, naicsSicOnly, naicsTitle) => {
                    setReportForm({
                      ...reportForm,
                      valueN: item ? [item] : [],
                      valueNaicsSic: naicsSicOnly,
                      industry: naicsTitle,
                    });
                    setErrorFields({
                      ...errorFields,
                      naics: {
                        ...errorFields.naics,
                        isShow: !naicsSicOnly,
                      },
                    });
                    if (naicsSicOnly) {
                      if (naicsSicOnly !== organization.naics_code) {
                        setShowModal(true);
                        setCompany({
                          ...organization,
                          naics_code: naicsSicOnly,
                          industry: naicsTitle,
                        });
                      }
                    }
                  }}
                />
                {errorFields.naics?.isShow && (
                  <ValidationErrorText text={errorFields.naics.message} />
                )}
              </FormGroup>
              <FormGroup className="date-wrapper">
                <Label for="title">Report Date</Label>
                <ReactDatepicker
                  id={'rptDate'}
                  name={'reportDate'}
                  todayButton="Today"
                  value={reportForm.reportDate}
                  autoComplete="off"
                  className={`form-control mx-0 mb-0 border-left-4 border-left-danger ${
                    errorFields.reportDate?.isShow ? 'border-danger' : ''
                  }`}
                  placeholder="Select Report Date"
                  format="MMMM yyyy"
                  onChange={(date) => {
                    setReportForm({
                      ...reportForm,
                      reportDate: date,
                    });
                    setErrorFields({
                      ...errorFields,
                      reportDate: {
                        ...errorFields.reportDate,
                        isShow: !date,
                      },
                    });
                  }}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
                {errorFields.reportDate?.isShow && (
                  <ValidationErrorText text={errorFields.reportDate.message} />
                )}
              </FormGroup>
              <FormGroup>
                <Label for="title">Adjust Values by Page</Label>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={FraudReportSections.FraudWhyItMatters}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {FraudReportSections.FraudWhyItMatters}
                      <ReportSectionIncludedLabel
                        section={FraudReportSections.FraudWhyItMatters}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={FraudReportSections.FraudWhyItMatters}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="title">Balance</Label>
                          <InputGroup className="align-items-center">
                            <InputGroup.Prepend>
                              <InputGroup.Text style={{ width: 40 }}>
                                $
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <CurrencyInput
                              name="balance"
                              placeholder="0"
                              value={
                                formatCurrencyField(reportForm.balance) || ''
                              }
                              className="form-control"
                              onValueChange={(value, name, values) => {
                                handleChange(
                                  { target: { value: values.float, name } },
                                  name
                                );
                              }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <div
                            className={`d-flex pt-1 mb-3 align-items-center`}
                          >
                            <FormCheck
                              type="switch"
                              key="multipleAccounts"
                              custom={true}
                              id={`multipleAccounts`}
                              name={`multipleAccounts`}
                              checked={reportForm.multipleAccounts}
                              onChange={handleToggleChange}
                              label=""
                              className="fs-7"
                            />
                            <Label className="mb-0">Multiple Accounts</Label>
                          </div>
                        </FormGroup>
                        <FormGroup className="mb-0">
                          <Label>Fraud Prevention Products</Label>
                        </FormGroup>
                        {Object.entries(
                          reportForm?.fraudPreventionProducts || {}
                        ).map((entry) => (
                          <SwitchInputWithEditableControls
                            id={entry[0]}
                            key={entry[0]}
                            entry={entry}
                            section={TreasuryReportSections.Fraud}
                            checked={entry[1]?.value > 0}
                            label={entry[1]?.key}
                            onChange={(e) =>
                              handleToggleFraudPreventionProductsChange(
                                e,
                                entry
                              )
                            }
                            controls={{
                              maxLength: 50,
                              onChange: (e) => {
                                handleToggleFraudPreventionProductsTextChange(
                                  e,
                                  entry
                                );
                              },
                            }}
                          />
                        ))}
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={FraudReportSections.WhatCanYouDo}
                      callback={handleAccordionClick}
                      dontExpand={true}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {FraudWhatYouCanDoHeading}
                      <ReportSectionIncludedLabel
                        section={FraudReportSections.WhatCanYouDo}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={
                        FraudReportSections.PrioritizeElectronicPayments
                      }
                      callback={handleAccordionClick}
                      dontExpand={true}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {FraudReportSections.PrioritizeElectronicPayments}
                      <ReportSectionIncludedLabel
                        section={
                          FraudReportSections.PrioritizeElectronicPayments
                        }
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                  </Card>
                </Accordion>
                <Accordion activeKey={currentAccordionKey}>
                  <Card>
                    <ReportAccordionToggle
                      eventKey={FraudReportSections.Disclaimer}
                      callback={handleAccordionClick}
                      setCurrentAccordionKey={setCurrentAccordionKey}
                    >
                      {reportForm.disclaimerHeading}
                      <ReportSectionIncludedLabel
                        section={FraudReportSections.Disclaimer}
                        reportPages={reportPages}
                        handleReportPageToggle={handleReportPageToggle}
                      />
                    </ReportAccordionToggle>
                    <Accordion.Collapse
                      eventKey={FraudReportSections.Disclaimer}
                    >
                      <CardBody>
                        <FormGroup>
                          <Label for="title">Enter disclaimer heading</Label>
                          <input
                            name="heading"
                            value={reportForm.disclaimerHeading}
                            onChange={handleChangeInputDisclaimerHeading}
                            className="form-control"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="title">Enter disclaimer text</Label>
                          <textarea
                            name="disclaimer"
                            rows={6}
                            value={reportForm.disclaimer}
                            onChange={handleChangeInputDisclaimer}
                            className="form-control"
                          />
                        </FormGroup>
                      </CardBody>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </FormGroup>
            </div>
          </Col>
          <Col
            md={7}
            className={`bg-white treasury-modal position-relative overflow-x-hidden pb-3 pl-0 ${
              currentAccordionKey === null
                ? 'd-flex align-items-center justify-content-center'
                : ''
            }`}
          >
            {currentAccordionKey === null && (
              <NoDataFound
                title="No page selected"
                description="Please expand a page menu from left to edit values"
                icon="analytics"
                containerStyle="text-gray-900"
              />
            )}
            <div className="py-2 px-3">
              <div>
                {currentAccordionKey ===
                  FraudReportSections.FraudWhyItMatters && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={FraudReportSections.FraudWhyItMatters}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey === FraudReportSections.WhatCanYouDo && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={FraudReportSections.WhatCanYouDo}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey ===
                  FraudReportSections.PrioritizeElectronicPayments && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={FraudReportSections.PrioritizeElectronicPayments}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
                {currentAccordionKey === FraudReportSections.Disclaimer && (
                  <WidgetsBySection
                    icon="price_check"
                    widgets={widgets}
                    section={FraudReportSections.Disclaimer}
                    onAddWidget={onAddWidget}
                    reportPages={reportPages}
                    onDeleteWidget={onDeleteWidget}
                    setSelectedWidget={setSelectedWidget}
                    setShowWidgetsLibrary={setShowWidgetsLibrary}
                    handleReportPageToggle={handleReportPageToggle}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </SimpleModalCreation>
    </>
  );
};

const useGenerateFraudReportModal = (
  report,
  organization,
  modalReportPages,
  modalWidgets,
  handleGenerateReport,
  getOrganization
) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [widgets, setWidgets] = useState({});
  const [reportPages, setReportPages] = useState({});

  const GenerateFraudReportModalCallback = useCallback(() => {
    return (
      <GenerateFraudReportModal
        show={showModal}
        setShow={setShowModal}
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
        organization={organization}
        modalReportPages={reportPages}
        modalWidgets={widgets}
        handleGenerateReport={handleGenerateReport}
        getOrganization={getOrganization}
      />
    );
  }, [showModal, setShowModal, selectedReport, setSelectedReport]);

  return useMemo(
    () => ({
      setShowModal,
      setSelectedReport,
      setModalWidgets: setWidgets,
      setModalReportPages: setReportPages,
      GenerateFraudReportModal: GenerateFraudReportModalCallback,
    }),
    [setShowModal, GenerateFraudReportModalCallback]
  );
};

export default useGenerateFraudReportModal;
