import { useState, useEffect, Fragment } from 'react';
import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import DropdownLabels from '../../inputs/DropdownLabels/DropdownLabels';
import stringConstants from '../../../utils/stringConstants.json';
import labelServices from '../../../services/labels.service';

const IdfSelectLabel = ({
  value = null,
  type,
  onChange,
  validationConfig,
  fieldState,
  refresh,
}) => {
  const constantsOrg = stringConstants.deals.organizations;

  const [label, setLabel] = useState([]);
  const [checkGetDeals, setCheckGetDeals] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    (async () => {
      const labels = await getLabels().catch((err) => console.log(err));

      setLabel(labels || []);
    })();
  }, [checkGetDeals]);

  const getLabels = async () => {
    return await labelServices.getLabels(type).catch((err) => console.log(err));
  };

  const fieldInFields = (item) => {
    onChange({
      item,
      target: {
        name: 'label_id',
        value: item.id,
      },
    });
  };

  return (
    <Fragment>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <DropdownLabels
        value={label.find((item) => item.id === value)}
        options={label}
        getLabels={() => setCheckGetDeals(!checkGetDeals)}
        placeholder={constantsOrg.placeholderDropdownLabels}
        onHandleSelect={(item) => fieldInFields(item)}
        refresh={refresh}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        validationConfig={validationConfig}
        fieldState={fieldState}
        btnAddLabel={constantsOrg.buttonAddLabels}
        type={type}
      />
    </Fragment>
  );
};

export default IdfSelectLabel;
