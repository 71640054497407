import React from 'react';
import {
  AccountSchematicReportSections,
  AccountStructureTabMap,
} from '../account.structure.constants';
import AccountsWidget from '../widgets/AccountsWidget';
import { usePagesContext } from '../../../../contexts/pagesContext';
import AccountCard from '../AccountCard';

const Accounts = ({ report, chunked }) => {
  const { pageContext } = usePagesContext();
  const whenPrinting = !!report;
  const jsonKey = AccountStructureTabMap.ReportDetails.key;
  let accounts = [];
  let showWidget = true;
  try {
    const accountStructure =
      report?.AccountStructureReport ||
      pageContext?.AccountStructureReport ||
      {};
    accounts = chunked || accountStructure[jsonKey]?.accounts || [];
    showWidget =
      accountStructure[AccountStructureTabMap.Widgets.key][
        AccountSchematicReportSections.AccountStructure
      ]?.isActive;
  } catch (e) {
    console.log(e);
  }
  return (
    <>
      <AccountCard accounts={accounts} whenPrinting={whenPrinting} />
      {showWidget && (
        <div className="mt-3">
          <AccountsWidget whenPrinting={whenPrinting} />
        </div>
      )}
    </>
  );
};

export default Accounts;
