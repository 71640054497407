import { Dropdown, Form, FormControl } from 'react-bootstrap';
import ButtonIcon from './ButtonIcon';
import React, { useRef } from 'react';
import MaterialCheckbox from './MaterialCheckbox';
import useOutsideClickDropDown from '../../hooks/useOutsideClickDropDown';
import NoDataFound from './NoDataFound';
import NoDataFoundTitle from '../fields/NoDataFoundTitle';

const SingleSelectionDropdown = ({
  data = [],
  show,
  label = 'options',
  setShow,
  selectedData,
  customClass,
  customKey = ['key', 'id'],
  handleOptionSelected,
  customStyle,
  setSelectedData,
  search,
  searchQuery,
  setSearchQuery,
  selectedItemDetailsShow = false,
  setItemDetailsAppear,
}) => {
  const renderDropdownTitle = () => {
    if (Object.keys(selectedData)?.length > 1) {
      return selectedData[customKey[0]];
    } else {
      return `Select ${label}`;
    }
  };
  const menuRef = useRef(null);
  useOutsideClickDropDown(menuRef, show, setShow);

  const filteredData = () => data?.filter((item) => item);
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  return (
    <Dropdown
      show={show}
      onToggle={setShow}
      className={customClass}
      style={customStyle}
    >
      <Dropdown.Toggle
        variant="white"
        as="div"
        className="w-100 btn btn-white dropdown-toggle cursor-pointer custom-toggle-caret"
        id="dropdown-basic"
      >
        {renderDropdownTitle()}
      </Dropdown.Toggle>

      <Dropdown.Menu ref={menuRef} className="w-100 py-0">
        <div className="d-flex h-100 flex-column">
          <Form className="p-0 flex-grow-1 menu-labels">
            {search && (
              <div className="p-3">
                <FormControl
                  value={searchQuery}
                  placeholder={`Search ${label}`}
                  type="search"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            )}
            {filteredData()?.length > 0 ? (
              filteredData().map((rpt) => (
                <div
                  key={rpt.key}
                  onClick={() => {
                    if (selectedData[customKey[1]] === rpt[customKey[1]]) {
                      setSelectedData({});
                    } else {
                      setSelectedData(rpt);
                    }
                  }}
                  className="py-2 d-flex cursor-pointer bg-hover-gray align-items-center justify-content-between px-3 text-left"
                >
                  <span className="fs-7 font-weight-medium pr-2">
                    {rpt[customKey[0]]}
                  </span>
                  <MaterialCheckbox
                    checked={selectedData[customKey[1]] === rpt[customKey[1]]}
                  />
                </div>
              ))
            ) : (
              <NoDataFound
                icon="account_balance_wallet"
                iconRounded={true}
                iconStyle="font-size-2em"
                containerStyle="text-gray-search mt-2 pt-2 mb-1"
                title={
                  <NoDataFoundTitle
                    clazz="fs-7 mb-0"
                    str={`No ${label} found`}
                  />
                }
              />
            )}
          </Form>
          <div className="p-3 border-top">
            <ButtonIcon
              label="Done"
              color="primary"
              classnames="btn-block btn-sm"
              onclick={() => {
                handleOptionSelected(selectedData);
                if (selectedItemDetailsShow) {
                  setItemDetailsAppear(selectedData);
                }
                setShow(false);
              }}
            />
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SingleSelectionDropdown;
