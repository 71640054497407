import { useCallback, useMemo, useState } from 'react';
import { overflowing, setIdfToken } from '../../utils/Utils';
import SimpleModalCreation from './SimpleModalCreation';
import AuthService from '../../services/auth.service';
import { useKeycloak } from '../../contexts/KeycloakProvider';
import { useAppContext } from '../../contexts/appContext';
import { useProfileContext } from '../../contexts/profileContext';
import { useHistory } from 'react-router-dom';
import { useTenantContext } from '../../contexts/TenantContext';
import { SIGN_OUT } from '../../utils/constants';
import {
  getClientPortalToken,
  getIdfPublicToken,
} from '../../layouts/constants';
const SessionExpiryModal = ({ show, setShow, isClientPortal }) => {
  const { setCount } = useTenantContext();
  const [loading, setLoading] = useState(false);
  const keycloak = useKeycloak();
  const { userHasAuthenticated } = useAppContext();
  const { setProfileInfo } = useProfileContext();
  const history = useHistory();
  const { tenant } = useTenantContext();

  const handleCancel = async () => {
    setShow(false);
    document.removeEventListener('visibilitychange', () => {});
    if (isClientPortal) {
      AuthService.clientLogout();
      history.push('/clientportal/login');
    } else {
      setCount(2);
      try {
        await keycloak?.logout();
      } catch (e) {
        console.error('e', e);
      }
      AuthService.logout();
      userHasAuthenticated(false);
      setProfileInfo({});
      history.push('/login');
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (isClientPortal) {
        const clientPortalToken = getIdfPublicToken();
        const { access_token, expires } =
          await AuthService.refreshTokenClientPortal(
            clientPortalToken.access_token,
            tenant?.id
          );
        const newToken = {
          access_token,
        };
        sessionStorage.setItem('idftoken-public', JSON.stringify(newToken));
        const tokenPayload = { ...getClientPortalToken() };
        // update expiry of current payload and save again
        tokenPayload.exp = expires;
        sessionStorage.setItem('tokenPayload', JSON.stringify(tokenPayload));
      } else {
        const { access_token, refresh_token, expires } =
          await AuthService.refreshToken(null, tenant?.id);
        try {
          await keycloak.updateToken(300);
        } catch (e) {
          console.error('e', e);
        }
        setIdfToken(
          JSON.stringify({
            access_token,
            refresh_token,
            expires,
          })
        );
      }
      window.location.reload();
      setShow(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SimpleModalCreation
      modalTitle="Session Expiring"
      open={show}
      bankTeam={false}
      isLoading={loading}
      saveButton="Stay Logged In"
      cancelButtonText={SIGN_OUT}
      size="xss"
      footerStyle="border-top-0"
      handleSubmit={handleSubmit}
      onHandleCloseModal={() => {
        overflowing();
        handleCancel();
      }}
    >
      <p>
        Your session will expire soon.
        <br />
        Do you want to stay logged in?
      </p>
    </SimpleModalCreation>
  );
};

const useSessionExpiryModal = (isClientPortal) => {
  const [showModal, setShowModal] = useState(false);

  const SessionExpiryModalCallback = useCallback(() => {
    return (
      <SessionExpiryModal
        show={showModal}
        setShow={setShowModal}
        isClientPortal={isClientPortal}
      />
    );
  }, [showModal, setShowModal]);

  return useMemo(
    () => ({
      setShowModal,
      SessionExpiryModal: SessionExpiryModalCallback,
    }),
    [setShowModal, SessionExpiryModalCallback]
  );
};

export default useSessionExpiryModal;
