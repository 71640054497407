import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './style.css';
import './responsive.css';
import Avatar from '../Avatar';
import MaterialIcon from '../commons/MaterialIcon';
import BrandLogoIcon from '../sidebar/BrandLogoIcon';
import { useTenantContext } from '../../contexts/TenantContext';
import authService from '../../services/auth.service';
import { Dropdown } from 'react-bootstrap';
import useIsClientPortalHome from './useIsClientPortalHome';
import useContactModal from './useContactModal';
import { getClientPortalToken } from '../../layouts/constants';
import PortalModuleNavigation from './PortalModuleNavigation';
import contactService from '../../services/contact.service';
import { SIGN_OUT } from '../../utils/constants';

const MenuItems = {
  Home: 'Home',
  Files: 'Files',
  Videos: 'Videos',
  Contact: 'Contact',
};
const MENU_ITEMS = [
  {
    name: MenuItems.Home,
    route: '/clientportal/dashboard',
    key: 'dashboard',
    icon: 'home',
  },
  {
    name: MenuItems.Files,
    route: '/clientportal/dashboard/files',
    key: 'files',
    icon: 'description',
  },
];
const MenuItem = ({ menu, active, owner }) => {
  const isHome = useIsClientPortalHome();
  const isMenuActive =
    active === menu.key || (isHome && menu.name === MenuItems.Home);
  return (
    <Link
      className={`d-flex flex-fill flex-column portal-menu-item text-gray-dark align-items-center`}
      aria-current="page"
      to={menu.route}
    >
      <div
        className={`p-2 d-flex align-items-center portal-menu-item-icon justify-content-center rounded ${
          isMenuActive ? 'primary-selected-item' : ''
        }`}
      >
        <MaterialIcon
          icon={menu.icon}
          rounded
          clazz={isMenuActive ? 'filled' : ''}
          size="fs-4"
        />
      </div>
      <span
        className={`fs-8 ${
          isMenuActive
            ? 'text-primary font-weight-semi-bold'
            : 'font-weight-medium'
        }`}
      >
        {menu.name}
      </span>
    </Link>
  );
};

const ContactMenuItem = ({ owner }) => {
  const clientPortalToken = getClientPortalToken();
  const { ContactYourRepresentative, setShowModal } = useContactModal(
    clientPortalToken?.shared_by
  );
  const menu = {
    name: MenuItems.Contact,
    key: 'conversations',
    route: null,
    icon: '3p',
  };
  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };
  return (
    <>
      <ContactYourRepresentative />
      <li key={menu.name} className={`py-2 portal-menu-item w-100 px-0`}>
        <a
          className={`d-flex flex-fill cursor-pointer flex-column text-gray-dark align-items-center`}
          aria-current="page"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e);
          }}
        >
          <div
            className={`p-2 d-flex align-items-center portal-menu-item-icon justify-content-center rounded`}
          >
            <MaterialIcon icon={menu.icon} rounded size="fs-4" />
          </div>
          <span className={`fs-8 font-weight-medium`}>{menu.name}</span>
        </a>
      </li>
    </>
  );
};

const ClientPortalMenu = ({ active, owner }) => {
  return (
    <ul className="nav flex-column align-items-center justify-content-center">
      {MENU_ITEMS.map((menu) => (
        <li key={menu.name} className={`portal-menu-item py-2 w-100 px-0`}>
          <MenuItem menu={menu} active={active} owner={owner} />
        </li>
      ))}
      <ContactMenuItem owner={owner} />
    </ul>
  );
};

const LeftSidebar = ({
  owner,
  selectedMenu,
  setSelectedMenu,
  organization,
  organizationId,
}) => {
  const active = document.URL.split('/').at(-1);
  const history = useHistory();
  const { tenant } = useTenantContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [contact, setContact] = useState({});
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const isHome = useIsClientPortalHome();
  const clientPortalToken = getClientPortalToken();

  const getContactInfo = async () => {
    const data = await contactService.getContactById(
      clientPortalToken.contact_id
    );
    setContact(data);
  };
  const logout = () => {
    authService.logout();
    history.push('/clientportal/login');
  };

  useEffect(() => {
    getContactInfo();
  }, []);
  return (
    <Fragment>
      <div className="left_sidebar_container d-flex h-100 flex-column">
        <div className="logo">
          <BrandLogoIcon tenant={tenant} width="100%" />
        </div>
        <div className="navigation my-5 flex-grow-1">
          <ClientPortalMenu active={active} />
        </div>
        <div className="text-center">
          <Dropdown
            show={dropdownOpen}
            onToggle={toggle}
            className="avatar-dropdown-container"
          >
            <Dropdown.Toggle
              as="span"
              className="avatar-custom-toggle"
              style={{
                border: 'none',
                padding: '0',
                background: 'transparent',
                cursor: 'pointer',
              }}
            >
              <Avatar
                user={contact}
                defaultSize="md"
                sizeIcon="border-color-primary"
                classModifiers="rounded bg-primary text-white"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="py-1 menu">
              <Dropdown.Item className="bg-hover-white cursor-default">
                <div className="media align-items-center">
                  <Avatar
                    user={contact}
                    classModifiers="mr-2"
                    sizeIcon="avatar-dark text-white"
                  />
                  <div className="media-body">
                    {contact?.first_name && (
                      <span className="card-title h5">
                        {contact?.id
                          ? `${contact.first_name} ${contact.last_name}`
                          : 'Loading...'}
                      </span>
                    )}

                    <span className="card-text text-muted font-size-xs">
                      {contact?.id ? `${contact?.email_work}` : 'Loading...'}
                    </span>
                  </div>
                </div>
              </Dropdown.Item>
              <div className="dropdown-divider"></div>
              <Dropdown.Item
                onClick={logout}
                className="bg-hover-danger left-0"
              >
                <div className="d-flex gap-1 text-danger align-items-center">
                  <MaterialIcon rounded icon="logout" />
                  <span className="font-weight-medium">{SIGN_OUT}</span>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {isHome && (
          <PortalModuleNavigation
            owner={owner}
            organization={organization}
            organizationId={organizationId}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
          />
        )}
      </div>
    </Fragment>
  );
};

export default LeftSidebar;
