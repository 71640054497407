import Table from '../../GenericTable';
import React, { useEffect, useState } from 'react';
import dealService from '../../../services/deal.service';
import { paginationDefault } from '../../../utils/constants';
import Skeleton from 'react-loading-skeleton';
import { formatNumber, setDateFormat } from '../../../utils/Utils';
import { usePipelineBoardContext } from '../../../contexts/PipelineBoardContext';
import moment from 'moment';
import userService from '../../../services/user.service';
import Avatar from '../../Avatar';

const columns = [
  {
    key: 'stage',
    component: 'Stage',
    classes: 'px-3',
  },
  {
    key: 'amount',
    component: 'Amount',
  },
  {
    key: 'status',
    component: 'Stage Duration (In Days)',
  },
  {
    key: 'modified',
    component: 'Modified By',
  },
];

const useFetchStageHistory = (deal, defaultPagination) => {
  const [loading, setLoading] = useState(true);
  const [stageHistory, setStageHistory] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const fetchStageHistory = async () => {
    try {
      setLoading(true);
      const { data, pagination: stagePagination } =
        await dealService.getDealStageHistory(deal?.id, pagination);
      data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      setStageHistory(data);
      setPagination((prevPagination) => {
        if (
          JSON.stringify(prevPagination) !== JSON.stringify(stagePagination)
        ) {
          return stagePagination;
        }
        return prevPagination;
      });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    deal?.id && fetchStageHistory();
  }, [deal, pagination]);
  return {
    loading,
    stageHistory,
    setStageHistory,
    pagination,
    setPagination,
    fetchStageHistory,
  };
};

const StageHistory = ({ deal }) => {
  const { loading, stageHistory, pagination, setPagination } =
    useFetchStageHistory(deal, paginationDefault);
  const [rows, setRows] = useState([]);
  const [accountCache, setAccountCache] = useState(new Map()); // to store user account details

  const fetchAccounts = async (uniqueAccountIds) => {
    const fetchedAccounts = new Map();
    try {
      for (const accountId of uniqueAccountIds) {
        if (!accountCache.has(accountId)) {
          const data = await userService.getUserAccount(accountId);
          const name =
            data?.name || `${data?.first_name} ${data?.last_name || ''}`;
          fetchedAccounts.set(accountId, { ...data, name });
        } else {
          fetchedAccounts.set(accountId, accountCache.get(accountId)); // Use cached user data
        }
      }
      setAccountCache((prev) => new Map([...prev, ...fetchedAccounts]));
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  const { stages } = usePipelineBoardContext();
  const getStageName = (stageId) => {
    return stages?.find((st) => st.id === stageId)?.name;
  };
  const getDays = (transitionedFromDt) => {
    const currentMoment = moment();
    const days = currentMoment.diff(transitionedFromDt, 'days');
    return isNaN(days) ? 'Current Stage' : days;
  };

  useEffect(() => {
    // first pick unique account ids in set so that we dont call again and again same account details
    if (stageHistory?.length) {
      const uniqueAccountIds = [
        ...new Set(stageHistory.map((item) => item.createdByAccountId)),
      ];
      // once ids are set get them in bulk.
      fetchAccounts(uniqueAccountIds);
    }
  }, [stageHistory]);

  useEffect(() => {
    if (stageHistory?.length && accountCache.size) {
      const newRows = stageHistory.map((item) => {
        const {
          toPipelineStageId,
          amount = deal?.amount || 0,
          updatedAt,
          createdByAccountId,
          transitionedFromDt,
        } = item;

        return {
          ...item,
          dataRow: [
            {
              key: 'stage',
              bgColor: 'px-3',
              component: <span>{getStageName(toPipelineStageId) || '--'}</span>,
            },
            {
              key: 'amount',
              component: (
                <span className="d-inline-block text-truncate mw-fix-200">
                  {amount ? formatNumber(amount, 2, 0, false) : '--'}
                </span>
              ),
            },
            {
              key: 'status',
              component: <span>{getDays(transitionedFromDt)}</span>,
            },
            {
              key: 'modified',
              component: (
                <div className="d-flex align-items-center gap-1">
                  <Avatar
                    user={accountCache.get(createdByAccountId)?.user}
                    defaultSize="xs"
                  />
                  {setDateFormat(updatedAt)}
                </div>
              ),
            },
          ],
        };
      });
      setRows(newRows);
    }
  }, [stageHistory, accountCache, deal]);
  return (
    <div className="mt-3">
      {loading ? (
        <div className="px-3">
          <Skeleton count={3} height={10} className={'mb-2'} />
        </div>
      ) : (
        <Table
          columns={columns}
          actionPadding="h-100"
          data={rows}
          paginationInfo={pagination}
          onPageChange={(page) => {
            setPagination((prev) => ({ ...prev, page }));
          }}
          emptyDataText="No stage history found."
          emptyDataIcon="history"
          title="Stage History"
          headClass="bg-gray-table-head border-top"
          tableLoading={false}
          usePagination={true}
          dataInDB={!!stageHistory?.length}
          onClick={() => {}}
        />
      )}
    </div>
  );
};

export default StageHistory;
