import React from 'react';
import { Col, Row } from 'reactstrap';
import { CreateChangeLog } from './CreateChangelog';
import { ChangelogList } from './ChangelogList';

const Changelogs = () => {
  return (
    <>
      <Row>
        <Col md={5}>
          <ChangelogList />
        </Col>
        <Col md={7}>
          <CreateChangeLog />
        </Col>
      </Row>
    </>
  );
};

export default Changelogs;
