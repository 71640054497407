import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';
import orgService from '../services/organization.service';
import Alert from '../components/Alert/Alert';
import AlertWrapper from '../components/Alert/AlertWrapper';
const NaicsChangeModal = ({ show, setShow, company, getCompany }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const handleOk = async () => {
    try {
      setLoading(true);
      await orgService.updateOrganization(company.id, {
        industry: company?.industry,
        naics_code: company.naics_code,
      });
      setSuccessMessage('NAICS updated successfully.');
      getCompany && getCompany();
      setShow(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      <Modal
        isOpen={show}
        fade={false}
        zIndex={1202}
        className={`delete-role-modal`}
      >
        <ModalHeader>
          <h4>Update NAICS Code</h4>
        </ModalHeader>
        <ModalBody className="border-top">
          This will update <b>{company?.name}</b> default NAICS code.
        </ModalBody>
        <ModalFooter className="flex-nowrap">
          <button
            type="button"
            className="btn btn-sm w-50 btn-outline-danger"
            onClick={() => setShow(false)}
          >
            No, Cancel
          </button>
          <button
            className="btn btn-sm w-50 btn-primary"
            data-dismiss="modal"
            onClick={handleOk}
          >
            {loading ? (
              <Spinner className="spinner-grow-xs" />
            ) : (
              <span>Yes, Change</span>
            )}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const useSicNaicsChangeDetect = (report, organization, getOrganization) => {
  const [showModal, setShowModal] = useState(false);
  const [company, setCompany] = useState({});

  useEffect(() => {
    if (organization) {
      setCompany({
        ...organization,
        naics_code: '' + report?.valueNaicsSic,
        industry: report?.industry,
      });
    }
  }, [report?.valueN, report?.valueNaicsSic, organization]);

  const NaicsModalCallback = useCallback(() => {
    return (
      <NaicsChangeModal
        show={showModal}
        setShow={setShowModal}
        company={company}
      />
    );
  }, [showModal, setShowModal, company, setCompany]);

  return useMemo(
    () => ({
      setShowModal,
      setCompany,
      NaicsModal: NaicsModalCallback,
    }),
    [setShowModal, NaicsModalCallback]
  );
};

export default useSicNaicsChangeDetect;
