import AllChecklist from '../views/ChecklistViews/AllChecklist';

const Checklists = () => {
  return (
    <>
      <AllChecklist />
    </>
  );
};

export default Checklists;
