import ButtonFilterDropdown from './ButtonFilterDropdown';
import React, { useContext, useEffect, useState } from 'react';
import categoryService from '../../services/category.service';
import {
  generatePath,
  isModuleAllowed,
  LearnViewTypes,
} from '../../utils/Utils';
import MyFavorites from '../../views/Resources/MyLessons';
import SelfAssessment from '../../views/Resources/selfAssessment/SelfAssessment';
import MaterialIcon from './MaterialIcon';
import { TenantContext } from '../../contexts/TenantContext';
import routes from '../../utils/routes.json';
import { useHistory } from 'react-router-dom';
import OrganizationTopics from '../../views/Learn/OrganizationTopics';
import KnowledgeAssessment from '../../views/Resources/knowledgeAssessment/KnowledgeAssessment';
import { PermissionsConstants } from '../../utils/permissions.constants';
import AIAdvisor from '../../views/Resources/aiAdvisor/AIAdvisor';

const mapCategoryPath = (item) => {
  const path = item.title.toLocaleLowerCase().trim().replace(/ /g, '-');
  return {
    ...item,
    path: `${routes.learnMain}?id=${item.id}&path=${path}&title=${
      item.title
    }&type=${item.isPublic ? 'explore' : 'custom'}`,
  };
};

const CategoryList = ({ list, type, selected, onClick, viewType, path }) => {
  return (
    <div className="overflow-y-auto" style={{ maxHeight: 400, minWidth: 250 }}>
      <ul className="list-group">
        {list.map((item) => (
          <li key={item.id}>
            <a
              onClick={(e) => onClick(e, item, type)}
              className={`py-2 px-3 d-flex align-items-center gap-1 text-black bg-primary-soft-hover ${
                item?.title === path || viewType === item?.key
                  ? 'fw-bold text-primary bg-primary-soft'
                  : 'font-weight-medium'
              } d-block w-100 border-bottom`}
            >
              <MaterialIcon icon={item.icon || 'category'} />
              <p
                className="mb-0 text-truncate flex-fill"
                style={{ maxWidth: 150 }}
              >
                {item.title}
              </p>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const LearnFilter = ({ defaultFilter, viewType = '', path = '' }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedTopic] = useState({});
  const [, setLoader] = useState(false);
  const { tenant } = useContext(TenantContext);
  const history = useHistory();
  const filterList = [
    {
      name: 'Overview',
      key: LearnViewTypes.Overview,
      component: null,
    },
    {
      name: 'My Learning',
      key: LearnViewTypes.MyFavorites,
      component: (
        <MyFavorites
          selectedFilter={{
            key: LearnViewTypes.MyFavorites,
            name: 'My Learning',
          }}
        />
      ),
    },
    {
      name: 'Categories',
      key: LearnViewTypes.Topics,
      component: null,
      submenu: null, // will list all public categories
    },
    {
      name: 'Self-Assessment',
      key: LearnViewTypes.SelfAssessment,
      component: (
        <div className="pt-3">
          <SelfAssessment />
        </div>
      ),
    },
    {
      name: 'Knowledge Assessment',
      key: LearnViewTypes.KnowledgeAssessment,
      moduleName: PermissionsConstants.Learn.KnowledgeAssessment,
      component: (
        <div className="pt-3">
          <KnowledgeAssessment />
        </div>
      ),
    },
    {
      name: 'Ask AI Advisor',
      key: LearnViewTypes.AIAdvisor,
      moduleName: PermissionsConstants.Learn.AIAdvisor,
      component: (
        <div className="pt-3">
          <AIAdvisor />
        </div>
      ),
    },
  ];
  const [updateFilterList, setUpdateFilterList] = useState(filterList);
  const [selectedFilter, setSelectedFilter] = useState(
    defaultFilter || filterList[0]
  );
  const handleTopicCustomMenuClick = (e, item, type) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenFilter(false);
    if (item?.type === 'custom') {
      history.push(item?.url);
    } else {
      const selectedCategory = {
        ...updateFilterList.find((f) => f.key === type),
      };
      const titlePath = item.title
        .toLocaleLowerCase()
        .trim()
        .replace(/ /g, '-');
      history.push(generatePath(item, titlePath));
      setSelectedFilter(selectedCategory);
    }
  };
  const getCategories = async () => {
    try {
      setLoader(true);
      const requests = [
        categoryService.GetCategories(null, { limit: 75, isPublic: true }),
        categoryService.GetCategories(null, { limit: 75, isPublic: false }),
      ];

      const responses = await Promise.all(requests);
      const exploreList = responses[0].data?.map((m) => ({
        ...m,
        isPublic: true,
      }));
      const updatedExploreList = exploreList?.filter(
        (item) => item.title !== 'My Organization'
      );

      const updatedSubMenu = [...updatedExploreList];
      updatedSubMenu.unshift({
        title: 'My Organization',
        name: 'my-organization',
        type: 'custom',
        id: '1',
        key: LearnViewTypes.Custom,
        component: <OrganizationTopics />,
        url: '/learn?viewType=custom&ref=my-organization',
      });
      let newFilters = [...updateFilterList].map((s) => ({
        ...s,
        submenu:
          s.key === LearnViewTypes.Topics ? (
            <CategoryList
              list={updatedSubMenu?.map(mapCategoryPath)}
              onClick={handleTopicCustomMenuClick}
              type={LearnViewTypes.Topics}
              path={path}
              viewType={viewType}
              selected={selectedTopic}
            />
          ) : null,
      }));

      // permission check
      newFilters = newFilters.map((le) => ({
        ...le,
        showHide:
          le.key === LearnViewTypes.SelfAssessment
            ? isModuleAllowed(tenant.modules, 'learns_self_assessment')
              ? ''
              : 'd-none'
            : '',
      }));
      setUpdateFilterList(newFilters);
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    if (viewType !== 'custom') {
      const obj = filterList.find((f) => f.key === viewType);
      setSelectedFilter(obj);
    } else if (viewType !== 'explore') {
      setSelectedFilter(
        filterList.find((f) => f.key === LearnViewTypes.Topics)
      );
    } else {
      setSelectedFilter({
        name: 'Overview',
        key: LearnViewTypes.Overview,
        component: null,
      });
    }
  }, [viewType]);
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <>
      <ButtonFilterDropdown
        buttonText="Timeline"
        options={updateFilterList}
        filterOptionSelected={selectedFilter}
        ignoreChildHover="ignore-child"
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        handleFilterSelect={(e, item) => {
          setOpenFilter(false);
          history.replace({
            search: '',
          });
          setSelectedFilter(item);
          history.push(`${routes.learnMain}?viewType=${item.key}`);
        }}
      />
    </>
  );
};

export default LearnFilter;
