import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const ReportSkeletonLoader = ({ rows, height = 5 }) => {
  return (
    <div className="p-3">
      <Skeleton count={rows} height={height} className="mb-2 w-100" />
    </div>
  );
};
