import React, { useEffect, useState } from 'react';
import {
  DSOFTIData,
  DSOTPData,
} from '../../../reportbuilder/constants/widgetsConstants';
import DPODSOpportunityToImproveWidget from '../../../reportbuilder/widgets/horizontal/DPODSOpportunityToImproveWidget';
import FactorsThatImpactDSODPOWidget from '../../../reportbuilder/widgets/horizontal/FactorsThatImpactDSODPOWidget';
import { usePagesContext } from '../../../../contexts/pagesContext';
import { workingCapitalMapping } from '../../reports.helper.functions';
import {
  AccountSchematicReportSections,
  AccountStructureTabMap,
} from '../account.structure.constants';

const Widget = () => {
  return (
    <div className="mt-3">
      <FactorsThatImpactDSODPOWidget widgetConfig={DSOFTIData} />
    </div>
  );
};
const WorkingCapitalDPO = ({ report }) => {
  const [insightsData, setInsightsData] = useState({});
  const { pageContext } = usePagesContext();
  const accountStructure =
    report?.AccountStructureReport || pageContext.AccountStructureReport;
  useEffect(() => {
    if (accountStructure?.insightsData) {
      const mapping = workingCapitalMapping(
        pageContext.AccountStructureReport?.WorkingCapital?.data,
        pageContext.AccountStructureReport.insightsData
      );
      const mappingAndInsightsData = {
        ...pageContext.AccountStructureReport.insightsData,
        ...mapping,
      };
      setInsightsData(mappingAndInsightsData);
    }
  }, [pageContext]);
  const showWidget =
    accountStructure[AccountStructureTabMap.Widgets.key][
      AccountSchematicReportSections.WCDSO
    ]?.isActive;
  return (
    <>
      <DPODSOpportunityToImproveWidget
        widgetConfig={{
          ...DSOTPData,
          heading: 'Working Capital: Days Sales Outstanding',
          data: insightsData,
        }}
      />
      {showWidget && <Widget />}
    </>
  );
};

export default WorkingCapitalDPO;
