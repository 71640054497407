import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, TabContent, TabPane } from 'reactstrap';

import Card from '../../components/lesson/card';
import { categoriesDefaultInfo } from '../../views/Resources/category/constants/Category.constants';
import courseService from '../../services/course.service';
import CardSkeleton from '../../components/lesson/CardSkeleton';
import NoDataFound from '../../components/commons/NoDataFound';
import {
  LearnViewTypes,
  isModuleAllowed,
  isPermissionAllowed,
  sortByCompleted,
} from '../../utils/Utils';
import lessonService from '../../services/lesson.service';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import AnimatedTabs from '../../components/commons/AnimatedTabs';
import Heading from '../../components/heading';
import { TabsContext } from '../../contexts/tabsContext';
import MyAssignments from '../../components/Assignments/MyAssignments';
import useHash from '../../hooks/useHash';
import { useTenantContext } from '../../contexts/TenantContext';
import { useProfileContext } from '../../contexts/profileContext';

const MyLearningTabs = {
  MyAssignments: 1,
  MyFavorites: 2,
};
function LessonCard(props) {
  const { lesson, setLesson, topics } = props;
  const [icon, setIcon] = useState('');
  const params = useUrlSearchParams();
  const LearnViewTypes = params?.get('viewType');
  useEffect(() => {
    const { category } = lesson;

    const categoryInfo = category;

    if (categoryInfo) {
      const slug = categoryInfo.title
        .toLocaleLowerCase()
        .trim()
        .replace(/ /g, '-');

      const icon = categoriesDefaultInfo[slug]?.icon || 'summarize';
      setIcon(icon);
    }
  }, []);

  return (
    <Card
      item={lesson}
      setItem={setLesson}
      icon={icon}
      topics={topics}
      learnViewTypes={LearnViewTypes}
      sectionType={
        Object.hasOwn(lesson, 'is_learning_path') ? 'course' : 'lesson'
      }
    />
  );
}

function MyLessonsSection({ data, setData, topics, loading }) {
  if (loading)
    return (
      <div className="px-2 pt-1 mt-2 pb-1">
        <CardSkeleton count={3} cols="row-cols-md-3" />{' '}
      </div>
    );

  return (
    <>
      {data?.length > 0 && (
        <div className="px-2 pt-1">
          <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3">
            {data.map((lessonCourse, indx) => (
              <Col key={indx} className="mb-3 px-2 lesson-card-resize">
                <LessonCard
                  lesson={lessonCourse}
                  setLesson={setData}
                  topics={topics}
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
}

export default function MyLessons({ selectedFilter, topics }) {
  const limit = 1000; // default items count for this page
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(MyLearningTabs.MyAssignments);
  const { activatedTab, setActivatedTab } = useContext(TabsContext);
  const { hash, updateHash } = useHash();
  const { tenant } = useTenantContext();
  const { profileInfo } = useProfileContext();
  const [ownerAccess, setOwnerAccess] = useState(false);

  async function getFavoriteLessonsAndCourses() {
    const filter = {
      order: [
        ['progress.completed_at', 'asc nulls first'],
        ['progress.last_attempted_at', 'desc nulls last'],
        ['updated_at', 'desc'],
      ],
    };

    const requests = [];

    requests.push(
      lessonService.getLessons({
        page: 1,
        limit,
        self: true,
        favorites: 'required',
        progress: 'include',
        ...filter,
      })
    );
    requests.push(
      courseService.getCourses({
        page: 1,
        limit,
        self: true,
        favorites: 'required',
        progress: 'include',
        ...filter,
      })
    );

    try {
      setLoading(true);
      const responses = await Promise.all(requests);

      const lessons = sortByCompleted(responses[0]?.data);
      const courses = sortByCompleted(responses[1]?.data, 'courseTracking');

      setData([...lessons, ...courses]);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getFavoriteLessonsAndCourses();
  }, []);

  useEffect(() => {
    getFavoriteLessonsAndCourses();
  }, [activeTab]);

  useEffect(() => {
    if (hash === 'assignments') {
      setActiveTab(null);
      setActiveTab(MyLearningTabs.MyAssignments);
    }
  }, [hash]);

  const handleDataUpdate = (favoriteItem) => {
    const newData = data.filter((d) => {
      return d.id !== favoriteItem.id;
    });
    setData(newData);
  };

  const isLearnAllowed =
    isModuleAllowed(tenant.modules, 'learns_assignments') &&
    (isPermissionAllowed('lessons', 'view') || ownerAccess);

  useEffect(() => {
    if (activatedTab[location.pathname]) {
      setActiveTab(activatedTab[location.pathname]);
    }
    return () => {
      setActiveTab(MyLearningTabs.MyAssignments);
    };
  }, []);

  useEffect(() => {
    if (isLearnAllowed) {
      setActiveTab(MyLearningTabs.MyAssignments);
    } else {
      setActiveTab(MyLearningTabs.MyFavorites);
    }
  }, [isLearnAllowed]);

  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
      updateHash('');
      setActivatedTab({
        [location.pathname]: tab.tabId,
      });
    }
  };

  useEffect(() => {
    if (profileInfo?.role) {
      setOwnerAccess(profileInfo.role.owner_access);
    }
  }, [profileInfo]);

  const tabsData = [
    {
      title: 'My Assignments',
      component: isLearnAllowed ? <MyAssignments /> : '',
      tabId: 1,
      byModule: 'learns_assignments',
    },
    {
      title: 'My Favorites',
      byModule: 'learns_lesson_catalog',
      component: (
        <MyLessonsSection
          data={data}
          setData={handleDataUpdate}
          loading={loading}
          topics={topics}
        />
      ),
      icon: 'list_alt',
      tabId: 2,
    },
  ];

  return (
    <>
      <div>
        <Heading
          title="My Learning"
          useBc
          showGreeting={false}
          className="mt-5"
        >
          <AnimatedTabs
            tabsData={tabsData}
            activeTab={activeTab}
            toggle={toggle}
            permissionCheck={true}
            tabClasses="py-2"
          />
        </Heading>
        <TabContent>
          <TabPane className="position-relative">
            {tabsData.find((item) => item.tabId === activeTab)?.component}
          </TabPane>
        </TabContent>

        {!loading &&
          !data.length &&
          selectedFilter?.key === LearnViewTypes.MyFavorites &&
          activeTab === MyLearningTabs.MyFavorites && (
            <NoDataFound
              title={`${
                selectedFilter?.key === LearnViewTypes.MyFavorites &&
                activeTab === MyLearningTabs.MyFavorites
                  ? 'No favorites yet'
                  : 'No data available'
              }`}
              description="To get started, explore available categories (Topics/Custom) from top left menu."
              icon={`${
                selectedFilter?.key === LearnViewTypes.MyFavorites &&
                activeTab === MyLearningTabs.MyFavorites
                  ? 'favorite'
                  : 'manage_search'
              }`}
              containerStyle="text-gray-900 my-6 py-6"
            ></NoDataFound>
          )}
      </div>
    </>
  );
}
