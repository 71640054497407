import React, { Fragment } from 'react';
import ButtonIcon from '../../commons/ButtonIcon';
import Modal from 'react-bootstrap/Modal';
import MaterialIcon from '../../commons/MaterialIcon';

const ModalSuccessMsg = (props) => {
  const handleRequestCall = () => {
    props.closeModal();
  };
  return (
    <Fragment>
      <Modal
        {...props}
        size="xs"
        aria-labelledby="bank_rep"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="success-message text-center shadow-green p-3">
          <div className="mt-5 mb-4 p-3 rounded-circle d-inline-flex align-items-center justify-content-center bg-soft-green">
            <MaterialIcon icon="check_circle" filled clazz="text-green fs-3" />
          </div>
          <h3 className="mb-1">Request Sent!</h3>
          <p className="text-muted fs-7">Your contact request has been sent.</p>
          <ButtonIcon
            color="white"
            classnames="btn-md mt-2 w-100"
            label="Done"
            onClick={handleRequestCall}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ModalSuccessMsg;
