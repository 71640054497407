import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { CardBody } from 'reactstrap';

ChartJS.register(ArcElement, Tooltip, Legend);

const ProgressBar = ({ progress, color = 'bg-gray-600', height = '12px' }) => {
  return (
    <div className="w-100 rounded-pill bg-gray-300" style={{ height }}>
      <div
        className={`h-100 ${
          progress < 100 ? 'rounded-left-pill' : 'rounded-pill'
        } ${color}`}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
};

const PieChart = ({
  color = '#D32F2F',
  bgColor = '#FFCDD2',
  progress = 80,
  size = 80,
}) => {
  // Ensure progress is between 0 and 100
  const normalizedProgress = Math.max(0, Math.min(progress, 100));

  // Calculate the angle for the progress (in degrees)
  const angle = (normalizedProgress / 100) * 360;

  // Function to calculate the position of a point on a circle, given an angle
  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  // Function to create the large arc path
  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
      'L',
      x,
      y,
      'Z',
    ].join(' ');
  };

  // Define the full circle's radius and center
  const radius = size / 2;
  const pathData =
    normalizedProgress === 100
      ? `M ${radius},${radius} m -${radius},0 a ${radius},${radius} 0 1,0 ${
          2 * radius
        },0 a ${radius},${radius} 0 1,0 -${2 * radius},0`
      : describeArc(radius, radius, radius, 0, angle);

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle cx={radius} cy={radius} r={radius} fill={bgColor} />
      <path d={pathData} fill={color} />
    </svg>
  );
};

const FeeBreakdownChartWidget = ({ widgetConfig, whenPrinting }) => {
  const feesData = widgetConfig?.items[0];
  const isAggregateView = widgetConfig?.aggregate === true;
  const aggregateOrIndividualObject = isAggregateView
    ? widgetConfig?.reportObject?.aggregate
    : widgetConfig?.data;
  const interchangePercentage =
    aggregateOrIndividualObject?.InterchangePercentage || 0;
  const networkFeesPercentage =
    aggregateOrIndividualObject?.NetworkFeesPercentage || 0;
  const commercialFee = interchangePercentage + networkFeesPercentage;
  const remaingChartArea = 100 - commercialFee;
  const data = {
    labels: [
      'Not controlled by Comerica Merchant Services',
      'Controlled by Comerica Merchant Services',
    ],
    datasets: [
      {
        data: [commercialFee, remaingChartArea],
        backgroundColor: ['#D32F2F', '#FFCDD2'],
        borderColor: ['#D32F2F', '#FFCDD2'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <div
      className={`d-flex flex-column w-100 px-2 report-widget ${
        whenPrinting ? 'p-2' : 'p-4'
      }`}
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      {widgetConfig.header && (
        <span className="font-weight-medium text-align-left mb-2">
          {widgetConfig.header}
        </span>
      )}
      <CardBody
        className={`d-flex align-items-center justify-content-center border-2 rpt-border-red bg-red-5 rpt-rounded ${
          whenPrinting ? 'py-2 px-6 gap-2' : 'py-4 px-10 gap-4'
        }`}
      >
        <div
          className=""
          style={{
            width: whenPrinting ? '68px' : '112px',
            height: whenPrinting ? '68px' : '112px',
          }}
        >
          {whenPrinting ? (
            <PieChart progress={commercialFee} size={68} />
          ) : (
            <Pie data={data} options={options} />
          )}
        </div>
        <p className="text-left fs-6 font-weight-medium text-rpt-gray px-2">
          {`${commercialFee?.toFixed(2)}% ${feesData.label}`}
        </p>
      </CardBody>
      {feesData.list.map((item, index) => {
        return (
          <div
            key={index}
            className={`d-flex align-items-start justify-content-center ${
              index === 0 ? 'border-2 rpt-border-red' : 'widget-border-2'
            } bg-white rpt-rounded mt-2`}
          >
            <CardBody className="d-flex justify-content-start gap-4">
              <div
                className="d-flex flex-column align-items-start justify-content-center gap-2 px-2"
                style={{ width: '120px' }}
              >
                <span
                  className={`fs-3 font-weight-bold ${
                    index === 0 ? 'text-red' : ''
                  }`}
                >{`${
                  aggregateOrIndividualObject[item.key]
                    ? aggregateOrIndividualObject[item.key] > 100
                      ? aggregateOrIndividualObject[item.key]?.toFixed(2)
                      : aggregateOrIndividualObject[item.key] || 0
                    : aggregateOrIndividualObject[item.key] || 0
                }%`}</span>
                <ProgressBar
                  progress={
                    aggregateOrIndividualObject[item.key]
                      ? aggregateOrIndividualObject[item.key] > 100
                        ? 100
                        : aggregateOrIndividualObject[item.key] || 0
                      : aggregateOrIndividualObject[item.key] || 0
                  }
                  color={`${index === 0 ? 'bg-red' : 'bg-gray-400'}`}
                  height={`${whenPrinting ? '8px' : '12px'}`}
                />
              </div>
              <div className="d-flex flex-column justify-content-center gap-1">
                <span className="text-left font-size-sm2 font-weight-bold">
                  {`${item.beforeText} ${
                    aggregateOrIndividualObject[item.key]?.toFixed(2) || 0
                  }% ${item.afterText}`}
                </span>
                <span className="text-left font-size-xs font-weight-medium text-rpt-gray">
                  {item.description}
                </span>
              </div>
            </CardBody>
          </div>
        );
      })}
    </div>
  );
};

export default FeeBreakdownChartWidget;
