import { Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import teamsService from '../../../../services/teams.service';
import { AssignmentSnapshotWidget } from '../AssignmentSnapshotWidget';
import { NoUserSelected } from '../../components/queries/NoUserSelected';
import SingleSelectionDropdown from '../../../../components/commons/SingleSelectionDropdown';

export const TrainingAssignmentTeamSnapshot = ({
  insightName,
  assignmentStatsNames = {},
}) => {
  const assignmentStatsTooltip = {
    'Assignments Completed': '% of all assignments completed',
    'Assignments In Progress':
      '% of all assignments started but not yet completed ',
    'Assignments Not Started': '% of all assignments not started ',
    'Assignments Overdue':
      '% of all assignments not completed by the due date.',
    'Number of Assignments':
      'An assignment may consist of one lesson or a bundled set of lessons. Each assignment is created by an administrator and given a unique name and due date',
    'Users Assigned': '# of users required to complete the assignment',
    Lessons: 'total # of lessons included in the assignment',
  };
  const [queryFilter, setQueryFilter] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [show, setShow] = useState(false);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await teamsService.getTeams({
        page: 1,
        limit: 1000,
        filter: {
          search: searchQuery,
        },
      });
      setTeams(data);
    })();
  }, [searchQuery]);

  const handleOptionSelect = (item) => {
    if (Object.keys(item)?.length === 0) {
      setFilter([]);
    } else {
      setFilter([item?.id]);
    }
  };

  return (
    <>
      <Card>
        <Card.Header className={`justify-content-between`}>
          <h4 className="card-title text-hover-primary py-2 mb-0 pt-2 mt-1">
            {insightName}
          </h4>
          {insightName === 'Assignments Organization Snapshot' ||
          insightName === '' ? (
            <></>
          ) : (
            <div className="w-33">
              <SingleSelectionDropdown
                data={teams}
                show={show}
                label={`Team`}
                customClass={'w-100'}
                scrollable="pr-3 text-left"
                setShow={setShow}
                showCheckAll={false}
                search={true}
                customKey={['name', 'id']}
                handleOptionSelected={handleOptionSelect}
                selectedData={queryFilter}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                setSelectedData={setQueryFilter}
              />
            </div>
          )}
        </Card.Header>
        {filter?.length > 0 ? (
          <div className="my-4">
            <AssignmentSnapshotWidget
              queryFilter={filter}
              assignmentStatsNames={assignmentStatsNames}
              allSelectedData={teams}
              headingClass="font-size-lg"
              countClass={'font-size-4em text-left'}
              subHeadingClass="font-size-xl"
              assignmentStatsTooltip={assignmentStatsTooltip}
              customClass="col-md-3 pb-3 pr-2 pl-2"
            />
          </div>
        ) : (
          <NoUserSelected label="team" />
        )}
      </Card>
    </>
  );
};
