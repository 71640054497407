import Accounts from '../Accounts';
import Balances from '../Balances';
import AuthorizedSigners from '../AuthorizedSigners';
import AuthorizedUsers from '../AuthorizedUsers';
import BankContacts from '../BankContacts';
import React, { useState } from 'react';
import FlexColumn from '../FlexColumn';
import ExpandCollapseCard from '../ExpandCollapseCard';

const ReportDetails = ({ report, setReport }) => {
  const [expand, setExpand] = useState({
    'Relationship Overview': true,
    'Authorized Signers': false,
    'Authorized Users': false,
    'Bank Contacts': false,
  });
  return (
    <FlexColumn>
      <ExpandCollapseCard
        label="1a"
        heading="Relationship Overview"
        expand={expand}
        setExpand={setExpand}
      >
        <FlexColumn>
          <Accounts />
          <Balances />
        </FlexColumn>
      </ExpandCollapseCard>
      <ExpandCollapseCard
        label="1b"
        heading="Authorized Signers"
        expand={expand}
        setExpand={setExpand}
      >
        <FlexColumn>
          <AuthorizedSigners />
        </FlexColumn>
      </ExpandCollapseCard>
      <ExpandCollapseCard
        label="1c"
        heading="Authorized Users"
        expand={expand}
        setExpand={setExpand}
      >
        <FlexColumn>
          <AuthorizedUsers />
        </FlexColumn>
      </ExpandCollapseCard>
      <ExpandCollapseCard
        label="1d"
        heading="Bank Contacts"
        expand={expand}
        setExpand={setExpand}
      >
        <FlexColumn>
          <BankContacts />
        </FlexColumn>
      </ExpandCollapseCard>
    </FlexColumn>
  );
};

export default ReportDetails;
