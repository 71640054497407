import React, { useEffect, useState } from 'react';
import { FILE_SIZE_LIMIT, getFileSize } from '../../../utils/Utils';
import stringConstants from '../../../utils/stringConstants.json';
import {
  checkAndAllowFileUpload,
  VALID_FILE_FORMATS,
} from '../../../utils/constants';
import { Spinner } from 'reactstrap';
import feedService from '../../../services/feed.service';
import ActivityFile from './ActivityFile';
import UploadFileModal from '../../modal/UploadFileModal';
import MaterialIcon from '../../commons/MaterialIcon';
import Table from 'react-bootstrap/Table';
import Pagination from '../../Pagination';
import ActivityFileMobile from './ActivityFileMobile';
import Skeleton from 'react-loading-skeleton';
import NoDataFound from '../../commons/NoDataFound';
import checklistService from '../../../services/checklist.service';
import { getClientPortalToken } from '../../../layouts/constants';
import filesService from '../../../services/files.service';
import { useLocation } from 'react-router-dom';

const constants = stringConstants.modals.uploadFileModal;

const FilePreview = ({ file, deleteFile }) => {
  const [fileInfo, setFileInfo] = useState({
    name: '',
    size: '',
  });

  useEffect(() => {
    setFileInfo((prev) => ({
      ...prev,
      name: file.name,
      size: getFileSize(file.size),
    }));
  }, [file]);

  return (
    <div className="js-dropzone dropzone-custom dz-clickable dz-started">
      <div className="col h-100 px-1 mb-2 dz-processing dz-success dz-complete">
        <div className="dz-preview dz-file-preview border shadow">
          <div
            className="d-flex justify-content-end dz-close-icon position-absolute"
            style={{ top: 5, right: 5 }}
          >
            <a
              href=""
              onClick={deleteFile}
              className="icon-hover-bg btn btn-link"
            >
              <MaterialIcon icon="close" />
            </a>
          </div>
          <div className="dz-details media">
            <span className="dz-file-initials text-capitalize">
              {fileInfo.name[0]}
            </span>
            <div className="media-body dz-file-wrapper">
              <h6 className="dz-filename">
                <span className="dz-title">{fileInfo.name}</span>
              </h6>
              <div className="dz-size">
                <strong>{fileInfo.size}</strong>
              </div>
            </div>
          </div>
          <div className="dz-progress progress mb-1">
            <div className="dz-upload progress-bar bg-success w-100"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IdfUploadFiles = ({
  fileInput,
  deleteFile,
  setErrorMessage,
  setFileInput,
  setIsLoading,
  handleSubmit,
  loading,
  activityId,
  publicPage,
  organizationId,
  openFilesModal,
  setOpenFilesModal,
}) => {
  const clientPortalToken = getClientPortalToken();
  const location = useLocation();
  const [recentFiles, setRecentFiles] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, limit: 15 });
  const [loader, setLoader] = useState(false);
  const isMobile = window.innerWidth <= 768;
  useEffect(() => {
    if (publicPage) getRecentFiles();
  }, []);
  const getRecentFiles = async () => {
    try {
      setLoader(true);
      if (location?.pathname === '/clientportal/dashboard/files') {
        const contactChecklistResponse =
          await checklistService.getChecklistByContact(
            clientPortalToken?.contact_id,
            {
              page: 1,
              limit: 100,
            }
          );
        const data = contactChecklistResponse?.data?.data?.map(async (item) => {
          return await checklistService.getCheckListFiles(
            item?.organizationChecklistId,
            {
              limit: 100,
              page: 1,
            }
          );
        });
        const updatedData = await Promise.all(data);
        const fileData = updatedData[0]?.data?.data?.map(async (item) => {
          try {
            const file = await filesService.getFile(item?.fileId);
            const { data } = file;
            return data;
          } catch (error) {
            console.error(
              `Error fetching file with ID ${item?.fileId}:`,
              error
            );
            return null;
          }
        });
        const files = await Promise.all(fileData);
        const updatedFiles = files?.map((item) => {
          const fileData = {
            file: {
              ...item,
            },
          };
          return fileData;
        });
        setRecentFiles(updatedFiles);
        setPagination(updatedData[0]?.data?.pagination);
      } else {
        feedService
          .getFiles({ organizationId, activityId }, pagination)
          .then((res) => {
            setLoader(false);
            setRecentFiles(res.files);
            setPagination(res.pagination);
          })
          .catch(() => {
            setLoader(false);
            setErrorMessage(constants.profile.getFileError);
          });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const onFileChange = (event) => {
    const file = event?.target?.files[0];

    const fileSize = file.size;
    const errors = [];

    // if file exceeds limit
    if (fileSize > FILE_SIZE_LIMIT) {
      errors.push(
        `File size exceeds ${getFileSize(FILE_SIZE_LIMIT, true)} limit.`
      );
    }
    // if file type not allowed
    if (!checkAndAllowFileUpload(file)) {
      errors.push(
        `Invalid file format. Upload file in these ${VALID_FILE_FORMATS.join(
          ','
        )} format.`
      );
    }

    if (errors.length) {
      return setErrorMessage(errors.join('\n'));
    }

    if (!file?.type) {
      const extensionIndex = file.name.indexOf('.');
      const extension = file.name.slice(extensionIndex + 1);

      const newFile = new Blob([file], {
        type: extension,
      });

      newFile.name = file.name.slice(0, extensionIndex);
      newFile.lastModifiedDate = file.lastModifiedDate;

      return setFileInput(newFile);
    }

    if (checkAndAllowFileUpload(file)) {
      return setFileInput(file);
    }
  };

  const onSubmit = () => {
    setIsLoading(true);
    handleSubmit(fileInput, setIsLoading, getRecentFiles);
  };

  return (
    <div className="w-100">
      <div className="position-relative">
        <div className="d-flex align-items-center justify-content-center gap-2 rounded mb-3 p-2_1 bg-yellow border border-yellow">
          <MaterialIcon icon="info" clazz="text-black" rounded filled />{' '}
          <p className="mb-0 text-center text-black font-size-sm2">
            All files will be automatically deleted in 14 days.
          </p>
        </div>
        {fileInput ? (
          <>
            <FilePreview file={fileInput} deleteFile={deleteFile} />
          </>
        ) : (
          <>
            <div
              id="file"
              className="mux-drop-area w-100 mb-3 rounded bg-primary-soft-forced p-5 border-dashed-gray"
            >
              <div className="mb-0 d-flex flex-column form-label justify-content-center align-items-center h-100">
                <div>
                  <MaterialIcon
                    icon="description"
                    rounded
                    clazz="font-size-3xl text-muted"
                  />
                </div>
                <div>
                  <a className="btn-link decoration-underline cursor-pointer text-primary font-weight-semi-bold">
                    Click to upload
                  </a>
                  <span className="ml-1 font-weight-semi-bold">
                    or drag and drop
                  </span>
                </div>
                <p className="text-muted font-size-sm2 font-weight-normal mb-0">
                  Maximum file size {getFileSize(FILE_SIZE_LIMIT, true)}.
                </p>
              </div>
            </div>

            <input
              className="file-input-drag"
              type="file"
              name="file"
              onChange={onFileChange}
              accept={VALID_FILE_FORMATS.join(',')}
              value={fileInput}
              id="file"
            />
          </>
        )}
      </div>

      {fileInput && (
        <div className="d-flex justify-content-end mt-3 mb-4">
          <button
            className="btn btn-primary"
            onClick={onSubmit}
            disabled={!fileInput}
          >
            {loading ? (
              <Spinner className="spinner-grow-xs" />
            ) : (
              <span> Upload Files </span>
            )}
          </button>
        </div>
      )}

      {publicPage && (
        <UploadFileModal
          setShowModal={setOpenUploadModal}
          showModal={openUploadModal}
          handleSubmit={handleSubmit}
          setErrorMessage={setErrorMessage}
          publicPage={publicPage}
        />
      )}

      {publicPage && (
        <>
          {isMobile ? (
            <>
              {recentFiles?.map((file) => (
                <div key={file.id}>
                  <ActivityFileMobile
                    key={file.file_id}
                    file={file}
                    openFilesModal={openFilesModal}
                    publicPage={publicPage}
                    getRecentFiles={getRecentFiles}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="card generic mb-4">
              <>
                {loader ? (
                  <div className="p-3">
                    <Skeleton count={5} height={10} className={'mb-2'} />
                  </div>
                ) : (
                  <Table responsive className="mb-0">
                    <thead>
                      <tr>
                        <th className="text-muted fs-8 text-transform-none font-weight-medium">
                          File Name
                        </th>
                        <th className="align-middle text-muted text-transform-none fs-8 font-weight-medium">
                          Date Uploaded
                        </th>
                        <th className="align-middle text-muted text-transform-none fs-8 font-weight-normal">
                          Uploaded By
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentFiles.length === 0 ? (
                        <tr>
                          <td colSpan={3}>
                            <NoDataFound
                              icon="note_stack"
                              iconSymbol={true}
                              title={
                                <div className="font-normal font-size-sm2 text-gray-search">
                                  No files available
                                </div>
                              }
                              containerStyle="text-gray-search py-6"
                            />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {recentFiles?.map((file) => (
                            <tr key={file.id}>
                              <ActivityFile
                                key={file.file_id}
                                file={file}
                                openFilesModal={openFilesModal}
                                publicPage={publicPage}
                                getRecentFiles={getRecentFiles}
                              />
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </Table>
                )}
              </>
            </div>
          )}
          {recentFiles?.length > 0 && pagination.totalPages > 1 && (
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mb-3 d-flex justify-content-center">
              <Pagination
                className="m-auto"
                paginationInfo={pagination}
                onPageChange={(page) => {
                  setPagination((prevState) => ({
                    ...prevState,
                    page,
                  }));
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default IdfUploadFiles;
