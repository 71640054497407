import React, { useState, useEffect, useContext, Fragment } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';

import authService from '../services/auth.service';
import UserService from '../services/user.service';
import Avatar from './Avatar';
import { useProfileContext } from '../contexts/profileContext';
import { useAppContext } from '../contexts/appContext';
import { ToggleMenuContext } from '../contexts/toogleMenuContext';
import HelpCenter from './helpCenter/HelpCenter';
import { PROFILE_LABEL, SIGN_OUT } from '../utils/constants';
import { Col, Row } from 'react-bootstrap';
import { TenantContext } from '../contexts/TenantContext';
import NotificationsModal from './notificationsModal/notificationsModal';
import avatarService from '../services/avatar.service';
import { GlobalSearch } from './search/GlobalSearch';
import NavbarFilters from './navBarFilters/NavbarFilters';
import constants from '../components/search/GlobalSearch.constants.json';
import { useModuleContext } from '../contexts/moduleContext';
import AppVersion from './commons/AppVersion';
import { isModuleAllowed, isPermissionAllowed } from '../utils/Utils';
import { useKeycloak } from '../contexts/KeycloakProvider';
import SidebarIcon from './sidebar/SidebarIcon';
import MaterialIcon from './commons/MaterialIcon';

export default function Header() {
  const { tenant, setCount } = useContext(TenantContext);
  const history = useHistory();
  const keycloak = useKeycloak();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenDesktop, setDropdownOpenDesktop] = useState(false);

  const [profile, setProfile] = useState({});
  const { profileInfo, setProfileInfo } = useProfileContext();

  const { userHasAuthenticated } = useAppContext();
  const { setOpen } = useContext(ToggleMenuContext);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleDesktop = () => setDropdownOpenDesktop((prevState) => !prevState);

  const [tenantLogo, setTenantLogo] = useState(null);

  const { moduleMap } = useModuleContext();
  const newOptionsList = [...constants.optionsList].filter((item) =>
    isPermissionAllowed(item.permission.collection, item.permission.action)
  );
  useEffect(() => {
    const keyToPluralMap = {
      organizations: 'organization',
      contacts: 'contact',
      deals: 'deal',
      task: 'task',
      event: 'event',
      call: 'call',
      products: 'product',
    };

    newOptionsList.map((obj) => {
      const pluralKey = keyToPluralMap[obj.key];
      if (pluralKey && moduleMap[pluralKey]) {
        return (obj.name = moduleMap[pluralKey].plural);
      }
      return obj;
    });
  }, [newOptionsList]);

  useEffect(() => {
    if (tenant.status === 'disabled') {
      history.push('/403');
    }
    const getAvatarLogo = async () => {
      let logo = null;

      if (tenant?.logo) {
        logo = await avatarService.getAvatarMemo(tenant.logo, true);
      }
      setTenantLogo(logo);
    };

    getAvatarLogo();
  }, [tenant]);

  useEffect(() => {
    UserService.getUserInfo()
      .then((profileResult) => {
        setProfile(profileResult);
        setProfileInfo(profileResult);
      })
      .catch((err) => console.error(err));
  }, []);

  const logout = async () => {
    setCount(2);
    authService.logout();
    if (!keycloak) {
      userHasAuthenticated(false);
      setProfileInfo({});
      history.push('/login');
    } else {
      await keycloak?.logout();
    }
  };

  useEffect(() => {
    setProfile((prev) => ({
      ...prev,
      ...profileInfo,
    }));
  }, [profileInfo]);

  useEffect(() => {
    const screen = window.screen;

    if (screen.width < 1200) setOpen(false);
  }, [history.location, setOpen]);
  return (
    <>
      <header
        id="headerDesktop"
        className="header desktop d-none d-md-block navbar navbar-expand-lg z-index-100 navbar-fixed navbar-height navbar-flush border-bottom pr-0 navbar-container"
      >
        <div className="navbar-nav-wrap py-1">
          <div
            className="mr-2 d-xl-none"
            onClick={() => setOpen((prevOpen) => setOpen(!prevOpen))}
          >
            <span className="material-symbols-rounded text-primary font-size-3xl ">
              menu
            </span>
          </div>
          <div className="navbar-brand-wrapper">
            <a className="navbar-brand" href="/" aria-label="Identifee">
              <img
                className="navbar-brand-logo"
                style={{ height: '40px' }}
                src={`${tenant?.settings?.light_theme_logo || tenantLogo?.url}`}
                alt={`${tenant.name} Logo`}
              />
              <img
                className="navbar-brand-logo-mini"
                src={`${
                  tenant.settings?.light_theme_logo ||
                  tenant.logo ||
                  '/img/icon.svg'
                }`}
                alt={`${tenant.name} Logo`}
              />
            </a>
          </div>
          <div className="pl-0 breadcrumb-desktop header-logo">
            <Row>
              <Col>
                <SidebarIcon />
              </Col>
            </Row>
          </div>
          <div className="col justify-content-between d-flex px-0">
            {!profileInfo?.role?.admin_access && (
              <Fragment>
                {moduleMap.organization !== undefined && (
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <GlobalSearch data={newOptionsList} />
                    <NavbarFilters moduleMap={moduleMap} />
                  </div>
                )}
              </Fragment>
            )}
          </div>
          <div className="d-flex align-items-center col-auto pr-1">
            <NotificationsModal />
            <HelpCenter />
            {isModuleAllowed(tenant.modules, 'Settings') && (
              <section
                className="ml-auto mr-2"
                onClick={() => history.push('/settings/profile')}
              >
                <MaterialIcon
                  icon="settings"
                  rounded
                  clazz="text-gray-700 icon-hover-bg font-size-xl2 cursor-pointer"
                />
              </section>
            )}
            <div className="navbar-nav-wrap-content-right avatar-mobile">
              <ul className="navbar-nav align-items-center flex-row">
                <li className="nav-item">
                  <Dropdown
                    isOpen={dropdownOpenDesktop}
                    toggle={toggleDesktop}
                    direction="down"
                  >
                    <div className="hs-unfold">
                      <DropdownToggle
                        className="bg-transparent border-0 p-2"
                        data-toggle="dropdown"
                        aria-expanded={dropdownOpenDesktop}
                      >
                        <div>
                          <Avatar
                            user={profile}
                            active={false}
                            sizeIcon="avatar-dark"
                          />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu className="navfix py-1">
                        <div id="accountNavbarDropdown">
                          <DropdownItem className="px-3 bg-hover-white cursor-default">
                            <div className="media align-items-center">
                              <Avatar
                                user={profile}
                                classModifiers="mr-2"
                                sizeIcon="avatar-dark text-white"
                              />
                              <div className="media-body">
                                {profile?.first_name && (
                                  <span className="card-title h5">
                                    {profile?.id
                                      ? `${profile.first_name} ${profile.last_name}`
                                      : 'Loading...'}
                                  </span>
                                )}

                                <span className="card-text text-muted font-size-xs">
                                  {profile?.id
                                    ? `${profile?.email}`
                                    : 'Loading...'}
                                </span>
                              </div>
                            </div>
                          </DropdownItem>
                          <div className="dropdown-divider"></div>
                          <Link to="/settings/profile">
                            <DropdownItem className="px-3">
                              <span
                                className="text-truncate pr-2"
                                title="Settings"
                              >
                                {PROFILE_LABEL} Settings
                              </span>
                            </DropdownItem>
                          </Link>
                          <DropdownItem className="px-3">
                            <div onClick={logout}>
                              <span
                                className="text-truncate text-black pr-2"
                                title={SIGN_OUT}
                              >
                                {SIGN_OUT}
                              </span>
                            </div>
                          </DropdownItem>
                          <div className="dropdown-divider"></div>
                          <DropdownItem
                            disabled
                            className="px-3 text-black cursor-default"
                          >
                            <AppVersion
                              styles="text-right cursor-default"
                              color="text-black"
                            />
                          </DropdownItem>
                        </div>
                      </DropdownMenu>
                    </div>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <header
        id="headerMobile"
        className="header mobile navbar d-md-none navbar-expand-lg z-index-100 navbar-fixed navbar-height navbar-flush border-bottom pr-0 navbar-container"
      >
        <div className="topheader d-flex justify-content-between align-items-center">
          <div
            className="mr-2 d-xl-none"
            onClick={() => setOpen((prevOpen) => setOpen(!prevOpen))}
          >
            <span className="material-symbols-rounded text-primary font-size-3xl ">
              menu
            </span>
          </div>

          <div className="topheader-right d-flex justify-content-between w-100 align-items-center">
            <div className="navbar-brand-wrapper">
              <a className="navbar-brand" href="/" aria-label="Identifee">
                <img
                  className="navbar-brand-logo"
                  style={{ height: '40px' }}
                  src={`${
                    tenant?.settings?.light_theme_logo || tenantLogo?.url
                  }`}
                  alt={`${tenant.name} Logo`}
                />
                <img
                  className="navbar-brand-logo-mini"
                  src={`${
                    tenant.settings?.light_theme_logo ||
                    tenant.logo ||
                    '/img/icon.svg'
                  }`}
                  alt={`${tenant.name} Logo`}
                />
              </a>
            </div>
            <div className="d-flex align-items-center col-auto pr-1">
              <NotificationsModal className="mr-2" />
              <HelpCenter />
              <div className="navbar-nav-wrap-content-right avatar-mobile">
                <ul className="navbar-nav align-items-center flex-row">
                  <li className="nav-item">
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggle}
                      direction="down"
                    >
                      <div className="hs-unfold">
                        <DropdownToggle
                          className="bg-transparent border-0 p-1"
                          data-toggle="dropdown"
                          aria-expanded={dropdownOpen}
                        >
                          <div>
                            <Avatar
                              user={profile}
                              active={false}
                              sizeIcon="avatar-dark"
                            />
                          </div>
                        </DropdownToggle>
                        <DropdownMenu className="navfix py-1">
                          <div id="accountNavbarDropdown">
                            <DropdownItem className="px-3 bg-hover-white cursor-default">
                              <div className="media align-items-center">
                                <Avatar
                                  user={profile}
                                  classModifiers="mr-2"
                                  sizeIcon="avatar-dark text-white"
                                />
                                <div className="media-body">
                                  {profile?.first_name && (
                                    <span className="card-title h5">
                                      {profile?.id
                                        ? `${profile.first_name} ${profile.last_name}`
                                        : 'Loading...'}
                                    </span>
                                  )}

                                  <span className="card-text text-muted font-size-xs">
                                    {profile?.id
                                      ? `${profile?.email}`
                                      : 'Loading...'}
                                  </span>
                                </div>
                              </div>
                            </DropdownItem>
                            <div className="dropdown-divider"></div>
                            <Link to="/settings/profile">
                              <DropdownItem className="px-3">
                                <span
                                  className="text-truncate pr-2"
                                  title="Settings"
                                >
                                  {PROFILE_LABEL} Settings
                                </span>
                              </DropdownItem>
                            </Link>
                            <DropdownItem className="px-3">
                              <div onClick={logout}>
                                <span
                                  className="text-truncate text-black pr-2"
                                  title={SIGN_OUT}
                                >
                                  {SIGN_OUT}
                                </span>
                              </div>
                            </DropdownItem>
                            <div className="dropdown-divider"></div>
                            <DropdownItem
                              disabled
                              className="px-3 text-black cursor-default"
                            >
                              <AppVersion
                                styles="text-right cursor-default"
                                color="text-black"
                              />
                            </DropdownItem>
                          </div>
                        </DropdownMenu>
                      </div>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {!profileInfo?.role?.admin_access && (
          <Fragment>
            {moduleMap.organization !== undefined && (
              <div className="serach-wrp d-flex align-items-center justify-content-center py-1 px-2 gap-3 w-100">
                <GlobalSearch data={newOptionsList} />
                <NavbarFilters moduleMap={moduleMap} />
              </div>
            )}
          </Fragment>
        )}
      </header>
    </>
  );
}
