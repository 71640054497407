import { useTenantContext } from '../../../contexts/TenantContext';
import useIsTenant from '../../../hooks/useIsTenant';

const TheDifferenceWidget = ({ selectedTenant }) => {
  const { tenant } = useTenantContext();
  const { isValleyBank } = useIsTenant();
  const tenantName = isValleyBank
    ? 'Valley Bank'
    : selectedTenant?.tip || tenant?.name;
  return <>{`The ${tenantName} Difference`}</>;
};

export default TheDifferenceWidget;
