import { Card, CardBody } from 'reactstrap';
import React from 'react';
import { formatNumberV2, isToFixedNoRound } from '../../../../utils/Utils';
import { Col, Row } from 'react-bootstrap';
import GreenRedCheckItem from '../../blocks/GreenRedCheckItem';
import { TreasuryReportSections } from '../../../reports/reports.constants';

const FraudBalancesAtRiskWidget = ({ widgetConfig, whenPrinting }) => {
  const report = widgetConfig?.data;
  const isChecked = Object.entries(report?.fraudPreventionProducts || {}).every(
    (item) => {
      return item[1]?.value > 0;
    }
  );
  return (
    <Card className="report-widget">
      <CardBody className="justify-content-center align-items-center d-flex">
        <div className="flex-fill">
          <h5 className="text-left mb-1 d-flex align-items-center gap-1">
            Balances at Risk
          </h5>
          <Row className={`align-items-center position-relative px-2`}>
            <Col md={5} className="position-relative">
              <div
                className={`border-2 ${
                  isChecked ? 'rpt-green-box' : 'rpt-red-box'
                }`}
                style={{
                  height: 180,
                  width: '100%',
                }}
              >
                <div className="position-absolute abs-center-xy">
                  <div className={`mb-1 fw-bolder text-black`}>
                    <div className="d-flex gap-2 justify-content-center align-items-center">
                      <h1 className="mb-0 font-weight-bold">
                        {report?.balance === 0
                          ? '$0'
                          : report?.balance < 1000000
                          ? isToFixedNoRound(report.balance, 0)
                          : formatNumberV2(report.balance)}
                      </h1>
                    </div>
                  </div>
                  <p className="fs-9 sub-text text-center mb-0">
                    {isChecked
                      ? 'Your balances with fraud protection'
                      : 'Your balances at risk'}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="text-left text-rpt-heading font-size-sm2 ml-0">
              <p className="mb-2 font-size-sm2">
                As of the last billing cycle, your average collected balance was{' '}
                {isToFixedNoRound(report.balance, 0)}.
              </p>
              <p className="mb-2 rounded font-size-sm2 py-2 px-0">
                {isChecked
                  ? "You're doing great job protecting your balances with fraud protection services. Since fraudsters are always evolving, continue to stay alert."
                  : report?.multipleAccounts
                  ? 'Your accounts are considered to be at high risk given your deposit volume and lack of fraud solutions.'
                  : 'Your account is considered to be at high risk given your deposit volume and lack of fraud solutions.'}
              </p>
              <p className="fs-8 font-weight-semi-bold text-left mb-0">
                Fraud Prevention Products:
              </p>
              <div className="d-flex position-relative mt-1 gap-3 fraud-products align-items-center">
                {Object.entries(report?.fraudPreventionProducts || {}).map(
                  (entry) => (
                    <div
                      key={entry[0]}
                      className="text-center d-flex align-items-center justify-content-center"
                    >
                      <GreenRedCheckItem
                        bordered=""
                        section={TreasuryReportSections.Fraud}
                        item={entry}
                      />
                    </div>
                  )
                )}
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default FraudBalancesAtRiskWidget;
