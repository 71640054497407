import { useEffect, useCallback } from 'react';
import OrganizationService from '../services/organization.service';
import {
  CUSTOMER_ID_KEY,
  LEAD_COMPOSITE_ID_KEY,
} from '../components/prospecting/v2/constants';
import { useCustomerIdContext } from '../contexts/CustomerIdContext';

const useCustomerId = (organization) => {
  const { customerId, setCustomerId } = useCustomerIdContext();
  const fieldKeys = [CUSTOMER_ID_KEY, LEAD_COMPOSITE_ID_KEY];

  const findCustomerIdField = (data) => {
    return data.find((tx) => fieldKeys.includes(tx?.field?.key?.toLowerCase()));
  };

  const loadFields = useCallback(async () => {
    try {
      const { data } = await OrganizationService.getFieldByOrganization(
        organization.id,
        {}
      );
      const customerIdField = findCustomerIdField(data);
      setCustomerId(customerIdField?.value || null);
    } catch (error) {
      console.error('Failed to load customer ID:', error);
      setCustomerId(null);
    }
  }, [organization.id, setCustomerId]);

  useEffect(() => {
    if (organization?.id) {
      loadFields();
    }
  }, [organization, loadFields]);

  return customerId;
};

export default useCustomerId;
