import React from 'react';

import Table from '../GenericTable';
import TableActions from '../commons/TableActions';
import MaterialIcon from '../commons/MaterialIcon';
import TooltipComponent from '../lesson/Tooltip';
import moment from 'moment/moment';

const AssignmentsTable = ({
  data = [],
  paginationInfo,
  dataInDB,
  onPageChange,
  handleDelete,
  handleEdit,
  handleEditFullAssignment,
  handleUpdateStatus,
  sortingTable,
  sortingOrder,
}) => {
  const columns = [
    {
      key: 'Title',
      orderBy: 'title',
      component: 'Name',
    },
    {
      key: 'Due',
      orderBy: 'dueAt',
      component: 'Due',
    },
    {
      key: 'Started',
      orderBy: 'createdAt',
      component: 'Started',
    },
    {
      key: '',
      orderBy: '',
      component: '',
    },
  ];

  const tableActions = [
    {
      id: 4,
      title: 'Edit',
      icon: 'edit',
      onClick: handleEditFullAssignment,
    },
    {
      id: 5,
      title: 'Delete',
      icon: 'delete',
      onClick: handleDelete,
    },
  ];

  const rows = data?.map((item, index) => {
    const statusIcon =
      item.status === 'active' ? 'event_busy' : 'event_available';
    const statusTitle = item.status === 'active' ? 'Unassign' : 'Assign';

    return {
      ...item,
      dataRow: [
        {
          key: 'title',
          component: (
            <span className="d-flex align-items-center">
              {item.title}{' '}
              <TooltipComponent
                title={
                  'Updated at: ' +
                  moment(item.updatedAt || 0).format('MM/DD/YYYY')
                }
                placement={'right'}
              >
                <MaterialIcon icon="info" clazz="ml-1" />
              </TooltipComponent>
            </span>
          ),
        },
        {
          key: 'dueAt',
          component: (
            <span className="text-wrap">
              {moment(item.dueAt || 0).format('MM/DD/YYYY')}
            </span>
          ),
        },
        {
          key: 'assignedAt',
          component: '',
        },
        {
          key: '',
          component: (
            <TableActions
              item={{ ...item, title: item.title }}
              actions={[
                {
                  id: index,
                  title: statusTitle,
                  icon: statusIcon,
                  onClick: () => handleUpdateStatus(item),
                },
                ...tableActions,
              ]}
            />
          ),
        },
      ],
    };
  });

  return (
    <div className="table-responsive-md datatable-custom">
      <Table
        data={rows}
        columns={columns}
        title="Assignment"
        dataInDB={dataInDB}
        headClass="bg-gray-table-head border-top"
        toggle={handleEdit}
        onPageChange={onPageChange}
        sortingTable={sortingTable}
        sortingOrder={sortingOrder}
        emptyDataText="No Assignments"
        noDataInDbValidation={dataInDB}
        paginationInfo={paginationInfo}
        onClick={handleEditFullAssignment}
      />
    </div>
  );
};

export default AssignmentsTable;
