import SubHeading from '../../components/subheading';
import CardSkeleton from '../../components/lesson/CardSkeleton';
import React, { useContext } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import StatsAndFilter from '../../components/lesson/StatsAndFilter';
import TopicIcon from '../../components/commons/TopicIcon';
import { sortByPinnedTopics, isModuleAllowed } from '../../utils/Utils';
import { Image } from 'react-bootstrap';
import MyOrganizationCover from '../../assets/png/learn/categories/MyOrganization-Category.png';
import { TenantContext } from '../../contexts/TenantContext';

const myOrganization = {
  icon: 'corporate_fare',
  title: 'My Organization',
  cover: MyOrganizationCover,
};
const TopicItemWithImage = ({ topic }) => {
  return (
    <Link to={topic.path}>
      <Card className="h-100 p-0 setting-item">
        <CardBody className="position-relative p-0 d-flex flex-column w-100">
          <div className="h-100 rounded-top flex-fill">
            <Image
              height={200}
              className="rounded-top"
              style={{ width: '100%', objectFit: 'cover' }}
              src={topic.cover}
            />
          </div>
          {topic?.totalCourses || topic?.totalLessons ? (
            <p className="text-muted font-size-sm2 px-3 mt-2 py-2 mb-0">
              <StatsAndFilter
                showStatsOnly={true}
                courseKey="totalCourses"
                lessonKey="totalLessons"
                categoryInfo={topic}
                stats={topic}
              />
            </p>
          ) : null}
        </CardBody>
      </Card>
    </Link>
  );
};

const TopicItem = ({ topic }) => {
  return (
    <Link to={topic.path}>
      <Card className="h-100 p-0 setting-item setting-item-category">
        <CardBody className="py-0 position-relative pr-4 pl-0 h-100">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ height: 250 }}
          >
            <h4
              className="text-left p-2_1 px-3 font-weight-semi-bold"
              style={{ marginTop: -90 }}
            >
              {topic.title}
            </h4>
            <TopicIcon
              icon={topic.icon || 'category'}
              iconBg="bg-primary-soft"
              iconStyle={{ width: 96, height: 96 }}
              iconClasses="font-size-4em text-primary"
            />
          </div>
          <p className="position-absolute text-muted fs-8 px-3 py-2 bottom-0 left-0 mb-0">
            <StatsAndFilter
              showStatsOnly={true}
              courseKey="totalCourses"
              lessonKey="totalLessons"
              categoryInfo={topic}
              stats={topic}
            />
          </p>
        </CardBody>
      </Card>
    </Link>
  );
};
const MyOrganizationItemWithImage = ({
  cat,
  setSelectedFilter,
  updateFilterList,
  stats,
  history,
}) => {
  return (
    <Card
      className="h-100 p-0 cursor-pointer setting-item"
      onClick={() => {
        history.replace({
          search: `?viewType=custom&ref=my-organization`,
        });
        setSelectedFilter(updateFilterList[2]);
      }}
    >
      <CardBody className="position-relative p-0 d-flex flex-column w-100">
        <div className="h-100 rounded-top flex-fill">
          <Image
            height={200}
            style={{ width: '100%', objectFit: 'cover' }}
            src={cat.cover}
            className={'rounded-top'}
          />
        </div>
        {stats?.totalCourses || stats?.totalLessons ? (
          <p className="text-muted font-size-sm2 mb-0 px-3 mt-2 py-2">
            <StatsAndFilter
              showStatsOnly={true}
              courseKey="totalCourses"
              lessonKey="totalLessons"
              categoryInfo={stats}
              stats={stats}
            />
          </p>
        ) : null}
      </CardBody>
    </Card>
  );
};

const TopicItems = ({
  topics,
  heading,
  stats,
  updateFilterList,
  setSelectedFilter,
}) => {
  const history = useHistory();
  return (
    <div className="px-2">
      <SubHeading title={heading} />
      <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3">
        {sortByPinnedTopics(topics)?.map((cat, indx) => (
          <>
            <Col className={`mb-3 px-2 ${indx === 0 ? 'd-block' : 'd-none'}`}>
              {cat?.cover ? (
                <MyOrganizationItemWithImage
                  cat={myOrganization}
                  showStatsOnly={true}
                  setSelectedFilter={setSelectedFilter}
                  updateFilterList={updateFilterList}
                  stats={stats}
                  history={history}
                />
              ) : (
                <Card
                  className="h-100 p-0 setting-item setting-item-category"
                  onClick={() => {
                    history.replace({
                      search: `?viewType=custom&ref=my-organization`,
                    });
                    setSelectedFilter(updateFilterList[2]);
                  }}
                >
                  <CardBody className="py-0 position-relative pr-4 pl-0 h-100">
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{ height: 250 }}
                    >
                      <h4
                        className="text-left font-weight-semi-bold p-2_1 px-3"
                        style={{ marginTop: -90 }}
                      >
                        {myOrganization.title}
                      </h4>
                      <TopicIcon
                        icon={myOrganization.icon || 'category'}
                        iconBg="bg-primary-soft"
                        iconStyle={{ width: 96, height: 96 }}
                        iconClasses="font-size-4em text-primary"
                      />
                    </div>
                    <p className="position-absolute text-muted font-size-sm2 px-3 py-2 bottom-0 left-0 mb-0">
                      <StatsAndFilter
                        showStatsOnly={true}
                        courseKey="totalCourses"
                        lessonKey="totalLessons"
                        categoryInfo={stats}
                        stats={stats}
                      />
                    </p>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col key={indx} className="mb-3 px-2">
              {cat.cover ? (
                <TopicItemWithImage topic={cat} />
              ) : (
                <TopicItem topic={cat} />
              )}
            </Col>
          </>
        ))}
      </Row>
    </div>
  );
};

const Topics = ({
  loading,
  topics,
  stats,
  updateFilterList,
  setSelectedFilter,
}) => {
  const { tenant } = useContext(TenantContext);
  return (
    <div>
      {loading && (
        <div className="px-2">
          <div>
            <SubHeading title={'Categories'} />{' '}
            <CardSkeleton count={3} cols="row-cols-md-3 mt-3" />
          </div>
        </div>
      )}
      {isModuleAllowed(tenant.modules, 'learns_lesson_catalog')
        ? topics?.exploreList?.length > 0 && (
            <TopicItems
              updateFilterList={updateFilterList}
              setSelectedFilter={setSelectedFilter}
              stats={stats}
              topics={topics?.exploreList}
              heading="Categories"
            />
          )
        : ''}
    </div>
  );
};

export default Topics;
