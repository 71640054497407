const useIsCommonLogin = () => {
  const host = window.location.host.split('.');
  const isClientPortal =
    host.length >= 2 && host[host.length - 2].toLowerCase() === 'iclientportal';

  const isCommonLogin = host[0] === 'login';

  return { isCommonLogin, isClientPortal };
};

export default useIsCommonLogin;
