import axios from 'axios';

import authHeader from './auth-header';
import BaseRequestService from './baseRequest.service';
import { errorsRedirectHandler } from '../utils/Utils';
const API_URL = process.env.REACT_APP_API_URL + '/api/teams';
const API_URL_TEAM_MEMBERS = process.env.REACT_APP_API_URL + '/api/teamMembers';
class TeamService extends BaseRequestService {
  async getTeams(query = {}) {
    const { page = 1, limit = 1000, self = true, order, filter = {} } = query;
    return axios
      .get(`${API_URL}`, {
        params: {
          order,
          page,
          limit,
          search: filter?.search,
          self,
          isActive: filter?.isActive,
        },
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async CreateTeam(data) {
    return await axios.post(API_URL, data, { headers: authHeader() });
  }

  async getTeamById(team_id) {
    return axios
      .get(`${API_URL}/${team_id}`, { headers: authHeader() })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async getTeamMembersById(
    team_id,
    { page = 1, limit = 10, order, isManager }
  ) {
    const params = {
      page,
      limit,
      order,
      isManager,
    };
    return axios
      .get(`${API_URL}/${team_id}/members`, {
        params,
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async updateTeam(id, data) {
    return await axios
      .put(`${API_URL}/${id}`, data, { headers: authHeader() })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async deleteTeam(team_id) {
    return axios
      .delete(`${API_URL}/${team_id}`, {
        headers: authHeader(),
      })
      .then((response) => response.data)
      .catch((error) => error);
  }

  async updateTeamMemberById(data, team_id) {
    return await this.put(`${API_URL}/${team_id}/members`, data, {
      headers: authHeader(),
    }).catch(errorsRedirectHandler);
  }

  async setTeamManager(memberId) {
    return await this.put(
      `${API_URL_TEAM_MEMBERS}/${memberId}`,
      { isManager: true },
      {
        headers: authHeader(),
      }
    ).catch(errorsRedirectHandler);
  }

  async deleteTeamMember(memberId) {
    return await this.delete(`${API_URL_TEAM_MEMBERS}/${memberId}`, {
      headers: authHeader(),
    }).catch(errorsRedirectHandler);
  }
}

export default new TeamService();
