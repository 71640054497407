import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormCheck } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { WCInputField, WorkingCapitalFields } from '../../WCInputField';
import useJumpToNextInputOnEnter from '../../../../hooks/useJumpToNextInputOnEnter';
import { usePagesContext } from '../../../../contexts/pagesContext';
import { AccountStructureTabMap } from '../account.structure.constants';

const WorkingCapital = ({ report, setReport }) => {
  const { pageContext, setPageContext } = usePagesContext();
  const jsonKey = AccountStructureTabMap.WorkingCapital.key;
  const inputRefs = useRef([]);
  const handleKeyPress = useJumpToNextInputOnEnter(inputRefs);
  const [workingCapital, setWorkingCapital] = useState(
    pageContext?.AccountStructureReportModal[jsonKey]?.data || {}
  );
  const [active, setActive] = useState(
    pageContext?.AccountStructureReportModal[jsonKey]?.isActive
  );
  const handleChange = (e, key) => {
    const { value } = e.target;
    const updatedWorkingCapital = {
      ...workingCapital,
      [key]: value,
    };
    setWorkingCapital(updatedWorkingCapital);
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: {
        ...pageContext.AccountStructureReportModal,
        [jsonKey]: {
          data: updatedWorkingCapital,
          isActive:
            pageContext.AccountStructureReportModal.WorkingCapital.isActive,
        },
      },
    });
  };
  const handleActiveChange = () => {
    setActive(!active);
    setPageContext({
      ...pageContext,
      AccountStructureReportModal: {
        ...pageContext.AccountStructureReportModal,
        [jsonKey]: {
          data: pageContext.AccountStructureReportModal.WorkingCapital.data,
          isActive: !active,
        },
      },
    });
  };
  const handleRef = (index) => (ref) => {
    inputRefs.current[index] = ref;
  };

  return (
    <Card>
      <CardHeader className="justify-content-between">
        <h4 className="mb-0">Enter Current Financial Data</h4>
        <FormCheck
          id="toggleEnableWorkingCapital"
          name="isActive"
          type="switch"
          checked={active}
          custom={true}
          label="Active"
          className="font-size-sm2"
          onChange={handleActiveChange}
        />
      </CardHeader>
      <CardBody>
        {WorkingCapitalFields.map((field, index) => (
          <WCInputField
            key={field}
            report={workingCapital}
            label={field}
            index={index}
            handleRef={handleRef}
            handleKeyPress={handleKeyPress}
            stacked={true}
            labelKey={_.camelCase(field)}
            handleChange={handleChange}
          />
        ))}
      </CardBody>
    </Card>
  );
};
export default WorkingCapital;
