import React, { useEffect } from 'react';
import RightPanelModal from '../modal/RightPanelModal';
import { renderComponent } from '../peoples/constantsPeople';
import IdfFormInputCurrency from '../idfComponents/idfFormInput/IdfFormInputCurrency';
import Loading from '../Loading';
import { useForm } from 'react-hook-form';
import { Form, ModalFooter } from 'react-bootstrap';
import ButtonIcon from '../commons/ButtonIcon';
import { CardBody, Col, FormGroup, Label } from 'reactstrap';
import { CheckboxInput } from '../layouts/CardLayout';
import ControllerValidation from '../commons/ControllerValidation';
import {
  DATE_FORMAT_EJS,
  DATE_FORMAT_EJS_UPDATED,
  RIGHT_PANEL_WIDTH,
  formatHHMMSS,
  getPattern,
  getPatternErrorMessage,
} from '../../utils/Utils';
import stringConstants from '../../utils/stringConstants.json';
import moment from 'moment';
import ReactDatepicker from '../inputs/ReactDatpicker';
import AddPicklistOptions from '../peopleProfile/contentFeed/AddPicklistOptions';
import DropdownSelect from '../DropdownSelect';
const excludedColumns = ['Price'];

const constants = stringConstants.deals.contacts.profile;

const excludedFieldTypes = ['CHECKBOX', 'DATE', 'PICKLIST', 'PICKLIST_MULTI'];
const initialLoad = {};
const picklistDefault = [];

const ProductCreateModal = ({
  show,
  onHandleClose,
  product,
  setProduct,
  onHandleSubmit,
  fields = {},
  productType,
  setLoading,
  isLoading,
  buttonSpinner,
  setIsLoading,
  customDataFields,
  setCustomDataFields,
  loading,
  onHandleUpdate,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getFieldState,
    formState: { errors },
  } = useForm({
    defaultValues: product,
  });
  const handleSubmitted = () => {
    setIsLoading(true);
    onHandleSubmit();
    reset(product);
    setIsLoading(false);
  };
  const onHandleChange = (e) => {
    const { value, name } = e.target;
    setProduct({ ...product, [name]: value });
    setValue(name, value);
  };
  const loader = () => {
    if (loading) return <Loading />;
  };
  const ResetForm = () => {
    onHandleClose();
    reset(product);
  };

  useEffect(() => {
    const groups = Object.keys(fields);
    if (groups.length) {
      for (const grp of groups) {
        const field = fields[grp];
        field.forEach((item) => {
          const { columnName, key } = item;
          const fieldName = columnName
            ? columnName.toLowerCase()
            : key?.toLowerCase().replace(/\s+/g, '');
          setValue(fieldName, product[fieldName]);
        });
      }
    }
  }, [fields]);

  const onHandleCustomField = (e, id, value_type, field_type) => {
    const target = e.target;
    let value = '';
    if (value_type === 'string' && target.value !== '') {
      value = target.value;
    }
    if (value_type === 'number' && target.value !== '') {
      value = parseInt(target.value);
    }

    if (field_type === 'CURRENCY') {
      value = `$${target.value}`;
    } else if (field_type === 'TIME') {
      value = moment(value).format(formatHHMMSS);
    }
    let updated = false;
    setProduct({ ...product, [target.name]: target.value });
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return {
          field_id: id,
          value,
        };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      setCustomDataFields([...fieldData, { field_id: id, value }]);
    }
  };
  const onHandleCustomCheckBox = (e, id) => {
    const target = e.target;
    setCustomDataFields([
      ...customDataFields,
      { field_id: id, value: target.checked, key: target.name },
    ]);
    product = {
      ...product,
      [target.name]: target.checked,
    };
    setProduct(product);
  };
  const onHandleCustomDate = (date, id, fieldName) => {
    if (date === '') {
      setCustomDataFields([...customDataFields, { field_id: id, value: '' }]);
    } else {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: new Date(date) },
      ]);
    }
    setValue(fieldName, new Date(date));
    setProduct({ ...product, [fieldName]: new Date(date) });
  };
  const selectPicklistMultiValue = (fieldName, value_option, id) => {
    if (product[fieldName] === '') {
      return [];
    }
    if (product[fieldName]) {
      return product[fieldName];
    }

    const defaultItem = value_option.find(
      (item) => item.default === true && item.value !== '-None-'
    );

    if (defaultItem && defaultItem.value && !(fieldName in initialLoad)) {
      initialLoad[fieldName] = 'yes';
      handlePicklistDefault(
        {
          field_id: id,
          value: [{ value: defaultItem.value }],
          key: fieldName,
        },
        fieldName
      );
      return [{ value: defaultItem.value }];
    }

    return [];
  };
  const handlePicklistDefault = (data, fieldName) => {
    picklistDefault.push(data);
    setCustomDataFields([...customDataFields, ...picklistDefault]);
    product = {
      ...product,
      [fieldName]: data.value,
    };
    setProduct(product);
    setValue(fieldName, [data]);
  };

  const onHandlePicklistSingle = (item, id, fieldName) => {
    let picked;
    if (item.name === '-None-') {
      picked = '';
    } else {
      picked = [{ value: item.name }];
    }
    let updated = false;
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      setCustomDataFields([
        ...customDataFields,
        { field_id: id, value: picked, key: fieldName },
      ]);
    }
    product = {
      ...product,
      [fieldName]: picked,
    };
    setProduct(product);
    setValue(fieldName, picked);
  };
  const selectPicklistValue = (fieldName, value_option, id) => {
    if (product[fieldName] === '') {
      return '-None-';
    }
    if (
      product[fieldName] &&
      product[fieldName][0] &&
      typeof product[fieldName][0].value === 'string'
    ) {
      return product[fieldName][0].value;
    }

    const defaultItem = value_option.find(
      (item) => item.default === true && item.value !== '-None-'
    );

    if (defaultItem && defaultItem.value && !(fieldName in initialLoad)) {
      initialLoad[fieldName] = 'yes';
      handlePicklistDefault(
        {
          field_id: id,
          value: [{ value: defaultItem.value }],
          key: fieldName,
        },
        fieldName
      );
      return defaultItem.value;
    }

    return '-None-';
  };
  const onHandlePicklistMulti = (val, id, fieldName) => {
    let picked;
    if (val.length === 0) {
      picked = '';
    } else {
      picked = val;
    }
    let updated = false;
    const fieldData = customDataFields.map((item) => {
      if (item.field_id === id) {
        updated = true;
        return { field_id: id, value: picked, key: fieldName };
      } else {
        return item;
      }
    });
    if (updated) {
      setCustomDataFields(fieldData);
    } else {
      const tempCustom = [
        ...customDataFields,
        { field_id: id, value: picked, key: fieldName },
      ];
      setCustomDataFields(tempCustom);
    }
    product = {
      ...product,
      [fieldName]: picked,
    };
    setProduct(product);
    setValue(fieldName, picked);
  };
  return (
    <>
      {show && (
        <RightPanelModal
          showModal={show}
          setShowModal={() => ResetForm()}
          showOverlay={true}
          containerBgColor={'pb-0'}
          containerWidth={RIGHT_PANEL_WIDTH}
          containerPosition={'position-fixed'}
          headerBgColor="bg-gray-5"
          Title={
            <div className="d-flex py-2 align-items-center">
              <h3 className="mb-0">
                {productType === 'edit'
                  ? 'Edit Product'
                  : productType === 'clone'
                  ? 'Clone Product'
                  : 'Add Product'}
              </h3>
            </div>
          }
        >
          {loading ? (
            loader()
          ) : (
            <CardBody className="right-bar-vh h-100 overflow-y-auto">
              <Form
                onSubmit={handleSubmit(
                  product?.id ? onHandleUpdate : handleSubmitted
                )}
              >
                <div>
                  {Object.keys(fields).map((key) => {
                    return (
                      <>
                        <h5 className="pb-0">{key}</h5>
                        {fields[key]?.map((item) => {
                          const fieldName =
                            item?.columnName ||
                            item?.key?.toLowerCase().replace(/\s+/g, '');
                          const {
                            field_type,
                            key,
                            mandatory,
                            isCustom,
                            value_option,
                            id,
                            value_type,
                          } = item;
                          const shouldRenderComponent =
                            !excludedColumns.includes(key) &&
                            !excludedFieldTypes.includes(field_type);
                          return (
                            <>
                              {
                                <div key={item?.id}>
                                  {shouldRenderComponent &&
                                    renderComponent(field_type, {
                                      label: key,
                                      value: product,
                                      name: fieldName,
                                      className: 'text-capitalize',
                                      inputClass: mandatory
                                        ? 'border-left-4 border-left-danger'
                                        : '',
                                      placeholder: key,
                                      validationConfig: {
                                        required: mandatory,
                                        inline: false,
                                        validateSpaces: mandatory,
                                        pattern: mandatory
                                          ? getPattern(field_type)
                                          : '',

                                        onChange: (e) =>
                                          isCustom
                                            ? onHandleCustomField(
                                                e,
                                                id,
                                                value_type
                                              )
                                            : onHandleChange(e),
                                      },
                                      errorMessage:
                                        getPatternErrorMessage(field_type),

                                      errors,
                                      register,
                                      errorDisplay: 'mb-0',
                                      fieldType: field_type,
                                      type:
                                        field_type === 'TEXT'
                                          ? 'textarea'
                                          : 'input',
                                    })}
                                  {field_type === 'DATE' && (
                                    <FormGroup
                                      row
                                      className="py-1 align-items-center"
                                    >
                                      <Label
                                        md={3}
                                        className="text-right font-size-sm"
                                      >
                                        {key}
                                      </Label>
                                      <Col md={9} className="pl-0">
                                        <div className="date-picker input-time w-100">
                                          <ControllerValidation
                                            name={fieldName}
                                            errors={errors}
                                            form={product}
                                            errorDisplay="mb-0"
                                            control={control}
                                            validationConfig={{
                                              required: mandatory
                                                ? `${key} is required.`
                                                : '',
                                            }}
                                            renderer={({ field }) => (
                                              <ReactDatepicker
                                                id={fieldName}
                                                name={fieldName}
                                                format={DATE_FORMAT_EJS}
                                                minDate={new Date()}
                                                autoComplete="off"
                                                todayButton="Today"
                                                validationConfig={{
                                                  required: mandatory
                                                    ? `${key} is required.`
                                                    : '',
                                                }}
                                                fieldState={getFieldState(
                                                  fieldName
                                                )}
                                                value={product[fieldName]}
                                                className="form-control"
                                                placeholder={
                                                  DATE_FORMAT_EJS_UPDATED
                                                }
                                                onChange={(date) => {
                                                  if (isCustom) {
                                                    onHandleCustomDate(
                                                      date,
                                                      id,
                                                      fieldName
                                                    );
                                                  }
                                                }}
                                              />
                                            )}
                                          />
                                        </div>
                                      </Col>
                                    </FormGroup>
                                  )}
                                  {item?.field_type === 'CHECKBOX' && (
                                    <FormGroup
                                      row
                                      className="align-items-center"
                                    >
                                      <Label
                                        md={3}
                                        className="text-right font-size-sm"
                                      ></Label>
                                      <Col md={9} className="pl-0">
                                        <ControllerValidation
                                          name={fieldName}
                                          errors={errors}
                                          form={product}
                                          errorDisplay="mb-0"
                                          control={control}
                                          validationConfig={{
                                            required: item?.mandatory
                                              ? `${item?.key} is required.`
                                              : '',
                                          }}
                                          renderer={({ field }) => (
                                            <CheckboxInput
                                              label={item?.key}
                                              id={fieldName}
                                              name={fieldName}
                                              onChange={(e) => {
                                                if (isCustom) {
                                                  onHandleCustomCheckBox(e, id);
                                                } else {
                                                  onHandleChange(e);
                                                }
                                              }}
                                              validationConfig={{
                                                required: item?.mandatory
                                                  ? `${item?.key} is required.`
                                                  : '',
                                              }}
                                              fieldState={getFieldState(
                                                fieldName
                                              )}
                                              checked={
                                                product[fieldName] === true
                                              }
                                            />
                                          )}
                                        />
                                      </Col>
                                    </FormGroup>
                                  )}
                                  {item?.key === 'Price' && (
                                    <FormGroup
                                      row
                                      className="align-items-center"
                                    >
                                      <Label
                                        md={3}
                                        className="text-right font-size-sm"
                                      >
                                        {item?.key}
                                      </Label>
                                      <Col md={9} className="pl-0">
                                        <IdfFormInputCurrency
                                          onChange={onHandleChange}
                                          name="price"
                                          value={product}
                                          type="text"
                                          max={999999999}
                                          step={0.01}
                                          min={0}
                                          required={item?.mandatory}
                                          placeholder="Product price"
                                        />
                                      </Col>
                                    </FormGroup>
                                  )}
                                  {field_type === 'PICKLIST' && (
                                    <FormGroup
                                      row
                                      className="align-items-center"
                                    >
                                      <Label
                                        md={3}
                                        className="text-right font-size-sm"
                                      >
                                        {key}
                                      </Label>
                                      <Col md={9} className="pl-0">
                                        <ControllerValidation
                                          name={fieldName}
                                          errors={errors}
                                          form={product}
                                          errorDisplay="mb-0"
                                          control={control}
                                          validationConfig={{
                                            required: mandatory
                                              ? `${key} is required.`
                                              : '',
                                          }}
                                          renderer={({ field }) => (
                                            <DropdownSelect
                                              data={value_option.map(
                                                (item, i) => {
                                                  return {
                                                    id: i,
                                                    name: item.value,
                                                  };
                                                }
                                              )}
                                              onHandleSelect={(item) => {
                                                onHandlePicklistSingle(
                                                  item,
                                                  id,
                                                  fieldName
                                                );
                                              }}
                                              select={selectPicklistValue(
                                                fieldName,
                                                value_option,
                                                id,
                                                fieldName
                                              )}
                                              placeholder="Select Option"
                                              customClasses={
                                                'w-100 overflow-y-auto max-h-300'
                                              }
                                              validationConfig={{
                                                required: mandatory,
                                              }}
                                            />
                                          )}
                                        />
                                      </Col>
                                    </FormGroup>
                                  )}
                                  {field_type === 'PICKLIST_MULTI' && (
                                    <FormGroup
                                      row
                                      className="align-items-center"
                                    >
                                      <Label
                                        md={3}
                                        className="text-right font-size-sm"
                                      >
                                        {key}
                                      </Label>
                                      <Col md={9} className="pl-0">
                                        <ControllerValidation
                                          name={fieldName}
                                          errors={errors}
                                          form={product}
                                          errorDisplay="mb-0"
                                          control={control}
                                          validationConfig={{
                                            required: mandatory
                                              ? `${key} is required.`
                                              : '',
                                          }}
                                          renderer={({ field }) => (
                                            <AddPicklistOptions
                                              dropdownList={value_option.filter(
                                                (item) =>
                                                  item.value !== '-None-'
                                              )}
                                              validationConfig={{
                                                required: mandatory,
                                              }}
                                              fieldState={getFieldState(
                                                fieldName
                                              )}
                                              placeholder={'Add Options'}
                                              value={selectPicklistMultiValue(
                                                fieldName,
                                                value_option,
                                                id,
                                                fieldName
                                              )}
                                              setValue={(e) =>
                                                onHandlePicklistMulti(
                                                  e,
                                                  id,
                                                  fieldName
                                                )
                                              }
                                              tooltip={
                                                constants.tooltipTagInput
                                              }
                                              labelSize="full"
                                              onChange={(e) => {}}
                                            />
                                          )}
                                        />
                                      </Col>
                                    </FormGroup>
                                  )}
                                </div>
                              }
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              </Form>
            </CardBody>
          )}

          <ModalFooter>
            <ButtonIcon
              label="Cancel"
              color="white"
              classnames="btn-white mx-1 btn-sm"
              onclick={() => (show ? ResetForm() : '')}
            />
            <ButtonIcon
              classnames="btn-sm"
              type="button"
              onclick={handleSubmit(
                productType === 'edit' ? onHandleUpdate : handleSubmitted
              )}
              label={
                productType === 'edit'
                  ? 'Update'
                  : productType === 'clone'
                  ? 'Clone'
                  : 'Save'
              }
              color={`primary`}
              loading={buttonSpinner}
            />
          </ModalFooter>
        </RightPanelModal>
      )}
    </>
  );
};

export default ProductCreateModal;
