const AccountStructureWidgetWrapper = ({ children, className }) => {
  return (
    <div className={`position-relative hover-actions ${className}`}>
      <div
        className="report-widget card"
        style={{ borderRadius: 'var(--rpt-widget-border-radius)' }}
      >
        <div className="card-body border-0">{children}</div>
      </div>
    </div>
  );
};

export default AccountStructureWidgetWrapper;
