import { useState, useEffect, createContext } from 'react';

export const TabsContext = createContext({
  activatedTab: null,
  setActivatedTab: () => null,
  useStorage: true,
});

export const TabsProvider = ({ children, useStorage = true }) => {
  const [activatedTab, setActivatedTab] = useState(() => {
    if (useStorage) {
      const storedTab = localStorage.getItem('activatedTab');
      return storedTab ? JSON.parse(storedTab) : {};
    }
    return {};
  });

  useEffect(() => {
    if (useStorage && activatedTab !== null) {
      localStorage.setItem('activatedTab', JSON.stringify(activatedTab));
    }
  }, [activatedTab, useStorage]);

  return (
    <TabsContext.Provider value={{ activatedTab, setActivatedTab, useStorage }}>
      {children}
    </TabsContext.Provider>
  );
};
