import AddNewReportButton from './AddNewReportButton';
import Skeleton from 'react-loading-skeleton';
import TooltipComponent from '../lesson/Tooltip';
import ReportOwner from './ReportOwner';
import MaterialIcon from '../commons/MaterialIcon';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import ButtonIcon from '../commons/ButtonIcon';
import DownloadReportDropdown from './DownloadReportDropdown';
import React, { useEffect, useState } from 'react';
import { PDF_PAGE_HEIGHT, ReportTypes } from './reports.constants';
import moment from 'moment';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import MoreActions from '../MoreActions';
import {
  getReportPDFName,
  getReportPDFNameMerchant,
} from './reports.helper.functions';
import useDeleteFraudReportModal from '../../hooks/reports/modals/useDeleteFraudReportModal';
import useResyncMerchantReportModal from '../../hooks/reports/modals/useResyncMerchantReportModal';
import OrganizationService from '../../services/organization.service';

const ReportAction = ({
  readOnly,
  pastReports,
  handleManualReport,
  rptGenerated,
  handleEditReport,
  loadingReport,
  loadingPastReports,
  selectedRpt,
  setSelectedRpt,
  selectedIndividualRpt = {},
  setSelectedIndividualRpt = () => {},
  handleClearIndividualReport = () => {},
  handleEditIndividualReport = () => {},
  profileInfo,
  report,
  startDownload,
  setStartDownload,
  downloadOptions,
  csvData,
  exportToCSV,
  reportType,
  linkConfig,
  draftMode,
  handleDraftClick,
  reSync,
  handleResyncClick,
  organization,
  subOrganizationConfig = {},
  setSuccessMessage = () => {},
  reportPdfTitle = {},
  isAggregate,
}) => {
  const {
    DeleteFraudReportModal,
    setReport,
    setShowModal: setShowDeleteModal,
  } = useDeleteFraudReportModal(
    () => {
      // just refresh no fetch core data and create
      subOrganizationConfig && subOrganizationConfig.refetchReports();
    },
    () => {}
  );
  const {
    SyncMerchantReportModal,
    setShowModal: setShowMerchantReportModal,
    setOrganization: setMerchantOrg,
    setReport: setResyncMerchantReport,
  } = useResyncMerchantReportModal(
    () => {
      setSuccessMessage('Report refreshed successfully.');
      // resync means again call core data to fetch and create
      subOrganizationConfig && subOrganizationConfig.refreshReports();
    },
    () => {}
  );
  const isCoreData =
    Object.keys(report?.coreData || {}).length || report?.manualInput?.isAuto;

  const isMerchantReport =
    reportType === ReportTypes.MerchantV2 ||
    reportType === ReportTypes.Merchant;

  const [isOrgPrimaryOwner, setIsOrgPrimaryOwner] = useState(false);

  const isSubOrgFlow = Object.keys(subOrganizationConfig).length > 0;

  const getOrganizationOwners = async () => {
    const { data: owners } = await OrganizationService.getOwners(
      organization.id,
      {
        page: 1,
        limit: 10,
      }
    );
    if (owners.length) {
      const ownerIds = owners.map((own) => own.user_id);
      setIsOrgPrimaryOwner(
        ownerIds.includes(profileInfo.id) ||
          ownerIds.includes(organization?.assigned_user_id)
      );
    } else {
      setIsOrgPrimaryOwner(true);
    }
  };
  const DropdownSelectTitle = () => {
    return (
      <div className="d-flex align-items-center gap-1">
        <MaterialIcon rounded icon="corporate_fare" />
        <span>
          {subOrganizationConfig?.selected?.name ||
            subOrganizationConfig?.selectedParent?.name ||
            'Select Organization'}
        </span>
      </div>
    );
  };
  const getHeightFromWidth = (width) => {
    const aspectRatio = 8.5 / 11; // Letter paper size aspect ratio
    const height = width / aspectRatio;
    return Math.round(height);
  };
  const handleDownload = () => {
    setStartDownload(true);
    setTimeout(() => {
      const $canvas = document.getElementById('rptPdf');
      const configWidth = $canvas?.getBoundingClientRect();
      const HTML_Width = configWidth?.width;
      window.scrollTo(0, 0);
      const calculatedPageHeight = getHeightFromWidth(HTML_Width);
      const pageHeight =
        calculatedPageHeight < PDF_PAGE_HEIGHT
          ? PDF_PAGE_HEIGHT
          : calculatedPageHeight;

      // eslint-disable-next-line new-cap
      const pdf = new window.jspdf.jsPDF('p', 'pt', [HTML_Width, pageHeight]);

      const canvases = [...document.querySelectorAll('#rptPdf > div.px-0')].map(
        (el) => {
          return window.html2canvas(el, {
            allowTaint: true,
            scale: 3,
            useCORS: true,
            removeContainer: true,
            imageTimeout: 15000,
          });
        }
      );
      const linkConfigs = linkConfig || {};
      const links =
        Object.entries(linkConfigs).length === 1 ? null : linkConfigs;
      const Pages = {};
      if (links?.length) {
        for (let k = 0; k < links.length; k++) {
          Pages[links[k].page] = links[k];
        }
      }
      Promise.all(canvases).then((resps) => {
        resps.forEach((canvas, index) => {
          const imgData = canvas.toDataURL('image/jpeg', 1);

          const newLinkPage = links?.length ? Pages[index] || {} : linkConfig;
          // experiment for one qr code default widget, currently doing for working capital
          // will have to think to make it a dynamic solution
          // for now hard-coding working capital widget area so that it would be clickable
          // and only the second page of WC report we have QRCode widget
          if (newLinkPage?.url && index === newLinkPage?.page) {
            // Add a rectangle to represent an area on the map and clickable
            const { areaX, areaY, areaWidth, areaHeight } = newLinkPage.area;

            pdf.link(areaX, areaY, areaWidth, areaHeight, {
              url: newLinkPage.url,
              target: '_blank',
            });
          }
          pdf.addPage();
          pdf.addImage(imgData, 'JPEG', 0, 0, HTML_Width, PDF_PAGE_HEIGHT);
        });
        pdf.deletePage(1);
        if (reportType === ReportTypes.MerchantV2) {
          pdf.save(
            `${getReportPDFNameMerchant(
              reportType,
              reportPdfTitle,
              isAggregate
            )}.pdf`
          );
        } else {
          pdf.save(
            `${getReportPDFName(
              reportType,
              reportType === ReportTypes.AccountStructure
                ? selectedRpt?.ReportInfo
                : report
            )}.pdf`
          );
        }
        setStartDownload(false);
      });
    }, 100);
  };

  useEffect(() => {
    organization?.id && getOrganizationOwners();
  }, [organization, profileInfo]);
  return (
    <>
      <DeleteFraudReportModal />
      <SyncMerchantReportModal />
      {!readOnly && (
        <div
          className={`d-flex position-absolute align-items-center gap-1 report-controls ${
            readOnly ? 'end-0' : ''
          }`}
          style={{ top: readOnly ? -60 : -35 }}
        >
          {reportType !== ReportTypes.MerchantV2 && (
            <AddNewReportButton
              readOnly={readOnly}
              onClick={handleManualReport}
              addView={!rptGenerated && !loadingReport && !readOnly}
            />
          )}
        </div>
      )}
      <div
        className={`d-flex align-items-center gap-1 w-100 report-controls ${
          pastReports.length > 0 && !readOnly ? 'pt-3' : ''
        } ${readOnly ? 'pb-0' : ''}`}
        style={{ top: readOnly ? -60 : -42 }}
      >
        {loadingPastReports ? (
          <div className="d-flex align-items-center px-3 pt-3 justify-content-between w-100">
            <div>
              <Skeleton height={12} width={120} />
            </div>
            <div className="d-flex align-items-center ml-auto gap-1 mx-2">
              <Skeleton height={12} width={50} />
              <Skeleton height={12} width={80} />
              <Skeleton height={12} width={50} />
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-end pl-3 pr-2 pt-0 justify-content-between w-100">
            <div>
              <h1
                className={`mb-0 d-flex align-items-center gap-1 ${
                  readOnly ? 'font-size-lg' : 'font-size-em'
                }`}
              >
                <span>{selectedRpt?.prettyDate}</span>
                {selectedRpt?.prettyDate && !readOnly && (
                  <TooltipComponent
                    placement="right"
                    title={
                      <ReportOwner report={selectedRpt} me={profileInfo} />
                    }
                  >
                    <MaterialIcon icon="info" />
                  </TooltipComponent>
                )}
              </h1>
            </div>
            <div className="d-flex align-items-center ml-auto gap-1 mx-2">
              {draftMode && !readOnly && (
                <ButtonIcon
                  icon="edit_square"
                  color="outline-primary"
                  label="1 Draft Report"
                  onclick={handleDraftClick}
                  classnames="btn-sm bg-white"
                />
              )}
              {rptGenerated &&
                !readOnly &&
                reSync &&
                isCoreData &&
                !isMerchantReport && (
                  <ButtonIcon
                    icon="refresh"
                    color="outline-primary"
                    label="Refresh"
                    onclick={() => handleResyncClick(report)}
                    classnames="btn-sm"
                  />
                )}
              {rptGenerated &&
                !readOnly &&
                !isMerchantReport &&
                isOrgPrimaryOwner && (
                  <ButtonIcon
                    icon="edit"
                    color="white"
                    label="Edit"
                    onclick={() => handleEditReport('0')}
                    classnames="btn-sm"
                  />
                )}
              {pastReports.length > 0 && !isMerchantReport && (
                <ButtonFilterDropdown
                  buttonText="Select Period"
                  icon="calendar_month"
                  menuClass="rpt-history-dd-width max-h-300"
                  btnToggleStyle="btn-h-sm"
                  options={pastReports}
                  customKeys={['key', 'prettyDate']}
                  filterOptionSelected={selectedRpt}
                  handleFilterSelect={(e, item) => {
                    setSelectedRpt(item);
                  }}
                />
              )}

              {readOnly ? (
                <>
                  {(rptGenerated || pastReports.length > 0) && (
                    <>
                      {rptGenerated && (
                        <DownloadReportDropdown
                          report={report}
                          selectedRpt={selectedRpt}
                          startDownload={startDownload}
                          setStartDownload={setStartDownload}
                          downloadOptions={downloadOptions}
                          csvData={csvData}
                          reportType={reportType}
                          exportToCSV={exportToCSV}
                          linkConfig={linkConfig}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {isMerchantReport ? (
                    <>
                      {isMerchantReport && (
                        <div className="d-flex align-items-center gap-1">
                          {selectedRpt?.coreDataList?.length && (
                            <ButtonFilterDropdown
                              buttonText="Select Statement"
                              icon="list"
                              menuClass="rpt-history-dd-width max-h-300"
                              btnToggleStyle="btn-h-sm"
                              options={selectedRpt?.coreDataList?.sort(
                                (a, b) => moment(a.Month) - moment(b.Month)
                              )}
                              customKeys={['key', 'prettyDate']}
                              filterOptionSelected={selectedIndividualRpt}
                              handleFilterSelect={(e, item) => {
                                setSelectedIndividualRpt(item);
                              }}
                            />
                          )}
                          {pastReports.length > 0 && (
                            <ButtonFilterDropdown
                              buttonText="Select Period"
                              icon="date_range"
                              menuClass="rpt-history-dd-width max-h-300"
                              btnToggleStyle="btn-h-sm"
                              options={pastReports}
                              customKeys={['key', 'prettyDate']}
                              filterOptionSelected={selectedRpt}
                              handleFilterSelect={(e, item) => {
                                setSelectedRpt(item);
                              }}
                            />
                          )}
                          {isSubOrgFlow ? (
                            <>
                              {subOrganizationConfig.loading ? (
                                <Skeleton height={10} width={220} />
                              ) : (
                                <div
                                  style={{ top: -36 }}
                                  className="position-absolute right-0 mr-3"
                                >
                                  {subOrganizationConfig.list.length > 0 ? (
                                    <DropdownButton
                                      id="dropdown-sub-org"
                                      variant="white"
                                      className="w-100"
                                      size="sm"
                                      title={<DropdownSelectTitle />}
                                    >
                                      <Dropdown.Item
                                        as="button"
                                        className="font-weight-medium d-flex justify-content-between align-items-center pl-3"
                                        onClick={() =>
                                          subOrganizationConfig.onChangeParent(
                                            organization
                                          )
                                        }
                                      >
                                        <span>{organization?.name}</span>
                                        {subOrganizationConfig?.selectedParent
                                          ?.id === organization?.id &&
                                          !subOrganizationConfig.selected && (
                                            <MaterialIcon
                                              icon="check"
                                              clazz="fw-bold text-primary"
                                            />
                                          )}
                                      </Dropdown.Item>
                                      {subOrganizationConfig.list?.map(
                                        (sub) => (
                                          <Dropdown.Item
                                            onClick={() =>
                                              subOrganizationConfig.onChange(
                                                sub
                                              )
                                            }
                                            className="pl-4 d-flex justify-content-between align-items-center"
                                            key={sub.subOrganizationId}
                                            as="button"
                                          >
                                            <span>{sub.name}</span>
                                            {subOrganizationConfig?.selected
                                              ?.subOrganizationId ===
                                              sub.subOrganizationId && (
                                              <MaterialIcon
                                                icon="check"
                                                clazz="fw-bold text-primary"
                                              />
                                            )}
                                          </Dropdown.Item>
                                        )
                                      )}
                                    </DropdownButton>
                                  ) : null}
                                </div>
                              )}
                            </>
                          ) : null}
                          {pastReports?.length > 0 && (
                            <a className={`btn btn-white p-1`}>
                              <MoreActions
                                loader={startDownload}
                                iconStyle="fs-4"
                                toggleClassName="w-moto p-0"
                                items={[
                                  ...(selectedIndividualRpt?.key &&
                                  isOrgPrimaryOwner
                                    ? [
                                        {
                                          id: 'edit',
                                          name: 'Edit',
                                          className: 'bg-hover-primary',
                                          icon: 'edit',
                                        },
                                      ]
                                    : []),
                                  ...(selectedIndividualRpt?.key && isCoreData
                                    ? [
                                        {
                                          id: 'add',
                                          name: 'Aggregate View',
                                          className: 'bg-hover-primary',
                                          icon: 'view_list',
                                        },
                                      ]
                                    : []),
                                  ...(!selectedIndividualRpt?.key && isCoreData
                                    ? [
                                        {
                                          id: 'open',
                                          name: 'Refresh Data',
                                          className: 'bg-hover-primary',
                                          icon: 'refresh',
                                        },
                                      ]
                                    : []),
                                  {
                                    id: 'download',
                                    name: 'Download',
                                    className: 'bg-hover-primary',
                                    icon: 'download',
                                  },
                                  ...(!selectedIndividualRpt?.key
                                    ? [
                                        {
                                          type: 'divider',
                                        },
                                        {
                                          id: 'remove',
                                          name: 'Delete Report',
                                          icon: 'delete',
                                        },
                                      ]
                                    : []),
                                ]}
                                onHandleEdit={() => {
                                  handleEditIndividualReport(
                                    selectedIndividualRpt
                                  );
                                }}
                                onHandleAdd={handleClearIndividualReport}
                                onHandleRemove={() => {
                                  setReport({
                                    ...report,
                                    date:
                                      report?.aggregate?.monthRange?.end ||
                                      report.reportDate,
                                  });
                                  setShowDeleteModal(true);
                                }}
                                onHandleOpen={() => {
                                  setResyncMerchantReport({
                                    ...report,
                                    date:
                                      report?.aggregate?.monthRange?.end ||
                                      report.reportDate,
                                  });
                                  setMerchantOrg(organization);
                                  setShowMerchantReportModal(true);
                                }}
                                onHandleDownload={handleDownload}
                              />
                            </a>
                          )}
                        </div>
                      )}
                      {rptGenerated && !isMerchantReport && (
                        <DownloadReportDropdown
                          report={report}
                          startDownload={startDownload}
                          setStartDownload={setStartDownload}
                          downloadOptions={downloadOptions}
                          csvData={csvData}
                          reportType={reportType}
                          exportToCSV={exportToCSV}
                          linkConfig={linkConfig}
                        />
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReportAction;
