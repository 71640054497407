import { ListGroupItem } from 'reactstrap';
import WidgetWrapper from './WidgetWrapper';
import React from 'react';
import { HorizontalWidgets, WidgetTypes } from '../constants/widgetsConstants';

const WidgetWithActionButtons = ({
  widget,
  section,
  onAddWidget,
  actionButtons,
  reportType,
  onDeleteWidget,
  selectedTenant,
  setSelectedWidget,
  setShowWidgetsLibrary,
}) => {
  // get the list of widgets library and only allow them to be replaced in reports
  const widgetsLibrary = [
    ...HorizontalWidgets.map((wt) => wt.type),
    WidgetTypes.BLANK,
  ];
  return (
    <ListGroupItem className="border-0 px-3 position-relative pb-0">
      <WidgetWrapper
        actionButtons={actionButtons}
        selectedTenant={selectedTenant}
        widget={{
          ...widget,
          section,
          action:
            Object.entries(actionButtons).length &&
            widgetsLibrary.includes(widget.type)
              ? {
                  onAdd: () => {
                    setSelectedWidget({
                      ...widget,
                      section,
                      onAdd: onAddWidget,
                    });
                    setShowWidgetsLibrary(true);
                  },
                  onDelete: () =>
                    onDeleteWidget({
                      ...widget,
                      section,
                    }),
                }
              : null,
        }}
      />
    </ListGroupItem>
  );
};
export default WidgetWithActionButtons;
