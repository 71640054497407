import React, { useEffect, useState } from 'react';
import { Card, CardHeader } from 'reactstrap';
import ButtonFilterDropdown from '../../../../../components/commons/ButtonFilterDropdown';
import { reportPages } from '../../../../../utils/constants';
import UserStatisticsLesson from './LeaderboardStatistics';
import { getTomorrowDate } from '../../../../../utils/Utils';
import DatePickerTraining from '../../../../../components/dealsBreakdown/DatePickerTraining';
import teamsService from '../../../../../services/teams.service';
import ReportSearchOptionDropdown from '../../../../../components/commons/ReportSearchOptionDropdown';

const TrainingLeaderboard = ({ dashboard }) => {
  const [csvBtnLoad, setShowCSVBtn] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [show, setShow] = useState(false);
  const [reportPage, setReportPage] = useState(reportPages[0]);
  const tomorrowDate = getTomorrowDate();
  const [teams, setTeams] = useState([]);
  const [queryFilter, setQueryFilter] = useState({
    filters: [],
  });

  useEffect(() => {
    (async () => {
      const { data } = await teamsService.getTeams({
        page: 1,
        limit: 100,
        filter: { search: searchQuery, isActive: true },
      });
      setTeams(data);
    })();
  }, [searchQuery]);

  const [dateRange, setDateRange] = useState({
    key: 'All Time',
    startDate: new Date(1970, 0, 1),
    endDate: tomorrowDate,
  });

  const handleOptionSelect = (event) => {
    const { value } = event.target;
    if (selectedItem?.length > 0) {
      setQueryFilter({
        filters: [...selectedItem],
      });
    } else {
      setQueryFilter((prevState = []) => {
        const isSelected = prevState.includes(value);
        const updatedSelectedData = isSelected
          ? prevState.filter((item) => item !== value)
          : [...prevState, value];
        return updatedSelectedData;
      });
    }
  };

  const handleAllSelect = () => {
    const allSelected = teams?.map((rpt) => rpt?.id);

    setQueryFilter({
      filters: [...allSelected],
    });
  };

  return (
    <Card className={`overflow-x-hidden overflow-y-auto h-100`}>
      <CardHeader>
        <div
          className={`d-flex justify-content-between align items center w-100 ${
            csvBtnLoad ? 'mr-5' : ''
          }`}
        >
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            {dashboard?.name}: Lessons Completed
          </h4>
          <div
            className={`d-flex align-items-center ${
              csvBtnLoad ? 'mr-5 pr-5' : ''
            }`}
          >
            <DatePickerTraining
              range={dateRange}
              setRange={setDateRange}
              disablePastDate
              extraClass="mx-1"
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              btnToggleStyle="btn-md"
              options={reportPages}
              filterOptionSelected={reportPage}
              handleFilterSelect={(e, item) => {
                setReportPage(item);
              }}
            />
            <ReportSearchOptionDropdown
              selectedData={selectedItem}
              data={teams}
              show={show}
              label={`Team`}
              scrollable="pr-3 text-left"
              setShow={setShow}
              showCheckAll={false}
              setSelectedData={setSelectedItem}
              search={true}
              customKey={['name', 'id']}
              handleAllSelect={handleAllSelect}
              customStyle={{ width: '320px', marginLeft: '10px' }}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleOptionSelected={handleOptionSelect}
            />
          </div>
        </div>
      </CardHeader>
      <div className="p-0">
        <UserStatisticsLesson
          dateRange={dateRange}
          reportLimit={reportPage}
          queryFilter={queryFilter}
          setShowCSVBtn={setShowCSVBtn}
        />{' '}
      </div>
    </Card>
  );
};

export default TrainingLeaderboard;
