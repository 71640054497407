import React, { useEffect, useLayoutEffect, useState } from 'react';
import { CardBody, CardFooter, FormGroup, Label } from 'reactstrap';
import { Form } from 'react-bootstrap';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import ButtonIcon from '../commons/ButtonIcon';
import MaterialIcon from '../commons/MaterialIcon';
import NoDataFound from '../commons/NoDataFound';
import NoDataFoundTitle from '../fields/NoDataFoundTitle';
import AddEditChecklistItem from '../fields/modals/AddEditChecklistItem';
import TooltipComponent from '../lesson/Tooltip';
import { Actions } from '../fields/fields.constants';
import DeleteConfirmationModal from '../modal/DeleteConfirmationModal';
import {
  ActionIcons,
  CHECKLIST_ACTIONS,
  ChecklistFieldsTabs,
  ChecklistStatuses,
} from '../../utils/checklist.constants';
import MoreActions from '../MoreActions';
import checklistService from '../../services/checklist.service';
import {
  DATE_FORMAT_EJS,
  DATE_FORMAT_EJS_UPDATED,
  FrequencyMap,
  IntervalMap,
  handleEmptySpaceAtStart,
  isPermissionAllowed,
  parseRRuleString,
} from '../../utils/Utils';
import ReactDatepicker from '../inputs/ReactDatpicker';
import { useForm } from 'react-hook-form';
import ControllerValidation from '../commons/ControllerValidation';
import InputValidation from '../commons/InputValidation';
import { Skeleton } from '@mui/material';
import { RRule } from 'rrule';
import { capitalize } from 'lodash';
import { FormattedTextarea } from '../commons/FormattedTextarea';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ChecklistStatus from './ChecklistStatus';

const RecurrencePatternKeys = {
  Monthly: 'monthly',
  Quarterly: 'quarterly',
  Semi: 'semi-annually',
  Annually: 'annually',
};
const tabsData = [
  {
    title: 'Internal Checklist',
    key: ChecklistFieldsTabs.Internal,
    tabId: ChecklistFieldsTabs.Internal,
  },
  {
    title: 'Client Checklist',
    key: ChecklistFieldsTabs.Client,
    tabId: ChecklistFieldsTabs.Client,
  },
];

const ChecklistOption = ({
  item,
  editItem,
  removeItem,
  fieldSection,
  index,
}) => {
  const actionItems = [
    {
      id: 'remove',
      icon: 'delete',
      name: 'Delete',
    },
  ];
  const action = item?.action;
  const icon = ActionIcons[action];
  return (
    <>
      {fieldSection.isDraggable && (
        <Draggable
          key={item.checklistItemId}
          draggableId={`id-block-${index}`}
          index={index}
        >
          {(provided, snapshot) => (
            <div
              key={index}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`d-flex pl-2 pr-3 setting-item rounded inactive bg-hover-gray align-items-center ${
                snapshot.isDragging ? 'shadow-lg' : ''
              }}`}
            >
              <MaterialIcon icon="drag_indicator" clazz="text-gray-600" />
              <div className="d-flex py-1 setting-item w-100 justify-content-between gap-1 align-items-center">
                <div className="border rounded justify-content-between d-flex align-items-center gap-1 p-2 flex-fill">
                  <div className="d-flex gap-1 flex-fill align-items-center">
                    <TooltipComponent title={item?.action}>
                      <MaterialIcon icon={icon} />
                    </TooltipComponent>
                    <span>{item.title}</span>
                  </div>
                  <div className="d-flex align-items-center gap-1">
                    <TooltipComponent title="Edit">
                      <a
                        onClick={editItem}
                        className="cursor-pointer refresh-icon text-gray-800 icon-hover-bg"
                      >
                        <MaterialIcon icon="edit" size="fs-6" />
                      </a>
                    </TooltipComponent>
                    <a className={`icon-hover-bg refresh-icon cursor-pointer`}>
                      <MoreActions
                        icon="more_vert"
                        items={actionItems}
                        onHandleRemove={() => removeItem(item)}
                        toggleClassName="w-auto p-0 h-auto"
                        menuWidth={180}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Draggable>
      )}
    </>
  );
};

const ChecklistOptions = ({
  items,
  setItems,
  setSuccessMessage,
  data,
  getChecklistItems,
  isItemsLoading,
  activeTab,
  setErrorMessage,
  setChecklistItems,
}) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editedField, setEditedField] = useState({});
  const [mode, setMode] = useState(Actions.Add);
  const [componentsToDelete, setComponentsToDelete] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [options, setOptions] = useState([]);

  const getChecklistMode = () => {
    return data?.checklistId ? Actions.Edit : Actions.Add;
  };

  const addItem = () => {
    setMode(Actions.Add);
    const checklistAction = CHECKLIST_ACTIONS[0];
    setEditedField({
      checklistId: data?.checklistId,
      status: ChecklistStatuses.InProgress,
      type: activeTab,
      action: checklistAction.name,
      checklistMode: getChecklistMode(),
    });
    setOpenAddModal(true);
  };
  const editItem = (item) => {
    setMode(Actions.Edit);
    setEditedField({ ...item, checklistMode: getChecklistMode() });
    setOpenAddModal(true);
  };

  const handleConfirmDeleteField = async () => {
    try {
      const itemToDelete = componentsToDelete[0];
      await checklistService.deleteCheckListItem(itemToDelete.checklistItemId);
      setSuccessMessage(
        `Checklist item "${componentsToDelete[0]?.title}" deleted successfully.`
      );
      getChecklistItems(
        itemToDelete?.checklistId,
        itemToDelete,
        Actions.Delete
      );
    } catch (e) {
      console.log(e);
    } finally {
      setComponentsToDelete([]);
      setOpenDeleteModal(false);
    }
  };
  const removeItem = (item) => {
    setComponentsToDelete([item]);
    setOpenDeleteModal(true);
  };
  const handleConfirm = (newItem, action) => {
    if (action === Actions.Edit) {
      setItems([
        ...items.map((f) => (f.id === newItem.id ? { ...newItem } : f)),
      ]);
      setSuccessMessage('Checklist item updated.');
    } else {
      setItems(items ? [...items, newItem] : [newItem]);
      setSuccessMessage('Checklist item created.');
    }
  };

  const DeleteFieldBody = ({ text }) => {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <MaterialIcon icon="report_problem" clazz="font-size-4em" />
        </div>
        <hr />
        <h4>{text}</h4>
        <ul className="list-disc">
          {componentsToDelete.map((item) => (
            <li className="font-weight-medium ml-4" key={item?.id}>
              <p className="mb-1">{item?.title}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const AddChecklistItemLink = () => {
    return (
      <FormGroup>
        <div className="d-flex gap-1 align-items-center">
          <span
            onClick={addItem}
            className="text-primary d-flex align-items-center fs-7 fw-bold cursor-pointer"
          >
            <MaterialIcon icon="add" rounded size="fs-5" />
            <span className="btn-link text-primary text-decoration-underline">
              Checklist Item
            </span>
          </span>
        </div>
      </FormGroup>
    );
  };
  useEffect(() => {
    setOptions(items?.filter((fl) => fl.type === activeTab));
  }, [activeTab, items]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((obj, index) => {
      obj.position = index + 1;
    });
    return result;
  };
  const onHandleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const checklistItems = reorder(
      [...items],
      result.source.index,
      result.destination.index
    );
    setChecklistItems(checklistItems);
  };

  return (
    <>
      {isItemsLoading ? (
        <div>
          <div className="d-flex align-items-center gap-2 w-100 mt-3">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="rounded" width={'100%'} height={15} />
          </div>
          <div className="d-flex align-items-center gap-2 w-100 mt-3">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="rounded" width={'100%'} height={15} />
          </div>
          <div className="d-flex align-items-center gap-2 w-100 mt-3 mb-4">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="rounded" width={'100%'} height={15} />
          </div>
        </div>
      ) : (
        <>
          {options?.length ? (
            <DragDropContext onDragEnd={onHandleDragEnd}>
              <Droppable droppableId={`${data.checklistId}`}>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {/* <TransitionGroup appear={true}> */}
                    {options?.map((item, index) => (
                      <ChecklistOption
                        key={index}
                        fieldSection={{
                          ...data,
                          isDraggable: true,
                        }}
                        item={item}
                        index={index}
                        total={items.length}
                        editItem={() => editItem(item)}
                        setItems={setItems}
                        checklist={data}
                        removeItem={() => removeItem(item)}
                      />
                    ))}
                    {provided.placeholder}
                    {/* </TransitionGroup> */}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <>
              {isPermissionAllowed('checklists', 'create') &&
                isPermissionAllowed('checklists', 'edit') && (
                  <AddChecklistItemLink />
                )}
              <NoDataFound
                icon="edit_note"
                iconStyle="font-size-3em"
                containerStyle="text-gray-search my-1 py-1"
                title={
                  <NoDataFoundTitle clazz="fs-7" str={`No checklist items`} />
                }
              />
            </>
          )}
        </>
      )}
      {options?.length > 0 &&
        isPermissionAllowed('checklists', 'create') &&
        isPermissionAllowed('checklists', 'edit') && <AddChecklistItemLink />}
      <AddEditChecklistItem
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        items={items}
        setItems={setItems}
        setErrorMessage={setErrorMessage}
        mode={mode}
        getChecklistItems={getChecklistItems}
        field={editedField}
        setSuccessMessage={setSuccessMessage}
        section={activeTab}
        handleConfirmModal={handleConfirm}
      />
      <DeleteConfirmationModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        setSelectedCategories={setComponentsToDelete}
        event={handleConfirmDeleteField}
        itemsConfirmation={componentsToDelete}
        itemsReport={[]}
        customBody={
          <DeleteFieldBody text="Are you sure you want delete following checklist item?" />
        }
      />
    </>
  );
};

const renewalDateOptions = [
  { key: 30, name: '30 days prior' },
  { key: 60, name: '60 days prior' },
  { key: 90, name: '90 days prior' },
  { key: 120, name: '120 days prior' },
];
const recurringOptions = [
  { key: '', name: 'Does Not Repeat' },
  { key: RecurrencePatternKeys.Monthly, name: 'Monthly' },
  { key: RecurrencePatternKeys.Quarterly, name: 'Quarterly' },
  { key: RecurrencePatternKeys.Semi, name: 'Semi-Annually' },
  { key: RecurrencePatternKeys.Annually, name: 'Annually' },
];

const ChecklistSection = ({
  checklistCreate,
  setChecklistCreate,
  checklistData = {},
  setErrorMessage,
  setSuccessMessage,
  getChecklistData,
}) => {
  const [activeTab, setActiveTab] = useState(
    checklistData?.type || ChecklistFieldsTabs.Client
  );

  const defaultState = {
    renewalInterval: renewalDateOptions[0],
    recurrencePattern: recurringOptions[4],
    items: [],
    message: '',
    title: '',
    global: false,
    initialDueDate: null,
    status: false,
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getFieldState,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: defaultState,
  });
  const [data, setData] = useState(defaultState);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isItemsLoading, setIsItemsLoading] = useState(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [checklistToDelete, setChecklistToDelete] = useState([]);
  const [checklistItems, setChecklistItems] = useState([]);

  const handleSaveChecklist = async () => {
    setIsLoading(true);
    let freq = 'MONTHLY';
    let inter = '1';
    if (data?.recurrencePattern?.key === RecurrencePatternKeys.Quarterly) {
      inter = '3';
    } else if (data?.recurrencePattern?.key === RecurrencePatternKeys.Semi) {
      inter = '6';
    } else if (
      data?.recurrencePattern?.key === RecurrencePatternKeys.Annually
    ) {
      freq = 'YEARLY';
    }
    // Create RRule instance
    const rule = new RRule({
      freq: RRule[freq],
      interval: inter,
    });

    const checklist = {
      type: activeTab,
      title: data?.title,
      status: 'active',
      message: data?.message,
      initialDueDate: data?.initialDueDate,
      global: data?.global,
      renewalPeriod: 'day',
      renewalValue: parseInt(data?.renewalInterval?.key, 10) || null,
      recurrencePattern:
        data?.recurrencePattern?.key === '' ? '' : rule.toString(),
    };
    try {
      const { data } = await checklistService.createCheckLists(checklist);
      if (
        checklistItems.length &&
        checklistItems.every((item) => !item.checklistId)
      ) {
        const requests = checklistItems.map((item) =>
          checklistService.createCheckListItem({
            ...item,
            checklistId: data.checklistId,
          })
        );
        await Promise.all(requests);
      }

      getChecklistData(data, Actions.Add);
      setSuccessMessage('Checklist Created');
    } catch (e) {
      console.log(e);
    } finally {
      reset(defaultState);
      setData(defaultState);
      setIsLoading(false);
      setChecklistCreate(false);
      setChecklistItems([]);
    }
  };

  useEffect(() => {
    if (data?.global === true && data?.initialDueDate === null) {
      const next30Days = new Date();
      next30Days.setDate(new Date().getDate() + 30);
      setData({
        ...data,
        initialDueDate: next30Days,
      });
    }
  }, [data?.global]);

  const handleDraftChecklist = async () => {
    if (data?.title === '') {
      return setErrorMessage('Title Required.');
    }
    setIsDraftLoading(true);
    let freq = 'MONTHLY';
    let inter = '1';
    if (data?.recurrencePattern?.key === RecurrencePatternKeys.Quarterly) {
      inter = '3';
    } else if (data?.recurrencePattern?.key === RecurrencePatternKeys.Semi) {
      inter = '6';
    } else if (
      data?.recurrencePattern?.key === RecurrencePatternKeys.Annually
    ) {
      freq = 'YEARLY';
    }
    // Create RRule instance
    const rule = new RRule({
      freq: RRule[freq],
      interval: inter,
    });

    const checklist = {
      type: activeTab,
      title: data?.title,
      status: 'draft',
      message: data?.message,
      initialDueDate: data?.initialDueDate,
      global: data?.global,
      renewalPeriod: 'day',
      renewalValue: parseInt(data?.renewalInterval?.key, 10) || null,
      recurrencePattern: rule.toString(),
    };
    try {
      const { data } = await checklistService.createCheckLists(checklist);
      // create checklist items too if user has added them

      if (
        checklistItems.length &&
        checklistItems.every((item) => !item.checklistId)
      ) {
        const requests = checklistItems.map((item) =>
          checklistService.createCheckListItem({
            ...item,
            checklistId: data.checklistId,
          })
        );
        await Promise.all(requests);
      }
      getChecklistData(data, Actions.Add);
      setSuccessMessage('Checklist Saved as Draft');
    } catch (e) {
      console.log(e);
    } finally {
      setData(defaultState);
      reset(defaultState);
      setIsDraftLoading(false);
      setChecklistCreate(false);
      setChecklistItems([]);
    }
  };
  const handleUpdateChecklist = async () => {
    let freq = 'MONTHLY';
    let inter = '1';
    if (data?.recurrencePattern?.key === RecurrencePatternKeys.Quarterly) {
      inter = '3';
    } else if (data?.recurrencePattern?.key === RecurrencePatternKeys.Semi) {
      inter = '6';
    } else if (
      data?.recurrencePattern?.key === RecurrencePatternKeys.Annually
    ) {
      freq = 'YEARLY';
    }
    const rule = new RRule({
      freq: RRule[freq],
      interval: inter,
    });
    setIsLoading(true);
    const checklist = {
      type: activeTab,
      title: data?.title,
      status: 'active',
      message: data?.message,
      initialDueDate: data?.initialDueDate,
      global: data?.global,
      renewalPeriod: 'day',
      renewalValue: parseInt(data?.renewalInterval?.key, 10) || null,
      recurrencePattern:
        data?.recurrencePattern?.key === ''
          ? ''
          : rule.toString() || data?.recurrencePattern?.recurse,
    };
    try {
      const { data: updatedChecklist } =
        await checklistService.updateCheckLists(checklist, data?.checklistId);
      setSuccessMessage('Checklist Saved');
      const recurrencePattern = parseRRuleString(
        updatedChecklist?.recurrencePattern
      );
      const interval = IntervalMap[recurrencePattern?.INTERVAL];
      const freq =
        FrequencyMap[recurrencePattern?.FREQ] || recurrencePattern?.FREQ;
      const checklistData = {
        ...updatedChecklist,
        renewalInterval: {
          key: updatedChecklist?.renewalValue,
          name: `${updatedChecklist?.renewalValue} ${capitalize(
            updatedChecklist?.renewalPeriod
          )}s Prior`,
        },
        recurrencePattern: {
          key: interval || freq || recurrencePattern?.FREQ?.toLowerCase(),
          name:
            capitalize(interval) ||
            capitalize(freq) ||
            capitalize(recurrencePattern?.FREQ),
          recurse: updatedChecklist?.recurrencePattern,
        },
        status: updatedChecklist?.status,
      };
      if (checklistItems?.length > 0) {
        const sortItems = checklistItems?.map(async (item) => {
          return await checklistService.updateCheckListItem(
            item?.checklistItemId,
            item
          );
        });
        await Promise.all(sortItems);
      }
      setData(checklistData);
      getChecklistData(updatedChecklist, Actions.Edit);
    } catch (e) {
      setErrorMessage(e?.response?.data?.errors[0]?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDraftUpdateChecklist = async () => {
    let freq = 'MONTHLY';
    let inter = '1';
    if (data?.recurrencePattern?.key === RecurrencePatternKeys.Quarterly) {
      inter = '3';
    } else if (data?.recurrencePattern?.key === RecurrencePatternKeys.Semi) {
      inter = '6';
    } else if (
      data?.recurrencePattern?.key === RecurrencePatternKeys.Annually
    ) {
      freq = 'YEARLY';
    }
    const rule = new RRule({
      freq: RRule[freq],
      interval: inter,
    });
    setIsDraftLoading(true);
    const checklist = {
      type: activeTab,
      title: data?.title,
      status: 'draft',
      message: data?.message,
      initialDueDate: data?.initialDueDate,
      global: data?.global,
      renewalPeriod: 'day',
      renewalValue: parseInt(data?.renewalInterval?.key, 10) || null,
      recurrencePattern: rule.toString() || data?.recurrencePattern?.recurse,
    };
    try {
      const { data: updatedChecklist } =
        await checklistService.updateCheckLists(checklist, data?.checklistId);
      setSuccessMessage('Checklist Saved as a Draft');
      getChecklistData(updatedChecklist, Actions.Edit);
      const recurrencePattern = parseRRuleString(
        updatedChecklist?.recurrencePattern
      );
      const interval = IntervalMap[recurrencePattern?.INTERVAL];
      const freq =
        FrequencyMap[recurrencePattern?.FREQ] || recurrencePattern?.FREQ;
      const checklistData = {
        ...updatedChecklist,
        renewalInterval: {
          key: updatedChecklist?.renewalValue,
          name: `${updatedChecklist?.renewalValue} ${capitalize(
            updatedChecklist?.renewalPeriod
          )}s Prior`,
        },
        recurrencePattern: {
          key: interval || freq || recurrencePattern?.FREQ?.toLowerCase(),
          name:
            capitalize(interval) ||
            capitalize(freq) ||
            capitalize(recurrencePattern?.FREQ),
          recurse: updatedChecklist?.recurrencePattern,
        },
        status: updatedChecklist?.status,
      };
      setData(checklistData);
    } catch (e) {
      setErrorMessage(e?.response?.data?.errors[0]?.message);
    } finally {
      setIsDraftLoading(false);
    }
  };

  const onHandleRemove = (item) => {
    setChecklistToDelete([{ ...item }]);
    setOpenDeleteModal(true);
  };
  const loadChecklistItems = async (id) => {
    const { data } = await checklistService.getCheckListItemsById(id, {
      limit: 100,
      page: 1,
      type: activeTab,
    });
    setChecklistItems(data?.data);
  };
  const getChecklistItems = async (id, field, type) => {
    setIsItemsLoading(true);
    try {
      if (type === Actions.Delete) {
        setChecklistItems(
          [...checklistItems].filter(
            (fl) => fl.checklistItemId !== field.checklistItemId
          )
        );
      } else {
        if (id) {
          // means we dont want to refresh
          if (type === Actions.Add) {
            const updatedChecklistItems = [...checklistItems, field].map(
              (item, index) => ({
                ...item,
                position: index + 1,
              })
            );

            setChecklistItems(updatedChecklistItems);
          } else if (type === Actions.Edit) {
            // edit case FE handling
            setChecklistItems(
              [...checklistItems].map((fl) =>
                fl.checklistItemId === field.checklistItemId
                  ? { ...field }
                  : { ...fl }
              )
            );
          } else {
            await loadChecklistItems(id);
          }
        } else {
          // means we dont want to refresh
          if (type === Actions.Add) {
            const updatedChecklistItems = [...checklistItems, field].map(
              (item, index) => ({
                ...item,
                position: index + 1,
              })
            );

            setChecklistItems(updatedChecklistItems);
          } else if (type === Actions.Edit) {
            // edit case FE handling
            setChecklistItems(
              [...checklistItems].map((fl) =>
                fl.feId === field.feId ? { ...field } : { ...fl }
              )
            );
          } else {
            await loadChecklistItems(id);
          }
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsItemsLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (Object.keys(checklistData)?.length > 0) {
      const editedData = {
        ...checklistData,
      };
      setData(editedData);
      Object.keys(editedData).forEach((key) => {
        const value = editedData[key];
        setValue(key, value);
      });
      getChecklistItems(checklistData?.checklistId);
    }
  }, [checklistData?.checklistId, activeTab]);
  const handleDeleteChecklist = async () => {
    try {
      await checklistService.deleteCheckList(data?.checklistId);
      getChecklistData(data, Actions.Delete);
      setOpenDeleteModal(false);
    } catch (e) {
      console.log(e);
    }
  };
  const updatedDate = new Date(data?.initialDueDate);
  useEffect(() => {
    if (checklistCreate) {
      const checklistObj = {
        ...data,
        renewalInterval: renewalDateOptions[0],
        recurrencePattern: recurringOptions[4],
      };
      setData(checklistObj);
      setValue('renewalInterval', renewalDateOptions[0]);
      setValue('recurrencePattern', recurringOptions[4]);
    }
  }, [checklistCreate]);

  const countCharacters = () => {
    return data?.message?.length;
  };
  return (
    <>
      <Form
        className="bg-white"
        onSubmit={handleSubmit(
          checklistData?.checklistId &&
            isPermissionAllowed('checklists', 'edit')
            ? handleUpdateChecklist
            : handleSaveChecklist
        )}
      >
        <CardBody
          className="overflow-y-auto border-top"
          style={{ height: 'calc(100vh - 190px)', overflowX: 'hidden' }}
        >
          {data?.checklistId && (
            <div className="text-right">
              <ChecklistStatus
                fontSize="fs-7"
                item={{
                  status:
                    data?.status === 'active'
                      ? ChecklistStatuses.Completed.text
                      : ChecklistStatuses.NotStarted.text,
                  statusText: data?.status === 'active' ? 'Active' : 'Draft',
                }}
              />
            </div>
          )}
          <div>
            <FormGroup>
              <Label className="mb-0 fs-7 font-weight-bold">Title</Label>
              <InputValidation
                fieldType="text"
                type="input"
                name="title"
                id="title"
                rows={3}
                classNames={`border-left-4 border-left-danger`}
                className="mt-2 mb-2"
                value={data?.title}
                errors={errors}
                validationConfig={{
                  required: true,
                  validateSpaces: true,
                  inline: false,
                  onChange: (e) => {
                    setData({ ...data, title: e.target.value });
                    setValue('initialDueDate', e.target.value);
                  },
                }}
                register={register}
                placeholder="Title"
                onKeyDown={handleEmptySpaceAtStart}
              />
            </FormGroup>
            <FormGroup>
              <div>
                <Label className="mb-0 fs-7 font-weight-bold">Recurring</Label>
                <ControllerValidation
                  name={'recurrencePattern'}
                  errors={errors}
                  form={data}
                  customClass="w-100 d-block"
                  errorDisplay="mb-0"
                  control={control}
                  validationConfig={{
                    required: `Recurring is required.`,
                  }}
                  renderer={({ field }) => (
                    <ButtonFilterDropdown
                      buttonText="Select Recurring"
                      menuClass="text-capitalize"
                      btnToggleStyle="text-truncate w-100 btn-h-sm text-capitalize input-global-height"
                      options={recurringOptions}
                      validationConfig={{
                        required: `Recurring is required.`,
                      }}
                      fieldState={getFieldState('recurrencePattern')}
                      filterOptionSelected={
                        data.recurrencePattern?.name === ''
                          ? recurringOptions[0]
                          : data.recurrencePattern || recurringOptions[4]
                      }
                      handleFilterSelect={(e, item) => {
                        setData({ ...data, recurrencePattern: item });
                        setValue('recurrencePattern', item);
                      }}
                    />
                  )}
                />
              </div>
            </FormGroup>
            <FormGroup className="w-100 d-block">
              <Label className="mb-0 fs-7 font-weight-bold">
                Start Reminder
              </Label>
              <ControllerValidation
                name={'renewalInterval'}
                errors={errors}
                form={data}
                errorDisplay="mb-0"
                customClass="w-100 d-block"
                control={control}
                validationConfig={{
                  required: `Renewal Interval is required.`,
                }}
                renderer={({ field }) => (
                  <ButtonFilterDropdown
                    buttonText="Select Renewal Date"
                    btnToggleStyle="text-truncate w-100 btn-h-sm input-global-height"
                    options={renewalDateOptions}
                    validationConfig={{
                      required: `Renewal Interval is required.`,
                    }}
                    fieldState={getFieldState('initialDueDate')}
                    filterOptionSelected={
                      data.renewalInterval || renewalDateOptions[0]
                    }
                    handleFilterSelect={(e, item) => {
                      setData({ ...data, renewalInterval: item });
                      setValue('renewalInterval', item);
                    }}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Label className="mb-0 fs-7 font-weight-bold">
                Custom Client Message
              </Label>
              <FormattedTextarea
                maxLength={1000}
                aria-rowcount={4}
                rows={4}
                data={data}
                setData={setData}
                value={data.message}
                name="message"
                max={1000}
                onChange={(e) => {
                  setData({ ...data, message: e.target.value });
                }}
              />
              <div className="text-right font-size-sm2 fw-normal">
                {countCharacters()} / 1000
              </div>
            </FormGroup>
            <nav
              className="modal-report-tabs bg-white w-100 nav-justified py-2 nav"
              style={{ width: 'fit-content' }}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(tabsData[1].tabId);
                }}
                className={`mb-0 py-2 rounded-pill nav-item ${
                  activeTab === tabsData[1].tabId
                    ? 'cursor-default active bg-primary text-white'
                    : 'cursor-pointer bg-hover-gray'
                } nav-link mr-1`}
              >
                {tabsData[1].title}
              </a>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTab(tabsData[0].tabId);
                }}
                className={`mb-0 rounded-pill py-2 nav-item ${
                  activeTab === tabsData[0].tabId
                    ? 'cursor-default active bg-primary text-white'
                    : 'cursor-pointer bg-hover-gray'
                } nav-link mr-1`}
              >
                {tabsData[0].title}
              </a>
            </nav>
            <ChecklistOptions
              setErrorMessage={setErrorMessage}
              items={checklistItems}
              getChecklistData={getChecklistData}
              isItemsLoading={isItemsLoading}
              setChecklistItems={setChecklistItems}
              setItems={(newItems) => {
                setData({ ...data, items: newItems });
              }}
              data={data}
              activeTab={activeTab}
              getChecklistItems={getChecklistItems}
              setSuccessMessage={setSuccessMessage}
            />
            {data?.global === true && (
              <FormGroup>
                <Label className="mb-0 fs-7 font-weight-bold">Due Date</Label>
                <ControllerValidation
                  name={'initialDueDate'}
                  errors={errors}
                  form={data}
                  errorDisplay="mb-0"
                  control={control}
                  validationConfig={{
                    required: `Due Date is required.`,
                  }}
                  renderer={({ field }) => (
                    <ReactDatepicker
                      id={'initialDueDate'}
                      name={'initialDueDate'}
                      format={DATE_FORMAT_EJS}
                      autoComplete="off"
                      todayButton="Today"
                      validationConfig={{
                        required: `Due Date is required.`,
                      }}
                      fieldState={getFieldState('initialDueDate')}
                      value={updatedDate}
                      className="form-control"
                      placeholder={DATE_FORMAT_EJS_UPDATED}
                      onChange={(date) => {
                        setData({
                          ...data,
                          initialDueDate: date,
                        });
                        setValue('initialDueDate', date);
                      }}
                    />
                  )}
                />
              </FormGroup>
            )}
          </div>
        </CardBody>
        {isPermissionAllowed('checklists', 'create') ||
        isPermissionAllowed('checklists', 'edit') ||
        isPermissionAllowed('checklists', 'delete') ? (
          <CardFooter className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-1 align-items-center">
              {data?.checklistId ? (
                <>
                  {isPermissionAllowed('checklists', 'delete') && (
                    <ButtonIcon
                      color="outline-danger"
                      disabled={!data?.checklistId}
                      label="Delete"
                      type="button"
                      onclick={() => onHandleRemove(data)}
                      classnames="btn-sm "
                    />
                  )}
                </>
              ) : (
                <ButtonIcon
                  color="white"
                  label="Cancel"
                  type="button"
                  onclick={() => setChecklistCreate(false)}
                  classnames="btn-sm"
                />
              )}
            </div>
            {isPermissionAllowed('checklists', 'create') ||
            isPermissionAllowed('checklists', 'edit') ? (
              <div>
                <ButtonIcon
                  type="button"
                  color=""
                  loading={isDraftLoading}
                  label={'Save as Draft'}
                  onclick={
                    data?.checklistId
                      ? handleDraftUpdateChecklist
                      : handleDraftChecklist
                  }
                  classnames="btn-sm mr-2 bg-primary-soft text-primary btn-hover-primary"
                />
                <ButtonIcon
                  type="submit"
                  color="primary"
                  loading={isLoading}
                  label={'Save'}
                  classnames="btn-sm"
                />
              </div>
            ) : (
              ''
            )}
          </CardFooter>
        ) : (
          ''
        )}
      </Form>
      <DeleteConfirmationModal
        showModal={openDeleteModal}
        setShowModal={setOpenDeleteModal}
        event={handleDeleteChecklist}
        setSelectedCategories={setChecklistToDelete}
        itemsConfirmation={checklistToDelete}
        itemsReport={[]}
        description="Are you sure you want to delete this checklist?"
      />
    </>
  );
};
export default ChecklistSection;
