import React from 'react';
import WidgetSourceBlock from '../../WidgetSourceBlock';
import { Card, CardBody } from 'reactstrap';
import MaterialIcon from '../../../commons/MaterialIcon';
import CutoutWidget from './CutoutWidget';

const DEMANDS = [
  { icon: 'location_city', label: 'B2B Payments' },
  { icon: 'order_approve', label: 'Bill Pay' },
  { icon: 'currency_exchange', label: 'Disbursements' },
  { icon: 'universal_currency', label: 'Payroll' },
  { icon: 'move_up', label: 'Wire Transfers' },
];

const PERCENTAGE_LABELS = [
  { percentage: '44%', labels: ['are implementing', 'FedNow'] },
  {
    percentage: '62%',
    labels: ['have implemented or are', 'implementing RTP'],
  },
  {
    percentage: '86%',
    labels: ['have implemented or are', 'implementing Same Day ACH'],
  },
];
const PercentageLabelItem = ({ data, whenPrinting }) => {
  return (
    <div className={`d-flex gap-2 ${whenPrinting ? '' : 'align-items-center'}`}>
      <h1
        className={`text-primary mb-0 font-weight-bold ${
          whenPrinting ? 'font-size-xl' : ''
        }`}
      >
        {data.percentage}
      </h1>
      <div className={`mb-0 d-flex flex-column`}>
        {data.labels.map((mp) => (
          <p
            className={`font-weight-medium mb-0 text-left ${
              whenPrinting ? 'fs-10' : 'fs-9'
            }`}
            key={mp}
          >
            {mp}
          </p>
        ))}
      </div>
    </div>
  );
};

const DemandItem = ({ data }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="bg-gray-300 rounded-circle d-flex align-items-center justify-content-center p-1">
        <MaterialIcon icon={data.icon} clazz="fs-6" symbols rounded={false} />
      </div>
      <p className="mb-0 fs-8 font-weight-medium">{data.label}</p>
    </div>
  );
};

const InstantPaymentsWidget = ({ widgetConfig, whenPrinting }) => {
  return (
    <Card className="report-widget">
      <CardBody className="align-items-center d-flex">
        <div className="flex-fill">
          <h5 className="text-left d-flex justify-content-between mb-1 d-flex align-items-center gap-1">
            {widgetConfig.heading}
          </h5>
          <div className="px-2">
            <p
              className={`${
                whenPrinting ? 'fs-9' : 'font-size-sm2'
              } mb-1 text-left`}
            >
              {widgetConfig.description}
            </p>
            <div
              style={{ gap: whenPrinting ? 20 : 30 }}
              className={`d-flex align-items-center justify-content-center ${
                whenPrinting ? 'pt-2 pb-0' : 'py-2'
              }`}
            >
              {DEMANDS.map((mp) => (
                <DemandItem data={mp} key={mp.label} />
              ))}
            </div>
            <CutoutWidget
              widgetConfig={widgetConfig}
              whenPrinting={whenPrinting}
              cutoutConfig={{
                icon: 'apartment',
                text: `The Industry <br /> Is Moving`,
                bg: 'report-widget-inner-section-soft bg-white',
                iconConfig: {
                  fg: 'text-primary',
                  bg: 'bg-primary-soft',
                  icon: 'apartment',
                },
              }}
            >
              <div
                style={{ gap: whenPrinting ? 20 : 30 }}
                className={`d-flex align-items-center justify-content-center ${
                  whenPrinting ? 'p-2' : 'py-4'
                }`}
              >
                {PERCENTAGE_LABELS.map((mp) => (
                  <PercentageLabelItem
                    data={mp}
                    key={mp.label}
                    whenPrinting={whenPrinting}
                  />
                ))}
              </div>
            </CutoutWidget>
          </div>
          {widgetConfig.source && (
            <div className="mt-2">
              <WidgetSourceBlock text="US Faster Payments Council" />
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default InstantPaymentsWidget;
