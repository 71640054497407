const LocalStorageKey = 'account-structure-report';

const useLocalAccountStructureReport = () => {
  const saveReport = (data) => {
    localStorage.setItem(LocalStorageKey, JSON.stringify(data));
  };

  const getReport = () => {
    return JSON.parse(localStorage.getItem(LocalStorageKey) || '{}');
  };

  const clearReport = () => {
    localStorage.removeItem(LocalStorageKey);
  };

  return { saveReport, getReport, clearReport };
};

export default useLocalAccountStructureReport;
