import React, { useEffect, useState } from 'react';
import RightPanelModal from '../../../modal/RightPanelModal';
import { CardBody, Form, CardFooter, Col, FormGroup, Label } from 'reactstrap';
import { RESOURCE_NOT_FOUND } from '../../../../utils/constants';
import MaterialIcon from '../../../commons/MaterialIcon';
import NoDataFound from '../../../commons/NoDataFound';
import Loading from '../../../Loading';
import { DropdownTreeView } from './DropdownTreeView';
import { RIGHT_PANEL_WIDTH } from '../../../../utils/Utils';
import ButtonIcon from '../../../commons/ButtonIcon';
import InputValidationAdvance from '../../../commons/InputValidationAdvance';
import { FormCheck } from 'react-bootstrap';
import useRoleUsersModal from '../../../role/modals/useRoleUsersModal';
// eslint-disable-next-line no-unused-vars
import groupService from '../../../../services/groups.service';

export const TreeViewTable = (props) => {
  const {
    data,
    closeEditModal,
    setShowReport,
    handleEditModelShow,
    editRoleData,
    handleGetRoleById,
    setEditRoleData,
    dataGet,
    getListGroups,
    setOpenEditModal,
    setIsDropdownId,
    isDropdownId,
    isLoading,
    setShowCreateGroupModal,
    openEditModal,
    setIsAddSingleRole,
    showLoading,
    register,
    handleSubmit,
    setValue,
    errors,
    setSuccessMessage,
    setErrorMessage,
  } = props;
  const [isShow, setIsShow] = useState(new Set());
  const handleCollapse = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    if (isShow.has(id)) {
      setIsShow((prev) => {
        const next = new Set(prev);
        next.delete(id);
        return next;
      });
    } else {
      setIsShow((prev) => new Set(prev).add(id));
    }
  };
  const getParentIds = (data) => {
    const parents = [];
    data?.forEach((child) => {
      if (child.parent_id) {
        parents.push(child.parent_id);
      }
      if (child.children && child.children.length > 0) {
        const childParents = getParentIds(child.children);
        parents.push(...childParents);
      }
    });
    return parents;
  };

  useEffect(() => {
    if (data) {
      const parentIds = getParentIds(data.children);
      parentIds.forEach((id) => {
        setIsShow((prev) => new Set(prev.add(id)));
      });
    }
  }, [data]);
  const { RoleUsersModal, setShowModal, setData } = useRoleUsersModal({
    edit: handleEditModelShow,
    delete: setShowReport,
    refresh: getListGroups,
  });
  const handleRoleRowClick = (item) => {
    setData(item);
    setShowModal(true);
  };
  const handleAddSingleRole = (item) => {
    setShowCreateGroupModal(true);
    setIsAddSingleRole(item);
  };
  const loader = () => {
    if (showLoading) return <Loading />;
  };
  const Title = () => {
    return <div className="text-gray-search">{RESOURCE_NOT_FOUND}</div>;
  };
  const handleExpandCollapse = (e, expand) => {
    e.preventDefault();
    if (expand) {
      const parentIds = getParentIds(data.children);
      parentIds.forEach((id) => {
        setIsShow((prev) => new Set(prev.add(id)));
      });
    } else {
      setIsShow((prev) => {
        const next = new Set(prev);
        next.delete(data.id);
        return next;
      });
    }
  };
  const handlePeerDataVisibility = async (e, role) => {
    try {
      await groupService.updateGroup({
        id: role.id,
        has_sibling_access: !role.has_sibling_access,
      });
      setSuccessMessage('Role Updated.');
      getListGroups();
    } catch (e) {
      console.log(e);
      setErrorMessage('Error updating role.');
    }
  };
  const display = (parent) => {
    return (
      <>
        {showLoading ? (
          loader()
        ) : (
          <>
            {parent?.length > 0 &&
              parent.map((item, i) => {
                return (
                  <>
                    <div
                      key={`treeView_${i}`}
                      className={`${
                        isShow.has(item.id) ? 'show_border' : 'dnt_show_border'
                      } 
                  ${
                    item.children?.length > 0 ? '' : 'no-children-h-main'
                  } main-body-hh`}
                    >
                      <div
                        className={`${
                          isShow.has(item.id)
                            ? 'show_minus_icon btn_hover_show'
                            : 'show_plus_icon btn_hover_show'
                        }
                  ${
                    item.children?.length > 0
                      ? ''
                      : 'no-children-h btn_hover_show'
                  }
                  ${
                    isShow.has(item.children) ? '' : 'children-hh'
                  } btn_hover_show hover-actions cursor-pointer main-row-hh table-tree-tbody-row`}
                      >
                        <div
                          className="table-tree-body-cell"
                          onClick={() => handleRoleRowClick(item)}
                        >
                          <div className="d-inline-flex position-relative align-items-center gap-2">
                            <span
                              onClick={(e) => handleCollapse(e, item.id)}
                              className="bg-transparent bg-hover-gray cursor-pointer border-hover-gray z-index-2 border-2 position-absolute"
                              style={{
                                width: 17,
                                height: 17,
                                left: -28,
                                top: 5,
                                borderColor: 'var(--tree-item)',
                              }}
                            ></span>
                            <span>{item.name}</span>
                            <div className="d-inline-flex action-items align-items-center gap-2">
                              <a
                                href=""
                                className="icon-hover-bg"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleAddSingleRole(item);
                                }}
                              >
                                <MaterialIcon icon="add" />
                              </a>
                              <a
                                href=""
                                className="icon-hover-bg"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleEditModelShow(item);
                                }}
                              >
                                <MaterialIcon icon="edit" />
                              </a>
                              {item.parent_id === null ? (
                                <></>
                              ) : (
                                <a
                                  href=""
                                  className="icon-hover-bg"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowReport(item);
                                  }}
                                >
                                  <MaterialIcon icon="delete" />
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="peerDataVisibility table-tree-body-cell">
                          <div className="d-flex align-items-center gap-1">
                            <FormCheck
                              id={`has_sibling_access_modal_${item.id}`}
                              name={`has_sibling_access_modal_${item.id}`}
                              type="switch"
                              custom={true}
                              label=""
                              value={item.has_sibling_access}
                              checked={item.has_sibling_access}
                              onChange={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handlePeerDataVisibility(e, item);
                              }}
                            />
                            <span className="font-size-sm2">
                              {item.has_sibling_access === true ? 'Yes' : 'No'}
                            </span>
                          </div>
                        </div>
                      </div>

                      {isShow.has(item.id) && (
                        <>
                          {item.children?.length > 0 && item.id ? (
                            <div className="child py-0 pl-3">
                              <div className="main-body-hh">
                                {isShow.has(item.id) && display(item.children)}
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                  </>
                );
              })}
          </>
        )}
      </>
    );
  };
  return (
    <div className="" id="no-more-tables">
      <RoleUsersModal />
      <div className="table-tree">
        <div className="active bg-gray-table-head table-tree-thead-row">
          <div className="table-head-cell pl-4 gap-1 d-flex align-items-center">
            <a href="" onClick={(e) => handleExpandCollapse(e, true)}>
              Expand
            </a>
            <span className="text-gray-search"> / </span>
            <a href="" onClick={(e) => handleExpandCollapse(e, false)}>
              Collapse
            </a>
          </div>
          <div className="table-head-cell">Peer Data Visibility</div>
          <div className="table-head-cell"></div>
        </div>
        <div className="main-body-hh-table-body">
          {[data].length > 0 ? (
            display([data])
          ) : (
            <NoDataFound
              icon="account_tree"
              containerStyle="text-gray-search my-6 py-6"
              title={<Title />}
            />
          )}
        </div>
      </div>
      <RightPanelModal
        Title={
          <div className="d-flex py-2 align-items-center">
            <h3 className="mb-0 text-capitalize">Edit Role</h3>
          </div>
        }
        onHandleCloseModal={() => closeEditModal()}
        showModal={openEditModal}
        allowCloseOutside={false}
        setShowModal={setOpenEditModal}
        showOverlay={true}
        containerBgColor={'pb-0'}
        containerWidth={RIGHT_PANEL_WIDTH}
        containerPosition={'position-fixed'}
        headerBgColor="bg-gray-5"
      >
        <CardBody>
          <Form>
            <div>
              <InputValidationAdvance
                fieldType="text"
                type="input"
                label="Role Name"
                name="name"
                id="group_name"
                classNames={`border-left-4 border-left-danger`}
                className="mt-2 mb-2"
                value={editRoleData}
                errors={errors}
                placeholder="Role Name"
                validationConfig={{
                  required: true,
                  inline: false,
                  onChange: (e) => {
                    setEditRoleData({
                      ...editRoleData,
                      name: e.target.value,
                    });
                    setValue(name, e.target.value);
                  },
                }}
                register={register}
              />
              {dataGet.parent_id !== null && (
                <FormGroup row>
                  <Label
                    htmlFor=""
                    md={3}
                    className="form-label mt-0 pt-0 text-right"
                  >
                    Reports to
                  </Label>
                  <Col md={9} className="pl-0">
                    <DropdownTreeView
                      editRoleData={dataGet}
                      reportTo={editRoleData}
                      isDropdownId={isDropdownId}
                      setIsDropdownId={setIsDropdownId}
                      data={data}
                    />
                  </Col>
                </FormGroup>
              )}
              <FormGroup row className="pt-1">
                <Col md={3} className="text-right">
                  <Label className="form-label mt-0 mb-0 pt-0 text-right">
                    Peer Data <br />
                    Visibility
                  </Label>
                </Col>
                <Col md={9} className="pl-0">
                  <div className="bg-gray-5 p-2_2 border rounded">
                    <FormCheck
                      id={'has_sibling_access'}
                      name={'has_sibling_access'}
                      type="switch"
                      custom={true}
                      label={`Let users in this role see each other's data`}
                      value={editRoleData?.has_sibling_access}
                      checked={editRoleData?.has_sibling_access}
                      onChange={() =>
                        setEditRoleData({
                          ...editRoleData,
                          has_sibling_access: !editRoleData?.has_sibling_access,
                        })
                      }
                    />
                  </div>
                </Col>
              </FormGroup>
              <InputValidationAdvance
                fieldType="text"
                type="textarea"
                label="Description"
                name="description"
                id="description"
                classNames={`border-left-4 border-left-danger`}
                className="mt-2 mb-2"
                value={editRoleData}
                labelAlign="pt-0"
                errors={errors}
                rows={3}
                placeholder="A few words about this role"
                validationConfig={{
                  required: true,
                  inline: false,
                  onChange: (e) => {
                    setEditRoleData({
                      ...editRoleData,
                      description: e.target.value,
                    });
                    setValue(name, e.target.value);
                  },
                }}
                register={register}
              />
            </div>
          </Form>
        </CardBody>
        <CardFooter className="text-right">
          <ButtonIcon
            label="Cancel"
            type="button"
            color="white"
            classnames="btn-white mx-1 btn-sm"
            onclick={closeEditModal}
          />
          <ButtonIcon
            classnames="btn-sm"
            type="button"
            onclick={handleSubmit(handleGetRoleById)}
            label={'Update'}
            color={`primary`}
            loading={isLoading}
          />
        </CardFooter>
      </RightPanelModal>
    </div>
  );
};
