import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import Search from '../../../components/manageUsers/Search';
import coursesService from '../../../services/course.service';
import lessonService from '../../../services/lesson.service';
import Skeleton from 'react-loading-skeleton';
import MaterialIcon from '../../commons/MaterialIcon';
import NoDataFound from '../../commons/NoDataFound';
import ButtonIcon from '../../commons/ButtonIcon';

const ContentTab = ({
  onContentDataChange,
  dataToEdit,
  isEdit,
  selectedContentData,
  setSelectedContentData,
}) => {
  const defaultPagination = { page: 1, limit: 15 };
  const [fetchLessonCourse, setFetchLessonCourse] = useState('course');
  const [pagination, setPagination] = useState(defaultPagination);
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [courses, setCourses] = useState([]);

  const handleContentSelection = (courseName, itemType, itemID) => {
    const selectedItem = selectedContentData.find((item) => {
      if (itemType === 'course') {
        return item.courseId === itemID && item.type === itemType;
      } else {
        return item.lessonId === itemID && item.type === itemType;
      }
    });

    if (selectedItem) {
      const data = selectedContentData.filter((item) => item !== selectedItem);
      setSelectedContentData(data);
      onContentDataChange(data);
    } else {
      let itemToAdd = {};
      if (itemType === 'course') {
        itemToAdd = { courseId: itemID, name: courseName, type: itemType };
      } else {
        itemToAdd = { lessonId: itemID, name: courseName, type: itemType };
      }
      setSelectedContentData([itemToAdd, ...selectedContentData]);
      onContentDataChange([itemToAdd, ...selectedContentData]);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const search = searchKeyWord;
      const [coursesResponse, lessonsResponse] = await Promise.all([
        coursesService.getCourses({
          page: pagination.page,
          limit: pagination.limit,
          deleted: false,
          status: 'published',
          search,
        }),
        lessonService.getLessons({
          page: pagination.page,
          limit: pagination.limit,
          deleted: false,
          status: 'published',
          search,
        }),
      ]);
      const mergedContentData = [
        ...coursesResponse?.data.map((item) => ({ ...item, type: 'course' })),
        ...lessonsResponse?.data.map((item) => ({ ...item, type: 'lesson' })),
      ];
      setPagination(
        fetchLessonCourse === 'course'
          ? coursesResponse?.pagination
          : lessonsResponse?.pagination
      );
      const filteredMergedContentData = mergedContentData.filter((item) =>
        fetchLessonCourse.includes(item.type)
      );
      setLoader(false);
      if (loadMore) {
        const uniqueData = new Set([...courses, ...filteredMergedContentData]);
        const uniqueCoursesLessons = [...uniqueData];
        setCourses(uniqueCoursesLessons);
      } else {
        setCourses(filteredMergedContentData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchKeyWord, fetchLessonCourse, pagination?.page]);

  useEffect(() => {
    if (isEdit) {
      const fetchAllCoursesLessons = async () => {
        // Use Promise.all to make both API calls in parallel
        const [coursesResponse, lessonsResponse] = await Promise.all([
          coursesService.getCourses({
            page: pagination.page,
            limit: 1000,
          }),
          lessonService.getLessons({
            page: pagination.page,
            limit: 1000,
          }),
        ]);
        const mergedData = [...coursesResponse?.data, ...lessonsResponse?.data];

        function findObjectsByKey(
          mainArray,
          objectsToFind,
          key,
          type,
          nameProperty
        ) {
          return mainArray
            .map((item1) => {
              const matchedObject = objectsToFind.find(
                (item2) => item2[key] === item1.id
              );
              return matchedObject
                ? { name: item1[nameProperty], [key]: item1.id, type }
                : null;
            })
            .filter(Boolean);
        }

        const matchedCourses = findObjectsByKey(
          mergedData,
          dataToEdit.contents,
          'courseId',
          'course',
          'name'
        );
        const matchedLessons = findObjectsByKey(
          mergedData,
          dataToEdit.contents,
          'lessonId',
          'lesson',
          'title'
        );
        const matchedCoursesLessons = [...matchedCourses, ...matchedLessons];
        setSelectedContentData(matchedCoursesLessons);
      };

      fetchAllCoursesLessons();
    }
  }, []);

  return (
    <>
      <ButtonGroup className="p-0 mt-3 modal-report-tabs gap-1">
        <Button
          color="primary"
          outline
          onClick={() => {
            if (fetchLessonCourse !== 'course') {
              setSearchKeyWord('');
              setCourses([]);
              setFetchLessonCourse('course');
              setPagination(defaultPagination);
            }
          }}
          active={fetchLessonCourse === 'course'}
          className="rounded-pill border-0"
        >
          Courses
        </Button>
        <Button
          color="primary"
          outline
          onClick={() => {
            if (fetchLessonCourse !== 'lesson') {
              setSearchKeyWord('');
              setCourses([]);
              setFetchLessonCourse('lesson');
              setPagination(defaultPagination);
            }
          }}
          active={fetchLessonCourse === 'lesson'}
          className="rounded-pill border-0"
        >
          Lessons
        </Button>
      </ButtonGroup>

      <Search
        classnames="col-xs col-md-12 p-0 mb-2 mt-3"
        searchPlaceholder={'Search content'}
        value={searchKeyWord}
        onHandleChange={(e) => {
          if (e.target.value !== '') {
            setLoadMore(false);
            setFetchLessonCourse(['course', 'lesson']);
          } else {
            setLoadMore(true);
            setCourses([]);
            setFetchLessonCourse('course');
          }
          setPagination(defaultPagination);
          setSearchKeyWord(e.target.value);
        }}
      />
      {loading ? (
        <div className="pt-2">
          <Skeleton count={10} height={20} className={'mb-2'} />
        </div>
      ) : (
        <div style={{ maxHeight: '550px', overflowY: 'auto' }}>
          <div className="card content-box">
            {courses && courses.length > 0 ? (
              courses.map((course, index) => {
                const courseName =
                  course.type === 'course' ? course.name : course.title;
                const itemId = course.id;
                let itemType = '';
                if (Array.isArray(fetchLessonCourse)) {
                  itemType = course.type;
                } else {
                  itemType =
                    fetchLessonCourse === 'course' ? 'course' : 'lesson';
                }

                const isActive = selectedContentData.find((item) => {
                  if (itemType === 'course') {
                    return item.courseId === itemId && item.type === itemType;
                  } else {
                    return item.lessonId === itemId && item.type === itemType;
                  }
                });

                return (
                  <div
                    key={index}
                    className={`pl-3 pr-3 pt-2 pb-2 gap-2 border-bottom cursor-pointer d-flex justify-content-between bg-hover-gray text-wrap ${
                      isActive ? 'bg-primary-soft text-primary' : ''
                    }`}
                    onClick={() =>
                      handleContentSelection(courseName, itemType, itemId)
                    }
                  >
                    {courseName}{' '}
                    <div className="d-flex align-items-center gap-1 item-count w-25 justify-content-end flex-shrink-0">
                      {course.type === 'course' ? (
                        <small className="text-muted">
                          COURSE({course.totalLessons} Lessons)
                        </small>
                      ) : (
                        <small className="text-muted">LESSON</small>
                      )}
                      {isActive && <MaterialIcon clazz="" icon="close" />}
                    </div>
                  </div>
                );
              })
            ) : (
              <NoDataFound
                icon="sticky_note_2"
                title={
                  <div className="font-normal font-size-sm2 text-gray-search">
                    Records not found
                  </div>
                }
                containerStyle="text-gray-search py-6"
              />
            )}
          </div>
        </div>
      )}

      {pagination.page < pagination?.totalPages && (
        <ButtonIcon
          classnames="button-pill btn-pill mt-3 btn-sm"
          color="primary"
          label="Load more"
          loading={loader}
          onclick={() => {
            setLoadMore(true);
            setLoader(true);
            setLoading(false);
            setPagination((prevState) => ({
              ...prevState,
              page: prevState.page + 1,
            }));
          }}
        />
      )}
    </>
  );
};

export default ContentTab;
