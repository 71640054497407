import React from 'react';
import MaterialIcon from '../../../components/commons/MaterialIcon';

const ChecklistAverageStates = (results) => {
  const { data } = results[0];
  function getAverageCompletionTime(stats) {
    return stats[0]?.['OrganizationChecklistProgress.averageCompletionTime'];
  }

  function getCountOfCompleted(stats) {
    return stats[0]?.['OrganizationChecklistProgress.countOfCompleted'];
  }

  function shouldShowPlaceholder(averageCompletionTime, countOfCompleted) {
    return (
      (averageCompletionTime === 0 && countOfCompleted === '0') ||
      (averageCompletionTime === null && countOfCompleted === '0')
    );
  }

  function getCompletionTimeDisplay(averageCompletionTime, countOfCompleted) {
    if (
      (averageCompletionTime < 1 && averageCompletionTime > 0) ||
      countOfCompleted > 0
    ) {
      return 1;
    }
    return Math.round(averageCompletionTime);
  }

  function getCompletionTimeUnit(averageCompletionTime, countOfCompleted) {
    if (
      (averageCompletionTime < 1 && averageCompletionTime > 0) ||
      countOfCompleted > 0
    ) {
      return 'day';
    }
    return 'days';
  }

  function CompletionTimeDisplay({ stats }) {
    const averageCompletionTime = getAverageCompletionTime(stats);
    const countOfCompleted = getCountOfCompleted(stats);

    return (
      <div>
        {shouldShowPlaceholder(averageCompletionTime, countOfCompleted) ? (
          '--'
        ) : (
          <h2>
            {getCompletionTimeDisplay(averageCompletionTime, countOfCompleted)}{' '}
            {getCompletionTimeUnit(averageCompletionTime, countOfCompleted)}
          </h2>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="split-progress-bar">
        <div className="d-flex align-items-center gap-2 w-100">
          <MaterialIcon icon="schedule" clazz="font-size-2em text-teal-blue" />
          <div className="w-100">
            <h5 className="text-muted fon-weight-light font-size-sm mb-1">
              Average Completion Time (Last 30 days)
            </h5>
            <CompletionTimeDisplay stats={data} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChecklistAverageStates;
