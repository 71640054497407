import { useState, useEffect } from 'react';
import { getClientPortalToken } from '../layouts/constants';
const WARNING_THRESHOLD_SECONDS = 120; // 2 minutes

const useCheckTokenValidityClientPortal = (showSessionExpiredModal) => {
  const [alertShown, setAlertShown] = useState(false);
  const expiryTime = Number(getClientPortalToken()?.exp || 0);
  const isTokenExpired = () => {
    const expirationTimeInSeconds = (expiryTime - Date.now()) / 1000;
    return expirationTimeInSeconds < WARNING_THRESHOLD_SECONDS;
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (isTokenExpired()) {
        // just additional check to avoid modal opening more than once.
        if (!alertShown) {
          setAlertShown(true);
          showSessionExpiredModal(true);
        }
      }
    };

    // when tab gets active or browser gets resumed
    document.addEventListener('visibilitychange', checkTokenExpiration);

    return () => {
      document.removeEventListener('visibilitychange', checkTokenExpiration);
    };
  }, []);
};

export default useCheckTokenValidityClientPortal;
