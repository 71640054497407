import React from 'react';

const FormatTextForDisplay = ({ text = '' }) => {
  return (
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

const FormattedTextarea = ({
  value = '',
  name,
  data,
  setData,
  onChange,
  ...props
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.value.length <= 999) {
      event.preventDefault();
      const { selectionStart, selectionEnd } = event.target;
      const newText =
        value.substring(0, selectionStart) +
        '\n' +
        value.substring(selectionEnd);
      setData({ ...data, [name]: newText });
      setTimeout(() => {
        event.target.selectionStart = event.target.selectionEnd =
          selectionStart + 1;
      }, 0);
    }
  };

  return (
    <textarea
      value={value}
      onChange={onChange}
      {...props}
      onKeyDown={handleKeyDown}
      className="form-control"
    />
  );
};

export { FormattedTextarea, FormatTextForDisplay };
