import React from 'react';

import ListItems from '../../filters/ListItems';
import DropDownSearch from './DropDownSearch';

const DropdownWrapper = ({
  placeholder = '',
  onChange,
  onSelect,
  options,
  customKey,
  selects = [],
  onRemoveSelect,
  onClear,
  inputVal,
  allowClear,
  loader,
  isRequired,
  showFieldError,
  isDisabled,
}) => {
  return (
    <>
      <DropDownSearch
        placeholder={placeholder}
        onChange={onChange}
        onSelect={onSelect}
        options={options}
        customKey={customKey}
        inputVal={inputVal}
        isRequired={isRequired}
        isDisabled={isDisabled}
        allowClear={allowClear}
        showFieldError={showFieldError}
        onClear={onClear}
        loader={loader}
      />
      {!!selects.length && (
        <ListItems
          items={selects}
          options={options}
          deleteItem={onRemoveSelect}
          onClear={onClear}
          customKey={customKey}
        />
      )}
    </>
  );
};

export default DropdownWrapper;
