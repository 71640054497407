import { Col, FormGroup, Label } from 'reactstrap';
import React from 'react';

const InlineFormGroup = ({
  label,
  component,
  alignLabel = 'align-items-center',
}) => {
  return (
    <FormGroup className={`mb-0 ${alignLabel} mt-0`} row>
      <Label md={3} className="text-right py-0 font-size-sm">
        {label}
      </Label>
      <Col md={9} className="pl-0 py-0">
        {component}
      </Col>
    </FormGroup>
  );
};

export default InlineFormGroup;
