import React from 'react';
import {
  FraudWhatYouCanDoHeading,
  WidgetTypes,
} from './constants/widgetsConstants';
import ReportBlockWrapper from '../reports/ReportBlockWrapper';
import IconHeadingBlock from './blocks/IconHeadingBlock';
import _ from 'lodash';
import { ListGroup, ListGroupItem } from 'reactstrap';
import WidgetWrapper from './widgets/WidgetWrapper';
import {
  FraudReportSections,
  ReportTypes,
  TreasuryReportSections,
  WorkingCapitalReportSections,
} from '../reports/reports.constants';
import { getDifferenceWidgetVisibility } from '../../utils/Utils';

const WidgetsBySection = ({
  icon,
  report,
  section,
  widgets,
  showIcon,
  reportType,
  pageNumber,
  selectedTenant,
}) => {
  const widgetsWithoutBlank = widgets[section]?.filter(
    (widget) => widget.type !== WidgetTypes.BLANK
  );
  const sectionNames = {
    [WorkingCapitalReportSections.Payables]: 'Accounts Payable',
    [WorkingCapitalReportSections.Receivables]: 'Accounts Receivable',
    [WorkingCapitalReportSections.Overview]: 'Working Capital Analysis',
    [FraudReportSections.FraudWhyItMatters]: 'Fraud - Why It Matters',
    [FraudReportSections.WhatCanYouDo]: FraudWhatYouCanDoHeading,
    [TreasuryReportSections.Disclaimer]: report?.disclaimerHeading,
  };
  return (
    <>
      <ReportBlockWrapper
        showLogo={true}
        current={pageNumber}
        report={report}
        reportType={reportType}
        selectedTenant={selectedTenant}
      >
        <div className="text-left">
          <br />
          <IconHeadingBlock
            heading={sectionNames[section] || _.startCase(section)}
            icon={icon}
            showIcon={showIcon}
            containerStyle="gap-1 justify-content-between px-5 mb-0"
            pageNumber={pageNumber}
            report={report}
            reportType={reportType}
          />
        </div>
        <ListGroup
          className={`list-group-no-gutters my-0 list-group-flush ${
            reportType === ReportTypes.WorkingCapital
              ? 'working-capital-report'
              : ''
          }`}
        >
          {widgetsWithoutBlank?.map((widget) => (
            <>
              {widget.type === WidgetTypes.PEO ? (
                getDifferenceWidgetVisibility(widget.widgetConfig) ? (
                  <ListGroupItem
                    key={widget.id}
                    className="border-0 px-5 position-relative pb-0"
                  >
                    <WidgetWrapper
                      widget={widget}
                      whenPrinting={true}
                      selectedTenant={selectedTenant}
                    />
                  </ListGroupItem>
                ) : null
              ) : (
                <ListGroupItem
                  key={widget.id}
                  className="border-0 px-5 position-relative pb-0"
                >
                  <WidgetWrapper
                    widget={widget}
                    whenPrinting={true}
                    selectedTenant={selectedTenant}
                  />
                </ListGroupItem>
              )}
            </>
          ))}
        </ListGroup>
      </ReportBlockWrapper>
    </>
  );
};
// this wrap each section into pdf page
const ReportPages = ({
  pages,
  report,
  showIcon = true, // the icon of heading when download as pdf
  reportType,
  pageConfig,
  reportPages,
  sectionKeys,
  selectedTenant,
}) => {
  let pageNumber = 2; // starting from 2 because we have cover page at 1
  return (
    <>
      {sectionKeys.flatMap((key) => {
        const widgetsForCurrentKey = pages[key] || [];
        return (
          <>
            {widgetsForCurrentKey.length && reportPages[key]?.enabled ? (
              <WidgetsBySection
                key={key}
                report={report}
                selectedTenant={selectedTenant}
                pageNumber={pageNumber++}
                widgets={pages}
                icon={pageConfig[key]?.icon}
                section={key}
                showIcon={showIcon}
                reportType={reportType}
              />
            ) : null}
          </>
        );
      })}
    </>
  );
};

export default ReportPages;
