import { createContext, useContext, useState } from 'react';

const CustomerIdContext = createContext();

export const CustomerIdProvider = ({ children }) => {
  const [customerId, setCustomerId] = useState(null);
  return (
    <CustomerIdContext.Provider value={{ customerId, setCustomerId }}>
      {children}
    </CustomerIdContext.Provider>
  );
};

export const useCustomerIdContext = () => {
  const context = useContext(CustomerIdContext);
  if (!context) {
    throw new Error(
      'useCustomerIdContext must be used within a CustomerIdProvider'
    );
  }
  return context;
};
