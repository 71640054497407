import React, { useCallback, useMemo, useState } from 'react';
import WistiaEmbed from '../components/wistia';
import { overflowing } from '../utils/Utils';
import SimpleModalCreation from '../components/modal/SimpleModalCreation';
import Skeleton from 'react-loading-skeleton';

const CHECKLIST_WELCOME_VIDEO_KEY = 'checklist_welcome_video';
const WistiaEmbedModal = ({
  show,
  setShow,
  videoId,
  isChannel,
  onlyOnce,
  videoData = '',
  buttonText,
}) => {
  const [loader, setLoader] = useState(true);
  const [videoTitle, setVideoTitle] = useState('');
  return (
    <SimpleModalCreation
      modalTitle={videoData?.title || videoTitle}
      open={show}
      size={isChannel ? 'xl' : 'md'}
      bankTeam={false}
      saveButton={buttonText}
      isLoading={false}
      cancelButtonText={'Close'}
      bodyClassName="mb-0 p-3 min-h-120"
      handleSubmit={() => setShow(false) || ''}
      onHandleCloseModal={() => {
        overflowing();
        setShow(!show);
        if (onlyOnce) {
          localStorage.setItem(CHECKLIST_WELCOME_VIDEO_KEY, 'true');
        }
      }}
    >
      <div className="position-relative">
        {loader && (
          <div className="position-absolute h-100 w-100">
            <Skeleton height={90} width="100%" className="d-block" />
          </div>
        )}
        <WistiaEmbed
          hashedId={videoId}
          isResponsive={true}
          isChannel={isChannel}
          onVideoLoaded={(video, duration) => {
            setLoader(false);
            setVideoTitle(video?.data?.media?.name || 'Video');
          }}
        />
      </div>
    </SimpleModalCreation>
  );
};

const useWistiaEmbedPlayer = (
  videoId,
  isChannel,
  onlyOnce,
  videoData,
  buttonText
) => {
  const [showModal, setShowModal] = useState(false);

  const shouldShowModal = () => {
    // if its not already closed once
    return localStorage.getItem(CHECKLIST_WELCOME_VIDEO_KEY);
  };

  const WistiaEmbedModalModalCallback = useCallback(() => {
    return (
      <WistiaEmbedModal
        show={showModal}
        setShow={setShowModal}
        videoId={videoId}
        onlyOnce={onlyOnce}
        videoData={videoData}
        buttonText={buttonText}
        isChannel={isChannel}
      />
    );
  }, [showModal, setShowModal]);

  return useMemo(
    () => ({
      setShowModal,
      shouldShowModal,
      WistiaEmbedModal: showModal
        ? WistiaEmbedModalModalCallback
        : () => <div />,
    }),
    [setShowModal, WistiaEmbedModalModalCallback]
  );
};

export default useWistiaEmbedPlayer;
